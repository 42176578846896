import { Dropdown, Menu, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import calendarOverdue from '../../../assets/images/calendar-red.svg';
import calendar from '../../../assets/images/calendar.svg';
import clock from '../../../assets/images/clock.svg';
import completedEvent from '../../../assets/images/event-completed.svg';
import deletedEvent from '../../../assets/images/event-deleted.svg';
import property from '../../../assets/images/event-property.svg';
import moreIcon from '../../../assets/images/more.svg';
import user from '../../../assets/images/user.svg';
import ActionButton from '../../action-button';
import DeleteMenuItem from '../../menu-items/delete-menu-item';
import EditMenuItem from '../../menu-items/edit-menu-item';

const EventCard = (props) => {
    const { t } = useTranslation();

    const onChangeEventCompleted = () => {
        if (props.event.isCompleted) {
            props.setAsNotCompleted && props.setAsNotCompleted(props.event.id);
        } else {
            props.setAsCompleted && props.setAsCompleted(props.event.id);
        }
    };

    const onDelete = () => {
        props.onDelete && props.onDelete(props.event.id);
    };

    const onEdit = () => {
        props.onEdit && props.onEdit(props.event.eventId);
    };

    const dropdownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" disabled={!props.hasEditPermission} onClick={onEdit} text={t('events.card.edit')} />
                <DeleteMenuItem key="1" disabled={!props.hasEditPermission} onClick={onDelete} text={t('events.card.delete')} />
            </Menu>
        );
    };

    const getEventIcon = (event) => {
        return event.isDeleted ? <Image src={deletedEvent} /> : props.event.isCompleted ? <Image src={completedEvent} /> : null;
    };

    const getEventStatusClassName = (event) => {
        return event.isDeleted ? 'deletedEvent' : props.event.isCompleted ? 'completedEvent' : '';
    };

    return (
        <div className="event-card" key={props.event.id}>
            <ActionButton
                disabled={!props.hasEditPermission}
                onClick={!props.event.isDeleted ? onChangeEventCompleted : null}
                icon={getEventIcon(props.event)}
                className={`complete-btn ${getEventStatusClassName(props.event)}`}
            />
            <div className="d-flex flex-row justify-content-between w-100">
                <div className="info">
                    <div className="d-flex flex-row justify-content-between">
                        <p className="mb-0">{props.event.title}</p>
                        {!props.event.isDeleted ? (
                            <div className="menu">
                                <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu()}>
                                    <ActionButton className="h-auto" icon={<Image className="m-0" src={moreIcon} />} />
                                </Dropdown>
                            </div>
                        ) : null}
                    </div>
                    <Row className="m-0">
                        <Col xl={3} md={12} xs={12} className="info-col">
                            <div className="item">
                                <Image src={property} />
                                <span>{props.event.contractName || props.event.propertyName}</span>
                            </div>
                        </Col>
                        <Col xl={3} md={12} xs={12} className="info-col">
                            <div className="item">
                                {props.event.isOverdue && !props.event.isCompleted ? (
                                    <React.Fragment>
                                        <Image src={calendarOverdue} />
                                        <span className="text-error">{moment(props.event.eventEndDate).format('dddd Do MMMM YYYY')}</span>
                                        <span className="text-error"> - {t('events.overdue')}</span>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Image src={calendar} />
                                        <span>{moment(props.event.eventEndDate).format('dddd Do MMMM YYYY')}</span>
                                    </React.Fragment>
                                )}
                            </div>
                        </Col>
                        <Col xl={3} md={12} xs={12} className="info-col">
                            <div className="item">
                                <Image src={clock} />
                                <span>{props.event.time}</span>
                            </div>
                        </Col>
                        <Col xl={3} md={12} xs={12} className="info-col">
                            {props.event.users && props.event.users.length > 0 ? (
                                <div className="users-row">
                                    <div className="item">
                                        <Image src={user} />
                                        <span>{props.event.users[0]}</span>
                                    </div>
                                    {props.event.users.length > 1 && (
                                        <Tooltip
                                            placement="bottom"
                                            overlayClassName="users-tooltip"
                                            trigger="click"
                                            title={
                                                <div className="d-flex flex-column">
                                                    {props.event.users.slice(1).map((item) => (
                                                        <div className="user">
                                                            <Image src={user} />
                                                            <span>{item}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            }>
                                            <ActionButton className="btn-thirdy users-btn btn-transparent" text={`+${props.event.users.length - 1}`} />
                                        </Tooltip>
                                    )}
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default EventCard;
