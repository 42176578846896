import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Alert } from 'antd';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearUser } from '../../reducers/commonReducer';
import { login, setValueLogin } from '../../reducers/loginReducer';
import { requiredCommonInput, validateEmail } from '../../common/validators';
import passwordHide from '../../assets/images/password-hide.svg';
import passwordShow from '../../assets/images/password-show.svg';
import localStorageService from '../../services/localStorageService';
import routingService from '../../services/routingService';
import clear from '../../common/clear';
import get from 'lodash.get';
import LoginSignUpLayout from '../../components/layouts/login-layout';
import ActionButton from '../../components/action-button';
import CommonInput from '../../components/common-input';

const LoginView = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    useEffect(() => {
        localStorageService.setUser(null);
        props.clearUserCall();
    }, []);

    const onSubmit = () => {
        form.validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const params = new URLSearchParams(decodeURI(props.location.search));
                const returnUrl = params.has('returnUrl') ? params.get('returnUrl') : '';
                props.loginCall(values.email.trim(), values.password.trim(), returnUrl);
            })
            .catch((e) => {});
    };

    return (
        <LoginSignUpLayout header={t('loginSignUpPage.loginTitle')}>
            <div className="d-flex flex-direction-row mb-3">
                {/* ToDo: Implement login via social networks first */}
                {/* <p className='text-secondary'>
                    <span>{t('loginSignUpPage.wantToUseSocialLogIn')} </span>
                    <Link to="/">{t('loginSignUpPage.backToSocialLogIn')}</Link>
                </p> */}
            </div>
            {props.location.search.indexOf('password-was-reset') !== -1 && (
                <div className="mb-3">
                    <Alert message={t('loginSignUpPage.passwordWasReset') + ' '} description={t('loginSignUpPage.pleaseLoginBelow')} type="success" showIcon />
                </div>
            )}
            <Form form={form} name="basic" onFinish={onSubmit}>
                <Form.Item name="email" rules={[requiredCommonInput(t('loginSignUpPage.form.email.title') + t('common.isRequired')), validateEmail]}>
                    <CommonInput
                        type="email"
                        submitOnEnter={true}
                        maxLength={100}
                        placeholder={t('loginSignUpPage.form.email.placeholder')}
                        title={t('loginSignUpPage.form.email.title')}
                    />
                </Form.Item>
                <div className="d-flex flex-direction-row justify-content-between">
                    <p className="mb-2 font-weight-bold">{t('loginSignUpPage.form.password.title')}</p>
                    <Link to={routingService.forgotPasswordUrl()}>{t('loginSignUpPage.form.password.forgottenYourPassword')}</Link>
                </div>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t('loginSignUpPage.form.password.title') + t('common.isRequired'),
                        },
                    ]}>
                    <Input.Password
                        className="password-input"
                        onPressEnter={onSubmit}
                        placeholder={t('loginSignUpPage.form.password.placeholder')}
                        iconRender={(visible) => (visible ? <Image src={passwordShow} /> : <Image src={passwordHide} />)}
                    />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <ActionButton
                            disabled={form.getFieldsError().filter(({ errors }) => errors.length).length || props.loading}
                            text={t('loginSignUpPage.loginNow')}
                            onClick={onSubmit}
                            className="btn-primary btn-block width-fit-content"
                            loading={props.loading}
                        />
                    )}
                </Form.Item>
            </Form>
            <div>
                <span>{t('loginSignUpPage.dontHaveAccount')} </span>
                <Link to={routingService.signupUrl()}>{t('loginSignUpPage.signUpNow')}</Link>
            </div>
        </LoginSignUpLayout>
    );
};

const mapState = ({ login }) => {
    return {
        loading: get(login, 'loading'),
        error: get(login, 'error'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        loginCall(email, password, returnUrl) {
            dispatch(login(email, password, returnUrl));
        },
        setValueLoginCall(key, value) {
            dispatch(setValueLogin(key, value));
        },
        clearUserCall() {
            dispatch(clearUser());
        },
    };
};

const Login = clear(connect(mapState, mapDispatch)(LoginView));
export default Login;
