import i18next from 'i18next';
import { StatusCodes } from 'http-status-codes';
import { setValueProperty } from './propertyReducer';
import toaster from '../common/toaster';
import update from '../helpers/update';
import plotService from '../services/plotService';

const SET_VALUE_PLOT = 'SET_VALUE_PLOT';
const SUCCESS_GET_PLOT_INFO = 'SUCCESS_GET_PLOT_INFO';
const SUCCESS_GET_ADDRESSES = 'SUCCESS_GET_ADDRESSES';
const CLEAR_PLOT = 'CLEAR_PLOT';
const CLEAR_DELETE_PLOT_MODEL = 'CLEAR_DELETE_PLOT_MODEL';
const SET_DELETE_PLOT_MODEL = 'SET_DELETE_PLOT_MODEL';

export const defaultPlot = {
    plotHasName: false,
    gnrNumber: 0,
    bnrNumber: 0,
    municipality: 0,
    addresses: [],
    name: '',
    city: '',
    mainAddress: '',
    address: '',
    hasSecondaryAddresses: false,
};

const initialState = {
    plots: [],
    plot: defaultPlot,
    plotAddresses: null,
    plotInfo: null,
    isUserHasAnyPlots: false,
    addresses: [],
    disabledNextBtn: false,
    hasPlotDuplicate: false,

    plotLoading: false,
    plotInfoLoading: false,
    deleteLoading: false,

    plotNotFound: false,

    showDraftModal: false,

    showCreationResultModal: false,
    showItemUpdatedResultModal: false,
    showConfirmDeleteModal: false,

    deletePlotModel: null,
};

const plotReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_PLOT:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case SUCCESS_GET_PLOT_INFO:
            newState = update.set(newState, 'plotInfo', action.payload);
            break;
        case SUCCESS_GET_ADDRESSES:
            newState = update.set(newState, 'plotAddresses', action.payload);
            break;
        case CLEAR_PLOT:
            newState = update.set(newState, 'plot', defaultPlot);
            break;
        case CLEAR_DELETE_PLOT_MODEL:
            newState = update.set(newState, 'deletePlotModel', null);
            break;
        case SET_DELETE_PLOT_MODEL:
            newState = update.set(newState, 'deletePlotModel', action.payload);
            break;
        default:
            break;
    }

    return newState;
};

export const setValuePlot = (key, value) => {
    return {
        type: SET_VALUE_PLOT,
        payload: { key, value },
    };
};

export const clearPlot = () => {
    return {
        type: CLEAR_PLOT,
    };
};

export const clearDeletePlotModel = () => {
    return {
        type: CLEAR_DELETE_PLOT_MODEL,
    };
};

export const setDeletePlotModel = (value) => {
    return {
        type: SET_DELETE_PLOT_MODEL,
        payload: value,
    };
};

export const successGetPlotInfo = (value) => {
    return {
        type: SUCCESS_GET_PLOT_INFO,
        payload: value,
    };
};

export const successGetPlotAddresses = (value) => {
    return {
        type: SUCCESS_GET_ADDRESSES,
        payload: value,
    };
};

const handlePlotError = (error, dispatch) => {
    if (error && error.response && error.response.status && error.response.status === StatusCodes.NOT_FOUND) {
        dispatch(setValuePlot('plotNotFound', true));
    } else if (error && error.response && error.response.status && error.response.status === StatusCodes.FORBIDDEN) {
        dispatch(setValueProperty('propertyNotFound', true));
    } else if (error && error.response && error.response.title) {
        toaster.error(error.response.title, null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const createPlot = (data) => {
    return (dispatch) => {
        dispatch(setValuePlot('plotLoading', true));
        return plotService
            .create(data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => handlePlotError(error, dispatch))
            .finally(() => {
                dispatch(setValuePlot('plotLoading', false));
            });
    };
};

export const createPlots = (data, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValuePlot('plotLoading', true));
        return plotService
            .createPlots(data, propertyId, portfolioId)
            .then(() => {
                return true;
            })
            .catch((error) => handlePlotError(error, dispatch))
            .finally(() => {
                dispatch(setValuePlot('plotLoading', false));
            });
    };
};

export const updatePlot = (data, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValuePlot('plotLoading', true));
        return plotService
            .update(data, propertyId, portfolioId)
            .then((response) => {
                return response.data;
            })
            .catch((error) => handlePlotError(error, dispatch))
            .finally(() => {
                dispatch(setValuePlot('plotLoading', false));
            });
    };
};

export const deletePlot = (id, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValuePlot('deleteLoading', true));
        return plotService
            .delete(id, propertyId, portfolioId)
            .then((_) => true)
            .catch((error) => {
                if (error && error.response && error.response.status && error.response.status === StatusCodes.BAD_REQUEST) {
                    toaster.error(i18next.t('plot.deleteForbidden'), null);
                } else {
                    handlePlotError(error, dispatch);
                }
            })
            .finally(() => {
                dispatch(setValuePlot('deleteLoading', false));
            });
    };
};

export const getPlotInfo = (gnrNumber, municipality, bnrNumber, callback, cancelToken) => {
    return (dispatch) => {
        dispatch(setValuePlot('plotInfoLoading', true));
        return plotService
            .getPlotInfo(gnrNumber, municipality, bnrNumber, cancelToken)
            .then((res) => {
                dispatch(successGetPlotInfo(res.data));
                callback && callback(res.data.cadastreId);
            })
            .catch((error) => handlePlotError(error, dispatch))
            .finally(() => {
                dispatch(setValuePlot('plotInfoLoading', false));
            });
    };
};

export const getPlotById = (portfolioId, plotId, propertyId) => {
    return (dispatch) => {
        dispatch(setValuePlot('plotLoading', true));
        plotService
            .getPlotById(portfolioId, plotId, propertyId)
            .then((response) => {
                dispatch(setValuePlot('plot', response.data));
            })
            .catch((error) => handlePlotError(error, dispatch))
            .finally(() => {
                dispatch(setValuePlot('plotLoading', false));
            });
    };
};

export const getPlotAddresses = (cadastreId, cancelToken) => {
    return (dispatch) => {
        dispatch(setValuePlot('plotAddressLoading', true));
        return plotService
            .getPlotAddresses(cadastreId, cancelToken)
            .then((res) => {
                dispatch(successGetPlotAddresses(res.data));
                return res.data;
            })
            .catch((error) => handlePlotError(error, dispatch))
            .finally(() => {
                dispatch(setValuePlot('plotAddressLoading', false));
            });
    };
};

export default plotReducer;
