import { UploadOutlined } from '@ant-design/icons';
import get from 'lodash-es/get';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clear from '../../../common/clear';
import toaster from '../../../common/toaster';
import ActionButton from '../../../components/action-button';
import MainLayout from '../../../components/layouts/main-layout';
import LoaderSpinner from '../../../components/spinner';
import {
    addLocalizationValue,
    deleteLocalizationKey,
    exportFlatTranslations,
    getAllLocalizationRecords,
    publishTranslations,
    setValueTranslations,
    updateLocalizationKey,
    updateLocalizationValue,
} from '../../../reducers/translationsReducer';
import { supportedLanguages } from '../../../services/languageService';
import NewTranslationRow from './new-translation-row';
import TranslationTable from './translations-table';

const TranslationManagerComponent = (props) => {
    const { t } = useTranslation();
    const [module, setModule] = useState('frontend');
    const [sourceLang, setSourceLang] = useState(supportedLanguages.English);
    const [destinationLang, setDestinationLang] = useState(supportedLanguages.Norway);

    useEffect(() => {
        props.getAllLocalizationRecordsCall(module, sourceLang, destinationLang);
    }, []);

    const handleAddRow = (newRow) => {
        const request = {
            module: module,
            key: newRow.key,
            sourceLanguage: sourceLang,
            sourceLanguageValue: newRow.sourceLanguageValue,
            destinationLanguage: destinationLang,
            destinationLanguageValue: newRow.destinationLanguageValue,
        };

        return props.addLocalizationValueCall(request).then((newItem) => {
            if (newItem) {
                const localizationRecords = [newItem, ...props.localizationRecords];
                props.setValueTranslationsCall('localizationRecords', localizationRecords);
                return true;
            }
        });
    };

    const handlePublish = () => {
        props.publishTranslationsCall(module).then((isSuccess) => {
            isSuccess && toaster.success(t('translationManager.publishSuccess'));
        });
    };

    const handleRemoveKey = (key) => {
        const request = {
            module,
            key,
        };
        props.deleteLocalizationKeyCall(request).then((isSuccess) => {
            if (isSuccess) {
                const localizationRecords = [...props.localizationRecords.filter((x) => x.key !== key)];
                props.setValueTranslationsCall('localizationRecords', localizationRecords);
            }
        });
    };

    const handleChangeKey = (oldKey, newKey) => {
        const request = {
            module,
            oldKey,
            newKey,
        };
        props.updateLocalizationKeyCall(request).then((isSuccess) => {
            if (isSuccess) {
                const localizationRecords = [...props.localizationRecords];
                const index = localizationRecords.findIndex((x) => x.key === oldKey);
                if (index >= 0) {
                    const recordToUpdate = { ...localizationRecords[index] };
                    recordToUpdate.key = newKey;
                    localizationRecords[index] = recordToUpdate;
                    props.setValueTranslationsCall('localizationRecords', localizationRecords);
                }
            }
        });
    };

    const handleChangeTranslation = (data, language, value) => {
        const key = data.key;
        const request = {
            module,
            key,
            language,
            value,
        };

        return props.updateLocalizationValueCall(request).then((isSuccess) => {
            if (isSuccess) {
                const localizationRecords = [...props.localizationRecords];
                const index = localizationRecords.findIndex((x) => x.key === key);
                if (index >= 0) {
                    const recordToUpdate = { ...localizationRecords[index] };
                    if (language === destinationLang) {
                        recordToUpdate.destinationLanguageValue = value;
                        recordToUpdate.needUpdate = false;
                    } else {
                        recordToUpdate.sourceLanguageValue = value;
                        recordToUpdate.needUpdate = !!recordToUpdate.destinationLanguageValue;
                    }
                    localizationRecords[index] = recordToUpdate;
                    props.setValueTranslationsCall('localizationRecords', localizationRecords);
                }
            }
        });
    };

    const handleExport = () => {
        props.exportTranslationsCall(module).then((response) => {
            if (response) {
                const json = JSON.stringify(response, null, 4);
                const url = window.URL.createObjectURL(new Blob([json]));
                const link = window.document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'translations.json');
                window.document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        });
    };

    return (
        <MainLayout>
            {props.loading ? (
                <LoaderSpinner />
            ) : (
                <Row noGutters>
                    <Col md={12}>
                        <div className="translation-manager">
                            <div className="page-header">
                                <h3 className="mb-2">{t('translationManager.title')}</h3>
                                <div className="btns">
                                    <ActionButton onClick={handleExport} className="btn-secondary mr-2" text={t('translationManager.export')} loading={props.exportLoading} />
                                    <ActionButton
                                        onClick={handlePublish}
                                        className="btn-primary"
                                        text={t('translationManager.publish')}
                                        loading={props.publishLoading}
                                        icon={<UploadOutlined />}
                                    />
                                </div>
                            </div>
                            <NewTranslationRow sourceLang={sourceLang} destinationLang={destinationLang} loading={props.crudLoading} onSave={handleAddRow} />
                            <TranslationTable
                                module={module}
                                sourceLang={sourceLang}
                                destinationLang={destinationLang}
                                onRemove={handleRemoveKey}
                                onChangeKey={handleChangeKey}
                                onChangeTranslation={handleChangeTranslation}
                            />
                        </div>
                    </Col>
                </Row>
            )}
        </MainLayout>
    );
};

const mapState = ({ translations }) => {
    return {
        loading: get(translations, 'loading'),
        crudLoading: get(translations, 'crudLoading'),
        exportLoading: get(translations, 'exportLoading'),
        publishLoading: get(translations, 'publishLoading'),
        localizationRecords: get(translations, 'localizationRecords'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueTranslationsCall(key, value) {
            dispatch(setValueTranslations(key, value));
        },
        getAllLocalizationRecordsCall(module, sourceLang, destinationLang) {
            dispatch(getAllLocalizationRecords(module, sourceLang, destinationLang));
        },
        addLocalizationValueCall(data) {
            return dispatch(addLocalizationValue(data));
        },
        publishTranslationsCall(module) {
            return dispatch(publishTranslations(module));
        },
        updateLocalizationKeyCall(data) {
            return dispatch(updateLocalizationKey(data));
        },
        deleteLocalizationKeyCall(key) {
            return dispatch(deleteLocalizationKey(key));
        },
        updateLocalizationValueCall(data) {
            return dispatch(updateLocalizationValue(data));
        },
        exportTranslationsCall(module) {
            return dispatch(exportFlatTranslations(module));
        },
    };
};

const TranslationManager = clear(connect(mapState, mapDispatch)(TranslationManagerComponent));
export default TranslationManager;
