import get from 'lodash-es/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ActionButton from '../../../../components/action-button';
import { deleteContractOption, setValueContract } from '../../../../reducers/contractReducer';
import ContractOptionEditCard from './contract-option-edit-card';
import ContractOption from './modals/contract-option-modal';
import ConfirmDeleteContractOption from './modals/remove-contract-option-modal';
import { useParams } from 'react-router';

const ContractOptionsComponent = (props) => {
    const { t } = useTranslation();

    const { portfolioId, propertyId} = useParams();

    const openContractOptionOnDelete = () => {
        props.deleteContractOptionCall(props.contractOptionModel.id, props.contract.id, propertyId, portfolioId);
    };

    return (
        <div className="page-container pb-0">
            <h5 className="mb-3">{t('contract.contractOption.title')}</h5>
            {props.hasContractOptions &&
                props.contractOptions.map((option) => (
                    <>
                        <ContractOptionEditCard option={option} />
                    </>
                ))}
            <ActionButton className="btn-secondary mb-3" text={t('contract.contractOption.contractOptionsBtn')} onClick={() => props.openContractOptionModal()} />
            {props.showContractOptionEventModal && <ContractOption />}
            {props.showRemoveContractOptionModal && <ConfirmDeleteContractOption openContractOptionOnDelete={openContractOptionOnDelete} />}
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        showContractOptionEventModal: get(contract, 'showContractOptionEventModal'),
        showRemoveContractOptionModal: get(contract, 'showRemoveContractOptionModal'),
        contractOptions: get(contract, 'contractOptions'),
        hasContractOptions: get(contract, 'contractOptions.length') > 0,
        contractOptionModel: get(contract, 'contractOptionModel'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
        deleteContractOptionCall(id, contractId, propertyId, portfolioId) {
            dispatch(deleteContractOption(id, contractId, propertyId, portfolioId));
        },
        openContractOptionModal() {
            dispatch(setValueContract('showContractOptionEventModal', true));
        },
    };
};

const ContractOptions = connect(mapState, mapDispatch)(ContractOptionsComponent);
export default ContractOptions;
