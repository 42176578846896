import i18next from 'i18next';
import { getPortfoliosWithDetails } from './portfolioReducer';
import { StatusCodes } from 'http-status-codes';
import userService from '../services/userService';
import update from '../helpers/update';
import localStorageService from '../services/localStorageService';
import portfolioService from '../services/portfolioService';
import toaster from '../common/toaster';

const SET_VALUE_SETTINGS = 'SET_VALUE_SETTINGS';
const SET_ERROR_SETTINGS = 'SET_ERROR_SETTINGS';

const initialState = {
    userLoading: false,
    removeLoading: false,
    uploadItem: null,
    userNotFound: false,
};

const userSettingsReducer = (state = initialState, action) => {
    let newState = { ...state };
    switch (action.type) {
        case SET_VALUE_SETTINGS:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case SET_ERROR_SETTINGS:
            newState = update.set(newState, 'error', action.payload);
            break;
        default:
            break;
    }

    return newState;
};

export const setValueUserSettings = (key, value) => {
    return {
        type: SET_VALUE_SETTINGS,
        payload: {
            key,
            value,
        },
    };
};

const handleSettingsError = (error, dispatch) => {
    if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
        dispatch(setValueUserSettings('userNotFound', true));
        toaster.error(i18next.t('userSettings.notFound'), null);
    } else if (error && error.response && error.response.data && error.response.data.title) {
        toaster.error(i18next.t('errorMessages.' + error.response.data.title), null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const updateUser = (settings) => {
    return (dispatch) => {
        dispatch(setValueUserSettings('userLoading', true));
        return userService
            .updateUser(settings)
            .then((response) => {
                localStorageService.setUser(response.data);
                return response.data;
            })
            .catch((error) => {
                handleSettingsError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueUserSettings('userLoading', false));
            });
    };
};

export const removeUserFromPortfolio = (portfolioId) => {
    return (dispatch) => {
        dispatch(setValueUserSettings('removeLoading', true));
        portfolioService
            .removeUserFromPortfolio(portfolioId)
            .then((response) => {
                dispatch(getPortfoliosWithDetails());
            })
            .catch((error) => {
                if (error && error.response && error.response.status) {
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        toaster.error(i18next.t('settings.removePortfolioForbidden'), null);
                    } else if (error.response.status === StatusCodes.NOT_FOUND) {
                        toaster.error(i18next.t('settings.removePortfolioNotFound'), null);
                    }
                }
            })
            .finally(() => {
                dispatch(setValueUserSettings('removeLoading', false));
            });
    };
};

export const uploadPhoto = (file, options) => {
    return (dispatch) => {
        return userService
            .uploadPhoto(file, options)
            .then((res) => {
                dispatch(setValueUserSettings('uploadPhoto', res));
                return res;
            })
            .catch((error) => handleSettingsError(error, dispatch));
    };
};

export default userSettingsReducer;
