import React from 'react';
import { Button } from 'antd';
import XLSX from 'sheetjs-style';
import { useTranslation } from 'react-i18next';
import { emptyMark, invalidDate } from './rent-roll-table';
import helper from '../../../helpers/helper';
import { contractStatus, rentObjectRentalTypeOptions, warrantyTypeOptions } from '../../../common/contract-options';
import moment from 'moment';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import { calculateContractRentWithExemptions, calculateTotalRentMetersValue, calculateTotalRentUnitsValue, getValueByNestedProp } from './rent-roll-total-row';
import download from '../../../assets/images/download.svg';
import { Image } from 'react-bootstrap';

const cellColors = {
    purple: 'BE7AFF',
    red: 'F13F3F',
    default: '000',
    green: '00AE31',
    orange: 'F2994A',
    blue: '8091cf',
    gray: 'e7e6e6',
    yellow: 'ffff00',
};

export const calculateTotalPropSum = (contract, props) => {
    return contract.rentObjects.reduce((sum, element) => {
        for (let i = 0; i < props.length; i++) {
            const value = getValueByNestedProp(element, props[i]);
            if (value !== null) {
                sum += value;
                break;
            }
        }
        return sum;
    }, 0);
};

const ExportToExcel = ({ dataSource, propertyName, currentYear, disabled }) => {
    const { t } = useTranslation();

    const emptyRow = [];
    const borderBottomStyle = { bottom: { style: 'thin', color: { rgb: '000' } } };
    const titleCellStyle = { font: { bold: true, color: { rgb: '000' } }, fill: { fgColor: { rgb: cellColors.gray } }, border: borderBottomStyle, alignment: { wrapText: true } };
    const title = [
        { v: propertyName, s: { font: { bold: true }, fill: { fgColor: { rgb: cellColors.yellow } } } },
        { v: moment().format(primaryDateFormat), s: { font: { bold: true, color: { rgb: cellColors.red } } } },
        { v: `Current year - ${currentYear}`, s: { font: { bold: true, color: { rgb: cellColors.purple } } } },
    ];

    const titleRow = [
        { v: t('rentRoll.header.tenant'), s: titleCellStyle },
        { v: t('rentRoll.header.location'), s: titleCellStyle },
        { v: t('rentRoll.header.floor'), s: titleCellStyle },
        { v: t('rentRoll.header.m2'), s: titleCellStyle },
        { v: t('rentRoll.header.unit'), s: titleCellStyle },
        { v: t('rentRoll.header.rentM2'), s: titleCellStyle },
        { v: t('rentRoll.header.rentUnit'), s: titleCellStyle },
        { v: t('rentRoll.header.turnover'), s: titleCellStyle },
        { v: t('rentRoll.header.rent'), s: titleCellStyle },
        { v: t('rentRoll.header.yearlyRent'), s: titleCellStyle },
        { v: t('rentRoll.header.start'), s: titleCellStyle },
        { v: t('rentRoll.header.end'), s: titleCellStyle },
        { v: t('rentRoll.header.remaining'), s: titleCellStyle },
        { v: t('rentRoll.header.cpi'), s: titleCellStyle },
        { v: t('rentRoll.header.durationOprion'), s: titleCellStyle },
        { v: t('rentRoll.header.vat'), s: titleCellStyle },
        { v: t('rentRoll.header.warranty'), s: titleCellStyle },
    ];

    const getExcelCell = (value, mark, isNumber = true, style) => {
        if (!value) {
            return { v: mark, s: style };
        }

        const roundedValue = isNumber ? helper.numberWithSpaces(value?.toFixed(1)) : value;

        return { v: roundedValue, s: style };
    };

    const getCellColor = (rent, isContractExpiring, isContractStarting) => {
        if (rent?.turnoverId && !!rent?.turnover?.minimunRentYearlyId) {
            return cellColors.purple;
        }

        if (isContractExpiring) {
            return cellColors.red;
        }

        if (isContractStarting) {
            return cellColors.green;
        }

        return cellColors.default;
    };

    const getTotalRow = (contract, isContractExpiring, isContractStarting) => {
        return [
            getExcelCell(contract?.name, emptyMark.hyphen, false, { font: { bold: true, color: { rgb: getCellColor(null, isContractExpiring, isContractStarting) } } }),
            {},
            {},
            getExcelCell(calculateTotalPropSum(contract, ['squareMeters']), emptyMark.hyphen, true, {
                font: { bold: true, color: { rgb: getCellColor(null, isContractExpiring, isContractStarting) } },
            }),
            getExcelCell(calculateTotalPropSum(contract, ['units']), emptyMark.hyphen, true, {
                font: { bold: true, color: { rgb: getCellColor(null, isContractExpiring, isContractStarting) } },
            }),
            getExcelCell(calculateTotalRentMetersValue(contract, calculateTotalPropSum(contract, ['rentSquareMetersCpiAdjusted.currentRentCpiAdjusted', 'rentSquareMeters'])), emptyMark.hyphen, true, { font: { bold: true } }),
            getExcelCell(calculateTotalRentUnitsValue(contract, calculateTotalPropSum(contract, ['rentUnitsCpiAdjusted.currentRentCpiAdjusted', 'rentUnits'])), emptyMark.hyphen, true, { font: { bold: true } }),
            getExcelCell(null, emptyMark.hyphen, false, { font: { bold: true } }),
            getExcelCell(calculateContractRentWithExemptions(contract), emptyMark.hyphen, true, {
                font: { bold: true, color: { rgb: getCellColor(null, isContractExpiring, isContractStarting) } },
            }),
            getExcelCell(calculateTotalPropSum(contract, ['contractedRentByYears.yearlyRentPaymentPlanItem.fullRentAmount']), emptyMark.hyphen, true, {
                font: { bold: true, color: { rgb: getCellColor(null, isContractExpiring, isContractStarting) } },
            }),
            getExcelCell(moment(contract?.leaseStartDate).format(primaryDateFormat), emptyMark.hyphen, false, {
                font: { bold: true, color: { rgb: isContractStarting ? cellColors.green : cellColors.default } },
            }),
            getExcelCell(moment(contract?.leaseEndDate).format(primaryDateFormat), emptyMark.hyphen, false, {
                font: { bold: true, color: { rgb: isContractExpiring ? cellColors.red : cellColors.default } },
            }),
            getExcelCell(contract?.remaining, emptyMark.hyphen, true, { font: { bold: true } }),
            getExcelCell(contract?.hasRentAdjustmentOptions && `${contract?.cpiRate}%`, emptyMark.hyphen, false, { font: { bold: true } }),
            getExcelCell(t('rentRoll.mix'), emptyMark.hyphen, false, { font: { bold: true } }),
            getExcelCell(t('rentRoll.mix'), emptyMark.hyphen, false, { font: { bold: true } }),
            getExcelCell(
                contract?.contractWarranties?.length > 0 && helper.getEnumValue(warrantyTypeOptions, contract?.contractWarranties[0]?.warrantyTypeId),
                t('rentRoll.mix'),
                false,
                { font: { bold: true } }
            ),
        ];
    };

    const getTotalSection = () => {
        const rentObjectsTotal = dataSource?.rentObjectsTotal;
        return [
            [
                getExcelCell(`${t('rentRoll.totalSection.running')} ${t('rentRoll.totalSection.activeOnly')} ${moment().format(primaryDateFormat)}`, emptyMark.hyphen, false, {
                    font: { bold: true, color: { rgb: cellColors.blue } },
                }),
            ],
            [
                {},
                {},
                {},
                getExcelCell(t('rentRoll.totalSection.m2'), emptyMark.hyphen, false, { font: { bold: true } }),
                getExcelCell(t('rentRoll.totalSection.units'), emptyMark.hyphen, false, { font: { bold: true } }),
                getExcelCell(t('rentRoll.totalSection.rentM2'), emptyMark.hyphen, false, { font: { bold: true } }),
                getExcelCell(t('rentRoll.totalSection.rentUnits'), emptyMark.hyphen, false, { font: { bold: true } }),
                {},
                getExcelCell(t('rentRoll.totalSection.rent2023'), emptyMark.hyphen, false, { font: { bold: true } }),
                getExcelCell(t('rentRoll.totalSection.contractedYerlyRent'), emptyMark.hyphen, false, { font: { bold: true } }),
                getExcelCell(t('rentRoll.totalSection.wault'), emptyMark.hyphen, false, { font: { bold: true } }),
            ],
            [
                getExcelCell(t('rentRoll.totalSection.currentTotal'), emptyMark.hyphen, false, { font: { bold: true } }),
                {},
                {},
                getExcelCell(rentObjectsTotal?.currentTotalUnits?.squareMeters, emptyMark.hyphen, true),
                getExcelCell(rentObjectsTotal?.currentTotalUnits?.units, emptyMark.hyphen, true),
                getExcelCell(rentObjectsTotal?.currentTotalRented?.squareMeters, emptyMark.hyphen, true),
                getExcelCell(rentObjectsTotal?.currentTotalRented?.units, emptyMark.hyphen, true),
                getExcelCell(t('rentRoll.totalSection.m2'), emptyMark.hyphen, false, { font: { bold: true } }),
                getExcelCell(rentObjectsTotal?.rentByYears?.squareMeters, emptyMark.hyphen, true),
                getExcelCell(rentObjectsTotal?.contractedRentByYears?.squareMeters, emptyMark.hyphen, true),
                getExcelCell(`${rentObjectsTotal?.wault} years`, emptyMark.hyphen, false),
            ],
            [
                getExcelCell(t('rentRoll.totalSection.vacantBta'), emptyMark.hyphen, false, { border: borderBottomStyle, font: { bold: true } }),
                getExcelCell('', emptyMark.empty, false, { border: borderBottomStyle }),
                getExcelCell('', emptyMark.empty, false, { border: borderBottomStyle }),
                getExcelCell(rentObjectsTotal?.vacancy?.vacancy, emptyMark.hyphen, true, { border: borderBottomStyle }),
                getExcelCell('', emptyMark.empty, false, { border: borderBottomStyle }),
                getExcelCell('', emptyMark.empty, false, { border: borderBottomStyle }),
                getExcelCell('', emptyMark.empty, false, { border: borderBottomStyle }),
                getExcelCell(t('rentRoll.totalSection.units'), emptyMark.hyphen, false, { border: borderBottomStyle, font: { bold: true } }),
                getExcelCell(rentObjectsTotal?.rentByYears?.units, emptyMark.hyphen, true, { border: borderBottomStyle }),
                getExcelCell(rentObjectsTotal?.contractedRentByYears?.units, emptyMark.hyphen, true, { border: borderBottomStyle }),
                getExcelCell('', emptyMark.empty, false, { border: borderBottomStyle }),
                getExcelCell('', emptyMark.empty, false, { border: borderBottomStyle }),
            ],
            [
                getExcelCell(t('rentRoll.totalSection.TotalBta'), emptyMark.hyphen, false, { font: { bold: true } }),
                {},
                {},
                getExcelCell(rentObjectsTotal?.currentTotalUnits?.squareMeters + rentObjectsTotal?.vacancy?.vacancy, emptyMark.hyphen, true),
                {},
                {},
                {},
                {},
                getExcelCell(rentObjectsTotal?.rentByYears?.squareMeters + rentObjectsTotal?.rentByYears?.units, emptyMark.hyphen, true),
                getExcelCell(rentObjectsTotal?.contractedRentByYears?.squareMeters + rentObjectsTotal?.contractedRentByYears?.units, emptyMark.hyphen, true),
            ],
        ];
    };

    const handleDownload = async () => {
        const wb = XLSX.utils.book_new();

        const tableData = dataSource?.tenants
            ?.map((tenant) => {
                return tenant?.contracts?.map((contract) => {
                    const isContractExpiring = moment(contract?.leaseEndDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'));
                    const isContractStarting = moment(contract?.leaseStartDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'));
                    const isMinimumRent = contract?.rentObjects?.some((rent) => rent?.turnoverId && !!rent?.turnover?.minimunRentYearlyId);

                    return [
                        getTotalRow(contract, isContractExpiring, isContractStarting),
                        ...contract?.rentObjects?.map((rentObject, index, array) => {
                            const isLastElement = index === array.length - 1;

                            const isRentObjectExpiring = moment(rentObject?.leaseEndDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'));
                            const isRentObjectStarting = moment(rentObject?.leaseStartDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'));

                            return [
                                getExcelCell(helper.getEnumValue(rentObjectRentalTypeOptions, rentObject?.rentObjectRentalTypeId), emptyMark.hyphen, false, {
                                    border: isLastElement ? borderBottomStyle : {},
                                }),
                                getExcelCell(rentObject?.buildingId ? rentObject?.building?.address : rentObject?.plot?.address, emptyMark.hyphen, false, {
                                    border: isLastElement ? borderBottomStyle : {},
                                }),
                                getExcelCell(
                                    rentObject?.buildingId &&
                                        rentObject?.building?.floors
                                            ?.filter((floor) => rentObject?.assignedFloorIds?.includes(floor.id))
                                            ?.map((item) => item?.level)
                                            .join(', '),
                                    emptyMark.hyphen,
                                    false,
                                    {
                                        border: isLastElement ? borderBottomStyle : {},
                                    }
                                ),
                                getExcelCell(rentObject?.squareMeters, emptyMark.hyphen, true, {
                                    font: { color: { rgb: getCellColor(rentObject, isContractExpiring, isContractStarting) } },
                                    border: isLastElement ? borderBottomStyle : {},
                                }),
                                getExcelCell(rentObject?.units, emptyMark.hyphen, true, {
                                    font: { color: { rgb: getCellColor(rentObject, isContractExpiring, isContractStarting) } },
                                    border: isLastElement ? borderBottomStyle : {},
                                }),
                                getExcelCell(rentObject?.rentSquareMeters, emptyMark.hyphen, true, {
                                    border: isLastElement ? borderBottomStyle : {},
                                }),
                                getExcelCell(rentObject?.rentUnits, emptyMark.hyphen, true, {
                                    border: isLastElement ? borderBottomStyle : {},
                                }),
                                getExcelCell(
                                    rentObject?.turnoverId && rentObject?.turnover?.turnoverRates?.map((item) => `${item?.rate}%`)?.join(' | '),
                                    emptyMark.notApplicable,
                                    false,
                                    {
                                        border: isLastElement ? borderBottomStyle : {},
                                    }
                                ),
                                getExcelCell(rentObject?.rentByYears?.yearlyRentPaymentPlanItem?.fullRentAmount, emptyMark.hyphen, true, {
                                    font: { color: { rgb: getCellColor(rentObject, isContractExpiring, isContractStarting) } },
                                    border: isLastElement ? borderBottomStyle : {},
                                }),
                                getExcelCell(rentObject?.contractedRentByYears?.yearlyRentPaymentPlanItem?.fullRentAmount, emptyMark.hyphen, true, {
                                    font: { color: { rgb: getCellColor(rentObject, isContractExpiring, isContractStarting) } },
                                    border: isLastElement ? borderBottomStyle : {},
                                }),
                                getExcelCell(
                                    rentObject?.leaseStartDate && !moment(rentObject?.leaseStartDate).isSame(invalidDate)
                                        ? moment(rentObject?.leaseStartDate).format(primaryDateFormat)
                                        : moment(contract?.leaseStartDate).format(primaryDateFormat),
                                    emptyMark.hyphen,
                                    false,
                                    {
                                        font: {
                                            color: {
                                                rgb: (rentObject?.leaseStartDate && !moment(rentObject?.leaseStartDate).isSame(invalidDate) ? isRentObjectStarting : isContractStarting)
                                                    ? cellColors.green
                                                    : cellColors.default,
                                            },
                                        },
                                        border: isLastElement ? borderBottomStyle : {},
                                    }
                                ),
                                getExcelCell(
                                    rentObject?.leaseEndDate && !moment(rentObject?.leaseEndDate).isSame(invalidDate)
                                        ? moment(rentObject?.leaseEndDate).format(primaryDateFormat)
                                        : moment(contract?.leaseEndDate).format(primaryDateFormat),
                                    emptyMark.hyphen,
                                    false,
                                    {
                                        font: {
                                            color: {
                                                rgb: (rentObject?.leaseEndDate && !moment(rentObject?.leaseEndDate).isSame(invalidDate) ? isRentObjectExpiring : isContractExpiring)
                                                    ? cellColors.red
                                                    : cellColors.default,
                                            },
                                        },
                                        border: isLastElement ? borderBottomStyle : {},
                                    }
                                ),
                                contract?.status == contractStatus.terminated
                                    ? getExcelCell(`Term. ${moment(contract?.terminatedDate).format(primaryDateFormat)}`, emptyMark.hyphen, false, {
                                          font: { color: { rgb: cellColors.red } },
                                          border: isLastElement ? borderBottomStyle : {},
                                      })
                                    : getExcelCell(contract?.remaining ? contract?.remaining : t('rentRoll.expired'), emptyMark.hyphen, !!contract?.remaining, {
                                          font: { color: { rgb: contract?.remaining ? cellColors.default : cellColors.red } },
                                          border: isLastElement ? borderBottomStyle : {},
                                      }),
                                getExcelCell(
                                    rentObject?.cpiRate ? `${rentObject?.cpiRate}%` : contract?.hasRentAdjustmentOptions && `${contract?.cpiRate}%`,
                                    emptyMark.hyphen,
                                    false,
                                    {
                                        border: isLastElement ? borderBottomStyle : {},
                                    }
                                ),
                                getExcelCell(null, emptyMark.empty, false, {
                                    border: isLastElement ? borderBottomStyle : {},
                                }),
                                getExcelCell(rentObject?.hasVatCalculation ? t('common.yes') : t('common.no'), emptyMark.hyphen, false, {
                                    border: isLastElement ? borderBottomStyle : {},
                                }),
                                getExcelCell(
                                    rentObject?.contractWarranties?.length > 0
                                        ? helper.getEnumValue(warrantyTypeOptions, rentObject?.contractWarranties[0]?.warrantyTypeId)
                                        : helper.getEnumValue(warrantyTypeOptions, contract?.contractWarranties[0]?.warrantyTypeId),
                                    emptyMark.hyphen,
                                    false,
                                    {
                                        border: isLastElement ? borderBottomStyle : {},
                                    }
                                ),
                            ];
                        }),
                        [
                            isContractExpiring
                                ? getExcelCell(`* ${t('rentRoll.durationEnd')} ${moment(contract?.leaseEndDate).format(primaryDateFormat)}`, emptyMark.empty, false, {
                                      font: { color: { rgb: cellColors.red } },
                                  })
                                : undefined,
                            isContractStarting
                                ? getExcelCell(`* ${t('rentRoll.durationStart')} ${moment(contract?.leaseEndDate).format(primaryDateFormat)}`, emptyMark.empty, false, {
                                      font: { color: { rgb: cellColors.green } },
                                  })
                                : undefined,
                            {},
                            isMinimumRent
                                ? getExcelCell(`* ${t('rentRoll.minimumRentOnly')}`, emptyMark.empty, false, {
                                      font: { color: { rgb: cellColors.purple } },
                                  })
                                : undefined,
                        ].filter((item) => item !== undefined),
                        [],
                        ...contract?.rentObjects
                            ?.map((rentObject) => {
                                return rentObject?.rentByYears?.rentPaymentPlanExemptionItems?.map((item) => {
                                    return [
                                        getExcelCell(
                                            `${t('rentRoll.exemptionAmount')} ${moment(item?.startDate).format(primaryDateFormat)} - ${moment(item?.endDate).format(
                                                primaryDateFormat
                                            )} - ${item?.exemptionAmount?.toFixed(1)} (${helper.getEnumValue(rentObjectRentalTypeOptions, rentObject?.rentObjectRentalTypeId)})`,
                                            emptyMark.empty,
                                            false,
                                            {
                                                font: { color: { rgb: cellColors.orange } },
                                            }
                                        ),
                                    ];
                                });
                            })
                            .flat(1),
                    ];
                });
            })
            .flat(2);

        const columnWidths = [
            { width: 20 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 10 },
            { width: 10 },
            { width: 10 },
            { width: 10 },
            { width: 10 },
        ];

        const ws = XLSX.utils.aoa_to_sheet([emptyRow, title, emptyRow, titleRow, ...tableData, emptyRow, ...getTotalSection()]);
        ws['!cols'] = columnWidths;
        ws['!rows'] = [
            {},
            { autoRowHeight: true },
            { autoRowHeight: true },
            { autoRowHeight: true },
            { autoRowHeight: true },
            { autoRowHeight: true },
            { autoRowHeight: true },
            { autoRowHeight: true },
        ];

        XLSX.utils.book_append_sheet(wb, ws, `${propertyName} ${moment().format(primaryDateFormat)}`.substr(0, 30));

        XLSX.writeFile(wb, `${propertyName.substr(0, 23).replace(/ /g, '-').replace(/[\\/?*\[\]]/g, ' ')}_${moment().format(primaryDateFormat)}.xlsx`);
    };

    return (
        <Button className="btn-secondary" onClick={handleDownload} style={{ marginRight: '5px' }} disabled={disabled}>
            <Image src={download} />
            {t('rentRoll.download.excel')}
        </Button>
    );
};

export default ExportToExcel;
