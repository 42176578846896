import i18next from 'i18next';

export const invoiceStatuses = {
    new: 1,
    sent: 2,
    paid: 3,
    overdue: 4,
};

export const invoiceStatusesOptions = [
    { id: invoiceStatuses.new, name: () => i18next.t('backoffice.invoices.statuses.new') },
    { id: invoiceStatuses.sent, name: () => i18next.t('backoffice.invoices.statuses.sent') },
    { id: invoiceStatuses.paid, name: () => i18next.t('backoffice.invoices.statuses.paid') },
    { id: invoiceStatuses.overdue, name: () => i18next.t('backoffice.invoices.statuses.overdue') },
];
