import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Form } from 'antd';
import { createProperty, editProperty, setValueProperty, getPropertyById } from '../../../reducers/propertyReducer';
import ObjectLayout from '../../../components/layouts/object-layout';
import NotFoundPage from '../../../components/error-page';
import LeaveModal from '../../../components/leave-modal';
import PropertyReview from './review-step';
import PropertyOwner from './owner-step';
import PropertyName from './name-step';
import propertyOwner from '../../../assets/images/new-design-portfolio-owner-icon.svg';
import propertyName from '../../../assets/images/new-design-property-icon.svg';
import review from '../../../assets/images/review.svg';
import objectHelper from '../../../helpers/trim-object';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import moment from 'moment';
import routingService from '../../../services/routingService';
import { propertyStatuses } from '../../../common/property-options';

const CreateEditProperty = (props) => {
    const { t } = useTranslation();
    const { propertyId, portfolioId } = useParams();

    const stepNames = {
        name: 'name',
        details: 'details',
        review: 'review',
    };

    const [currentStepKey, setCurrentStepKey] = useState(stepNames.name);
    const [timelineSteps, setTimelineSteps] = useState([]);
    const timelineStepsRef = useRef([]);

    useEffect(() => {
        mapTimelineSteps(steps, isStepCompleted, setTimelineSteps);
        if (portfolioId && propertyId) {
            props.getPropertyByIdCall(propertyId, portfolioId);
        }
    }, []);

    useEffect(() => {
        mapTimelineSteps(steps, isStepCompleted, setTimelineSteps);
    }, [props.property]);

    const mapTimelineSteps = () => {
        const timelineSteps = steps.map((x) => ({
            key: x.key,
            stepTitle: x.stepTitle,
            isCompleted: isStepCompleted(x.key),
            canSkip: x.canSkip,
        }));

        setTimelineSteps(timelineSteps);
        timelineStepsRef.current = timelineSteps;
    };

    const isStepCompleted = (stepKey) => {
        if (!props.property) {
            return false;
        }

        switch (stepKey) {
            case stepNames.name:
                return props.property && props.property.propertyName;
            case stepNames.details:
                return !!props.property.owner;
        }

        return false;
    };

    const nameRef = React.useRef();
    const ownerRef = React.useRef();

    const getDataFromNameForm = () => {
        const values = nameRef.current.getFieldsValue();
        const name = objectHelper.trimObjectProperties(values);
        return name;
    };

    const getDataFromOwnerForm = () => {
        const values = ownerRef.current.getFieldsValue();
        const owner = objectHelper.trimObjectProperties(values);
        return owner;
    };

    const validateNameStep = () => {
        return nameRef.current.validateFields();
    };

    const validateOwnerStep = () => {
        return ownerRef.current.validateFields();
    };

    const onStepChanging = () => {
        switch (currentStepKey) {
            case stepNames.name:
                return validateNameStep()
                    .then((_) => {
                        const property = getDataFromNameForm();

                        props.setValuePropertyCall('property.propertyName', property.propertyName);
                        props.setValuePropertyCall('property.propertyStatusId', property.propertyStatusId);
                        propertyStatuses.active == property.propertyStatusId && props.setValuePropertyCall('property.takeoverDate', moment(property.takeoverDate).startOf('day').utc(true));
                        propertyStatuses.archive == property.propertyStatusId && props.setValuePropertyCall('property.archiveFrom', moment(property.archiveFrom).startOf('day').utc(true));

                        return Promise.resolve(true);
                    })
                    .catch((_) => {});
            case stepNames.details:
                return validateOwnerStep()
                    .then((_) => {
                        const owner = getDataFromOwnerForm();
                        if (propertyId) {
                            owner.propertyId = props.property.id;
                        }
                        if (props.property.owner) {
                            owner.id = props.property.owner.id;
                            if (owner.phone && owner.phone[0] !== '+') {
                                owner.phone = `+${owner.phone}`;
                            }
                        }
                        props.setValuePropertyCall('property.owner', owner);

                        return Promise.resolve(true);
                    })
                    .catch((_) => {});
            default:
                return Promise.resolve(true);
        }
    };

    const created = (property) => {
        if (property) {
            goOutOfPage(property.propertyId, property.propertyName);
            props.showPropertyCreationResult();
        }
    };

    const updated = (property) => {
        if (property) {
            goOutOfPage(property.propertyId, property.propertyName);
            props.showPropertyUpdatedResult();
        }
    };

    const onSaveAndExitOnEdit = () => {
        const storeProperty = props.property;
        if (currentStepKey === stepNames.name) {
            validateNameStep()
                .then(() => {
                    const property = getDataFromNameForm();
                    storeProperty.propertyName = property.propertyName;
                    storeProperty.propertyStatusId = property.propertyStatusId;
                    storeProperty.takeoverDate = propertyStatuses.active == property.propertyStatusId ? property.takeoverDate : undefined;
                    storeProperty.archiveFrom = propertyStatuses.archive == property.propertyStatusId ? property.archiveFrom : undefined;
                    props.editPropertyCall(storeProperty).then((propertyId) => updated(propertyId));
                })
                .catch((_) => {});
        } else if (currentStepKey === stepNames.details) {
            validateOwnerStep()
                .then(() => {
                    const owner = getDataFromOwnerForm();
                    owner.id = props.property.owner.id;
                    storeProperty.owner = owner;
                    props.editPropertyCall(storeProperty).then((propertyId) => updated(propertyId));
                })
                .catch((_) => {});
        } else if (currentStepKey === stepNames.review) {
            props.editPropertyCall(props.property).then((propertyId) => updated(propertyId));
        }
    };

    const onSaveAndExitOnCreate = () => {
        const property = objectHelper.trimObjectProperties(props.property);
        property.portfolioId = +portfolioId;
        props.createPropertyCall(property).then((property) => created(property));
    };

    const onSaveAndExit = () => {
        if (propertyId) {
            onSaveAndExitOnEdit();
        } else {
            onSaveAndExitOnCreate();
        }
    };

    const onSkipOwnerStep = () => {
        setCurrentStepKey(stepNames.review);

        if (!props.property.owner || !props.property.owner.id) {
            props.setValuePropertyCall('property.owner', null);
            props.setValuePropertyCall('property.usePortfolioOwner', false);
        }
    };

    const onNext = () => {
        const currentStepIndex = timelineSteps.findIndex((x) => x.key === currentStepKey);
        const nextStep = timelineSteps[currentStepIndex + 1];

        if (!nextStep) {
            return;
        }

        onStepChanging().then((isSuccess) => isSuccess && onChangeStep(nextStep));
    };

    const onPrevious = (previousStep) => {
        if (previousStep) {
            setCurrentStepKey(previousStep.key);
        } else {
            const currentStepIndex = steps.findIndex((x) => x.key === currentStepKey);
            const previousStep = steps[currentStepIndex - 1];
            previousStep && setCurrentStepKey(previousStep.key);
        }
    };

    const onChangeStep = (newStep) => {
        const timelineStepsByRef = timelineStepsRef.current;
        const newIndex = timelineStepsByRef.findIndex((x) => x.key === newStep.key);
        const previousSteps = timelineStepsByRef.slice(0, newIndex);
        const isPreviousStepsAllCompleted = previousSteps.every((x) => x.isCompleted || x.canSkip);

        if (isPreviousStepsAllCompleted) {
            setCurrentStepKey(newStep.key);
        } else {
            const firstInCompletedStep = timelineStepsByRef.find((x) => !x.isCompleted || x.canSkip);
            firstInCompletedStep && setCurrentStepKey(firstInCompletedStep.key);
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious(newStep);
        } else {
            onStepChanging().then((isSuccess) => isSuccess && onChangeStep(newStep));
        }
    };

    const onOpenCloseDraftModal = () => {
        props.setValuePropertyCall('showDraftModal', !props.showDraftModal);
    };

    const goOutOfPage = (propertyId, propertyName) => {
        routingService.navigateToProperties(portfolioId, { propertyId: propertyId, propertyName: propertyName });
    };

    const steps = [
        {
            key: stepNames.name,
            header: t('property.name.header', { name: props.user.firstName }),
            stepTitle: t('property.name.stepTitle'),
            logo: propertyName,
            content: (
                <Form name="basic" autoComplete="off" ref={nameRef}>
                    <PropertyName nameRef={nameRef} propertyId={propertyId}/>
                </Form>
            ),
        },
        {
            key: stepNames.details,
            header: t('property.owner.header'),
            stepTitle: t('property.owner.stepTitle'),
            logo: propertyOwner,
            canSkip: true,
            onSkip: onSkipOwnerStep,
            content: (
                <Form name="basic" autoComplete="off" ref={ownerRef}>
                    <PropertyOwner ownerRef={ownerRef} />
                </Form>
            ),
        },
        {
            key: stepNames.review,
            header: t('property.review.header'),
            stepTitle: t('property.review.stepTitle'),
            logo: review,
            content: <PropertyReview goToStep={setCurrentStepKey} />,
        },
    ];

    return (
        <React.Fragment>
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {!props.portfolioNotFound && !props.propertyNotFound ? (
                <ObjectLayout
                    steps={steps}
                    timelineSteps={timelineSteps}
                    isEdit={propertyId}
                    loading={props.propertyLoading}
                    currentStepKey={currentStepKey}
                    exitUrl={routingService.propertiesUrl(portfolioId)}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onSwitchStep={onSwitchStep}
                    onSaveAndExit={onSaveAndExit}
                    onCancel={onOpenCloseDraftModal}
                />
            ) : null}
            {props.showDraftModal && <LeaveModal onCancel={onOpenCloseDraftModal} onLeave={goOutOfPage} onSave={onSaveAndExit} loading={props.propertyLoading} />}
        </React.Fragment>
    );
};

const mapState = ({ property, portfolio, common }) => {
    return {
        user: get(common, 'user'),
        property: get(property, 'property'),
        showDraftModal: get(property, 'showDraftModal'),
        propertyLoading: get(property, 'propertyLoading'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        getPropertyByIdCall(propertyId, portfolioId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        createPropertyCall(values) {
            return dispatch(createProperty(values));
        },
        editPropertyCall(values) {
            return dispatch(editProperty(values));
        },
        setValuePropertyCall(key, value) {
            dispatch(setValueProperty(key, value));
        },
        showPropertyCreationResult() {
            dispatch(setValueProperty('showCreationResultModal', true));
        },
        showPropertyUpdatedResult() {
            dispatch(setValueProperty('showItemUpdatedResultModal', true));
        },
    };
};

const Property = clear(connect(mapState, mapDispatch)(CreateEditProperty));
export default Property;
