import React from 'react';
import { useTranslation } from 'react-i18next';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import { paymentPlan } from '../../../../common/contract-options';
import moment from 'moment';
import PropertyRow from '../../../../components/property-row';
import helper from '../../../../helpers/helper';

const InvoiceTitle = ({ invoice, index, rentObject }) => {
    const { t } = useTranslation();

    const getQuater = () => {
        const quarter = moment(invoice.invoicePeriodStartDate).utc(true).quarter();

        return quarter === 4 ? '4th' : quarter === 3 ? '3rd' : quarter === 2 ? '2nd' : '1st';
    };

    return (
        <div className="invoice-title">
            <h5 className="mr-3 mb-0">{t('contract.rentObjectCalculations.invoice') + ` ${index}`}</h5>
            {rentObject?.paymentPlan === paymentPlan?.quarterly ? (
                <div className="d-flex flex-row">
                    <p className="text-secondary">{getQuater()}</p>
                    <p className="mx-1 text-secondary">{t('common.quarter')}</p>
                    <p className="text-secondary">{moment(invoice.invoicePeriodStartDate).format('YYYY')}</p>
                </div>
            ) : rentObject?.paymentPlan === paymentPlan?.monthly ? (
                <div className="d-flex flex-row">
                    <p className="text-secondary">{moment(invoice.invoicePeriodStartDate).format('MMM YYYY')}</p>
                </div>
            ) : (
                <p className="text-secondary">{moment(invoice.invoicePeriodStartDate).format('YYYY')}</p>
            )}
        </div>
    );
};

const InvoiceInfo = ({ invoice, rentObject }) => {
    const { t } = useTranslation();

    return (
        <div className="invoice-info">
            <div className="invoice-data">
                <PropertyRow title={t('contract.rentObjectCalculations.orderDate')} value={moment(invoice.invoiceIssueDate).format(primaryDateFormat)} />
                <PropertyRow title={t('contract.rentObjectCalculations.dueDate')} value={moment(invoice.invoicePeriodStartDate).format(primaryDateFormat)} />
                <PropertyRow
                    title={t('contract.rentObjectCalculations.originalAmount')}
                    value={invoice.priceByPaymentPeriod ? helper.numberWithTwoDigits(invoice.priceByPaymentPeriod) : '-'}
                />
                <PropertyRow
                    title={t('contract.rentObjectCalculations.runningCpiAmount')}
                    value={invoice.priceByPaymentPeriodRegulatedByCpi ? helper.numberWithTwoDigits(invoice.priceByPaymentPeriodRegulatedByCpi) : '-'}
                />
                <PropertyRow
                    title={t('contract.rentObjectCalculations.commonCost')}
                    value={invoice.commonCostAmount ? helper.numberWithTwoDigits(invoice.commonCostAmount) : '-'}
                />
                <PropertyRow
                    title={t('contract.rentObjectCalculations.originalBaseIndex')}
                    value={invoice.baseCpiIndexDate ? moment(invoice.baseCpiIndexDate).format('MMMM YYYY') : '-'}
                />
                <PropertyRow
                    title={t('contract.rentObjectCalculations.nextCpiIndex')}
                    value={invoice.nextInvoiceCpiIndexDate ? moment(invoice.nextInvoiceCpiIndexDate).format('MMMM YYYY') : '-'}
                />
                <PropertyRow
                    title={t('contract.rentObjectCalculations.indexAdjustment')}
                    value={<span className="uppercase">{invoice.shouldApplyCpiRegulation ? t('common.yes') : t('common.no')}</span>}
                />
                <PropertyRow
                    title={t('contract.rentObjectCalculations.indexCalculation')}
                    value={
                        invoice.shouldApplyCpiRegulation && invoice.baseCpiIndexDate && invoice.currentInvoiceCpiIndexDate ? (
                            <span className="text-danger">
                                {rentObject?.pricePerYearPerUnit}
                                {' / '}
                                {moment(invoice.baseCpiIndexDate).format('MMM YY')} ({invoice.baseCpiIndexValue}){' * '}
                                {moment(invoice.currentInvoiceCpiIndexDate).format('MMM YY')} ({invoice.currentInvoiceCpiIndexValue})
                            </span>
                        ) : (
                            <span>-</span>
                        )
                    }
                />
            </div>
            <div className="rent-object-data">
                <PropertyRow
                    title={t('contract.rentObjectCalculations.priceRentobject')}
                    value={invoice.priceByPaymentPeriodRegulatedByCpi ? helper.numberWithTwoDigits(invoice.priceByPaymentPeriodRegulatedByCpi) : '-'}
                />
                <PropertyRow
                    title={t('contract.rentObjectCalculations.unitsAmount')}
                    value={rentObject && rentObject?.amount ? helper.numberWithTwoDigits(rentObject.amount) : '-'}
                />
                <PropertyRow
                    title={t('contract.rentObjectCalculations.rentalAmount')}
                    value={invoice.rentalAmount ? helper.numberWithTwoDigits(invoice.rentalAmount) : '-'}
                />
            </div>
        </div>
    );
};

export const InvoiceCard = (props) => {
    return (
        <div className="invoice-card details-info">
            <InvoiceTitle {...props} />
            <InvoiceInfo {...props} />
        </div>
    );
};
