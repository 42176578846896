import { Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { supportedLanguages } from '../../services/languageService';

const { Option } = Select;

const LanguageSwitcherComponent = (props) => {
    const { t } = useTranslation();

    return (
        <Select {...props} getPopupContainer={(triggerNode) => triggerNode.parentElement}>
            <Option key={supportedLanguages.English} value={supportedLanguages.English}>
                <span className="flag-icon en mr-1"></span>
                {t('languages.en')}
            </Option>
            <Option key={supportedLanguages.Norway} value={supportedLanguages.Norway}>
                <span className="flag-icon no mr-1"></span>
                {t('languages.no')}
            </Option>
        </Select>
    );
};

const LanguageSwitcher = LanguageSwitcherComponent;
export default LanguageSwitcher;
