import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moreIcon from '../../assets/images/more.svg';
import plot from '../../assets/images/new-plot-card-icon.svg';
import TextEllipsis from '../ellipsis-text';
import DeleteMenuItem from '../menu-items/delete-menu-item';
import EditMenuItem from '../menu-items/edit-menu-item';

const PlotCard = (props) => {
    const { t } = useTranslation();

    const openPlot = () => {
        props.openPlot(props.plot.id);
    };

    const editPlot = () => {
        if (props.editPlot) {
            props.editPlot(props.plot);
        }
    };

    const deletePlot = () => {
        if (props.deletePlot) {
            props.deletePlot(props.plot);
        }
    };

    const dropdownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" onClick={editPlot} disabled={!props.hasEditPermission || props.isArchived} text={t('plot.editPlot')} />
                <DeleteMenuItem key="1" onClick={deletePlot} disabled={!props.hasEditPermission || props.isArchived} text={t('plot.deletePlot')} />
            </Menu>
        );
    };

    return (
        <div className="entity-card card-wrapper">
            <div className="image" onClick={openPlot}>
                <Image src={plot} />
            </div>
            <div className="card-content">
                <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                    <TextEllipsis>
                        {props.plot.name ? (
                            <React.Fragment>
                                <h6 className="ellipsis">{props.plot.name}</h6>
                                <p className="mb-0">{props.plot.mainAddress}</p>
                            </React.Fragment>
                        ) : (
                            <h6 className="ellipsis">{props.plot.mainAddress}</h6>
                        )}
                    </TextEllipsis>
                    <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu()}>
                        <Button className="btn-link btn-transparent settings-btn">
                            <Image src={moreIcon} />
                        </Button>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default PlotCard;
