import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import CommonCostRentalObjectCard from './rent-object-edit-card';
import { updatePreview } from '../../../../reducers/contractReducer';
import { Collapse } from 'antd';

const ContractCommonCostComponent = (props) => {
    const { t } = useTranslation();

    const [activeKeys, setActiveKeys] = useState(props.rentObjects.map(item => item.id));

    return (
        <React.Fragment>
            <div className="page-container edit-rent-part">
                <h5 className="mb-4">{t('contract.commonCost.editRentalObjectsCommonCost.title')}</h5>
                <Collapse
                    activeKey={activeKeys}
                    onChange={(values) => setActiveKeys(values)}
                    className='contract-collapse'
                    expandIcon={({ isActive }) => isActive ? <div className='collapse-btn btn-transparent' /> : <div className='collapse-btn collapsed btn-transparent' />}>
                    {props.rentObjects.map((rentObject) => (
                        <Collapse.Panel
                            className='contract-collapse-item'
                            header={<h5 className='mb-0'>{rentObject.displayName}</h5>}
                            key={rentObject.id}
                        >
                            <CommonCostRentalObjectCard key={rentObject.uId} rentObject={rentObject} setActiveKeys={setActiveKeys} />
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </div>
        </React.Fragment>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        rentObjects: get(contract, 'rentObjects'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        updateGeneralCommonCostCall(value) {
            return dispatch(updatePreview({ commonCostPerUnitPerYear: value }));
        },
    };
};

const ContractCommonCost = connect(mapState, mapDispatch)(ContractCommonCostComponent);
export default ContractCommonCost;
