import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { contractStatusOptions } from '../../../../common/contract-options';
import ActionButton from '../../../../components/action-button';

const ConfirmStatusModal = (props) => {
    const { t } = useTranslation();

    const { isConfirmModalOpen, handleStatusConfirm, handleCancelModal, contractStatusBuffer } = props;

    return (
        <Modal wrapClassName="result-modal creation-modal" visible={isConfirmModalOpen} closable={false}>
            <div className="main" style={{ paddingTop: '44px' }}>
                <h6>{`${t('contract.basicDetails.confirmStatusChange')} ${contractStatusOptions.find(item => item.id == contractStatusBuffer)?.name()}`}</h6>
            </div>
            <div className="btns justify-content-end">
                <ActionButton className="btn-secondary" onClick={handleCancelModal} text={t('common.buttons.cancel')}></ActionButton>
                <ActionButton className="btn-primary" onClick={handleStatusConfirm} text={t('common.buttons.confirm')}></ActionButton>
            </div>
        </Modal>
    );
};

export default ConfirmStatusModal;
