import { Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import newUpload from '../../assets/images/new-upload.svg';
import { maxPercents } from '../../common/constants';
import toaster from '../../common/toaster';
import UploadedFile from '../uploaded-file';

const { Dragger } = Upload;

const Uploader = (props) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState(props.files || []);

    useEffect(() => {
        setFiles(props.files || []);
    }, [props.files]);

    const onDeleteFileFunc = (file) => {
        props.deleteFile(file.id).then((isSuccess) => {
            if (isSuccess) {
                const listOfFiles = [...files];
                const newList = listOfFiles.filter((item) => item.id !== file.id);
                setFiles(newList);
                toaster.success(t('common.itemDeletedSuccessfully', { name: file.name }));
            }
        });
    };

    const sendFile = async (file) => {
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * maxPercents) / total);
                const listOfFiles = [...files];
                const itemIndex = listOfFiles.findIndex((item) => item.uid === file.uid);
                if (itemIndex > -1) {
                    if (file && percent < maxPercents) {
                        listOfFiles[itemIndex].percent = percent;
                    }
                    setFiles(listOfFiles);
                }
            },
        };

        props.uploadFile(file, options).then((fileResponse) => {
            if (fileResponse) {
                setFiles((prev) => [...prev.filter(item => item.uid !== fileResponse?.uid), fileResponse]);
            }
        });
    };

    const customRequest = (data) => {
        const file = data.file;

        if (!files.find((item) => item.name === file.name)) {
            file.percent = 0;
            files.push(file);
            setFiles(files);
            sendFile(file);
        }
    };

    const propsDragger = {
        name: 'file',
        multiple: true,
        listType: 'picture',
        accept: '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,application/pdf',
        customRequest: customRequest,
        fileList: files,
        itemRender: (_, file) => {
            return <UploadedFile file={file} key={file.name} onDeleteFileFunc={() => onDeleteFileFunc(file)} />;
        },
    };

    return (
        <React.Fragment>
            <p className="font-weight-bold">{props.title}</p>
            <Dragger {...propsDragger} ite>
                <Image src={newUpload} />
                <p>{t('dragAndDrop')}</p>
            </Dragger>
        </React.Fragment>
    );
};

export default Uploader;
