import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import ActionButton from '../../../components/action-button';
import PropertyRow from '../../../components/property-row';
import helper from '../../../helpers/helper';

const TenantReviewComponent = (props) => {
    const { t } = useTranslation();

    const goToStep = (stepKey, objectId) => {
        props.goToStep(stepKey, objectId);
    };

    const renderTenant = (tenant) => {
        return (
            <div className="review-part">
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                    <h5>{tenant.orgName ? tenant.orgName : `${tenant.firstName} ${tenant.lastName}`}</h5>
                    <ActionButton onClick={() => goToStep('details', tenant.uId)} className="btn-link" text={t('common.buttons.edit')} />
                </div>
                <div className="details-info">
                    {tenant.orgNumber ? (
                        <React.Fragment>
                            <PropertyRow title={t('tenant.review.companyName')} value={tenant.orgName} />
                            <PropertyRow title={t('tenant.review.organisationNumber')} value={helper.numberWithSpaces(tenant.orgNumber)} />
                            <PropertyRow title={t('tenant.review.corporateContact')} value={tenant.corporateContact} />
                            <PropertyRow title={t('tenant.review.corporateEmail')} value={tenant.corporateEmail} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <PropertyRow title={t('tenant.review.firstName')} value={tenant.firstName} />
                            <PropertyRow title={t('tenant.review.lastName')} value={tenant.lastName} />
                            <PropertyRow title={t('tenant.review.phoneNumber')} value={tenant.phone} />
                        </React.Fragment>
                    )}
                    <PropertyRow title={t('tenant.review.emailForInvoices')} value={tenant.emailForInvoices} />
                    <PropertyRow title={t('tenant.review.address')} value={tenant.address} />
                    <PropertyRow title={t('tenant.review.postNumber')} value={tenant.post} />
                    <PropertyRow title={t('tenant.review.municipality')} value={tenant.municipality} />
                    <PropertyRow title={t('tenant.review.country')} value={tenant.country} />
                </div>
            </div>
        );
    };

    return (
        <div className="page-container review">
            {props.hasTenants && props.tenants.map((tenant) => renderTenant(tenant))}
            {props.tenant && props.tenant.id && renderTenant(props.tenant)}
        </div>
    );
};

const mapState = ({ tenant }) => {
    return {
        tenants: get(tenant, 'tenants'),
        tenant: get(tenant, 'tenant'),
        hasTenants: get(tenant, 'tenants.length') > 0,
    };
};

const TenantReview = connect(mapState, null)(TenantReviewComponent);
export default TenantReview;
