import { LoadingOutlined } from '@ant-design/icons';
import { Select, Tag } from 'antd';
import get from 'lodash-es/get';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { enterBtnCode } from '../../common/constants';
import { setValuePlot } from '../../reducers/plotReducer';

const { Option } = Select;

const AddressesComponent = (props) => {
    const removeItem = (value) => {
        props.setValuePlotCall(
            'plot.addresses',
            props.addresses.filter((item) => item !== value)
        );
    };

    const onChange = (addresses) => {
        props.setValuePlotCall('plot.addresses', addresses);
    };

    return (
        <div className="multiple-cards-container">
            <p className="font-weight-bold mb-0">{props.title}</p>
            <p className="text-secondary">{props.description}</p>
            <Row>
                <Col md={12} lg={6}>
                    <div className="select-container multiple-cards">
                        <Select
                            mode="tags"
                            tagRender={() => {}}
                            listHeight={120}
                            onChange={onChange}
                            value={props.addresses}
                            placeholder={props.placeholder}
                            onKeyDown={(e) => (e.keyCode === enterBtnCode ? e.preventDefault() : '')}>
                            {props.hasPlotAddresses &&
                                props.plotAddresses.map((address, i) => (
                                    <Option key={i} value={address} address={address}>
                                        <p className="mb-0">{address}</p>
                                    </Option>
                                ))}
                        </Select>
                        {props.plotAddressLoading && <LoadingOutlined className="autocomplete-loading-icon" />}
                    </div>
                </Col>
            </Row>
            {props.hasAddresses &&
                props.addresses.map((address) => (
                    <Tag className="selected-item" closable key={address} onClose={(e) => removeItem(address)}>
                        {address}
                    </Tag>
                ))}
        </div>
    );
};

const mapState = ({ plot }) => {
    return {
        addresses: get(plot, 'plot.addresses'),
        hasAddresses: get(plot, 'plot.addresses.length') > 0,
        plotAddresses: get(plot, 'plotAddresses'),
        hasPlotAddresses: get(plot, 'plotAddresses.length') > 0,
        plotAddressLoading: get(plot, 'plotAddressLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValuePlotCall(key, value) {
            dispatch(setValuePlot(key, value));
        },
    };
};

const Addresses = connect(mapState, mapDispatch)(AddressesComponent);
export default Addresses;
