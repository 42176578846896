import { v4 as uuidv4 } from 'uuid';
import i18next from 'i18next';
import { StatusCodes } from 'http-status-codes';
import update from '../helpers/update';
import portfolioService from '../services/portfolioService';
import toaster from '../common/toaster';
import commonService from '../services/commonService';

const SET_VALUE_PORTFOLIO = 'SET_VALUE_PORTFOLIO';
const SUCCESS_GET_PORTFOLIOS_WITH_DETAILS = 'SUCCESS_GET_PORTFOLIOS_WITH_DETAILS';
const SUCCESS_GET_PORTFOLIOS_LIST = 'SUCCESS_GET_PORTFOLIOS_LIST';
const SUCCESS_GET_PORTFOLIO_BY_ID = 'SUCCESS_GET_PORTFOLIO_BY_ID';
const SUCCESS_GET_PORTFOLIO_KPIS = 'SUCCESS_GET_PORTFOLIO_KPIS';
const SET_ADDITIONAL_MANAGER_INFO = 'SET_ADDITIONAL_MANAGER_INFO';
const CLEAR_ADDITIONAL_MANAGER_INFO = 'CLEAR_ADDITIONAL_MANAGER_INFO';
const SET_VALUE_INVITE = 'SET_VALUE_INVITE';

export const defaultManager = {
    ownedBy: commonService.ownerRadioGroupTypes.company,
};

const initialState = {
    portfolios: [],
    portfoliosWithDetails: [],

    kpisLoading: false,
    kpis: null,

    portfolioLoading: false,
    portfolio: {
        invites: [],
    },

    showDraftModal: false,

    searchingPortfolio: null,
    isUserHasAnyPortfolios: false,
    totalData: null,

    getPortfoliosLoading: false,
    portfoliosWithDetailsLoading: true,
    portfolioNotFound: false,

    showCreationResultModal: false,
    showItemUpdatedResultModal: false,

    portfolioUsers: [],
    portfolioOwner: {}
};

const portfolioReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_PORTFOLIO:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case SET_VALUE_INVITE:
            let invites = newState.portfolio.invites;
            invites.find((x) => x.uIdInvite === action.payload.index)[action.payload.key] = action.payload.value;
            newState = update.set(newState, 'portfolio.invites', invites);
            break;
        case SUCCESS_GET_PORTFOLIOS_WITH_DETAILS:
            newState = update.set(newState, 'portfoliosWithDetails', action.payload.portfolios);
            newState = update.set(newState, 'totalData', action.payload.totalData);
            newState = update.set(newState, 'isUserHasAnyPortfolios', !!action.payload.portfolios.length);
            newState = update.set(newState, 'portfoliosWithDetailsLoading', false);
            break;
        case SUCCESS_GET_PORTFOLIOS_LIST:
            newState = update.set(newState, 'portfolios', action.payload.portfolios);
            break;
        case SUCCESS_GET_PORTFOLIO_BY_ID:
            newState = update.set(newState, 'portfolio', action.payload);
            newState = update.set(newState, 'portfolioLoading', false);
            break;
        case SUCCESS_GET_PORTFOLIO_KPIS:
            newState = update.set(newState, 'kpis', action.payload);
            break;
        case SET_ADDITIONAL_MANAGER_INFO:
            newState = update.set(newState, 'portfolio.manager.companyInfo', action.payload);
            break;
        case CLEAR_ADDITIONAL_MANAGER_INFO:
            newState = update.set(newState, 'portfolio.manager.companyInfo', null);
            break;
        default:
            break;
    }

    return newState;
};

export const setValuePortfolio = (key, value) => {
    return {
        type: SET_VALUE_PORTFOLIO,
        payload: {
            key,
            value,
        },
    };
};

export const setValueInvite = (key, value, index) => {
    return {
        type: SET_VALUE_INVITE,
        payload: {
            key,
            value,
            index,
        },
    };
};

const successGetPortfolioKpis = (value) => {
    return {
        type: SUCCESS_GET_PORTFOLIO_KPIS,
        payload: value,
    };
};

const successGetPortfoliosWithDetails = (value) => {
    return {
        type: SUCCESS_GET_PORTFOLIOS_WITH_DETAILS,
        payload: value,
    };
};

const successGetPortfoliosList = (value) => {
    return {
        type: SUCCESS_GET_PORTFOLIOS_LIST,
        payload: value,
    };
};

const successGetPortfolioById = (value) => {
    return {
        type: SUCCESS_GET_PORTFOLIO_BY_ID,
        payload: value,
    };
};

export const setAdditionalManagerInfo = (value) => {
    return {
        type: SET_ADDITIONAL_MANAGER_INFO,
        payload: value,
    };
};

export const clearAdditionalManagerInfo = () => {
    return {
        type: CLEAR_ADDITIONAL_MANAGER_INFO,
    };
};

const handleError = (error, dispatch) => {
    if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
        dispatch(setValuePortfolio('portfolioNotFound', true));
    } else if (error && error.response && error.response.status && error.response.status === StatusCodes.BAD_REQUEST) {
        toaster.error(error.response.data.title, null);
    } else if (error && error.response && error.response.title) {
        toaster.error(error.response.title, null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const createPortfolio = (values) => {
    return (dispatch) => {
        dispatch(setValuePortfolio('portfolioLoading', true));
        return portfolioService
            .create(values)
            .then((response) => {
                return response.data.id;
            })
            .catch((error) => handleError(error, dispatch))
            .finally(() => {
                dispatch(setValuePortfolio('portfolioLoading', false));
            });
    };
};

export const editPortfolio = (id, values) => {
    return (dispatch) => {
        dispatch(setValuePortfolio('portfolioLoading', true));
        return portfolioService
            .edit(id, values)
            .then((response) => {
                return response.data.id;
            })
            .catch((error) => handleError(error, dispatch))
            .finally(() => {
                dispatch(setValuePortfolio('portfolioLoading', false));
            });
    };
};

export const getPortfoliosWithDetails = (showMyPortfolios) => {
    return (dispatch) => {
        dispatch(setValuePortfolio('portfoliosWithDetailsLoading', true));
        portfolioService
            .search(showMyPortfolios)
            .then((response) => {
                const portfoliosTotalData = {
                    totalActiveContractsCount: response.data.reduce((a, b) => a + b.activeContracts, 0),
                    totalAnnualRentIncome: response.data.reduce((a, b) => a + b.rentIncome, 0),
                    totalPortfoliosCount: response.data.length,
                    totalPropertiesCount: response.data.reduce((a, b) => a + b.propertiesNumber, 0)
                }

                const data = {
                    totalData: portfoliosTotalData,
                    portfolios: response.data,
                };
                dispatch(successGetPortfoliosWithDetails(data));
                dispatch(successGetPortfoliosList({ portfolios: data.portfolios }));
            })
            .catch((error) => {
                handleError(error, dispatch);
                dispatch(setValuePortfolio('portfoliosWithDetailsLoading', false));
            });
    };
};

export const getPortfolioById = (id) => {
    return (dispatch) => {
        dispatch(setValuePortfolio('portfolioLoading', true));
        return portfolioService
            .getById(id)
            .then((response) => {
                const portfolio = response.data;
                portfolio.invites = portfolio.invites.length > 0 ? portfolio.invites.map((obj) => ({ ...obj, uIdInvite: uuidv4() })) : [];
                dispatch(successGetPortfolioById(portfolio));
                return portfolio;
            })
            .catch((error) => {
                handleError(error, dispatch);
                dispatch(setValuePortfolio('portfolioLoading', false));
            });
    };
};

export const reSendInvites = (portfolioId, invites) => {
    return (dispatch) => {
        dispatch(setValuePortfolio('sendInvitesLoading', true));
        return portfolioService
            .reSendInvites(portfolioId, invites)
            .then((_) => true)
            .catch((error) => handleError(error, dispatch))
            .finally(() => {
                dispatch(setValuePortfolio('sendInvitesLoading', false));
            });
    };
};

export const getPortfolioUsers = (portfolioId) => {
    return (dispatch) => {
        return portfolioService
            .getUsersByPortfolio(portfolioId)
            .then((response) => {
                dispatch(setValuePortfolio('portfolioUsers', response.data));
            })
            .catch((error) => handleError(error, dispatch));
    };
};

export const getOwnerByProtfolioId = (portfolioId) => {
    return (dispatch) => {
        return portfolioService
            .getOwnerByProtfolioId(portfolioId)
            .then((response) => {
                dispatch(setValuePortfolio('portfolioOwner', response.data));
            })
            .catch((error) => handleError(error, dispatch));
    };
};

export const getPortfolioKpis = (portfolioId, propertyId) => {
    return (dispatch) => {
        dispatch(setValuePortfolio('kpisLoading', true));
        portfolioService
            .getKpis(portfolioId, propertyId)
            .then((response) => {
                dispatch(successGetPortfolioKpis(response.data));
            })
            .catch((error) => handleError(error, dispatch))
            .finally(() => dispatch(setValuePortfolio('kpisLoading', false)));
    };
};

export default portfolioReducer;
