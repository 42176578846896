const objectHelper = {
    trimObjectProperties: (object) => {
        let newObject = {};
        for (const property in object) {
            if (typeof object[property] === 'string') {
                newObject[property] = object[property].trim();
            } else if (typeof object[property] === 'array') {
                let newArray = [];
                object[property].forEach((item) => {
                    if (typeof item === 'string') {
                        if (typeof object[property] === 'string') {
                            newArray.push(object[property].trim());
                        } else {
                            newArray.push(object[property]);
                        }
                    }
                });
            } else {
                newObject[property] = object[property];
            }
        }
        return newObject;
    },
};

export default objectHelper;
