import React from 'react';
import { Button } from 'antd';

const StoryBook = () => (
    <div>
        <h1 style={{ color: 'white' }}>Storybook</h1>
        <div className="page-container">
            <div className="row mb-5">
                <div className="col-2">
                    <Button className="btn-primary">Default</Button>
                </div>
                <div className="col">
                    <Button className="btn-primary btn-block">Full width</Button>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col">
                    <Button className="btn-primary btn-block">Primary</Button>
                </div>
                <div className="col">
                    <Button className="btn-primary btn-block" disabled={true}>
                        Primary
                    </Button>
                </div>
                <div className="col">
                    <Button className="btn-secondary btn-block">Secondary</Button>
                </div>
                <div className="col">
                    <Button className="btn-secondary btn-block" disabled={true}>
                        Secondary
                    </Button>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col">
                    <Button className="btn-primary btn-block">
                        <span>Primary</span>
                    </Button>
                </div>
                <div className="col">
                    <Button className="btn-primary btn-block" disabled={true}>
                        <span>Primary</span>
                    </Button>
                </div>
                <div className="col">
                    <Button className="btn-secondary btn-block">
                        <span>Secondary</span>
                    </Button>
                </div>
                <div className="col">
                    <Button className="btn-secondary btn-block" disabled={true}>
                        <span>Secondary</span>
                    </Button>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col text-center">
                    <Button className="btn-primary-m">Primary</Button>
                </div>
                <div className="col text-center">
                    <Button className="btn-primary-m" disabled={true}>
                        Primary
                    </Button>
                </div>
                <div className="col text-center">
                    <Button className="btn-secondary-m">Secondary</Button>
                </div>
                <div className="col text-center">
                    <Button className="btn-secondary-m" disabled={true}>
                        Secondary
                    </Button>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col text-center">
                    <Button className="btn-primary-sm">
                        <span>More</span>
                    </Button>
                </div>
                <div className="col text-center">
                    <Button className="btn-primary-sm" disabled={true}>
                        <span>More</span>
                    </Button>
                </div>
                <div className="col text-center">
                    <Button className="btn-secondary-sm">
                        <span>More</span>
                    </Button>
                </div>
                <div className="col text-center">
                    <Button className="btn-secondary-sm" disabled={true}>
                        <span>More</span>
                    </Button>
                </div>
            </div>
        </div>
    </div>
);

export default StoryBook;
