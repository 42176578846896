import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../helpers/useDebounse';
import companyService from '../../services/companyService';

const CompanyAutoComplete = (props) => {
    const [companies, setCompanies] = useState([]);
    const [searching, setSearching] = useState(false);
    const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
    const [searchCompany, setSearchCompany] = useState('');
    const [selectedText, setSelectedText] = useState();
    const [isSelected, setIsSelected] = useState(props.isSelected);
    const debouncedSearchCompany = useDebounce(searchCompany, 500);

    const onSelect = (id, data) => {
        onChange(data);
        setIsSelected(true);
        setSelectedText(data.name);
    };

    const onChangeInput = (value) => {
        if (isSelected && value) {
            return;
        }
        setIsSelected(false);
        setSelectedText(value);
        if (props.onChange) {
            props.onChange(value);
        }
    };

    const onChange = (value) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };

    const getAdditionalInfo = (value) => {
        if (props.getAdditionalInfo) {
            props.getAdditionalInfo(value);
        }
    };

    const onSearch = (data) => {
        if (isSelected && data) {
            return;
        }
        setIsSelected(false);
        setSearchCompany(data);
        if (data === '') {
            setCompanies([]);
            onChange();
            if (searching) {
                cancelToken.cancel();
                setSearching(false);
                setCancelToken(axios.CancelToken.source());
            }
        }
    };

    useEffect(() => {
        if (debouncedSearchCompany) {
            setSearching(true);
            if (/^\d{9}$/g.test(debouncedSearchCompany)) {
                companyService
                    .getCompanyByOrgNumber(debouncedSearchCompany)
                    .then((res) => {
                        const companies = [];
                        companies.push(res.data);
                        setCompanies(companies);
                        setSearching(false);
                    })
                    .catch((error) => {
                        setCompanies([]);
                        setSearching(false);
                    });
            } else {
                companyService
                    .getCompaniesList(debouncedSearchCompany, cancelToken)
                    .then((res) => {
                        setCompanies(res.data);
                        setSearching(false);
                    })
                    .catch((error) => {
                        setCompanies([]);
                        setSearching(false);
                    });
            }
        } else {
            if (props.initCompanyNumber) {
                setSearching(true);
                let isLoad = false;
                if (/^\d{9}$/g.test(props.initCompanyNumber)) {
                    companyService
                        .getCompanyByOrgNumber(props.initCompanyNumber)
                        .then((res) => {
                            if (!isLoad) {
                                setSelectedText(res.data.name);
                                getAdditionalInfo({
                                    businessCodeDescription: res.data.businessCodeDescription,
                                    organizationForm: res.data.organizationForm,
                                    businessCode: res.data.businessCode,
                                    isRegisteredInRegisterOfBusinessEnterprises: res.data.isRegisteredInRegisterOfBusinessEnterprises,
                                    isRegisteredInVatRegister: res.data.isRegisteredInVatRegister,
                                    latestFinancialStatementYear: res.data.latestFinancialStatementYear,
                                });
                                setSearching(false);
                            }
                        })
                        .catch((error) => {
                            setCompanies([]);
                            setSearching(false);
                        });
                }
                return () => {
                    isLoad = true;
                };
            }
        }
    }, [debouncedSearchCompany, cancelToken, props.initCompanyNumber]);

    return (
        <div className="select-container">
            <AutoComplete
                autoFocus={true}
                virtual={false}
                listHeight={120}
                autoComplete="off"
                onSelect={onSelect}
                onSearch={onSearch}
                onChange={onChangeInput}
                allowClear
                placeholder={props.placeholder}
                value={props.value || selectedText}>
                {companies &&
                    companies.map((company) => (
                        <AutoComplete.Option className={company.name === selectedText ? 'selected-item' : ''} key={company.identificationNumber} name={company.name} info={company}>
                            {company.name}
                        </AutoComplete.Option>
                    ))}
            </AutoComplete>
            {searching && <LoadingOutlined className="autocomplete-loading-icon" />}
        </div>
    );
};

export default CompanyAutoComplete;
