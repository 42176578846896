import { CloseOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Form, Modal } from 'antd';
import i18next from 'i18next';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import required from '../../../assets/images/required.svg';
import toaster from '../../../common/toaster';
import { validateEmail } from '../../../common/validators';
import ActionButton from '../../../components/action-button';
import CommonInput from '../../../components/common-input';
import rentRollService from '../../../services/rentRollService';

const SendInvite = ({ modalVisible, setModalVisible, year }) => {
    const { propertyId, portfolioId } = useParams();

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const sendInviteLink = async () => {
        const emailForm = await form.validateFields();

        try {
            setIsLoading(true);
            await rentRollService.sendRentRollLinkByEmail({ year: year, invitedEmail: emailForm.invitedEmail }, portfolioId, propertyId);
        } catch (error) {
            toaster.error(i18next.t('common.serverErrorMessage'), null);
            setIsLoading(false);
        }
        setIsLoading(false);
        setModalVisible(false);
        form.resetFields();
        toaster.success(t('rentRoll.sendInvite.inviteSuccess'));
    }

    const closeModal = () => {
        form.resetFields();
        setModalVisible(false)
    }

    return (
        <Modal
            wrapClassName={`result-modal`}
            visible={modalVisible}
            maskClosable={false}
            closeIcon={<CloseOutlined className="close-icon" onClick={closeModal} />}>
            <div>
                <div className="header">
                    <h4 className="mb-0">{t('rentRoll.sendInvite.sendLinkTitle')}</h4>
                </div>
                <div className="main">
                    <p className="font-weight-bold d-flex flex-row align-items-center">
                        <Image className="required-img" src={required} />
                        {t('rentRoll.sendInvite.emailAddress')}
                        <QuestionCircleFilled style={{ marginLeft: '5px' }} />
                    </p>
                    <Form form={form}>
                        <Form.Item name="invitedEmail" rules={[validateEmail]}>
                            <CommonInput type={'email'} maxLength={150} placeholder={t('rentRoll.sendInvite.placeholder')} />
                        </Form.Item>
                    </Form>
                    <p className="mt-4" style={{ color: '#F56A6A', fontSize: '14px' }}>{t('rentRoll.sendInvite.notification')}</p>
                </div>
                <div className="btns">
                    <ActionButton className="btn-secondary ml-3" text={t('common.buttons.cancel')} onClick={closeModal}></ActionButton>
                    <ActionButton loading={isLoading} className="btn-primary ml-3" text={t('rentRoll.sendInvite.sendLink')} onClick={() => sendInviteLink()}></ActionButton>
                </div>
            </div>
        </Modal>
    );
};

export default SendInvite;
