import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { archiveContract, createContractDraft, deleteContract, getContractsByTenantId, restoreContract, setValueContract } from '../../../reducers/contractReducer';
import { setValueTenant } from '../../../reducers/tenantReducer';
import { contractStatus, contractStatuses } from '../../../common/contract-options';
import ConfirmArchiveContract from '../../contract/modals/confirm-archive-modal';
import ConfirmDeleteContract from '../../contract/modals/confirm-delete-modal';
import ContractFilterModal from '../../contract/modals/filter-contracts';
import ContractCard from '../../../components/contract/contract-card';
import EmptyList from '../../../components/empty-list/empty-list';
import ActionButton from '../../../components/action-button';
import SearchInput from '../../../components/search-input';
import LoaderSpinner from '../../../components/spinner';
import Filter from '../../../components/filter';
import routingService from '../../../services/routingService';
import toaster from '../../../common/toaster';
import get from 'lodash-es/get';
import emptyListOfContracts from '../../../assets/images/empty-contract.svg';
import add from '../../../assets/images/plus.svg';
import contractService from '../../../services/contractService';
import { editPermisionTypes } from '../../../common/constants';
import { getPropertyById } from '../../../reducers/propertyReducer';

const ContractsTabComponent = (props) => {
    const { t } = useTranslation();
    const { tenantId, propertyId, portfolioId } = useParams();
    const [isArchivedShowed, setIsArchivedShowed] = useState(false);
    const [filteredContracts, setFilteredContracts] = useState(props.contracts || []);

    useEffect(() => {
        props.setValueContractCall('status', Object.values(contractStatus));
    }, [])

    useEffect(() => {
        if (props.property.isArchived) {
            props.setValueContractCall('showArchived', true);
            const filter = {
                status: contractStatuses.archived,
                hideDraftContracts: false,
            };
            onFilter(filter);
        }
    }, [])

    useEffect(() => {
        setFilteredContracts(props.contracts || []);
        props.getPropertyCall(portfolioId, propertyId);
    }, [props.contracts]);

    const openContract = (contract) => {
        routingService.navigateToContractDetails(portfolioId, propertyId, tenantId, contract.id, contract.isArchived);
    };

    const editContract = (contract) => {
        routingService.navigateToEditContract(portfolioId, propertyId, tenantId, contract.id);
    };

    const goToContractCreation = (_) => {
        if (!props.hasEditPermission) return;

        if(props.property.plots.length == 0 && props.property.buildings.length == 0) {
            toaster.error(t('tenantDetails.contracts.addBuildingOrPlot'), null);
        } else {
            contractService
            .createDraft(tenantId, propertyId, portfolioId).then((response) => {
                routingService.navigateToEditContract(portfolioId, propertyId, tenantId, response.data.id);
            })
        }
    };

    const showDeleteContractConfirmModal = (contract) => {
        if (!props.hasEditPermission) return;
        props.setContractToDelete(contract);
    };

    const showArchiveContractConfirmModal = (contract) => {
        if (!props.hasEditPermission) return;
        props.setContractToArchive(contract);
    };

    const restoreContract = (contract) => {
        if (!props.hasEditPermission) return;
        props.restoreContractCall(contract.id, portfolioId, propertyId).then((isSuccess) => {
            if (isSuccess) {
                toaster.success(t('contract.itemRestoredSuccessfully', { name: contract.name }));
                const contracts = [...props.contracts];
                const contractIndex = contracts.findIndex((x) => x.id === contract.id);
                if (contractIndex > -1) {
                    contracts[contractIndex].isArchived = false;
                }
                props.setValueTenantCall(
                    'tenantDetails.contracts',
                    contracts.filter((x) => x.id !== contract.id)
                );
            }
        });
    };

    const handleDeleteContract = (contract) => {
        if (!props.hasEditPermission) return;
        props.deleteContractCall(contract.id, portfolioId, propertyId).then((isDeleted) => {
            if (isDeleted) {
                toaster.success(t('common.itemDeletedSuccessfully', { name: contract.name }));
                props.closeDeleteContractModalWindow();
                const contracts = [...props.contracts].filter((x) => x.id !== contract.id);
                props.setValueTenantCall('tenantDetails.contracts', contracts);
            }
        });
    };

    const handleArchiveContract = (contract) => {
        if (!props.hasEditPermission) return;
        props.archiveContractCall(contract.id, portfolioId, propertyId).then((isSuccess) => {
            if (isSuccess) {
                toaster.success(t('contract.itemArchivedSuccessfully', { name: contract.name }));
                props.closeArchiveContractModalWindow();
                const contracts = [...props.contracts];
                const contractIndex = contracts.findIndex((x) => x.id === contract.id);
                if (contractIndex > -1) {
                    contracts[contractIndex].isArchived = true;
                }
                props.setValueTenantCall(
                    'tenantDetails.contracts',
                    contracts.filter((x) => x.id !== contract.id)
                );
            }
        });
    };

    const onSearch = (filterText) => {
        if (!props.hasAnyContracts) {
            return;
        }

        filterText = (filterText || '').toUpperCase();
        const contracts = props.contracts || [];
        const filteredContracts = filterText ? contracts.filter((contract) => contract.name && contract.name.toUpperCase().includes(filterText)) : contracts;
        setFilteredContracts(filteredContracts);
    };

    const onFilter = (filter) => {
        if (tenantId && propertyId) {
            setIsArchivedShowed(filter.status === contractStatuses.archived);
            props.getContractsByTenantIdCall(tenantId, propertyId, filter, portfolioId);
        }
    };

    const onClear = () => {
        const filter = {
            status: contractStatuses.active,
            hideDraftContracts: false,
        };
        onFilter(filter);
    };

    const addNewContractBtn = (label) => (
        <ActionButton
            className="btn-primary width-fit-content"
            text={label}
            icon={<Image src={add} />}
            disabled={!props.hasEditPermission}
            onClick={goToContractCreation}
        />
    );

    return (
        <div className="list list-of-contracts">
            <div className="list-header">
                <div className="search-header">
                    <SearchInput placeholder={t('contract.searchPlaceholder')} onSearch={onSearch} />
                    <Filter
                        onClick={() => props.setValueContractCall('showContractFilterModalWindow', true)}
                        filters={[props.status !== contractStatuses.active, props.hideDraftContracts].reduce((a, b) => a + b, 0)}
                    />
                </div>
                {(!isArchivedShowed && props.hasAnyContracts) && addNewContractBtn(t('contract.new'))}
            </div>
            {props.contractsLoading ? (
                <LoaderSpinner />
            ) : (
                <React.Fragment>
                    {props.hasAnyContracts ? (
                        <div className="cards">
                            {filteredContracts.map((contract) => (
                                <ContractCard
                                    key={contract.id}
                                    contract={contract}
                                    hasEditPermission={props.hasEditPermission}
                                    openContract={openContract}
                                    editContract={editContract}
                                    deleteContract={showDeleteContractConfirmModal}
                                    archiveContract={showArchiveContractConfirmModal}
                                    restoreContract={restoreContract}
                                />
                            ))}
                        </div>
                    ) : (
                        <EmptyList
                            image={emptyListOfContracts}
                            title={t(isArchivedShowed ? 'contract.emptyList.archivedTitle' : 'contract.emptyList.title', { tenant: props.tenantDetails.displayName })}
                            subTitle={!isArchivedShowed && t('contract.emptyList.subtitle', { tenant: props.tenantDetails.displayName })}>
                            {!isArchivedShowed && addNewContractBtn(t('contract.addContract'))}
                        </EmptyList>
                    )}
                </React.Fragment>
            )}
            {props.confirmDeleteContract && <ConfirmDeleteContract onConfirm={handleDeleteContract} />}
            {props.confirmArchiveContract && <ConfirmArchiveContract onConfirm={handleArchiveContract} />}
            {props.showContractFilterModalWindow && <ContractFilterModal onFilter={onFilter} onClear={onClear} />}
        </div>
    );
};

const mapState = ({ tenant, navigation, contract, property }) => {
    return {
        contractsLoading: get(contract, 'contractsLoading'),
        tenantDetails: get(tenant, 'tenantDetails'),
        property: get(property, 'property'),
        contracts: get(tenant, 'tenantDetails.contracts'),
        hasAnyContracts: get(tenant, 'tenantDetails.contracts.length') > 0,
        status: get(contract, 'status'),
        hideDraftContracts: get(contract, 'hideDraftContracts'),
        confirmDeleteContract: !!get(contract, 'contractToDelete'),
        confirmArchiveContract: !!get(contract, 'contractToArchive'),
        showContractFilterModalWindow: get(contract, 'showContractFilterModalWindow'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
    };
};

const mapDispatch = (dispatch) => {
    return {
        getContractsByTenantIdCall(tenantId, propertyId, filter, portfolioId) {
            dispatch(getContractsByTenantId(tenantId, propertyId, filter, portfolioId));
        },
        setContractToDelete(contract) {
            dispatch(setValueContract('contractToDelete', contract));
        },
        setContractToArchive(contract) {
            dispatch(setValueContract('contractToArchive', contract));
        },
        closeDeleteContractModalWindow() {
            dispatch(setValueContract('contractToDelete', null));
        },
        closeArchiveContractModalWindow() {
            dispatch(setValueContract('contractToArchive', null));
        },
        setValueTenantCall(key, value) {
            dispatch(setValueTenant(key, value));
        },
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
        deleteContractCall(contractId, portfolioId, propertyId) {
            return dispatch(deleteContract(contractId, portfolioId, propertyId));
        },
        archiveContractCall(contractId, portfolioId, propertyId) {
            return dispatch(archiveContract(contractId, portfolioId, propertyId));
        },
        restoreContractCall(contractId, portfolioId, propertyId) {
            return dispatch(restoreContract(contractId, portfolioId, propertyId));
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
    };
};

const ContractsTab = connect(mapState, mapDispatch)(ContractsTabComponent);
export default ContractsTab;
