import React from 'react';
import chartService from '../../../services/chartService';
import { connect } from 'react-redux';
import get from 'lodash.get';

class RentalPercentageChartComponent extends React.Component {
    id = 'rental-percercentage-chart';

    constructor(props) {
        super(props);
        this.state = { chart: null };
    }
    componentDidMount() {
        this.init();
    }

    mapData(totalRentalPercentage) {
        let result = { percent: totalRentalPercentage };

        result.chartData = [
            { value: totalRentalPercentage, radius: 150, color: '#0092E3' },
            { value: 100 - totalRentalPercentage, radius: 70, color: '#334372' },
        ];

        return result;
    }

    init() {
        const data = this.mapData(this.props.totalRentalPercentage);

        this.setState({
            chart: chartService.rentalPercentageChart(this.id, data),
        });
    }
    render() {
        return <div id={this.id} style={{ minWidth: '200px', height: '200px' }}></div>;
    }
}

const mapState = ({ tenant }) => {
    return {
        totalRentalPercentage: get(tenant, 'tenantSummary.totalRentalPercentage'),
    };
};

const RentalPercentageChart = connect(mapState)(RentalPercentageChartComponent);
export default RentalPercentageChart;
