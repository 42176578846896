import get from 'lodash-es/get';
import groupBy from 'lodash-es/groupBy';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import add from '../../assets/images/add.svg';
import { eventPeriodes, eventStatuses } from '../../common/event-options';
import toaster from '../../common/toaster';
import {
    getContractEvents,
    getEventForEditByIdAndGetRelatedData,
    getEventRelatedData,
    getPortfolioEvents,
    getPropertyEvents,
    setAsCompleted,
    setAsNotCompleted,
    setValueEvent,
    successGetEvents,
} from '../../reducers/eventsReducer';
import routingService from '../../services/routingService';
import ActionButton from '../action-button';
import Filter from '../filter';
import LoaderSpinner from '../spinner';
import EventCard from './event-card';
import ConfirmDeleteEvent from './modals/confirm-delete-modal';
import CustomRecurrence from './modals/custom-recurrence';
import EventModal from './modals/event-modal';
import EventsFilterModal from './modals/filter-for-events';
import { editPermisionTypes } from '../../common/constants';

const EventsComponent = (props) => {
    const { t } = useTranslation();
    const { contractId, propertyId, portfolioId } = useParams();
    const location = useLocation();
    const [loadPastCompletedEventsPageNumber, setLoadPastCompletedEventsPageNumber] = useState(1);
    const [loadPastDeletedEventsPageNumber, setLoadPastDeletedEventsPageNumber] = useState(1);
    const [loadFutureActiveEventsPageNumber, setLoadFutureActiveEventsPageNumber] = useState(1);
    const [loadPastActiveEventsPageNumber, setLoadPastActiveEventsPageNumber] = useState(1);

    useEffect(() => {
        getCurrentActiveListOfEvents();
        openEventToEdit();
    }, []);

    const openEventToEdit = () => {
        const params = new URLSearchParams(decodeURI(location.search));
        if (params.has('event')) {
            const eventId = +params.get('event');
            if (eventId) {
                onEdit(eventId);
            }
        }
    };

    const getListOfEvents = (filter) => {
        if (contractId) {
            return props.getContractEventsCall(portfolioId, propertyId, contractId, filter);
        } else if (propertyId) {
            return props.getPropertyEventsCall(portfolioId, propertyId, filter);
        } else if (portfolioId) {
            return props.getPortfolioEventsCall(portfolioId, filter);
        }
    };

    const getCurrentActiveListOfEvents = () => {
        props.setValueEventCall('disableLoadPastEventsBtn', false);
        props.setValueEventCall('disableLoadFutureEventsBtn', false);

        const filter = {
            eventStatus: eventStatuses.active,
            eventPeriod: eventPeriodes.current,
            getAssignedToMeEvents: false,
        };

        resetPageNumbers();
        onFilter(filter);
    };

    const onFilter = (filter) => {
        let currentPage = 1;
        if (filter.eventStatus === eventStatuses.active) {
            if (filter.eventPeriod === eventPeriodes.past) {
                currentPage = loadPastActiveEventsPageNumber;
            } else if (filter.eventPeriod === eventPeriodes.future) {
                currentPage = loadFutureActiveEventsPageNumber;
            } else {
                currentPage = 1;
            }
        } else if (filter.eventStatus === eventStatuses.completed) {
            currentPage = loadPastCompletedEventsPageNumber;
        } else if (filter.eventStatus === eventStatuses.deleted) {
            currentPage = loadPastDeletedEventsPageNumber;
        }

        filter.pagination = { currentPage: currentPage, itemsPerPage: 10 };

        return getListOfEvents(filter).then((list) => {
            if (list) {
                managingOfEvents(filter, list);
                pageIncrement(filter);
            }
        });
    };

    const pageIncrement = (filter) => {
        if (filter.eventStatus === eventStatuses.active) {
            if (filter.eventPeriod === eventPeriodes.past) {
                setLoadPastActiveEventsPageNumber(loadPastActiveEventsPageNumber + 1);
            } else if (filter.eventPeriod === eventPeriodes.future) {
                setLoadFutureActiveEventsPageNumber(loadFutureActiveEventsPageNumber + 1);
            }
            setLoadPastCompletedEventsPageNumber(1);
            setLoadPastDeletedEventsPageNumber(1);
        } else if (filter.eventStatus === eventStatuses.completed) {
            setLoadPastCompletedEventsPageNumber(loadPastCompletedEventsPageNumber + 1);
            setLoadFutureActiveEventsPageNumber(1);
            setLoadPastActiveEventsPageNumber(1);
            setLoadPastDeletedEventsPageNumber(1);
        } else if (filter.eventStatus === eventStatuses.deleted) {
            setLoadPastDeletedEventsPageNumber(loadPastDeletedEventsPageNumber + 1);
            setLoadFutureActiveEventsPageNumber(1);
            setLoadPastActiveEventsPageNumber(1);
            setLoadPastCompletedEventsPageNumber(1);
        }
    };

    const resetPageNumbers = () => {
        setLoadPastDeletedEventsPageNumber(1);
        setLoadFutureActiveEventsPageNumber(1);
        setLoadPastActiveEventsPageNumber(1);
        setLoadPastCompletedEventsPageNumber(1);
    };

    const onClear = () => {
        getCurrentActiveListOfEvents();
    };

    const setAsCompleted = (id) => {
        props.setAsCompletedCall(portfolioId, id).then((response) => {
            if (response) {
                toaster.success(t('events.successfullyCompleted', { name: response.title }));
            }
        });
    };

    const setAsNotCompleted = (id) => {
        props.setAsNotCompletedCall(portfolioId, id).then((response) => {
            if (response) {
                toaster.success(t('events.successfullyInCompleted', { name: response.title }));
            }
        });
    };

    const onEdit = (id) => {
        props.getEventForEditByIdAndGetRelatedDataCall(portfolioId, propertyId, id).then((response) => {
            if (response) {
                props.setValueEventCall('showEventModalWindow', true);
            }
        });
    };

    const onDelete = (id) => {
        const event = props.events.find((x) => x.id === id);
        if (event) {
            props.setValueEventCall('event', event);
            props.setValueEventCall('showConfirmDeleteEventModalWindow', true);
        }
    };

    const onOpenEventModal = () => {
        props.getEventRelatedDataCall(portfolioId, propertyId, contractId).then((response) => {
            if (response) {
                props.setValueEventCall('showEventModalWindow', true);
            }
        });
    };

    const getEventWithFilter = () => {
        resetPageNumbers();

        props.setValueEventCall('disableLoadPastEventsBtn', false);
        props.setValueEventCall('disableLoadFutureEventsBtn', false);

        const filter = {
            eventStatus: props.eventStatus,
            eventPeriod: eventPeriodes.current,
            getAssignedToMeEvents: props.getAssignedToMeEvents,
        };

        onFilter(filter);
    };

    const afterCreate = (event) => {
        getEventWithFilter();
        toaster.success(t('events.successfullyCreated', { name: event.title }));
    };

    const afterEdit = (event) => {
        getEventWithFilter();
        toaster.success(t('events.successfullyEdited', { name: event.title }));
    };

    const afterDelete = (event) => {
        getEventWithFilter();
        toaster.success(t('common.itemDeletedSuccessfully', { name: event.title }));
    };

    const onCloseModalWindow = () => {
        props.setValueEventCall('showEventModalWindow', false);

        const params = new URLSearchParams(location.search);
        if (params.has('event')) {
            params.delete('event');
            routingService.replace('?' + params.toString());
        }
    };

    const managingOfEvents = (filter, events) => {
        if (filter.eventPeriod === eventPeriodes.past) {
            if (!events.length) {
                toaster.success(t('events.youDontHaveAnyPastEvents'));
            }
            if (events.length < 10) {
                props.setValueEventCall('disableLoadPastEventsBtn', true);
            }
            let newEventsList = props.events;
            if (events.length > 0) {
                newEventsList = newEventsList.concat(events);
            }
            props.successGetEventsCall(newEventsList);
        } else if (filter.eventPeriod === eventPeriodes.future) {
            if (!events.length) {
                toaster.success(t('events.youDontHaveAnyFutureEvents'));
            }
            if (events.length < 10) {
                props.setValueEventCall('disableLoadFutureEventsBtn', true);
            }
            let newEventsList = props.events;
            if (events.length > 0) {
                newEventsList = newEventsList.concat(events);
            }
            props.successGetEventsCall(newEventsList);
        } else {
            props.successGetEventsCall(events);
        }
    };

    const loadFutureEvents = () => {
        const filter = {
            eventStatus: props.eventStatus,
            eventPeriod: eventPeriodes.future,
            getAssignedToMeEvents: props.getAssignedToMeEvents,
        };

        onFilter(filter).then(() => {
            document.getElementById('loadFutureBtn').scrollIntoView();
        });
    };

    const loadPastEvents = () => {
        const filter = {
            eventStatus: props.eventStatus,
            eventPeriod: eventPeriodes.past,
            getAssignedToMeEvents: props.getAssignedToMeEvents,
        };

        onFilter(filter);
    };

    const showGroupedEvents = (listOfEvents, groupByProperty) => {
        if (listOfEvents && groupByProperty) {
            listOfEvents.forEach((element) => {
                if (element.deletedDate) {
                    element.deletedDate = moment(element.deletedDate).startOf('day').utc(true).format();
                } else if (element.completedDate) {
                    element.completedDate = moment(element.completedDate).startOf('day').utc(true).format();
                } else if (!element.deletedDate && !element.completedDate) {
                    element.eventTaskDate = moment(element.eventTaskDate).startOf('day').utc(true).format();
                }
            });

            const list = groupBy(listOfEvents, groupByProperty);

            let newList = {};
            Object.keys(list)
                .sort(function (a, b) {
                    return moment(a, 'YYYY-MM-DD').toDate() - moment(b, 'YYYY-MM-DD').toDate();
                })
                .forEach(function (key) {
                    newList[key] = list[key];
                });

            return (
                <React.Fragment>
                    {newList && Object.keys(newList).length > 0
                        ? Object.keys(newList).map((key) => (
                              <div className="group-list-of-events" key={key}>
                                  {moment(key).isSame(moment().startOf('day').utc(true)) ? (
                                      <div className="d-flex flex-row">
                                          <p className="font-weight-bold mb-0 ml-2">{t('events.today')}</p>
                                          <p className="text-secondary mb-0 ml-2">{moment(key).format('dddd Do MMMM YYYY')}</p>
                                      </div>
                                  ) : (
                                      <p className="font-weight-bold ml-2 m-0">{moment(key).format('dddd Do MMMM YYYY')}</p>
                                  )}
                                  <div>
                                      {newList[key].map((event) => (
                                          <EventCard
                                              event={event}
                                              key={event.id}
                                              onEdit={onEdit}
                                              onDelete={onDelete}
                                              hasEditPermission={props.hasEditPermission}
                                              setAsCompleted={setAsCompleted}
                                              setAsNotCompleted={setAsNotCompleted}
                                          />
                                      ))}
                                  </div>
                              </div>
                          ))
                        : null}
                </React.Fragment>
            );
        } else {
            return null;
        }
    };

    const hasAnyEventsInAnyGroup = () =>
        (props.eventStatus === eventStatuses.active && props.activeEvents && props.activeEvents.length > 0) ||
        (props.eventStatus === eventStatuses.active && props.overdueEvents && props.overdueEvents.length > 0) ||
        (props.eventStatus === eventStatuses.completed && props.completedEvents && props.completedEvents.length > 0) ||
        (props.eventStatus === eventStatuses.deleted && props.deletedEvents && props.deletedEvents.length > 0);

    return (
        <div className="h-100">
            {props.getEventsLoading ? (
                <LoaderSpinner />
            ) : (
                <React.Fragment>
                    <div className="events">
                        <div className="btns event-btns mb-3">
                            <ActionButton onClick={loadPastEvents} className="btn-link p-2" text={t('events.loadPastEvents')} disabled={props.disableLoadPastEventsBtn} />
                            <div className="d-flex flex-row">
                                <Filter
                                    className="mr-3"
                                    onClick={() => props.setValueEventCall('showEventFilterModalWindow', true)}
                                    filters={[props.eventStatus !== eventStatuses.active, props.getAssignedToMeEvents].reduce((a, b) => a + b, 0)}
                                />
                                <ActionButton
                                    className="btn-primary desktop-btn"
                                    icon={<Image src={add} />}
                                    onClick={onOpenEventModal}
                                    disabled={!props.hasEditPermission}
                                    text={t('events.addNewEvent')}
                                    loading={props.getRelatedDataLoading}
                                />
                            </div>
                        </div>
                        {hasAnyEventsInAnyGroup() && (
                            <div className="list-of-events">
                                {props.eventStatus === eventStatuses.active && props.overdueEvents.length > 0 && (
                                    <div className="group-list-of-events">
                                        <p className="font-weight-bold ml-2">{t('events.overdue')}</p>
                                        {props.overdueEvents.map((event) => (
                                            <EventCard
                                                event={event}
                                                key={event.id}
                                                onEdit={onEdit}
                                                onDelete={onDelete}
                                                hasEditPermission={props.hasEditPermission}
                                                setAsCompleted={setAsCompleted}
                                                setAsNotCompleted={setAsNotCompleted}
                                            />
                                        ))}
                                    </div>
                                )}
                                {props.eventStatus === eventStatuses.active && showGroupedEvents(props.activeEvents, 'eventTaskDate')}
                                {props.eventStatus === eventStatuses.completed && showGroupedEvents(props.completedEvents, 'completedDate')}
                                {props.eventStatus === eventStatuses.deleted && showGroupedEvents(props.deletedEvents, 'deletedDate')}
                            </div>
                        )}
                    </div>
                    <React.Fragment>
                        {!hasAnyEventsInAnyGroup() && (
                            <React.Fragment>
                                {props.eventStatus === eventStatuses.active ? (
                                    <div className="empty-list-message">
                                        <h3>{t('events.activeEmpty.title')}</h3>
                                        <p className="text-secondary">{t('events.activeEmpty.message')}</p>
                                    </div>
                                ) : props.eventStatus === eventStatuses.completed ? (
                                    <div className="empty-list-message">
                                        <h3>{t('events.completedEmpty.title')}</h3>
                                        <p className="text-secondary">{t('events.completedEmpty.message')}</p>
                                    </div>
                                ) : props.eventStatus === eventStatuses.deleted ? (
                                    <div className="empty-list-message">
                                        <h3>{t('events.deletedEmpty.title')}</h3>
                                        <p className="text-secondary">{t('events.deletedEmpty.message')}</p>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                    <div className="mobile-footer">
                        <ActionButton
                            className="btn-primary"
                            icon={<Image src={add} />}
                            onClick={onOpenEventModal}
                            disabled={!props.hasEditPermission}
                            text={t('events.addNewEvent')}
                            loading={props.getRelatedDataLoading}
                        />
                    </div>
                    {props.eventStatus === eventStatuses.active && (
                        <div className="btns load-future-btn justify-content-start pb-3" id="loadFutureBtn">
                            <ActionButton onClick={loadFutureEvents} className="btn-link p-2" disabled={props.disableLoadFutureEventsBtn} text={t('events.loadFutureEvents')} />
                        </div>
                    )}
                </React.Fragment>
            )}
            {props.showEventModalWindow && <EventModal onCloseModalWindow={onCloseModalWindow} afterCreate={afterCreate} afterEdit={afterEdit} />}
            {props.showReminderModalWindow && <CustomRecurrence />}
            {props.showEventFilterModalWindow && <EventsFilterModal onFilter={onFilter} onClear={onClear} />}
            {props.showConfirmDeleteEventModalWindow && <ConfirmDeleteEvent afterDelete={afterDelete} />}
        </div>
    );
};

const mapState = ({ events, navigation }) => {
    return {
        event: get(events, 'event'),
        events: get(events, 'events'),
        eventStatus: get(events, 'eventStatus'),

        getEventsLoading: get(events, 'getEventsLoading'),
        showEventModalWindow: get(events, 'showEventModalWindow'),
        showReminderModalWindow: get(events, 'showReminderModalWindow'),
        getRelatedDataLoading: get(events, 'getRelatedDataLoading'),
        getAssignedToMeEvents: get(events, 'getAssignedToMeEvents'),
        disableLoadPastEventsBtn: get(events, 'disableLoadPastEventsBtn'),
        disableLoadFutureEventsBtn: get(events, 'disableLoadFutureEventsBtn'),
        showEventFilterModalWindow: get(events, 'showEventFilterModalWindow'),
        showConfirmDeleteEventModalWindow: get(events, 'showConfirmDeleteEventModalWindow'),
        activeEvents: get(events, 'events').filter((x) => !x.isOverdue && !x.isDeleted),
        overdueEvents: get(events, 'events').filter((x) => x.isOverdue && !x.isDeleted),
        completedEvents: get(events, 'events'),
        deletedEvents: get(events, 'events').filter((x) => x.isDeleted),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueEventCall(key, value) {
            dispatch(setValueEvent(key, value));
        },
        successGetEventsCall(data) {
            dispatch(successGetEvents(data));
        },
        getContractEventsCall(portfolioId, propertyId, contractId, data) {
            return dispatch(getContractEvents(portfolioId, propertyId, contractId, data));
        },
        getPropertyEventsCall(portfolioId, propertyId, data) {
            return dispatch(getPropertyEvents(portfolioId, propertyId, data));
        },
        getPortfolioEventsCall(portfolioId, data) {
            return dispatch(getPortfolioEvents(portfolioId, data));
        },
        setAsCompletedCall(portfolioId, eventTaskId) {
            return dispatch(setAsCompleted(portfolioId, eventTaskId));
        },
        setAsNotCompletedCall(portfolioId, eventTaskId) {
            return dispatch(setAsNotCompleted(portfolioId, eventTaskId));
        },
        getEventRelatedDataCall(portfolioId, propertyId, contractId) {
            return dispatch(getEventRelatedData(portfolioId, propertyId, contractId));
        },
        getEventForEditByIdAndGetRelatedDataCall(portfolioId, propertyId, id) {
            return dispatch(getEventForEditByIdAndGetRelatedData(portfolioId, propertyId, id));
        },
    };
};

const Events = connect(mapState, mapDispatch)(EventsComponent);
export default Events;
