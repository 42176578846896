import { PlusOutlined } from '@ant-design/icons';
import { Col, Row, Form, Tabs } from 'antd';
import get from 'lodash-es/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ActionButton from '../../../../components/action-button';
import { setValueContract } from '../../../../reducers/contractReducer';
import TurnoverRateLabel from './turnover-rate-label';
import TurnoverRateIntervals from './turnover-rate-intervals';

const TurnoverRentComponent = (props) => {
    const { turnoverRateLabels, setTurnoverRateLabels, turnoverRates, rentObject, turnoverRateForm, turnoverRateTabActiveKey, setTurnoverRateTabActiveKey } = props;

    const { t } = useTranslation();

    const addNewSource = () => {
        const lastIndex = Math.max(turnoverRateLabels.map((item) => item.id));
        setTurnoverRateLabels([...turnoverRateLabels, { id: lastIndex + 1, name: 'New Source' }]);
    };

    return (
        <div className="turnover-rate">
            <Form.List name="turnoverRates" initialValue={[{ }]}>
                {(fields, { add, remove }, { errors }) => (
                    <>
                        <h6>{t('contract.turnover.turnoverRate.title')}</h6>
                        <Row className="divider-bottom pb-3">
                            <Col span={5} offset={19}>
                                <ActionButton
                                    className="btn-secondary add-interval mb-3"
                                    icon={<PlusOutlined style={{ fontSize: '22px' }} />}
                                    onClick={() => {
                                        addNewSource();
                                        add();
                                    }}
                                    text={t('contract.turnover.turnoverRate.addNewSource')}
                                />
                            </Col>
                        </Row>
                        <Tabs activeKey={turnoverRateTabActiveKey} onChange={key => setTurnoverRateTabActiveKey(key)}>
                            {fields.map(({ key, name, ...restField }, index) => {
                                const rate = turnoverRates ? turnoverRates[index] : undefined;

                                return (
                                    <Tabs.TabPane forceRender={true} tab={<TurnoverRateLabel name={name} turnoverRateForm={turnoverRateForm} setTurnoverRateLabels={setTurnoverRateLabels} initialName={rate?.incomeSource ? rate?.incomeSource : index == 0 ? rentObject.displayName : undefined} />} key={index}>
                                        <TurnoverRateIntervals
                                            setTurnoverRateLabels={setTurnoverRateLabels}
                                            initialMultipleIntervals={rate?.multipleInterval}
                                            hideDeleteButton={fields.length == 1}
                                            turnoverRateForm={turnoverRateForm}
                                            rateIndex={index}
                                            deleteSource={() => {
                                                remove(name);
                                            }}
                                            sourceName={name}
                                        />
                                    </Tabs.TabPane>
                                );
                            })}
                        </Tabs>
                    </>
                )}
            </Form.List>
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        turnoverRate: get(contract, 'turnoverRate'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
    };
};

const TurnoverRent = connect(mapState, mapDispatch)(TurnoverRentComponent);
export default TurnoverRent;
