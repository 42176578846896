import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Menu, Tabs, Dropdown, Button } from 'antd';
import { Col, Image } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { deletePlot, getPlotById, setValuePlot, setDeletePlotModel } from '../../../reducers/plotReducer';
import get from 'lodash-es/get';
import clear from '../../../common/clear';
import backArrow from '../../../assets/images/back-arrow.svg';
import moreIcon from '../../../assets/images/more.svg';
import MainLayout from '../../../components/layouts/main-layout';
import ActionButton from '../../../components/action-button';
import ConfirmDeletePlot from '../modals/confirm-delete-modal';
import NotFoundPage from '../../../components/error-page';
import EditMenuItem from '../../../components/menu-items/edit-menu-item';
import DeleteMenuItem from '../../../components/menu-items/delete-menu-item';
import LoaderSpinner from '../../../components/spinner';
import routingService from '../../../services/routingService';
import PropertyRow from '../../../components/property-row';
import { editPermisionTypes } from '../../../common/constants';

const { TabPane } = Tabs;

const plotTabs = {
    details: '1',
};

const PlotDetailsComponent = (props) => {
    const { t } = useTranslation();
    const { plotId, propertyId, portfolioId } = useParams();
    const plotsUrl = routingService.plotsUrl(portfolioId, propertyId);

    useEffect(() => {
        if (portfolioId && propertyId && plotId) {
            props.getPlotByIdCall(portfolioId, plotId, propertyId);
        } else if (portfolioId && propertyId) {
            routingService.goTo(plotsUrl);
        } else {
            routingService.navigateToPortfolios();
        }
    }, []);

    const goToPlotEdit = () => {
        if (!props.hasEditPermission) return;
        routingService.navigateToEditPlot(portfolioId, propertyId, plotId);
    };

    const defaultIcon = () => {
        var randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return <div className="entity-image" style={{ backgroundColor: '#' + randomColor }} />;
    };

    const onDeleteClick = (_) => {
        if (!props.hasEditPermission) return;
        props.showPlotDeleteModal();
        props.setDeletePlotModelCall(props.plot);
    };

    const handleDeletePlot = async () => {
        const isSuccess = await props.deletePlotCall(props.plot.id, props.plot.propertyId, portfolioId);
        if (isSuccess) {
            routingService.goTo(plotsUrl);
            return true;
        }
    };

    const dropDownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" disabled={!props.hasEditPermission || props.isArchived} onClick={goToPlotEdit} text={t('plot.editPlot')} />
                <DeleteMenuItem key="1" disabled={!props.hasEditPermission || props.isArchived} onClick={onDeleteClick} text={t('plot.deletePlot')} />
            </Menu>
        );
    };

    return (
        <MainLayout>
            {props.plotNotFound ? <NotFoundPage header={t('plot.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {!props.plotNotFound && !props.propertyNotFound && !props.plotLoading && props.plot && props.plot.id ? (
                <div className="details-page">
                    <div className="title-container">
                        <div className="title">
                            <Link to={plotsUrl}>
                                <Image className="m-0" src={backArrow} />
                            </Link>
                            {props.plot.image ? <Image src={props.plot.image} /> : defaultIcon()}
                            <h3 className="mb-0">{props.plot.displayName}</h3>
                            <Dropdown trigger={['click']} placement="bottomRight" overlay={dropDownMenu()}>
                                <Button className="btn-link px-0">
                                    <Image className="m-0" src={moreIcon} />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                    <Tabs defaultActiveKey={plotTabs.details}>
                        <TabPane tab={t('plot.tabs.details')} key={plotTabs.details}>
                            <Col md={12} lg={6} className="p-0">
                                <div className="details-card details-info">
                                    <div className="main">
                                        <h5>{t('plot.detailsTab.title')}</h5>
                                        {props.plot.name && <PropertyRow title={t('plot.detailsTab.name')} value={props.plot.name} />}
                                        <PropertyRow title={t('plot.detailsTab.municipality')} value={props.plot.city} />
                                        <PropertyRow title={t('plot.detailsTab.address')} value={props.plot.address} />
                                        <PropertyRow
                                            title={t('plot.detailsTab.gnrBnr')}
                                            value={
                                                <span>
                                                    {props.plot.gnrNumber}/{props.plot.bnrNumber}
                                                </span>
                                            }
                                        />
                                    </div>
                                    <div className="btns">
                                        <ActionButton className="btn-secondary" text={t('common.buttons.edit')} disabled={!props.hasEditPermission || props.isArchived} onClick={goToPlotEdit} />
                                    </div>
                                </div>
                            </Col>
                        </TabPane>
                    </Tabs>
                </div>
            ) : (
                <LoaderSpinner />
            )}
            {props.confirmDeletePlot && <ConfirmDeletePlot handleDeletePlot={handleDeletePlot} />}
        </MainLayout>
    );
};

const mapState = ({ plot, property, navigation }) => {
    return {
        plot: get(plot, 'plot'),
        plotNotFound: get(plot, 'plotNotFound'),
        plotLoading: get(plot, 'plotLoading'),
        confirmDeletePlot: get(plot, 'showConfirmDeleteModal'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        propertyNotFound: get(property, 'propertyNotFound'),
        isArchived: get(plot, 'plot.isArchived')
    };
};

const mapDispatch = (dispatch) => {
    return {
        getPlotByIdCall(portfolioId, plotId, propertyId) {
            dispatch(getPlotById(portfolioId, plotId, propertyId));
        },
        showPlotDeleteModal() {
            dispatch(setValuePlot('showConfirmDeleteModal', true));
        },
        setDeletePlotModelCall(value) {
            dispatch(setDeletePlotModel(value));
        },
        deletePlotCall(plotId, propertyId, portfolioId) {
            return dispatch(deletePlot(plotId, propertyId, portfolioId));
        },
    };
};

const PlotDetails = clear(connect(mapState, mapDispatch)(PlotDetailsComponent));
export default PlotDetails;
