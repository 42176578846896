import React from 'react';
import { Modal } from 'antd';
import { Image } from 'react-bootstrap';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setValueContract } from '../../../../reducers/contractReducer';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import { invoiceIssueDateOptions, paymentPlanOptions, rentObjectRentalTypeOptions, rentObjectTypeOptions } from '../../../../common/contract-options';
import PropertyRow from '../../../../components/property-row';
import ActionButton from '../../../../components/action-button';
import helper from '../../../../helpers/helper';
import get from 'lodash-es/get';
import moment from 'moment';
import rentObjectImg from '../../../../assets/images/rent-object.svg';

const RentObjectDetailsModalComponent = (props) => {
    const { t } = useTranslation();

    const onClose = () => {
        props.closeModalWindow();
        props.clearRentObjectModel();
    };

    const leaseStartDate = props.rentObject.leaseStartDate || props.contract.leaseStartDate;
    const leaseEndDate = props.rentObject.leaseEndDate || props.contract.leaseEndDate;
    const firstInvoiceDueDate = props.rentObject.firstInvoiceDueDate || props.contract.firstInvoiceDueDate;
    const invoiceIssueDateOption = props.rentObject.invoiceIssueDateOption || props.contract.invoiceIssueDateOption;
    const paymentPlan = props.rentObject.paymentPlan || props.contract.paymentPlan;
    const originalBaseIndexDate = props.rentObject.originalBaseIndexDate || props.contract.originalBaseIndexDate;
    const firstTimeCpiAdjustmentDate = props.rentObject.firstTimeCpiAdjustmentDate || props.contract.firstTimeCpiAdjustmentDate;
    const yearlyCpiIndexDate = props.rentObject.yearlyCpiIndexDate || props.contract.yearlyCpiIndexDate;
    const cpiRate = props.rentObject.cpiRate || props.contract.cpiRate;
    const commonCostPerUnitPerYear = props.rentObject.commonCostPerUnitPerYear || props.contract.commonCostPerUnitPerYear;

    return (
        <Modal
            wrapClassName="result-modal creation-modal rent-object-details-modal"
            footer={null}
            visible={true}
            maskClosable={false}
            onCancel={onClose}
            closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    {props.rentObject.image ? (
                        <Image src={props.rentObject.image} />
                    ) : (
                        <div className="entity-image rent-object-img">
                            <Image src={rentObjectImg} />
                        </div>
                    )}
                </div>
                <div className="main">
                    <h4 className="mb-0">{props.rentObject.displayName}</h4>
                    <div className="details-card details-info">
                        {leaseStartDate && leaseEndDate && (
                            <PropertyRow
                                title={t('contract.contractDetails.rentObjectDetailsModal.leaseStartEnd')}
                                value={`${moment(leaseStartDate).format(primaryDateFormat)} - ${moment(leaseEndDate).format(primaryDateFormat)}`}
                            />
                        )}
                        {paymentPlan || invoiceIssueDateOption || firstInvoiceDueDate ? (
                            <React.Fragment>
                                <h6>{t('contract.contractDetails.rentObjectDetailsModal.paymentPlan')}</h6>
                                <div className="properties-container">
                                    {leaseStartDate && leaseEndDate && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.paymentPlan')}
                                            value={helper.getEnumValue(paymentPlanOptions, paymentPlan)}
                                        />
                                    )}
                                    {invoiceIssueDateOption && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.paymentCreditTerms')}
                                            value={helper.getEnumValue(invoiceIssueDateOptions, invoiceIssueDateOption)}
                                        />
                                    )}
                                    {/* 
                                        // need calculations
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.firstInvoiceDate')}
                                            value={moment(props.rentObject.firstInvoiceDueDate || props.contract.firstInvoiceDueDate).format(primaryDateFormat)} /> 
                                    */}
                                    {firstInvoiceDueDate && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.firstInvoiceDueDate')}
                                            value={moment(firstInvoiceDueDate).format(primaryDateFormat)}
                                        />
                                    )}
                                    {/* 
                                        // need calculations
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.invoicePeriod')}
                                            value={moment(props.contract.signatureDate).format(primaryDateFormat)} /> 
                                    */}
                                </div>
                            </React.Fragment>
                        ) : null}
                        {props.rentObject.rentObjectType ||
                        props.rentObject.rentObjectRentalType ||
                        props.rentObject.pricePerYearPerUnit ||
                        props.rentObject.amount ||
                        props.rentObject.pricePerYearPerUnit ||
                        commonCostPerUnitPerYear ? (
                            <React.Fragment>
                                <h6>{t('contract.contractDetails.rentObjectDetailsModal.assetInformation')}</h6>
                                <div className="properties-container">
                                    {props.rentObject.rentObjectType && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.assetType')}
                                            value={helper.getEnumValue(rentObjectTypeOptions, props.rentObject.rentObjectType)}
                                        />
                                    )}
                                    {props.rentObject.rentObjectRentalType && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.rentObjectType')}
                                            value={helper.getEnumValue(rentObjectRentalTypeOptions, props.rentObject.rentObjectRentalType)}
                                        />
                                    )}
                                    {props.rentObject.pricePerYearPerUnit && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.unitedPricePerYear')}
                                            value={helper.numberWithTwoDigits(props.rentObject.pricePerYearPerUnit.toFixed(2))}
                                        />
                                    )}
                                    {props.rentObject.amount && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.unitQuantity')}
                                            value={helper.numberWithTwoDigits(props.rentObject.amount)}
                                        />
                                    )}
                                    {props.rentObject.pricePerYearPerUnit && props.rentObject.amount && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.annualRental')}
                                            value={helper.numberWithTwoDigits((props.rentObject.pricePerYearPerUnit * props.rentObject.amount))}
                                        />
                                    )}
                                    {commonCostPerUnitPerYear > 0 && props.rentObject.amount && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.commonCost')}
                                            value={helper.numberWithTwoDigits((commonCostPerUnitPerYear * props.rentObject.amount))}
                                        />
                                    )}
                                </div>
                            </React.Fragment>
                        ) : null}
                        {originalBaseIndexDate || firstTimeCpiAdjustmentDate || yearlyCpiIndexDate || cpiRate ? (
                            <React.Fragment>
                                <h6>{t('contract.contractDetails.rentObjectDetailsModal.cpi')}</h6>
                                <div className="properties-container">
                                    {originalBaseIndexDate && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.originalBaseIndex')}
                                            value={moment(originalBaseIndexDate).format('MMMM YYYY')}
                                        />
                                    )}
                                    {firstTimeCpiAdjustmentDate && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.firstCpiIndex')}
                                            value={moment(firstTimeCpiAdjustmentDate).format('MMMM YYYY')}
                                        />
                                    )}
                                    {yearlyCpiIndexDate && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.rentObjectDetailsModal.yearlyCpiIndex')}
                                            value={moment(yearlyCpiIndexDate).format('MMMM')}
                                        />
                                    )}
                                    {cpiRate && <PropertyRow title={t('contract.contractDetails.rentObjectDetailsModal.cpiRate')} value={cpiRate} />}
                                </div>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
                <div className="btns">
                    <ActionButton onClick={onClose} className="btn-secondary" text={t('common.buttons.close')} />
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        rentObject: get(contract, 'rentObjectWithDetails'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showRentObjectDetailsModal', false));
        },
        clearRentObjectModel() {
            dispatch(setValueContract('rentObjectWithDetails', null));
        },
    };
};

const RentObjectDetailsModal = connect(mapState, mapDispatch)(RentObjectDetailsModalComponent);
export default RentObjectDetailsModal;
