import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setValueContract } from '../../../../../reducers/contractReducer';
import ConfirmDeleteModal from '../../../../../components/confirm-delete-modal';
import get from 'lodash-es/get';

const ConfirmRemoveLeaseDurationOptionComponent = (props) => {
    const { t } = useTranslation();

    const handleDeleteClick = () => {
        if (props.deleteContractLeaseDurationOption) {
            props.deleteContractLeaseDurationOption();
        }
    };

    return (
        <ConfirmDeleteModal
            onClose={props.closeModalWindow}
            onConfirm={handleDeleteClick}
            title={t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.delete.title', { name: props.name })}
            text={t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.delete.sentence')}
            loading={props.leaseDurationOptionLoading}
            deleteBtnText={t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.delete.deleteBtnText')}
        />
    );
};

const mapState = ({ contract }) => {
    return {
        leaseDurationOptionLoading: get(contract, 'leaseDurationOptionLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showRemoveLeaseDurationModal', false));
        },
    };
};

const ConfirmRemoveLeaseDurationOption = connect(mapState, mapDispatch)(ConfirmRemoveLeaseDurationOptionComponent);
export default ConfirmRemoveLeaseDurationOption;
