import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';

const NotificationWarning = ({ textElements, onClose }) => {
    return (
        <div className="notification-warning">
            <div className="warning-icon-container">
                <ExclamationCircleOutlined className="warning-icon" />
            </div>
            <div className="d-flex flex-column">{textElements}</div>
            {onClose && <CloseOutlined className="close-icon" onClick={onClose} />}
        </div>
    );
};

export default NotificationWarning;
