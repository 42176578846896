import { Checkbox, Form, Radio, Select, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import { Image } from 'react-bootstrap';
import required from '../../../../assets/images/required.svg';
import { enterBtnCode } from '../../../../common/constants';
import { assignedTypeOptions, rentObjectRentalType, rentObjectRentalTypeOptions, unitType, unitTypeOptions } from '../../../../common/contract-options';
import CommonInput from '../../../../components/common-input';
import PositiveNumericInput from '../../../../components/numeric-input';
import helper from '../../../../helpers/helper';

const { Option } = Select;

export const EXTERIOR_VALUE = 'Exterior';

const RentalObjectEditFormComponent = (props) => {
    const { t } = useTranslation();

    const [rentObjectHasName, setRentObjectHasName] = useState(props.rentObject.rentObjectHasName);
    const [assignedType, setAssignedType] = useState(props.rentObject.plotId ? assignedTypeOptions.plot : assignedTypeOptions.building);
    const [floors, setFloors] = useState([]);
    const [selectedRentType, setSelectedRentType] = useState(props.rentObject.rentObjectRentalType);
    const [assignedFloorIds, setAssignedFloorIds] = useState(props.rentObject?.isExterior ? [EXTERIOR_VALUE] : (props.rentObject.assignedFloorIds || []));
    const [isExterior, setIsExterior] = useState(props.rentObject?.isExterior);

    const plotOptions = props.plots
        ? props.plots.map((plot) => {
              return { id: 'p-' + plot.id, name: plot.displayName, value: plot.id };
          })
        : [];

    const buildingOptions = props.buildings
        ? props.buildings.map((building) => {
              return { id: 'b-' + building.id, name: building.name || building.address, value: building.id };
          })
        : [];

    const floorOptions = () => {
        const assignedBuilding = props.buildings?.find((x) => x.id == props.rentalRef?.current?.getFieldValue('buildingId'));

        return assignedBuilding?.floors
            ? assignedBuilding?.floors.map((floor) => {
                  return { id: 'f-' + floor.id, name: floor.level, value: floor.id };
              })
            : [];
    };

    const assignPlotOrBuilding = (value, i) => {
        setFloors(floorOptions());
        setAssignedFloorIds([]);

        if (props.assignPlotOrBuilding) {
            props.assignPlotOrBuilding(value, i.info.type);
        }
    };

    const onChangeHasName = (e) => {
        setRentObjectHasName(e.target.checked);
    };

    const toggleHasCustomDuration = () => {
        if (props.onChangeHasCustomDuration) {
            props.onChangeHasCustomDuration(!props.hasCustomDuration);
        }
    };

    const onChangeAssignEntity = (e) => {
        const amount = props.rentalRef.current.getFieldValue('amount');
        if (amount) {
            props.rentalRef.current.validateFields(['amount']);
        }
        setAssignedType(e.target.value);
    };

    const onChangeFloor = (values) => {
        const exterior = values.includes(EXTERIOR_VALUE)
        setIsExterior(exterior);

        const floors = exterior ? [EXTERIOR_VALUE] : values;
        setAssignedFloorIds(floors);
        props.rentalRef.current.setFieldsValue({ assignedFloorIds: floors });
        props.rentalRef.current.validateFields(['assignedFloorIds']);
    };

    const getFloorName = (floorId) => {
        const floor = floors?.find((x) => x.value === floorId);
        if (floor) {
            return floor.name > 0
                ? `${floor.name} ${t('contract.durationAndRentalObjects.rentObject.assign.floorLevel')}`
                : `${floor.name} ${t('contract.durationAndRentalObjects.rentObject.assign.basementLevel')}`;
        }
        return null;
    };

    const removeFloor = (floorId) => {
        const floorIds = assignedFloorIds.filter((x) => x != floorId);

        setAssignedFloorIds(floorIds);
        props.rentalRef.current.setFieldsValue({ assignedFloorIds: floorIds });
        props.rentalRef.current.validateFields(['assignedFloorIds']);
    };

    const validateRentObjectAmount = () => ({
        validator(rule, value) {

            if (assignedType === assignedTypeOptions.building) {
                const building = props.buildings?.find((x) => x.id === props.rentalRef.current.getFieldValue('buildingId'));
                if (building && building.lettable) {
                    if (!value || building.lettable >= value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('contract.durationAndRentalObjects.rentObject.amount.errorMessage')));
                }
            }
            return Promise.resolve();
        },
    });

    const floorsSelectorDisabled = !(floors && floors.length);

    useEffect(() => {
        if (props.rentObject.name) {
            setRentObjectHasName(true);
        }
        setFloors(floorOptions);
    }, []);

    const handleRentTypeChange = (value) => {
        setSelectedRentType(value);
        setAssignedFloorIds([]);
        props.rentalRef.current.resetFields(['assignedFloorIds']);
        props.rentalRef.current.validateFields(['assignedFloorIds']);
        setIsExterior(false);
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg={12} xl={6}>
                    <Checkbox checked={rentObjectHasName} className="ml-1 mb-3" onChange={onChangeHasName}>
                        {t('contract.durationAndRentalObjects.rentObject.details.checkboxTitle')}
                    </Checkbox>
                    {rentObjectHasName && (
                        <Form.Item name="name">
                            <CommonInput
                                title={t('contract.durationAndRentalObjects.rentObject.rentalName.title')}
                                placeholder={t('contract.durationAndRentalObjects.rentObject.rentalName.placeholder')}
                            />
                        </Form.Item>
                    )}
                    <p className="font-weight-bold mb-2">
                        <Image className="required-img" src={required} />
                        {t('contract.durationAndRentalObjects.rentObject.type.title')}
                    </p>
                    <Form.Item
                        name="rentObjectRentalType"
                        rules={[
                            {
                                required: true,
                                message: t('contract.durationAndRentalObjects.rentObject.type.title') + t('common.isRequired'),
                            },
                        ]}>
                        <Select placeholder={t('contract.durationAndRentalObjects.rentObject.type.title')} dropdownClassName="new-design-dropdown" onChange={(value) => handleRentTypeChange(value)}>
                            {rentObjectRentalTypeOptions.filter(item => item.id != rentObjectRentalType.total).map((object) => (
                                <Option value={object.id} key={object.id}>
                                    {object.name()}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <p className="font-weight-bold mb-2">
                        <Image className="required-img" src={required} />
                        {t('contract.durationAndRentalObjects.rentObject.assign.title')}
                    </p>
                    <Radio.Group defaultValue={assignedType} className="radio-container vertical-radio-container my-3" onChange={onChangeAssignEntity}>
                        <Radio value={'building'}>{t('contract.durationAndRentalObjects.rentObject.assign.radioBtns.building')}</Radio>
                        <Radio value={'plot'}>{t('contract.durationAndRentalObjects.rentObject.assign.radioBtns.plot')}</Radio>
                    </Radio.Group>
                    {assignedType === 'building' ? (
                        <Form.Item
                            name="buildingId"
                            initialValue={props.rentObject.buildingId}
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.durationAndRentalObjects.rentObject.assign.title') + t('common.isRequired'),
                                },
                            ]}>
                            <Select
                                placeholder={t('contract.durationAndRentalObjects.rentObject.assign.placeholderBuilding')}
                                defaultValue={props.rentObject.buildingId || null}
                                dropdownClassName="new-design-dropdown"
                                onChange={(e, i) => assignPlotOrBuilding(e, i)}>
                                {buildingOptions.map((option) => (
                                    <Option value={option.value} info={{ type: 'building' }} key={option.id}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name="plotId"
                            initialValue={props.rentObject.plotId}
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.durationAndRentalObjects.rentObject.assign.title') + t('common.isRequired'),
                                },
                            ]}>
                            <Select
                                placeholder={t('contract.durationAndRentalObjects.rentObject.assign.placeholderPlot')}
                                defaultValue={props.rentObject.plotId || null}
                                dropdownClassName="new-design-dropdown"
                                onChange={(e, i) => assignPlotOrBuilding(e, i)}>
                                {plotOptions.map((option) => (
                                    <Option value={option.value} info={{ type: 'plot' }} key={option.id}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    {assignedType === assignedTypeOptions.building && <p className="font-weight-bold mb-2"><Image className="required-img" src={required} />{t('contract.durationAndRentalObjects.rentObject.chooseFloors.title')}</p>}
                    {assignedType === assignedTypeOptions.building && (
                        <Form.Item
                            name="assignedFloorIds"
                            initialValue={assignedFloorIds}
                            rules={
                                !floorsSelectorDisabled
                                    ? [
                                          {
                                              required: true,
                                              message: t('contract.durationAndRentalObjects.rentObject.chooseFloors.title') + t('common.isRequired'),
                                          },
                                      ]
                                    : []
                            }>
                            <div className="multiple-cards-container">
                                <div className="select-container multiple-cards">
                                    {floorsSelectorDisabled ? (
                                        <Tooltip title={t('contract.durationAndRentalObjects.rentObject.missingBuildingFloorsWarning')} placement="top">
                                            <Select
                                                disabled={floorsSelectorDisabled}
                                                mode="multiple"
                                                maxTagCount="responsive"
                                                showArrow
                                                dropdownClassName="new-design-dropdown"
                                                onChange={onChangeFloor}
                                                value={assignedFloorIds}
                                                placeholder={t('contract.durationAndRentalObjects.rentObject.assign.placeholderFloor')}
                                                onKeyDown={(e) => (e.keyCode === enterBtnCode ? e.preventDefault() : '')}>
                                                {floors.sort((a, b) => a.name > b.name ? 1 : -1).map((option) => (
                                                    <Option value={option.value} info={{ type: 'floor' }} key={option.id}>
                                                        <p className="mb-0">{getFloorName(option.value)}</p>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Tooltip>
                                    ) : (
                                        <Select
                                            mode="multiple"
                                            maxTagCount="responsive"
                                            showArrow
                                            dropdownClassName="new-design-dropdown"
                                            onChange={onChangeFloor}
                                            value={assignedFloorIds}
                                            placeholder={t('contract.durationAndRentalObjects.rentObject.assign.placeholderFloor')}
                                            onKeyDown={(e) => (e.keyCode === enterBtnCode ? e.preventDefault() : '')}>
                                            {floors.sort((a, b) => a.name > b.name ? 1 : -1).map((option) => (
                                                <Option value={option.value} info={{ type: 'floor' }} disabled={isExterior} key={option.id}>
                                                    <p className="mb-0">{getFloorName(option.value)}</p>
                                                </Option>
                                            ))}
                                            {rentObjectRentalTypeOptions.find((item) => item.id == selectedRentType)?.unitType == unitType.numberOfUnits && (
                                                <Option value={EXTERIOR_VALUE} info={{ type: 'floor' }} key={floors.length + 1}>
                                                    <p className="mb-0">{t('contract.durationAndRentalObjects.rentObject.exterior')}</p>
                                                </Option>
                                            )}
                                        </Select>
                                    )}
                                </div>
                            </div>
                        </Form.Item>
                    )}
                    <p className="font-weight-bold mb-2">
                        <Image className="required-img" src={required} />
                        {t('contract.durationAndRentalObjects.rentObject.amount.title')}
                    </p>
                    <Form.Item
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: t('contract.durationAndRentalObjects.rentObject.amount.title') + t('common.isRequired'),
                            },
                            validateRentObjectAmount,
                        ]}>
                        <PositiveNumericInput placeholder={t('contract.durationAndRentalObjects.rentObject.amount.placeholder')} min={1} suffix={helper.getEnumValue(unitTypeOptions, rentObjectRentalTypeOptions.find(item => item.id == selectedRentType)?.unitType)} />
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const mapState = ({ property }) => {
    return {
        plots: get(property, 'property.plots'),
        buildings: get(property, 'property.buildings'),
    };
};

const RentalObjectEditForm = connect(mapState, null)(RentalObjectEditFormComponent);
export default RentalObjectEditForm;
