import React from 'react';
import TextEllipsis from '../ellipsis-text';

const PropertyRow = ({ title, value, className, style }) => {
    return value ? (
        <div className={`property-row ${className ? className : ''}`} style={style}>
            <span className="text-secondary mr-2">{title}:</span>
            <TextEllipsis>
                <span className="ellipsis">{value}</span>
            </TextEllipsis>
        </div>
    ) : null;
};

export default PropertyRow;
