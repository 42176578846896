import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setValueContract } from '../../../../../reducers/contractReducer';
import { primaryDateFormat } from '../../../../../components/dateFormatter/dateFormats';
import ConfirmDeleteModal from '../../../../../components/confirm-delete-modal';
import get from 'lodash-es/get';
import moment from 'moment';

const ConfirmDeleteContractOptionComponent = (props) => {
    const { t } = useTranslation();

    const handleCloseClick = () => {
        props.closeModalWindow();
    };

    const handleDeleteClick = () => {
        if (props.openContractOptionOnDelete) {
            props.openContractOptionOnDelete();
        }
    };

    return (
        <ConfirmDeleteModal
            onClose={handleCloseClick}
            onConfirm={handleDeleteClick}
            title={t('contract.leaseDurationOptionAndContractOptionModal.contractOption.delete.title', {
                name: props?.contractOptionModel?.name || moment(props?.contractOptionModel?.date).format(primaryDateFormat),
            })}
            text={t('contract.leaseDurationOptionAndContractOptionModal.contractOption.delete.sentence')}
            loading={props.contractOptionLoading}
            deleteBtnText={t('contract.leaseDurationOptionAndContractOptionModal.contractOption.delete.deleteBtnText')}
        />
    );
};

const mapState = ({ contract }) => {
    return {
        contractOptionLoading: get(contract, 'contractOptionLoading'),
        contractOptionModel: get(contract, 'contractOptionModel'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showRemoveContractOptionModal', false));
        },
    };
};

const ConfirmDeleteContractOption = connect(mapState, mapDispatch)(ConfirmDeleteContractOptionComponent);
export default ConfirmDeleteContractOption;
