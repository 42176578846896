import get from 'lodash-es/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ConfirmDeleteModal from '../../../../components/confirm-delete-modal';
import { defaultEvent, deleteEvent, setValueEvent } from '../../../../reducers/eventsReducer';

const ConfirmDeleteEventComponent = (props) => {
    const { t } = useTranslation();

    const onClose = () => {
        props.closeModalWindow();
    };

    const deleteEvent = () => {
        props.deleteEventCall(props.event.eventId, props.portfolioId).then((isDeleted) => {
            if (isDeleted) {
                props.closeModalWindow();
                if (props.afterDelete) {
                    props.setValueEventCall('event', defaultEvent);
                    props.afterDelete(props.event);
                }
            }
        });
    };

    return (
        <ConfirmDeleteModal
            onClose={onClose}
            onConfirm={deleteEvent}
            title={t('events.deleteModal.title', { name: props.event.title })}
            text={t('events.deleteModal.sentence')}
            loading={props.deleteLoading}
            deleteBtnText={t('events.deleteModal.deleteBtnText')}
        />
    );
};

const mapState = ({ events, navigation }) => {
    return {
        event: get(events, 'event'),
        deleteLoading: get(events, 'deleteLoading'),
        portfolioId: get(navigation, 'selectedPortfolio.id'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueEvent('showConfirmDeleteEventModalWindow', false));
        },
        deleteEventCall(eventId, portfolioId) {
            return dispatch(deleteEvent(eventId, portfolioId));
        },
        setValueEventCall(key, value) {
            dispatch(setValueEvent(key, value));
        },
    };
};

const ConfirmDeleteEvent = connect(mapState, mapDispatch)(ConfirmDeleteEventComponent);
export default ConfirmDeleteEvent;
