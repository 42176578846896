import { CheckCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table, Tag } from 'antd';
import get from 'lodash-es/get';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ActionButton from '../../../components/action-button';
import DateFormatter from '../../../components/dateFormatter/dateFormatter';
import LoaderSpinner from '../../../components/spinner';
import { setValueBackoffice } from '../../../reducers/backofficeReducer';
import permissionsService from '../../../services/permissionsService';
import { userPermisionsTypes, userPermisionsTypesOptions } from '../../../common/constants';
import helper from '../../../helpers/helper';

const UsersComponent = (props) => {
    const { t } = useTranslation();

    const [expKeys, setExpKeys] = useState([]);

    useEffect(() => {
        openCurrentUser();
    }, []);

    const openCurrentUser = (_) => {
        if (props.currentUser) {
            setExpKeys([props.currentUser]);
            props.setValueBackofficeCall('currentUser', null);
        } else {
            setExpKeys([]);
        }
    };

    const onChangePage = (value) => {
        props.setValueBackofficeCall('currentPage', value);
    };

    const showInvoices = (propertyId, user) => {
        const property = user.properties.find((x) => x.propertyId === propertyId);
        if (property) {
            props.showInvoices(property);
            props.setValueBackofficeCall('currentUser', user.email);
        }
    };

    const getPermissionTag = (record) => {
        switch (record.permissionId) {
            case userPermisionsTypes.portefolioAdmin:
                return <Tag color="blue">{helper.getEnumValue(userPermisionsTypesOptions, userPermisionsTypes.portefolioAdmin)}</Tag>

            case userPermisionsTypes.portefolioOwner:
                return <Tag color="green">{helper.getEnumValue(userPermisionsTypesOptions, userPermisionsTypes.portefolioOwner)}</Tag>

            case userPermisionsTypes.propertyEditor:
                return <Tag color="gold">{helper.getEnumValue(userPermisionsTypesOptions, userPermisionsTypes.propertyEditor)}</Tag>

            case userPermisionsTypes.propertyReader:
                return <Tag color="yellow">{helper.getEnumValue(userPermisionsTypesOptions, userPermisionsTypes.propertyReader)}</Tag>

            case userPermisionsTypes.rentRolleUser:
                return <Tag color="red">{helper.getEnumValue(userPermisionsTypesOptions, userPermisionsTypes.rentRolleUser)}</Tag>
        }
    }

    const expandedRowRender = (user) => {
        const columns = [
            {
                title: t('backoffice.usersList.table.expandedTable.portfolio'),
                dataIndex: 'portfolioName',
                key: 'portfolioName',
                width: '30%',
                render: (portfolioName, record) => (
                    <div className="portfolio-record">
                        <p className="mb-0">{portfolioName}</p>
                        <div>
                            {getPermissionTag(record)}
                        </div>
                    </div>
                ),
            },
            {
                title: t('backoffice.usersList.table.expandedTable.property'),
                dataIndex: 'propertyName',
                key: 'propertyName',
                width: '30%',
            },
            {
                title: t('backoffice.usersList.table.expandedTable.orgNumberOrFullName'),
                dataIndex: 'orgNumberOrFullName',
                key: 'orgNumberOrFullName',
                width: '30%',
            },
            {
                dataIndex: 'propertyId',
                key: 'propertyId',
                width: '10%',
                render: (propertyId) => (
                    <ActionButton className="btn-link" onClick={() => showInvoices(propertyId, user)} text={t('backoffice.usersList.table.expandedTable.show')} />
                ),
            },
        ];

        return (
            <Table
                columns={columns}
                pagination={false}
                dataSource={user.properties}
                rowKey={(property) => property.propertyId}
                locale={{ emptyText: t('backoffice.usersList.table.expandedTable.emptyListOfProperties') }}
            />
        );
    };

    const columns = [
        {
            title: t('backoffice.usersList.table.userName'),
            dataIndex: 'userName',
            width: '25%',
            sorter: (a, b) => a.userName.toUpperCase().localeCompare(b.userName.toUpperCase()),
            key: 'userName',
        },
        {
            title: t('backoffice.usersList.table.phone'),
            dataIndex: 'phone',
            width: '15%',
            sorter: (a, b) => (a.phone || '').localeCompare(b.phone || ''),
            key: 'phone',
        },
        {
            title: t('backoffice.usersList.table.email'),
            dataIndex: 'email',
            key: 'email',
            width: '30%',
            sorter: (a, b) => a.email.toUpperCase().localeCompare(b.email.toUpperCase()),
            render: (value, record) => (
                <div>
                    <span className="mr-1">{value}</span>
                    {record.isEmailConfirmed && <CheckCircleOutlined />}
                </div>
            ),
        },
        {
            title: t('backoffice.usersList.table.registeredOn'),
            dataIndex: 'registrationDate',
            key: 'registrationDate',
            width: '15%',
            sorter: (a, b) => (a.registrationDate || '').localeCompare(b.registrationDate || ''),
            render: (value) => (value ? <DateFormatter date={value} format="DD.MM.YYYY" /> : 'N/A'),
        },
        {
            title: t('backoffice.usersList.table.lastSignIn'),
            dataIndex: 'lastLoginDate',
            key: 'lastLoginDate',
            width: '15%',
            sorter: (a, b) => (a.lastLoginDate || '').localeCompare(b.lastLoginDate || ''),
            render: (value) => (value ? <DateFormatter date={value} format="DD.MM.YYYY" /> : 'N/A'),
        },
    ];

    const onExpand = (user) => {
        const items = [...expKeys];
        items.push(user.email);
        setExpKeys(items);
    };

    const onCollapse = (user) => {
        const items = [...expKeys];
        const index = items.findIndex((email) => user.email === email);
        if (index >= 0) {
            items.splice(index, 1);
            setExpKeys(items);
        }
    };

    const customExpandIcon = (props) => {
        return props.expanded ? (
            <Button className="expand-btn" icon={<MinusOutlined />} onClick={(e) => onCollapse(props.record)} />
        ) : (
            <Button className="expand-btn" icon={<PlusOutlined />} onClick={(e) => onExpand(props.record)} />
        );
    };

    return (
        <div className="h-100">
            {props.usersListLoading ? (
                <LoaderSpinner />
            ) : (
                <div className="list-page">
                    <Row className="total-info">
                        <Col xs={12} md={6} lg={6}>
                            <h3 className="mb-4">{t('backoffice.usersList.title')}</h3>
                        </Col>
                        <Col xs={12} md={3} lg={3}>
                            <div className="d-flex flex-column">
                                <p>{t('backoffice.totalInfo.users')}</p>
                                <h4>{props.totalUsersCount}</h4>
                            </div>
                        </Col>
                        <Col xs={12} md={3} lg={3}>
                            <div className="d-flex flex-column">
                                <p>{t('backoffice.totalInfo.properties')}</p>
                                <h4>{props.totalPropertiesCount}</h4>
                            </div>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={props.users}
                        pagination={{ current: props.currentPage, onChange: onChangePage }}
                        rowKey={(user) => user.email}
                        expandIcon={(props) => customExpandIcon(props)}
                        expandedRowKeys={expKeys}
                        expandedRowRender={(user) => expandedRowRender(user)}
                        className="components-table-demo-nested"
                    />
                </div>
            )}
        </div>
    );
};

const mapState = ({ backOffice }) => {
    return {
        users: get(backOffice, 'users'),
        currentUser: get(backOffice, 'currentUser'),
        currentPage: get(backOffice, 'currentPage'),
        totalUsersCount: get(backOffice, 'totalUsersCount'),
        totalPropertiesCount: get(backOffice, 'totalPropertiesCount'),
        usersListLoading: get(backOffice, 'usersListLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueBackofficeCall(key, value) {
            dispatch(setValueBackoffice(key, value));
        },
    };
};

const Users = connect(mapState, mapDispatch)(UsersComponent);
export default Users;
