import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import { invoiceIssueDateOptions, paymentPlanOptions } from '../../../common/contract-options';
import PropertyRow from '../../../components/property-row';
import TenantCard from '../../../components/tenant/tenant-card';
import ActionButton from '../../../components/action-button';
import moment from 'moment';
import get from 'lodash-es/get';
import helper from '../../../helpers/helper';
import routingService from '../../../services/routingService';
import { editPermisionTypes } from '../../../common/constants';

const ContractDetailsComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId, propertyId, tenantId, contractId } = useParams();

    const openContractOnEdit = () => {
        if (!props.hasEditPermission) return;
        routingService.navigateToEditContract(portfolioId, propertyId, tenantId, contractId);
    };

    const openTenant = (_) => {
        routingService.navigateToTenantDetails(portfolioId, propertyId, tenantId);
    };

    const hasContractDuration = props.contract.leaseStartDate || props.contract.leaseEndDate || props.contract.signatureDate;

    const hasRentRegulation = props.contract.originalBaseIndexDate || props.contract.firstTimeCpiAdjustmentDate || props.contract.cpiRate;

    const hasInvoicing = props.contract.firstInvoiceDueDate || props.contract.invoiceIssueDateOption || props.contract.paymentPlan;

    return (
        <Row className="pb-3">
            <Col md={12} lg={6}>
                <div className="details-card details-info mb-4">
                    <div className="card-header d-flex flex-row justify-content-between align-items-center">
                        <h5 className="mb-0">{t('contract.contractDetails.detailsTab.header')}</h5>
                        <ActionButton className="btn-link" text={t('common.buttons.edit')} disabled={!props.hasEditPermission} onClick={openContractOnEdit} />
                    </div>
                    <div className="main">
                        {!hasContractDuration && !hasRentRegulation && !hasInvoicing ? <p class="text-center">{t('contract.contractDetails.noData')}</p> : null}
                        {hasContractDuration ? (
                            <React.Fragment>
                                <h6 className="">{t('contract.contractDetails.detailsTab.contractDuration')}</h6>
                                <div className="properties-container">
                                    {props.contract.leaseStartDate && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.detailsTab.startDate')}
                                            value={moment(props.contract.leaseStartDate).format(primaryDateFormat)}
                                        />
                                    )}
                                    {props.contract.leaseEndDate && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.detailsTab.endDate')}
                                            value={moment(props.contract.leaseEndDate).format(primaryDateFormat)}
                                        />
                                    )}
                                    {props.contract.signatureDate && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.detailsTab.signatureDate')}
                                            value={moment(props.contract.signatureDate).format(primaryDateFormat)}
                                        />
                                    )}
                                </div>
                            </React.Fragment>
                        ) : null}
                        {hasRentRegulation ? (
                            <React.Fragment>
                                <h6>{t('contract.contractDetails.detailsTab.rentRegulation')}</h6>
                                <div className="properties-container">
                                    {props.contract.originalBaseIndexDate && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.detailsTab.cpiPeriodStart')}
                                            value={moment(props.contract.originalBaseIndexDate).format('MMM YYYY')}
                                        />
                                    )}
                                    {props.contract.firstTimeCpiAdjustmentDate && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.detailsTab.firstCpiReg')}
                                            value={moment(props.contract.firstTimeCpiAdjustmentDate).format('MMM YYYY')}
                                        />
                                    )}
                                    {props.contract.cpiRate && <PropertyRow title={t('contract.contractDetails.detailsTab.cpi')} value={props.contract.cpiRate} />}
                                </div>
                            </React.Fragment>
                        ) : null}
                        {hasInvoicing ? (
                            <React.Fragment>
                                <h6>{t('contract.contractDetails.detailsTab.invoicing')}</h6>
                                <div className="properties-container">
                                    {props.contract.firstInvoiceDueDate && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.detailsTab.firstInvoiceDueDate')}
                                            value={moment(props.contract.firstInvoiceDueDate).format(primaryDateFormat)}
                                        />
                                    )}
                                    {!!props.contract.invoiceIssueDateOption && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.detailsTab.invoiceIssueDate')}
                                            value={helper.getEnumValue(invoiceIssueDateOptions, props.contract.invoiceIssueDateOption)}
                                        />
                                    )}
                                    {!!props.contract.paymentPlan && (
                                        <PropertyRow
                                            title={t('contract.contractDetails.detailsTab.paymentPlan')}
                                            value={helper.getEnumValue(paymentPlanOptions, props.contract.paymentPlan)}
                                        />
                                    )}
                                </div>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
            </Col>
            <div className="details-card contract-details-page">
                <div className="card-header">
                    <h5 className="mb-0">{t('contract.contractDetails.detailsTab.tenant')}</h5>
                </div>
                <div className="main">
                    <TenantCard tenant={props.tenant} openTenant={openTenant} hasEditPermission={props.hasEditPermission} />
                </div>
            </div>
        </Row>
    );
};

const mapState = ({ contract, navigation, tenant }) => {
    return {
        tenant: get(tenant, 'tenant'),
        contract: get(contract, 'contract'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
    };
};

const ContractDetails = connect(mapState)(ContractDetailsComponent);
export default ContractDetails;
