import { initReactI18next } from 'react-i18next';
import languageService, { supportedLanguages } from './languageService';
import i18next from 'i18next';
import translations from '../i18n/i18n';
import HttpApi from 'i18next-http-backend';

const resources = {
    en: {
        frontend: translations.en,
    },
    no: {
        frontend: translations.no,
    },
};

const backendOptions = {
    loadPath: '%{lng}/%{ns}',
    request: (options, url, payload, callback) => {
        const [lang, module] = url.split('/');
        languageService
            .fetchLocale(lang, module)
            .then((response) => {
                callback(null, {
                    status: response.status,
                    data: response.data,
                });
            })
            .catch((error) => {
                console.error(error);
                // Fallback to use local file:
                callback(null, {
                    status: 200,
                    data: resources[lang][module],
                });
            });
    },
};

i18next
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        ns: ['frontend'],
        defaultNS: 'frontend',
        lng: languageService.getDefaultLanguage(),
        fallbackLng: supportedLanguages.English,
        keySeparator: false, // (if working with a flat json, it's recommended to set keySeparator to false)
        supportedLngs: Object.values(supportedLanguages), //['en', 'no'],
        debug: process.env.REACT_APP_STAGE !== 'prod',
        interpolation: {
            escapeValue: false,
            prefix: '%{',
            suffix: '}',
        },
        backend: backendOptions,
    });

Number.prototype.toLocalizedString = function () {
    const locale = languageService.getDefaultLanguage();
    return this.toLocaleString(locale);
};

export default i18next;
