import { Form, Select, Switch } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import add from '../../assets/images/plus.svg';
import emptyCommonCost from '../../assets/images/empty-cost.svg';
import ActionButton from '../../components/action-button';
import EmptyList from '../../components/empty-list/empty-list';
import OwnerCostCard from '../../components/owner-cost/owner-cost-card';
import LoaderSpinner from '../../components/spinner';
import { deleteOwnerCost, getListOfOwnerCosts, setValueOwnerCost } from '../../reducers/ownerCostReducer';
import routingService from '../../services/routingService';
import ConfirmDeleteOwnerCost from './modals/confirm-delete-modal';
import './index.scss';
import { filterOptions } from '../../common/property-options';
import { editPermisionTypes } from '../../common/constants';

const { Option } = Select;

const OwnerCostsComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId, propertyId } = useParams();
    const listRef = React.useRef();
    const [filteredOwnerCosts, setFilteredOwnerCosts] = useState(props.ownerCosts || []);
    const plots = useSelector(({ property }) => property.property.plots);

    useEffect(() => {
        props.getListOfOwnerCostsCall(portfolioId, propertyId, moment().year());
    }, [props.filterOption]);

    useEffect(() => {
        const ownerCostsYears = props.ownerCosts?.map((c) => c.year);
        if (ownerCostsYears && ownerCostsYears.length) {
            ownerCostsYears.sort((f, s) => s - f);
            const newestYear = ownerCostsYears[0];

            setFilteredOwnerCosts(props.ownerCosts.filter((ownerCost) => newestYear === ownerCost.year));
            listRef.current.setFieldsValue({ year: props.hasOwnerCosts ? newestYear : null });
        } else {
            setFilteredOwnerCosts(props.ownerCosts.filter((ownerCost) => moment().year() === ownerCost.year));
            listRef.current.setFieldsValue({ year: props.hasOwnerCosts ? moment().year() : null });
        }

        const selectedYear = props.location?.state?.selectedYear;
        if (selectedYear) {
            const parsedYear = moment.utc(selectedYear).year();

            onSelectYear(parsedYear);
            listRef.current.setFieldsValue({ year: props.hasOwnerCosts ? parsedYear : null });
        }
    }, [props.ownerCosts]);

    const createCommonCost = () => {
        if (!props.hasEditPermission) return;
        routingService.navigateToCreateOwnerCost(portfolioId, propertyId);
    };

    const onSelectYear = (year) => {
        setFilteredOwnerCosts(props.ownerCosts.filter((ownerCost) => year === ownerCost.year));
    };

    const openOwnerCost = (ownerCostId) => {
        routingService.navigateToOwnerCostDetails(portfolioId, propertyId, ownerCostId);
    };

    const handleDeleteOwnerCost = async () => {
        await props.deleteOwnerCostCall(props.deleteOwnerCostModel.propertyId, props.deleteOwnerCostModel.id, portfolioId);
        const list = props.ownerCosts.filter((x) => x.id !== props.deleteOwnerCostModel.id);
        props.setValueOwnerCostCall('ownerCosts', list);
    };

    const deleteOwnerCost = (ownerCost) => {
        props.setDeleteOwnerCostModelCall(ownerCost);
        props.showOwnerCostDeleteModal();
    };

    const editOwnerCost = (ownerCost) => {
        props.setValueOwnerCostCall('ownerCost.editableOwnerCostMode', true);
        props.setValueOwnerCostCall('ownerCost.editableOwnerCost', ownerCost);
        routingService.navigateToEditOwnerCost(portfolioId, propertyId, ownerCost.id);
    };

    const addNewCommonCostBtn = (className) => (
        <ActionButton
            className={`btn-primary width-fit-content ${className ? className : ''}`}
            icon={<Image src={add} />}
            text={t('ownerCost.btnAddNew')}
            disabled={!props.hasEditPermission || plots.length === 0 || props.isArchived}
            onClick={createCommonCost}
        />
    );

    return (
        <div className="list common-costs">
            <div className="search-header">
                <Form name="commonCostsList" autoComplete="off" ref={listRef}>
                    <div className="select-wrapper">
                        <Form.Item name="year">
                            <Select onSelect={onSelectYear} placeholder={t('commonCost.selectYearPlaceholder')} disabled={!props.hasOwnerCosts}>
                                {props.ownerCostsYears.map((year) => (
                                    <Option value={year} key={year}>
                                        {year}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Switch
                            checkedChildren="Active"
                            checked={props.filterOption == 1 ? true : false}
                            unCheckedChildren="Archived"
                            onChange={(checked) => props.setValueOwnerCostCall('filterOption', checked ? filterOptions.active : filterOptions.archived)}
                        />
                    </div>
                </Form>
                {addNewCommonCostBtn()}
            </div>
            {props.loading ? (
                <LoaderSpinner />
            ) : props.hasOwnerCosts ? (
                <div className="cards">
                    {filteredOwnerCosts.map((ownerCost) => (
                        <OwnerCostCard
                            key={ownerCost.id}
                            ownerCost={ownerCost}
                            openOwnerCost={openOwnerCost}
                            editOwnerCost={editOwnerCost}
                            deleteOwnerCost={deleteOwnerCost}
                            hasEditPermission={props.hasEditPermission}
                            property={props.property}
                            isArchived={props.isArchived}
                        />
                    ))}
                </div>
            ) : (
                <EmptyList image={emptyCommonCost} title={t('ownerCost.emptyList.title')} subTitle={t('ownerCost.emptyList.subTitle')}>
                    {addNewCommonCostBtn('hide-on-mobile')}
                </EmptyList>
            )}
            {props.showConfirmDeleteOwnerCostModal && <ConfirmDeleteOwnerCost handleDeleteOwnerCost={handleDeleteOwnerCost} />}
            {/* {
            props.showBudgetsAddResultModal &&
            <CommonCostBudgetsAddResult
                portfolioId={portfolioId}
                propertyId={propertyId}
                commonCostId={props.location.state.commonCostId}
            />
        }
        {
            props.showCommonCostUpdatedResultModal &&
            <CommonCostUpdateResult />
        } */}
        </div>
    );
};

const mapState = ({ navigation, ownerCost, commonCost, property }) => {
    return {
        loading: get(ownerCost, 'ownerCostLoading'),
        ownerCosts: get(ownerCost, 'ownerCosts'),
        hasOwnerCosts: get(ownerCost, 'ownerCosts.length') > 0,
        ownerCostsYears: get(ownerCost, 'ownerCostsYears'),
        showBudgetsAddResultModal: get(commonCost, 'showBudgetsAddResultModal'),
        showCommonCostUpdatedResultModal: get(commonCost, 'showCommonCostUpdatedResultModal'),
        showConfirmDeleteOwnerCostModal: get(ownerCost, 'showConfirmDeleteOwnerCostModal'),
        deleteOwnerCostModel: get(ownerCost, 'deleteOwnerCostModel'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        property: get(property, 'property'),
        filterOption: get(ownerCost, 'filterOption'),
        isArchived: get(property, 'property.isArchived'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        deleteOwnerCostCall(propertyId, ownerCostId, portfolioId) {
            return dispatch(deleteOwnerCost(propertyId, ownerCostId, portfolioId));
        },
        getListOfOwnerCostsCall(portfolioId, propertyId, year) {
            return dispatch(getListOfOwnerCosts(portfolioId, propertyId, year));
        },
        showOwnerCostDeleteModal() {
            dispatch(setValueOwnerCost('showConfirmDeleteOwnerCostModal', true));
        },
        setDeleteOwnerCostModelCall(value) {
            dispatch(setValueOwnerCost('deleteOwnerCostModel', value));
        },
        setValueOwnerCostCall(key, value) {
            dispatch(setValueOwnerCost(key, value));
        },
    };
};

const OwnerCosts = withRouter(connect(mapState, mapDispatch)(OwnerCostsComponent));
export default OwnerCosts;
