import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Tabs, Dropdown, Menu, Button } from 'antd';
import { Image } from 'react-bootstrap';
import { deleteProperty, getPropertyById, setDeletePropertyModel, setValueProperty } from '../../reducers/propertyReducer';
import routingService from '../../services/routingService';
import clear from '../../common/clear';
import get from 'lodash-es/get';
import backArrow from '../../assets/images/back-arrow.svg';
import moreIcon from '../../assets/images/more.svg';
import BuildingUpdatedResult from '../building/modals/item-updated-result-modal';
import TenantUpdatedResult from '../tenants/modals/item-updated-result-modal';
import DeleteMenuItem from '../../components/menu-items/delete-menu-item';
import PlotUpdatedResult from '../plot/modals/item-updated-result-modal';
import EditMenuItem from '../../components/menu-items/edit-menu-item';
import BuildingCreationResult from '../building/modals/result-modal';
import ConfirmDeleteProperty from './modals/confirm-delete-modal';
import TenantCreationResult from '../tenants/modals/result-modal';
import MainLayout from '../../components/layouts/main-layout';
import PlotCreationResult from '../plot/modals/result-modal';
import TextEllipsis from '../../components/ellipsis-text';
import NotFoundPage from '../../components/error-page';
import CommonCosts from '../common-cost';
import PropertyDashboard from '../property-dashboard';
import PropertyDetails from './tabs/property-details';
import LoaderSpinner from '../../components/spinner';
import Events from '../../components/events';
import Documents from './tabs/documents';
import Buildings from '../building';
import Tenants from '../tenants';
import Plots from '../plot';
import OwnerCosts from '../owner-cost';
import RentRoll from './rent-roll';
import { editPermisionTypes, userPermisionsTypes } from '../../common/constants';

const { TabPane } = Tabs;

export const propertyTabs = {
    dashboard: '1',
    events: '2',
    tenants: '3',
    plots: '4',
    buildings: '5',
    documents: '6',
    details: '7',
    commonCosts: '8',
    ownerCosts: '9',
    rentRoll: '10',
    getKeyName: function (value) {
        return Object.keys(this).find((key) => this[key] === value) || 'dashboard';
    },
};

const OpenedPropertyComponent = (props) => {
    const { t } = useTranslation();
    const { propertyId, portfolioId } = useParams();
    const [currentTabKey, setCurrentTabKey] = useState(props.isRentRollPermision ? propertyTabs.rentRoll : propertyTabs.dashboard);

    useEffect(() => {
        if (!props.isRentRollPermision && props.selectedPortfolio != null) {
            props.getPropertyCall(propertyId, portfolioId);
        }

        const params = new URLSearchParams(decodeURI(props.location.search));
        const selectedTab = propertyTabs[params.has('tab') ? params.get('tab') : ''];
        if (selectedTab && currentTabKey !== selectedTab) {
            setCurrentTabKey(selectedTab);
        }
    }, [props.selectedPortfolio]);

    const changeTab = (id) => {
        setCurrentTabKey(id);
        const params = new URLSearchParams(props.location.search);
        const selectedTabName = propertyTabs.getKeyName(id);
        params.set('tab', selectedTabName);
        routingService.replace('?' + params.toString());
    };

    const onEditClick = () => {
        routingService.navigateToEditProperty(portfolioId, props.property.id);
    };

    const onDeleteClick = (_) => {
        props.setDeletePropertyModelCall(props.property);
        props.showConfirmDeletePropertyModalCall();
    };

    const handleDeleteProperty = async () => {
        await props.deletePropertyCall(props.property.id, portfolioId);
        routingService.navigateToProperties(portfolioId);
    };

    const dropdownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" disabled={!props.hasEditPermission} onClick={onEditClick} text={t('property.editProperty')} />
                <DeleteMenuItem key="1" disabled={!props.hasEditPermission} onClick={onDeleteClick} text={t('property.deleteProperty')} />
            </Menu>
        );
    };

    return (
        <MainLayout>
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {(!props.portfolioNotFound && !props.propertyNotFound && props.property.id && !props.propertyLoading) || props.isRentRollPermision ? (
                <div className="details-page">
                    <div className="title-container">
                        <div className="title">
                            {!props.isRentRollPermision && (
                                <>
                                    <Link to={routingService.propertiesUrl(portfolioId)}>
                                        <Image className="m-0" src={backArrow} />
                                    </Link>
                                    <TextEllipsis>
                                        <h3 className="mb-0 ellipsis">{props.property.propertyName}</h3>
                                    </TextEllipsis>
                                    <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu()}>
                                        <Button className="btn-link px-0">
                                            <Image className="m-0" src={moreIcon} />
                                        </Button>
                                    </Dropdown>
                                </>
                            )}
                        </div>
                    </div>
                    <Tabs activeKey={currentTabKey} onChange={changeTab}>
                        {!props.isRentRollPermision && (
                            <>
                                <TabPane tab={t('property.dashboard.tab')} key={propertyTabs.dashboard}>
                                    <PropertyDashboard />
                                </TabPane>
                                <TabPane tab={t('events.header')} key={propertyTabs.events}>
                                    <Events />
                                </TabPane>
                            </>
                        )}
                        <TabPane tab={t('property.rentRoll.tab')} key={propertyTabs.rentRoll}>
                            <RentRoll />
                        </TabPane>
                        {!props.isRentRollPermision && (
                            <>
                                <TabPane tab={t('tenant.tenants')} key={propertyTabs.tenants}>
                                    <Tenants />
                                </TabPane>
                                <TabPane tab={t('plot.plots')} key={propertyTabs.plots}>
                                    <Plots />
                                </TabPane>
                                <TabPane tab={t('building.buildings')} key={propertyTabs.buildings}>
                                    <Buildings />
                                </TabPane>
                                <TabPane tab={t('property.propertyDetails')} key={propertyTabs.details}>
                                    <PropertyDetails />
                                </TabPane>
                                <TabPane tab={t('property.commonCosts')} key={propertyTabs.commonCosts}>
                                    <CommonCosts />
                                </TabPane>
                                <TabPane tab={t('property.ownerCosts')} key={propertyTabs.ownerCosts}>
                                    <OwnerCosts />
                                </TabPane>
                                <TabPane tab={t('property.documents.tab')} key={propertyTabs.documents}>
                                    <Documents />
                                </TabPane>
                            </>
                        )}
                    </Tabs>
                </div>
            ) : (
                <LoaderSpinner />
            )}
            {props.plotCreationResult && <PlotCreationResult portfolioId={portfolioId} propertyId={propertyId} />}
            {props.plotUpdatedResult && <PlotUpdatedResult />}
            {props.buildingCreationResult && <BuildingCreationResult portfolioId={portfolioId} propertyId={propertyId} />}
            {props.buildingUpdatedResult && <BuildingUpdatedResult />}
            {props.tenantsCreationResult && <TenantCreationResult setCurrentTabKey={setCurrentTabKey} portfolioId={portfolioId} propertyId={propertyId} />}
            {props.tenantUpdatedResult && <TenantUpdatedResult />}
            {props.showConfirmDeletePropertyModal && <ConfirmDeleteProperty handleDeleteProperty={handleDeleteProperty} />}
        </MainLayout>
    );
};

const mapState = ({ portfolio, property, plot, building, tenant, navigation }) => {
    return {
        property: get(property, 'property'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
        plotCreationResult: get(plot, 'showCreationResultModal'),
        plotUpdatedResult: get(plot, 'showItemUpdatedResultModal'),
        buildingCreationResult: get(building, 'showCreationResultModal'),
        buildingUpdatedResult: get(building, 'showItemUpdatedResultModal'),
        tenantsCreationResult: get(tenant, 'showCreationResultModal'),
        tenantUpdatedResult: get(tenant, 'showItemUpdatedResultModal'),
        propertyLoading: get(property, 'propertyLoading'),
        showConfirmDeletePropertyModal: get(property, 'showConfirmDeletePropertyModal'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        selectedPortfolio: get(navigation, 'selectedPortfolio'),
        isRentRollPermision: get(navigation, 'selectedPortfolio.permissionId') == userPermisionsTypes.rentRolleUser,
    };
};

const mapDispatch = (dispatch) => {
    return {
        getPropertyCall(propertyId, portfolioId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        showConfirmDeletePropertyModalCall() {
            dispatch(setValueProperty('showConfirmDeletePropertyModal', true));
        },
        setDeletePropertyModelCall(value) {
            dispatch(setDeletePropertyModel(value));
        },
        deletePropertyCall(propertyId, portfolioId) {
            return dispatch(deleteProperty(propertyId, portfolioId));
        },
    };
};

const OpenedProperty = clear(connect(mapState, mapDispatch)(OpenedPropertyComponent));
export default OpenedProperty;
