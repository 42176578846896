import axios from 'axios';

const contractDetailsService = {
    updateBasicContractDetails: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/contractdetails/set-basic-contract-details?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updateContractDurationDetails: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/contractdetails/set-contract-duration?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updateContractDurationDetailsAndCreateRentalObjects: (contractDuration, rentObjects, contractId, propertyId, portfolioId) => {
        return axios.all([
            axios({
                method: 'put',
                data: contractDuration,
                url: `/api/contract/contractdetails/set-contract-duration?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
            }),
            axios({
                method: 'post',
                data: rentObjects,
                url: `/api/contract/rentobjects/create-rent-objects?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
            }),
        ]);
    },

    createRentalObjects: (rentObjects, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            data: rentObjects,
            url: `/api/contract/rentobjects/create-rent-objects?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updateCommonCostDetails: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/contractdetails/set-common-cost-details?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updatePaymentTerms: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/contractdetails/set-payment-terms-details?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updateRentAdjustmentOptions: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/contractdetails/set-rent-adjustment-options?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updateSignatureDate: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/contractdetails/set-signature-date?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    addContractNote: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/contractdetails/add-note?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updateContractNote: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/contractdetails/update-note?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    deleteContractNote: (noteId, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/contractdetails/delete-note?noteId=${noteId}&contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    addLeaseDurationOption: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/contractdetails/add-lease-duration-option?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updateLeaseDurationOption: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/contractdetails/update-lease-duration-option?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    deleteLeaseDurationOption: (leaseDurationOptionId, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/contractdetails/delete-lease-duration-option?leaseDurationOptionId=${leaseDurationOptionId}&contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    addContractOption: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/contractdetails/add-contract-option?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updateContractOption: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/contractdetails/update-contract-option?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    deleteContractOption: (contractOptionId, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/contractdetails/delete-contract-option?contractOptionId=${contractOptionId}&contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    upsertDurationOption: (data, contractId, propertyId, portfolioId) => {
        return axios.post(`/api/contract/contractdetails/upsert-lease-duration-option`, data, { params: { contractId, propertyId, portfolioId } });
    },

    deleteContractLeaseDurationOption: (optionId, contractId, propertyId, portfolioId) => {
        return axios.delete(`/api/contract/contractdetails/delete-lease-duration-option`, { params: { contractId, leaseDurationOptionId: optionId, propertyId, portfolioId } });
    },

    addRentExemption: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/contractdetails/add-rent-exemption?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updateRentExemption: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/contractdetails/update-rent-exemption?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    deleteRentExemption: (rentExemptionId, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/contractdetails/delete-rent-exemption?rentExemptionId=${rentExemptionId}&contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    addStairRentInterval: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/contractdetails/add-stair-rent-interval?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updateStairRentInterval: (data, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/contractdetails/update-stair-rent-interval?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    deleteStairRentInterval: (stairRentIntervalId, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/contractdetails/delete-stair-rent-interval?stairRentIntervalId=${stairRentIntervalId}&contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    createWarrantyDraft: async (warranty, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'post',
            data: warranty,
            params: { portfolioId, propertyId, contractId },
            url: `/api/contract/contractdetails/add-contract-warranty-draft`,
        });
    },

    updateWarranty: async (warranty, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'put',
            data: warranty,
            params: { portfolioId, propertyId, contractId },
            url: `/api/contract/contractdetails/update-contract-warranty`,
        });
    },

    deleteWarranty: async (warrantyId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'delete',
            params: { contractId, contractWarrantyId: warrantyId, portfolioId, propertyId },
            url: `/api/contract/contractdetails/delete-contract-warranty`,
        });
    },
};

export default contractDetailsService;
