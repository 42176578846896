import axios from 'axios';

const navigationService = {
    getNavigationData: () => {
        return axios({
            method: 'post',
            url: `/api/contract/navigation`,
            data: {
                showOnlyMy: true
            }
        });
    },
};

export default navigationService;
