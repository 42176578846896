import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import userMdIcon from '../../../../assets/images/user.svg';
import { Button } from 'antd';
import get from 'lodash-es/get';
import CreateNoteModal from './create-note-modal';
import { setValueContract } from '../../../../reducers/contractReducer';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import moment from 'moment';
import ContractNotesModal from './contract-notes-modal';
import { UserOutlined } from '@ant-design/icons';
import ContractNoteObject from './contract-node-object';

const ContractNotesComponent = (props) => {
    const { t } = useTranslation();
    const [noteFormVisible, setNoteFormVisible] = useState(false);

    return (
        <div className="contract-notes-container">
            <h5>{t('contract.notes.title')}</h5>
            <p className="text-secondary mb-3">{t('contract.notes.description')}</p>
            {props.contractNotes &&
                props.contractNotes.map((note) => (
                    <ContractNoteObject note={note} />
                ))}
            <div className="mt-3">
                {!noteFormVisible && (
                    <Button className="btn-secondary mr-3" onClick={() => setNoteFormVisible(true)}>
                        {t('contract.notes.addNoteBtn')}
                    </Button>
                )}
            </div>
            <CreateNoteModal noteFormVisible={noteFormVisible} setNoteFormVisible={setNoteFormVisible} />
            {props.showContractNotesModal && <ContractNotesModal />}
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        contractNotes: get(contract, 'contractNotes'),
        hasContractNotes: get(contract, 'contractNotes.length') > 0,
        showCreateNoteModal: get(contract, 'showCreateNoteModal'),
        showContractNotesModal: get(contract, 'showContractNotesModal'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        showCreateNoteModalCall() {
            dispatch(setValueContract('showCreateNoteModal', true));
        },
        showContractNotesModalCall() {
            dispatch(setValueContract('showContractNotesModal', true));
        },
    };
};

const ContractNotes = connect(mapState, mapDispatch)(ContractNotesComponent);
export default ContractNotes;
