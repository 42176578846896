import axios from 'axios';

const eventsService = {
    defaultPagination: { currentPage: 1, itemsPerPage: 10 },

    create: (data, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/events/create-event?portfolioId=${portfolioId}`,
        });
    },

    edit: (data, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/events/update-event?portfolioId=${portfolioId}`,
        });
    },

    getRecurrentDates: (data, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/events/get-recurrent-dates?portfolioId=${portfolioId}`,
        });
    },

    delete: (eventId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/events?eventId=${eventId}&portfolioId=${portfolioId}`,
        });
    },

    getPropertyEvents: (portfolioId, propertyId, data) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/events/get-for-property?propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    getEventById: (portfolioId, id) => {
        return axios({
            method: 'get',
            url: `/api/contract/events/get-for-edit-by-id/${id}?portfolioId=${portfolioId}`,
        });
    },

    getEventForEditByIdAndGetRelatedData: async (portfolioId, propertyId, id) => {
        return await axios.all([axios.get(`/api/contract/events/get-for-edit-by-id/${id}?portfolioId=${portfolioId}`), eventsService.getEventRelatedData(portfolioId, propertyId)]);
    },

    getEventRelatedData: (portfolioId, propertyId, contractId) => {
        if (contractId) {
            return axios.get(`/api/contract/events?portfolioId=${portfolioId}&propertyId=${propertyId}&contractId=${contractId}`);
        } else if (propertyId) {
            return axios.get(`/api/contract/events?portfolioId=${portfolioId}&propertyId=${propertyId}`);
        }
        return axios.get(`/api/contract/events?portfolioId=${portfolioId}`);
    },

    getPortfolioEvents: (portfolioId, data) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/events/get-for-portfolio?portfolioId=${portfolioId}`,
        });
    },

    setAsCompleted: (portfolioId, eventTaskId) => {
        return axios({
            method: 'post',
            url: `/api/contract/events/set-as-completed?portfolioId=${portfolioId}&eventTaskId=${eventTaskId}`,
        });
    },

    setAsNotCompleted: (portfolioId, eventTaskId) => {
        return axios({
            method: 'post',
            url: `/api/contract/events/set-as-not-completed?portfolioId=${portfolioId}&eventTaskId=${eventTaskId}`,
        });
    },

    getContractEvents: (portfolioId, propertyId, contractId, data) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/events/get-for-contract?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },
};

export default eventsService;
