import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setValueContract } from '../../../reducers/contractReducer';
import get from 'lodash-es/get';
import ConfirmDeleteModal from '../../../components/confirm-delete-modal';

const ConfirmArchiveContractComponent = (props) => {
    const { t } = useTranslation();

    const handleCloseClick = () => {
        props.closeModalWindow();
    };

    const handleArchiveClick = () => {
        if (props.onConfirm) {
            props.onConfirm(props.contract);
        }
    };

    return (
        <ConfirmDeleteModal
            onClose={handleCloseClick}
            onConfirm={handleArchiveClick}
            title={t('contract.archive.title', { name: props.contract.name })}
            loading={props.loading}
            deleteBtnText={t('contract.archive.archiveBtnText')}
        />
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contractToArchive'),
        loading: get(contract, 'archiveLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('contractToArchive', null));
        },
    };
};

const ConfirmArchiveContract = connect(mapState, mapDispatch)(ConfirmArchiveContractComponent);
export default ConfirmArchiveContract;
