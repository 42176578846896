import i18next from 'i18next';

export const ownerCostItemType = {
    maintenance: 1,
    insurance: 2,
    revision: 3,
    brokerageAndRental: 4,
    legal: 5,
    consultancy: 6,
    administrationManagement: 7,
    propertyTax: 8,
    groundRent: 9,
    otherOperatingCosts: 10,
};

export const ownerCostItemTypeOptions = [
    { id: ownerCostItemType.maintenance, name: () => i18next.t('ownerCost.costs.maintenance')},
    { id: ownerCostItemType.insurance, name: () => i18next.t('ownerCost.costs.insurance') },
    { id: ownerCostItemType.revision, name: () => i18next.t('ownerCost.costs.revision') },
    { id: ownerCostItemType.brokerageAndRental, name: () => i18next.t('ownerCost.costs.brokerageAndRental') },
    { id: ownerCostItemType.legal, name: () => i18next.t('ownerCost.costs.legal') },
    { id: ownerCostItemType.consultancy, name: () => i18next.t('ownerCost.costs.consultancy') },
    { id: ownerCostItemType.administrationManagement, name: () => i18next.t('ownerCost.costs.administrationAndManagement') },
    { id: ownerCostItemType.propertyTax, name: () => i18next.t('ownerCost.costs.propertyTax') },
    { id: ownerCostItemType.groundRent, name: () => i18next.t('ownerCost.costs.groundRent') },
    { id: ownerCostItemType.otherOperatingCosts, name: () => i18next.t('ownerCost.costs.otherOperatingCosts') },
];
