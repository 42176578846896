import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';
import roof from '../../assets/images/filled-top-level.svg';
import firstFloor from '../../assets/images/filled-ground-floor.svg';
import basement from '../../assets/images/filled-basement.svg';
import floor from '../../assets/images/filled-level.svg';
import NumberFormat from '../../components/number-format';

export const AreaItem = ({ value, title }) => {
    return (
        <div className="d-flex flex-column">
            <p className="font-weight-bold">{title}</p>
            <p className="text-secondary">
                <NumberFormat value={value} />
            </p>
        </div>
    );
};

export const BuildingCommonPercents = (props) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column">
            <p className="font-weight-bold">{t('building.buildingConstructor.commonPercent')}</p>
            <p className="text-secondary">{props.value}%</p>
        </div>
    );
};

export const GetImageOfBuilding = (props) => {
    const roofZIndex = 999;
    const floorsZIndex = 500;

    const hasFloors = props.floors.length > 0;
    const hasBasementFloors = props.basementFloors.length > 0;

    return (
        <React.Fragment>
            <Image src={roof} style={{ zIndex: roofZIndex }} />
            {hasFloors &&
                props.floors.map((item) => {
                    if (item.level > 1) {
                        return <Image key={floorsZIndex + item.level} style={{ zIndex: floorsZIndex + item.level }} src={floor} />;
                    } else {
                        return <Image src={firstFloor} key={floorsZIndex + item.level} style={{ zIndex: floorsZIndex + item.level }} />;
                    }
                })}
            {hasBasementFloors &&
                props.basementFloors.map((item) => (
                    <Image src={basement} key={props.basementFloors.length + item.level} style={{ zIndex: props.basementFloors.length + item.level }} />
                ))}
        </React.Fragment>
    );
};
