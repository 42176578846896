import axios from 'axios';

const passwordService = {
    forgotPassword: (email) => {
        return axios.post(`/api/access/forgot`, {
            email,
        });
    },

    changePassword: (values) => {
        return axios.post(`/api/access/reset`, values);
    },
};

export default passwordService;
