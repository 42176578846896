import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import clear from '../../../common/clear';
import { connect } from 'react-redux';
import { Dropdown, Menu, Tabs, Button } from 'antd';
import { Col, Image, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { deleteTenant, getTenantDetails, setDeleteTenantModel, setValueTenant } from '../../../reducers/tenantReducer';
import DeleteMenuItem from '../../../components/menu-items/delete-menu-item';
import EditMenuItem from '../../../components/menu-items/edit-menu-item';
import TenantCreatedResult from '../modals/tenant-created-result-modal';
import PropertyRow from '../../../components/property-row';
import MainLayout from '../../../components/layouts/main-layout';
import ConfirmDeleteTenant from '../modals/confirm-delete-modal';
import ActionButton from '../../../components/action-button';
import TextEllipsis from '../../../components/ellipsis-text';
import NotFoundPage from '../../../components/error-page';
import LoaderSpinner from '../../../components/spinner';
import TenantSummaryInfo from './summary-info';
import routingService from '../../../services/routingService';
import toaster from '../../../common/toaster';
import get from 'lodash-es/get';
import backArrow from '../../../assets/images/back-arrow.svg';
import tenant from '../../../assets/images/new-design-tenant-icon.svg';
import moreIcon from '../../../assets/images/more.svg';
import ContractsTab from './contracts-tab';
import helper from '../../../helpers/helper';
import { editPermisionTypes, userPermisionsTypes } from '../../../common/constants';

const { TabPane } = Tabs;

export const tenantTabs = {
    details: '1',
    contracts: '2',
    getKeyName: function (value) {
        return Object.keys(this).find((key) => this[key] === value) || 'details';
    },
};

const TenantDetailsComponent = (props) => {
    const [defaultTab, setDefaultTab] = useState(tenantTabs.details);
    const { tenantId, propertyId, portfolioId } = useParams();
    const tenantsUrl = routingService.propertyTenantsUrl(portfolioId, propertyId);

    useEffect(() => {
        if (portfolioId && propertyId && tenantId) {
            props.getTenantDetails(portfolioId, tenantId, propertyId);
            setDefaultTabByQueryParameter();
        } else if (portfolioId && propertyId) {
            routingService.goTo(tenantsUrl);
        } else {
            routingService.navigateToPortfolios();
        }
    }, []);

    const setDefaultTabByQueryParameter = () => {
        const params = new URLSearchParams(decodeURI(props.location.search));
        const selectedTab = tenantTabs[params.has('tab') ? params.get('tab') : ''];
        selectedTab && setDefaultTab(selectedTab);
    };

    const goToTenantEdit = (_) => {
        if (!props.hasEditPermission) return;
        routingService.navigateToEditTenant(portfolioId, propertyId, tenantId, props.tenantDetails?.isArchived);
    };

    const onTabChange = (key) => {
        setDefaultTab(key);
        const params = new URLSearchParams(props.location.search);
        const selectedTabName = tenantTabs.getKeyName(key);
        params.set('tab', selectedTabName);
        routingService.replace('?' + params.toString());
    };

    const onDeleteClick = (_) => {
        if (!props.hasEditPermission) return;
        props.setDeleteTenantModelCall(props.tenantDetails);
        props.showTenantDeleteModal();
    };

    const handleDeleteTenant = (_) => {
        props.deleteTenantCall(props.tenantDetails.id, props.tenantDetails.propertyId, portfolioId).then((isSuccess) => {
            props.closeTenantDeleteModal();

            if (isSuccess) {
                routingService.goTo(tenantsUrl);
                toaster.success(i18next.t('common.itemDeletedSuccessfully', { name: props.tenantDetails.displayName }));
            }
        });
    };

    const dropDownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" disabled={!props.hasEditPermission || props.tenantDetails?.isArchived} onClick={goToTenantEdit} text={i18next.t('tenant.editTenant')} />
                <DeleteMenuItem key="1" disabled={!props.hasEditPermission || props.tenantDetails?.isArchived} onClick={onDeleteClick} text={i18next.t('tenant.deleteTenant')} />
            </Menu>
        );
    };

    const renderCompanyInfo = () => {
        return (
            <React.Fragment>
                <PropertyRow title={i18next.t('tenant.details.orgName.title')} value={props.tenantDetails.orgName} />
                <PropertyRow title={i18next.t('tenant.details.orgNumber.title')} value={helper.numberWithSpaces(props.tenantDetails.orgNumber)} />
                <PropertyRow title={i18next.t('tenant.details.corporateContact.title')} value={props.tenantDetails.corporateContact} />
                <PropertyRow title={i18next.t('tenant.details.corporateEmail.title')} value={props.tenantDetails.corporateEmai} />
            </React.Fragment>
        );
    };

    const renderPersonInfo = () => {
        return (
            <React.Fragment>
                <PropertyRow title={i18next.t('tenant.details.fullName.title')} value={`${props.tenantDetails.firstName || ''} ${props.tenantDetails.lastName || ''}`} />
                <PropertyRow title={i18next.t('tenant.details.phone.title')} value={props.tenantDetails.phone} />
            </React.Fragment>
        );
    };

    return (
        <MainLayout>
            {props.tenantNotFound ? <NotFoundPage header={i18next.t('tenant.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={i18next.t('property.notFound')} /> : null}
            {!props.tenantLoading && props.tenantDetails && !props.propertyNotFound && !props.tenantNotFound ? (
                <div className="details-page tenant-details">
                    <div className="title-container">
                        <div className="title">
                            <Link to={tenantsUrl}>
                                <Image className="m-0" src={backArrow} />
                            </Link>
                            {props.tenantDetails.image ? (
                                <Image src={props.tenantDetails.image} />
                            ) : (
                                <div className="tenant-image">
                                    <Image src={tenant} />
                                </div>
                            )}
                            <TextEllipsis>
                                <h3 className="ml-2 mb-0 ellipsis">{props.tenantDetails.displayName}</h3>
                            </TextEllipsis>
                            <Dropdown trigger={['click']} placement="bottomRight" overlay={dropDownMenu()}>
                                <Button className="btn-link px-0">
                                    <Image className="m-0" src={moreIcon} />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                    <Tabs onChange={onTabChange} defaultActiveKey={defaultTab}>
                        <TabPane tab={i18next.t('tenant.tabs.details')} key={tenantTabs.details}>
                            <div className="pb-3">
                                <Row>
                                    <Col lg={12}>
                                        <div className="details-card details-info">
                                            <div className="main">
                                                <Row>
                                                    <Col md={12} lg={4}>
                                                        <h5>{i18next.t('tenant.details.cardHeader')}</h5>
                                                        {props.tenantDetails.orgName ? renderCompanyInfo() : renderPersonInfo()}
                                                        <PropertyRow title={i18next.t('tenant.details.emailForInvoices.title')} value={props.tenantDetails.emailForInvoices} />
                                                        <PropertyRow title={i18next.t('tenant.details.address.title')} value={props.tenantDetails.address} />
                                                        <PropertyRow title={i18next.t('tenant.details.post.title')} value={props.tenantDetails.post} />
                                                        <PropertyRow title={i18next.t('tenant.details.municipality.title')} value={props.tenantDetails.municipality} />
                                                        <PropertyRow title={i18next.t('tenant.details.country.title')} value={props.tenantDetails.country} />
                                                    </Col>
                                                    <Col md={12} lg={8}>
                                                        <TenantSummaryInfo />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="btns">
                                                <ActionButton
                                                    className="btn-secondary"
                                                    text={i18next.t('tenant.editTenant')}
                                                    disabled={!props.hasEditPermission || props.tenantDetails?.isArchived}
                                                    onClick={goToTenantEdit}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tab={i18next.t('tenant.tabs.contracts')} key={tenantTabs.contracts}>
                            <ContractsTab />
                        </TabPane>
                    </Tabs>
                </div>
            ) : (
                <LoaderSpinner />
            )}
            {props.showConfirmDeleteModal && <ConfirmDeleteTenant handleDeleteTenant={handleDeleteTenant} />}
            {props.tenantCreatedResult && <TenantCreatedResult portfolioId={portfolioId} propertyId={propertyId} tenantId={tenantId} />}
        </MainLayout>
    );
};

const mapState = ({ property, tenant, navigation }) => {
    return {
        tenantLoading: get(tenant, 'tenantLoading'),
        tenantDetails: get(tenant, 'tenantDetails'),
        tenantNotFound: get(tenant, 'tenantNotFound'),
        showConfirmDeleteModal: get(tenant, 'showConfirmDeleteModal'),
        tenantCreatedResult: get(tenant, 'showCreateTenantResultModal'),
        propertyNotFound: get(property, 'propertyNotFound'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId'))
    };
};

const mapDispatch = (dispatch) => {
    return {
        getTenantDetails(portfolioId, tenantId, propertyId) {
            dispatch(getTenantDetails(portfolioId, tenantId, propertyId));
        },
        showTenantDeleteModal() {
            dispatch(setValueTenant('showConfirmDeleteModal', true));
        },
        closeTenantDeleteModal() {
            dispatch(setValueTenant('showConfirmDeleteModal', false));
        },
        setDeleteTenantModelCall(value) {
            dispatch(setDeleteTenantModel(value));
        },
        deleteTenantCall(tenantId, propertyId, portfolioId) {
            return dispatch(deleteTenant(tenantId, propertyId, portfolioId));
        },
    };
};

const TenantDetails = clear(connect(mapState, mapDispatch)(TenantDetailsComponent));
export default TenantDetails;
