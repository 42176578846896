import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgotPassword } from '../../reducers/newPasswordReducer';
import { requiredCommonInput, validateEmail } from '../../common/validators';
import localStorageService from '../../services/localStorageService';
import routingService from '../../services/routingService';
import toaster from '../../common/toaster';
import clear from '../../common/clear';
import get from 'lodash.get';
import LoginSignUpLayout from '../../components/layouts/login-layout';
import ActionButton from '../../components/action-button';
import CommonInput from '../../components/common-input';

const ForgotView = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onSubmit = () => {
        form.validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                props.forgotPasswordCall(values.email.trim()).then((response) => {
                    if (response) {
                        if (response.userExists) {
                            routingService.navigateToForgotPasswordInfo();
                        } else {
                            toaster.error(t('forgotPasswordPage.userIsNotExists'), null);
                        }
                    }
                });
                localStorageService.clearUser();
            })
            .catch((_) => {});
    };

    return (
        <LoginSignUpLayout header={t('forgotPasswordPage.header')}>
            <div className="forgot-password">
                <div className="d-flex flex-direction-row mb-3">
                    <p className="text-secondary">{t('forgotPasswordPage.subHeader')}</p>
                </div>

                <Form form={form} name="basic" autoComplete="off" onFinish={onSubmit}>
                    <Form.Item name="email" rules={[requiredCommonInput(t('forgotPasswordPage.form.email.title') + t('common.isRequired')), validateEmail]}>
                        <CommonInput
                            type="email"
                            submitOnEnter={true}
                            maxLength={100}
                            placeholder={t('forgotPasswordPage.form.email.placeholder')}
                            title={t('forgotPasswordPage.form.email.title')}
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <ActionButton
                                disabled={form.getFieldsError().filter(({ errors }) => errors.length).length || props.loading}
                                text={t('forgotPasswordPage.form.submitBtnText')}
                                onClick={onSubmit}
                                className="btn-primary btn-block"
                                loading={props.loading}
                            />
                        )}
                    </Form.Item>
                </Form>
                <div>
                    <span>{t('forgotPasswordPage.rememberedPassword')} </span>
                    <Link to={routingService.loginUrl()}>{t('forgotPasswordPage.backToLogin')}</Link>
                </div>
            </div>
        </LoginSignUpLayout>
    );
};

const mapState = ({ password }) => {
    return {
        loading: get(password, 'loading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        forgotPasswordCall(username) {
            return dispatch(forgotPassword(username));
        },
    };
};

const Forgot = clear(connect(mapState, mapDispatch)(ForgotView));
export default Forgot;
