import React from 'react';
import helper from '../../helpers/helper';

const NumberFormat = ({ value, prefix = '', suffix = '' }) => {

    return (
        <>
            {prefix}
            {helper.numberWithTwoDigits(value)}
            {suffix}
        </>
    );
};

export default NumberFormat;
