import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, DatePicker, Form, Modal, Select } from 'antd';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { CloseOutlined } from '@ant-design/icons';
import { addStairRentInterval, setValueContract, updateStairRentInterval } from '../../../../../reducers/contractReducer';
import { primaryDateFormat } from '../../../../../components/dateFormatter/dateFormats';
import { unitType } from '../../../../../common/contract-options';
import calendarIcon from '../../../../../assets/images/calendar-icon.svg';
import PositiveNumericInput from '../../../../../components/numeric-input';
import ActionButton from '../../../../../components/action-button';
import moment from 'moment';
import get from 'lodash-es/get';
import { useParams } from 'react-router';

const { Option } = Select;

const StairRentIntervalComponent = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [forAll, setForAll] = useState(false);
    const [currentRentObject, setCurrentRentObject] = useState();
    const { portfolioId, propertyId} = useParams();

    useEffect(() => {
        if (props.stairRentIntervalModel) {
            const values = { ...props.stairRentIntervalModel };
            values.startDate = moment(values.startDate).utc(true);
            values.endDate = moment(values.endDate).utc(true);
            setForAll(!values.rentObjectId);
            form.setFieldsValue(values);
            setCurrentRentObject(getSelectedRentObject());
        }
    }, []);

    const onClose = () => {
        props.closeModalWindow();
    };

    const addStairRentInterval = () => {
        form.validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                values.startDate = values.startDate.startOf('day').utc(true);
                values.endDate = values.endDate.startOf('day').utc(true);
                if (props.stairRentIntervalModel) {
                    values.id = props.stairRentIntervalModel.id;
                    props.updateStairRentIntervalCall(values, props.contractId, propertyId, portfolioId).then((isSuccess) => isSuccess && props.closeModalWindow());
                } else {
                    props.addStairRentIntervalCall(values, props.contractId, propertyId, portfolioId).then((isSuccess) => isSuccess && props.closeModalWindow());
                }
            })
            .catch(() => {});
    };

    const setStairForAll = () => {
        setForAll(!forAll);
        form.setFieldsValue({ rentObjectId: null });
        setCurrentRentObject(null);
        validateDates();
    };

    const handleSelectedRentObject = () => {
        validateDates();
        setCurrentRentObject(getSelectedRentObject());
    };

    const validateDates = () => {
        const startDate = form.getFieldValue('startDate');
        const endDate = form.getFieldValue('endDate');

        startDate && form.validateFields(['startDate']);
        endDate && form.validateFields(['endDate']);
    };

    const validateStartDate = (date) => {
        if (!date) {
            return Promise.resolve();
        }

        const leaseStartDate = getLeaseStartDate();
        if (leaseStartDate && date.startOf('day') < leaseStartDate.startOf('day')) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.startDate')));
        }

        if (hasOverlapWithExistingExemption(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherStairRent')));
        }

        return Promise.resolve();
    };

    const validateEndDate = (date) => {
        if (!date) {
            return Promise.resolve();
        }

        const leaseEndDate = getLeaseEndDate();
        if (leaseEndDate && date.startOf('day') > leaseEndDate) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.endDate')));
        }

        if (hasOverlapWithExistingExemption(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherStairRent')));
        }

        return Promise.resolve();
    };

    const validateStairRentIntervalAmount = (value) => {
        if (currentRentObject && value > currentRentObject.pricePerYearPerUnit) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.stairRentAmount')));
        }
        return Promise.resolve();
    };

    const getLeaseStartDate = () => {
        if (!forAll) {
            return currentRentObject ? moment(currentRentObject.leaseStartDate || props.contract.leaseStartDate) : null;
        }

        return moment(props.contract.leaseStartDate);
    };

    const getLeaseEndDate = () => {
        if (!forAll) {
            return currentRentObject ? moment(currentRentObject.leaseEndDate || props.contract.leaseEndDate) : null;
        }

        return moment(props.contract.leaseEndDate);
    };

    const getSelectedRentObject = () => {
        const selectedRentObjectId = form.getFieldValue('rentObjectId');
        return props.rentObjects.find((x) => x.id === selectedRentObjectId);
    };

    const disabledEndDate = (currentDate) => {
        const startDate = form.getFieldValue('startDate');
        if (startDate && currentDate < startDate) {
            return true;
        }

        return false;
    };

    const disabledStartDate = (currentDate) => {
        const endDate = form.getFieldValue('endDate');
        if (endDate && currentDate > endDate) {
            return true;
        }

        return false;
    };

    const hasOverlapWithExistingExemption = (date) => {
        if (!props.stairRentIntervals || !props.stairRentIntervals.length) {
            return false;
        }

        const anotherStairRentIntervals = props.stairRentIntervalModel
            ? props.stairRentIntervals.filter((x) => x.id !== props.stairRentIntervalModel.id)
            : props.stairRentIntervals;

        for (const exemption of anotherStairRentIntervals) {
            if (date.isBetween(exemption.startDate, exemption.endDate)) {
                return true;
            }
        }

        return false;
    };

    return (
        <Modal
            wrapClassName="result-modal creation-modal"
            footer={null}
            visible={true}
            maskClosable={false}
            onCancel={onClose}
            closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <h4 className="mb-0">
                        {props.stairRentIntervalModel ? t('contract.finances.rentExemptions.editStairRent') : t('contract.finances.rentExemptions.addStairRent')}
                    </h4>
                </div>
                <Form form={form} autoComplete="off">
                    <div className="main">
                        <Checkbox checked={forAll} className="mb-3" onChange={setStairForAll}>
                            {t('contract.finances.rentExemptions.stairModal.addForAll')}
                        </Checkbox>
                        <p className="mb-2 font-weight-bold">{t('contract.finances.rentExemptions.stairModal.rentalName')}</p>
                        <Form.Item
                            name="rentObjectId"
                            rules={[
                                {
                                    required: !forAll,
                                    message: t('contract.rentObject') + t('common.isRequired'),
                                },
                            ]}>
                            <Select disabled={forAll} onChange={handleSelectedRentObject} placeholder={t('contract.rentObjectSelectPlaceholder')}>
                                {props.rentObjects.map((item) => (
                                    <Option value={item.id || item.uId} key={item.id || item.uId}>
                                        {item.name || item.displayName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {currentRentObject && currentRentObject.rentObjectUnit === unitType.numberOfUnits ? (
                            <p className="mb-2 font-weight-bold">{t('contract.finances.rentExemptions.stairModal.stairAmountPerUnit')}</p>
                        ) : (
                            <p className="mb-2 font-weight-bold">{t('contract.finances.rentExemptions.stairModal.stairAmountPerSqM')}</p>
                        )}
                        <Form.Item
                            name="stairRentAmount"
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.finances.rentObject.rent.title') + t('common.isRequired'),
                                },
                                {
                                    validator(_, value) {
                                        return validateStairRentIntervalAmount(value);
                                    },
                                },
                            ]}>
                            <PositiveNumericInput prefix={t('currency.norway')} />
                        </Form.Item>
                        <p className="mb-2 font-weight-bold">{t('common.start')}</p>
                        <Form.Item
                            name="startDate"
                            rules={[
                                {
                                    required: true,
                                    message: t('common.start') + t('common.isRequired'),
                                },
                                {
                                    validator(_, value) {
                                        return validateStartDate(value);
                                    },
                                },
                            ]}>
                            <DatePicker suffixIcon={<Image src={calendarIcon} />} format={primaryDateFormat} disabledDate={disabledStartDate} allowClear={false} />
                        </Form.Item>
                        <p className="mb-2 font-weight-bold">{t('common.end')}</p>
                        <Form.Item
                            name="endDate"
                            rules={[
                                {
                                    required: true,
                                    message: t('common.end') + t('common.isRequired'),
                                },
                                {
                                    validator(_, value) {
                                        return validateEndDate(value);
                                    },
                                },
                            ]}>
                            <DatePicker suffixIcon={<Image src={calendarIcon} />} format={primaryDateFormat} disabledDate={disabledEndDate} allowClear={false} />
                        </Form.Item>
                    </div>
                </Form>
                <div className="btns">
                    <ActionButton onClick={onClose} className="btn-secondary" text={t('common.buttons.cancel')} />
                    <ActionButton
                        onClick={addStairRentInterval}
                        loading={props.stairRentIntervalLoading}
                        className="btn-primary"
                        text={
                            props.stairRentIntervalModel
                                ? t('contract.finances.rentExemptions.stairModal.btnEditStair')
                                : t('contract.finances.rentExemptions.stairModal.btnAddStair')
                        }
                    />
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        contractId: get(contract, 'contract.id'),
        stairRentIntervalModel: get(contract, 'stairRentIntervalModel'),
        rentObjects: get(contract, 'rentObjects'),
        stairRentIntervals: get(contract, 'stairRentIntervals'),
        stairRentIntervalLoading: get(contract, 'stairRentIntervalLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showStairRentIntervalModal', false));
            dispatch(setValueContract('stairRentIntervalModel', null));
        },
        addStairRentIntervalCall(data, contractId, propertyId, portfolioId) {
            return dispatch(addStairRentInterval(data, contractId, propertyId, portfolioId));
        },
        updateStairRentIntervalCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateStairRentInterval(data, contractId, propertyId, portfolioId));
        },
    };
};

const StairRentInterval = connect(mapState, mapDispatch)(StairRentIntervalComponent);
export default StairRentInterval;
