import { Select } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const { Option } = Select;

const CountrySelectComponent = ({ countries, ...rest }) => {
    return (
        <Select showSearch listHeight={120} listItemHeight={40} optionFilterProp="value" filterOption={true} {...rest}>
            {countries &&
                countries.map((item) => (
                    <Option key={item.isoCode} value={item.displayName}>
                        {item.displayName}
                    </Option>
                ))}
        </Select>
    );
};

const mapState = ({ common }) => {
    return {
        countries: get(common, 'countries'),
    };
};

const CountrySelect = connect(mapState)(CountrySelectComponent);
export default CountrySelect;
