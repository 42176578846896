import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Input, Popconfirm } from 'antd';
import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import trash from '../../../assets/images/trash-default.svg';

// Due to performance issue with functional component, this should be as Class component
class TranslationRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { readOnly: true };
    }

    toggleReadonly = () => {
        this.setState((state) => ({
            readOnly: !state.readOnly,
        }));
    };

    renderEditIcon = () => <EditOutlined onClick={this.toggleReadonly} />;

    renderExclamationIcon = () => (this.props.data.needUpdate ? <ExclamationCircleOutlined className="warning-icon" /> : null);

    onBlurSourceLang = (e) => {
        const newValue = e.target.value;
        const { data, sourceLang, onChangeTranslation } = this.props;
        if (data.sourceLanguageValue !== newValue) {
            onChangeTranslation(data, sourceLang, newValue);
        }
    };

    onBlurTranslatedLang = (e) => {
        const newValue = e.target.value || '';
        const { data, destinationLang, onChangeTranslation } = this.props;
        onChangeTranslation(data, destinationLang, newValue);
    };

    onBlurKeyInput = (e) => {
        const newKey = e.target.value;
        if (newKey !== this.props.data.key) {
            this.props.onChangeKey(this.props.data.key, newKey);
        }
    };

    handleRemove = () => {
        this.props.onRemove(this.props.data.key);
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState !== this.state) {
            return true;
        }

        const { data, sourceLang, destinationLang } = this.props;
        if (nextProps.sourceLang !== sourceLang || nextProps.destinationLang !== destinationLang) {
            return true;
        }

        if (nextProps.data !== data) {
            return true;
        }
        return false;
    }

    render() {
        const { data, t } = this.props;
        return (
            <Row className="mb-1">
                <Col>
                    <Input defaultValue={data.key} readOnly={this.state.readOnly} bordered={false} suffix={this.renderEditIcon()} onBlur={this.onBlurKeyInput} />
                </Col>
                <Col key={data.sourceLanguageValue}>
                    <Input defaultValue={data.sourceLanguageValue} onBlur={this.onBlurSourceLang} bordered={false} />
                </Col>
                <Col key={data.destinationLanguageValue}>
                    <Input defaultValue={data.destinationLanguageValue} bordered={false} suffix={this.renderExclamationIcon()} onBlur={this.onBlurTranslatedLang} />
                </Col>
                <Col className="remove-icon-col">
                    <Popconfirm
                        overlayClassName="translation-manager-confirm"
                        title={t('common.areYouSure')}
                        cancelButtonProps={{ className: 'btn-secondary-sm' }}
                        okButtonProps={{ className: 'btn-primary-sm' }}
                        onConfirm={this.handleRemove}>
                        <Image src={trash} />
                    </Popconfirm>
                </Col>
            </Row>
        );
    }
}

const TranslationRow = withTranslation()(TranslationRowComponent);
export default TranslationRow;
