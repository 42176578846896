import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined } from '@ant-design/icons';

const ReviewContract = () => {
    const { t } = useTranslation();

    return (
        <div className="page-container pb-0">
            <h5 className="mb-3">{t('contract.review.beforeCreate.title')}</h5>
            <p className="subtitle">{t('contract.review.beforeCreate.subtitle')}</p>
            <Row className="notification-success m-0">
                <Col md={1} className="check-icon-col">
                    <CheckCircleOutlined /> 
                </Col>
                <Col className="d-flex flex-column">
                    <p className="font-weight-bold">{t('contract.review.beforeCreate.notificationHeader')}</p>
                    <p>{t('contract.review.beforeCreate.notificationBody')}</p>
                </Col>
                {/*To do... <Col md={11}>
                    <p>{t('contract.review.missingOptional')}</p>
                    <p>{t('contract.review.optionalFieldsWarning')}</p>
                    <ul>
                        <li>
                            <p>
                                {t('contract.review.objectAmount')} - <a>{t('contract.review.editNow')}</a>
                            </p>
                        </li>
                        <li>
                            <p>
                                {t('contract.review.noPortfolioManager')} - <a>{t('contract.review.inviteContact')}</a>
                            </p>
                        </li>
                    </ul>
    </Col>*/}
            </Row>
        </div>
    );
};

export default ReviewContract;
