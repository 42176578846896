import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Tabs, Dropdown, Menu, Button } from 'antd';
import { Image } from 'react-bootstrap';
import { deleteCommonCost, getCommonCostDetails, setDeleteCommonCostModel, setValueCommonCost } from '../../../reducers/commonCostReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';
import { commonCostLabelFormat, primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import routingService from '../../../services/routingService';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import moment from 'moment';
import costProperty from '../../../assets/images/cost-property.svg';
import backArrow from '../../../assets/images/back-arrow.svg';
import costDate from '../../../assets/images/cost-date.svg';
import moreIcon from '../../../assets/images/more.svg';
import add from '../../../assets/images/plus.svg';
import edit from '../../../assets/images/edit.svg';
import CommonCostActualsAddResult from '../modals/actuals-add-result-modal';
import DeleteMenuItem from '../../../components/menu-items/delete-menu-item';
import EditMenuItem from '../../../components/menu-items/edit-menu-item';
import ConfirmDeleteCommonCost from '../modals/confirm-delete-modal';
import MainLayout from '../../../components/layouts/main-layout';
import TextEllipsis from '../../../components/ellipsis-text';
import ActionButton from '../../../components/action-button';
import NotFoundPage from '../../../components/error-page';
import LoaderSpinner from '../../../components/spinner';
import FractionCalculator from './fraction-calculator';
import CommonCostCalculation from './calculations-tab';
import CommonCostAnalysis from './analysis-tab';
import commonCostService from '../../../services/commonCostService';
import { editPermisionTypes } from '../../../common/constants';

const { TabPane } = Tabs;

export const commonCostTabs = {
    analysis: '1',
    calculation: '2',
    getKeyName: function (value) {
        return Object.keys(this).find((key) => this[key] === value) || 'analysis';
    },
};

const CommonCostDetailsComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId, propertyId, commonCostId } = useParams();
    const [currentTabKey, setCurrentTabKey] = useState(commonCostTabs.analysis);

    const commonCostsUrl = routingService.commonCostsUrl(portfolioId, propertyId);

    useEffect(() => {
        props.getPropertyCall(portfolioId, propertyId);
    }, []);

    useEffect(() => {
        if (portfolioId && propertyId && commonCostId) {
            props.getCommonCostDetailsCall(portfolioId, propertyId, commonCostId);
        } else if (portfolioId && propertyId) {
            routingService.goTo(commonCostsUrl);
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(decodeURI(props.location.search));
        const selectedTab = commonCostTabs[params.has('tab') ? params.get('tab') : ''];
        if (selectedTab && currentTabKey !== selectedTab) {
            setCurrentTabKey(selectedTab);
        }
    }, []);

    const changeTab = (id) => {
        setCurrentTabKey(id);
        const params = new URLSearchParams(props.location.search);
        const selectedTabName = commonCostTabs.getKeyName(id);
        params.set('tab', selectedTabName);
        routingService.replace('?' + params.toString());
    };

    const onEditClick = (_) => {
        routingService.navigateToEditCommonCost(portfolioId, propertyId, commonCostId);
    };

    const onDeleteClick = (_) => {
        props.setDeleteCommonCostModelCall(props.commonCost);
        props.showConfirmDeleteCommonCostModalCall();
    };

    const handleDeleteCommonCost = async (_) => {
        await props.deleteCommonCostCall(commonCostId, propertyId, portfolioId);
        routingService.navigateToCommonCosts(portfolioId, propertyId);
    };

    const addCostActuals = (_) => {
        routingService.navigateToAddCommonCostActuals(portfolioId, propertyId, commonCostId);
    };

    const dropdownMenu = (_) => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem
                    key="0"
                    disabled={!props.hasEditPermission || props.isArchived}
                    onClick={onEditClick}
                    text={props.hasAnyActuals ? t('commonCost.editCommonCostWithActuals') : t('commonCost.editCommonCost')}
                />
                <DeleteMenuItem key="1" disabled={!props.hasEditPermission || props.isArchived} onClick={onDeleteClick} text={t('commonCost.deleteCommonCost')} />
            </Menu>
        );
    };

    const CostPeriod = ({ commonCost }) => {
        return (
            <TextEllipsis>
                <h5 className="mb-0 ellipsis">
                    {moment(commonCost.startDate).startOf('day').format(primaryDateFormat)} - {moment(commonCost.endDate).startOf('day').format(primaryDateFormat)}
                </h5>
            </TextEllipsis>
        );
    };

    return (
        <MainLayout>
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {props.commonCostNotFound ? <NotFoundPage header={t('commonCost.notFound')} /> : null}
            {!props.portfolioNotFound && !props.propertyNotFound && !props.commonCostNotFound && !props.loading && props.commonCost ? (
                <div className="details-page common-cost-details new-design-details-page">
                    <div className="title-container">
                        <div className="d-flex flex-column">
                            <div className="title">
                                <Link to={commonCostsUrl}>
                                    <Image className="m-0" src={backArrow} />
                                </Link>
                                <TextEllipsis>
                                    <h3 className="mb-0 ellipsis">{props.commonCost.name}</h3>
                                </TextEllipsis>
                                <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu()}>
                                    <Button className="btn-link px-0">
                                        <Image className="m-0" src={moreIcon} />
                                    </Button>
                                </Dropdown>
                                <CostPeriod commonCost={props.commonCost} />
                            </div>
                            <div className="common-cost-details-title">
                                <div className="property">
                                    <Image src={costDate} />
                                    <p className="text-secondary">{moment(props.commonCost.startDate).startOf('day').format(commonCostLabelFormat)}</p>
                                </div>
                                {props.plots || props.buildings ? (
                                    <div className="property">
                                        <Image src={costProperty} />
                                        <TextEllipsis>
                                            <p className="text-secondary ellipsis">{commonCostService.getRelatedEntityName(props.commonCost, props.buildings, props.plots)}</p>
                                        </TextEllipsis>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="common-cost-btn-header list p-0 add-actuals-btn">
                            <div className="search-header">
                                <ActionButton
                                    className="edit-budget-btn btn-secondary"
                                    icon={<Image src={edit} />}
                                    disabled={!props.hasEditPermission || props.isArchived}
                                    onClick={onEditClick}
                                    text={t('commonCost.editBudget')}
                                />
                                {!props.hasAnyActuals && (
                                    <ActionButton
                                        className="btn-primary width-fit-content"
                                        icon={<Image src={add} />}
                                        onClick={addCostActuals}
                                        disabled={!props.hasEditPermission || props.isArchived}
                                        text={t('commonCost.addCostActualsBtn')}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <Tabs className={!props.hasAnyActuals ? 'common-cost-no-actuals' : ''} activeKey={currentTabKey} onChange={changeTab}>
                        <TabPane tab={t('commonCost.analysis')} key={commonCostTabs.analysis}>
                            <CommonCostAnalysis />
                        </TabPane>
                        <TabPane tab={t('commonCost.calculation')} key={commonCostTabs.calculation}>
                            <CommonCostCalculation />
                        </TabPane>
                    </Tabs>
                </div>
            ) : (
                <LoaderSpinner />
            )}
            {props.showConfirmDeleteCommonCostModal && <ConfirmDeleteCommonCost handleDeleteCommonCost={handleDeleteCommonCost} />}
            {props.showFractionModal && <FractionCalculator />}
            {props.showActualsAddResultModal && <CommonCostActualsAddResult />}
        </MainLayout>
    );
};

const mapState = ({ portfolio, property, navigation, commonCost }) => {
    return {
        plots: get(property, 'property.plots'),
        buildings: get(property, 'property.buildings'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
        commonCost: get(commonCost, 'commonCost'),
        hasAnyActuals: get(commonCost, 'commonCost.commonCostActuals.length') > 0,
        showFractionModal: get(commonCost, 'showFractionModal'),
        showActualsAddResultModal: get(commonCost, 'showActualsAddResultModal'),
        showConfirmDeleteCommonCostModal: get(commonCost, 'showConfirmDeleteCommonCostModal'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        loading: get(navigation, 'navigationLoading') || get(commonCost, 'commonCostLoading'),
        isArchived: get(commonCost, 'commonCost.isArchived'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        showConfirmDeleteCommonCostModalCall() {
            dispatch(setValueCommonCost('showConfirmDeleteCommonCostModal', true));
        },
        setValueCommonCostCall(key, value) {
            dispatch(setValueCommonCost(key, value));
        },
        setDeleteCommonCostModelCall(value) {
            dispatch(setDeleteCommonCostModel(value));
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        getCommonCostDetailsCall(portfolioId, propertyId, commonCostId) {
            return dispatch(getCommonCostDetails(portfolioId, propertyId, commonCostId));
        },
        deleteCommonCostCall(propertyId, commonCostId, portfolioId) {
            return dispatch(deleteCommonCost(propertyId, commonCostId, portfolioId));
        },
    };
};

const CommonCostDetails = clear(connect(mapState, mapDispatch)(CommonCostDetailsComponent));
export default CommonCostDetails;
