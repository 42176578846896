import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import commonCostBudget from '../../assets/images/budget-icon.svg';
import commonCostActual from '../../assets/images/common-cost-actual.svg';
import commonCostProperties from '../../assets/images/common-cost-properties.svg';
import commonCostImg from '../../assets/images/new-cost-card-icon.svg';
import moreIcon from '../../assets/images/more.svg';
import CurrencyFormat from '../currency-format';
import TextEllipsis from '../ellipsis-text';
import DeleteMenuItem from '../menu-items/delete-menu-item';
import EditMenuItem from '../menu-items/edit-menu-item';

const OwnerCostCard = (props) => {
    const { t } = useTranslation();

    const openOwnerCost = () => {
        props.openOwnerCost(props.ownerCost.id);
    };

    const editOwnerCost = () => {
        props.editOwnerCost && props.editOwnerCost(props.ownerCost);
    };

    const deleteOwnerCost = () => {
        props.deleteOwnerCost && props.deleteOwnerCost(props.ownerCost);
    };

    const dropdownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" disabled={!props.hasEditPermission || props.isArchived} onClick={editOwnerCost} text={t('ownerCost.card.edit')} />
                <DeleteMenuItem key="1" onClick={deleteOwnerCost} disabled={!props.hasEditPermission || props.isArchived} text={t('ownerCost.card.delete')} />
            </Menu>
        );
    };

    return (
        <div className="entity-card common-cost-card">
            <div className="cost-image" onClick={openOwnerCost}>
                <Image src={commonCostImg} />
            </div>
            <div className="card-content">
                <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                    <TextEllipsis>
                        <h6 className="ellipsis">{props.ownerCost.name}</h6>
                    </TextEllipsis>
                    <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu()}>
                        <Button className="btn-link btn-transparent settings-btn">
                            <Image src={moreIcon} />
                        </Button>
                    </Dropdown>
                </div>
                <div className="property p-0">
                    <Image src={commonCostProperties} />
                    <p className="text-secondary mb-0">{t('commonCost.card.property')}</p>
                    <p className="text-secondary ml-1 mb-0">{props.property.propertyName}</p>
                </div>
                <div className="property p-0">
                    <Image src={commonCostBudget} />
                    <p className="text-secondary mb-0">{t('commonCost.card.budget')}</p>
                    <p className="text-secondary ml-1 mb-0">
                        <CurrencyFormat value={props.ownerCost.budgetTotal} />
                    </p>
                </div>
                {props.ownerCost.hasAnyActuals && (
                    <div className="property p-0">
                        <Image src={commonCostActual} />
                        <p className="text-secondary mb-0">{t('commonCost.card.actual')}</p>
                        <p className="text-secondary ml-1 mb-0">
                            <CurrencyFormat value={props.ownerCost.actualTotal} />
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OwnerCostCard;
