import moment from 'moment';
import i18next from 'i18next';
import toaster from '../common/toaster';
import update from '../helpers/update';
import invoiceService from '../services/invoiceService';

const SET_VALUE_INVOICE = 'SET_VALUE_INVOICE';
const SET_INVOICES_PROPERTY_ID = 'SET_INVOICES_PROPERTY_ID';
const CLEAR_INVOICES_PROPERTY_ID = 'CLEAR_INVOICES_PROPERTY_ID';

const initialState = {
    invoices: [],
    invoice: null,

    invoicesProperty: null,

    showInvoiceModal: false,
    showConfirmDeleteModal: false,

    getLastInvoiceNumberLoading: false,
    invoicesLoading: false,
    invoiceLoading: false,
    loading: false,
};

const invoiceReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_INVOICE:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case SET_INVOICES_PROPERTY_ID:
            newState = update.set(newState, 'invoicesProperty', action.payload);
            break;
        case CLEAR_INVOICES_PROPERTY_ID:
            newState = update.set(newState, 'invoicesProperty', null);
            break;
        default:
            break;
    }

    return newState;
};

export const setValueInvoice = (key, value) => {
    return {
        type: SET_VALUE_INVOICE,
        payload: { key, value },
    };
};

export const setInvoicesPropertyId = (value) => {
    return {
        type: SET_INVOICES_PROPERTY_ID,
        payload: value,
    };
};

export const clearInvoicesPropertyId = (_) => {
    return {
        type: CLEAR_INVOICES_PROPERTY_ID,
    };
};

const handleInvoiceError = (error) => {
    if (error && error.response && error.response.title) {
        toaster.error(error.response.title, null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const createInvoice = (data) => {
    return (dispatch, getState) => {
        dispatch(setValueInvoice('loading', true));
        invoiceService
            .create(data)
            .then((response) => {
                const invoices = [...getState().invoice.invoices];
                invoices.push(response.data);
                dispatch(setValueInvoice('invoices', invoices));
            })
            .catch((error) => handleInvoiceError(error))
            .finally(() => {
                dispatch(setValueInvoice('loading', false));
            });
    };
};

export const updateInvoice = (data) => {
    return (dispatch, getState) => {
        dispatch(setValueInvoice('loading', true));
        invoiceService
            .update(data)
            .then((response) => {
                const invoice = response.data;
                const invoices = [...getState().invoice.invoices];
                const index = invoices.findIndex((x) => x.id === invoice.id);
                if (index > -1) {
                    invoices[index] = invoice;
                    dispatch(setValueInvoice('invoices', invoices));
                }
            })
            .catch((error) => handleInvoiceError(error))
            .finally(() => {
                dispatch(setValueInvoice('loading', false));
            });
    };
};

export const deleteInvoice = (id) => {
    return async (dispatch, getState) => {
        dispatch(setValueInvoice('loading', true));
        try {
            await invoiceService.delete(id);

            const invoices = [...getState().invoice.invoices];
            const newList = invoices.filter((x) => x.id !== id);
            dispatch(setValueInvoice('invoices', newList));
        } catch (error) {
            handleInvoiceError(error);
        }
        dispatch(setValueInvoice('loading', false));
    };
};

export const getInvoices = (propertyId) => {
    return (dispatch) => {
        dispatch(setValueInvoice('invoicesLoading', true));
        invoiceService
            .getInvoices(propertyId)
            .then((response) => {
                const invoices = response.data;
                const invoicesWithKeys = invoices.map((invoice) => ({
                    ...invoice,
                    key: invoice.id,
                    createdOnUtc: moment.utc(invoice.createdOnUtc).format(),
                }));
                dispatch(setValueInvoice('invoices', invoicesWithKeys));
            })
            .catch((error) => handleInvoiceError(error))
            .finally(() => {
                dispatch(setValueInvoice('invoicesLoading', false));
            });
    };
};

export const getInvoiceById = (id) => {
    return (dispatch) => {
        dispatch(setValueInvoice('invoiceLoading', true));
        invoiceService
            .getInvoiceById(id)
            .then((response) => {
                dispatch(setValueInvoice('invoice', response.data));
            })
            .catch((error) => handleInvoiceError(error))
            .finally(() => {
                dispatch(setValueInvoice('invoiceLoading', false));
            });
    };
};

export const getLastInvoiceNumber = (_) => {
    return (dispatch) => {
        dispatch(setValueInvoice('getLastInvoiceNumberLoading', true));
        return invoiceService
            .getLastInvoiceNumber()
            .then((response) => {
                return response.data;
            })
            .catch((error) => handleInvoiceError(error))
            .finally(() => {
                dispatch(setValueInvoice('getLastInvoiceNumberLoading', false));
            });
    };
};

export default invoiceReducer;
