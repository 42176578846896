import React from 'react';
import { Image } from 'react-bootstrap';
import notificationImage from '../../assets/images/success-download.svg';

const SuccessMessage = ({ textElements }) => {
    return (
        <div className="notification-success">
            <div className="success-icon-container">
            <Image src={notificationImage} />
            </div>
            <div className="d-flex flex-column">{textElements}</div>
        </div>
    );
};

export default SuccessMessage;
