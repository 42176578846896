import { Collapse, Form } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import { deleteLeaseDurationOption, deleteRentObject, setValueContract } from '../../../../reducers/contractReducer';
import rentObjectService from '../../../../services/rentObjectService';
import ContractDates from '../contract-dates';
import ConfirmRemoveCustomDuration from '../contract-dates/remove-custom-duration-modal';
import LeaseContractDuration from '../lease-duration-modal';
import ConfirmRemoveLeaseDurationOption from './modals/remove-lease-duration-option-modal';
import RentObjectDuration from './rent-object-duration';
import { useParams } from 'react-router';

const DurationAndRentalObjectsComponent = (props) => {
    const { t } = useTranslation();

    const { portfolioId, propertyId} = useParams();

    const [contractLeaseDurationOptionObject, setContractLeaseDurationOptionObject] = useState();

    const [contractStartDate, setContractStartDate] = useState(props.contract.leaseStartDate);
    const [contractEndDate, setContractEndDate] = useState(props.contract.leaseEndDate);

    const [rentObjectHasCustomDuration, setRentObjectHasCustomDuration] = useState(props.rentObject.hasCustomDuration || false);

    useEffect(() => {
        if (props.hasRentObjects) {
            props.setValueContractCall('showRentObjectForm', false);
        }
    }, []);

    // Contract Lease Duration Option
    const openContractLeaseDurationOptionModalWindow = () => {
        props.contractDatesRef.current.validateFields([`dates`]).then(() => {
            props.openContractLeaseDurationOption();
        });
    };

    const openContractLeaseDurationOptionOnDelete = (id) => {
        const leaseDurationOption = props.leaseDurationOptions.find((x) => x.uId === id);
        if (leaseDurationOption) {
            setContractLeaseDurationOptionObject(leaseDurationOption);
            props.setValueContractCall('showRemoveLeaseDurationModal', true);
        }
    };

    const deleteContractLeaseDurationOption = () => {
        if (contractLeaseDurationOptionObject) {
            props.deleteLeaseDurationOptionCall(contractLeaseDurationOptionObject.id, props.contract.id, propertyId, portfolioId);
        }
    };

    // Custom Duration
    const onCloseRemoveCustomDurationRentObject = () => {
        props.setValueContractCall('showRentObjectRemoveCustomDurationModal', false);
    };

    const onConfirmRemoveCustomDurationRentObject = () => {
        setRentObjectHasCustomDuration(!rentObjectHasCustomDuration);

        const rentObject = props.rentObject;
        rentObject.leaseDurationOptions = [];
        rentObject.leaseStartDate = null;
        rentObject.leaseEndDate = null;
        rentObject.hasCustomDuration = false;
        props.setValueContractCall('rentObject', rentObject);
        onCloseRemoveCustomDurationRentObject();
        props.rentalRef.current.setFieldsValue({ leaseStartDate: null, leaseEndDate: null });
    };

    const showName = (_) => {
        if (props.rentalRef.current) {
            const name = props.rentalRef.current.getFieldValue('name');
            const rentObjectRentalType = props.rentalRef.current.getFieldValue('rentObjectRentalType');
            const rentObjectAmount = props.rentalRef.current.getFieldValue('amount');
            const rentObjectRentObjectUnit = props.rentalRef.current.getFieldValue('rentObjectUnit');
            if (name) {
                return name;
            } else if (rentObjectRentalType && rentObjectAmount && rentObjectRentObjectUnit) {
                return rentObjectService.getRentalObjectDisplayName({
                    rentObjectRentalType: rentObjectRentalType,
                    amount: rentObjectAmount,
                    rentObjectUnit: rentObjectRentObjectUnit,
                });
            }
        }
        return t('contract.durationAndRentalObjects.rentObject.newTitle');
    };

    return (
        <div>
            <div className="contract-dates">
                <h5>{t('contract.durationAndRentalObjects.contractDates.title')}</h5>
                <Form name="contract-dates" autoComplete="off" ref={props.contractDatesRef}>
                    <ContractDates
                        onSelectLeaseStartDate={setContractStartDate}
                        onSelectLeaseEndDate={setContractEndDate}
                        openContractLeaseDurationOptionModalWindow={openContractLeaseDurationOptionModalWindow}
                        openContractLeaseDurationOptionOnDelete={openContractLeaseDurationOptionOnDelete}
                    />
                </Form>
            </div>
            {props.showRemoveLeaseDurationModal && (
                <ConfirmRemoveLeaseDurationOption
                    deleteContractLeaseDurationOption={deleteContractLeaseDurationOption}
                    name={moment(contractLeaseDurationOptionObject.date).format(primaryDateFormat)}
                />
            )}
            <LeaseContractDuration
                leaseOptionRef={props.leaseOptionRef}
                leaseStartDate={props.contract.leaseStartDate || contractStartDate}
                leaseEndDate={props.contract.leaseEndDate || contractEndDate}
            />
            {props.rentObjects.length > 0 && <div className="px-4 pt-4">
                <Collapse className='contract-collapse'
                    expandIcon={({ isActive }) => isActive ? <div className='collapse-btn btn-transparent' /> : <div className='collapse-btn collapsed btn-transparent' />}>
                    {props.rentObjects?.map((item, id) => {
                        return (
                            <Collapse.Panel
                                className='contract-collapse-item'
                                header={<h5 className="mb-0">{!item?.newlyCreated ? item.displayName : t('contract.durationAndRentalObjects.rentObject.newTitle')}</h5>}
                                key={id}
                            >
                                <RentObjectDuration rentObject={item} />
                            </Collapse.Panel>
                        );
                    })
                    }
                </Collapse>
            </div>
            }
            {props.showRentObjectRemoveCustomDurationModal && (
                <ConfirmRemoveCustomDuration name={showName()} onConfirm={onConfirmRemoveCustomDurationRentObject} onClose={onCloseRemoveCustomDurationRentObject} />
            )}
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        leaseDurationOptions: get(contract, 'leaseDurationOptions'),
        showRentObjectForm: get(contract, 'showRentObjectForm'),
        rentObject: get(contract, 'rentObject'),
        rentObjects: get(contract, 'rentObjects'),
        hasRentObjects: get(contract, 'rentObjects.length') > 0,
        rentObjectOnDelete: get(contract, 'rentObjectOnDelete'),
        showContractLeaseDurationModal: get(contract, 'showContractLeaseDurationModal'),
        showRemoveLeaseDurationModal: get(contract, 'showRemoveLeaseDurationModal'),
        showRemoveRentObjectModal: get(contract, 'showRemoveRentObjectModal'),
        showRentObjectLeaseDurationModal: get(contract, 'showRentObjectLeaseDurationModal'),
        showRentObjectRemoveCustomDurationModal: get(contract, 'showRentObjectRemoveCustomDurationModal'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
        openRentObjectLeaseDurationOptionModal() {
            dispatch(setValueContract('showRentObjectLeaseDurationModal', true));
        },
        openContractLeaseDurationOption() {
            dispatch(setValueContract('showContractLeaseDurationModal', true));
        },
        deleteLeaseDurationOptionCall(id, contractId, propertyId, portfolioId) {
            dispatch(deleteLeaseDurationOption(id, contractId, propertyId, portfolioId));
        },
        deleteRentObjectCall(id, contractId, portfolioId, propertyId) {
            return dispatch(deleteRentObject(id, contractId, portfolioId, propertyId));
        },
    };
};

const DurationAndRentalObjects = connect(mapState, mapDispatch)(DurationAndRentalObjectsComponent);
export default DurationAndRentalObjects;
