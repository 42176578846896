import { Input } from 'antd';
import React from 'react';
import { enterBtnCode } from '../../common/constants';
import required from '../../assets/images/required.svg';
import { Image } from 'react-bootstrap';

const CommonInput = ({ autofocus = false, value = '', type = 'text', onChange, submitOnEnter = false, ...rest }) => {
    return (
        <div className="common-input">
            {rest.title ? <p className="input-label">{rest.isRequired ? <Image src={required} /> : null}{rest.title}</p> : null}
            <Input
                {...rest}
                type={type}
                value={value}
                onChange={onChange}
                autoFocus={autofocus}
                onKeyDown={(e) => (!submitOnEnter && e.keyCode === enterBtnCode ? e.preventDefault() : '')}
            />
        </div>
    );
};

export default CommonInput;
