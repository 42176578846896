import { Button, Input } from 'antd';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import img from '../../assets/images/search.svg';
import { enterBtnCode } from '../../common/constants';

const SearchInput = ({ placeholder, onSearch, submitOnEnter = true, className }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');

    const update = (event) => {
        setSearch(event.target.value);
    };

    const handleClick = () => {
        onSearch && onSearch(search);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === enterBtnCode && submitOnEnter) {
            handleClick();
        }
    };

    return (
        <div className={`search ${className || ''}`}>
            <Input placeholder={placeholder || t('common.searchPlaceholder')} onKeyDown={handleKeyDown} onChange={update} />
            <Button onClick={handleClick} className="search-btn">
                <Image src={img} />
            </Button>
        </div>
    );
};

export default SearchInput;
