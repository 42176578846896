import { Col, Collapse, Form, Row } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { updateRentObjectPaymentTerms } from '../../../../reducers/contractReducer';
import PaymentsTermsOptions from './rent-payment-terms';
import ActionButton from '../../../../components/action-button';
import { useParams } from 'react-router';

const PaymentTermsRentalObjectCardComponent = (props) => {
    const { t } = useTranslation();

    const { portfolioId, propertyId } = useParams();

    const editRentObjectRef = React.useRef();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.rentObject) {
            let rentInvoicedFromDate = props.rentObject.rentInvoicedFromDate ? moment(props.rentObject.rentInvoicedFromDate).utc(true) : null;
            rentInvoicedFromDate = rentInvoicedFromDate || moment(props.contract.leaseStartDate).startOf('day').utc(true);
            let firstInvoiceDueDate = props.rentObject.firstInvoiceDueDate ? moment(props.rentObject.firstInvoiceDueDate).utc(true) : null;
            firstInvoiceDueDate = rentInvoicedFromDate || moment(props.contract.leaseStartDate).startOf('day').utc(true);

            editRentObjectRef.current.setFieldsValue({
                rentInvoicedFromDate: rentInvoicedFromDate,
                firstInvoiceDueDate: firstInvoiceDueDate,
                invoiceIssueDateOption: props.rentObject.invoiceIssueDateOption ? props.rentObject.invoiceIssueDateOption : null,
                paymentPlan: props.rentObject.paymentPlan ? props.rentObject.paymentPlan : null,
                paymentReference: props.rentObject.paymentReference ? props.rentObject.paymentReference : null
            });
        }
    }, []);

    const done = () => {
        return editRentObjectRef.current.validateFields().then(() => {
            const rentObject = editRentObjectRef.current.getFieldsValue();
            rentObject.rentInvoicedFromDate = rentObject.rentInvoicedFromDate.startOf('day').utc(true);
            rentObject.firstInvoiceDueDate = rentObject.firstInvoiceDueDate.startOf('day').utc(true);
            setLoading(true);
            props.updateRentObjectPaymentTermsCall(rentObject, props.rentObject.id, props.contract.id, portfolioId, propertyId).then(() => {
                setLoading(false);
            });
        });
    };

    return (
        <Form name="basic" autoComplete="off" ref={editRentObjectRef}>
            <PaymentsTermsOptions rentObject={props.rentObject} />
            <Row justify="end" className="content-block-actions m-0">
                <Col className="ml-3">
                    <ActionButton
                        className='content-block-action btn-primary'
                        loading={loading}
                        disabled={editRentObjectRef.current && editRentObjectRef.current.getFieldsError().filter(({ errors }) => errors.length).length}
                        text={t('common.buttons.done')}
                        onClick={() => {
                            done().then(() => {
                                props.setActiveKeys(prev => prev.filter(item => item != props.rentObject.id))
                            });
                        }}
                    />
                </Col>
            </Row>
        </Form>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        rentObjects: get(contract, 'rentObjects'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        updateRentObjectPaymentTermsCall(value, id, contractId, portfolioId, propertyId) {
            return dispatch(updateRentObjectPaymentTerms(value, id, contractId, portfolioId, propertyId));
        },
    };
};

const PaymentTermsRentalObjectCard = connect(mapState, mapDispatch)(PaymentTermsRentalObjectCardComponent);
export default PaymentTermsRentalObjectCard;
