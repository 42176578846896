import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Radio } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { defaultManager, setValuePortfolio } from '../../../../reducers/portfolioReducer';
import { requiredCommonInput, validateEmail } from '../../../../common/validators';
import commonService from '../../../../services/commonService';
import get from 'lodash-es/get';
import CommonInput from '../../../../components/common-input';
import OwnPhoneInput from '../../../../components/phone-input';
import CountrySelect from '../../../../components/country-selector';

const PortfolioManagerByPersonComponent = (props) => {
    const { t } = useTranslation();

    const portfolioManager = props.portfolioManager || defaultManager;

    useEffect(() => {
        props.managerRef.current.setFieldsValue(portfolioManager);
    }, []);

    const onChangeRadioButton = (e) => {
        const manager = { ...portfolioManager };
        manager.ownedBy = e.target.value;
        const portfolio = { ...props.portfolio };
        portfolio.manager = manager;
        props.setValuePortfolioCall('portfolio', portfolio);
    };

    return (
        <React.Fragment>
            <div className="page-container bottom-border">
                <Row>
                    <Col lg={12} xl={6}>
                        <h5>{t('portfolio.manager.details.title')}</h5>
                        <Form.Item name="ownedBy">
                            <Radio.Group defaultValue={portfolioManager.ownedBy} className="radio-container" onChange={onChangeRadioButton}>
                                <Radio
                                    disabled={portfolioManager.id && portfolioManager.ownedBy === commonService.ownerRadioGroupTypes.person}
                                    value={commonService.ownerRadioGroupTypes.company}>
                                    {t('common.company')}
                                </Radio>
                                <Radio value={commonService.ownerRadioGroupTypes.person}>{t('common.person')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="firstName" rules={[requiredCommonInput(t('portfolio.manager.details.firstName.title') + t('common.isRequired'))]}>
                            <CommonInput
                                maxLength={150}
                                title={t('portfolio.manager.details.firstName.title')}
                                placeholder={t('portfolio.manager.details.firstName.placeholder')}
                            />
                        </Form.Item>
                        <Form.Item name="lastName" rules={[requiredCommonInput(t('portfolio.manager.details.lastName.title') + t('common.isRequired'))]}>
                            <CommonInput maxLength={150} title={t('portfolio.manager.details.lastName.title')} placeholder={t('portfolio.manager.details.lastName.placeholder')} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <div className="page-container">
                <Row>
                    <Col lg={12} xl={6}>
                        <h5>{t('portfolio.manager.contacts.title')}</h5>
                        <Form.Item name="address" rules={[requiredCommonInput(t('portfolio.manager.contacts.address.title') + t('common.isRequired'))]}>
                            <CommonInput maxLength={150} title={t('portfolio.manager.contacts.address.title')} placeholder={t('portfolio.manager.contacts.address.placeholder')} />
                        </Form.Item>
                        <p className="font-weight-bold">{t('portfolio.manager.contacts.phone.title')}</p>
                        <Form.Item name="phone">
                            <OwnPhoneInput placeholder={t('portfolio.manager.contacts.phone.placeholder')} />
                        </Form.Item>
                        <Form.Item name="email" rules={[validateEmail]}>
                            <CommonInput
                                type={'email'}
                                maxLength={150}
                                title={t('portfolio.manager.contacts.email.title')}
                                placeholder={t('portfolio.manager.contacts.email.placeholder')}
                            />
                        </Form.Item>
                        <Form.Item name="post" rules={[requiredCommonInput(t('portfolio.manager.contacts.post.title') + t('common.isRequired'))]}>
                            <CommonInput maxLength={150} title={t('portfolio.manager.contacts.post.title')} placeholder={t('portfolio.manager.contacts.post.placeholder')} />
                        </Form.Item>
                        <p className="font-weight-bold">{t('portfolio.manager.contacts.country.title')}</p>
                        <Form.Item
                            name="country"
                            initialValue={t('defaultCountry')}
                            rules={[
                                {
                                    required: true,
                                    message: t('portfolio.manager.contacts.country.title') + t('common.isRequired'),
                                },
                            ]}>
                            <CountrySelect placeholder={t('portfolio.manager.contacts.country.placeholder')} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

const mapState = ({ portfolio }) => {
    return {
        portfolio: get(portfolio, 'portfolio'),
        portfolioManager: get(portfolio, 'portfolio.manager'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValuePortfolioCall(key, value) {
            dispatch(setValuePortfolio(key, value));
        },
    };
};

const PortfolioManagerByPerson = connect(mapState, mapDispatch)(PortfolioManagerByPersonComponent);
export default PortfolioManagerByPerson;
