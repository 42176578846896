import localStorageService from './localStorageService';
import i18next from 'i18next';
import axios from 'axios';

import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/nb';

import * as am4core from '@amcharts/amcharts4/core';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4lang_nb_NO from '@amcharts/amcharts4/lang/nb_NO';

import en_US from 'antd/lib/locale-provider/en_US';
import nb_NO from 'antd/lib/locale-provider/nb_NO';

export const supportedLanguages = {
    English: 'en',
    Norway: 'no',
};

export const languageService = {
    changeLanguage: (lang) => {
        localStorageService.setLanguage(lang);
        i18next.changeLanguage(lang);
        if (lang === supportedLanguages.Norway) {
            Number.prototype.toLocalizedString = function () {
                return this.toLocaleString('de-DE');
            };
            moment.locale('nb');
            am4core.options.defaultLocale = am4lang_nb_NO;
        } else {
            Number.prototype.toLocalizedString = function () {
                return this.toLocaleString('nb');
            };
            moment.locale('en-gb');
            am4core.options.defaultLocale = am4lang_en_US;
        }
    },

    getDefaultLanguage: () => {
        const supportedLangs = Object.values(supportedLanguages);
        const selectedLanguage = localStorageService.getLanguage();
        if (selectedLanguage && supportedLangs.some((x) => x === selectedLanguage)) {
            return selectedLanguage;
        }

        const defaultLanguage = process.env.REACT_APP_STAGE === 'prod' ? supportedLanguages.Norway : supportedLanguages.English;
        return defaultLanguage;
    },

    getLanguageForAntConfigProvider: (lang) => {
        if (lang === supportedLanguages.Norway) {
            return nb_NO;
        } else {
            return en_US;
        }
    },

    getLanguageForCharts: (lang) => {
        if (lang === supportedLanguages.Norway) {
            return am4lang_nb_NO;
        } else {
            return am4lang_en_US;
        }
    },

    fetchLocale: (lang, module) => {
        return axios({
            method: 'get',
            url: `/api/contract/localization/get-translations/${lang}/${module}`,
        });
    },

    getAllLocalizationRecords: (module, sourceLang, destinationLang) => {
        return axios({
            method: 'get',
            url: `/api/contract/backoffice/translationmanagement/get-all-records/${module}/${sourceLang}/${destinationLang}`,
        });
    },

    updateLocalizationKey: (data) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/backoffice/translationmanagement/update-key`,
        });
    },

    deleteLocalizationKey: (data) => {
        return axios({
            method: 'delete',
            data: data,
            url: `/api/contract/backoffice/translationmanagement/delete-key`,
        });
    },

    addLocalizationValue: (data) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/backoffice/translationmanagement/add-localization`,
        });
    },

    updateLocalizationValue: (data) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/backoffice/translationmanagement/update-localization`,
        });
    },

    exportTranslations: (module) => {
        return axios({
            method: 'post',
            url: `/api/contract/backoffice/translationmanagement/export/${module}`,
        });
    },

    importTranslations: (data) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/backoffice/translationmanagement/import`,
        });
    },

    compareTranslations: (data) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/backoffice/translationmanagement/compare`,
        });
    },

    exportFlatTranslations: (module) => {
        return axios({
            method: 'post',
            url: `/api/contract/backoffice/translationmanagement/flat-export/${module}`,
        });
    },

    publishTranslations: (module) => {
        return axios({
            method: 'post',
            url: `/api/contract/backoffice/translationmanagement/publish/${module}`,
        });
    },
};

export default languageService;
