import axios from 'axios';
import helper from '../helpers/helper';
import { rentObjectRentalTypeOptions, unitType, unitTypeOptions } from '../common/contract-options';
import i18next from 'i18next';

const rentObjectService = {
    getRentalObjectDisplayName: ({ name, rentObjectRentalType, rentObjectUnit, amount }) => {
        const rentalTypeName = helper.getEnumValue(rentObjectRentalTypeOptions, rentObjectRentalType);
        const unitTypeName = rentObjectUnit === unitType.squareMetre ? helper.getEnumValue(unitTypeOptions, rentObjectUnit) : '';

        return `${name ? name : ''} ${name ? '-' : ''} ${rentalTypeName} - ${helper.numberWithSpaces(amount, i18next.language == 'en' ? '.' : ',')} ${unitTypeName}`.trim();
    },

    updateRentObject: (data, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/rentobjects/update-rent-object?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    updateRentObjectRentOptions: (data, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/rentobjects/set-rent-options?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    updateRentObjectCommonCostDetails: (data, rentObjectId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/rentobjects/set-common-cost-details?rentObjectId=${rentObjectId}&contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    updateRentObjectsCommonCostDetails: (data, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'put',
            data: [data],
            url: `/api/contract/rentobjects/set-common-cost-details?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    updateRentObjectPaymentTerms: (data, rentObjectId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/rentobjects/set-payment-terms-details?rentObjectId=${rentObjectId}&contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    updateRentObjectRentAdjustmentOptions: (data, rentObjectId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/rentobjects/set-rent-adjustment-options?rentObjectId=${rentObjectId}&contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    deleteRentObject: (rentObjectId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/rentobjects/delete-rent-object/${rentObjectId}?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    addLeaseDurationOption: (data, rentObjectId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/rentobjects/add-lease-duration-option?rentObjectId=${rentObjectId}&contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    updateLeaseDurationOption: (data, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/rentobjects/update-lease-duration-option?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    deleteLeaseDurationOption: (leaseDurationOptionId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/rentobjects/delete-lease-duration-option/leaseDurationOptionId=${leaseDurationOptionId}&contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },
};

export default rentObjectService;
