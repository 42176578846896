import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clearDeletePlotModel, setValuePlot } from '../../../reducers/plotReducer';
import get from 'lodash-es/get';
import toaster from '../../../common/toaster';
import ConfirmDeleteModal from '../../../components/confirm-delete-modal';

const ConfirmDeletePlotComponent = (props) => {
    const { t } = useTranslation();

    const handleCloseClick = (_) => {
        props.closeModalWindow();
        props.clearDeletePlotModelCall();
    };

    const handleDeleteClick = async (_) => {
        if (props.handleDeletePlot) {
            const isSuccess = await props.handleDeletePlot();
            isSuccess && toaster.success(t('common.itemDeletedSuccessfully', { name: props.plot.displayName }));
            props.closeModalWindow();
            props.clearDeletePlotModelCall();
        }
    };

    return (
        <ConfirmDeleteModal
            onClose={handleCloseClick}
            onConfirm={handleDeleteClick}
            title={t('plot.delete.title', { name: props.plot.displayName })}
            text={t('plot.delete.sentence')}
            loading={props.deleteLoading}
            deleteBtnText={t('plot.delete.deleteBtnText')}
        />
    );
};

const mapState = ({ plot }) => {
    return {
        plot: get(plot, 'deletePlotModel'),
        deleteLoading: get(plot, 'deleteLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        clearDeletePlotModelCall() {
            dispatch(clearDeletePlotModel());
        },
        closeModalWindow() {
            dispatch(setValuePlot('showConfirmDeleteModal', false));
        },
    };
};

const ConfirmDeletePlot = connect(mapState, mapDispatch)(ConfirmDeletePlotComponent);
export default ConfirmDeletePlot;
