import { Progress } from 'antd';
import { get } from 'lodash-es';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import back_icon from '../../assets/images/back.svg';
import routingService from '../../services/routingService';
import ActionButton from '../action-button';
import Logo from '../logo-container';
import LoaderSpinner from '../spinner';
import StepsTimeline from './steps-timeline';
import { editPermisionTypes } from '../../common/constants';

const ObjectLayoutComponent = (props) => {
    const { t } = useTranslation();

    const getByKey = (key) => {
        const index = props.steps.findIndex((x) => x.key === key);
        return props.steps[index];
    };

    const handleNextClick = () => {
        props.onNext && props.onNext();
    };

    const handleBackClick = () => {
        props.onPrevious && props.onPrevious();
    };

    const handleExitClick = () => {
        if (props.exitUrl) {
            routingService.goTo(props.exitUrl);
        } else {
            routingService.goBack();
        }
    };

    const currentStep = getByKey(props.currentStepKey);
    const currentStepIndex = props.steps.findIndex((x) => x.key === currentStep.key);
    const isLastStep = currentStepIndex === props.steps.length - 1;

    return (
        <React.Fragment>
            {props.navigationLoading || props.loading ? (
                <LoaderSpinner />
            ) : (
                <div className={`object-layout ${props.className}`}>
                    <div className="header">
                        <Logo />
                        {props.isEdit || props.usingDraft ? (
                            <div className="draft-btns">
                                <ActionButton onClick={props.onCancel} className="btn-secondary" text={t('common.buttons.cancel')} />
                                <ActionButton htmlType="submit" onClick={() => props.onSaveAndExit()} className="btn-primary ml-3" text={t('common.buttons.saveAndExit')} />
                            </div>
                        ) : (
                            <div className="draft-btns">
                                <ActionButton onClick={handleExitClick} className="btn-secondary exit" text={t('common.buttons.exit')} />
                                {isLastStep && (
                                    <ActionButton htmlType="submit" onClick={() => props.onSaveAndExit()} className="btn-primary ml-3" text={t('common.buttons.saveAndExit')} />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="page h-100" style={{ paddingBottom: props.isNewDesign && '0px'}}>
                        <div className="mobile-progress-container">
                            <Progress width={32} strokeWidth={10} type="circle" percent={(currentStepIndex + 1) * (100 / props.steps.length)} />
                            <div className="d-flex flex-column">
                                <h6 className="mb-0">{currentStep.stepTitle}</h6>
                                <p className="mb-0">{t('common.steps', { current: currentStepIndex + 1, steps: props.steps.length })}</p>
                            </div>
                        </div>
                        <div className='main-wrapper'>
                            {!!props.steps?.length &&
                                props.steps?.slice(0, currentStepIndex + 1).map((item) => (
                                    <div className="main">
                                        <div className="main-header">
                                            {!!item.logo && (
                                                <span className="icon">
                                                    <Image src={item.logo} />
                                                </span>
                                            )}
                                            <div className="d-flex flex-column">
                                                <h4 className="mb-2">{item.header}</h4>
                                                <p className="text-secondary mb-0">{item.layoutMessage ? item.layoutMessage : t('layout.message')}</p>
                                            </div>
                                        </div>
                                        <div className="content">{item.content}</div>
                                    </div>
                                ))}
                            {currentStep?.underSteps?.length &&
                                currentStep.underSteps.map((item) => (
                                    <div className="main">
                                        <div className="main-header">
                                            <span className="icon">
                                                <Image src={item.logo} />
                                            </span>
                                            <div className="d-flex flex-column">
                                                <h4 className="mb-2">{item.header}</h4>
                                                <p className="text-secondary mb-0">{t('layout.message')}</p>
                                            </div>
                                        </div>
                                        <div className="content">{item.content}</div>
                                    </div>
                                ))}
                            {!props.isNewDesign && (
                                <div className="new-footer">
                                    {currentStepIndex > 0 && (
                                        <ActionButton
                                            className="btn-secondary"
                                            disabledPreviousBtn={!!props.disabledPreviousBtn}
                                            text={t('common.buttons.previous')}
                                            onClick={handleBackClick}
                                            icon={<Image src={back_icon} />}
                                        />
                                    )}
                                    {currentStepIndex == 0 && <ActionButton className="btn-secondary" text={t('common.buttons.cancel')} onClick={handleExitClick} />}
                                    {currentStep.onSkip && <ActionButton className="btn-secondary mr-3" onClick={currentStep.onSkip} text={t('common.buttons.skip')} />}
                                    {!isLastStep ? (
                                        <ActionButton
                                            className="btn-primary next-btn"
                                            onClick={handleNextClick}
                                            loading={props.saveBtnLoading}
                                            text={t('common.buttons.next')}
                                            disabled={!!props.disabledNextBtn}
                                        />
                                    ) : props.usingDraft && !props.hasEditPermission ? (
                                        <ActionButton onClick={handleExitClick} className="btn-primary next-btn" text={t('common.buttons.exit')} />
                                    ) : (
                                        <ActionButton
                                            className="btn-primary next-btn"
                                            onClick={() => props.onSaveAndExit()}
                                            loading={props.saveBtnLoading}
                                            text={props.isEdit ? t('common.buttons.saveChanges') : t('common.buttons.finish')}
                                            disabled={!!props.disabledNextBtn}
                                        />
                                    )}
                                </div>
                            )}
                            {props.isNewDesign && (
                                <div className="new-footer">
                                    <ActionButton
                                        className="btn-secondary"
                                        disabledPreviousBtn={!!props.disabledPreviousBtn}
                                        text={t('common.buttons.previous')}
                                        onClick={handleBackClick}
                                        icon={<Image src={back_icon} />}
                                    />
                                    {!isLastStep ? (
                                        <ActionButton
                                            className="btn-primary next-btn"
                                            onClick={handleNextClick}
                                            loading={props.saveBtnLoading}
                                            text={t('common.buttons.next')}
                                            disabled={!!props.disabledNextBtn}
                                        />
                                    ) : props.usingDraft && !props.hasEditPermission ? (
                                        <ActionButton onClick={handleExitClick} className="btn-primary next-btn" text={t('common.buttons.exit')} />
                                    ) : (
                                        <ActionButton
                                            className="btn-primary next-btn"
                                            onClick={() => props.onSaveAndExit()}
                                            loading={props.saveBtnLoading}
                                            text={props.isEdit ? t('common.buttons.saveChanges') : t('common.buttons.finish')}
                                            disabled={!!props.disabledNextBtn}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="right-side">
                            {props.topRightSideBar}
                            <StepsTimeline steps={props.timelineSteps} isNewDesign={props.isNewDesign} currentStepIndex={currentStepIndex} onSwitchStep={props.onSwitchStep} />
                            {props.bottomRightSideBar}
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

const mapState = ({ navigation }) => {
    return {
        navigationLoading: get(navigation, 'navigationLoading'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
    };
};

const ObjectLayout = connect(mapState)(ObjectLayoutComponent);
export default ObjectLayout;
