import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Form, Modal, Radio, Select, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';
import { enterBtnCode } from '../../../../common/constants';
import { repeatOption, repeatReminderOptions } from '../../../../common/event-options';
import { requiredCommonInput } from '../../../../common/validators';
import { createEvent, defaultEvent, editEvent, repeatWeekDays, setValueEvent } from '../../../../reducers/eventsReducer';
import ActionButton from '../../../action-button';
import CommonInput from '../../../common-input';
import { primaryDateFormat } from '../../../dateFormatter/dateFormats';
import mobileRangePickerPanel from '../../../mobile-range-picker-panel';
import CustomTimePicker from '../../../time-picker';

const { RangePicker } = DatePicker;
const { Option } = Select;

const EventModalComponent = (props) => {
  const { t } = useTranslation();
  const eventRef = React.useRef();
  const [assignedEntityType, setAssignedEntityType] = useState(props.event.propertyId ? 'property' : 'contract');

  const isEdit = props.event && props.event.id;

  useEffect(() => {
    if (eventRef.current) {
      eventRef.current.setFieldsValue({ repeatOption: props.repeatOption });
    }
  }, [props.repeatOption]);

  useEffect(() => {
    props.setValueEventCall('eventEditReminderRepeatOption', props.repeatOption);
  }, []);

  const onSave = () => {
    eventRef.current
      .validateFields()
      .then(() => {
        const event = eventRef.current.getFieldsValue();
        event.startDate = event.date[0].startOf('day').utc(true);
        event.endDate = event.date[1].startOf('day').utc(true);
        event.time = moment(event.time).format('HH:mm:ss');
        event.hasReminder = props.event.hasReminder;

        if (props.event.hasReminder) {
          event.eventReminder = {
            reminderDateUtc: moment(event.reminderDateUtc).startOf('day').utc(true),
            reminderTimeUtc: moment.utc(event.reminderTimeUtc.utc(false)).format('HH:mm:ss'),
            shouldNotifyByEmail: props.event.eventReminder.shouldNotifyByEmail,
            repeatCount: parseInt(props.event.eventReminder.repeatCount),
            periodType: props.repeatOption,
            repeatOption: props.repeatOption,
            occuranceEndingDate: props.event.eventReminder.occuranceEndingDate ? moment(props.event.eventReminder.occuranceEndingDate).startOf('day').utc(true) : null,
            occurancesCount: props.event.eventReminder.occurancesCount,
            selectedWeekDays: null,
            repeatOnOption: null,
          };

          if (props.repeatOption === repeatOption.weekly) {
            const days = props.event.eventReminder.repeatWeekDays.filter((x) => x.isSelected).map((x) => x.name);
            const selectedDays = days.join();
            if (selectedDays) {
              event.eventReminder.selectedWeekDays = selectedDays;
            }
          } else if (props.repeatOption === repeatOption.monthly) {
            event.eventReminder.repeatOnOption = props.event.eventReminder.repeatOnOption;
          }
        } else {
          event.eventReminder = null;
        }

        if (isEdit) {
          event.id = props.event.id;
          props.editEventCall(event, props.portfolioId).then((result) => {
            if (result) {
              handleClose();
              if (props.afterEdit) {
                props.afterEdit(event);
              }
            }
          });
        } else {
          props.createEventCall(event, props.portfolioId).then((result) => {
            if (result) {
              handleClose();
              if (props.afterCreate) {
                props.afterCreate(event);
              }
            }
          });
        }
      })
      .catch((_) => {});
  };

  const onChangeAssignEntityType = (value) => {
    setAssignedEntityType(value);
  };

  const onChangeHasReminder = () => {
    if (!props.event.hasReminder) {
      eventRef.current.validateFields(['date']).then(() => {
        props.setValueEventCall('event.hasReminder', true);
      });
    } else {
      props.setValueEventCall('event.hasReminder', false);
    }
  };

  const onChangeDate = () => {
    eventRef.current.validateFields(['reminderDateUtc']);
  };

  const onChangeReminderShouldNotifyByEmail = () => {
    props.setValueEventCall('event.eventReminder.shouldNotifyByEmail', !props.event.eventReminder.shouldNotifyByEmail);
  };

  const disableReminderDate = (current) => {
    return current && current > eventRef.current.getFieldValue('date')[1];
  };

  const onChangeRepeatReminderOption = (value) => {
    eventRef.current
      .validateFields(['reminderDateUtc', 'reminderTimeUtc'])
      .then(() => {
        if (value === repeatOption.everyWeekday) {
          props.setValueEventCall('event.eventReminder.repeatOption', repeatOption.weekly);
          if (props.event.eventReminder.repeatOption !== repeatOption.everyWeekday) {
            const repeatDays = [...props.event.eventReminder.repeatWeekDays];
            repeatDays.forEach((element) => {
              if (element.name !== 'SUN' && element.name !== 'SAT') {
                element.isSelected = true;
              }
            });
            props.setValueEventCall('event.eventReminder.repeatWeekDays', repeatDays);
          }
        } else if (value === repeatOption.weekly) {
          props.setValueEventCall('event.eventReminder.repeatOption', repeatOption.weekly);
          if (props.event.eventReminder.repeatOption !== repeatOption.weekly) {
            const repeatDays = cloneDeep(repeatWeekDays);
            const dayNumber = moment().day();
            repeatDays[dayNumber - 1].isSelected = true;
            props.setValueEventCall('event.eventReminder.repeatWeekDays', repeatDays);
          }
        } else if (value === repeatOption.custom) {
          props.setValueEventCall('event.eventReminder.repeatOption', props.repeatOption === repeatOption.doesNotRepeat ? repeatOption.daily : props.repeatOption);
        } else {
          props.setValueEventCall('event.eventReminder.repeatOption', value);
        }
        if (value !== repeatOption.doesNotRepeat) {
          props.setValueEventCall('showReminderModalWindow', true);
        }
      })
      .catch((e) => {
        if (eventRef.current) {
          eventRef.current.setFieldsValue({ repeatOption: repeatOption.doesNotRepeat });
        }
      });
  };

  const removeItem = (value) => {
    const users = props.eventUsers.filter((item) => item !== value);
    props.setValueEventCall('event.assignedUserIds', users);
    eventRef.current.setFieldsValue({ assignedUserIds: users });
    eventRef.current.validateFields(['assignedUserIds']);
  };

  const onChange = (users) => {
    props.setValueEventCall('event.assignedUserIds', users);
    eventRef.current.setFieldsValue({ assignedUserIds: users });
    eventRef.current.validateFields(['assignedUserIds']);
  };

  const getUserName = (userId) => {
    const user = props.users.find((x) => x.userId === userId);
    if (user) {
      return user.userName;
    }
    return null;
  };

  const handleClose = () => {
    props.setValueEventCall('event', defaultEvent);
    props.setValueEventCall('contracts', []);
    props.setValueEventCall('properties', []);
    props.setValueEventCall('portfolioUsers', []);
    props.onCloseModalWindow();
  };

  return (
    <Modal
      wrapClassName="result-modal creation-modal"
      footer={null}
      visible={true}
      maskClosable={false}
      onCancel={handleClose}
      closeIcon={<CloseOutlined className="close-icon" />}>
      <div>
        <div className="header flex-column">
          <h4 className="mb-2">{isEdit ? t('events.modal.editHeader') : t('events.modal.header')}</h4>
          <h6 className="mb-0 text-secondary font-weight-normal">{t('events.modal.subTitle')}</h6>
        </div>
        <div className="main">
          <Form name="basic" autoComplete="off" ref={eventRef}>
            <Form.Item name="title" initialValue={props.event.title && props.event.title} rules={[requiredCommonInput(t('events.modal.eventName.title') + t('common.isRequired'))]}>
              <CommonInput maxLength={1024} title={t('events.modal.eventName.title')} placeholder={t('events.modal.eventName.placeholder')} />
            </Form.Item>
            {!props.contract ? (
              <React.Fragment>
                <p className="font-weight-bold mb-2">{t('events.modal.assignToEntity.title')}?</p>
                <Radio.Group defaultValue={assignedEntityType} className="radio-container my-3" onChange={(e) => onChangeAssignEntityType(e.target.value)}>
                  <Radio value={'contract'}>{t('events.modal.assignToEntity.contract')}</Radio>
                  <Radio value={'property'}>{t('events.modal.assignToEntity.property')}</Radio>
                </Radio.Group>
              </React.Fragment>
            ) : (
              <p className="font-weight-bold mb-2">{t('events.modal.assignToContract')}</p>
            )}
            {!props.contract ? (
              assignedEntityType === 'property' ? (
                <Form.Item
                  name="propertyId"
                  initialValue={props.event.propertyId && props.event.propertyId}
                  rules={[
                    {
                      required: true,
                      message: t('events.modal.assignToEntity.title') + t('common.isRequired'),
                    },
                  ]}>
                  <Select placeholder={t('events.modal.assignToEntity.propertyPlaceholder')}>
                    {props.properties.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  name="contractId"
                  initialValue={props.event.contractId && props.event.contractId}
                  rules={[
                    {
                      required: true,
                      message: t('events.modal.assignToEntity.title') + t('common.isRequired'),
                    },
                  ]}>
                  <Select placeholder={t('events.modal.assignToEntity.contractPlaceholder')}>
                    {props.contracts.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            ) : (
              <Form.Item
                name="contractId"
                initialValue={props.event.contractId ? props.event.contractId : props.contract.id}
                rules={[
                  {
                    required: true,
                    message: t('events.modal.assignToEntity.title') + t('common.isRequired'),
                  },
                ]}>
                <Select placeholder={t('events.modal.assignToEntity.contractPlaceholder')}>
                  {props.contracts.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <p className="font-weight-bold mb-2">{t('events.modal.assignPersonToEvent.title')}?</p>
            <Form.Item
              name="assignedUserIds"
              initialValue={props.eventUsers}
              rules={[
                {
                  required: true,
                  message: t('events.modal.assignPersonToEvent.message'),
                },
              ]}>
              <div className="multiple-cards-container">
                <div className="select-container multiple-cards">
                  <Select
                    mode="multiple"
                    tagRender={() => {}}
                    listHeight={120}
                    onChange={onChange}
                    value={props.eventUsers}
                    placeholder={props.placeholder}
                    onKeyDown={(e) => (e.keyCode === enterBtnCode ? e.preventDefault() : '')}>
                    {props.hasUsers &&
                      props.usersIds.map((user, i) => (
                        <Option key={i} value={user}>
                          <p className="mb-0">{getUserName(user)}</p>
                        </Option>
                      ))}
                  </Select>
                  {props.getRelatedDataLoading && <LoadingOutlined className="autocomplete-loading-icon" />}
                </div>
                {props.hasEventUsers &&
                  props.eventUsers.map((user, i) => (
                    <Tag className="selected-item" closable key={user} onClose={(e) => removeItem(user)}>
                      {getUserName(user)}
                    </Tag>
                  ))}
              </div>
            </Form.Item>
            <p className="font-weight-bold">{t('events.modal.date')}</p>
            <Form.Item
              name="date"
              initialValue={props.event.startDate && props.event.endDate ? [moment(props.event.startDate), moment(props.event.endDate)] : null}
              rules={[
                {
                  required: true,
                  message: t('events.modal.date') + t('common.isRequired'),
                },
              ]}>
              <RangePicker panelRender={mobileRangePickerPanel} suffixIcon={<Image src={calendarIcon} />} onChange={onChangeDate} format={primaryDateFormat} allowClear={false} />
            </Form.Item>
            <p className="font-weight-bold">{t('events.modal.time.title')}</p>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Item
                  name="time"
                  initialValue={props.event.time ? moment().startOf('day').add(props.event.time.totalSeconds, 'seconds') : null}
                  rules={[
                    {
                      required: true,
                      message: t('events.modal.time.title') + t('common.isRequired'),
                    },
                  ]}>
                  <CustomTimePicker format={'HH:mm'} allowClear={false} popupClassName="time-picker-dropdown" placeholder={t('events.modal.time.placeholder')} />
                </Form.Item>
              </Col>
            </Row>
            <Checkbox className="mb-3" checked={props.event.hasReminder} onChange={onChangeHasReminder}>
              {t('events.modal.addReminder')}
            </Checkbox>
            {props.event.hasReminder && (
              <React.Fragment>
                <p className="font-weight-bold">{t('events.modal.reminderDateAndTime.title')}</p>
                <Row>
                  <Col xs={12} sm={6}>
                    <Form.Item
                      name="reminderDateUtc"
                      initialValue={props.event.eventReminder.reminderDateUtc ? moment(props.event.eventReminder.reminderDateUtc) : null}
                      rules={[
                        {
                          required: true,
                          message: t('events.modal.reminderDateAndTime.reminderDate') + t('common.isRequired'),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue('date')[1] >= value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('events.modal.reminderDateAndTime.reminderDate.errorMessage')));
                          },
                        }),
                      ]}>
                      <DatePicker
                        suffixIcon={<Image src={calendarIcon} />}
                        placeholder={t('events.modal.reminderDateAndTime.reminderDate')}
                        disabledDate={disableReminderDate}
                        format={primaryDateFormat}
                        onSelect={(e) => props.setValueEventCall('event.eventReminder.reminderDateUtc', e)}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Item
                      name="reminderTimeUtc"
                      initialValue={
                        props.event.eventReminder.reminderTimeUtc ? moment.utc().startOf('day').add(props.event.eventReminder.reminderTimeUtc, 'seconds').local() : null
                      }
                      rules={[
                        {
                          required: true,
                          message: t('events.modal.reminderDateAndTime.reminderTime') + t('common.isRequired'),
                        },
                      ]}>
                      <CustomTimePicker
                        format={'HH:mm'}
                        allowClear={false}
                        popupClassName="time-picker-dropdown"
                        placeholder={t('events.modal.reminderDateAndTime.reminderTime')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <p className="font-weight-bold">{t('events.modal.repeatOption.title')}</p>
                <Row>
                  <Col xs={12} sm={6}>
                    <Form.Item
                      name="repeatOption"
                      initialValue={props.repeatOption}
                      rules={[
                        {
                          required: true,
                          message: t('events.modal.repeatOption.title') + t('common.isRequired'),
                        },
                      ]}>
                      <Select onSelect={(e) => onChangeRepeatReminderOption(e)}>
                        {repeatReminderOptions.map((item) => (
                          <Option value={item.id} key={item.id}>
                            {item.name()}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Checkbox className="mb-3" checked={props.event.eventReminder.shouldNotifyByEmail} onChange={onChangeReminderShouldNotifyByEmail}>
                  {t('events.modal.reminedViaEmail')}
                </Checkbox>
              </React.Fragment>
            )}
            <p className="font-weight-bold">{t('events.modal.description.title')}</p>
            <Form.Item className="mb-0" name="description" initialValue={props.event.description}>
              <TextArea placeholder={t('events.modal.description.placeholder')} maxLength={1024} rows={5} style={{ minHeight: '48px' }} />
            </Form.Item>
          </Form>
        </div>
        <div className="btns">
          <ActionButton className="btn-secondary mr-3" text={t('common.buttons.cancel')} onClick={handleClose} disabled={props.eventLoading}></ActionButton>
          <ActionButton
            className="btn-primary"
            text={isEdit ? t('common.buttons.saveChanges') : t('events.modal.createBtn')}
            onClick={onSave}
            disabled={props.eventLoading}
            loading={props.eventLoading}></ActionButton>
        </div>
      </div>
    </Modal>
  );
};

const mapState = ({ events, navigation, contract }) => {
  return {
    portfolioId: get(navigation, 'selectedPortfolio.id'),
    contract: get(contract, 'contract'),
    event: get(events, 'event'),
    properties: get(events, 'properties'),
    eventLoading: get(events, 'eventLoading'),
    contracts: get(events, 'contracts'),
    eventUsers: get(events, 'event.assignedUserIds'),
    hasEventUsers: get(events, 'event.assignedUserIds.length') > 0,
    users: get(events, 'portfolioUsers'),
    hasUsers: get(events, 'portfolioUsers.length') > 0,
    usersIds: get(events, 'portfolioUsers').map((x) => x.userId),
    getRelatedDataLoading: get(events, 'getRelatedDataLoading'),
    repeatOption: get(events, 'event.eventReminder.repeatOption'),
  };
};

const mapDispatch = (dispatch) => {
  return {
    setValueEventCall(key, value) {
      dispatch(setValueEvent(key, value));
    },
    createEventCall(data, portfolioId) {
      return dispatch(createEvent(data, portfolioId));
    },
    editEventCall(data, portfolioId) {
      return dispatch(editEvent(data, portfolioId));
    },
  };
};

const EventModal = connect(mapState, mapDispatch)(EventModalComponent);
export default EventModal;
