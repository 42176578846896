import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../components/action-button';
import CommonCostItemReview from '../../../components/common-cost/cost-item-review';
import PropertyRow from '../../../components/property-row';
import { commonCostSelector, selectFilteredActualCosts } from '../selectors';
import CurrencyFormat from '../../../components/currency-format';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import moment from 'moment';
import { Divider } from 'antd';
import SuccessMessage from '../../../components/success-message';

const CommonCostActualsReview = (props) => {
    const { t } = useTranslation();
    const commonCost = useSelector(commonCostSelector);
    const commonCostActuals = useSelector(selectFilteredActualCosts);
    const propertyName = useSelector(({ property }) => property.property.propertyName);

    const total = commonCostActuals.map((item) => item.value).reduce((a, b) => a + b, 0);
    const adminFee = commonCost.budgetAdministrationFeePercent ? (total * commonCost.budgetAdministrationFeePercent) / 100 : commonCost.budgetAdministrationFee ?? 0;
    const totalCosts = total + adminFee;
    const costPerSqm = totalCosts / commonCost.lettable;

    return (
        <div className="page-container review">
            {
                <div className="review-part">
                    <div className="d-flex flex-row justify-content-between align-items-baseline">
                        <h5>{t('commonCost.review.costDetails.actualsDetails')}</h5>
                    </div>
                    <PropertyRow title={t('commonCost.review.costDetails.costName')} value={commonCost.name} className="common-cost-review-details-item" />
                    <PropertyRow
                        title={t('commonCost.review.costDetails.costPeriod')}
                        value={`${moment(commonCost.startDate).format(primaryDateFormat)} - ${moment(commonCost.endDate).format(primaryDateFormat)}`}
                        className="common-cost-review-details-item"
                    />
                    <PropertyRow title={t('commonCost.review.costDetails.assignedTo')} value={propertyName} className="common-cost-review-details-item" />
                    <PropertyRow
                        title={t('commonCost.review.costDetails.lettableArea')}
                        value={commonCost.lettable + t('commonCost.tenantCard.m2')}
                        className="common-cost-review-details-item"
                        style={{ marginBottom: '45px' }}
                    />
                    <div className="review-items-wrapper">
                        {commonCostActuals.map((cost) => (
                            <CommonCostItemReview key={cost.name} cost={cost} goToStep={props.goToStep} />
                        ))}
                        <Divider className="common-cost-items-review-divider" />
                        <PropertyRow title={t('commonCost.addNewCommonCost.review.total')} value={<CurrencyFormat value={total} />} className="common-cost-review-merged-item" />
                        <PropertyRow
                            title={t('commonCost.addNewCommonCost.review.adminFee')}
                            value={<CurrencyFormat value={adminFee} />}
                            className="common-cost-review-merged-item"
                        />
                        <PropertyRow
                            title={t('commonCost.addNewCommonCost.review.totalCosts')}
                            value={<CurrencyFormat value={totalCosts} />}
                            className="common-cost-review-merged-item common-cost-add-totals"
                        />
                        <PropertyRow
                            title={t('commonCost.addNewCommonCost.review.costPerSqm')}
                            value={<CurrencyFormat value={costPerSqm} />}
                            className="common-cost-review-merged-item common-cost-add-totals"
                        />
                    </div>
                    <SuccessMessage textElements={<><p className="font-weight-bold">{t('commonCost.review.successMessage.looksGood')}</p><p>{t('commonCost.review.successMessage.notification')}</p></>}/>
                </div>
            }
        </div>
    );
};

export default CommonCostActualsReview;
