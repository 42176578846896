import axios from 'axios';
import { commonCostItemTypeOptions } from '../common/common-cost-options';
import helper from '../helpers/helper';

const commonCostService = {
    listOfCostsIsEmpty: (costs) => {
        return !costs.some((x) => x.value);
    },

    getRelatedEntityName: (commonCost, buildings, plots) => {
        if (commonCost.buildingId) {
            const building = buildings.find((x) => x.id === commonCost.buildingId);
            return building ? (building.name ? building.name : building.address) : '';
        } else {
            const plot = plots.find((x) => x.id === commonCost.plotId);
            return plot ? plot.displayName : '';
        }
    },

    mapCommonCostItemNames: (commonCost) => {
        const mapName = (x) => (x.name = helper.getEnumValue(commonCostItemTypeOptions, x.costType) || x.name);

        if (commonCost) {
            commonCost.commonCostBudgets = commonCost.commonCostItems.filter((item) => item.isBudget) || [];
            commonCost.commonCostActuals = commonCost.commonCostItems.filter((item) => !item.isBudget) || [];

            commonCost.commonCostBudgets.forEach(mapName);
            commonCost.commonCostActuals.forEach(mapName);
        }

        return commonCost;
    },

    mergeBudgetAndActualItems: (budgetItems, actualItems) => {
        const budgetNames = budgetItems.map((x) => x.name);
        const actualNames = actualItems.map((x) => x.name);
        const uniqueKeys = [...new Set([...budgetNames, ...actualNames])];

        return uniqueKeys.map((key) => ({
            key,
            budgetItem: budgetItems.find((x) => x.name === key),
            actualItem: actualItems.find((x) => x.name === key),
        }));
    },

    filterCommonCostItemsByValue: (items) => (items || []).filter((x) => x.value !== null && x.value !== undefined),

    getListOfCommonCosts: (portfolioId, propertyId, year, status) => {
        return axios({
            method: 'post',
            url: year
                ? `/api/contract/commonCosts?portfolioId=${portfolioId}&propertyId=${propertyId}&year=${year}`
                : `/api/contract/commonCosts?portfolioId=${portfolioId}&propertyId=${propertyId}`,
            data: {
                status: status,
            },
        });
    },

    create: (data) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/commonCosts/create-common-cost?portfolioId=${data.portfolioId}&propertyId=${data.propertyId}`,
        });
    },

    updateFractions: (data, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/commonCosts/update-fractions?portfolioId=${portfolioId}&propertyId=${propertyId}&commonCostId=${data.commonCostId}`,
        });
    },

    update: (data) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/commonCosts/update-common-cost?portfolioId=${data.portfolioId}&propertyId=${data.propertyId}&commonCostId=${data.id}`,
        });
    },

    updateLettableArea: (data, propertyId, commonCostId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/commonCosts/update-common-cost-lettable-area?portfolioId=${portfolioId}&propertyId=${propertyId}&commonCostId=${commonCostId}`,
        });
    },

    addActuals: (data, propertyId, commonCostId, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/commonCosts/add-common-cost-actuals?portfolioId=${portfolioId}&propertyId=${propertyId}&commonCostId=${commonCostId}`,
        });
    },

    delete: (propertyId, commonCostId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/commonCosts/${commonCostId}?portfolioId=${portfolioId}&propertyId=${propertyId}&commonCostId=${commonCostId}`,
        });
    },

    getCommonCostById: (portfolioId, propertyId, commonCostId) => {
        return axios({
            method: 'get',
            url: `/api/contract/commonCosts/${commonCostId}?portfolioId=${portfolioId}&propertyId=${propertyId}&commonCostId=${commonCostId}`,
        });
    },

    getCommonCostDetails: (portfolioId, propertyId, commonCostId) => {
        return axios({
            method: 'get',
            url: `/api/contract/commonCosts/${commonCostId}/details?portfolioId=${portfolioId}&propertyId=${propertyId}&commonCostId=${commonCostId}`,
        });
    },

    getCommonCostCalculations: (portfolioId, propertyId, commonCostId) => {
        return axios({
            method: 'get',
            url: `/api/contract/commonCosts/${commonCostId}/calculations?portfolioId=${portfolioId}&propertyId=${propertyId}&commonCostId=${commonCostId}`,
        });
    },

    getAreaForAssignedAsset: (propertyId, plotId, buildingId, portfolioId) => {
        return axios({
            method: 'get',
            url: `/api/contract/commonCosts/get-area-for-asset?portfolioId=${portfolioId}&propertyId=${propertyId}&plotId=${plotId || ''}&buildingId=${buildingId || ''}`,
        });
    },
};

export default commonCostService;
