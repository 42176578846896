import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router';
import { Image } from 'react-bootstrap';
import { Form } from 'antd';
import { createPlots, defaultPlot, setValuePlot } from '../../../reducers/plotReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';
import plotService from '../../../services/plotService';
import routingService from '../../../services/routingService';
import objectHelper from '../../../helpers/trim-object';
import toaster from '../../../common/toaster';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import plotLogo from '../../../assets/images/new-design-plot-icon.svg';
import review from '../../../assets/images/review.svg';
import add from '../../../assets/images/add.svg';
import InformationComponent from '../../../components/information-container';
import CreatedComponent from '../../../components/object/created-object';
import ObjectLayout from '../../../components/layouts/object-layout';
import ActionButton from '../../../components/action-button';
import DetailsStep from './details-step';
import PlotReview from './review-step';

const CreatePlotComponent = (props) => {
    const { t } = useTranslation();

    const stepNames = {
        details: 'details',
        review: 'review',
    };

    const plotRef = useRef();
    const [currentStepKey, setCurrentStepKey] = useState(stepNames.details);
    const [currentPlotIdOnEdit, setCurrentPlotIdOnEdit] = useState();
    const { propertyId, portfolioId } = useParams();
    const plotsUrl = routingService.plotsUrl(portfolioId, propertyId);

    useEffect(() => {
        props.getPropertyCall(portfolioId, propertyId);
    }, []);

    const onNext = async () => {
        validatePlotRef()
            .then((_) => {
                const plots = [...props.plots];
                let newPlot = getPlotFromForm();
                newPlot.plotHasName = props.plot.plotHasName;
                newPlot.address = props.plot.address;
                if (!props.plot.uId) {
                    newPlot.uId = uuidv4();
                    plots.push(newPlot);
                } else {
                    const index = plots.findIndex((item) => item.uId === props.plot.uId);
                    if (index !== -1) {
                        newPlot.uId = props.plot.uId;
                        plots[index] = newPlot;
                    }
                }
                setCurrentPlotIdOnEdit(null);
                props.setValuePlotCall('plots', plots);
                props.setValuePlotCall('plot', defaultPlot);

                setCurrentStepKey(stepNames.review);
            })
            .catch((_) => {});
    };

    const onPrevious = () => {
        setCurrentStepKey(stepNames.details);

        const plots = [...props.plots];
        if (plots[0]) {
            const plot = plots[0];
            setCurrentPlotIdOnEdit(plot.uId);
            props.setValuePlotCall('plot', plot);
            plotRef.current.setFieldsValue({
                name: plot.name,
                mainAddress: plot.mainAddress,
                municipality: plot.municipality + '/' + plot.city,
            });
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious();
        } else {
            onNext();
        }
    };

    const goToEdit = (stepKey, objectId) => {
        setCurrentStepKey(stepKey);
        editPlot(objectId, true);
    };

    const getPlotFromForm = () => {
        const values = plotRef.current.getFieldsValue();
        values.city = props.plot.city;
        values.municipality = props.plot.municipality;
        values.addresses = props.plot.addresses;
        values.gnrNumber = props.plot.gnrNumber;
        values.bnrNumber = props.plot.bnrNumber;
        values.address = props.plot.address;
        values.displayName = plotService.getPlotDisplayName(values);
        return objectHelper.trimObjectProperties(values);
    };

    const checkPlotDuplicationOnCurrentList = () => {
        if (props.plots) {
            const addedPlot = props.plots.find(
                (x) =>
                    x.gnrNumber === props.plot.gnrNumber &&
                    x.bnrNumber === props.plot.bnrNumber &&
                    x.municipality === props.plot.municipality &&
                    x.mainAddress === props.plot.mainAddress
            );
            if (addedPlot) {
                const isCurrentPlot = addedPlot.uId === props.plot.uId;
                if (!isCurrentPlot) {
                    props.setValuePlotCall('hasPlotDuplicate', true);
                    throw Error();
                }
            }
        }
    };

    const checkPlotDuplicationOnPropertyList = (plotObject) => {
        if (props.propertyPlots && plotObject.mainAddress) {
            const plot = props.propertyPlots.find(
                (x) =>
                    x.gnrNumber === props.plot.gnrNumber &&
                    x.bnrNumber === props.plot.bnrNumber &&
                    x.municipality === props.plot.municipality &&
                    x.mainAddress === props.plot.mainAddress
            );
            if (plot) {
                props.setValuePlotCall('hasPlotDuplicate', true);
                throw Error();
            }
        }
    };

    const validatePlotRef = async () => {
        await plotRef.current.validateFields();
        const values = plotRef.current.getFieldsValue();
        const plot = objectHelper.trimObjectProperties(values);

        checkPlotDuplicationOnCurrentList();
        checkPlotDuplicationOnPropertyList(plot);
    };

    const deletePlot = (id) => {
        props.setValuePlotCall(
            'plots',
            props.plots.filter((item) => item.uId !== id)
        );
    };

    const editPlot = async (id, fromReview) => {
        await addAnotherPlot(id, fromReview);
    };

    const setPlot = (id) => {
        const plot = props.plots.find((item) => item.uId === id);
        if (plot) {
            setCurrentPlotIdOnEdit(plot.uId);
            plotRef.current.setFieldsValue({
                name: plot.name,
                mainAddress: plot.mainAddress,
                municipality: plot.municipality + '/' + plot.city,
            });
            props.setValuePlotCall('plot', plot);
            props.setValuePlotCall('hasPlotDuplicate', false);
        }
    };

    const addAnotherPlot = async (id, fromReview) => {
        try {
            await validatePlotRef();
            const plot = getPlotFromForm();
            plot.plotHasName = props.plot.plotHasName;
            plot.address = props.plot.address;
            const plots = [...props.plots];
            if (currentPlotIdOnEdit) {
                const index = plots.findIndex((item) => item.uId === currentPlotIdOnEdit);
                const oldPlot = plots.find((item) => item.uId === currentPlotIdOnEdit);
                if (index !== -1) {
                    plot.uId = oldPlot.uId;
                    plots[index] = plot;
                    props.setValuePlotCall('plots', plots);
                }
            } else if (!fromReview || (!currentPlotIdOnEdit && !id)) {
                plot.uId = uuidv4();
                plots.push(plot);
                props.setValuePlotCall('plots', plots);
            }
            props.setValuePlotCall('plot', defaultPlot);
            props.setValuePlotCall('plotInfo', null);
            plotRef.current.resetFields();
            setCurrentPlotIdOnEdit(null);

            if (id) {
                setPlot(id);
            } else {
                props.setValuePlotCall('plotAddresses', null);
                props.setValuePlotCall('addresses', []);
            }
        } catch (e) {
            if (e.values && !e.values.name && !e.values.municipality && !e.values.mainAddress) {
                setPlot(id);
            } else {
                if (id) {
                    toaster.error(t('common.editAddedEntity'), null);
                }
            }
        }
    };

    const created = (isCreated) => {
        if (isCreated) {
            routingService.goTo(plotsUrl);
            props.showPlotCreationResult();
        }
    };

    const onSaveAndExit = () => {
        if (propertyId) {
            const plots = [...props.plots].map((plot) => {
                plot.propertyId = +propertyId;
                return plot;
            });
            props.createPlotsCall(plots, propertyId, portfolioId).then((isCreated) => created(isCreated));
        }
    };

    const steps = [
        {
            key: stepNames.details,
            header: t('plot.details.header', { propertyName: props.propertyName }),
            stepTitle: t('plot.details.stepTitle'),
            logo: plotLogo,
            canShowAsCompleted: true,
            content: (
                <div className="page-container">
                    {props.hasPlots &&
                        props.plots.map((plot) =>
                            props.plot.uId === plot.uId ? null : (
                                <CreatedComponent title={plot.displayName} key={plot.uId} id={plot.uId} editObject={editPlot} deleteObject={props.plots.length > 1 ? deletePlot : null} />
                            )
                        )}
                    {currentStepKey === stepNames.details && <DetailsStep plotRef={plotRef} />}
                    <ActionButton icon={<Image src={add} />} onClick={addAnotherPlot} className="btn-secondary" text={t('plot.btnAdd')} />
                </div>
            ),
        },
        {
            key: stepNames.review,
            header: t('plot.review.header'),
            stepTitle: t('plot.review.stepTitle'),
            logo: review,
            content: <PlotReview goToStep={goToEdit} />,
        },
    ];

    return (
        <Form name="basic" ref={plotRef} autoComplete="off" className="h-100">
            <ObjectLayout
                bottomRightSideBar={
                    <React.Fragment>
                        {currentStepKey === stepNames.details && <InformationComponent title={t('plot.information.title')} text={t('plot.information.text')} />}
                    </React.Fragment>
                }
                steps={steps}
                timelineSteps={steps}
                currentStepKey={currentStepKey}
                exitUrl={plotsUrl}
                loading={props.plotLoading || props.propertyLoading}
                onNext={onNext}
                onPrevious={onPrevious}
                onSwitchStep={onSwitchStep}
                onSaveAndExit={onSaveAndExit}
            />
        </Form>
    );
};

const mapState = ({ plot, property }) => {
    return {
        plot: get(plot, 'plot'),
        plots: get(plot, 'plots'),
        hasPlots: get(plot, 'plots.length') > 0,
        plotLoading: get(plot, 'plotLoading'),
        propertyPlots: get(property, 'property.plots'),
        propertyName: get(property, 'property.propertyName'),
        propertyLoading: get(property, 'propertyLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        createPlotsCall(plot, propertyId, portfolioId) {
            return dispatch(createPlots(plot, propertyId, portfolioId));
        },
        setValuePlotCall(key, value) {
            dispatch(setValuePlot(key, value));
        },
        showPlotCreationResult() {
            dispatch(setValuePlot('showCreationResultModal', true));
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
    };
};

const CreatePlot = clear(connect(mapState, mapDispatch)(CreatePlotComponent));
export default CreatePlot;
