import i18next from 'i18next';

export const commonCostItemType = {
    externalBuildingCosts: 1,
    municipalFees: 2,
    showRemoval: 3,
    caretakerCosts: 4,
    energyCommon: 5,
    internalBuildingCosts: 6,
    cleaningCosts: 7,
    securityCosts: 8,
    hvacSystemsCosts: 9,
    elevatorCosts: 10,
    otherSystemsCosts: 11,
};

//TODO: localization
export const commonCostItemTypeOptions = [
    { id: commonCostItemType.externalBuildingCosts, name: () => i18next.t('commonCost.costs.externalBuildingCosts') },
    { id: commonCostItemType.municipalFees, name: () => i18next.t('commonCost.costs.municipalFees') },
    { id: commonCostItemType.showRemoval, name: () => i18next.t('commonCost.costs.showRemoval') },
    { id: commonCostItemType.caretakerCosts, name: () => i18next.t('commonCost.costs.caretakerCosts') },
    { id: commonCostItemType.energyCommon, name: () => i18next.t('commonCost.costs.energyCommon') },
    { id: commonCostItemType.internalBuildingCosts, name: () => i18next.t('commonCost.costs.internalBuildingCosts') },
    { id: commonCostItemType.cleaningCosts, name: () => i18next.t('commonCost.costs.cleaningCosts') },
    { id: commonCostItemType.securityCosts, name: () => i18next.t('commonCost.costs.securityCosts') },
    { id: commonCostItemType.hvacSystemsCosts, name: () => i18next.t('commonCost.costs.hvacSystemsCosts') },
    { id: commonCostItemType.elevatorCosts, name: () => i18next.t('commonCost.costs.elevatorCosts') },
    { id: commonCostItemType.otherSystemsCosts, name: () => i18next.t('commonCost.costs.otherSystemsCosts') },
];

export const feesValueTypes = {
    percent: 1,
    currency: 2,
};

export const rangeOptions = {
    start: 'start',
    end: 'end',
};
