import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { setValueContract, updateRentObjectsCommonCostDetails } from '../../../../reducers/contractReducer';
import get from 'lodash-es/get';
import CommonCostOptions from './common-cost-options';
import { measurementPeriodTypes, measurementTypes } from '../../../../common/contract-options';
import ActionButton from '../../../../components/action-button';
import { useParams } from 'react-router';

const CommonCostRentalObjectCardComponent = (props) => {
    const { t } = useTranslation();
    const editRentObjectRef = React.useRef();
    const [loading, setLoading] = useState(false);
    const { portfolioId, propertyId } = useParams();
    const [noCommonCost, setNoCommonCost] = useState(props.rentObject.noCommonCost);
    const [commonCostPerUnitPerYear, setCommonCostPerUnitPerYear] = useState(props.rentObject ? props.rentObject.commonCostPerUnitPerYear : null);

    useEffect(() => {
        if (props.rentObject) {
            const selectedMeasurement = !!props.rentObject.commonCostMeasurements && props.rentObject.commonCostMeasurements.find((item) => item.isSelected);
            editRentObjectRef.current.setFieldsValue({
                isTenantCoverPropertyTax: props.rentObject?.isTenantCoverPropertyTax,
                noCommonCost: props.rentObject?.noCommonCost,
                commonCostValue: !!selectedMeasurement ? selectedMeasurement.value : undefined,
                measurement: !!selectedMeasurement
                    ? JSON.stringify({ measurementTypeId: selectedMeasurement.measurementTypeId, measurementPeriodTypeId: selectedMeasurement.measurementPeriodTypeId })
                    : JSON.stringify({ measurementTypeId: measurementTypes.units, measurementPeriodTypeId: measurementPeriodTypes.annual }),
            });
        }
    }, []);

    const done = () => {
        return editRentObjectRef.current.validateFields().then(() => {
            setLoading(true);
            const values = editRentObjectRef.current.getFieldsValue();
            const measurement = !!values.measurement && JSON.parse(values?.measurement);

            const commonCost = {
                id: props.rentObject.id,
                isTenantCoverPropertyTax: values?.isTenantCoverPropertyTax,
                noCommonCost: values?.noCommonCost !== null ? values?.noCommonCost : false,
                commonCostValue: values?.commonCostValue,
                measurementTypeId: !!measurement && !values?.noCommonCost ? measurement?.measurementTypeId : undefined,
                measurementPeriodTypeId: !!measurement && !values?.noCommonCost ? measurement?.measurementPeriodTypeId : undefined,
            };

            props.setValueContractCall('commonCostOptions', [...props.commonCostOptions, commonCost]);
            setLoading(false);
        });
    };

    const setNoCommonCostFunc = () => {
        setNoCommonCost(!noCommonCost);
        if (!noCommonCost) {
            editRentObjectRef.current.resetFields(['commonCostValue', 'measurement']);
            setCommonCostPerUnitPerYear(null);
        }
    };

    return (
        <Form name="common-cost-card" autoComplete="off" ref={editRentObjectRef}>
            <div className="d-flex flex-column pl-2">
                <CommonCostOptions
                    commonCostPerUnitPerYear={commonCostPerUnitPerYear}
                    setCommonCostPerUnitPerYear={setCommonCostPerUnitPerYear}
                    noCommonCost={noCommonCost}
                    setNoCommonCostFunc={setNoCommonCostFunc}
                />
                <Row justify="end" className="content-block-actions m-0">
                    <Col className="ml-3">
                        <ActionButton
                            className='content-block-action btn-primary'
                            loading={loading}
                            disabled={editRentObjectRef.current && editRentObjectRef.current.getFieldsError().filter(({ errors }) => errors.length).length}
                            text={t('common.buttons.done')}
                            onClick={() => {
                                done().then(() => {
                                    props.setActiveKeys(prev => prev.filter(item => item != props.rentObject.id))
                                });
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </Form>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        commonCostOptions: get(contract, 'commonCostOptions'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        updateRentObjectCommonCostDetailsCall(value, contractId, portfolioId, propertyId) {
            return dispatch(updateRentObjectsCommonCostDetails(value, contractId, portfolioId, propertyId));
        },
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
    };
};

const CommonCostRentalObjectCard = connect(mapState, mapDispatch)(CommonCostRentalObjectCardComponent);
export default CommonCostRentalObjectCard;
