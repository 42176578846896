import { Input, Form } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TurnoverRateLabel = (props) => {
    const { name, initialName } = props;

    const { t } = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const [labelName, setLabelName] = useState(initialName ? initialName : 'New Source');

    return (
        <div className="turnover-rate-label">
            <p onDoubleClick={() => setIsEdit(true)} style={{ display: !isEdit ? '' : 'none' }}>
                {labelName}
            </p>
            <Form.Item
                style={{ display: isEdit ? '' : 'none' }}
                initialValue={labelName}
                rules={[{ required: true, message: t('contract.turnover.turnoverRate.rateName') + t('common.isRequired') }]}
                name={[name, 'incomeSource']}>
                <Input
                    onBlur={() => labelName != '' && setIsEdit(false)}
                    onKeyDown={(e) => e.stopPropagation()}
                    value={labelName}
                    onChange={(e) => {
                        setLabelName(e.target.value);
                    }}
                />
            </Form.Item>
        </div>
    );
};

export default TurnoverRateLabel;
