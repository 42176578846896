import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setValueBuilding } from '../../../reducers/buildingReducer';
import buildingService from '../../../services/buildingService';
import MobileFloorConstructor from './constructor';
import get from 'lodash-es/get';
import NumberFormat from '../../../components/number-format';

const BuildingConstructorComponent = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (props.building.perFloor && props.buildingConstructorData) {
            props.setValueBuildingCall('buildingFormData', {
                commonArea: props.buildingConstructorData?.commonArea,
                grossArea: props.buildingConstructorData?.grossArea,
                nonLettable: props.buildingConstructorData?.nonLettable,
                levels: props.buildingConstructorData?.levels,
            });
        }
    }, [props.buildingConstructorData]);

    const calculateBuildingData = () => {
        if (props.floors && props.basementFloors) {
            if (!props.building.perFloor) {
                props.buildingFormData?.commonArea == null &&
                    props.setValueBuildingCall(
                        'buildingFormData.commonArea',
                        props.buildingConstructorData?.commonArea || buildingService.getBuildingCommonArea(props.floors, props.basementFloors)
                    );
                !props.buildingFormData?.grossArea &&
                    props.setValueBuildingCall(
                        'buildingFormData.grossArea',
                        props.buildingConstructorData?.grossArea || buildingService.getBuildingGrossArea(props.floors, props.basementFloors)
                    );
                !props.buildingFormData?.nonLettable &&
                    props.setValueBuildingCall(
                        'buildingFormData.nonLettable',
                        props.buildingConstructorData?.nonLettable || buildingService.getBuildingNonLettableArea(props.floors, props.basementFloors)
                    );
                !props.buildingFormData?.levels &&
                    props.setValueBuildingCall('buildingFormData.levels', props.buildingConstructorData?.levels || props.floors.length + props.basementFloors.length);
            }
            const buildingCommonArea = props.building.perFloor ? buildingService.getBuildingCommonArea(props.floors, props.basementFloors) : props.buildingFormData?.commonArea;
            const buildingGrossArea = props.building.perFloor ? buildingService.getBuildingGrossArea(props.floors, props.basementFloors) : props.buildingFormData?.grossArea;
            const buildingNonLettableArea = props.building.perFloor
                ? buildingService.getBuildingNonLettableArea(props.floors, props.basementFloors)
                : props.buildingFormData?.nonLettable;
            const buildingLettableArea = buildingGrossArea - (buildingNonLettableArea || 0);
            const buildingNettoArea = buildingLettableArea - buildingCommonArea;
            const buildingCommonPercents = buildingCommonArea && buildingNettoArea > 0 ? ((buildingCommonArea / buildingLettableArea) * 100).toFixed(2) : 0;
            const levels = props.building.perFloor ? props.floors.length + props.basementFloors.length : props.buildingFormData?.levels;

            return (
                <React.Fragment>
                    <Row className="desk-floors-header">
                        <Col sm={1}>
                            <p className="font-weight-bold m-0">{t('building.review.levels')}</p>
                        </Col>
                        <Col sm={2}>
                            <p className="font-weight-bold m-0">{t('building.review.grossArea')}</p>
                        </Col>
                        <Col sm={2}>
                            <p className="font-weight-bold m-0">{t('building.review.nonLettable')}</p>
                        </Col>
                        <Col sm={2}>
                            <p className="font-weight-bold m-0">{t('building.review.lettable')}</p>
                        </Col>
                        <Col sm={2}>
                            <p className="font-weight-bold m-0">{t('building.review.commonArea')}</p>
                        </Col>
                        <Col sm={2} className="pl-2">
                            <p className="font-weight-bold m-0">{t('building.buildingConstructor.netto')}</p>
                        </Col>
                        <Col sm={1} className="pl-2">
                            <p className="font-weight-bold m-0">{t('building.buildingConstructor.commonPercent')}</p>
                        </Col>
                    </Row>
                    <Row className="desk-floors-header">
                        <Col sm={1}>
                            <p className="text-secondary">{levels}</p>
                        </Col>
                        <Col sm={2}>
                            <p className="text-secondary">
                                <NumberFormat value={buildingGrossArea} />
                            </p>
                        </Col>
                        <Col sm={2}>
                            <p className="text-secondary">
                                <NumberFormat value={buildingNonLettableArea} />
                            </p>
                        </Col>
                        <Col sm={2}>
                            <p className="text-secondary">
                                <NumberFormat value={buildingLettableArea} />
                            </p>
                        </Col>
                        <Col sm={2}>
                            <p className="text-secondary">
                                <NumberFormat value={buildingCommonArea} />
                            </p>
                        </Col>
                        <Col sm={2} className="pl-2">
                            <p className="text-secondary">
                                <NumberFormat value={buildingNettoArea} />
                            </p>
                        </Col>
                        <Col sm={1} className="pl-2">
                            <p className="text-secondary">{buildingCommonPercents}%</p>
                        </Col>
                    </Row>
                    <div className="mobile-columns">
                        <Row>
                            <Col>
                                <p className="font-weight-bold m-0">{t('building.review.levels')}</p>
                            </Col>
                            <Col>
                                <p className="text-secondary text-right">{levels}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="font-weight-bold m-0">{t('building.review.grossArea')}</p>
                            </Col>
                            <Col>
                                <p className="text-secondary text-right">
                                    <NumberFormat value={buildingGrossArea} />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="font-weight-bold m-0">{t('building.review.nonLettable')}</p>
                            </Col>
                            <Col>
                                <p className="text-secondary text-right">
                                    <NumberFormat value={buildingNonLettableArea} />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="font-weight-bold m-0">{t('building.review.lettable')}</p>
                            </Col>
                            <Col>
                                <p className="text-secondary text-right">
                                    <NumberFormat value={buildingLettableArea} />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="font-weight-bold m-0">{t('building.review.commonArea')}</p>
                            </Col>
                            <Col>
                                <p className="text-secondary text-right">
                                    <NumberFormat value={buildingCommonArea} />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="font-weight-bold m-0">{t('building.buildingConstructor.netto')}</p>
                            </Col>
                            <Col>
                                <p className="text-secondary text-right">
                                    <NumberFormat value={buildingNettoArea} />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="font-weight-bold m-0">{t('building.buildingConstructor.commonPercent')}</p>
                            </Col>
                            <Col>
                                <p className="text-secondary text-right">{buildingCommonPercents}%</p>
                            </Col>
                        </Row>
                    </div>
                </React.Fragment>
            );
        }
    };

    useEffect(() => {
        props.setValueBuildingCall('buildingFormData', {
            commonArea: props.building.commonArea,
            grossArea: props.building.grossArea,
            nonLettable: props.building.nonLettable,
            levels: props.building.levels,
        });
    }, []);

    return (
        <div className="building-constructor-modal result-modal">
            <div className={`building-constructor-main ${props.building.perFloor ? 'building-constructor-main-not-collapsed' : ''}`}>
                <Form form={props.buildingForm} autoComplete="off">
                    <MobileFloorConstructor floorsForm={props.buildingForm} />
                </Form>
            </div>
            <div className="divider" />
            <div className="bottom-container">{calculateBuildingData()}</div>
        </div>
    );
};

const mapState = ({ building }) => {
    return {
        buildingConstructorData: get(building, 'buildingConstructorData'),
        floors: get(building, 'buildingConstructorData.floors'),
        basementFloors: get(building, 'buildingConstructorData.basementFloors'),
        buildingFormData: get(building, 'buildingFormData'),
        building: get(building, 'building'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueBuilding('buildingConstructorData', null));
            dispatch(setValueBuilding('showConstructorModal', false));
        },
        setValueBuildingCall(key, value) {
            dispatch(setValueBuilding(key, value));
        },
    };
};

const BuildingConstructor = connect(mapState, mapDispatch)(BuildingConstructorComponent);
export default BuildingConstructor;
