import { Button } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { Image } from 'react-bootstrap';
import activeFilter from '../../assets/images/new-active-filter.svg';
import filter from '../../assets/images/filter.svg';

const Filter = (props) => {
    return (
        <Button onClick={props.onClick} className={`filter ${props.filters ? 'active-filter' : ''} ${props.className ? props.className : ''}`}>
            {props.filters ? <Image src={activeFilter} /> : <Image src={filter} />}
            {i18next.t('filters')}
            <div className="dot"></div>
            {props.filters || 0}
        </Button>
    );
};

export default Filter;
