import React from 'react';
import { useTranslation } from 'react-i18next';
import ActualToggleComponent from './actual-toggle';
import BudgetsAndActualsChart from './budgets-and-actuals-chart';
import CommonCostTotal from './common-cost-total';
import CostsTable from './costs-table';

const BreakdownComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <p className="font-weight-bold">{t('commonCost.analysis.breakdown')}</p>
            <div className="dashboard budgets-and-actuals-chart-area">
                <div className="chart-area">
                    <div>
                        <ActualToggleComponent isAnalysisTab={true} />
                        <BudgetsAndActualsChart />
                    </div>
                </div>
                <div className="table-flex">
                    <CostsTable />
                </div>
            </div>
            <div className="table-flex total-wrapper">
                <CommonCostTotal />
            </div>
        </>
    );
};

export default BreakdownComponent;
