import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import commonCostIcon from '../../assets/images/cost-icon-info.svg';

const HowToAddYourOwnerCost = (props) => {
    const { t } = useTranslation();

    return (
        <div className="information-container">
            <Image src={commonCostIcon} />
            <h5>{t('ownerCost.information.hoToAdd')}</h5>
            <h6 className="text-secondary mb-3 font-weight-normal">
                {t('ownerCost.information.actualCost')}
            </h6>
            <h6 className="text-secondary font-weight-normal">{`1. ${t('ownerCost.information.addBudgetCost')}`}</h6>
            <h6 className="text-secondary font-weight-normal">{`2. ${t('ownerCost.information.addActualCost')}`}</h6>
            <h6 className="text-secondary mt-3 font-weight-normal">{t('ownerCost.information.youCanEdit')}</h6>
        </div>
    );
};

export default HowToAddYourOwnerCost;
