import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { setValueCommonCost } from '../../../../reducers/commonCostReducer';
import ActionButton from '../../../../components/action-button';
import PositiveNumericInput from '../../../../components/numeric-input';
import helper from '../../../../helpers/helper';
import { rentObjectRentalTypeOptions } from '../../../../common/contract-options';
import { connect } from 'react-redux';
import { editFractions } from '../../../../reducers/commonCostReducer';
import toaster from '../../../../common/toaster';
import NotificationWarning from '../../../../components/notification-warning';
import { useParams } from 'react-router';

const FractionCalculatorComponent = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [totalFractions, setTotalFractions] = useState();
    const dispatch = useDispatch();
    const commonCost = useSelector(({ commonCost }) => commonCost.commonCost);
    const fractionItemEditModel = useSelector(({ commonCost }) => commonCost.fractionItemEditModel);

    const { propertyId, id: commonCostId, commonCostItems: commonCostBudgetItems } = commonCost;

    const { portfolioId } = useParams();

    const [resettedItems, setResettedItems] = useState([]);
    const [fractionEditModel, setFractionEditModel] = useState([]);

    useEffect(() => {
        setFractionEditModel(fractionItemEditModel.fractionItems);
        return () => {
            setFractionEditModel([]);
        };
    }, [fractionItemEditModel]);

    const closeModalWindow = () => {
        dispatch(setValueCommonCost('fractionItemEditModel', null));
        dispatch(setValueCommonCost('showFractionModal', false));
    };

    const handleClose = (_) => {
        closeModalWindow();
    };

    const onConfirm = async (_) => {
        const validatedFields = await form.validateFields();
        props.editFractions(
            {
                commonCostId: commonCostId,
                rentObjectId: fractionItemEditModel.rentObjectId,
                fractionItems: Object.getOwnPropertyNames(validatedFields).map((key) => {
                    const isReset = resettedItems.find((item) => item.commonCostItemId == key)?.isReset;
                    return {
                        commonCostItemId: Number(key),
                        fractionPercent: validatedFields[key],
                        isReset: isReset != undefined ? isReset : false,
                    };
                }),
            },
            propertyId,
            portfolioId
        );
        toaster.success(t('commonCost.calculations.fractionAltered'));
        dispatch(setValueCommonCost('showFractionModal', false));
    };

    const handleResetChange = (id, value) => {
        setResettedItems((prev) => [
            ...prev.filter((item) => item.commonCostItemId != id),
            {
                commonCostItemId: id,
                isReset: value,
            },
        ]);
    };

    const resetFraction = (name, value) => {
        handleResetChange(name, true);
        form.setFieldsValue({
            [name]: value.toFixed(1),
        });
        handleTotal([], form.getFieldsValue());
    };

    const resetAllFractions = () => {
        fractionEditModel.forEach((item) => {
            form.setFieldsValue({
                [item.commonCostItemId]: item.initialFractionPercent.toFixed(1),
            });
        });
        setResettedItems(
            fractionEditModel.map((item) => ({
                commonCostItemId: item.commonCostItemId,
                isReset: true,
            }))
        );
        setTotalFractions(fractionItemEditModel.summary.total.fractionPercent);
    };

    const handleTotal = (_changedValues, allValues) => {
        setTotalFractions(Object.values(allValues).reduce((prev, curr) => prev + curr, 0) / fractionEditModel.length);
    };

    const validatePercent = (value) => {
        if (!value) {
            return Promise.resolve();
        }
        if (value > 100 || value < 0) {
            return Promise.reject(new Error(t('commonCost.calculations.validationMessage')));
        }
        return Promise.resolve();
    };

    return (
        <Modal
            wrapClassName="result-modal fraction-modal"
            footer={null}
            visible={true}
            maskClosable={false}
            onCancel={handleClose}
            closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <h4 className="mb-0">{t('commonCost.fraction.header')}</h4>
                </div>
                <div className="main">
                    <Form form={form} onValuesChange={handleTotal} autoComplete="off">
                        <div className="headers">
                            <Row>
                                <Col xs={5}>
                                    <p className="font-weight-bold mb-0">{t('commonCost.calculations.rentObject')}</p>
                                </Col>
                                <Col xs={4} md={6}>
                                    <p className="font-weight-bold mb-0">{t('commonCost.calculations.totalFractionEdit')}</p>
                                </Col>
                            </Row>
                        </div>
                        <div className={`fraction`}>
                            <Row>
                                <Col xs={6}>
                                    <p className="font-weight-bold mb-0">{helper.getEnumValue(rentObjectRentalTypeOptions, fractionItemEditModel?.rentObjectTypeId)}</p>
                                </Col>
                                <Col xs={4}>
                                    <p className="font-weight-bold mb-0">{`${
                                        !!totalFractions ? totalFractions.toFixed(1) : helper.numberWithSpaces(fractionItemEditModel.summary.total.fractionPercent?.toFixed(1))
                                    }%`}</p>
                                </Col>
                                <Col xs={2}>
                                    <ActionButton className="btn-link btn-transparent" text={t('common.buttons.reset')} onClick={resetAllFractions} />
                                </Col>
                            </Row>
                        </div>
                        <div className="headers" style={{ marginTop: '20px' }}>
                            <Row>
                                <Col xs={5}>
                                    <p className="font-weight-bold mb-0">{t('commonCost.calculations.contract')}</p>
                                </Col>
                                <Col xs={4} md={6}>
                                    <p className="font-weight-bold mb-0">{t('commonCost.calculations.fraction')}</p>
                                </Col>
                            </Row>
                        </div>
                        {fractionEditModel.map((item, index) => {
                            const fractionItem = commonCostBudgetItems?.find((x) => x.id === item.commonCostItemId);
                            return (
                                <div className={`fraction ${index % 2 === 1 ? '' : 'alternate'}`}>
                                    <Row>
                                        <Col xs={5}>
                                            <p className="font-weight-bold mb-0">{fractionItem?.name}</p>
                                        </Col>
                                        <Col xs={5}>
                                            <Form.Item
                                                className="mb-0"
                                                name={item.commonCostItemId}
                                                initialValue={item?.fractionPercent?.toFixed(1)}
                                                rules={[
                                                    {
                                                        validator(_, value) {
                                                            return validatePercent(value);
                                                        },
                                                    },
                                                ]}>
                                                <PositiveNumericInput
                                                    prefix={t('%')}
                                                    onChange={() => handleResetChange(item.commonCostItemId, false)}
                                                    onWheel={(e) => e.target.blur()}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={2}>
                                            <ActionButton
                                                className="btn-link btn-transparent"
                                                text={t('common.buttons.reset')}
                                                onClick={() => resetFraction(item?.commonCostItemId, item.initialFractionPercent)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })}
                    </Form>
                </div>
                <NotificationWarning
                    textElements={
                        <React.Fragment>
                            <p>
                                <span className="title">{t('commonCost.notification.fractionNotification.title')}</span>
                                <br />
                                <span>{t('commonCost.notification.fractionNotification.message')}</span>
                            </p>
                        </React.Fragment>
                    }
                />
                <div className="btns">
                    <ActionButton className="btn-secondary mr-3" text={t('common.buttons.cancel')} onClick={handleClose}></ActionButton>
                    <ActionButton className="btn-primary" text={t('common.buttons.confirm')} onClick={onConfirm}></ActionButton>
                </div>
            </div>
        </Modal>
    );
};

const mapDispatch = (dispatch) => {
    return {
        editFractions(data, propertyId, portfolioId) {
            return dispatch(editFractions(data, propertyId, portfolioId));
        },
    };
};

const FractionCalculator = connect(null, mapDispatch)(FractionCalculatorComponent);
export default FractionCalculator;
