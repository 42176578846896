import { Modal, notification } from 'antd';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import trashIcon from '../../../assets/images/trash-icon.svg';
import cancelIcon from '../../../assets/images/cancel-icon.svg';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../components/action-button';
import portfolioService from '../../../services/portfolioService';
import { modalActions } from './invite-step';
import { UndoOutlined } from '@ant-design/icons';
import resendIcon from '../../../assets/images/return-icon.svg';
import LoaderSpinner from '../../../components/spinner';

const InviteConfirmModal = ({ inviteModalVisible, setInviteModalVisible, setPortfolioCall, inviteId, portfolio, isEditPortfolio, action }) => {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);

    const deleteInvite = async () => {
        try {
            setLoading(true);
            if (isEditPortfolio) {
                await portfolioService.deleteInvite(inviteId, portfolio.id);
                setPortfolioCall({
                    ...portfolio,
                    invites: portfolio.invites.filter(item => item.id != inviteId)
                });
            } else {
                setPortfolioCall({
                    ...portfolio,
                    invites: portfolio.invites.filter(item => item.uIdInvite != inviteId)
                });
            }
            setLoading(false);
            setInviteModalVisible({ visible: false, inviteId: null, action: null })
            notification['success']({
                message: t('portfolio.invites.deleteSuccess'),
                placement: 'bottomLeft'
            });
        } catch (error) {
            setLoading(false);
            notification['error']({
                message: t('common.serverErrorMessage'),
                placement: 'bottomLeft'
            });
        }
    }

    const resendInvite = async () => {
        try {
            setLoading(true);
            await portfolioService.resendInvite(portfolio.id, portfolio.invites.find(item => item.id == inviteId));

            notification['success']({
                message: t('portfolio.invites.resendSuccess'),
                placement: 'bottomLeft'
            });

            setInviteModalVisible({ visible: false, inviteId: null, action: null })
            setLoading(false);
        } catch (err) {
            setLoading(false);
            notification['error']({
                message: t('common.serverErrorMessage'),
                placement: 'bottomLeft'
            });
        }
    }

    return (
        <Modal footer={null} visible={inviteModalVisible} maskClosable={false} onCancel={() => setInviteModalVisible({ visible: false, inviteId: null, action: null })} wrapClassName='delete-invite-modal' closeIcon={<Image src={cancelIcon} />}>
            {(action == modalActions.delete && !isLoading) && (
                <>
                    <Image src={trashIcon} style={{ marginBottom: '15px' }} />
                    <p>{t('portfolio.invites.deleteConfirm')}</p>
                    <p className='delete-notification-invite'>{t('portfolio.invites.deleteNotification')}</p>
                </>
            )}
            {(action == modalActions.resend && !isLoading) && (
                <>
                    <Image src={resendIcon} style={{ marginBottom: '15px' }} />
                    <p>{t('portfolio.invites.resendConfirm')}</p>
                </>
            )}
            {!isLoading && (
                <div className='action-btns'>
                    <ActionButton
                        className={`btn-primary ${action == modalActions.delete ? 'red-btn' : ''}`}
                        text={t('common.yes')}
                        onClick={action == modalActions.delete ? deleteInvite : resendInvite}
                    />
                    <ActionButton
                        className='btn-secondary'
                        text={t('common.no')}
                        onClick={() => setInviteModalVisible({ visible: false, inviteId: null, action: null })}
                    />
                </div>
            )}
            {isLoading && <div style={{ height: '200px' }}><LoaderSpinner /></div>}
        </Modal>
    )
}

export default InviteConfirmModal;