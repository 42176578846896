import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import ActionButton from '../../../components/action-button';
import { Link, useParams } from 'react-router-dom';
import TextEllipsis from '../../../components/ellipsis-text';
import PropertyRow from '../../../components/property-row';
import buildingService from '../../../services/buildingService';
import { pdfExtension } from '../../../common/constants';
import helper from '../../../helpers/helper';
import { downloadBuildingDocument, openPdfBuildingDocument } from '../../../reducers/buildingReducer';

const BuildingReviewComponent = (props) => {
    const { t } = useTranslation();

    const { propertyId, portfolioId } = useParams();

    const goToStep = (stepKey, objectId) => {
        props.goToStep(stepKey, objectId);
    };

    const handleDownload = (e, file) => {
        e.preventDefault();
        if (helper.getFileExtension(file.name) == pdfExtension) {
            props.openPdfDocumentCall(file, portfolioId, propertyId);
        } else {
            props.downloadFileCall(file, file.name, portfolioId, propertyId);
        }
    };

    const renderBuilding = (building) => {
        const buildingCommonArea = building.perFloor
            ? buildingService.getBuildingCommonArea(building?.floorsData?.floors, building?.floorsData?.basementFloors)
            : building?.commonArea;
        const buildingGrossArea = building.perFloor
            ? buildingService.getBuildingGrossArea(building?.floorsData?.floors, building?.floorsData?.basementFloors)
            : building?.grossArea;
        const buildingNonLettableArea =
            (building.perFloor ? buildingService.getBuildingNonLettableArea(building?.floorsData?.floors, building?.floorsData?.basementFloors) : building?.nonLettable) || 0;
        const buildingLettableArea = buildingGrossArea - buildingNonLettableArea;
        const buildingNettoArea = buildingLettableArea - buildingCommonArea;
        const buildingCommonPercents = buildingCommonArea && buildingNettoArea > 0 ? ((buildingCommonArea / buildingLettableArea) * 100).toFixed(2) : 0;
        const levels = building.perFloor ? building?.floorsData?.floors.length + building?.floorsData?.basementFloors.length : building?.levels;

        const plots = props.property.plots.filter((item) => building.assignedPlots?.includes(item.id));
        console.log(building);
        return (
            <div className="review-part details-info" key={building.uId}>
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                    <TextEllipsis>
                        <h5 className="ellipsis">{building.name || building.address}</h5>
                    </TextEllipsis>
                    <ActionButton onClick={() => goToStep('details', building.uId)} className="btn-link width-fit-content ml-3" text={t('common.buttons.edit')} />
                </div>
                <PropertyRow title={t('building.review.buildingName')} value={building.name} />
                <PropertyRow title={t('building.review.address')} value={building.address} />
                <PropertyRow title={t('building.review.buildYear')} value={building.buildYear} />
                <PropertyRow title={t('building.review.grossArea')} value={buildingGrossArea?.toFixed(2) || '0'} />
                <PropertyRow title={t('building.review.nonLettable')} value={buildingNonLettableArea?.toFixed(2) || '0'} />
                <PropertyRow title={t('building.review.lettable')} value={buildingLettableArea?.toFixed(2) || '0'} />
                <PropertyRow title={t('building.review.netto')} value={buildingNettoArea?.toFixed(2) || '0'} />
                <PropertyRow title={t('building.review.commonPercents')} value={(buildingCommonPercents || '0') + '%'} />
                <PropertyRow title={t('building.review.commonArea')} value={buildingCommonArea?.toFixed(2) || '0'} />
                <PropertyRow title={t('building.review.levels')} value={levels || '0'} />
                {plots.length > 0 && <PropertyRow title={t('building.detailsTab.relatedPlot')} value={plots.map((plot) => plot.mainAddress).join(', ')} />}
                {!!building.plans && building.plans.length > 0 && (
                    <PropertyRow
                        title={t('building.review.buildingPlan')}
                        value={building.plans.map((plan) => (
                            <Link className="mr-1" onClick={(e) => handleDownload(e, plan)}>
                                {plan.name}
                            </Link>
                        ))}
                    />
                )}
            </div>
        );
    };

    return (
        <div className="page-container review">
            {props.hasBuildings && props.buildings.map((building) => renderBuilding(building))}
            {props.building && props.building.id && renderBuilding(props.building)}
        </div>
    );
};

const mapState = ({ building, property }) => {
    return {
        building: get(building, 'building'),
        buildings: get(building, 'buildings'),
        hasBuildings: get(building, 'buildings.length') > 0,
        property: get(property, 'property'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        downloadFileCall(file, contractId, name, portfolioId, propertyId) {
            return dispatch(downloadBuildingDocument(file, contractId, name, portfolioId, propertyId));
        },
        openPdfDocumentCall(document, portfolioId, propertyId) {
            return dispatch(openPdfBuildingDocument(document, portfolioId, propertyId));
        },
    };
};

const BuildingReview = connect(mapState, mapDispatch)(BuildingReviewComponent);
export default BuildingReview;
