import { Checkbox, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import get from 'lodash-es/get';
import moment from 'moment';
import { default as React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { requiredCommonInput } from '../../../../common/validators';
import CommonInput from '../../../../components/common-input';
import ContentBlock, { actionButtonTypes } from '../../../../components/content-block';
import CustomContractDatePicker from '../../../../components/custom-contract-date-picker';
import objectHelper from '../../../../helpers/trim-object';
import { addContractOption, updateContractOption } from '../../../../reducers/contractReducer';
import { useParams } from 'react-router';

const ContractOptionEditCardComponent = (props) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(true);

    const { portfolioId, propertyId} = useParams();

    const [hasReminder, setHasReminder] = useState((props.option && props.option.reminderDate) || false);
    const [remindByEmail, setRemindByEmail] = useState(props.option ? props.option.remindByEmail : false);

    const contractOptionRef = React.useRef();

    const done = () => {
        return contractOptionRef.current.validateFields().then(() => {
            setLoading(true);
            const values = contractOptionRef.current.getFieldsValue();
            const contractOptionObject = objectHelper.trimObjectProperties(values);
            if (!hasReminder) {
                contractOptionObject.reminderDate = null;
            } else {
                contractOptionObject.reminderDate = contractOptionObject.reminderDate.startOf('day').utc(true);
            }
            contractOptionObject.date = contractOptionObject.date.startOf('day').utc(true);
            contractOptionObject.remindByEmail = remindByEmail;
            if (props.option) {
                contractOptionObject.id = props.option.id;
                props.updateContractOptionCall(contractOptionObject, props.contract.id, propertyId, portfolioId).then(() => {
                    setLoading(false);
                });
            } else {
                props.addContractOptionCall(contractOptionObject, props.contract.id, propertyId, portfolioId).then(() => {
                    setLoading(false);
                });
            }
        });
    };

    const onChangeHasReminder = () => {
        contractOptionRef.current.setFieldsValue({ reminderDate: null });
        if (!hasReminder) {
            contractOptionRef.current.validateFields(['date']).then(() => {
                setHasReminder(true);
            });
        } else {
            setHasReminder(false);
        }
    };
    return (
        <Form name="basic" autoComplete="off" ref={contractOptionRef}>
            <ContentBlock
                collapsed={collapsed}
                onCollapse={() => setCollapsed(!collapsed)}
                form={contractOptionRef}
                loading={loading}
                actions={[
                    {
                        type: actionButtonTypes.secondary,
                        label: t('common.buttons.cancel'),
                        onClick: () => {
                            contractOptionRef.current.resetFields();
                            setCollapsed(true);
                        },
                        disabled: contractOptionRef.current && contractOptionRef.current.getFieldsError().filter(({ errors }) => errors.length).length,
                    },
                    {
                        type: actionButtonTypes.primary,
                        label: t('common.buttons.done'),
                        onClick: () => {
                            done().then(() => {
                                setCollapsed(true);
                            });
                        },
                        disabled: contractOptionRef.current && contractOptionRef.current.getFieldsError().filter(({ errors }) => errors.length).length,
                    },
                ]}
                name={props.option.name}>
                <Form.Item
                    name="name"
                    initialValue={props.option ? props.option.name : null}
                    rules={[requiredCommonInput(t('contract.leaseDurationOptionAndContractOptionModal.contractOption.optionName.title') + t('common.isRequired'))]}>
                    <CommonInput
                        title={t('contract.leaseDurationOptionAndContractOptionModal.contractOption.optionName.title')}
                        placeholder={t('contract.leaseDurationOptionAndContractOptionModal.contractOption.optionName.placeholder')}
                    />
                </Form.Item>
                <p className="font-weight-bold">{t('contract.leaseDurationOptionAndContractOptionModal.date')}</p>
                <Form.Item
                    name="date"
                    initialValue={props.option && props.option.date ? moment(props.option.date) : null}
                    rules={[
                        {
                            required: true,
                            message: t('contract.leaseDurationOptionAndContractOptionModal.date') + t('common.isRequired'),
                        },
                    ]}>
                    <CustomContractDatePicker feedback showSearch />
                </Form.Item>
                <p className="font-weight-bold">{t('contract.leaseDurationOptionAndContractOptionModal.contractOption.description.title')}</p>
                <Form.Item
                    name="description"
                    initialValue={props.option ? props.option.description : null}
                    rules={[requiredCommonInput(t('contract.leaseDurationOptionAndContractOptionModal.contractOption.description.title') + t('common.isRequired'))]}>
                    <TextArea placeholder={t('contract.leaseDurationOptionAndContractOptionModal.contractOption.description.placeholder')} rows={5} style={{ minHeight: '48px' }} />
                </Form.Item>
                <Checkbox checked={hasReminder} onChange={onChangeHasReminder}>
                    {t('contract.leaseDurationOptionAndContractOptionModal.contractOption.setReminder')}
                </Checkbox>
                {hasReminder && (
                    <React.Fragment>
                        <p className="mt-3">{t('contract.leaseDurationOptionAndContractOptionModal.contractOption.reminderDate')}</p>
                        <Form.Item
                            name="reminderDate"
                            initialValue={props.option && props.option.reminderDate ? moment(props.option.reminderDate) : null}
                            className="m-0"
                            rules={[
                                {
                                    required: hasReminder,
                                    message: t('contract.leaseDurationOptionAndContractOptionModal.contractOption.reminderDate') + t('common.isRequired'),
                                },
                            ]}>
                            <CustomContractDatePicker feedback showSearch />
                        </Form.Item>
                        <Checkbox
                            className="mt-3"
                            checked={remindByEmail}
                            onChange={() => {
                                setRemindByEmail(!remindByEmail);
                            }}>
                            {t('contract.leaseDurationOptionAndContractOptionModal.remindMeByEmail')}
                        </Checkbox>
                    </React.Fragment>
                )}
            </ContentBlock>
        </Form>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        contractOptionLoading: get(contract, 'contractOptionLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        addContractOptionCall(data, contractId, propertyId, portfolioId) {
            return dispatch(addContractOption(data, contractId, propertyId, portfolioId));
        },
        updateContractOptionCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateContractOption(data, contractId, propertyId, portfolioId));
        },
    };
};

const ContractOptionEditCard = connect(mapState, mapDispatch)(ContractOptionEditCardComponent);
export default ContractOptionEditCard;
