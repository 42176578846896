import { Form } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import uuidV4 from 'uuid/v4';
import costIcon from '../../../assets/images/new-design-cost-icon.svg';
import clear from '../../../common/clear';
import { ownerCostItemTypeOptions } from '../../../common/owner-cost-options';
import toaster from '../../../common/toaster';
import NotFoundPage from '../../../components/error-page';
import CostsObjectLayout from '../../../components/layouts/costs-object-layout';
import LeaveModal from '../../../components/leave-modal';
import objectHelper from '../../../helpers/trim-object';
import { createOwnerCost, setValueOwnerCost } from '../../../reducers/ownerCostReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';
import commonCostService from '../../../services/commonCostService';
import ownerCostService from '../../../services/ownerCostService';
import routingService from '../../../services/routingService';
import HowToAddYourOwnerCost from '../info-container';
import OwnerCostBudgetItems from './budget-items-step';
import OwnerCostDetails from './details-step';
import OwnerCostBudgetsReview from './review-step';

const AddOwnerCostBudgetComponent = (props) => {
    const { t } = useTranslation();

    const stepNames = {
        details: 'details',
        budgets: 'budgets',
        review: 'review',
    };

    const [currentStepKey, setCurrentStepKey] = useState(stepNames.details);
    const [timelineSteps, setTimelineSteps] = useState([]);
    const timelineStepsRef = useRef([]);
    const detailsRef = useRef();
    const budgetRef = useRef();
    const { portfolioId, propertyId } = useParams();

    useEffect(() => {
        props.getPropertyCall(portfolioId, propertyId);
        createDefaultCostItems();
    }, []);

    const createDefaultCostItems = (_) => {
        const defaultCostItems = ownerCostItemTypeOptions.map((x) => ({
            name: x.name(),
            costType: x.id,
            isBudget: true,
            uId: uuidV4(),
        }));
        props.setValueOwnerCostCall('ownerCost.ownerCostBudgets', defaultCostItems);
    };

    //#region navigation

    useEffect(() => {
        mapTimelineSteps();
    }, [props.ownerCost]);

    const mapTimelineSteps = (_) => {
        const timelineSteps = steps.map((x) => ({
            key: x.key,
            stepTitle: x.stepTitle,
            isCompleted: isStepCompleted(x.key),
            canSkip: x.canSkip,
        }));

        setTimelineSteps(timelineSteps);
        timelineStepsRef.current = timelineSteps;
    };

    const isStepCompleted = (stepKey) => {
        if (!props.ownerCost) {
            return false;
        }

        switch (stepKey) {
            case stepNames.details:
                return props.ownerCost && props.ownerCost.name && props.ownerCost.startDate && props.ownerCost.endDate;
            case stepNames.budgets:
                return props.ownerCostBudgets && props.ownerCostBudgets.length && props.ownerCostBudgets.some((x) => x.value);
        }

        return false;
    };

    const onNext = () => {
        const currentStepIndex = timelineSteps.findIndex((x) => x.key === currentStepKey);
        const nextStep = timelineSteps[currentStepIndex + 1];

        if (!nextStep) {
            return;
        }

        onStepChanging().then((updatedOwnerCost) => updatedOwnerCost && setCurrentStepKey(nextStep.key));
    };

    const onPrevious = (previousStep) => {
        if (previousStep) {
            setCurrentStepKey(previousStep.key);
        } else {
            const currentStepIndex = steps.findIndex((x) => x.key === currentStepKey);
            const previousStep = steps[currentStepIndex - 1];
            previousStep && setCurrentStepKey(previousStep.key);
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious(newStep);
        } else {
            onStepChanging().then((updatedOwnerCost) => updatedOwnerCost && onChangeStep(newStep.key));
        }
    };

    const onChangeStep = (newStepKey) => {
        const timelineStepsByRef = timelineStepsRef.current;
        const newIndex = timelineStepsByRef.findIndex((x) => x.key === newStepKey);
        const previousSteps = timelineStepsByRef.slice(0, newIndex);
        const isPreviousStepsAllCompleted = previousSteps.every((x) => x.isCompleted || x.canSkip);

        if (isPreviousStepsAllCompleted) {
            setCurrentStepKey(newStepKey);
        } else {
            const firstInCompletedStep = timelineStepsByRef.find((x) => !x.isCompleted || x.canSkip);
            firstInCompletedStep && setCurrentStepKey(firstInCompletedStep.key);
        }
    };

    //#endregion

    const validateStep = (stepRef) => {
        return stepRef.current.validateFields();
    };

    const getDataFromDetailsForm = (_) => {
        const values = detailsRef.current.getFieldsValue();
        return objectHelper.trimObjectProperties(values);
    };

    const onStepChanging = () => {
        const ownerCost = { ...props.ownerCost };
        switch (currentStepKey) {
            case stepNames.details: {
                return validateStep(detailsRef).then((_) => {
                    const formData = getDataFromDetailsForm();
                    ownerCost.startDate = moment(formData.costPeriod[0]).startOf('day').utc(true);
                    ownerCost.endDate = moment(formData.costPeriod[1]).startOf('day').utc(true);
                    ownerCost.name = formData.name;
                    ownerCost.buildingId = formData.buildingId;
                    ownerCost.plotId = formData.plotId;
                    ownerCost.grossArea = formData.grossArea;
                    ownerCost.nonLettable = props.nonLettable;
                    ownerCost.lettable = formData.grossArea - props.nonLettable;
                    props.setValueOwnerCostCall('ownerCost', ownerCost);

                    return Promise.resolve(ownerCost);
                });
            }
            case stepNames.budgets: {
                const formData = budgetRef.current.getFieldsValue();
                const predefinedItems = props.ownerCostBudgets.filter((x) => x.costType);
                predefinedItems.forEach((x) => (x.value = formData['budgets' + x.costType]));
                const additionalItems = props.ownerCostBudgets.filter((x) => !x.costType);

                const items = [...predefinedItems, ...additionalItems];

                if (commonCostService.listOfCostsIsEmpty(items)) {
                    toaster.error(t('commonCost.budgets.atLeastOneBudgetCost'), null);
                    return Promise.reject();
                }

                const ownerCost = { ...props.ownerCost };

                props.setValueOwnerCostCall('ownerCost', ownerCost);
                props.setValueOwnerCostCall('ownerCost.ownerCostBudgets', items);

                return Promise.resolve(ownerCost);
            }
            default:
                return Promise.resolve(ownerCost);
        }
    };

    const onSaveAndExit = (_) => {
        const ownerCost = props.ownerCost;
        ownerCost.portfolioId = +portfolioId;
        ownerCost.propertyId = +propertyId;
        ownerCost.ownerCostItems = ownerCostService.filterOwnerCostItemsByValue(ownerCost.ownerCostBudgets);
        props.createOwnerCostCall(ownerCost).then((ownerCostId) => ownerCostId && created(ownerCostId));
    };

    const created = (ownerCostId) => {
        goOutOfPage(ownerCostId);
    };

    const goOutOfPage = (ownerCostId) => {
        const selectedYear = props.ownerCost.startDate._isAMomentObject ? props.ownerCost.startDate.format() : props.ownerCost.startDate;

        routingService.navigateToOwnerCosts(portfolioId, propertyId, { ownerCostId: ownerCostId, selectedYear: selectedYear });
    };

    const openCloseDraftModal = (_) => {
        props.setValueOwnerCostCall('showDraftModal', !props.showDraftModal);
    };

    const steps = [
        {
            key: stepNames.details,
            header: t('ownerCost.actualsStep.header'),
            stepTitle: t('commonCost.detailsStep.stepTitle'),
            logo: costIcon,
            content: (
                <Form name="details" autoComplete="off" isEdit={props.editableOwnerCostMode} editableOwnerCost={props.editableOwnerCost} ref={detailsRef}>
                    <OwnerCostDetails detailsRef={detailsRef} />
                </Form>
            ),
        },
        {
            key: stepNames.budgets,
            header: t('ownerCost.budgetStep.header'),
            stepTitle: t('commonCost.budgetsStep.stepTitle'),
            content: (
                <Form name="budgets" autoComplete="off" ref={budgetRef}>
                    <OwnerCostBudgetItems budgetRef={budgetRef} />
                </Form>
            ),
        },
        {
            key: stepNames.review,
            header: t('commonCost.reviewStep.header'),
            stepTitle: t('commonCost.reviewStep.header'),
            content: <OwnerCostBudgetsReview goToStep={() => setCurrentStepKey(stepNames.budgets)} />,
        },
    ];

    return (
        <React.Fragment>
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.ownerCostNotFound ? <NotFoundPage header={t('commonCost.notFound')} /> : null}
            {!props.portfolioNotFound && !props.propertyNotFound && !props.ownerCostNotFound ? (
                <CostsObjectLayout
                    className="common-cost-new-design"
                    isNewDesign={true}
                    steps={steps}
                    timelineSteps={timelineSteps}
                    currentStepKey={currentStepKey}
                    bottomRightSideBar={<HowToAddYourOwnerCost />}
                    exitUrl={routingService.ownerCostsUrl(portfolioId, propertyId)}
                    loading={props.ownerCostLoading}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onSwitchStep={onSwitchStep}
                    onSaveAndExit={onSaveAndExit}
                    onCancel={openCloseDraftModal}
                />
            ) : null}
            {props.showDraftModal && (
                <LeaveModal onCancel={openCloseDraftModal} onLeave={goOutOfPage} onSave={onSaveAndExit} loading={props.ownerCostLoading} className="cost-draft-modal" />
            )}
        </React.Fragment>
    );
};

const mapState = ({ property, portfolio, ownerCost }) => {
    return {
        nonLettable: get(ownerCost, 'assignedAssetAreaData.nonLettable'),
        ownerCost: get(ownerCost, 'ownerCost'),
        ownerCostBudgets: get(ownerCost, 'ownerCost.ownerCostBudgets'),
        showDraftModal: get(ownerCost, 'showDraftModal'),
        ownerCostLoading: get(ownerCost, 'ownerCostLoading'),
        ownerCostNotFound: get(ownerCost, 'ownerCostNotFound'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
        editableOwnerCostMode: get(ownerCost, 'editableOwnerCostMode'),
        editableOwnerCost: get(ownerCost, 'editableOwnerCost'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        setValueOwnerCostCall(key, value) {
            dispatch(setValueOwnerCost(key, value));
        },
        createOwnerCostCall(values) {
            return dispatch(createOwnerCost(values));
        },
    };
};

const AddOwnerCostBudget = clear(connect(mapState, mapDispatch)(AddOwnerCostBudgetComponent));
export default AddOwnerCostBudget;
