import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import { Col } from 'react-bootstrap';
import ActionButton from '../../../components/action-button';
import moment from 'moment';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import PropertyRow from '../../../components/property-row';
import helper from '../../../helpers/helper';
import routingService from '../../../services/routingService';
import { Row } from 'react-bootstrap';
import { editPermisionTypes } from '../../../common/constants';
import { propertyStatuses } from '../../../common/property-options';

const PropertyDetailsComponent = (props) => {
    const { t } = useTranslation();

    const goToPropertyEdit = () => {
        if (!props.hasEditPermission) return;
        routingService.navigateToEditProperty(props.property.portfolioId, props.property.id);
    };

    const propertyStatusDetails = [
        { id: propertyStatuses.active, name: () => t('property.name.active') },
        { id: propertyStatuses.consideredPurchased, name: () => t('property.detailsTab.considered') },
        { id: propertyStatuses.archive, name: () => t('common.archived').toLowerCase() },
    ];

    return (
        <Row>
            <Col md={12} lg={10}>
                <div className="details-card details-info">
                    <div className="main">
                        <h5>{t('property.detailsTab.title')}</h5>
                        <h6 className="mt-4">{t('property.detailsTab.propertyName')}</h6>
                        <PropertyRow title={t('property.detailsTab.propertyName')} value={props.property.propertyName} />
                        <PropertyRow
                            title={t('property.name.propertyStatus')}
                            value={props.property.propertyStatusId ? helper.getEnumValue(propertyStatusDetails, props.property.propertyStatusId) : 'N/A'}
                        />

                        {props.property.owner && (
                            <>
                                <h6 className="mt-4">{t('property.detailsTab.propertyDetails')}</h6>
                                {props.property.owner.orgName ? (
                                    <React.Fragment>
                                        <PropertyRow title={t('property.detailsTab.companyName')} value={props.property.owner.orgName} />
                                        <PropertyRow title={t('property.detailsTab.organisationNumber')} value={helper.numberWithSpaces(props.property.owner.orgNumber)} />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <PropertyRow title={t('property.detailsTab.ownerFirstName')} value={props.property.owner.firstName} />
                                        <PropertyRow title={t('property.detailsTab.ownerLastName')} value={props.property.owner.lastName} />
                                    </React.Fragment>
                                )}
                            </>
                        )}
                        {props.property.owner && (
                            <>
                                <h6 className="mt-4">{t('property.detailsTab.propertyManager')}</h6>
                                <PropertyRow title={t('property.detailsTab.name')} value={props.property.owner.name} />
                                <PropertyRow title={t('property.detailsTab.address')} value={props.property.owner.address} />
                                <PropertyRow title={t('property.detailsTab.phoneNumber')} value={props.property.owner.phone} />
                                <PropertyRow title={t('property.detailsTab.email')} value={props.property.owner.email} />
                                <PropertyRow title={t('property.detailsTab.post')} value={props.property.owner.post} />
                                <PropertyRow title={t('property.detailsTab.country')} value={props.property.owner.country} />
                            </>
                        )}
                    </div>
                    <div className="btns">
                        <ActionButton className="btn-secondary" text={t('common.buttons.edit')} disabled={!props.hasEditPermission} onClick={goToPropertyEdit} />
                    </div>
                </div>
            </Col>
        </Row>
    );
};

const mapState = ({ property, navigation }) => {
    return {
        property: get(property, 'property'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
    };
};

const PropertyDetails = connect(mapState, null)(PropertyDetailsComponent);
export default PropertyDetails;
