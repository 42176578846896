import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { setValueProperty, defaultOwner } from '../../../reducers/propertyReducer';
import { getPortfolioById } from '../../../reducers/portfolioReducer';
import get from 'lodash-es/get';
import commonService from '../../../services/commonService';
import PropertyOwnerByCompany from './property-owner/owner-company';
import PropertyOwnerByPerson from './property-owner/owner-person';
import { useEffect } from 'react';
import { getCountries } from '../../../reducers/commonReducer';
import LoaderSpinner from '../../../components/spinner';

const PropertyOwnerStepComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId } = useParams();

    const propertyOwner = props.propertyOwner || defaultOwner;

    useEffect(() => {
        if (!props.hasCountries) {
            props.getCountriesCall();
        }
    }, []);

    const onChangeRadioButton = (value) => {
        let owner = { ...defaultOwner };
        owner.ownedBy = value;

        props.ownerRef.current.resetFields();

        if (value === commonService.ownerRadioGroupTypes.sameAsManager) {
            props.getPortfolioByIdCall(portfolioId).then((portfolio) => {
                if (portfolio && portfolio.manager) {
                    owner = { ...portfolio.manager, id: undefined };
                    props.setValuePropertyCall('property.owner', owner);
                    props.setValuePropertyCall('property.usePortfolioOwner', true);
                }
            });
        } else {
            props.setValuePropertyCall('property.usePortfolioOwner', false);
            props.setValuePropertyCall('property.owner', owner);
        }
    };

    return (
        <div>
            <div className="page-container pb-0">
                <Row>
                    <Col lg={12} xl={6}>
                        <h5>{t('property.owner.details.title')}</h5>
                        <Radio.Group
                            defaultValue={props.usePortfolioOwner ? commonService.ownerRadioGroupTypes.sameAsManager : propertyOwner.ownedBy}
                            className="radio-container vertical-radio-container"
                            onChange={(e) => onChangeRadioButton(e.target.value)}>
                            <Radio disabled={propertyOwner && propertyOwner.id} value={commonService.ownerRadioGroupTypes.company}>
                                {t('common.company')}
                            </Radio>
                            <Radio disabled={propertyOwner && propertyOwner.id} value={commonService.ownerRadioGroupTypes.person}>
                                {t('common.person')}
                            </Radio>
                            <Radio disabled={propertyOwner && propertyOwner.id} value={commonService.ownerRadioGroupTypes.sameAsManager}>
                                {t('property.owner.details.usePortfolioOwnerDetails')}
                            </Radio>
                        </Radio.Group>
                    </Col>
                </Row>
            </div>
            {props.portfolioLoading ? (
                <LoaderSpinner />
            ) : propertyOwner.ownedBy === commonService.ownerRadioGroupTypes.company ? (
                <PropertyOwnerByCompany ownerRef={props.ownerRef} />
            ) : (
                <PropertyOwnerByPerson ownerRef={props.ownerRef} />
            )}
        </div>
    );
};

const mapState = ({ property, portfolio, common }) => {
    return {
        usePortfolioOwner: get(property, 'property.usePortfolioOwner'),
        propertyOwner: get(property, 'property.owner'),
        portfolioManager: get(portfolio, 'portfolio.manager'),
        portfolioLoading: get(portfolio, 'portfolioLoading'),
        countries: get(common, 'countries'),
        hasCountries: get(common, 'countries.length') > 0,
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValuePropertyCall(key, value) {
            dispatch(setValueProperty(key, value));
        },
        getPortfolioByIdCall(portfolioId) {
            return dispatch(getPortfolioById(portfolioId));
        },
        getCountriesCall() {
            dispatch(getCountries());
        },
    };
};

const PropertyOwner = connect(mapState, mapDispatch)(PropertyOwnerStepComponent);
export default PropertyOwner;
