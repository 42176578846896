import React, { useEffect, useState } from 'react';
import { contractStatus, contractStatusOptions } from '../../../../common/contract-options';
import { Form, Select } from 'antd';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import required from '../../../../assets/images/required.svg';
import { QuestionCircleFilled } from '@ant-design/icons';
import TurnoverNotification from '../turnover/turnover-notification';
import { connect } from 'react-redux';
import get from 'lodash-es/get';

const ContractStatusSection = (props) => {
    const { t } = useTranslation();

    const [statusNotificationVisible, setStatusNotificationVisible] = useState(false);

    useEffect(() => {
        if (props.contract) {
            if (props.contract.status > 0 && props.statusRef.current.getFieldsValue()?.status == contractStatus.draft) {
                props.statusRef.current.setFieldsValue({ status: props.contract.status });
            }
        }
    }, [props.contract]);

    return (
        <div className="page-container pb-0">
            <h5 className="mb-4">{t('contract.status.header')}</h5>
            <Row>
                <Col lg={12} xl={6}>
                    <p className="font-weight-bold d-flex flex-row align-items-center">
                        <Image className="required-img" src={required} />
                        {t('contract.details.contractStatus.title')}
                        <QuestionCircleFilled onClick={() => setStatusNotificationVisible(true)} style={{ marginLeft: '5px' }} />
                    </p>
                    {statusNotificationVisible && (
                        <TurnoverNotification className="mb-2" text={t('contract.details.status.notification')} close={() => setStatusNotificationVisible(false)} />
                    )}
                    <Form.Item
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: t('contract.details.contractStatus.title') + t('common.isRequired'),
                            },
                        ]}>
                        <Select
                            placeholder={t('contract.details.contractStatus.placeholder')}
                            dropdownClassName="new-design-dropdown"
                            >
                            {contractStatusOptions.filter(item => item.id !== contractStatus.draft && item.id !== contractStatus.expired).map((object) => (
                                <Select.Option value={object.id} key={object.id} disabled={object.disabled}>
                                    {object.name()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract')
    };
};

export default  connect(mapState, null)(ContractStatusSection);
