import { Checkbox, Form, Select, Switch, Tag } from 'antd';
import get from 'lodash-es/get';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { enterBtnCode } from '../../../common/constants';
import { requiredCommonInput } from '../../../common/validators';
import CommonInput from '../../../components/common-input';
import PositiveNumericInput from '../../../components/numeric-input';
import Uploader from '../../../components/uploader';
import { defaultFloorsData, deleteFile, downloadFile, setValueBuilding, uploadFile } from '../../../reducers/buildingReducer';
import BuildingConstructor from '../building-constructor';

const { Option } = Select;

const DetailsStepComponent = (props) => {
    const { t } = useTranslation();
    const { propertyId, portfolioId } = useParams();
    const perFloor = props.perFloor;
    const [assignedPlots, setAssignedPlots] = useState(props.assignedPlots || []);

    useEffect(() => {
        props.detailsRef.current.setFieldsValue(props.building);
    }, []);

    useEffect(() => {
        if (props.plots && props.plots.length === 1) {
            onChangeAssignedPlot([props.plots[0].id]);
        }
    }, [props.plots]);

    useEffect(() => {
        if (props.resetAssignedPlots) {
            setAssignedPlots([]);
            props.setValueBuildingCall('resetAssignedPlots', false);
        }
    }, [props.resetAssignedPlots]);

    useEffect(() => {
        const formValues = props.detailsRef.current.getFieldValue();
        let values = { ...formValues, ...props.buildingFormData };
        props.detailsRef.current.setFieldsValue(values);
    }, [props.buildingFormData]);

    useEffect(() => {
        if (props.floorsData) {
            props.floorsData.grossArea && props.detailsRef.current.setFieldsValue({ grossArea: props.floorsData.grossArea.toFixed(2) });
            props.floorsData.commonArea && props.detailsRef.current.setFieldsValue({ commonArea: props.floorsData.commonArea.toFixed(2) });
            props.floorsData.levels && props.detailsRef.current.setFieldsValue({ levels: props.floorsData.levels });
        }
        props.setValueBuildingCall('buildingConstructorData', props.floorsData);
    }, [props.floorsData]);

    const onChangeAssignedPlot = (values) => {
        setAssignedPlots(values);
        props.setValueBuildingCall('building.assignedPlots', values);
        props.detailsRef.current.setFieldsValue({ assignedPlots: values });
        props.detailsRef.current.validateFields(['assignedPlots']);
        const assignedPlot = props.plots.find((x) => x.id === values[0]);
        if (values.length == 1 && assignedPlot) {
            props.detailsRef.current.setFieldsValue({ address: assignedPlot.address });
        } else {
            props.detailsRef.current.setFieldsValue({ address: null });
        }
    };

    const onChangeHasName = (e) => {
        props.setValueBuildingCall('building.buildingHasName', e.target.checked);
    };

    const handleDownload = (e, file) => {
        e.preventDefault();
        props.downloadFileCall(file, propertyId, portfolioId);
    };

    const openConstructorModal = () => {
        props.setValueBuildingCall('buildingConstructorData', props.floorsData);
        props.showConstructorModalCall();
    };

    const uploadFileFunc = (file, options) => {
        return props.uploadFileCall(file, options, propertyId, props.building.id, portfolioId);
    };

    const deleteFileFunc = (fileId) => {
        return props.deleteFileCall(fileId, propertyId, portfolioId);
    };

    const handleFormDataChange = (propName, e) => {
        props.setValueBuildingCall('buildingFormData', {
            ...props.buildingFormData,
            [propName]: e,
        });
    };

    const getPlotName = (plotId) => {
        const plot = props.plots?.find((x) => x.id === plotId);
        if (plot) {
            return plot.displayName;
        }
        return null;
    };

    const removePlot = (plotId) => {
        const plots = assignedPlots.filter((x) => x != plotId);

        setAssignedPlots(plots);
        props.setValueBuildingCall('building.assignedPlots', plots);
        props.detailsRef.current.setFieldsValue({ assignedPlots: plots });
        props.detailsRef.current.validateFields(['assignedPlots']);
        const assignedPlot = props.plots.find((x) => x.id === plotId);
        if (assignedPlot && assignedPlot.address) {
            props.detailsRef.current.setFieldsValue({ address: assignedPlot.address });
        } else {
            props.detailsRef.current.setFieldsValue({ address: null });
        }
    };

    const validateCommonArea = () => {
        if (props.detailsRef.current.getFieldValue('commonArea')) {
            props.detailsRef.current.validateFields(['commonArea']);
        }
    };

    return (
        <div className="page-container object">
            <Row>
                <Col lg={12} xl={6}>
                    {!props.building.id && <h5 className="mb-3">{t('building.details.title')}</h5>}
                    <Checkbox checked={props.buildingHasName} className="ml-1 mb-3" onChange={onChangeHasName}>
                        {t('building.details.checkboxTitle')}
                    </Checkbox>
                    {props.buildingHasName && (
                        <Form.Item name="name" rules={[requiredCommonInput(t('building.details.name.title') + t('common.isRequired'))]}>
                            <CommonInput autofocus={true} placeholder={t('building.details.name.placeholder')} title={t('building.details.name.title')} />
                        </Form.Item>
                    )}
                    <p className="font-weight-bold mb-2">{t('building.details.assignedPlots.choosePlotTitle')}</p>
                    <Form.Item
                        name="assignedPlots"
                        initialValue={assignedPlots}
                        rules={[
                            {
                                required: true,
                                message: t('building.details.assignedPlots.choosePlotTitle') + t('common.isRequired'),
                            },
                        ]}>
                        <div className="multiple-cards-container">
                            <div className="select-container multiple-cards">
                                <Select
                                    autoFocus={true}
                                    tagRender={() => {}}
                                    virtual={false}
                                    mode="multiple"
                                    dropdownClassName="new-design-dropdown"
                                    showSearch
                                    listHeight={120}
                                    className="w-100"
                                    onChange={onChangeAssignedPlot}
                                    value={assignedPlots}
                                    placeholder={t('building.details.assignedPlots.placeholder')}
                                    onKeyDown={(e) => (e.keyCode === enterBtnCode ? e.preventDefault() : '')}>
                                    {props.hasPlots &&
                                        props.plots.map((plot) => (
                                            <Option value={plot.id} key={plot.id} info={{ mainAddress: plot.mainAddress }}>
                                                {plot.displayName}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                            <div className="tags-wrapper" style={{ marginTop: assignedPlots.length > 0 ? '0px' : '0px' }}>
                                {assignedPlots &&
                                    assignedPlots.map((plotId) => (
                                        <Tag className="selected-item" closable key={plotId} onClose={(e) => removePlot(plotId)}>
                                            {console.log(getPlotName(plotId))}
                                            {getPlotName(plotId)}
                                        </Tag>
                                    ))}
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item name="address" rules={[requiredCommonInput(t('building.details.address.title') + t('common.isRequired'))]}>
                        <CommonInput placeholder={t('building.details.address.placeholder')} title={t('building.details.address.title')} />
                    </Form.Item>
                    <p className="input-label font-weight-bold">{t('building.details.year.title')}</p>
                    <Form.Item name="buildYear">
                        <PositiveNumericInput placeholder={t('building.details.year.placeholder')} title={t('building.details.year.title')} disableFormatter />
                    </Form.Item>
                    <p className="mb-2 font-weight-bold">{t('building.buildingConstructor.title')}</p>
                </Col>
            </Row>
            <p className="text-secondary">{t('building.buildingConstructor.constructorDescription')}</p>
            <div className="constructor-wrapper">
                <div className="header">
                    <h4 className="mb-0">{t('building.buildingConstructor.header')}</h4>
                    <div className="d-flex">
                        <p className="mr-1">Pr Floor</p>
                        <Switch
                            checked={perFloor}
                            onChange={() => {
                                props.togglePerFloor(!perFloor);
                            }}
                        />
                    </div>
                </div>
                <div className="divider" />
                <Row className={`mt-2 details-constructor ${!perFloor ? 'details-constructor-not-collapsed' : ''}`}>
                    <Col lg={12} xl={6}>
                        <p className="font-weight-bold mb-2">{t('building.details.gross.title')}</p>
                        <Form.Item
                            name="grossArea"
                            rules={[
                                {
                                    required: !perFloor,
                                    message: t('building.details.gross.title') + t('common.isRequired'),
                                },
                            ]}>
                            <PositiveNumericInput
                                placeholder={t('building.details.gross.placeholder')}
                                onChange={(e) => {
                                    props.detailsRef.current.validateFields(['nonLettable']);
                                    validateCommonArea();
                                    handleFormDataChange('grossArea', e);
                                }}
                            />
                        </Form.Item>
                        <p className="font-weight-bold mb-2">{t('building.details.nonLettable.title')}</p>
                        <Form.Item
                            name="nonLettable"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        let gross = getFieldValue('grossArea') || 0;

                                        if (!value || parseFloat(value) <= parseFloat(gross)) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error(t('building.details.nonLettable.errorMessage')));
                                    },
                                }),
                            ]}>
                            <PositiveNumericInput
                                placeholder={t('building.details.nonLettable.placeholder')}
                                onChange={(e) => {
                                    validateCommonArea();
                                    handleFormDataChange('nonLettable', e);
                                }}
                            />
                        </Form.Item>
                        <p className="font-weight-bold mb-2">{t('building.details.common.title')}</p>
                        <Form.Item
                            name="commonArea"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        let gross = getFieldValue('grossArea') || 0;
                                        let nonLettable = getFieldValue('nonLettable') || 0;
                                        if (!value || parseFloat(gross) - parseFloat(nonLettable) >= parseFloat(value || 0)) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error(t('building.details.common.errorMessage')));
                                    },
                                }),
                                {
                                    required: !perFloor,
                                    message: t('building.details.common.title') + t('common.isRequired'),
                                },
                            ]}>
                            <PositiveNumericInput placeholder={t('building.details.common.placeholder')} onChange={(e) => handleFormDataChange('commonArea', e)} />
                        </Form.Item>
                        <p className="font-weight-bold mb-2">{t('building.details.levels.title')}</p>
                        <Form.Item
                            name="levels"
                            rules={[
                                {
                                    required: !perFloor,
                                    message: t('building.details.levels.title') + t('common.isRequired'),
                                },
                            ]}>
                            <PositiveNumericInput placeholder={t('building.details.levels.placeholder')} onChange={(e) => handleFormDataChange('levels', e)} />
                        </Form.Item>
                    </Col>
                </Row>
                <BuildingConstructor buildingForm={props.buildingForm} perFloor={perFloor} />
            </div>
            <Uploader title={t('building.details.plan')} files={[...props.plans]} downloadFile={handleDownload} uploadFile={uploadFileFunc} deleteFile={deleteFileFunc} />
        </div>
    );
};

const mapState = ({ property, building }) => {
    return {
        perFloor: get(building, 'building.perFloor'),
        plans: get(building, 'building.plans'),
        building: get(building, 'building'),
        plots: get(property, 'property.plots'),
        hasPlots: get(property, 'property.plots.length') > 0,
        assignedPlots: get(building, 'building.assignedPlots'),
        hasAssignedPlots: get(building, 'building.assignedPlots.length') > 0,
        buildingHasName: get(building, 'building.buildingHasName'),
        floorsData: get(building, 'building.floorsData'),
        floors: get(building, 'building.floorsData.floors'),
        hasFloors: get(building, 'building.floorsData.floors.length') > 0,
        basementFloors: get(building, 'building.floorsData.basementFloors'),
        hasBasementFloors: get(building, 'building.floorsData.basementFloors.length') > 0,
        countOfFloors: get(building, 'building.floorsData.floors.length'),
        countOfBasementFloors: get(building, 'building.floorsData.basementFloors.length'),
        showConstructorModal: get(building, 'showConstructorModal'),
        buildingFormData: get(building, 'buildingFormData'),
        resetAssignedPlots: get(building, 'resetAssignedPlots'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueBuildingCall(key, value) {
            dispatch(setValueBuilding(key, value));
        },
        uploadFileCall(file, options, propertyId, buildingId, portfolioId) {
            return dispatch(uploadFile(file, options, propertyId, buildingId, portfolioId));
        },
        deleteFileCall(fileId, propertyId, portfolioId) {
            return dispatch(deleteFile(fileId, propertyId, portfolioId));
        },
        downloadFileCall(file, propertyId, portfolioId) {
            return dispatch(downloadFile(file, propertyId, portfolioId));
        },
        togglePerFloor(perFloor) {
            return dispatch(setValueBuilding('building.perFloor', perFloor));
        },
        showConstructorModalCall() {
            dispatch(setValueBuilding('showConstructorModal', true));
        },
        clearFloors() {
            dispatch(setValueBuilding('building.floorsData', defaultFloorsData));
        },
    };
};

const DetailsStep = connect(mapState, mapDispatch)(DetailsStepComponent);
export default DetailsStep;
