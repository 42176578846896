import i18next from 'i18next';
import { StatusCodes } from 'http-status-codes';
import { setValueProperty } from './propertyReducer';
import toaster from '../common/toaster';
import update from '../helpers/update';
import commonCostService from '../services/commonCostService';
import { filterOptions } from '../common/property-options';

const SET_VALUE_COMMON_COST = 'SET_VALUE_COMMON_COST';
const CLEAR_DELETE_COMMON_COST_MODEL = 'CLEAR_DELETE_COMMON_COST_MODEL';
const SET_DELETE_COMMON_COST_MODEL = 'SET_DELETE_COMMON_COST_MODEL';
const SUCCESS_GET_COMMON_COSTS = 'SUCCESS_GET_COMMON_COSTS';
const SUCCESS_GET_COMMON_COST_BY_ID = 'SUCCESS_GET_COMMON_COST_BY_ID';

const initialState = {
    commonCosts: [],
    commonCostsYears: [],

    commonCost: null,
    commonCostDetails: null,
    commonCostCalculations: null,
    assignedAssetAreaData: null,

    commonCostLoading: false,
    commonCostCalculationsLoading: true,
    deleteLoading: false,

    showConfirmDeleteCommonCostModal: false,
    showAddAdditionalCostModal: false,
    showFractionModal: false,

    showBudgetsAddResultModal: false,
    showActualsAddResultModal: false,
    showCommonCostUpdatedResultModal: false,

    deleteCommonCostModel: null,

    additionalCostEditModel: null,

    fractionItemEditModel: null,

    showDraftModal: false,
    showActuals: false,
    commonCostNotFound: false,

    filterOption: filterOptions.active,
};

const commonCostReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VALUE_COMMON_COST:
            return update.set(state, `${action.payload.key}`, action.payload.value);
        case SUCCESS_GET_COMMON_COSTS:
            return update.set(state, 'commonCosts', action.payload);
        case SUCCESS_GET_COMMON_COST_BY_ID:
            return update.set(state, 'commonCost', action.payload);
        case CLEAR_DELETE_COMMON_COST_MODEL:
            return update.set(state, 'deleteCommonCostErrorModel', null);
        case SET_DELETE_COMMON_COST_MODEL:
            return update.set(state, 'deleteCommonCostModel', action.payload);
        default:
            return state;
    }
};

export const setValueCommonCost = (key, value) => {
    return {
        type: SET_VALUE_COMMON_COST,
        payload: { key, value },
    };
};

export const successGetCommonCosts = (value) => {
    return {
        type: SUCCESS_GET_COMMON_COSTS,
        payload: value,
    };
};

export const successGetCommonCostById = (value) => {
    return {
        type: SUCCESS_GET_COMMON_COST_BY_ID,
        payload: value,
    };
};

export const clearDeleteCommonCostModel = () => {
    return {
        type: CLEAR_DELETE_COMMON_COST_MODEL,
    };
};

export const setDeleteCommonCostModel = (value) => {
    return {
        type: SET_DELETE_COMMON_COST_MODEL,
        payload: value,
    };
};

const handleCommonCostError = (error, dispatch) => {
    if (error && error.response && error.response.status && error.response.status === StatusCodes.NOT_FOUND) {
        dispatch(setValueCommonCost('commonCostNotFound', true));
    } else if (error && error.response && error.response.status && error.response.status === StatusCodes.FORBIDDEN) {
        dispatch(setValueProperty('propertyNotFound', true));
    } else if (error && error.response && error.response.title) {
        toaster.error(error.response.title, null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const getListOfCommonCosts = (portfolioId, propertyId, year) => {
    return (dispatch, getState) => {
        dispatch(setValueCommonCost('commonCostLoading', true));
        return commonCostService
            .getListOfCommonCosts(portfolioId, propertyId, year, getState().commonCost.filterOption)
            .then((response) => {
                dispatch(successGetCommonCosts(response.data));
                const years = [...new Set(response.data.map((x) => x.year))].sort();
                dispatch(setValueCommonCost('commonCostsYears', years));
                return true;
            })
            .catch((error) => handleCommonCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueCommonCost('commonCostLoading', false));
            });
    };
};

export const createCommonCost = (data) => {
    return (dispatch) => {
        dispatch(setValueCommonCost('commonCostLoading', true));
        return commonCostService
            .create(data)
            .then((response) => {
                return response.data.id;
            })
            .catch((error) => handleCommonCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueCommonCost('commonCostLoading', false));
            });
    };
};

export const updateCommonCost = (data) => {
    return (dispatch) => {
        dispatch(setValueCommonCost('commonCostLoading', true));
        return commonCostService
            .update(data)
            .then((_) => true)
            .catch((error) => handleCommonCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueCommonCost('commonCostLoading', false));
            });
    };
};

export const addCommonCostActuals = (data, propertyId, commonCostId, addActuals) => {
    return (dispatch) => {
        dispatch(setValueCommonCost('commonCostLoading', true));
        return commonCostService
            .addActuals(data, propertyId, commonCostId, addActuals)
            .then((_) => true)
            .catch((error) => handleCommonCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueCommonCost('commonCostLoading', false));
            });
    };
};

export const updateLettableArea = (data, propertyId, commonCostId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueCommonCost('commonCostLoading', true));
        return commonCostService
            .updateLettableArea(data, propertyId, commonCostId, portfolioId)
            .then((response) => {
                dispatch(setValueCommonCost('commonCost.lettable', response.data?.vacancyInfo?.lettable));
                dispatch(setValueCommonCost('commonCostCalculations', response.data));
                dispatch(setValueCommonCost('commonCostDetails.buildingDetails', response.data?.vacancyInfo?.buildingDetails));
            })
            .catch((error) => handleCommonCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueCommonCost('commonCostLoading', false));
            });
    };
};

export const editFractions = (data, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueCommonCost('commonCostLoading', true));
        return commonCostService
            .updateFractions(data, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueCommonCost('commonCostCalculations', response.data));
            })
            .catch((error) => handleCommonCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueCommonCost('commonCostLoading', false));
                setValueCommonCost('fractionItemEditModel', null);
                setValueCommonCost('showFractionModal', false);
            });
    };
};

export const deleteCommonCost = (propertyId, commonCostId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueCommonCost('deleteLoading', true));
        return commonCostService
            .delete(propertyId, commonCostId, portfolioId)
            .then((_) => true)
            .catch((error) => handleCommonCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueCommonCost('deleteLoading', false));
            });
    };
};

export const getCommonCostById = (portfolioId, propertyId, commonCostId) => {
    return (dispatch) => {
        dispatch(setValueCommonCost('commonCostLoading', true));
        return commonCostService
            .getCommonCostById(portfolioId, propertyId, commonCostId)
            .then((response) => {
                dispatch(setValueCommonCost('commonCost', commonCostService.mapCommonCostItemNames(response.data)));
                return response.data;
            })
            .catch((error) => handleCommonCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueCommonCost('commonCostLoading', false));
            });
    };
};

export const getCommonCostDetails = (portfolioId, propertyId, commonCostId) => {
    return (dispatch) => {
        dispatch(setValueCommonCost('commonCostLoading', true));
        return commonCostService
            .getCommonCostDetails(portfolioId, propertyId, commonCostId)
            .then((response) => {
                dispatch(setValueCommonCost('commonCost', commonCostService.mapCommonCostItemNames(response.data.commonCost)));
                dispatch(setValueCommonCost('commonCostDetails', response.data.details));
            })
            .catch((error) => handleCommonCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueCommonCost('commonCostLoading', false));
            });
    };
};

export const getCommonCostCalculations = (portfolioId, propertyId, commonCostId) => {
    return (dispatch) => {
        dispatch(setValueCommonCost('commonCostCalculationsLoading', true));
        return commonCostService
            .getCommonCostCalculations(portfolioId, propertyId, commonCostId)
            .then((response) => {
                dispatch(setValueCommonCost('commonCostCalculations', response.data));
            })
            .catch((error) => handleCommonCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueCommonCost('commonCostCalculationsLoading', false));
            });
    };
};

export const getAreaForAssignedAsset = (propertyId, plotId, buildingId, portfolioId) => {
    return (dispatch) => {
        commonCostService.getAreaForAssignedAsset(propertyId, plotId, buildingId, portfolioId).then((response) => dispatch(setValueCommonCost('assignedAssetAreaData', response.data)));
    };
};

export default commonCostReducer;
