import { createSelector } from 'reselect'
import commonCostService from '../../services/commonCostService';

export const commonCostSelector = ({ commonCost }) => commonCost.commonCost;
export const commonCostBudgetItemsSelector = ({ commonCost }) => commonCost.commonCost?.commonCostBudgets;
export const commonCostActualItemsSelector = ({ commonCost }) => commonCost.commonCost?.commonCostActuals;
export const commonCostDetailsSelector = ({ commonCost }) => commonCost.commonCostDetails;
export const showActualsSelector = ({ commonCost }) => commonCost.showActuals;

export const selectMergedBudgetAndActualItems = createSelector(
    [commonCostBudgetItemsSelector, commonCostActualItemsSelector],
    (budgetItems, actualItems) => commonCostService.mergeBudgetAndActualItems(budgetItems, actualItems)
)

export const selectFilteredBudgetCosts = createSelector(
    commonCostBudgetItemsSelector,
    (budgetItems) => commonCostService.filterCommonCostItemsByValue(budgetItems)
)

export const selectFilteredActualCosts = createSelector(
    commonCostActualItemsSelector,
    (actualItems) => commonCostService.filterCommonCostItemsByValue(actualItems)
)

export const selectHasAnyBudgetCosts = createSelector(
    commonCostBudgetItemsSelector,
    (budgetItems) => budgetItems?.length > 0
)

export const selectHasAnyActualCosts = createSelector(
    commonCostActualItemsSelector,
    (actualItems) => actualItems?.length > 0
)