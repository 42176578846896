import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Form } from 'antd';
import { getPlotById, setValuePlot, updatePlot } from '../../../reducers/plotReducer';
import get from 'lodash-es/get';
import clear from '../../../common/clear';
import objectHelper from '../../../helpers/trim-object';
import plotLogo from '../../../assets/images/new-design-plot-icon.svg';
import review from '../../../assets/images/review.svg';
import InformationComponent from '../../../components/information-container';
import ObjectLayout from '../../../components/layouts/object-layout';
import LeaveModal from '../../../components/leave-modal';
import plotService from '../../../services/plotService';
import routingService from '../../../services/routingService';
import DetailsStep from './details-step';
import PlotReview from './review-step';
import NotFoundPage from '../../../components/error-page';

const EditPlotComponent = (props) => {
    const stepNames = {
        details: 'details',
        review: 'review',
    };

    const { t } = useTranslation();
    const plotRef = React.useRef();
    const [currentStepKey, setCurrentStepKey] = useState(stepNames.details);
    const { plotId, propertyId, portfolioId } = useParams();
    const plotsUrl = routingService.plotsUrl(portfolioId, propertyId);

    useEffect(() => {
        if (portfolioId && propertyId && plotId) {
            props.getPlotByIdCall(portfolioId, plotId, propertyId);
        } else if (portfolioId && propertyId) {
            goOutOfPage();
        } else {
            routingService.navigateToPortfolios();
        }
    }, []);

    const goOutOfPage = () => {
        routingService.goTo(plotsUrl);
    };

    const onNext = () => {
        validatePlotRef()
            .then((_) => {
                props.setValuePlotCall('plot', getPlotFromForm());

                setCurrentStepKey(stepNames.review);
            })
            .catch((_) => {});
    };

    const onPrevious = () => {
        setCurrentStepKey(stepNames.details);
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious();
        } else {
            onNext();
        }
    };

    const goToEdit = (stepKey) => {
        setCurrentStepKey(stepKey);
    };

    const getPlotFromForm = () => {
        const values = plotRef.current.getFieldsValue();
        values.city = props.plot.city;
        values.municipality = props.plot.municipality;
        values.addresses = props.plot.addresses;
        values.gnrNumber = props.plot.gnrNumber;
        values.bnrNumber = props.plot.bnrNumber;
        values.address = props.plot.address;
        values.id = props.plot.id;
        values.plotHasName = props.plot.plotHasName;
        values.displayName = plotService.getPlotDisplayName(values);
        const plot = objectHelper.trimObjectProperties(values);
        return plot;
    };

    const validatePlotRef = () => {
        return plotRef.current.validateFields();
    };

    const updated = (plot) => {
        if (plot) {
            goOutOfPage();
            props.showPlotUpdatedResult();
        }
    };

    const onSaveAndExit = () => {
        if (propertyId) {
            if (currentStepKey === stepNames.review) {
                updatePlot(props.plot, propertyId, portfolioId);
            } else {
                validatePlotRef()
                    .then((_) => {
                        updatePlot(getPlotFromForm(), propertyId);
                    })
                    .catch((_) => {});
            }
        }
    };

    const updatePlot = (plot, propertyId, portfolioId) => {
        plot.propertyId = +propertyId;
        props.editPlotCall(plot, propertyId, portfolioId).then((plot) => updated(plot));
    };

    const onOpenCloseDraftModal = () => {
        props.setValuePlotCall('showDraftModal', !props.showDraftModal);
    };

    const steps = [
        {
            key: stepNames.details,
            header: t('plot.editPlot'),
            stepTitle: t('plot.editPlot'),
            logo: plotLogo,
            canShowAsCompleted: true,
            content: (
                <div className="page-container">
                    <DetailsStep plotRef={plotRef} />
                </div>
            ),
        },
        {
            key: stepNames.review,
            header: t('plot.review.header'),
            stepTitle: t('plot.review.stepTitle'),
            logo: review,
            content: <PlotReview goToStep={goToEdit} />,
        },
    ];

    return (
        <React.Fragment>
            {props.plotNotFound ? <NotFoundPage header={t('plot.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {!props.plotNotFound && !props.propertyNotFound ? (
                <Form name="basic" autoComplete="off" className="h-100" ref={plotRef}>
                    <ObjectLayout
                        bottomRightSideBar={
                            <React.Fragment>
                                {currentStepKey === stepNames.details && <InformationComponent title={t('plot.information.title')} text={t('plot.information.text')} />}
                            </React.Fragment>
                        }
                        steps={steps}
                        timelineSteps={steps}
                        isEdit={true}
                        loading={props.plotLoading}
                        currentStepKey={currentStepKey}
                        exitUrl={plotsUrl}
                        onNext={onNext}
                        onPrevious={onPrevious}
                        onSwitchStep={onSwitchStep}
                        onSaveAndExit={onSaveAndExit}
                        onCancel={onOpenCloseDraftModal}
                    />
                </Form>
            ) : null}
            {props.showDraftModal && <LeaveModal onCancel={onOpenCloseDraftModal} onLeave={goOutOfPage} onSave={onSaveAndExit} loading={props.plotLoading} />}
        </React.Fragment>
    );
};

const mapState = ({ plot, property }) => {
    return {
        plot: get(plot, 'plot'),
        plotLoading: get(plot, 'plotLoading'),
        plotNotFound: get(plot, 'plotNotFound'),
        showDraftModal: get(plot, 'showDraftModal'),
        propertyNotFound: get(property, 'propertyNotFound'),
        propertyName: get(property, 'property.propertyName'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        editPlotCall(plot, propertyId, portfolioId) {
            return dispatch(updatePlot(plot, propertyId, portfolioId));
        },
        getPlotByIdCall(portfolioId, plotId, propertyId) {
            dispatch(getPlotById(portfolioId, plotId, propertyId));
        },
        setValuePlotCall(key, value) {
            dispatch(setValuePlot(key, value));
        },
        showPlotUpdatedResult() {
            dispatch(setValuePlot('showItemUpdatedResultModal', true));
        },
    };
};

const EditPlot = clear(connect(mapState, mapDispatch)(EditPlotComponent));
export default EditPlot;
