import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import uuidV4 from 'uuid/v4';
import { Form } from 'antd';
import { getPropertyById } from '../../../reducers/propertyReducer';
import costIcon from '../../../assets/images/new-design-cost-icon.svg';
import routingService from '../../../services/routingService';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import NotFoundPage from '../../../components/error-page';
import LeaveModal from '../../../components/leave-modal';
import HowToAddYourCommonCost from '../info-container';
import CommonCostActualsReview from './review-step';
import CommonCostActual from './actual-items-step';
import toaster from '../../../common/toaster';
import commonCostService from '../../../services/commonCostService';
import { addOwnerCostActuals, getOwnerCostById, setValueOwnerCost } from '../../../reducers/ownerCostReducer';
import ownerCostService from '../../../services/ownerCostService';
import CostsObjectLayout from '../../../components/layouts/costs-object-layout';

const AddOwnerCostActualComponent = (props) => {
    const { t } = useTranslation();

    const stepNames = {
        actuals: 'actuals',
        review: 'review',
    };

    const [currentStepKey, setCurrentStepKey] = useState(stepNames.actuals);
    const [timelineSteps, setTimelineSteps] = useState([]);
    const actualRef = useRef();
    const { portfolioId, propertyId, ownerCostId } = useParams();

    useEffect(() => {
        props.getPropertyCall(portfolioId, propertyId);
        props.getOwnerCostByIdCall(portfolioId, propertyId, ownerCostId);
        mapTimelineSteps();
    }, []);

    useEffect(() => {
        !props.hasAnyActuals && createDefaultCostItems();
    }, [props.ownerCostBudgets]);

    const createDefaultCostItems = (_) => {
        const items = (props.ownerCostBudgets ?? []).map((x) => ({
            name: x.name,
            costType: x.costType,
            isBudget: false,
            formId: 'budgetId_' + x.id,
            uId: uuidV4(),
        }));

        props.setValueOwnerCostCall('ownerCost.ownerCostActuals', items);
    };

    //#region navigation

    useEffect(() => {
        mapTimelineSteps();
    }, [props.ownerCost]);

    const mapTimelineSteps = (_) => {
        const timelineSteps = steps.map((x) => ({
            key: x.key,
            stepTitle: x.stepTitle,
            isCompleted: isStepCompleted(x.key),
        }));

        setTimelineSteps(timelineSteps);
    };

    const isStepCompleted = (stepKey) => {
        if (!props.ownerCost) {
            return false;
        }

        switch (stepKey) {
            case stepNames.actuals:
                return props.hasAnyActuals;
        }

        return false;
    };

    const onPrevious = (previousStep) => {
        if (previousStep) {
            setCurrentStepKey(previousStep.key);
        } else {
            const currentStepIndex = steps.findIndex((x) => x.key === currentStepKey);
            const previousStep = steps[currentStepIndex - 1];
            previousStep && setCurrentStepKey(previousStep.key);
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious(newStep);
        } else {
            onNext();
        }
    };

    //#endregion

    const onNext = (_) => {
        onStepChanging().then((isSuccess) => isSuccess && setCurrentStepKey(stepNames.review));
    };

    const onStepChanging = () => {
        switch (currentStepKey) {
            case stepNames.actuals: {
                const formData = actualRef.current.getFieldsValue();
                const predefinedItems = props.ownerCostActuals.filter((x) => x.formId);
                const additionalItems = props.ownerCostActuals.filter((x) => !x.formId);
                predefinedItems.forEach((x) => (x.value = formData[x.formId]));

                const items = [...predefinedItems, ...additionalItems];
                const ownerCost = { ...props.ownerCost };

                props.setValueOwnerCostCall('ownerCost', ownerCost);
                props.setValueOwnerCostCall('ownerCost.ownerCostActuals', items);

                if (commonCostService.listOfCostsIsEmpty(predefinedItems.concat(additionalItems))) {
                    toaster.error(t('commonCost.actuals.atLeastOneActualCost'), null);
                    return Promise.reject();
                }

                return Promise.resolve(true);
            }
            default:
                return Promise.resolve(true);
        }
    };

    const onSaveAndExit = (_) => {
        const ownerCost = {
            ...props.ownerCost,
            ownerCostItems: [...props.ownerCost.ownerCostItems, ...props.ownerCost.ownerCostActuals],
        };
        ownerCost.ownerCostActuals = ownerCostService.filterOwnerCostItemsByValue(props.ownerCostActuals);

        props.addOwnerCostActualsCall(propertyId, ownerCost, portfolioId).then((isSuccess) => isSuccess && added());
    };

    const added = (_) => {
        goOutOfPage();
        props.showActualsAddResult();
    };

    const goOutOfPage = (_) => {
        routingService.navigateToOwnerCostDetails(portfolioId, propertyId, ownerCostId);
    };

    const openCloseDraftModal = (_) => {
        props.setValueOwnerCostCall('showDraftModal', !props.showDraftModal);
    };

    const steps = [
        {
            key: stepNames.actuals,
            header: t('ownerCost.actualsStep.header'),
            stepTitle: t('commonCost.actualsStep.stepTitle'),
            logo: costIcon,
            content: (
                <Form name="actuals" autoComplete="off" ref={actualRef}>
                    <CommonCostActual actualRef={actualRef} />
                </Form>
            ),
        },
        {
            key: stepNames.review,
            header: t('commonCost.reviewStep.header'),
            stepTitle: t('commonCost.reviewStep.header'),
            content: <CommonCostActualsReview goToStep={() => setCurrentStepKey(stepNames.actuals)} />,
        },
    ];

    return (
        <React.Fragment>
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.ownerCostNotFound ? <NotFoundPage header={t('ownerCost.notFound')} /> : null}
            {!props.portfolioNotFound && !props.propertyNotFound && !props.ownerCostNotFound ? (
                <CostsObjectLayout
                    className="common-cost-new-design"
                    isNewDesign={true}
                    steps={steps}
                    timelineSteps={timelineSteps}
                    currentStepKey={currentStepKey}
                    bottomRightSideBar={<HowToAddYourCommonCost />}
                    exitUrl={routingService.propertyUrl(portfolioId, propertyId)}
                    isEdit={ownerCostId && props.hasAnyActuals}
                    loading={props.ownerCostLoading}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onSwitchStep={onSwitchStep}
                    onSaveAndExit={onSaveAndExit}
                    onCancel={openCloseDraftModal}
                />
            ) : null}
            {props.showDraftModal && <LeaveModal onCancel={openCloseDraftModal} onLeave={goOutOfPage} onSave={onSaveAndExit} loading={props.ownerCostLoading}  className="cost-draft-modal"/>}
        </React.Fragment>
    );
};

const mapState = ({ property, portfolio, ownerCost }) => {
    return {
        ownerCost: get(ownerCost, 'ownerCost'),
        ownerCostActuals: get(ownerCost, 'ownerCost.ownerCostActuals'),
        ownerCostBudgets: get(ownerCost, 'ownerCost.ownerCostBudgets'),
        hasAnyActuals: get(ownerCost, 'ownerCost.ownerCostActuals.length') > 0,
        showDraftModal: get(ownerCost, 'showDraftModal'),
        ownerCostLoading: get(ownerCost, 'ownerCostLoading'),
        ownerCostNotFound: get(ownerCost, 'ownerCostNotFound'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        addOwnerCostActualsCall(propertyId, data, portfolioId) {
            return dispatch(addOwnerCostActuals(propertyId, data, portfolioId));
        },
        getOwnerCostByIdCall(portfolioId, propertyId, ownerCostId) {
            return dispatch(getOwnerCostById(portfolioId, propertyId, ownerCostId));
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        setValueOwnerCostCall(key, value) {
            dispatch(setValueOwnerCost(key, value));
        },
        showActualsAddResult() {
            dispatch(setValueOwnerCost('showActualsAddResultModal', true));
        },
    };
};

const AddOwnerCostActual = clear(connect(mapState, mapDispatch)(AddOwnerCostActualComponent));
export default AddOwnerCostActual;
