import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clearDeletePropertyModel, setValueProperty } from '../../../reducers/propertyReducer';
import get from 'lodash-es/get';
import toaster from '../../../common/toaster';
import ConfirmDeleteModal from '../../../components/confirm-delete-modal';

const ConfirmDeletePropertyComponent = (props) => {
    const { t } = useTranslation();

    const onClose = () => {
        props.closeModalWindow();
        props.clearDeletePropertyModelCall();
    };

    const deleteProperty = async () => {
        if (props.handleDeleteProperty) {
            await props.handleDeleteProperty();
            onClose();
            toaster.success(t('common.itemDeletedSuccessfully', { name: props.property.propertyName }));
        }
    };

    return (
        <ConfirmDeleteModal
            onClose={onClose}
            onConfirm={deleteProperty}
            title={t('property.delete.title', { name: props.property.propertyName })}
            text={t('property.delete.sentence')}
            loading={props.deletePropertyLoading}
            deleteBtnText={t('property.delete.deleteBtnText')}
        />
    );
};

const mapState = ({ property }) => {
    return {
        property: get(property, 'deletePropertyModel'),
        deletePropertyLoading: get(property, 'deletePropertyLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        clearDeletePropertyModelCall() {
            dispatch(clearDeletePropertyModel());
        },
        closeModalWindow() {
            dispatch(setValueProperty('showConfirmDeletePropertyModal', false));
        },
    };
};

const ConfirmDeleteProperty = connect(mapState, mapDispatch)(ConfirmDeletePropertyComponent);
export default ConfirmDeleteProperty;
