import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import uuidV4 from 'uuid/v4';
import { Form } from 'antd';
import { createCommonCost, setValueCommonCost } from '../../../reducers/commonCostReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';
import { commonCostItemTypeOptions } from '../../../common/common-cost-options';
import costIcon from '../../../assets/images/new-design-cost-icon.svg';
import routingService from '../../../services/routingService';
import objectHelper from '../../../helpers/trim-object';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import moment from 'moment';
import NotFoundPage from '../../../components/error-page';
import LeaveModal from '../../../components/leave-modal';
import HowToAddYourCommonCost from '../info-container';
import CommonCostBudgetsReview from './review-step';
import CommonCostDetails from './details-step';
import CommonCostBudgetItems from './budget-items-step';
import toaster from '../../../common/toaster';
import commonCostService from '../../../services/commonCostService';
import CostsObjectLayout from '../../../components/layouts/costs-object-layout';

const AddCommonCostBudgetComponent = (props) => {
    const { t } = useTranslation();

    const stepNames = {
        details: 'details',
        budgets: 'budgets',
        review: 'review',
    };

    const [currentStepKey, setCurrentStepKey] = useState(stepNames.details);
    const [timelineSteps, setTimelineSteps] = useState([]);
    const timelineStepsRef = useRef([]);
    const detailsRef = useRef();
    const budgetRef = useRef();
    const { portfolioId, propertyId } = useParams();

    useEffect(() => {
        props.getPropertyCall(portfolioId, propertyId);
        createDefaultCostItems();
    }, []);

    const createDefaultCostItems = (_) => {
        const defaultCostItems = commonCostItemTypeOptions.map((x) => ({
            name: x.name(),
            costType: x.id,
            isBudget: true,
            uId: uuidV4(),
        }));
        props.setValueCommonCostCall('commonCost.commonCostBudgets', defaultCostItems);
    };

    //#region navigation

    useEffect(() => {
        mapTimelineSteps();
    }, [props.commonCost]);

    const mapTimelineSteps = (_) => {
        const timelineSteps = steps.map((x) => ({
            key: x.key,
            stepTitle: x.stepTitle,
            isCompleted: isStepCompleted(x.key),
            canSkip: x.canSkip,
        }));

        setTimelineSteps(timelineSteps);
        timelineStepsRef.current = timelineSteps;
    };

    const isStepCompleted = (stepKey) => {
        if (!props.commonCost) {
            return false;
        }

        switch (stepKey) {
            case stepNames.details:
                return props.commonCost && props.commonCost.name && props.commonCost.startDate && props.commonCost.endDate;
            case stepNames.budgets:
                return props.commonCostBudgets && props.commonCostBudgets.length && props.commonCostBudgets.some((x) => x.value);
        }

        return false;
    };

    const onNext = () => {
        const currentStepIndex = timelineSteps.findIndex((x) => x.key === currentStepKey);
        const nextStep = timelineSteps[currentStepIndex + 1];

        if (!nextStep) {
            return;
        }

        onStepChanging().then((updatedCommonCost) => updatedCommonCost && setCurrentStepKey(nextStep.key));
    };

    const onPrevious = (previousStep) => {
        if (previousStep) {
            setCurrentStepKey(previousStep.key);
        } else {
            const currentStepIndex = steps.findIndex((x) => x.key === currentStepKey);
            const previousStep = steps[currentStepIndex - 1];
            previousStep && setCurrentStepKey(previousStep.key);
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious(newStep);
        } else {
            onStepChanging().then((updatedCommonCost) => updatedCommonCost && onChangeStep(newStep.key));
        }
    };

    const onChangeStep = (newStepKey) => {
        const timelineStepsByRef = timelineStepsRef.current;
        const newIndex = timelineStepsByRef.findIndex((x) => x.key === newStepKey);
        const previousSteps = timelineStepsByRef.slice(0, newIndex);
        const isPreviousStepsAllCompleted = previousSteps.every((x) => x.isCompleted || x.canSkip);

        if (isPreviousStepsAllCompleted) {
            setCurrentStepKey(newStepKey);
        } else {
            const firstInCompletedStep = timelineStepsByRef.find((x) => !x.isCompleted || x.canSkip);
            firstInCompletedStep && setCurrentStepKey(firstInCompletedStep.key);
        }
    };

    //#endregion

    const validateStep = (stepRef) => {
        return stepRef.current.validateFields();
    };

    const getDataFromDetailsForm = (_) => {
        const values = detailsRef.current.getFieldsValue();
        return objectHelper.trimObjectProperties(values);
    };

    const onStepChanging = async () => {
        const commonCost = { ...props.commonCost };
        switch (currentStepKey) {
            case stepNames.details: {
                return validateStep(detailsRef).then((_) => {
                    const formData = getDataFromDetailsForm();
                    commonCost.startDate = moment(formData.costPeriod[0]).startOf('day').utc(true);
                    commonCost.endDate = moment(formData.costPeriod[1]).startOf('day').utc(true);
                    commonCost.name = formData.name;
                    commonCost.buildingId = formData.buildingId;
                    commonCost.plotId = formData.plotId;
                    commonCost.lettable = formData.lettable;
                    commonCost.grossArea = formData.lettable;
                    commonCost.nonLettable = 0;
                    commonCost.vacancyArea = formData.vacancyArea;
                    props.setValueCommonCostCall('commonCost', commonCost);

                    return Promise.resolve(commonCost);
                });
            }
            case stepNames.budgets: {
                await budgetRef.current.validateFields();
                const formData = budgetRef.current.getFieldsValue();
                const predefinedItems = props.commonCostBudgets.filter((x) => x.costType);
                predefinedItems.forEach((x) => (x.value = formData['budgets' + x.costType]));
                const additionalItems = props.commonCostBudgets.filter((x) => !x.costType);

                const items = [...predefinedItems, ...additionalItems];

                if (commonCostService.listOfCostsIsEmpty(items)) {
                    toaster.error(t('commonCost.budgets.atLeastOneBudgetCost'), null);
                    return Promise.reject();
                }

                const commonCost = { ...props.commonCost };
                commonCost.budgetAdministrationFee = formData.budgetAdministrationFee;
                commonCost.budgetAdministrationFeePercent = formData.budgetAdministrationFeePercent;

                props.setValueCommonCostCall('commonCost', commonCost);
                props.setValueCommonCostCall('commonCost.commonCostBudgets', items);

                return Promise.resolve(commonCost);
            }
            default:
                return Promise.resolve(commonCost);
        }
    };

    const onSaveAndExit = (_) => {
        const commonCost = props.commonCost;
        commonCost.propertyId = +propertyId;
        commonCost.portfolioId = +portfolioId;
        commonCost.commonCostItems = commonCostService.filterCommonCostItemsByValue(commonCost.commonCostBudgets);
        props.createCommonCostCall(commonCost).then((commonCostId) => commonCostId && created(commonCostId));
    };

    const created = (commonCostId) => {
        goOutOfPage(commonCostId);
        props.showCommonCostCreationResult();
    };

    const goOutOfPage = (commonCostId) => {
        const selectedYear = props.commonCost.startDate._isAMomentObject ? props.commonCost.startDate.format() : props.commonCost.startDate;

        routingService.navigateToCommonCosts(portfolioId, propertyId, { commonCostId: commonCostId, selectedYear: selectedYear });
    };

    const openCloseDraftModal = (_) => {
        props.setValueCommonCostCall('showDraftModal', !props.showDraftModal);
    };

    const steps = [
        {
            key: stepNames.details,
            header: t('commonCost.detailsStep.header'),
            stepTitle: t('commonCost.detailsStep.stepTitle'),
            logo: costIcon,
            content: (
                <Form name="details" autoComplete="off" ref={detailsRef}>
                    <CommonCostDetails detailsRef={detailsRef} />
                </Form>
            ),
        },
        {
            key: stepNames.budgets,
            header: t('commonCost.budgetsStep.header'),
            stepTitle: t('commonCost.budgetsStep.stepTitle'),
            content: (
                <Form name="budgets" autoComplete="off" ref={budgetRef}>
                    <CommonCostBudgetItems budgetRef={budgetRef} />
                </Form>
            ),
        },
        {
            key: stepNames.review,
            header: t('commonCost.reviewStep.header'),
            stepTitle: t('commonCost.reviewStep.header'),
            content: <CommonCostBudgetsReview goToStep={() => setCurrentStepKey(stepNames.budgets)} />,
        },
    ];

    return (
        <React.Fragment>
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.commonCostNotFound ? <NotFoundPage header={t('commonCost.notFound')} /> : null}
            {!props.portfolioNotFound && !props.propertyNotFound && !props.commonCostNotFound ? (
                <CostsObjectLayout
                    className="common-cost-new-design"
                    isNewDesign={true}
                    steps={steps}
                    timelineSteps={timelineSteps}
                    currentStepKey={currentStepKey}
                    bottomRightSideBar={<HowToAddYourCommonCost />}
                    exitUrl={routingService.commonCostsUrl(portfolioId, propertyId)}
                    loading={props.commonCostLoading}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onSwitchStep={onSwitchStep}
                    onSaveAndExit={onSaveAndExit}
                    onCancel={openCloseDraftModal}
                />
            ) : null}
            {props.showDraftModal && <LeaveModal onCancel={openCloseDraftModal} onLeave={goOutOfPage} onSave={onSaveAndExit} loading={props.commonCostLoading} className="cost-draft-modal"/>}
        </React.Fragment>
    );
};

const mapState = ({ property, portfolio, commonCost }) => {
    return {
        commonCost: get(commonCost, 'commonCost'),
        commonCostBudgets: get(commonCost, 'commonCost.commonCostBudgets'),
        showDraftModal: get(commonCost, 'showDraftModal'),
        commonCostLoading: get(commonCost, 'commonCostLoading'),
        commonCostNotFound: get(commonCost, 'commonCostNotFound'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        createCommonCostCall(values) {
            return dispatch(createCommonCost(values));
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        setValueCommonCostCall(key, value) {
            dispatch(setValueCommonCost(key, value));
        },
        showCommonCostCreationResult() {
            dispatch(setValueCommonCost('showBudgetsAddResultModal', true));
        },
    };
};

const AddCommonCostBudget = clear(connect(mapState, mapDispatch)(AddCommonCostBudgetComponent));
export default AddCommonCostBudget;
