const env = process.env.REACT_APP_STAGE ? process.env.REACT_APP_STAGE : 'local';
console.log('App build version: ', process.env.REACT_APP_VERSION);

const configurations = {
    test: {
        API_HOST: 'https://estatelabgatewayservice.azurewebsites.net/',
    },
    uat: {
        API_HOST: 'https://estatelab-uat-gatewayservice.azurewebsites.net/',
    },
    prod: {
        API_HOST: 'https://estatelab-prod-gateway.azurewebsites.net/',
    },
    local: {
        API_HOST: 'https://localhost:3002/',
    },
};

var config = configurations[env];

export default config;
