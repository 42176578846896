import { Timeline } from 'antd';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import step_current from '../../assets/images/step-current.svg';
import step_done from '../../assets/images/step-done.svg';
import new_step_current from '../../assets/images/new-current-step.svg';
import new_step_done from '../../assets/images/new-step-done.svg'

const StepsTimeline = ({ steps, currentStepIndex, onSwitchStep, isNewDesign }) => {
    const { t } = useTranslation();

    const getTimelineItemClassName = (index) => {
        return index < currentStepIndex ? ' ready' : index === currentStepIndex ? ' current-page' : '';
    };

    const getTimelineItemDotImage = (index, step) => {
        return index === currentStepIndex ? <Image src={new_step_current} /> : step.isCompleted || step.canShowAsCompleted ? <Image src={new_step_done} /> : <Image />;
    };

    const renderTimeLineUnderItem = (step, index) => {
        return (
            <Timeline.Item key={step.key} className={getTimelineItemClassName(index)} dot={getTimelineItemDotImage(index, step)} style={{ marginLeft: '25px'}}>
                {step.stepTitle}
            </Timeline.Item>
        )
    }

    const renderTimeLineItem = (step, index) => {
        return (
            <>
                <Timeline.Item key={step.key} className={getTimelineItemClassName(index)} dot={getTimelineItemDotImage(index, step)} onClick={() => onSwitchStep(step)}>
                    {step.stepTitle}
                </Timeline.Item>
                {
                    (step?.underSteps?.length > 0 && currentStepIndex ==index) && (
                        step.underSteps.map((item, itemIndex) => renderTimeLineUnderItem(item, itemIndex))
                    )
                }
            </>
        );
    };

    return (
        <div className="web-progress-container">
            <h4>{t('common.progress')}</h4>
            <Timeline>{steps && steps.map((item, index) => renderTimeLineItem(item, index))}</Timeline>
        </div>
    );
};

export default StepsTimeline;
