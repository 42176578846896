import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete } from 'antd';
import axios from 'axios';
import get from 'lodash-es/get';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { enterBtnCode } from '../../common/constants';
import useDebounce from '../../helpers/useDebounse';
import plotService from '../../services/plotService';

const MunicipalityComponent = (props) => {
    const [cities, setMunicipalities] = useState();
    const [searchedMunicipality, setSearchMunicipality] = useState();
    const [selectedMunicipality, setSelectedMunicipality] = useState(props.municipality && props.city ? `${props.municipality}/${props.city}` : '');

    const [searching, setSearching] = useState(false);
    const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
    const debouncedSearchString = useDebounce(searchedMunicipality, 500);

    const onSelectMunicipality = (id, data) => {
        props.onChange(data.info);
        setSelectedMunicipality(data.info.name);
    };

    const onChangeMunicipality = (value) => {
        value = value ? value.trim() : '';
        if (!value) {
            setSearchMunicipality(null);
        }
        props.onChange(value);
        setSelectedMunicipality(value);
    };

    const cancelTokenFunc = () => {
        cancelToken.cancel();
        setSearching(false);
        setCancelToken(axios.CancelToken.source());
    };

    const onSearch = (data) => {
        if (data && data.length > 1) {
            setSearchMunicipality(data);
        }
        if (data === '' || data === undefined) {
            setMunicipalities([]);
            if (searching) {
                cancelTokenFunc();
            }
        }
    };

    useEffect(() => {
        if (debouncedSearchString) {
            setSearching(true);
            const searchString = debouncedSearchString;
            const municipalityNumber = /^\d{4}$/.test(searchString) ? searchString : null;
            plotService
                .searchMunicipality(searchString, municipalityNumber, cancelToken)
                .then((res) => {
                    setSearching(false);
                    setMunicipalities(res.data);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log('Canceled');
                    }
                    setMunicipalities([]);
                    setSearching(false);
                });
        }
    }, [debouncedSearchString, cancelToken]);

    return (
        <div className="d-flex flex-column">
            <p className="font-weight-bold">{props.title}</p>
            <div className="select-container">
                <AutoComplete
                    virtual={false}
                    listHeight={120}
                    allowClear
                    autoComplete="off"
                    value={(selectedMunicipality === '' || selectedMunicipality === undefined) && props.city !== '' ? `${props.municipality}/${props.city}` : selectedMunicipality}
                    placeholder={props.placeholder}
                    onSearch={onSearch}
                    onSelect={onSelectMunicipality}
                    onChange={onChangeMunicipality}
                    onKeyDown={(e) => (e.keyCode === enterBtnCode ? e.preventDefault() : '')}>
                    {cities &&
                        cities.map((municipality) => (
                            <AutoComplete.Option
                                key={municipality.name}
                                info={{
                                    number: municipality.number,
                                    name: municipality.name,
                                }}>
                                {municipality.name}
                            </AutoComplete.Option>
                        ))}
                </AutoComplete>
                {searching && <LoadingOutlined className="autocomplete-loading-icon" />}
            </div>
        </div>
    );
};

const mapState = ({ plot }) => {
    return {
        municipality: get(plot, 'plot.municipality'),
        city: get(plot, 'plot.city'),
        plot: get(plot, 'plot'),
    };
};

const Municipality = connect(mapState, null)(MunicipalityComponent);
export default Municipality;
