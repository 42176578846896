import axios from 'axios';
import { entityStatusRequestOptions } from '../common/contract-options';
import { filterOptions } from '../common/property-options';

const contractService = {
    getContractAndRelatedTenant: async (contractId, tenantId, propertyId, portfolioId, status) => {
        return await axios.all([
            axios({
                method: 'post',
                url: `/api/contract/contracts/get-by-id?contractId=${contractId}&tenantId=${tenantId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
                data: {
                    rentObjectStatus: entityStatusRequestOptions.none,
                },
            }),
            axios({
                method: 'post',
                data: {
                    status: status,
                },
                url: `/api/contract/tenants/${tenantId}?portfolioId=${portfolioId}&propertyId=${propertyId}`,
            }),
        ]);
    },

    downloadDocument: (documentId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'get',
            responseType: 'blob',
            url: `/api/contract/contracts/download-document/${documentId}?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    downloadWarrantyDocument: (documentId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'get',
            responseType: 'blob',
            url: `/api/contract/contractdetails/download-warranty-document/${documentId}?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    getContractsByPortfolioId: (portfolioId, filter) => {
        return axios({
            method: 'post',
            data: filter,
            url: `/api/contract/contracts/get-by-portfolio-id?portfolioId=${portfolioId}`,
        });
    },

    getContractsByTenantId: (tenantId, propertyId, filter, portfolioId) => {
        return axios({
            method: 'post',
            data: filter,
            url: `/api/contract/contracts/get-by-tenant-id?tenantId=${tenantId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    getById: (id, tenantId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            url: `/api/contract/contracts/get-by-id?contractId=${id}&tenantId=${tenantId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
            data: {
                rentObjectStatus: entityStatusRequestOptions.none,
            },
        });
    },

    getDraft: (propertyId, tenantId, portfolioId) => {
        return axios({
            method: 'post',
            url: `/api/contract/contracts/get-draft?tenantId=${tenantId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
            data: {
                rentObjectStatus: entityStatusRequestOptions.none,
            },
        });
    },

    createDraft: (tenantId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            url: `/api/contract/contracts/create-draft?tenantId=${tenantId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    create: (contractId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            url: `/api/contract/contracts?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    delete: (contractId, portfolioId, propertyId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/contracts?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    archive: (contractId, portfolioId, propertyId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/contracts/archive?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    restoreArchivedContract: (contractId, portfolioId, propertyId) => {
        return axios({
            method: 'put',
            url: `/api/contract/contracts/restore-archived?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    getCalculatedInvoices: (contractId, portfolioId) => {
        return axios({
            method: 'get',
            url: `/api/contract/contracts/calculate-invoices?contractId=${contractId}&portfolioId=${portfolioId}`,
        });
    },

    getRentObjectCalculations: (rentObjectId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'get',
            url: `/api/contract/v2.0/contracts/rent-objects-calculation?rentObjectId=${rentObjectId}&contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    uploadFile: (file, options, contractId, portfolioId, propertyId) => {
        const data = new FormData();
        data.append('file', file);

        return axios.post(`/api/contract/contracts/upload-document?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`, data, options);
    },

    uploadWarrantyDocument: (file, options, warrantyId, contractId, portfolioId, propertyId) => {
        const data = new FormData();
        data.append('file', file);

        return axios.post(
            `/api/contract/contractdetails/upload-warranty-document?contractWarrantyId=${warrantyId}&contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
            data,
            options
        );
    },

    deleteFile: (documentId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/contracts/delete-document/${documentId}?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    deleteWarrantyDocument: (documentId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/contractdetails/delete-warranty-document/${documentId}?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    getContractDocumentDownloadLink: (documentId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'post',
            url: `/api/contract/contracts/get-document-link/${documentId}?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    getWarrantyDocumentDownloadLink: (documentId, contractId, portfolioId, propertyId) => {
        return axios({
            method: 'post',
            url: `/api/contract/contractdetails/get-warranty-document-link/${documentId}?contractId=${contractId}&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    getRentPaymentPlanTableData: (rentObjects, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            data: rentObjects,
            url: `/api/contract/contracts/get-rent-payment-plan-table-data?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },
    getRentPaymentPlanTableDataIncludeCPI: (rentObjects, contractId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            data: rentObjects,
            url: `/api/contract/v2.0/contracts/get-rent-payment-plan-table-data?contractId=${contractId}&propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },
    getInvoiceScheduleTableData: (paymentTerms, contractId, portfolioId) => {
        return axios({
            method: 'post',
            data: paymentTerms,
            url: `/api/contract/contracts/get-invoice-schedule-table-data?contractId=${contractId}&portfolioId=${portfolioId}`,
        });
    },

    getDataForContractCreation: async (portfolioId) => {
        return await axios.all([
            axios({ url: `/api/contract/properties/get-by-portfolio?portfolioId=${portfolioId}`, method: 'post', data: { status: filterOptions.active } }),
            axios.get(`/api/contract/tenants/get-by-portfolio-id?portfolioId=${portfolioId}`),
        ]);
    },
};

export default contractService;
