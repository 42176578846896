import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import get from 'lodash.get';
import NumberFormat from '../../components/number-format';
import helper from '../../helpers/helper';

const PortfolioTotalComponent = (props) => {
    const { t } = useTranslation();

    return (
        <Row>
            <Col md={4}>
                <div>
                    <div className="totals-title">
                        <span className="title mr-2 text">{t('portfolio.dashboard.properties')}</span>
                        <span className="value text-secondary">{props.propertiesCount}</span>
                    </div>
                    <div className="value-title mt-5">
                        <p className="title mb-0">{t('portfolio.dashboard.wault')}</p>
                        <h4>{t('portfolio.dashboard.waultYears', { years: helper.transformNumberToLocale(props.wault) })}</h4>
                    </div>
                </div>
            </Col>
            <Col md={4}>
                <div>
                    <div className="totals-title">
                        <span className="title mr-2 text">{t('portfolio.dashboard.totalContracts')}</span>
                        <span className="value text-secondary">{props.contractsCount}</span>
                    </div>
                    <div className="value-title mt-5">
                        <p className="title mb-0">{t('portfolio.dashboard.totalContractsIncome')}</p>
                        <h4>
                            <NumberFormat value={props.totalContractIncome} />
                        </h4>
                    </div>
                </div>
            </Col>
            <Col md={4}>
                <div>
                    <div className="totals-title">
                        <span className="title mr-2 text">{t('portfolio.dashboard.events')}</span>
                        <span className="value text-secondary">{props.eventsCount}</span>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

const mapState = ({ portfolio }) => {
    return {
        propertiesCount: get(portfolio, 'kpis.propertiesCount'),
        contractsCount: get(portfolio, 'kpis.contractsCount'),
        eventsCount: get(portfolio, 'kpis.eventsCount'),
        totalContractIncome: get(portfolio, 'kpis.totalContractIncome'),
        wault: get(portfolio, 'kpis.wault'),
    };
};

const PortfolioTotal = connect(mapState)(PortfolioTotalComponent);
export default PortfolioTotal;
