import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Form } from 'antd';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { setValueProperty, clearAdditionalOwnerInfo, setAdditionalOwnerInfo, defaultOwner } from '../../../../reducers/propertyReducer';
import get from 'lodash-es/get';
import CommonInput from '../../../../components/common-input';
import { validateEmail } from '../../../../common/validators';
import CompanyAutoComplete from '../../../../components/company-autocomplete';
import KartverketDataComponent from '../../../../components/kartverket-data';
import CountrySelect from '../../../../components/country-selector';
import OwnPhoneInput from '../../../../components/phone-input';
import MaskedInput from 'antd-mask-input';
import companyService from '../../../../services/companyService';
import axios from 'axios';
import useDebounce from '../../../../helpers/useDebounse';

const PropertyOwnerByCompanyComponent = (props) => {
    const { t } = useTranslation();
    const [showAllert, setShowAllert] = useState(false);

    const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
    const [searchOrgNumber, setSearchOrgNumber] = useState('');
    const debouncedSearchCompany = useDebounce(searchOrgNumber, 500);

    const propertyOwner = props.propertyOwner || defaultOwner;

    useEffect(() => {
        if (propertyOwner) {
            props.ownerRef.current.setFieldsValue(propertyOwner);
        }
    }, [propertyOwner]);

    const onChange = (value) => {
        if (value === undefined) {
            setShowAllert(false);
            props.setValuePropertyCall('property.owner', { ...defaultOwner, id: propertyOwner.id, ownedBy: propertyOwner.ownedBy, country: propertyOwner.country });
            props.ownerRef.current.setFieldsValue({ orgName: '', orgNumber: '', post: '', address: '' });
            props.ownerRef.current.validateFields(['orgName', 'orgNumber', 'post', 'address']);
            setSearchOrgNumber(null);
        } else if (value.name) {
            setShowAllert(true);
            props.setValuePropertyCall('propertyOwnerCompanyInfo', value.info);
            props.ownerRef.current.setFieldsValue({
                orgName: value.name,
                post: value.info.zipCode,
                orgNumber: value.info.identificationNumber,
                address: value.info.address ? value.info.address.join(', ') : '',
            });
        } else if (typeof value === 'string' && !parseInt(value)) {
            props.ownerRef.current.setFieldsValue({
                orgName: value,
            });
        }
    };

    const onGetAdditionalInfo = (value) => {
        props.setAdditionalOwnerInfoCall(value);
    };

    const onChangeOrgNumber = (value) => {
        setSearchOrgNumber(value.replace(/\s/g, ''));
        if (!value) {
            setShowAllert(false);
            cancelToken.cancel();
            setCancelToken(axios.CancelToken.source());
        }
    };

    useEffect(() => {
        if (debouncedSearchCompany) {
            if (/^\d{9}$/g.test(searchOrgNumber)) {
                companyService
                    .getCompanyByOrgNumber(searchOrgNumber)
                    .then((res) => {
                        props.ownerRef.current.setFieldsValue({
                            orgName: res.data.name,
                            post: res.data.zipCode,
                            orgNumber: res.data.identificationNumber,
                            municipality: res.data.postal,
                            address: res.data.address ? res.data.address.join(', ') : '',
                        });
                        setShowAllert(true);
                        props.setValuePropertyCall('propertyOwnerCompanyInfo', res.data);
                    })
                    .catch((error) => {
                        setShowAllert(false);
                        props.ownerRef.current.resetFields(['orgName', 'post', 'municipality', 'address']);
                        props.clearAdditionalOwnerInfoCall();
                    });
            }
        }
    }, [debouncedSearchCompany, cancelToken]);

    return (
        <React.Fragment>
            <div className="page-container pt-0">
                <Row>
                    <Col lg={12} xl={6}>
                        <p className="font-weight-bold">{t('property.owner.details.orgName.title')}</p>
                        <Form.Item name="ownedBy" hidden={true}></Form.Item>
                        <Form.Item
                            name="orgName"
                            rules={[
                                {
                                    required: true,
                                    message: t('property.owner.details.orgName.title') + t('common.isRequired'),
                                },
                            ]}>
                            <CompanyAutoComplete
                                value={props.companyInfo ? props.companyInfo.name : null}
                                isSelected={propertyOwner.orgNumber && propertyOwner.orgNumber}
                                initCompanyNumber={propertyOwner.orgNumber}
                                getAdditionalInfo={onGetAdditionalInfo}
                                placeholder={t('property.owner.details.orgName.placeholder')}
                                onChange={onChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {showAllert && <Alert message={t('common.companyAlertMessage') + ' '} description={t('common.companyAlertDescription')} type="success" showIcon />}
                <Row>
                    <Col lg={12} xl={6}>
                        <p className="font-weight-bold">{t('property.owner.details.orgNumber.title')}</p>
                        <Form.Item
                            name="orgNumber"
                            rules={[
                                {
                                    required: true,
                                    message: t('property.owner.details.orgNumber.title') + t('common.isRequired'),
                                },
                            ]}>
                            <MaskedInput onChange={(e) => onChangeOrgNumber(e.target.value)} mask="111 111 111" placeholder={t('property.owner.details.orgNumber.placeholder')} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className="page-container">
                <Row>
                    <Col lg={12} xl={6}>
                        <h5>{t('property.owner.contacts.title')}</h5>
                        <Form.Item name="name">
                            <CommonInput maxLength={150} title={t('property.owner.contacts.name.title')} placeholder={t('property.owner.contacts.name.placeholder')} />
                        </Form.Item>
                        <Form.Item name="address">
                            <CommonInput maxLength={150} title={t('property.owner.contacts.address.title')} placeholder={t('property.owner.contacts.address.placeholder')} />
                        </Form.Item>
                        <p className="font-weight-bold">{t('property.owner.contacts.phone.title')}</p>
                        <Form.Item name="phone">
                            <OwnPhoneInput placeholder={t('property.owner.contacts.email.placeholder')} />
                        </Form.Item>
                        <Form.Item name="email" rules={[validateEmail]}>
                            <CommonInput
                                type={'email'}
                                maxLength={150}
                                title={t('property.owner.contacts.email.title')}
                                placeholder={t('property.owner.contacts.email.placeholder')}
                            />
                        </Form.Item>
                        <Form.Item name="post">
                            <CommonInput maxLength={150} title={t('property.owner.contacts.post.title')} placeholder={t('property.owner.contacts.post.placeholder')} />
                        </Form.Item>
                        <p className="font-weight-bold">{t('property.owner.contacts.country.title')}</p>
                        <Form.Item name="country" initialValue={t('defaultCountry')}>
                            <CountrySelect placeholder={t('property.owner.contacts.country.placeholder')} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            {props.companyInfo && (
                <React.Fragment>
                    <hr />
                    <div className="page-container">
                        <Row>
                            <Col lg={12}>
                                <KartverketDataComponent data={props.companyInfo} />
                            </Col>
                        </Row>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const mapState = ({ property }) => {
    return {
        propertyOwner: get(property, 'property.owner'),
        companyInfo: get(property, 'propertyOwnerCompanyInfo'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setAdditionalOwnerInfoCall(value) {
            dispatch(setAdditionalOwnerInfo(value));
        },
        clearAdditionalOwnerInfoCall() {
            dispatch(clearAdditionalOwnerInfo());
        },
        setValuePropertyCall(key, value) {
            dispatch(setValueProperty(key, value));
        },
    };
};

const PropertyOwnerByCompany = connect(mapState, mapDispatch)(PropertyOwnerByCompanyComponent);
export default PropertyOwnerByCompany;
