import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { Form, Modal, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { setValueTenant } from '../../../reducers/tenantReducer';
import ActionButton from '../../../components/action-button';
import LoaderSpinner from '../../../components/spinner';

const { Option } = Select;

const ModalForTenantCreationComponent = (props) => {
    const { t } = useTranslation();
    const formRef = React.useRef();

    const [property, setProperty] = useState();

    const onClose = () => {
        props.closeModalWindow();
    };

    const goToCreation = (_) => {
        formRef.current.validateFields().then((_) => {
            props.goToCreation && props.goToCreation(property);
        });
    };

    return (
        <Modal wrapClassName="result-modal" footer={null} visible={true} maskClosable={false} onCancel={onClose} closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <h4 className="mb-2">{t('selectEntityForCreation.tenantHeader')}</h4>
                </div>
                <div className="main">
                    {props.propertiesLoading ? (
                        <LoaderSpinner />
                    ) : (
                        <Form name="basic" autoComplete="off" ref={formRef}>
                            <p className="font-weight-bold mb-2">{t('selectEntityForCreation.property')}</p>
                            <Form.Item className="mb-0" name="property" rules={[{ required: true }]}>
                                <Select onSelect={setProperty} placeholder={t('selectEntityForCreation.propertyPlaceholder')}>
                                    {props.properties.map((item) => (
                                        <Option value={item.id} key={item.id}>
                                            {item.propertyName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form>
                    )}
                </div>
                <div className="btns">
                    <ActionButton onClick={onClose} className="btn-secondary" text={t('common.buttons.cancel')} />
                    <ActionButton onClick={goToCreation} className="btn-primary" text={t('common.buttons.confirm')} />
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ property }) => {
    return {
        properties: get(property, 'properties'),
        propertiesLoading: get(property, 'propertiesLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueTenant('showModalForTenantCreation', false));
        },
    };
};

const ModalForTenantCreation = connect(mapState, mapDispatch)(ModalForTenantCreationComponent);
export default ModalForTenantCreation;
