import React, { useEffect, useState } from 'react';
import { Form, Radio } from 'antd';
import { Col, Row, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setValueCommonCost } from '../../../reducers/commonCostReducer';
import { feesValueTypes } from '../../../common/common-cost-options';
import AdditionalCommonCostCard from '../../../components/common-cost/additional-common-cost-card';
import AddAdditionalCostModal from '../modals/create-edit-additional-cost-modal';
import PositiveNumericInput from '../../../components/numeric-input';
import ActionButton from '../../../components/action-button';
import trash from '../../../assets/images/trash-default.svg';
import { validatePercents } from '../../../common/validators';
import { commonCostSelector, commonCostBudgetItemsSelector } from '../selectors';

const CommonCostBudgetItems = ({ budgetRef }) => {
    const { t } = useTranslation();
    const commonCost = useSelector(commonCostSelector);
    const commonCostBudgets = useSelector(commonCostBudgetItemsSelector);
    const showAddAdditionalCostModal = useSelector(({ commonCost }) => commonCost.showAddAdditionalCostModal);

    const [adminFeeType, setAdminFeeType] = useState(commonCost.budgetAdministrationFee ? feesValueTypes.currency : feesValueTypes.percent);
    const [predefinedItems, setPredefinedItems] = useState([]);
    const [additionalItems, setAdditionalItems] = useState([]);

    const dispatch = useDispatch();
    const setAdditionalCostEditModel = (cost) => {
        dispatch(setValueCommonCost('additionalCostEditModel', cost));
    };
    const updateCommonCostBudgets = (newList) => {
        dispatch(setValueCommonCost('commonCost.commonCostBudgets', newList));
    };
    const showAddAdditionalCostCall = () => {
        dispatch(setValueCommonCost('showAddAdditionalCostModal', true));
    };

    useEffect(() => {
        const predefinedItems = commonCostBudgets.filter((x) => x.costType);
        const formData = predefinedItems.reduce((obj, item) => Object.assign(obj, { ['budgets' + item.costType]: item.value }), {});
        formData.budgetAdministrationFee = commonCost.budgetAdministrationFee;
        formData.budgetAdministrationFeePercent = commonCost.budgetAdministrationFeePercent;
        budgetRef.current.setFieldsValue(formData);
    }, []);

    useEffect(() => {
        setPredefinedItems(commonCostBudgets.filter((x) => x.costType));
        setAdditionalItems(commonCostBudgets.filter((x) => !x.costType));
    }, [commonCostBudgets]);

    const editAdditionalCommonCost = (cost) => {
        setAdditionalCostEditModel(cost);
        showAddAdditionalCostCall();
    };

    const deleteCommonCostItem = (cost) => {
        const currentList = [...commonCostBudgets];
        const newList = cost.id ? currentList.filter((x) => x.id !== cost.id) : currentList.filter((x) => x.uId !== cost.uId);
        updateCommonCostBudgets(newList);
    };

    const handleSaveAdditionalCost = (item) => {
        const items = [...(commonCostBudgets || [])];
        const existingItemIndex = items.findIndex((x) => (x.id > 0 && x.id === item.id) || x.uId === item.uId);

        if (existingItemIndex >= 0) {
            items[existingItemIndex] = item;
        } else {
            item.isBudget = true;
            items.push(item);
        }

        updateCommonCostBudgets(items);
    };

    const normalizeTwoDigitsAfterComma = (value) => {
        const strValue = String(value);
        const countDigitsAfterComma = strValue.includes('.') ? strValue.split('.')[1].length : 0;
        return value % 1 !== 0 && countDigitsAfterComma > 2 ? parseFloat(value).toFixed(2) : value;
    };

    return (
        <React.Fragment>
            {predefinedItems.length > 0 ? (
                <div className="page-container bottom-border">
                    <Row>
                        <Col lg={12} xl={6}>
                            {predefinedItems.map((cost) => (
                                <div key={cost.costType}>
                                    <p className="mb-2 font-weight-bold">{cost.name}</p>
                                    <div className="d-flex flex-row">
                                        <Form.Item
                                            name={'budgets' + cost.costType}
                                            rules={[{
                                                validator: (_, value) => {
                                                    if (value == undefined) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value < 0) {
                                                        return Promise.reject(new Error(`${cost.name} ${t('commonCost.budgetStep.cantBeLess')}`));
                                                    }

                                                    return Promise.resolve();
                                                }
                                            }]}
                                            className={`${predefinedItems[predefinedItems.length - 1].costType === cost.costType ? 'mb-0' : ''} w-100`}>
                                            <PositiveNumericInput
                                                prefix={t('currency.norway')}
                                                onChange={() =>
                                                    budgetRef.current.setFieldsValue({
                                                        ['budgets' + cost.costType]: normalizeTwoDigitsAfterComma(budgetRef.current.getFieldValue('budgets' + cost.costType)),
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                        <ActionButton
                                            className="btn-thirdy btn-transparent pr-0"
                                            onClick={() => deleteCommonCostItem(cost)}
                                            icon={<Image src={trash} className="m-0" />}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </div>
            ) : null}
            <div className="page-container bottom-border">
                <p className="font-weight-bold">{t('commonCost.budgetStep.additionalCosts')}</p>
                <Row>
                    <Col lg={12} xl={6}>
                        {additionalItems.map((item) => (
                            <AdditionalCommonCostCard
                                key={item.name}
                                cost={item}
                                editAdditionalCommonCost={editAdditionalCommonCost}
                                deleteAdditionalCommonCost={deleteCommonCostItem}
                                handleSaveAdditionalCost={handleSaveAdditionalCost}
                            />
                        ))}
                    </Col>
                </Row>
                <ActionButton className="btn-secondary" onClick={showAddAdditionalCostCall} text={t('commonCost.budgetStep.additionalCosts.addBtn')} />
            </div>
            <div className="page-container">
                <Row>
                    <Col lg={12} xl={6}>
                        <p className="mb-2 font-weight-bold">{t('commonCost.costs.administrationFees')}</p>
                        <Radio.Group defaultValue={adminFeeType} className="radio-container vertical-radio-container" onChange={(e) => setAdminFeeType(e.target.value)}>
                            <Radio value={feesValueTypes.percent}>{t('commonCost.fees.calculatePercent')}</Radio>
                            <Radio value={feesValueTypes.currency}>{t('commonCost.fees.calculateCurrency')}</Radio>
                        </Radio.Group>
                        {adminFeeType == feesValueTypes.percent ? (
                            <Form.Item name={'budgetAdministrationFeePercent'} className="mb-0" rules={[{ min: 0, max: 100, type: 'number', message: t('common.onlyPercent') }, { required: false }]}>
                                <PositiveNumericInput prefix={t('%')} />
                            </Form.Item>
                        ) : (
                            <Form.Item name={'budgetAdministrationFee'} className="mb-0">
                                <PositiveNumericInput prefix={t('currency.norway')} />
                            </Form.Item>
                        )}
                    </Col>
                </Row>
            </div>
            {showAddAdditionalCostModal && <AddAdditionalCostModal onSave={handleSaveAdditionalCost} />}
        </React.Fragment>
    );
};

export default CommonCostBudgetItems;
