import { Form } from 'antd';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18next from '../../services/i18nService';
import { languageService } from '../../services/languageService';
import LanguageSwitcher from '../language-switcher';
import Logo from '../logo-container';

const LoginSignUpLayout = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <Row noGutters className="layout-bg">
                <Col span={3} />
                <Col span={3} />
                <Col span={3} />
                <Col span={3} />
                <Col span={3} className="d-none d-md-block" />
                <Col span={3} className="d-none d-md-block" />
                <Col span={3} className="d-none d-md-block" />
                <Col span={3} className="d-none d-md-block" />
            </Row>
            <div className="login-layout">
                <div className="components h-100">
                    <Logo />
                    <Col className="window" xl={4} lg={6} md={8} sm={8} xs={11}>
                        <h4 className="mb-2">{props.header}</h4>
                        {props.children}
                    </Col>
                </div>
                <Row noGutters className="login-footer">
                    <Col md={6} className="d-flex">
                        <div className="copyright">
                            <span>
                                © {new Date().getFullYear()} {t('common.copyright')}{' '}
                            </span>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div>
                            <Form.Item className="lang-switcher mb-0">
                                <LanguageSwitcher value={i18next.language} onChange={languageService.changeLanguage} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default LoginSignUpLayout;
