import React, { useEffect } from 'react';
import { Form, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { updateLettableArea } from '../../../../reducers/commonCostReducer';
import ActionButton from '../../../../components/action-button';
import PositiveNumericInput from '../../../../components/numeric-input';
import { connect } from 'react-redux';
import lodash from 'lodash-es';
import { useParams } from 'react-router';

const LettableAreaModalComponent = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const propertyId = useSelector(({ commonCost }) => commonCost.commonCost.propertyId);
    const commonCostId = useSelector(({ commonCost }) => commonCost.commonCost.id);
    const initialAreaValue = useSelector(({ commonCost }) => commonCost.commonCostDetails.lettable);

    const { portfolioId } = useParams();

    const { closeModal, isAreaModalVisible, updateLettableArea } = props;

    const handleClose = (_) => {
        form.setFieldsValue({ lettable: props.lettable });
        closeModal();
    };

    const onConfirm = async (_) => {
        const validatedFields = await form.validateFields();
        updateLettableArea(
            {
                commonCostId: commonCostId,
                ...validatedFields,
            },
            propertyId,
            commonCostId, 
            portfolioId
        );
    };

    useEffect(() => {
        form.setFieldsValue({ lettable: props.lettable });
    }, [props.lettable]);

    return (
        <Modal
            wrapClassName="result-modal area-modal"
            footer={null}
            visible={isAreaModalVisible}
            maskClosable={false}
            onCancel={handleClose}
            closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="main">
                    <Form form={form} autoComplete="off">
                        <Row>
                            <Col xs={6}>
                                <p className="font-weight-bold mb-0">{t('commonCost.areaModal.lettable')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Item className="mb-0" name="lettable" initialValue={initialAreaValue}>
                                    <PositiveNumericInput />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="btns">
                    <ActionButton className="btn-secondary mr-3" text={t('common.buttons.cancel')} onClick={handleClose}></ActionButton>
                    <ActionButton className="btn-primary" text={t('common.buttons.confirm')} onClick={onConfirm}></ActionButton>
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ commonCost }) => {
    return {
        lettable: lodash.get(commonCost, 'commonCost.lettable'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        updateLettableArea(data, propertyId, commonCostId, portfolioId) {
            return dispatch(updateLettableArea(data, propertyId, commonCostId, portfolioId));
        },
    };
};

const LettableAreaModal = connect(mapState, mapDispatch)(LettableAreaModalComponent);
export default LettableAreaModal;
