import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Image, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getPropertyDocuments } from '../../../reducers/propertyReducer';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import { downloadDocument as downloadContractFile, downloadWarrantyDocument, openPdfDocument, openWarrantyPdfDocument } from '../../../reducers/contractReducer';
import { downloadBuildingDocument, downloadFile as downloadBuildingPlan, openPdfBuildingDocument } from '../../../reducers/buildingReducer';
import moment from 'moment';
import get from 'lodash-es/get';
import documentImage from '../../../assets/images/document.svg';
import TextEllipsis from '../../../components/ellipsis-text';
import LoaderSpinner from '../../../components/spinner';
import { fileExtensions } from '../../../components/uploaded-file';
import helper from '../../../helpers/helper';
import { pdfExtension } from '../../../common/constants';

const DocumentsComponent = (props) => {
    const { t } = useTranslation();

    const { portfolioId, propertyId } = useParams();

    useEffect(() => {
        if (!props.property.documents) {
            props.getPropertyDocumentsCall(props.property.id, portfolioId);
        }
    }, []);

    const handleDownloadFile = (e, document) => {
        e.preventDefault();

        if (document.warrantyId) {
            if (helper.getFileExtension(document.name) == pdfExtension) {
                props.openWarrantyPdfDocumentCall(document, document.contractId, portfolioId, propertyId);
            } else {
                props.downloadWarrantyFileCall(document, document.contractId, document.name, portfolioId, propertyId);
            }
        } else if (document.contractId) {
            if (helper.getFileExtension(document.name) == pdfExtension) {
                props.openPdfDocumentCall(document, document.contractId, portfolioId);
            } else {
                props.downloadContractFileCall(document, document.contractId, document.name, portfolioId);
            }
        } else {
            if (helper.getFileExtension(document.name) == pdfExtension) {
                props.openBuildingPdfDocumentCall(document, portfolioId, propertyId);
            } else {
                props.downloadBuildingFileCall(document, document.name, portfolioId, propertyId);
            }
        }
    };

    return (
        <React.Fragment>
            {!props.documentsLoading ? (
                props.property.documents && props.property.documents.length > 0 ? (
                    <Col className="p-0" xl={8}>
                        <div className="details-card documents-details">
                            <div className="main">
                                <h5>{t('property.documents.header')}</h5>
                                <div className="d-flex flex-column">
                                    <Row className="m-0 table-header">
                                        <Col className="p-0" sm={4}>
                                            <p className="font-weight-bold">{t('property.documents.title')}</p>
                                        </Col>
                                        <Col className="p-0" sm={4}>
                                            <p className="font-weight-bold">{t('property.documents.uploaded')}</p>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <div className="documents">
                                        {props.property.documents.map((document) => (
                                            <Row className="m-0 document" key={`${document.id}-${document.contractId}`}>
                                                <Col sm={4} className="p-0">
                                                    <div className="d-flex flex-row" style={{ width: 'inherit' }}>
                                                        <Image src={documentImage} />
                                                        <TextEllipsis>
                                                            <p className="ellipsis" data-text={document.name}>
                                                                {document.name}
                                                            </p>
                                                        </TextEllipsis>
                                                    </div>
                                                </Col>
                                                <Col sm={4} className="p-0">
                                                    <p>{moment(document.uploadedDateUtc).format(primaryDateFormat)}</p>
                                                </Col>
                                                <Col sm={4} className="p-0">
                                                    <Link onClick={(e) => handleDownloadFile(e, document)} className="font-weight-bold">
                                                        {t('property.documents.download')}
                                                    </Link>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                ) : (
                    <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                        <h4>{t('property.documents.listIsEmpty')}</h4>
                    </div>
                )
            ) : (
                <LoaderSpinner />
            )}
        </React.Fragment>
    );
};

const mapState = ({ property }) => {
    return {
        property: get(property, 'property'),
        documentsLoading: get(property, 'documentsLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        getPropertyDocumentsCall(propertyId, portfolioId) {
            dispatch(getPropertyDocuments(propertyId, portfolioId));
        },
        downloadContractFileCall(file, contractId, name, portfolioId) {
            return dispatch(downloadContractFile(file, contractId, name, portfolioId));
        },
        openPdfDocumentCall(document, contractId, portfolioId) {
            return dispatch(openPdfDocument(document, contractId, portfolioId));
        },
        downloadBuildingPlanCall(file, propertyId, portfolioId) {
            return dispatch(downloadBuildingPlan(file, propertyId, portfolioId));
        },
        downloadBuildingFileCall(file, contractId, name, portfolioId, propertyId) {
            return dispatch(downloadBuildingDocument(file, contractId, name, portfolioId, propertyId));
        },
        openBuildingPdfDocumentCall(document, portfolioId, propertyId) {
            return dispatch(openPdfBuildingDocument(document, portfolioId, propertyId));
        },
        downloadWarrantyFileCall(file, contractId, name, portfolioId, propertyId) {
            return dispatch(downloadWarrantyDocument(file, contractId, name, portfolioId, propertyId));
        },
        openWarrantyPdfDocumentCall(document, contractId, portfolioId, propertyId) {
            return dispatch(openWarrantyPdfDocument(document, contractId, portfolioId, propertyId));
        },
    };
};

const Documents = connect(mapState, mapDispatch)(DocumentsComponent);
export default Documents;
