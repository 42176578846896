import { Button, Dropdown, Menu, notification } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moreIcon from '../../assets/images/more.svg';
import portfolio from '../../assets/images/new-portfolio-icon.svg';
import CurrencyFormat from '../currency-format';
import TextEllipsis from '../ellipsis-text';
import EditMenuItem from '../menu-items/edit-menu-item';
import ActionButton from '../action-button';
import portfolioService from '../../services/portfolioService';
import { setValuePortfolio } from '../../reducers/portfolioReducer';
import { get } from 'lodash-es';

const zero_rent = 0;

const PortfolioCard = (props) => {
    const { t } = useTranslation();

    const [inviteLoading, setInviteLoading] = useState(false);

    const openNotification = (message, type) => {
        notification[type]({
            message: message,
            placement: 'bottomLeft',
        });
    };

    const openPortfolio = () => {
        if (!props.isInvite) {
            props.openPortfolio(props.portfolio);
        }
    };

    const editPortfolio = () => {
        if (props.editPortfolio) {
            props.editPortfolio(props.portfolio.id);
        }
    };

    const deletePortfolio = () => {
        if (props.deletePortfolio) {
            props.deletePortfolio(props.portfolio.id);
        }
    };

    const acceptPortfolioInvite = async () => {
        try {
            setInviteLoading(true);

            await portfolioService.acceptInvite(props.portfolio.id);
            props.setCurrentPortfoliosCall(
                props.portfolios.map((item) => {
                    if (item.id == props.portfolio.id) {
                        return {
                            ...item,
                            isAccepted: true,
                        };
                    } else {
                        return item;
                    }
                })
            );

            props.acceptInvite(props.portfolio);

            setInviteLoading(false);
            openNotification(t('portfolio.invites.inviteAccepted'), 'success');
        } catch (error) {
            openNotification(t('common.serverErrorMessage'), 'error');
            setInviteLoading(false);
        }
    };

    const declinePortfolioInvite = async () => {
        try {
            setInviteLoading(true);
            await portfolioService.declineInvite(props.portfolio.id);
            props.setCurrentPortfoliosCall(props.portfolios.filter((item) => item.id != props.portfolio.id));
            openNotification(t('portfolio.invites.inviteDeclined'), 'success');
            setInviteLoading(false);
        } catch (error) {
            openNotification(t('common.serverErrorMessage'), 'error');
            setInviteLoading(false);
        }
    };

    const dropdownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" onClick={editPortfolio} disabled={!props.hasEditPermission} text={t('portfolio.card.editPortfolio')} />
                {/*
            // To DO: discuss with customers 
            <DeleteMenuItem
                key='1'
                onClick={deletePortfolio}
                disabled={!props.portfolio.hasEditPermission}
                text={t('portfolio.card.deletePortfolio')} /> */}
            </Menu>
        );
    };

    return (
        <div className="new-portfolio-card">
            <div className="new-portfolio-image" onClick={openPortfolio}>
                <Image src={portfolio} />
            </div>
            <div className="portfolio-info">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <TextEllipsis>
                        <h6 className="ellipsis">{props.portfolio.name}</h6>
                    </TextEllipsis>
                    {!props.isInvite && (
                        <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu(props.portfolio)}>
                            <Button className="btn-link btn-transparent settings-btn">
                                <Image src={moreIcon} />
                            </Button>
                        </Dropdown>
                    )}
                </div>
                {!props.isInvite && (
                    <div className="mt-1">
                        <p className="text-secondary mr-1 mb-0">{`${props.portfolio.propertiesNumber} ${t('portfolio.card.properties')}, ${props.portfolio.activeContracts} ${t(
                            'portfolio.card.contracts'
                        )}`}</p>
                        <p className="text-secondary mb-1">{t('portfolio.card.payment')}</p>
                        <p className="mb-0">{props.portfolio.rentIncome ? <CurrencyFormat value={props.portfolio.rentIncome} /> : zero_rent}</p>
                    </div>
                )}
                {props.isInvite && (
                    <div className="invite-btns">
                        <ActionButton className="btn-primary" onClick={acceptPortfolioInvite} text={t('portfolio.card.acceptInvite')} loading={inviteLoading} />
                        <ActionButton className="btn-secondary" onClick={declinePortfolioInvite} text={t('portfolio.card.declineinvite')} loading={inviteLoading} />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapState = ({ portfolio }) => {
    return {
        portfolios: get(portfolio, 'portfoliosWithDetails'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setCurrentPortfoliosCall(portfolio) {
            dispatch(setValuePortfolio('portfoliosWithDetails', portfolio));
        },
    };
};

export default connect(mapState, mapDispatch)(PortfolioCard);
