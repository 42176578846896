import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { Tabs } from 'antd';
import { InvoiceCard } from './invoice-card';
import { getRentObjectCalculations } from '../../../../reducers/contractReducer';
import routingService from '../../../../services/routingService';
import clear from '../../../../common/clear';
import get from 'lodash-es/get';
import backArrow from '../../../../assets/images/back-arrow.svg';
import MainLayout from '../../../../components/layouts/main-layout';
import EmptyList from '../../../../components/empty-list/empty-list';
import moment from 'moment';
import commonService from '../../../../services/commonService';
import LoaderSpinner from '../../../../components/spinner';

const { TabPane } = Tabs;

export const calculationsTabs = {
    upcomming: '1',
    history: '2',
    getKeyName: function (value) {
        return Object.keys(this).find((key) => this[key] === value || 'upcomming');
    },
};

const RentObjectCalculationsComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId, propertyId, tenantId, contractId, rentObjectId } = useParams();
    const [currentTabKey, setCurrentTabKey] = useState(calculationsTabs.upcomming);
    const [rentObjectSnapshot, setRentObjectSnapshot] = useState();
    const [upCommingInvoices, setUpCommingInvoices] = useState([]);
    const [historyInvoices, setHistoryInvoices] = useState([]);

    useEffect(() => {
        if (contractId && rentObjectId) {
            props.getRentObjectCalculationsCall(rentObjectId, contractId, portfolioId, propertyId);
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(decodeURI(props.location.search));
        const selectedTab = calculationsTabs[params.has('tab') ? params.get('tab') : ''];
        if (selectedTab && currentTabKey !== selectedTab) {
            setCurrentTabKey(selectedTab);
        }
    }, []);

    useEffect(() => {
        if (props.rentObjectPaymentPlans && props?.rentObjectPaymentPlans?.length > 0) {
            let rentObjectPaymentPlan = props?.rentObjectPaymentPlans?.find((item) => item?.rentObjectSnapshot?.rentObjectId == rentObjectId);

            setRentObjectSnapshot(rentObjectPaymentPlan.rentObjectSnapshot);

            setUpCommingInvoices(
                rentObjectPaymentPlan?.paymentPlans
                    .filter((snapshot) => {
                        return moment(snapshot.invoicePeriodStartDate).isSameOrAfter(commonService.todayDate);
                    })
                    .sort((a, b) => moment(a.invoicePeriodStartDate) - moment(b.invoicePeriodStartDate))
            );
            setHistoryInvoices(
                rentObjectPaymentPlan?.paymentPlans
                    .filter((snapshot) => {
                        return moment(snapshot.invoicePeriodStartDate).isBefore(commonService.todayDate);
                    })
                    .sort((a, b) => moment(a.invoicePeriodStartDate) - moment(b.invoicePeriodStartDate))
            );
        }
    }, [props.rentObjectPaymentPlans]);

    const changeTab = (id) => {
        setCurrentTabKey(id);
        const params = new URLSearchParams(props.location.search);
        const selectedTabName = calculationsTabs.getKeyName(id);
        params.set('tab', selectedTabName);
        routingService.replace('?' + params.toString());
    };

    const renderUpcomingInvoicesList = () => {
        return upCommingInvoices?.length > 0 ? (
            upCommingInvoices.map((invoice, index) => <InvoiceCard invoice={invoice} index={index + 1} rentObject={rentObjectSnapshot} />)
        ) : (
            <EmptyList title={t('contract.rentObjectCalculations.upcomming.emptyList.title')} />
        );
    };

    const renderHistoryInvoices = () => {
        return historyInvoices?.length > 0 ? (
            historyInvoices.map((invoice, index) => <InvoiceCard invoice={invoice} index={index + 1} rentObject={rentObjectSnapshot} />)
        ) : (
            <EmptyList title={t('contract.rentObjectCalculations.history.emptyList.title')} />
        );
    };

    return (
        <MainLayout>
            <div className="details-page">
                <div className="title-container">
                    <div className="title">
                        <Link to={routingService.contractRentObjectsUrl(portfolioId, propertyId, tenantId, contractId)}>
                            <Image className="m-0" src={backArrow} />
                        </Link>
                        <h3 className="mb-0">{t('contract.rentObjectCalculations.header')}</h3>
                    </div>
                </div>
                {props.loading ? (
                    <LoaderSpinner />
                ) : (
                    <Tabs activeKey={currentTabKey} onChange={changeTab}>
                        <TabPane tab={t('contract.rentObjectCalculations.upcomming')} key={calculationsTabs.upcomming}>
                            {renderUpcomingInvoicesList()}
                        </TabPane>
                        <TabPane tab={t('contract.rentObjectCalculations.history')} key={calculationsTabs.history}>
                            {renderHistoryInvoices()}
                        </TabPane>
                    </Tabs>
                )}
            </div>
        </MainLayout>
    );
};

const mapState = ({ contract }) => {
    return {
        loading: get(contract, 'getLoading'),
        rentObjectPaymentPlans: get(contract, 'rentObjectPaymentPlans'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        getRentObjectCalculationsCall(contractId, rentObjectId, portfolioId, propertyId) {
            dispatch(getRentObjectCalculations(contractId, rentObjectId, portfolioId, propertyId));
        },
    };
};

const RentObjectCalculations = clear(connect(mapState, mapDispatch)(RentObjectCalculationsComponent));
export default RentObjectCalculations;
