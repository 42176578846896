import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import helper from '../../../helpers/helper';
import { contractStatus, rentObjectRentalTypeOptions, warrantyTypeOptions } from '../../../common/contract-options';
import moment from 'moment';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import { emptyMark, invalidDate } from './rent-roll-table';
import { calculateTotalPropSum } from './export-to-excel';
import download from '../../../assets/images/download.svg';
import { Image } from 'react-bootstrap';
import { calculateContractRentWithExemptions, calculateTotalRentMetersValue, calculateTotalRentUnitsValue } from './rent-roll-total-row';

const pdfColors = {
    red: '#F13F3F',
    green: '#00AE31',
    purple: '#BE7AFF',
    white: '#ffffff',
    orange: '#f88555',
    gray: '#f5f5f5',
    black: '#000000',
    blue: '#8091cf',
};

const ExportToPdf = ({ dataSource, propertyName, currentYear, disabled }) => {
    const { t } = useTranslation();

    const documnetTitle = [
        {
            content: `${propertyName} ${moment().format(primaryDateFormat)} Current year - ${currentYear}`,
            colSpan: 17,
            styles: { halign: 'center', fillColor: pdfColors.white, fontSize: 12, fontStyle: 'bold', textColor: pdfColors.black },
        },
    ];

    const totalTableTitle = [
        {
            content: `${t('rentRoll.totalSection.running')} ${t('rentRoll.totalSection.activeOnly')} ${moment().format(primaryDateFormat)}`,
            colSpan: 16,
            styles: { halign: 'left', fillColor: pdfColors.white, fontSize: 10, fontStyle: 'bold', textColor: pdfColors.blue },
        },
    ];

    const tableHeader = [
        t('rentRoll.header.tenant'),
        t('rentRoll.header.location'),
        t('rentRoll.header.floor'),
        t('rentRoll.header.m2'),
        t('rentRoll.header.unit'),
        t('rentRoll.header.rentM2'),
        t('rentRoll.header.rentUnit'),
        t('rentRoll.header.turnover'),
        t('rentRoll.header.rent'),
        t('rentRoll.header.yearlyRent'),
        t('rentRoll.header.start'),
        t('rentRoll.header.end'),
        t('rentRoll.header.remaining'),
        t('rentRoll.header.cpi'),
        //t('rentRoll.header.durationOprion'),
        'D/O',
        t('rentRoll.header.vat'),
        t('rentRoll.header.warranty'),
    ];

    const totalTableHeader = [
        '',
        '',
        '',
        t('rentRoll.totalSection.m2'),
        t('rentRoll.totalSection.units'),
        t('rentRoll.totalSection.rentM2'),
        t('rentRoll.totalSection.rentUnits'),
        '',
        t('rentRoll.totalSection.rent2023'),
        t('rentRoll.totalSection.contractedYerlyRent'),
        t('rentRoll.totalSection.wault'),
    ];

    const parseNumber = (value) => {
        if (!value) {
            return emptyMark.hyphen;
        }

        return helper.numberWithSpaces(Number.isInteger(value) ? value : value?.toFixed(1));
    };

    const handleDownload = () => {
        const doc = new jsPDF('landscape');

        doc.setFontSize(8);

        const getTotalRow = (contract, additionalProps) => {
            return [
                contract?.name,
                '',
                '',
                parseNumber(calculateTotalPropSum(contract, ['squareMeters'])),
                parseNumber(calculateTotalPropSum(contract, ['units'])),
                parseNumber(calculateTotalRentMetersValue(contract, calculateTotalPropSum(contract, ['rentSquareMetersCpiAdjusted.currentRentCpiAdjusted', 'rentSquareMeters']))),
                parseNumber(calculateTotalRentUnitsValue( contract, calculateTotalPropSum(contract, ['rentUnitsCpiAdjusted.currentRentCpiAdjusted', 'rentUnits']))),
                null,
                parseNumber(calculateContractRentWithExemptions(contract)),
                parseNumber(calculateTotalPropSum(contract, ['contractedRentByYears.yearlyRentPaymentPlanItem.fullRentAmount'])),
                moment(contract?.leaseStartDate).format(primaryDateFormat),
                moment(contract?.leaseEndDate).format(primaryDateFormat),
                parseNumber(contract?.remaining),
                contract?.hasRentAdjustmentOptions ? `${contract?.cpiRate}%` : emptyMark.hyphen,
                t('rentRoll.mix'),
                t('rentRoll.mix'),
                contract?.contractWarranties?.length > 0 ? helper.getEnumValue(warrantyTypeOptions, contract?.contractWarranties[0]?.warrantyTypeId) : emptyMark.hyphen,
                {
                    isTotalRow: true,
                    ...additionalProps,
                },
            ];
        };

        const getTotalSection = () => {
            const rentObjectsTotal = dataSource?.rentObjectsTotal;
            return [
                [
                    t('rentRoll.totalSection.currentTotal'),
                    '',
                    '',
                    parseNumber(rentObjectsTotal?.currentTotalUnits?.squareMeters),
                    parseNumber(rentObjectsTotal?.currentTotalUnits?.units),
                    parseNumber(rentObjectsTotal?.currentTotalRented?.squareMeters),
                    parseNumber(rentObjectsTotal?.currentTotalRented?.units),
                    t('rentRoll.totalSection.m2'),
                    parseNumber(rentObjectsTotal?.rentByYears?.squareMeters),
                    parseNumber(rentObjectsTotal?.contractedRentByYears?.squareMeters),
                    `${parseNumber(rentObjectsTotal?.wault)} years`,
                ],
                [
                    t('rentRoll.totalSection.vacantBta'),
                    '',
                    '',
                    parseNumber(rentObjectsTotal?.vacancy?.vacancy),
                    emptyMark.hyphen,
                    emptyMark.hyphen,
                    emptyMark.hyphen,
                    t('rentRoll.totalSection.units'),
                    parseNumber(rentObjectsTotal?.rentByYears?.units),
                    parseNumber(rentObjectsTotal?.contractedRentByYears?.units),
                    '',
                    '',
                ],
                [
                    t('rentRoll.totalSection.TotalBta'),
                    '',
                    '',
                    parseNumber(rentObjectsTotal?.currentTotalUnits?.squareMeters + rentObjectsTotal?.vacancy?.vacancy),
                    emptyMark.hyphen,
                    emptyMark.hyphen,
                    emptyMark.hyphen,
                    '',
                    parseNumber(rentObjectsTotal?.rentByYears?.squareMeters + rentObjectsTotal?.rentByYears?.units),
                    parseNumber(rentObjectsTotal?.contractedRentByYears?.squareMeters + rentObjectsTotal?.contractedRentByYears?.units),
                ],
            ];
        };

        const tableData = dataSource?.tenants
            ?.map((tenant) => {
                return tenant?.contracts?.map((contract) => {
                    const isContractExpiring = moment(contract?.leaseEndDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'));
                    const isContractStarting = moment(contract?.leaseStartDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'));
                    const isMinimumRent = contract?.rentObjects?.some((rent) => rent?.turnoverId && !!rent?.turnover?.minimunRentYearlyId);

                    return [
                        getTotalRow(contract, {
                            isContractExpiring: isContractExpiring,
                            isContractStarting: isContractStarting,
                        }),
                        ...contract?.rentObjects?.map((rentObject, index, array) => {
                            const isMinimumRentTurnover = rentObject?.turnoverId && !!rentObject?.turnover?.minimunRentYearlyId;
                            const isRentObjectExpiring = moment(rentObject?.leaseEndDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'));
                            const isRentObjectStarting = moment(rentObject?.leaseStartDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'));

                            return [
                                helper.getEnumValue(rentObjectRentalTypeOptions, rentObject?.rentObjectRentalTypeId),
                                rentObject?.buildingId ? rentObject?.building?.address : rentObject?.plot?.address,

                                rentObject?.buildingId
                                    ? rentObject?.building?.floors
                                        ?.filter((floor) => rentObject?.assignedFloorIds?.includes(floor.id))
                                        ?.map((item) => item?.level)
                                        .join(', ')
                                    : emptyMark.hyphen,
                                parseNumber(rentObject?.squareMeters),
                                parseNumber(rentObject?.units),
                                parseNumber(rentObject?.rentSquareMetersCpiAdjusted?.currentRentCpiAdjusted ?? rentObject?.rentSquareMeters),
                                parseNumber(rentObject?.rentUnitsCpiAdjusted?.currentRentCpiAdjusted ?? rentObject?.rentUnits),
                                rentObject?.turnoverId ? rentObject?.turnover?.turnoverRates?.map((item) => `${item?.rate}%`)?.join(' | ') : emptyMark.notApplicable,
                                parseNumber(rentObject?.rentByYears?.yearlyRentPaymentPlanItem?.fullRentAmount),
                                parseNumber(rentObject?.contractedRentByYears?.yearlyRentPaymentPlanItem?.fullRentAmount),
                                rentObject?.leaseStartDate && !moment(rentObject?.leaseStartDate).isSame(invalidDate)
                                    ? moment(rentObject?.leaseStartDate).format(primaryDateFormat)
                                    : moment(contract?.leaseStartDate).format(primaryDateFormat),
                                rentObject?.leaseEndDate && !moment(rentObject?.leaseEndDate).isSame(invalidDate)
                                    ? moment(rentObject?.leaseEndDate).format(primaryDateFormat)
                                    : moment(contract?.leaseEndDate).format(primaryDateFormat),
                                contract?.status == contractStatus.terminated ? `Term. ${moment(contract?.terminatedDate).format(primaryDateFormat)}` : contract?.remaining ? parseNumber(contract?.remaining) : t('rentRoll.expired'),
                                rentObject?.cpiRate ? `${rentObject?.cpiRate}%` : contract?.hasRentAdjustmentOptions ? `${contract?.cpiRate}%` : emptyMark.hyphen,
                                emptyMark.hyphen,
                                rentObject?.hasVatCalculation ? t('common.yes') : t('common.no'),
                                rentObject?.contractWarranties?.length > 0
                                    ? helper.getEnumValue(warrantyTypeOptions, rentObject?.contractWarranties[0]?.warrantyTypeId)
                                    : contract.contractWarranties?.length > 0 ? helper.getEnumValue(warrantyTypeOptions, contract?.contractWarranties[0]?.warrantyTypeId) : emptyMark.hyphen,
                                {
                                    isBodyRow: true,
                                    isContractExpiring: isContractExpiring,
                                    isContractStarting: isContractStarting,
                                    isMinimumRentTurnover: isMinimumRentTurnover,
                                    isRentObjectExpiring: isRentObjectExpiring,
                                    isRentObjectStarting: isRentObjectStarting
                                },
                            ];
                        }),
                        [
                            {
                                isEmptyRow: true,
                                isContractExpiring: isContractExpiring,
                                isContractStarting: isContractStarting,
                                contractEndDate: moment(contract?.leaseEndDate).format(primaryDateFormat),
                                contractStartDate: moment(contract?.leaseStartDate).format(primaryDateFormat),
                                isMinimumRentTurnover: isMinimumRent,
                            },
                        ],
                        [
                            {
                                isEmptyRow: true,
                            },
                        ],
                        ...contract?.rentObjects?.map((rentObject) => {
                            return rentObject?.rentByYears?.rentPaymentPlanExemptionItems?.map((item) => {
                                return [
                                    {
                                        isEmptyRow: true,
                                        isExemptionAmount: true,
                                        exemptionText: `${t('rentRoll.exemptionAmount')} ${moment(item?.startDate).format(primaryDateFormat)} - ${moment(item?.endDate).format(primaryDateFormat)} - ${item?.exemptionAmount?.toFixed(1)} (${helper.getEnumValue(rentObjectRentalTypeOptions, rentObject?.rentObjectRentalTypeId)})`
                                    }
                                ]
                            });
                        }).flat(1)
                    ];
                });
            })
            .flat(1);

        const tableConfig = {
            margin: { left: 5, right: 5, top: 5, bottom: 5 },
            styles: {
                fontSize: 9,
                valign: 'middle',
            },
            pageBreak: 'auto',
            bodyStyles: {
                minCellWidth: 1,
                cellPadding: 1,
            },
            columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 27, overflow: 'ellipsize' },
                2: { cellWidth: 'auto' },
                3: { cellWidth: 'auto' },
                4: { cellWidth: 'auto' },
                5: { cellWidth: 'auto' },
                6: { cellWidth: 'auto' },
                7: { cellWidth: 'auto' },
                8: { cellWidth: 'auto' },
                9: { cellWidth: 'auto' },
                10: { cellWidth: 'auto' },
                11: { cellWidth: 'auto' },
                12: { cellWidth: 20 },
                13: { cellWidth: 10 },
                14: { cellWidth: 10 },
                15: { cellWidth: 10 },
                16: { cellWidth: 'auto' },
                17: { cellWidth: 'auto' },
            },
            didParseCell: (cellData) => {
                const rowProp = cellData?.row?.raw?.at(-1);
                if (rowProp?.isTotalRow) {
                    cellData.cell.styles.fillColor = pdfColors.gray;
                    cellData.cell.styles.fontStyle = 'bold';
                    cellData.cell.styles.noWrap = false;

                    if (
                        rowProp?.isContractExpiring &&
                        (cellData.column.index == 11 ||
                            cellData.column.index == 9 ||
                            cellData.column.index == 8 ||
                            cellData.column.index == 4 ||
                            cellData.column.index == 3 ||
                            cellData.column.index == 0)
                    ) {
                        cellData.cell.styles.textColor = pdfColors.red;
                    } else if (
                        rowProp?.isContractStarting &&
                        (cellData.column.index == 10 ||
                            cellData.column.index == 9 ||
                            cellData.column.index == 8 ||
                            cellData.column.index == 4 ||
                            cellData.column.index == 3 ||
                            cellData.column.index == 0)
                    ) {
                        cellData.cell.styles.textColor = pdfColors.green;
                    }
                } else if (rowProp?.isBodyRow) {
                    cellData.cell.styles.fillColor = pdfColors.white;
                    cellData.cell.styles.noWrap = false;

                    if (rowProp?.isMinimumRentTurnover && (cellData.column.index == 9 || cellData.column.index == 8 || cellData.column.index == 4 || cellData.column.index == 3)) {
                        cellData.cell.styles.textColor = pdfColors.purple;
                    } else if (
                        (rowProp?.isContractExpiring || rowProp?.isRentObjectExpiring) &&
                        (cellData.column.index == 11 || cellData.column.index == 9 || cellData.column.index == 8 || cellData.column.index == 4 || cellData.column.index == 3)
                    ) {
                        cellData.cell.styles.textColor = pdfColors.red;
                    } else if (
                        (rowProp?.isContractStarting || rowProp?.isRentObjectStarting) &&
                        (cellData.column.index == 10 || cellData.column.index == 9 || cellData.column.index == 8 || cellData.column.index == 4 || cellData.column.index == 3)
                    ) {
                        cellData.cell.styles.textColor = pdfColors.green;
                    }
                } else if (rowProp?.isEmptyRow) {
                    cellData.cell.text = [''];
                    cellData.cell.styles.fillColor = pdfColors.white;
                    cellData.row.height = 0;

                    if (rowProp?.isContractExpiring && cellData.column.index == 0) {
                        cellData.cell.text = `* ${t('rentRoll.durationEnd')} ${rowProp.contractEndDate}`;
                        cellData.cell.styles.textColor = pdfColors.red;
                        cellData.cell.colSpan = 2;
                    } else if (rowProp?.isContractStarting && cellData.column.index == 0) {
                        cellData.cell.text = `* ${t('rentRoll.durationStart')} ${rowProp.contractStartDate}`;
                        cellData.cell.styles.textColor = pdfColors.green;
                        cellData.cell.colSpan = 2;
                    }

                    if (rowProp?.isExemptionAmount && cellData.column.index == 0) {
                        cellData.cell.text = rowProp?.exemptionText;
                        cellData.cell.styles.textColor = pdfColors.orange;
                        cellData.cell.colSpan = 5;
                    }

                    if (rowProp?.isMinimumRentTurnover && cellData.column.index == 2) {
                        cellData.cell.text = `* ${t('rentRoll.minimumRentOnly')}`;
                        cellData.cell.styles.textColor = pdfColors.purple;
                        cellData.cell.colSpan = 5;
                    }
                }
            },
        };

        var isFirstPageOnNewPage = true;
        var pageNumber = 1;
        var startY = 5;
        var distanceBetweenTables = 2;

        tableData.forEach((values, index) => {
            doc.autoTable({
                head: [documnetTitle, tableHeader],
                body: values,
                ...tableConfig,
                showHead: isFirstPageOnNewPage ? 'everyPage' : 'never',
                startY: startY + distanceBetweenTables
            });

            if (doc.internal.getCurrentPageInfo().pageNumber != pageNumber) {
                isFirstPageOnNewPage = true;
                pageNumber += 1;
            } else {
                isFirstPageOnNewPage = false;
            }
            startY = doc.autoTable.previous.finalY + distanceBetweenTables;
        });

        doc.autoTable({
            head: [totalTableTitle, totalTableHeader],
            body: getTotalSection(),
            ...tableConfig,
            startY: startY + distanceBetweenTables,
            headStyles: {
                fillColor: pdfColors.orange,
            },
            pageBreak: 'avoid',
            bodyStyles: {
                fillColor: pdfColors.gray,
            },
        });

        doc.save(`${propertyName.replace(/ /g, '-').replace(/[\\/?*\[\]]/g, ' ')}_${moment().format(primaryDateFormat)}.pdf`);
    };

    return (
        <Button className="btn-secondary" onClick={handleDownload} disabled={disabled} >
            <Image src={download} />
            {t('rentRoll.download.pdf')}
        </Button>
    );
};

export default ExportToPdf;
