const routes = {
    login: '/login',
    forgotPassword: '/forgot',
    forgotPasswordInfo: '/info',
    newPassword: '/new-password',
    signup: '/signup',
    register: '/register',
    registered: '/registered',
    invited: '/invited',
    invitationExpired: '/invited/expired/:reason',
    policy: '/policy',
    terms: '/terms',
    storybook: '/storybook',

    home: '/',
    userSettings: '/user-settings',
    createPortfolio: '/create-portfolio',
    editPortfolio: '/edit-portfolio/:portfolioId(\\d+)',
    portfolios: '/portfolios',
    portfolioDashboard: '/portfolio/:portfolioId(\\d+)/dashboard',

    editProperty: '/portfolio/:portfolioId(\\d+)/edit-property/:propertyId(\\d+)',
    createProperty: '/portfolio/:portfolioId(\\d+)/new-property',
    properties: '/portfolio/:portfolioId(\\d+)/properties',
    property: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)',

    createCommonCost: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/new-common-cost',
    editCommonCost: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/edit-common-cost-budget/:commonCostId(\\d+)',
    addCommonCostActuals: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/common-cost/:commonCostId(\\d+)/add-actual',
    commonCostDetails: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/common-cost/:commonCostId(\\d+)',

    createBuilding: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/new-building',
    editBuilding: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/edit-building/:buildingId(\\d+)',
    buildingDetails: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/building-details/:buildingId(\\d+)',

    createPlot: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/new-plot',
    editPlot: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/edit-plot/:plotId(\\d+)',
    plotDetails: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/plot-details/:plotId(\\d+)',

    createTenant: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/new-tenant',
    editTenant: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/edit-tenant/:tenantId(\\d+)/archived/:isArchived',
    tenantDetails: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/tenant/:tenantId(\\d+)',

    createContract: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/tenant/:tenantId(\\d+)/new-contract',
    editContract: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/tenant/:tenantId(\\d+)/edit-contract/:contractId(\\d+)',
    contractDetails: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/tenant/:tenantId(\\d+)/contract/:contractId(\\d+)/archived/:isArchived',
    rentObjectCalculations: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/tenant/:tenantId(\\d+)/contract/:contractId(\\d+)/calculations/:rentObjectId(\\d+)',

    portfolioTenants: '/portfolio/:portfolioId(\\d+)/tenants',
    portfolioContracts: '/portfolio/:portfolioId(\\d+)/contracts',

    translationsManager: '/backoffice/translations',
    translationsImport: '/backoffice/translations/import',
    backOfficeUsers: '/backoffice/users',

    createOwnerCost: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/new-owner-cost',
    editOwnerCost: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/edit-owner-cost-budget/:ownerCostId(\\d+)',
    addOwnerCostActuals: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/owner-cost/:ownerCostId(\\d+)/add-actual',
    ownerCostDetails: '/portfolio/:portfolioId(\\d+)/property/:propertyId(\\d+)/owner-cost/:ownerCostId(\\d+)',
};

export default routes;
