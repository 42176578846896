import i18next from 'i18next';
import { StatusCodes } from 'http-status-codes';
import { setValueProperty } from './propertyReducer';
import toaster from '../common/toaster';
import update from '../helpers/update';
import ownerCostService from '../services/ownerCostService';
import commonCostService from '../services/commonCostService';
import { filterOptions } from '../common/property-options';

const SET_VALUE_OWNER_COST = 'SET_VALUE_OWNER_COST';
const SUCCESS_GET_OWNER_COSTS = 'SUCCESS_GET_OWNER_COSTS';
const CLEAR_DELETE_OWNER_COST_MODEL = 'CLEAR_DELETE_OWNER_COST_MODEL';
const SET_DELETE_OWNER_COST_MODEL = 'SET_DELETE_OWNER_COST_MODEL';

const initialState = {
    ownerCosts: [],
    ownerCostsYears: [],

    ownerCost: null,
    commonCostDetails: null,
    commonCostCalculations: null,
    assignedAssetAreaData: null,

    ownerCostLoading: false,
    commonCostCalculationsLoading: true,
    deleteLoading: false,

    editableOwnerCostMode: false,
    editableOwnerCost: null,

    showConfirmDeleteOwnerCostModal: false,
    showAddAdditionalCostModal: false,
    showFractionModal: false,

    showBudgetsAddResultModal: false,
    showActualsAddResultModal: false,
    showCommonCostUpdatedResultModal: false,

    deleteCommonCostModel: null,

    additionalCostEditModel: null,

    fractionItemEditModel: null,

    showDraftModal: false,
    showActuals: false,
    ownerCostNotFound: false,

    filterOption: filterOptions.active,
};

const ownerCostReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VALUE_OWNER_COST:
            return update.set(state, `${action.payload.key}`, action.payload.value);
        case SUCCESS_GET_OWNER_COSTS:
            return update.set(state, 'ownerCosts', action.payload);
        case CLEAR_DELETE_OWNER_COST_MODEL:
            return update.set(state, 'deleteOwnerCostErrorModel', null);
        case SET_DELETE_OWNER_COST_MODEL:
            return update.set(state, 'deleteOwnerCostModel', action.payload);
        default:
            return state;
    }
};

export const setValueOwnerCost = (key, value) => {
    return {
        type: SET_VALUE_OWNER_COST,
        payload: { key, value },
    };
};

export const successGetOwnerCosts = (value) => {
    return {
        type: SUCCESS_GET_OWNER_COSTS,
        payload: value,
    };
};

export const clearDeleteOwnerCostModel = () => {
    return {
        type: CLEAR_DELETE_OWNER_COST_MODEL,
    };
};

const handleOwnerCostError = (error, dispatch) => {
    if (error && error.response && error.response.status && error.response.status === StatusCodes.NOT_FOUND) {
        dispatch(setValueOwnerCost('ownerCostNotFound', true));
    } else if (error && error.response && error.response.status && error.response.status === StatusCodes.FORBIDDEN) {
        dispatch(setValueProperty('propertyNotFound', true));
    } else if (error && error.response && error.response.title) {
        toaster.error(error.response.title, null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const getListOfOwnerCosts = (portfolioId, propertyId, year) => {
    return (dispatch, getState) => {
        dispatch(setValueOwnerCost('ownerCostLoading', true));
        return ownerCostService
            .getListOfOwnerCosts(portfolioId, propertyId, year, getState().ownerCost.filterOption)
            .then((response) => {
                dispatch(successGetOwnerCosts(response.data));
                const years = [...new Set(response.data.map((x) => x.year))].sort();
                dispatch(setValueOwnerCost('ownerCostsYears', years));
                return true;
            })
            .catch((error) => handleOwnerCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueOwnerCost('ownerCostLoading', false));
            });
    };
};

export const getOwnerCostDetails = (propertyId, ownerCostId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueOwnerCost('ownerCostLoading', true));
        return ownerCostService
            .getOwnerCostDetails(propertyId, ownerCostId, portfolioId)
            .then((response) => {
                dispatch(setValueOwnerCost('ownerCost', response.data.ownerCost));
                dispatch(setValueOwnerCost('ownerCostDetails', response.data.details));
                dispatch(
                    setValueOwnerCost(
                        'ownerCost.ownerCostBudgets',
                        response.data.ownerCost.ownerCostItems.filter((item) => item.isBudget)
                    )
                );
                dispatch(
                    setValueOwnerCost(
                        'ownerCost.ownerCostActuals',
                        response.data.ownerCost.ownerCostItems.filter((item) => !item.isBudget)
                    )
                );
            })
            .catch((error) => handleOwnerCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueOwnerCost('ownerCostLoading', false));
            });
    };
};

export const createOwnerCost = (data) => {
    return (dispatch) => {
        dispatch(setValueOwnerCost('ownerCostLoading', true));
        return ownerCostService
            .create(data)
            .then((response) => {
                return response.data.id;
            })
            .catch((error) => handleOwnerCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueOwnerCost('ownerCostLoading', false));
            });
    };
};

export const updateOwnerCost = (data) => {
    return (dispatch) => {
        dispatch(setValueOwnerCost('ownerCostLoading', true));
        return ownerCostService
            .update(data)
            .then((_) => true)
            .catch((error) => handleOwnerCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueOwnerCost('ownerCostLoading', false));
            });
    };
};

export const deleteOwnerCost = (propertyId, ownerCostId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueOwnerCost('deleteLoading', true));
        return ownerCostService
            .delete(propertyId, ownerCostId, portfolioId)
            .then((_) => true)
            .catch((error) => handleOwnerCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueOwnerCost('deleteLoading', false));
            });
    };
};

export const getOwnerCostById = (portfolioId, propertyId, ownerCostId) => {
    return (dispatch) => {
        dispatch(setValueOwnerCost('ownerCostLoading', true));
        return ownerCostService
            .getOwnerCostById(portfolioId, propertyId, ownerCostId)
            .then((response) => {
                dispatch(setValueOwnerCost('ownerCost', ownerCostService.mapOwnerCostItemNames(response.data)));
                return response.data;
            })
            .catch((error) => handleOwnerCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueOwnerCost('ownerCostLoading', false));
            });
    };
};

export const addOwnerCostActuals = (propertyId, data, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueOwnerCost('ownerCostLoading', true));
        return ownerCostService
            .addActuals(propertyId, data, portfolioId)
            .then((_) => true)
            .catch((error) => handleOwnerCostError(error, dispatch))
            .finally(() => {
                dispatch(setValueOwnerCost('ownerCostLoading', false));
            });
    };
};

export const getAreaForAssignedAsset = (propertyId, plotId, buildingId, portfolioId) => {
    return (dispatch) => {
        commonCostService
            .getAreaForAssignedAsset(propertyId, plotId, buildingId, portfolioId)
            .then((response) => dispatch(setValueOwnerCost('assignedAssetAreaData', response.data)));
    };
};

export default ownerCostReducer;
