import React from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyFormat from '../currency-format';
import PropertyRow from '../property-row';

const CommonCostMergedItemReview = ({ item, goToStep }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {item.budgetItem && (
                <PropertyRow
                    title={`${item.budgetItem.name} ${t('common.budget')}`}
                    value={<CurrencyFormat style={{display: 'inline'}}  value={item.budgetItem.value} />}
                    className="common-cost-review-merged-item"
                />
            )}
            {item.actualItem && (
                <PropertyRow
                    title={`${item.actualItem.name} ${t('common.actual')}`}
                    value={<CurrencyFormat style={{display: 'inline'}} value={item.actualItem.value} />}
                    className="common-cost-review-merged-item"
                />
            )}
        </React.Fragment>
    );
};

export default CommonCostMergedItemReview;
