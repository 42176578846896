import axios from 'axios';

const backofficeService = {
    getUsers: () => {
        return axios({
            method: 'GET',
            url: 'api/contract/backoffice/usermanagement/get-list',
        });
    },
};

export default backofficeService;
