import { Form } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import {
    createContract,
    createContractDraft,
    createRentalObjects,
    deleteContractLeaseDurationOption,
    getContractById,
    setValueContract,
    updateBasicContractDetails,
    updateCommonCostDetails,
    updateContractDurationDetails,
    updateContractDurationDetailsAndCreateRentalObjects,
    updatePaymentTerms,
    updateRentAdjustmentOptions,
    updateRentObjectRentOptions,
    updateRentObjectsCommonCostDetails,
    updateSignatureDate,
    upsertDurationOption,
} from '../../../reducers/contractReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';

import get from 'lodash-es/get';
import clear from '../../../common/clear';
import objectHelper from '../../../helpers/trim-object';
import toaster from '../../../common/toaster';
import InformationComponent from '../../../components/information-container';
import LeaveModal from '../../../components/leave-modal';
import BasicDetails from './basic-details';
import ContractCommonCost from './common-cost';
import ContractNotes from './contract-notes';
import ContractOption from './contract-option';
import DurationAndRentalObjects from './duration-and-rental-objects';
import PaymentTerms from './payment-terms';
import RentAdjustment from './rent-adjustment';
import Signature from './signature';
import moment from 'moment';
import contractReview from '../../../assets/images/contract-review.svg';
import contract from '../../../assets/images/new-design-contract-icon.svg';
import finances from '../../../assets/images/finances.svg';
import settings from '../../../assets/images/settings.svg';
import { notificationTypes } from '../../../common/constants';
import { contractStatus, leaseDurationOptionReminderTypeOptions, leaseDurationOptionTypeOptions, rentObjectType } from '../../../common/contract-options';
import NotFoundPage from '../../../components/error-page';
import NewDesignObjectLayout from '../../../components/layouts/new-design-object-layout';
import LoaderSpinner from '../../../components/spinner';
import routingService from '../../../services/routingService';
import RentExemptions from './rent-exemptions';
import RentalObjectRent from './rental-object-rent';
import ReviewContract from './review';
import Warranty from './warranty';
import ContractStatusSection from './basic-details/contract-status-section';
import RentalObjectsCreate from './create-edit-rent-objects';

export const contractStepNames = {
    setUpYourContract: 'setUpYourContract',
    details: 'details',
    rentObjects: 'rentObjects',
    duration: 'duration',
    rentExemptions: 'rentExemptions',
    rentObjectRent: 'rentObjectRent',
    finances: 'finances',
    commonCost: 'commonCost',
    paymentTerms: 'paymentTerms',
    rentAdjustment: 'rentAdjustment',
    contractOption: 'contractOption',
    signature: 'signature',
    review: 'review',
    advancedOptions: 'advancedOptions',
    warranty: 'warranty',
    status: 'status',
};

const CreateContractComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId, propertyId, tenantId, contractId } = useParams();
    const contractsUrl = routingService.tenantContractsUrl(portfolioId, propertyId, tenantId);

    const detailsRef = useRef();
    const rentalRef = useRef();
    const contractDatesRef = useRef();
    const commonCostRef = useRef();
    const paymentTermsRef = useRef();
    const rentAdjustmentRef = useRef();
    const signatureRef = useRef();
    const rentObjectRentRef = useRef();
    const rentExemptionsRef = useRef();
    const leaseOptionRef = useRef();
    const statusRef = useRef();

    const [currentStepKey, setCurrentStepKey] = useState(contractStepNames.setUpYourContract);
    const [timelineSteps, setTimelineSteps] = useState([]);
    const timelineStepsRef = useRef([]);

    useEffect(() => {
        if (portfolioId && propertyId && tenantId) {
            props.getPropertyCall(portfolioId, propertyId);
            if (contractId) {
                props.getContractByIdCall(contractId, tenantId, propertyId, portfolioId);
            } else {
                props.createContractDraftCall(tenantId, propertyId);
            }
        } else {
            routingService.goTo(contractsUrl);
        }
    }, []);

    useEffect(() => {
        const timelineSteps = steps.map((x) => ({
            key: x.key,
            stepTitle: x.stepTitle,
            isCompleted: isStepCompleted(x.key),
            canSkip: x.canSkip,
            underSteps: x?.underSteps?.map((item) => ({
                key: item.key,
                stepTitle: item.stepTitle,
                isCompleted: isStepCompleted(item.key),
                canSkip: item.canSkip,
            })),
        }));

        setTimelineSteps(timelineSteps);
        timelineStepsRef.current = timelineSteps;
    }, [props.contract, props.rentObjects, props.hasContractOptions]);

    const isStepCompleted = (stepKey) => {
        const contract = props.contract;
        if (!contract) {
            return false;
        }

        switch (stepKey) {
            case contractStepNames.details:
                return contract.name && contract.status > 0;
            case contractStepNames.rentObjects:
                return props.rentObjects && props.rentObjects.filter((ro) => ro.id !== -1).length > 0;
            case contractStepNames.duration:
                return contract.leaseStartDate && contract.leaseEndDate;
            case contractStepNames.rentExemptions:
                return true;
            case contractStepNames.rentObjectRent:
                return props.rentObjects && props.rentObjects.length && props.rentObjects.every((x) => x.rentObjectType && (x.pricePerYearPerUnit || x.turnover));
            case contractStepNames.commonCost:
                return props.rentObjects.every(item => item?.noCommonCost || !!item?.commonCostMeasurements);
            case contractStepNames.paymentTerms:
                return contract.invoiceIssueDateOption > 0 && contract.paymentPlan > 0;
            case contractStepNames.rentAdjustment:
                return props.hasRentAdjustmentOptions
                    ? contract.originalBaseIndexDate && contract.yearlyCpiIndexDate && contract.firstTimeCpiAdjustmentDate && contract.yearlyCpiAdjustmentDate
                    : true;
            case contractStepNames.contractOption:
                return true;
            case contractStepNames.warranty:
                return true;
            case contractStepNames.signature:
                return !!contract.signatureLandLordDate;
            case contractStepNames.status:
                return contract.status !== contractStatus.draft;
        }

        return false;
    };

    const validateRentObjects = () => {

        if (isStepCompleted(contractStepNames.rentObjects) && props.rentObjects.filter((ro) => ro.id == -1).length == 0) {
            return Promise.resolve(true);
        }

        toaster.error(t('contract.notification.addRentalObject'), null);
        return Promise.reject();
    };

    const validateDuration = async () => {
        await validateStep(contractDatesRef);
        await validateStep(leaseOptionRef);
        // Contract dates
        const formDates = contractDatesRef.current.getFieldsValue();
        const dates = { leaseStartDate: formDates.leaseStartDate, leaseEndDate: formDates.leaseEndDate };

        // Dates from form to utc
        const formattedStartDate = dates.leaseStartDate.utc(true);
        const formattedEndDate = dates.leaseEndDate.utc(true);

        // Dates from contract to utc
        const contractLeaseStartDate = moment(props.contract.leaseStartDate).utc(true);
        const contractLeaseEndDate = moment(props.contract.leaseEndDate).utc(true);

        // isEqual
        const sameStartDate = formattedStartDate.isSame(contractLeaseStartDate);
        const sameEndDate = formattedEndDate.isSame(contractLeaseEndDate);

        let isSuccess = true;
        if (!sameStartDate || !sameEndDate) {
            isSuccess = await props.updateContractDurationDetailsCall(dates, props.contract.id, propertyId, portfolioId);
        }

        const leaseDurationOptions = leaseOptionRef.current.getFieldsValue();

        if (leaseDurationOptions.optionToExtend && !!leaseDurationOptions.date) {
            let option = props.leaseDurationOptions.find((o) => o.leaseDurationOptionType === leaseDurationOptionTypeOptions.extendLeaseEndDateTo) || {};

            option.leaseDurationOptionType = leaseDurationOptionTypeOptions.extendLeaseEndDateTo;
            option.date = leaseDurationOptions.date;
            option.optionExpirationDate = leaseDurationOptions.date;
            option.description = leaseDurationOptions.description;

            option.notification =
                (!!leaseDurationOptions.hasReminder && !leaseDurationOptions.optionExpiration) || !leaseDurationOptions.optionExpirationDate
                    ? {
                        ...(!!option?.notification ? option.notification : {}),
                        portfolioId: props.portfolioId,
                        propertyId: props.contract.propertyId,
                        contractId: props.contract.id,
                        notificationTypeId: notificationTypes.ContractLeaseDurationOptions,
                        reminderDate: leaseDurationOptions.reminderType === leaseDurationOptionReminderTypeOptions.date ? leaseDurationOptions.reminderDate : option.date,
                        remindByEmail: leaseDurationOptions.remindByEmail,
                        hasReminder: leaseDurationOptions.hasReminder,
                        reminders:
                            leaseDurationOptions.reminderType === leaseDurationOptionReminderTypeOptions.prior
                                ? [
                                    {
                                        reminderPeriodTypeId: leaseDurationOptions.reminderPriorType,
                                        timeBeforeExpirity: leaseDurationOptions.reminderValue,
                                    },
                                ]
                                : [],
                    }
                    : null;

            await props.upsertDurationOptionCall(option, props.contract.id, propertyId, portfolioId);
        } else {
            let option = props.leaseDurationOptions.find((o) => o.leaseDurationOptionType === leaseDurationOptionTypeOptions.extendLeaseEndDateTo);

            if (option?.id) {
                await props.deleteContractLeaseDurationOptionCall(option.id, props.contract.id, propertyId, portfolioId);
            }
        }

        if (leaseDurationOptions.optionExpiration && !!leaseDurationOptions.optionExpirationDate) {
            let option = props.leaseDurationOptions.find((o) => o.leaseDurationOptionType === leaseDurationOptionTypeOptions.terminatePriorEndDateTo);
            let options = option === undefined ? {} : { ...option };

            options.leaseDurationOptionType = leaseDurationOptionTypeOptions.terminatePriorEndDateTo;
            options.date = leaseDurationOptions?.optionExpirationDate;
            options.optionExpirationDate = leaseDurationOptions?.optionExpirationDate;
            options.description = leaseDurationOptions?.description;

            options.notification = !!leaseDurationOptions.hasReminder
                ? {
                    ...(!!options?.notification ? options.notification : {}),
                    portfolioId: props.portfolioId,
                    propertyId: props.contract.propertyId,
                    contractId: props.contract.id,
                    notificationTypeId: notificationTypes.ContractLeaseDurationOptions,
                    reminderDate: leaseDurationOptions.reminderType === leaseDurationOptionReminderTypeOptions.date ? options.reminderDate : options.date,
                    remindByEmail: leaseDurationOptions.remindByEmail,
                    hasReminder: leaseDurationOptions.hasReminder,
                    reminders:
                        leaseDurationOptions.reminderType === leaseDurationOptionReminderTypeOptions.prior
                            ? [
                                {
                                    reminderPeriodTypeId: leaseDurationOptions.reminderPriorType,
                                    timeBeforeExpirity: leaseDurationOptions.reminderValue,
                                },
                            ]
                            : [],
                }
                : null;

            await props.upsertDurationOptionCall(options, props.contract.id, propertyId, portfolioId);
        } else {
            let option = props.leaseDurationOptions.find((o) => o.leaseDurationOptionType === leaseDurationOptionTypeOptions.terminatePriorEndDateTo);

            if (option?.id) {
                await props.deleteContractLeaseDurationOptionCall(option.id, props.contract.id, propertyId, portfolioId);
            }
        }
        return Promise.resolve(isSuccess);
    };

    const validateBasic = () => {
        return validateStep(detailsRef).then((_) => {
            const contract = detailsRef.current.getFieldsValue();
            let contractObject = objectHelper.trimObjectProperties(contract);

            if (!('status' in contract)) {
                contractObject = { ...contractObject, status: contractStatus.draft };
            }

            return props.updateBasicContractDetailsCall(contractObject, props.contract.id, propertyId, portfolioId);
        });
    };

    const validateStatus = () => {
        return validateStep(statusRef).then((_) => {
            const status = statusRef.current.getFieldsValue();

            return props.updateBasicContractDetailsCall(
                {
                    status: status.status,
                    name: props.contract.name,
                },
                props.contract.id,
                propertyId,
                portfolioId
            );
        });
    };

    const validateFinances = () => {
        return validateStep(rentObjectRentRef).then((_) => {
            const rentObjectsFinance = props.rentObjects.map((rentObject) => {
                return {
                    rentObjectId: rentObject.id,
                    rentObjectType: rentObject.rentObjectType,
                    pricePerYearPerUnit: rentObject.pricePerYearPerUnit,
                    totalPricePerYear: rentObject.totalPricePerYear,
                };
            });
            return props.updateRentObjectRentOptionsCall(rentObjectsFinance, props.contract.id, portfolioId, propertyId);
        });
    };

    const validateCommonCost = () => {
        props.commonCostOptions.map(commonCost => {
            props.updateRentObjectCommonCostDetailsCall(commonCost, props.contract.id, portfolioId, propertyId)
        })

        return Promise.resolve();
    };

    const validatePaymentTerms = () => {
        return validateStep(paymentTermsRef).then((_) => {
            const paymentTerms = paymentTermsRef.current.getFieldsValue();
            paymentTerms.contractId = props.contract.id;
            paymentTerms.firstInvoiceDueDate = paymentTerms.firstInvoiceDueDate.startOf('day').utc(true);
            paymentTerms.rentInvoicedFromDate = paymentTerms.rentInvoicedFromDate.startOf('day').utc(true);
            return props.updatePaymentTermsCall(paymentTerms, props.contract.id, propertyId, portfolioId);
        });
    };

    const validateRentAdjustment = () => {
        return validateStep(rentAdjustmentRef).then((_) => {
            const rentAdjustment = rentAdjustmentRef.current.getFieldsValue();
            if (rentAdjustment.hasRentAdjustmentOptions) {
                rentAdjustment.originalBaseIndexDate = rentAdjustment.originalBaseIndexDate.startOf('day').utc(true);
                rentAdjustment.yearlyCpiIndexDate = rentAdjustment.yearlyCpiIndexDate.startOf('day').utc(true);
                rentAdjustment.firstTimeCpiAdjustmentDate = rentAdjustment.firstTimeCpiAdjustmentDate.startOf('day').utc(true);
                rentAdjustment.yearlyCpiAdjustmentDate = rentAdjustment.yearlyCpiAdjustmentDate.utc(true);
            }
            return props.updateRentAdjustmentOptionsCall(rentAdjustment, props.contract.id, propertyId, portfolioId);
        });
    };

    const validateSignature = () => {
        return validateStep(signatureRef).then((_) => {
            const signature = signatureRef.current.getFieldsValue();
            if (signature.sameDateForSignature) {
                signature.signatureLandLordDate = signature.signatureLandLordDate.startOf('day').utc(true);
                signature.signatureTenantDate = signature.signatureLandLordDate.startOf('day').utc(true);
            } else {
                signature.signatureLandLordDate = signature.signatureLandLordDate.startOf('day').utc(true);
                signature.signatureTenantDate = signature.signatureTenantDate.startOf('day').utc(true);
            }
            const signatureObject = objectHelper.trimObjectProperties(signature);
            return props.updateSignatureDateCall(signatureObject, props.contract.id, propertyId, portfolioId);
        });
    };

    const validateRentObjectRent = () => {
        return validateStep(rentObjectRentRef)
            .then((_) => validateTurnovers())
            .then((_) => {
                const rentObjectsFinance = props.rentObjects.map((rentObject) => {
                    const turnover = props.turnovers.find((item) => item.rentObjectId == rentObject.id);
                    return {
                        rentObjectId: rentObject.id,
                        rentObjectType: rentObject.rentObjectType,
                        pricePerYearPerUnit: rentObject.pricePerYearPerUnit,
                        totalPricePerYear: rentObject.totalPricePerYear,
                        hasVatCalculation: rentObject.hasVatCalculation,
                        turnover: rentObject.rentObjectType == rentObjectType.turnoverRentalObject ? (turnover ? turnover : rentObject?.turnover) : undefined,
                    };
                });
                return props.updateRentObjectRentOptionsCall(rentObjectsFinance, props.contract.id, portfolioId, propertyId);
            });
    };

    const validateTurnovers = () => {
        const rentStatuses = props.rentObjects.map((rentObject) => {
            const turnover = props.turnovers.find((item) => item.rentObjectId == rentObject.id);

            if (rentObject.rentObjectType == rentObjectType.turnoverRentalObject) {
                return !!turnover || rentObject.turnover ? true : false;
            }

            return true;
        });

        if (!rentStatuses.some((item) => item == false)) {
            return Promise.resolve(true);
        }
        toaster.error('contract.turnover.addTurnoverNotification', null);
        return Promise.reject();
    };

    const onStepChanging = async (_) => {
        switch (currentStepKey) {
            case contractStepNames.details: {
                await validateBasic();
                await validateRentObjects();
                return await validateDuration();
            }
            case contractStepNames.duration: {
                return validateDuration().catch((_) => { });
            }
            case contractStepNames.rentObjectRent: {
                return await validateRentObjectRent();
            }
            case contractStepNames.durationAndRentalObjects: {
                return validateDuration().catch((_) => { });
            }
            case contractStepNames.finances: {
                await validateCommonCost();
                await validatePaymentTerms();
                await validateRentAdjustment();
                return await validateFinances();
            }
            case contractStepNames.commonCost: {
                return validateCommonCost().catch((_) => { });
            }
            case contractStepNames.paymentTerms: {
                return validatePaymentTerms().catch((_) => { });
            }
            case contractStepNames.rentAdjustment: {
                return validateRentAdjustment().catch((_) => { });
            }
            case contractStepNames.contractOption: {
                return validateSignature().catch((_) => { });
            }
            default:
                return Promise.resolve(true);
        }
    };

    const onNext = (_) => {
        const currentStepIndex = timelineSteps.findIndex((x) => x.key === currentStepKey);
        const nextStep = timelineSteps[currentStepIndex + 1];

        if (!nextStep) {
            return;
        }

        onStepChanging().then((isSuccess) => {
            if (isSuccess) {
                setCurrentStepKey(nextStep.key);
                scrollToTop();
            }
        });
    };

    const onPrevious = (previousStep) => {
        if (previousStep) {
            setCurrentStepKey(previousStep.key);
        } else {
            const currentStepIndex = timelineSteps.findIndex((x) => x.key === currentStepKey);
            const previousStep = timelineSteps[currentStepIndex - 1];
            previousStep && setCurrentStepKey(previousStep.key);
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = timelineSteps.findIndex((x) => x.key === currentStepKey);
        const newIndex = timelineSteps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious(newStep);
        } else {
            onStepChanging().then((isSuccess) => {
                if (isSuccess) {
                    onChangeStep(newStep.key);
                }
            });
        }
    };

    const onChangeStep = (newStepKey) => {
        const timelineStepsByRef = timelineStepsRef.current;
        const newIndex = timelineStepsByRef.findIndex((x) => x.key === newStepKey);
        const previousSteps = timelineStepsByRef.slice(0, newIndex);
        const isPreviousStepsAllCompleted = previousSteps.every((x) => x.isCompleted || x.canSkip);

        if (isPreviousStepsAllCompleted) {
            setCurrentStepKey(newStepKey);
        } else {
            const firstInCompletedStep = timelineStepsByRef.find((x) => !x.isCompleted || x.canSkip);
            firstInCompletedStep && setCurrentStepKey(firstInCompletedStep.key);
        }
    };

    // Validation methods
    const validateDurationAndRentalObjectStep = (_) => {
        return contractDatesRef.current.validateFields().then((result) => {
            if (result) {
                return validateRentalObjectRef();
            } else {
                Promise.reject();
            }
        });
    };

    const validateRentalObjectRef = (_) => {
        if (props.showRentObjectForm) {
            return rentalRef.current.validateFields();
        } else {
            return Promise.resolve();
        }
    };

    const validateStep = (formRef) => {
        if (formRef) {
            return formRef.current.validateFields();
        }

        return Promise.resolve();
    };

    const onSaveAndExit = (_) => {
        const onSuccess = (isSuccess) => {
            isSuccess && goOutOfPage();
        };

        if (currentStepKey === contractStepNames.review) {
            return props.createContractCall(props.contract.id, props.contract.propertyId, portfolioId).then(onSuccess);
        } else {
            return onStepChanging().then(onSuccess);
        }
    };

    const onSave = () => {
        return props.createContractCall(props.contract.id, props.contract.propertyId, portfolioId).then((isSuccess) => {
            isSuccess && goOutOfPage();
        });
    };

    const goOutOfPage = (_) => {
        routingService.goTo(contractsUrl);
    };

    const onOpenCloseDraftModal = (_) => {
        props.setValueContractCall('showDraftModal', !props.showDraftModal);
    };

    const scrollToTop = (_) => {
        window && window.scrollTo(0, 0);
    };

    const steps = [
        {
            key: contractStepNames.setUpYourContract,
            sectionTitle: t('contract.setUpYourContract.title'),
            logo: contract,
            underSteps: [
                {
                    key: contractStepNames.setUpYourContract,
                    header: t('contract.details.header'),
                    stepTitle: t('contract.details.stepTitle'),
                    validate: validateBasic,
                    isStepCompleted: () => isStepCompleted(contractStepNames.details),
                    content: (
                        <Form ref={detailsRef} name="basic-details" autoComplete="off">
                            <BasicDetails detailsRef={detailsRef} />
                        </Form>
                    ),
                },
                {
                    key: contractStepNames.rentObjects,
                    header: t('contract.rentalObjects.header'),
                    stepTitle: t('contract.rentalObjects.header'),
                    validate: validateRentObjects,
                    dontShowHeader: true,
                    isStepCompleted: () => isStepCompleted(contractStepNames.rentObjects),
                    content: <RentalObjectsCreate isValidRentalObject={validateRentalObjectRef} />,
                },
                {
                    key: contractStepNames.duration,
                    header: t('contract.duration.header'),
                    stepTitle: t('contract.duration.header'),
                    validate: validateDuration,
                    isStepCompleted: () => isStepCompleted(contractStepNames.duration),
                    content: (
                        <DurationAndRentalObjects
                            contractDatesRef={contractDatesRef}
                            rentalRef={rentalRef}
                            leaseOptionRef={leaseOptionRef}
                            isValidRentalObject={validateRentalObjectRef}
                        />
                    ),
                },
            ],
        },
        {
            key: contractStepNames.finances,
            sectionTitle: t('contract.rentalCost.title'),
            logo: finances,
            underSteps: [
                {
                    key: contractStepNames.finances,
                    header: t('contract.rentalObjectRent.header'),
                    stepTitle: t('contract.rentalObjectRent.header'),
                    validate: validateRentObjectRent,
                    isStepCompleted: () => isStepCompleted(contractStepNames.rentObjectRent),
                    content: (
                        <Form ref={rentObjectRentRef} name="rent-object-rent-form" autoComplete="off">
                            <RentalObjectRent financesRef={rentObjectRentRef} />
                        </Form>
                    ),
                },
                {
                    key: contractStepNames.rentExemptions,
                    header: t('contract.rentExemptions.header'),
                    stepTitle: t('contract.rentExemptions.header'),
                    validate: () => Promise.resolve(),
                    isOptional: true,
                    isStepCompleted: () => isStepCompleted(contractStepNames.rentExemptions),
                    content: (
                        <Form ref={rentExemptionsRef} name="rent-exemptions-form" autoComplete="off">
                            <RentExemptions financesRef={rentExemptionsRef} />
                        </Form>
                    ),
                },
                {
                    key: contractStepNames.paymentTerms,
                    header: t('contract.paymentTerms.header'),
                    stepTitle: t('contract.paymentTerms.header'),
                    validate: validatePaymentTerms,
                    isStepCompleted: () => isStepCompleted(contractStepNames.paymentTerms),
                    content: <PaymentTerms paymentTermsRef={paymentTermsRef} />,
                },
                {
                    key: contractStepNames.commonCost,
                    header: t('contract.commonCost.header'),
                    stepTitle: t('contract.commonCost.header'),
                    validate: validateCommonCost,
                    isStepCompleted: () => isStepCompleted(contractStepNames.commonCost),
                    content: <ContractCommonCost commonCostRef={commonCostRef} />,
                },
                {
                    key: contractStepNames.rentAdjustment,
                    header: t('contract.rentAdjustment.header'),
                    stepTitle: t('contract.rentAdjustment.header'),
                    validate: validateRentAdjustment,
                    isStepCompleted: () => isStepCompleted(contractStepNames.rentAdjustment),
                    content: <RentAdjustment rentAdjustmentRef={rentAdjustmentRef} />,
                },
            ],
        },
        {
            key: contractStepNames.advancedOptions,
            sectionTitle: t('contract.advancedOptionsAndReview.title'),
            logo: settings,
            canSkip: true,
            underSteps: [
                {
                    key: contractStepNames.advancedOptions,
                    header: t('contract.warranty.header'),
                    stepTitle: t('contract.warranty.header'),
                    validate: () => Promise.resolve(),
                    isOptional: true,
                    isStepCompleted: () => isStepCompleted(contractStepNames.warranty),
                    content: <Warranty />,
                },
                {
                    key: contractStepNames.contractOption,
                    header: t('contract.options.header'),
                    stepTitle: t('contract.options.header'),
                    validate: () => Promise.resolve(),
                    isOptional: true,
                    isStepCompleted: () => isStepCompleted(contractStepNames.contractOption),
                    content: <ContractOption />,
                },
                {
                    key: contractStepNames.signature,
                    header: t('contract.signature.header'),
                    stepTitle: t('contract.signature.header'),
                    validate: validateSignature,
                    isStepCompleted: () => isStepCompleted(contractStepNames.signature),
                    content: (
                        <Form ref={signatureRef} name="signature" autoComplete="off">
                            <Signature signatureRef={signatureRef} />
                        </Form>
                    ),
                },
                props.contract?.status == contractStatus.draft && {
                    key: contractStepNames.status,
                    header: t('contract.status.header'),
                    stepTitle: t('contract.status.header'),
                    validate: validateStatus,
                    isStepCompleted: () => isStepCompleted(contractStepNames.status),
                    content: (
                        <Form ref={statusRef} name="status" autoComplete="off">
                            <ContractStatusSection statusRef={statusRef} />
                        </Form>
                    ),
                },
                {
                    key: contractStepNames.review,
                    header: t('contract.review.header'),
                    stepTitle: t('contract.review.header'),
                    isStepCompleted: () => !props.contract.isDraft,
                    logo: contractReview,
                    content: <ReviewContract goToStep={setCurrentStepKey} />,
                },
            ].filter(Boolean),
        },
    ];

    return (
        <React.Fragment>
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.tenantNotFound ? <NotFoundPage header={t('tenant.notFound')} /> : null}
            {props.contractNotFound ? <NotFoundPage header={t('contract.notFoundContract')} /> : null}
            {!props.portfolioNotFound && !props.tenantNotFound && !props.propertyNotFound && !props.contractNotFound && props.contract ? (
                <NewDesignObjectLayout
                    steps={steps}
                    className="contract-create"
                    timelineSteps={timelineSteps}
                    usingDraft={true}
                    exitUrl={contractsUrl}
                    currentStepKey={currentStepKey}
                    saveBtnLoading={props.updateDurationAndCreationRentalObjectsLoading || props.updateDetailsLoading || props.crudLoading}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onSwitchStep={onSwitchStep}
                    onSaveAndExit={onSave}
                    onCancel={onOpenCloseDraftModal}
                    setCurrentStepKey={setCurrentStepKey}
                    isNewDesign={true}
                    moveToStepName={props.moveToStepName}
                    bottomRightSideBar={<ContractNotes />}
                    topRightSideBar={
                        <React.Fragment>{<InformationComponent showImage={false} title={t('contract.information.title')} text={t('contract.information.text')} />}</React.Fragment>
                    }
                />
            ) : (
                <LoaderSpinner />
            )}
            {props.showDraftModal && <LeaveModal onCancel={onOpenCloseDraftModal} onLeave={goOutOfPage} onSave={onSaveAndExit} loading={props.crudLoading} />}
        </React.Fragment>
    );
};

const mapState = ({ contract, tenant, property, portfolio, router }) => {
    return {
        contract: get(contract, 'contract'),
        rentObject: get(contract, 'rentObject'),
        getLoading: get(contract, 'getLoading'),
        rentObjects: get(contract, 'rentObjects'),
        hasContractOptions: get(contract, 'contractOptions.length') > 0,
        leaseDurationOptions: get(contract, 'leaseDurationOptions'),
        crudLoading: get(contract, 'crudLoading'),
        showDraftModal: get(contract, 'showDraftModal'),
        showRentObjectForm: get(contract, 'showRentObjectForm'),
        updateDetailsLoading: get(contract, 'updateDetailsLoading'),
        updateDurationAndCreationRentalObjectsLoading: get(contract, 'updateDurationAndCreationRentalObjectsLoading'),
        tenantNotFound: get(tenant, 'tenantNotFound'),
        contractNotFound: get(contract, 'contractNotFound'),
        portfolioId: get(property, 'property.portfolioId'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
        moveToStepName: get(router, 'location.state.moveToStepName'),
        turnovers: get(contract, 'turnovers'),
        commonCostOptions: get(contract, 'commonCostOptions')
    };
};

const mapDispatch = (dispatch) => {
    return {
        createContractCall(id, propertyId, portfolioId) {
            return dispatch(createContract(id, propertyId, portfolioId));
        },
        createContractDraftCall(tenantId, propertyId) {
            return dispatch(createContractDraft(tenantId, propertyId));
        },
        updateBasicContractDetailsCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateBasicContractDetails(data, contractId, propertyId, portfolioId));
        },
        updateContractDurationDetailsAndCreateRentalObjectsCall(contractDuration, rentObjects, contractId, propertyId, portfolioId) {
            return dispatch(updateContractDurationDetailsAndCreateRentalObjects(contractDuration, rentObjects, contractId, propertyId, portfolioId));
        },
        createRentalObjectsCall(rentObjects, contractId) {
            return dispatch(createRentalObjects(rentObjects, contractId));
        },
        updateContractDurationDetailsCall(contractDuration, contractId, propertyId, portfolioId) {
            return dispatch(updateContractDurationDetails(contractDuration, contractId, propertyId, portfolioId));
        },
        updateRentObjectRentOptionsCall(data, contractId, portfolioId, propertyId) {
            return dispatch(updateRentObjectRentOptions(data, contractId, portfolioId, propertyId));
        },
        updateCommonCostDetailsCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateCommonCostDetails(data, contractId, propertyId, portfolioId));
        },
        updatePaymentTermsCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updatePaymentTerms(data, contractId, propertyId, portfolioId));
        },
        updateRentAdjustmentOptionsCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateRentAdjustmentOptions(data, contractId, propertyId, portfolioId));
        },
        updateSignatureDateCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateSignatureDate(data, contractId, propertyId, portfolioId));
        },
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
        getContractByIdCall(id, tenantId, propertyId, portfolioId) {
            dispatch(getContractById(id, tenantId, propertyId, portfolioId));
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        upsertDurationOptionCall(data, contractId, propertyId, portfolioId) {
            return dispatch(upsertDurationOption(data, contractId, propertyId, portfolioId));
        },
        deleteContractLeaseDurationOptionCall(optionId, contractId, propertyId, portfolioId) {
            return dispatch(deleteContractLeaseDurationOption(optionId, contractId, propertyId, portfolioId));
        },
        updateRentObjectCommonCostDetailsCall(value, contractId, portfolioId, propertyId) {
            return dispatch(updateRentObjectsCommonCostDetails(value, contractId, portfolioId, propertyId));
        },
    };
};

const CreateContract = clear(connect(mapState, mapDispatch)(CreateContractComponent));
export default CreateContract;
