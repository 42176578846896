import { Divider } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CommonCostItemReview from '../../../components/common-cost/cost-item-review';
import CurrencyFormat from '../../../components/currency-format';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import NumberFormat from '../../../components/number-format';
import PropertyRow from '../../../components/property-row';
import SuccessMessage from '../../../components/success-message';
import { ownerCostSelector, selectFilteredBudgetCosts } from '../selectors';

const OwnerCostBudgetsReview = (props) => {
    const { t } = useTranslation();
    const plotId = useSelector(({ ownerCost }) => ownerCost.ownerCost.plotId);
    const ownerCost = useSelector(ownerCostSelector);
    const ownerCostBudgets = useSelector(selectFilteredBudgetCosts);
    const propertyName = useSelector(({ property }) => property.property.propertyName);

    const goToStep = (stepKey) => {
        props.goToStep(stepKey);
    };

    const totalBudget = ownerCostBudgets.map((item) => item.value).reduce((a, b) => a + b, 0);
    const showBudgetAdministrationFeePercent = ownerCost.budgetAdministrationFeePercent !== null && ownerCost.budgetAdministrationFeePercent !== undefined;
    const showBudgetAdministrationFee = ownerCost.budgetAdministrationFee !== null && ownerCost.budgetAdministrationFee !== undefined;

    return (
        <div className="page-container review">
            <div className="review-part" key={'details'}>
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                    <h5>{t('commonCost.review.costDetails')}</h5>
                </div>
                <PropertyRow title={t('commonCost.review.costDetails.costName')} value={ownerCost.name} className="common-cost-review-details-item" />
                <PropertyRow
                    title={t('commonCost.review.costDetails.costPeriod')}
                    value={`${moment(ownerCost.startDate).format(primaryDateFormat)} - ${moment(ownerCost.endDate).format(primaryDateFormat)}`}
                    className="common-cost-review-details-item"
                />
                <PropertyRow title={t('commonCost.review.costDetails.assignedTo')} value={propertyName} className="common-cost-review-details-item" />
                <PropertyRow
                    title={t('commonCost.review.costDetails.grossArea')}
                    value={`${ownerCost.grossArea} ${!plotId ? t('commonCost.tenantCard.m2') : ''}`}
                    className="common-cost-review-details-item"
                    style={{ marginBottom: '45px' }}
                />
                <div className="review-items-wrapper">
                    {ownerCostBudgets.map((cost) => (
                        <CommonCostItemReview key={cost.name} cost={cost} goToStep={goToStep} />
                    ))}
                    <Divider className="common-cost-items-review-divider" />
                    {showBudgetAdministrationFeePercent && (
                        <PropertyRow
                            title={`${t('commonCost.costs.administrationFees')} ${t('common.budget')}`}
                            value={<NumberFormat value={ownerCost.budgetAdministrationFeePercent} suffix={'%'} />}
                            className="common-cost-review-merged-item"
                        />
                    )}
                    {showBudgetAdministrationFee && (
                        <PropertyRow
                            title={`${t('commonCost.costs.administrationFees')} ${t('common.budget')}`}
                            value={<CurrencyFormat value={ownerCost.budgetAdministrationFee} />}
                            className="common-cost-review-merged-item"
                        />
                    )}
                    <PropertyRow title={t('commonCost.review.costDetails.total')} value={<CurrencyFormat value={totalBudget} />} className="common-cost-review-merged-item" />
                </div>
                <SuccessMessage textElements={<><p className="font-weight-bold">{t('commonCost.review.successMessage.looksGood')}</p><p>{t('commonCost.review.successMessage.notification')}</p></>}/>
            </div>
        </div>
    );
};

export default OwnerCostBudgetsReview;
