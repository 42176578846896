import get from 'lodash-es/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { typeOfYearlyRentOptions } from '../../../../common/contract-options';
import { setPropertyToRentalObject, setValueContract } from '../../../../reducers/contractReducer';
import RentalObjectFinanceCard from './rent-finances-card';

const RentalObjectRentComponent = (props) => {
    const { t } = useTranslation();

    const setTypeOfYearlyRent = (key, type, value) => {
        const rentalObjectIndex = [...props.rentObjects].findIndex((x) => x.uId === key);
        const rentObject = [...props.rentObjects].find((x) => x.uId === key);
        props.setPropertyToRentalObjectCall(rentalObjectIndex, 'typeOfYearlyRent', value);

        if (type === typeOfYearlyRentOptions.perUnit) {
            const totalPricePerYear = +(value * rentObject.amount).toFixed(2);
            if (value) {
                props.setPropertyToRentalObjectCall(rentalObjectIndex, 'totalPricePerYear', totalPricePerYear);
            }
            props.setPropertyToRentalObjectCall(rentalObjectIndex, 'pricePerYearPerUnit', value);

            const rentObjectForm = {};
            rentObjectForm[`pricePerYearPerUnit-${key}`] = value;
            rentObjectForm[`totalPricePerYear-${key}`] = totalPricePerYear;
            props.financesRef.current.setFieldsValue(rentObjectForm);
        } else if (type === typeOfYearlyRentOptions.totatAmount) {
            const pricePerYearPerUnit = +(value / rentObject.amount).toFixed(2);
            if (value) {
                props.setPropertyToRentalObjectCall(rentalObjectIndex, 'pricePerYearPerUnit', pricePerYearPerUnit);
            }
            props.setPropertyToRentalObjectCall(rentalObjectIndex, 'totalPricePerYear', value);

            const rentObjectForm = {};
            rentObjectForm[`pricePerYearPerUnit-${key}`] = pricePerYearPerUnit;
            rentObjectForm[`totalPricePerYear-${key}`] = value;
            props.financesRef.current.setFieldsValue(rentObjectForm);
        }
    };

    const setRentObjectType = (key, value) => {
        const rentalObjectIndex = [...props.rentObjects].findIndex((x) => x.uId === key);
        props.setPropertyToRentalObjectCall(rentalObjectIndex, 'rentObjectType', value);
    };

    const setHasVatCalculation = (key, value) => {
        const rentalObjectIndex = [...props.rentObjects].findIndex((x) => x.uId === key);
        props.setPropertyToRentalObjectCall(rentalObjectIndex, 'hasVatCalculation', value);
    };

    return (
        <React.Fragment>
            <div className="page-container pb-0">
                <h5>{t('contract.finances.title')}</h5>
                {props.rentObjects &&
                    props.rentObjects.map((rentObject) => (
                        <RentalObjectFinanceCard
                            key={rentObject.uId}
                            rentObject={rentObject}
                            setHasVatCalculation={setHasVatCalculation}
                            setTypeOfYearlyRent={setTypeOfYearlyRent}
                            setRentObjectType={setRentObjectType}
                        />
                    ))}
            </div>
        </React.Fragment>
    );
};

const mapState = ({ contract }) => {
    return {
        rentObjects: get(contract, 'rentObjects'),
        showRentExemptionModal: get(contract, 'showRentExemptionModal'),
        showStairRentIntervalModal: get(contract, 'showStairRentIntervalModal'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setPropertyToRentalObjectCall(id, name, value) {
            dispatch(setPropertyToRentalObject(id, name, value));
        },
        openRentExemptionModalWindowCall() {
            dispatch(setValueContract('showRentExemptionModal', true));
        },
        openStairRentIntervalModalWindowCall() {
            dispatch(setValueContract('showStairRentIntervalModal', true));
        },
    };
};

const RentalObjectRent = connect(mapState, mapDispatch)(RentalObjectRentComponent);
export default RentalObjectRent;
