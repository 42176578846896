import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash-es';
import { connect } from 'react-redux';
import { Form, Modal, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { setValueContract } from '../../../reducers/contractReducer';
import ActionButton from '../../../components/action-button';
import LoaderSpinner from '../../../components/spinner';

const { Option } = Select;

const ModalForContractCreationComponent = (props) => {
    const { t } = useTranslation();
    const formRef = React.useRef();

    const [property, setProperty] = useState();
    const [tenant, setTenant] = useState();
    const [tenantsByProperty, setTenants] = useState([]);

    const onSelectProperty = (property) => {
        setProperty(property);
        const tenants = props.tenants.filter((x) => x.propertyId === property);
        if (tenants.length > 0) {
            setTenants(tenants);
        } else {
            setTenants([]);
        }
        formRef.current.resetFields(['tenant']);
    };

    const onClose = () => {
        props.closeModalWindow();
    };

    const goToCreation = (_) => {
        formRef.current.validateFields().then((_) => {
            props.goToCreation && props.goToCreation(property, tenant);
        });
    };

    return (
        <Modal wrapClassName="result-modal" footer={null} visible={true} maskClosable={false} onCancel={onClose} closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <h4 className="mb-2">{t('selectEntityForCreation.contractHeader')}</h4>
                </div>
                <div className="main">
                    {props.contractDataLoading ? (
                        <LoaderSpinner />
                    ) : (
                        <Form name="basic" autoComplete="off" ref={formRef}>
                            <p className="font-weight-bold mb-2">{t('selectEntityForCreation.property')}</p>
                            <Form.Item name="property" rules={[{ required: true }]}>
                                <Select onSelect={onSelectProperty} placeholder={t('selectEntityForCreation.propertyPlaceholder')}>
                                    {props.properties.map((item) => (
                                        <Option value={item.id} key={item.id}>
                                            {item.propertyName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <p className="font-weight-bold mb-2">{t('selectEntityForCreation.tenant')}</p>
                            <Form.Item className="mb-0" name="tenant" rules={[{ required: true }]}>
                                <Select onSelect={setTenant} placeholder={t('selectEntityForCreation.tenantPlaceholder')}>
                                    {tenantsByProperty.map((item) => (
                                        <Option value={item.id} key={item.id}>
                                            {item.displayName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form>
                    )}
                </div>
                <div className="btns">
                    <ActionButton onClick={onClose} className="btn-secondary" text={t('common.buttons.cancel')} />
                    <ActionButton onClick={goToCreation} className="btn-primary" text={t('common.buttons.confirm')} />
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ property, tenant, contract }) => {
    return {
        properties: get(property, 'properties'),
        tenants: get(tenant, 'portfolioTenants'),
        contractDataLoading: get(contract, 'contractDataLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showModalForContractCreation', false));
        },
    };
};

const ModalForContractCreation = connect(mapState, mapDispatch)(ModalForContractCreationComponent);
export default ModalForContractCreation;
