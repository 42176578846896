import { Radio, Space } from 'antd';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import commonCostBudgetImg from '../../assets/images/clock.svg';
import commonCostActualImg from '../../assets/images/common-cost-actual.svg';
import commonCostGrowAreaImg from '../../assets/images/common-cost-grow-area.svg';
import commonCostVacancyImg from '../../assets/images/common-cost-vacancy.svg';
import collapse from '../../assets/images/select-arrow.svg';
import { commonCostDetailsSelector, commonCostSelector } from '../../pages/common-cost/selectors';
import ActionButton from '../action-button';
import CurrencyFormat from '../currency-format';
import NumberFormat from '../number-format';

const CommonCostAnalysisDetailsComponent = () => {
    const { t } = useTranslation();
    const [showPerM2, setShowPerM2] = useState();
    const [collapsed, setCollapsed] = useState(false);
    const commonCostDetails = useSelector(commonCostDetailsSelector);
    const commonCost = useSelector(commonCostSelector);
    const vacancyInfo = useSelector(({ commonCost }) => commonCost.commonCostCalculations?.vacancyInfo);

    const showDetailsRow = (img, title, value) => {
        value = showPerM2 ? value / commonCostDetails.lettable : value;

        return title && value ? (
            <div className="details-row">
                <div className="d-flex flex-row">
                    <Image src={img} />
                    <p>{title}</p>
                </div>
                <span className="text d-flex">
                    <CurrencyFormat value={value} />
                    <span className="ml-1">{showPerM2 && t('contract.unitTypes.m2')}</span>
                </span>
            </div>
        ) : null;
    };

    const showAreaRow = (img, title, value) => {
        return title && value ? (
            <div className="details-row">
                <div className="d-flex flex-row">
                    <Image src={img} />
                    <p>{title}</p>
                </div>
                <span className="text">
                    <NumberFormat value={value} />
                    <span className="ml-1">{t('contract.unitTypes.m2')}</span>
                </span>
            </div>
        ) : null;
    };

    return (
        <>
            <p className="font-weight-bold">{t('commonCost.analysisDetails.title')}</p>
            {vacancyInfo && (
                <div className="common-cost-analysis-details">
                    <div className="details-header justify-content-between">
                        <div className="d-flex align-items-center justify-content-end expanded-btn">
                            <ActionButton
                                className={`collapse-btn btn-transparent ${!collapsed ? 'not-collapsed-btn' : ''}`}
                                icon={<Image src={collapse} />}
                                onClick={() => setCollapsed(!collapsed)}
                            />
                            <p class="h3">{t('commonCost.analysisDetails.details')}</p>
                        </div>
                        <div className="d-flex">
                            <Radio.Group onChange={() => setShowPerM2(!showPerM2)} value={showPerM2 ? 1 : 0}>
                                <Space direction="vertical">
                                    <Radio value={1}>{t('commonCost.analysisDetails.showPerM2')}</Radio>
                                    <Radio value={0}>{t('commonCost.analysisDetails.showTotal')}</Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                    </div>
                    {!collapsed && (
                        <>
                            {showDetailsRow(commonCostBudgetImg, t('commonCost.analysisDetails.budget'), vacancyInfo.budgetTotal)}
                            {commonCost.hasAnyActuals ? showDetailsRow(commonCostActualImg, t('commonCost.analysisDetails.actual'), vacancyInfo.actualTotal) : null}
                            {showAreaRow(commonCostGrowAreaImg, t('commonCost.analysisDetails.lettableArea'), vacancyInfo.lettable)}
                            {showAreaRow(commonCostVacancyImg, t('commonCost.analysisDetails.vacancy'), vacancyInfo.vacancyArea)}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default CommonCostAnalysisDetailsComponent;
