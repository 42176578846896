import { Button, Dropdown, Menu } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import backArrow from '../../../assets/images/back-arrow.svg';
import costDate from '../../../assets/images/cost-date.svg';
import costProperty from '../../../assets/images/cost-property.svg';
import moreIcon from '../../../assets/images/more.svg';
import clear from '../../../common/clear';
import ActionButton from '../../../components/action-button';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import TextEllipsis from '../../../components/ellipsis-text';
import NotFoundPage from '../../../components/error-page';
import MainLayout from '../../../components/layouts/main-layout';
import DeleteMenuItem from '../../../components/menu-items/delete-menu-item';
import EditMenuItem from '../../../components/menu-items/edit-menu-item';
import LoaderSpinner from '../../../components/spinner';
import { setDeleteCommonCostModel, setValueCommonCost } from '../../../reducers/commonCostReducer';
import { deleteOwnerCost, getOwnerCostDetails, setValueOwnerCost } from '../../../reducers/ownerCostReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';
import commonCostService from '../../../services/commonCostService';
import routingService from '../../../services/routingService';
import CommonCostActualsAddResult from '../modals/actuals-add-result-modal';
import ConfirmDeleteCommonCost from '../modals/confirm-delete-modal';
import CommonCostAnalysis from './analysis-tab';
import add from '../../../assets/images/plus.svg';
import edit from '../../../assets/images/edit.svg';
import { editPermisionTypes } from '../../../common/constants';

export const commonCostTabs = {
    analysis: '1',
    calculation: '2',
    getKeyName: function (value) {
        return Object.keys(this).find((key) => this[key] === value) || 'analysis';
    },
};

const OwnerCostDetailsComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId, propertyId, ownerCostId } = useParams();
    const [currentTabKey, setCurrentTabKey] = useState(commonCostTabs.analysis);

    const ownerCostsUrl = routingService.ownerCostsUrl(portfolioId, propertyId);

    useEffect(() => {
        props.getPropertyCall(portfolioId, propertyId);
    }, []);

    useEffect(() => {
        if (portfolioId && propertyId && ownerCostId) {
            props.getOwnerCostDetailsCall(propertyId, ownerCostId, portfolioId);
        } else if (portfolioId && propertyId) {
            routingService.goTo(ownerCostsUrl);
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(decodeURI(props.location.search));
        const selectedTab = commonCostTabs[params.has('tab') ? params.get('tab') : ''];
        if (selectedTab && currentTabKey !== selectedTab) {
            setCurrentTabKey(selectedTab);
        }
    }, []);

    const addCostActuals = (_) => {
        routingService.navigateToAddOwnerCostActuals(portfolioId, propertyId, ownerCostId);
    };

    const deleteOwnerCost = async (_) => {
        await props.deleteOwnerCostCall(props.ownerCost.propertyId, props.ownerCost.id, portfolioId);
        const list = props.ownerCosts.filter((x) => x.id !== props.ownerCost.id);
        setValueOwnerCost('ownerCosts', list);
    };

    const handleDeleteownerCost = async (_) => {
        await deleteOwnerCost();
        routingService.navigateToOwnerCosts(portfolioId, propertyId);
    };

    const editOwnerCost = (_) => {
        props.setValueOwnerCostCall('ownerCost.editableOwnerCostMode', true);
        props.setValueOwnerCostCall('ownerCost.editableOwnerCost', props.ownerCost);
        routingService.navigateToEditOwnerCost(portfolioId, propertyId, props.ownerCost.id);
    };

    const dropdownMenu = (_) => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" disabled={!props.hasEditPermission || props.isArchived} onClick={editOwnerCost} text={t('ownerCost.card.edit')} />
                <DeleteMenuItem key="1" disabled={!props.hasEditPermission || props.isArchived} onClick={handleDeleteownerCost} text={t('ownerCost.card.delete')} />
            </Menu>
        );
    };

    const CostPeriod = ({ ownerCost }) => {
        return (
            <TextEllipsis>
                <h5 className="mb-0 ellipsis">
                    {moment(ownerCost.startDate).startOf('day').format(primaryDateFormat)} - {moment(ownerCost.endDate).startOf('day').format(primaryDateFormat)}
                </h5>
            </TextEllipsis>
        );
    };

    return (
        <MainLayout>
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {props.commonCostNotFound ? <NotFoundPage header={t('commonCost.notFound')} /> : null}
            {!props.loading && props.ownerCost ? (
                <div className="details-page common-cost-details owner-cost-details new-design-details-page">
                    <div className="title-container">
                        <div className="d-flex flex-column">
                            <div className="title">
                                <Link to={ownerCostsUrl}>
                                    <Image className="m-0" src={backArrow} />
                                </Link>
                                <TextEllipsis>
                                    <h3 className="mb-0 ellipsis">{props.ownerCost.name}</h3>
                                </TextEllipsis>
                                <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu()}>
                                    <Button className="btn-link px-0">
                                        <Image className="m-0" src={moreIcon} />
                                    </Button>
                                </Dropdown>
                                <CostPeriod ownerCost={props.ownerCost} />
                            </div>
                            <div className="common-cost-details-title">
                                <div className="property">
                                    <Image src={costDate} />
                                    <p className="text-secondary">{moment(props.ownerCost.startDate).startOf('day').format(primaryDateFormat)}</p>
                                </div>
                                {props.plots || props.buildings ? (
                                    <div className="property">
                                        <Image src={costProperty} />
                                        <TextEllipsis>
                                            <p className="text-secondary ellipsis">{commonCostService.getRelatedEntityName(props.ownerCost, props.buildings, props.plots)}</p>
                                        </TextEllipsis>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="common-cost-btn-header list p-0 add-actuals-btn">
                            <div className="search-header">
                                <ActionButton className="edit-budget-btn btn-secondary" icon={<Image src={edit} />} disabled={!props.hasEditPermission || props.isArchived} onClick={editOwnerCost} text={t('commonCost.editBudget')} />
                                {!props.hasAnyActuals && (
                                    <ActionButton
                                        className="btn-primary width-fit-content"
                                        icon={<Image src={add} />}
                                        onClick={addCostActuals}
                                        disabled={!props.hasEditPermission || props.isArchived}
                                        text={t('commonCost.addCostActualsBtn')}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <CommonCostAnalysis />
                </div>
            ) : (
                <LoaderSpinner />
            )}
            {props.showConfirmDeleteCommonCostModal && <ConfirmDeleteCommonCost handleDeleteCommonCost={deleteOwnerCost} />}
            {props.showActualsAddResultModal && <CommonCostActualsAddResult />}
        </MainLayout>
    );
};

const mapState = ({ portfolio, property, navigation, commonCost, ownerCost }) => {
    return {
        plots: get(property, 'property.plots'),
        buildings: get(property, 'property.buildings'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
        ownerCosts: get(ownerCost, 'ownerCosts'),
        ownerCost: get(ownerCost, 'ownerCost'),
        hasAnyActuals: get(ownerCost, 'ownerCost.ownerCostActuals.length') > 0,
        showFractionModal: get(commonCost, 'showFractionModal'),
        showActualsAddResultModal: get(commonCost, 'showActualsAddResultModal'),
        showConfirmDeleteCommonCostModal: get(commonCost, 'showConfirmDeleteCommonCostModal'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        loading: get(navigation, 'navigationLoading') || get(ownerCost, 'ownerCostLoading'),
        isArchived: get(ownerCost, 'ownerCost.isArchived')
    };
};

const mapDispatch = (dispatch) => {
    return {
        showConfirmDeleteCommonCostModalCall() {
            dispatch(setValueCommonCost('showConfirmDeleteCommonCostModal', true));
        },
        setValueOwnerCostCall(key, value) {
            dispatch(setValueOwnerCost(key, value));
        },
        setDeleteCommonCostModelCall(value) {
            dispatch(setDeleteCommonCostModel(value));
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        getOwnerCostDetailsCall(propertyId, ownerCostId, portfolioId) {
            return dispatch(getOwnerCostDetails(propertyId, ownerCostId, portfolioId));
        },
        deleteOwnerCostCall(propertyId, ownerCostId, portfolioId) {
            return dispatch(deleteOwnerCost(propertyId, ownerCostId, portfolioId));
        },
    };
};

const OwnerCostDetails = clear(connect(mapState, mapDispatch)(OwnerCostDetailsComponent));
export default OwnerCostDetails;
