import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moreIcon from '../../assets/images/more.svg';
import property from '../../assets/images/new-property-card-icon.svg';
import TextEllipsis from '../ellipsis-text';
import DeleteMenuItem from '../menu-items/delete-menu-item';
import EditMenuItem from '../menu-items/edit-menu-item';

const PropertyCard = (props) => {
    const { t } = useTranslation();

    const openProperty = () => {
        props.openProperty(props.property.id);
    };

    const editProperty = () => {
        if (props.editProperty) {
            props.editProperty(props.property);
        }
    };

    const deleteProperty = () => {
        if (props.deleteProperty) {
            props.deleteProperty(props.property);
        }
    };

    const dropdownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" onClick={editProperty} disabled={!props.hasEditPermission} text={t('property.editProperty')} />
                <DeleteMenuItem key="1" onClick={deleteProperty} disabled={!props.hasEditPermission} text={t('property.deleteProperty')} />
            </Menu>
        );
    };

    return (
        <div className="entity-card card-wrapper">
            <div className="image" onClick={openProperty}>
                <Image src={property} />
            </div>
            <div className="card-content">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="name-wrapper">
                        <TextEllipsis>
                            <h6 className="ellipsis">{props.property.propertyName}</h6>
                        </TextEllipsis>
                        {props.property.isArchived && <div className="archived-icon">Archived</div>}
                    </div>
                    <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu()}>
                        <Button className="btn-link btn-transparent settings-btn">
                            <Image src={moreIcon} />
                        </Button>
                    </Dropdown>
                </div>
                <p className="mb-3">{t(props.property.tenantsCount === 1 ? 'property.tenant' : 'property.countOfTenants', { count: props.property.tenantsCount })}</p>
            </div>
        </div>
    );
};

export default PropertyCard;
