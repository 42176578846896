import { ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/action-button';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import { deleteContractNote, updateContractNote } from '../../../../reducers/contractReducer';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import { useParams } from 'react-router';

const ContractNoteObject = (props) => {
    const { note, updateNoteCall, contractId, deleteNoteCall, updateNoteLoading, deleteNoteLoading } = props;

    const contentRef = useRef();

    const { t } = useTranslation();

    const [editForm] = Form.useForm();

    const { portfolioId, propertyId } = useParams();

    const [expanded, setExpanded] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    const handleEdit = () => {
        if (!isEdit) {
            editForm.setFieldsValue(note);
            setIsEdit((prev) => !prev);
        } else {
            setIsEdit((prev) => !prev);
        }
    };

    const updateContractNote = () => {
        editForm
            .validateFields()
            .then(() => {
                const values = {
                    ...editForm.getFieldsValue(),
                    id: note.id,
                    date: moment(),
                };
                updateNoteCall(values, contractId, propertyId, portfolioId).then((isSuccess) => {
                    if (isSuccess) {
                        setIsEdit((prev) => !prev);
                    }
                });
            })
            .catch(() => {});
    };

    const deleteContractNote = () => {
        deleteNoteCall(note.id, contractId, propertyId, portfolioId);
    };

    const getFirstLettersFromUserName = (str) => {
        const names = str ? str.split(' ') : [];

        if (names.length > 0) {
            const firstName = names[0].charAt(0);

            const lastName = names.length > 3 ? names[names.length - 2] : names[names.length - 1];

            return `${firstName}${lastName.charAt(0).toUpperCase()}`;
        }
    };

    return (
        <div className={`contract-note-object ${expanded ? 'expanded' : 'full'}`} key={note.id} style={{ maxHeight: props.collapsed ? 0 : contentRef.current?.offsetHeight + 400 }}>
            <div className="contract-note-header" onClick={() => expanded ? setExpanded(!expanded) : undefined}>
                <div className={`header-title ${expanded ? 'hover-anim' : ''}`}>
                    <div className="d-flex flex-row align-items-center">
                        <div className="notes-user-icon">{getFirstLettersFromUserName(note.userName)}</div>
                        <div>
                            <span className="user-name mr-2">{note.userName}</span>
                            {expanded && <p className="text-secondary">{note?.noteHeading}</p>}
                        </div>
                    </div>
                    <span className="text-secondary">{moment(note.date).format(primaryDateFormat)}</span>
                </div>
            </div>
            <div className={`contract-note-body ${expanded ? 'expanded' : ''}`} ref={contentRef}>
                {isEdit ? (
                    <Form form={editForm} autoComplete="off">
                        <div>
                            <p className="mb-2 font-weight-bold d-flex justify-content-between">
                                {t('common.date')}
                                <span>{moment().format('LL')}</span>
                            </p>
                            <p className="mb-2 font-weight-bold">{t('contract.notes.noteHeading')}</p>
                            <Form.Item
                                name="noteHeading"
                                rules={[
                                    {
                                        required: true,
                                        message: t('contract.notes.noteHeading') + t('common.isRequired'),
                                    },
                                ]}>
                                <Input placeholder={t('contract.notes.noteHeading')} />
                            </Form.Item>
                            <p className="mb-2 font-weight-bold">{t('contract.notes.note.label')}</p>
                            <Form.Item
                                name="note"
                                rules={[
                                    {
                                        required: true,
                                        message: t('contract.notes.note.title') + t('common.isRequired'),
                                    },
                                ]}>
                                <Input.TextArea rows={5} maxLength={1024} placeholder={t('contract.notes.note.placeholder')} />
                            </Form.Item>
                        </div>
                    </Form>
                ) : (
                    <>
                        <p className="noteheading">{note?.noteHeading}</p>
                        <p>{`${t('contract.notes.note.title')}:`}</p>
                        <p className="note text-secondary">{note?.note}</p>
                    </>
                )}
                <div className="action-buttons">
                    {isEdit ? (
                        <>
                            <Popconfirm
                                icon={<ExclamationCircleOutlined />}
                                title={t('contract.notes.note.deleteNoteConfirm')}
                                onConfirm={deleteContractNote}
                                okText={t('common.yes')}
                                cancelText={t('common.no')}>
                                <ActionButton className="delete-button" loading={deleteNoteLoading} text={t('common.buttons.delete')} />
                            </Popconfirm>
                            <ActionButton className="btn-secondary" onClick={() => setIsEdit((prev) => !prev)} text={t('common.buttons.cancel')} />
                            <ActionButton className="btn-primary" loading={updateNoteLoading} onClick={updateContractNote} text={t('common.buttons.saveChanges')} />
                        </>
                    ) : (
                        <>
                            <ActionButton className="btn-secondary" style={{ height: '35px' }} onClick={() => setExpanded(!expanded)} text={t('common.buttons.close')} />
                            <ActionButton className="btn-primary" style={{ height: '35px' }} onClick={() => handleEdit()} text={t('common.buttons.edit')} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        contractId: get(contract, 'contract.id'),
        updateNoteLoading: get(contract, 'updateNoteLoading'),
        deleteNoteLoading: get(contract, 'deleteNoteLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        updateNoteCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateContractNote(data, contractId, propertyId, portfolioId));
        },
        deleteNoteCall(noteId, contractId, propertyId, portfolioId) {
            return dispatch(deleteContractNote(noteId, contractId, propertyId, portfolioId));
        },
    };
};

export default connect(mapState, mapDispatch)(ContractNoteObject);
