import React from 'react';
import { Col } from 'react-bootstrap';
import OwnerCostAnalysisDetailsComponent from '../../../components/owner-cost/details-component';

const CommonCostDetailsRightSideComponents = () => {
    return (
        <Col sm={12} lg={4}>
            <div className="details-column">
                <div>
                    <OwnerCostAnalysisDetailsComponent />
                </div>
            </div>
        </Col>
    );
};

export default CommonCostDetailsRightSideComponents;
