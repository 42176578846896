const translations = {
    en: {
        'common.archived': 'Archived',
        'common.areYouSure': 'Are you sure?',
        'common.backToPortfolio': 'Back to portfolio',
        'common.beta': 'beta',
        'common.buttons.add': 'Add',
        'common.buttons.back': 'Back',
        'common.buttons.cancel': 'Cancel',
        'common.buttons.collapse': 'Collapse',
        'common.buttons.confirm': 'Confirm',
        'common.buttons.delete': 'Delete',
        'common.buttons.done': 'Done',
        'common.buttons.edit': 'Edit',
        'common.buttons.exit': 'Exit',
        'common.buttons.expand': 'Expand',
        'common.buttons.finish': 'Finish',
        'common.buttons.next': 'Next',
        'common.buttons.notNow': 'Not now',
        'common.buttons.previous': 'Previous',
        'common.buttons.saveAndExit': 'Save and exit',
        'common.buttons.saveChanges': 'Save changes',
        'common.buttons.showAll': 'Show all',
        'common.buttons.skip': 'Skip',
        'common.company': 'Company',
        'common.companyAlertDescription': 'You’re welcome!',
        'common.companyAlertMessage': 'We’ve autofilled the rest of the form by using Brreg.no.',
        'common.complete': 'Complete',
        'common.continue': 'Continue',
        'common.copyright': 'Estatelab, Inc. All rights reserved',
        'common.country': 'Country',
        'common.date': 'Date',
        'common.daysOfWeek.friday': 'friday',
        'common.daysOfWeek.monday': 'monday',
        'common.daysOfWeek.saturday': 'saturday',
        'common.daysOfWeek.sunday': 'sunday',
        'common.daysOfWeek.thursday': 'thursday',
        'common.daysOfWeek.tuesday': 'tuesday',
        'common.daysOfWeek.wednesday': 'wednesday',
        'common.digits.fifth': 'fifth',
        'common.digits.first': 'first',
        'common.digits.fourth': 'fourth',
        'common.digits.second': 'second',
        'common.digits.third': 'third',
        'common.discard': 'Discard',
        'common.draft': 'Draft',
        'common.dragAndDrop': 'Drag & drop',
        'common.durationMonths': '%{months} months',
        'common.durationYearsAndMonths': '%{years} years %{months} months',
        'common.editAddedEntity': 'You need to finish current entity or to clear all filled fields',
        'common.end': 'End',
        'common.invalidUrl': 'Web site URL is incorrect',
        'common.isRequired': ' is required',
        'common.areRequired': ' are required',
        'common.itemDeletedSuccessfully': '%{name} deleted successfully',
        'common.leavePage': 'Leave page',
        'common.monthly': 'Monthly',
        'common.months': 'months',
        'common.no': 'No',
        'common.notFoundMessage': 'We’re sorry but we cant find the page you requested.',
        'common.off': 'Off',
        'common.on': 'On',
        'common.onlyD': 'You should enter only digits',
        'common.onlyPercent': 'You should enter value in range 0-100',
        'common.orgNameNumber': 'Owner Organisation Name / Orgnr',
        'common.person': 'Person',
        'common.pleaseReturnTo': 'Please return to',
        'common.progress': 'Progress',
        'common.quarterly': 'Quarterly',
        'common.saveDraft': 'Keep as a Draft',
        'common.searchPlaceholder': 'Search',
        'common.serverErrorMessage': 'There has been an error',
        'common.start': 'Start',
        'common.steps': 'Step %{current} of %{steps}',
        'common.thankYou': 'Thank you',
        'common.validateLeaseDate': 'Date should be bigger than lease start',
        'common.yearly': 'Yearly',
        'common.years': 'years',
        'common.yes': 'Yes',
        'errorMessages.currentPasswordIsIncorrect': 'Last password is incorrect',
        'errorMessages.passwordIsEmpty': 'Password is empty',
        'forgotPasswordPage.backToLogin': 'Back to log in.',
        'forgotPasswordPage.changeYourEmail': 'Change your email.',
        'forgotPasswordPage.didntGetTheEmail': 'Didn’t get the email?',
        'forgotPasswordPage.emailHasBeenSentWithInstructions': 'An email has been sent to you with instructions on how to reset your password.',
        'forgotPasswordPage.form.email.placeholder': 'Enter your email',
        'forgotPasswordPage.form.email.title': 'Email',
        'forgotPasswordPage.form.submitBtnText': 'Email me with password reset link',
        'forgotPasswordPage.header': 'Reset your password',
        'forgotPasswordPage.instructionsSentTo': 'Instructions sent to:',
        'forgotPasswordPage.newCodeSentAt': 'New email sent at %{date}.',
        'forgotPasswordPage.rememberedPassword': 'Remembered your password?',
        'forgotPasswordPage.resendEmail': 'Resend email.',
        'forgotPasswordPage.subHeader': 'Don’t worry, it happens to the best of us.',
        'forgotPasswordPage.userIsNotExists': 'There is no user with this email.',
        'forgotPasswordPage.waitToSend': 'Wait 2 minutes to send new email',
        'forgotPasswordPage.wrongEmail': 'Wrong email?',
        'languages.en': 'English',
        'languages.no': 'Norwegian',
        'loginSignUpPage.alreadyHaveAnAccount': 'Already have an account?',
        'loginSignUpPage.backToSocialLogIn': 'Back to social log in',
        'loginSignUpPage.backToSocialSignUp': 'Back to social sign up',
        'loginSignUpPage.createAccount': 'Create your account',
        'loginSignUpPage.dontHaveAccount': "Don't have an account?",
        'loginSignUpPage.form.email.placeholder': 'Enter your email',
        'loginSignUpPage.form.email.title': 'Email',
        'loginSignUpPage.form.firstName.placeholder': 'Enter your first name',
        'loginSignUpPage.form.firstName.title': 'First Name',
        'loginSignUpPage.form.lastName.placeholder': 'Enter your last name',
        'loginSignUpPage.form.lastName.title': 'Last Name',
        'loginSignUpPage.form.password.forgottenYourPassword': 'Forgotten your password?',
        'loginSignUpPage.form.password.placeholder': 'Enter your password',
        'loginSignUpPage.form.password.title': 'Password',
        'loginSignUpPage.form.privacyPolicy.agreeTo': 'I agree to',
        'loginSignUpPage.form.privacyPolicy.and': 'and',
        'loginSignUpPage.form.privacyPolicy.privacyPolicy': 'Privacy Policy',
        'loginSignUpPage.form.privacyPolicy.required': 'Policy and Terms agreement is required',
        'loginSignUpPage.form.privacyPolicy.terms': 'Terms of Use',
        'loginSignUpPage.login': 'Login',
        'loginSignUpPage.loginNow': 'Login now',
        'loginSignUpPage.loginTitle': 'Log into your account using email',
        'loginSignUpPage.passwordWasReset': 'Your password was successfully reset.',
        'loginSignUpPage.pleaseLoginBelow': 'Please login below.',
        'loginSignUpPage.signUpNow': 'Sign Up now',
        'loginSignUpPage.signupTitle': 'Create an account with email',
        'loginSignUpPage.wantToUseSocialLogIn': 'Want to use social log in?',
        'loginSignUpPage.wantToUseSocialSignUp': 'Want to use social sign up?',
        'validation.email': 'Email address is invalid',
        'validation.phone': 'Phone number is invalid',
    },
    no: {
        'common.archived': 'Arkivert',
        'common.areYouSure': 'Er du sikker?',
        'common.backToPortfolio': 'Tilbake til portefølje',
        'common.beta': 'beta',
        'common.buttons.add': 'Legg til',
        'common.buttons.back': 'Tilbake',
        'common.buttons.cancel': 'Avbryt',
        'common.buttons.collapse': 'Lukk',
        'common.buttons.confirm': 'Bekreft',
        'common.buttons.delete': 'Slett',
        'common.buttons.done': 'Ferdig',
        'common.buttons.edit': 'Rediger',
        'common.buttons.exit': 'Avslutt',
        'common.buttons.expand': 'Utvid',
        'common.buttons.finish': 'Ferdig',
        'common.buttons.next': 'Neste',
        'common.buttons.notNow': 'Ikke nå',
        'common.buttons.previous': 'Tilbake',
        'common.buttons.saveAndExit': 'Lagre og lukk',
        'common.buttons.saveChanges': 'Lagre endringer',
        'common.buttons.showAll': 'Vis alt',
        'common.buttons.skip': 'Lukk',
        'common.company': 'Selskap',
        'common.companyAlertDescription': 'Værsågod!',
        'common.companyAlertMessage': 'Skjemaet er fyllt ut automatisk med data fra Brreg.no',
        'common.complete': 'Ferdig',
        'common.continue': 'Fortsett',
        'common.copyright': 'Estatelab AS har alle rettigheter reservert',
        'common.country': 'Land',
        'common.date': 'Dato',
        'common.daysOfWeek.friday': 'fredag',
        'common.daysOfWeek.monday': 'mandag',
        'common.daysOfWeek.saturday': 'lørdag',
        'common.daysOfWeek.sunday': 'søndag',
        'common.daysOfWeek.thursday': 'torsdag',
        'common.daysOfWeek.tuesday': 'tirsdag',
        'common.daysOfWeek.wednesday': 'onsdag',
        'common.digits.fifth': 'femte',
        'common.digits.first': 'første',
        'common.digits.fourth': 'fjerde',
        'common.digits.second': 'andre',
        'common.digits.third': 'tredje',
        'common.discard': 'Forkast',
        'common.draft': 'Utkast',
        'common.dragAndDrop': 'Dra og slipp filer her',
        'common.durationMonths': '%{months} måneder',
        'common.durationYearsAndMonths': '%{years} år %{months} måneder',
        'common.editAddedEntity': 'Avslutt det du gjør eller slett alle felt',
        'common.end': 'Slutt',
        'common.invalidUrl': 'Feil web adresse',
        'common.isRequired': ' Påkrevd',
        'common.itemDeletedSuccessfully': '%{name} slettet',
        'common.leavePage': 'Gå tilbake',
        'common.monthly': 'Månedlig',
        'common.months': 'Måneder',
        'common.no': 'Nei',
        'common.notFoundMessage': 'Beklager, vi finner ikke siden du ønsker',
        'common.off': 'Av',
        'common.on': 'På',
        'common.onlyD': 'Legg inn kun tall',
        'common.onlyPercent': 'Bruk verdier 0-100',
        'common.orgNameNumber': 'Eier Organisasjons navn / Orgnr',
        'common.person': 'Person',
        'common.pleaseReturnTo': 'Gå tilbake',
        'common.progress': 'Fremdrift',
        'common.quarterly': 'Kvartal',
        'common.saveDraft': 'Lagre som utkast',
        'common.searchPlaceholder': 'Søk',
        'common.serverErrorMessage': 'Beklager! Nå oppstod det en feil!',
        'common.start': 'Start',
        'common.steps': 'Trinn %{current} i %{steps}',
        'common.thankYou': 'Takk',
        'common.validateLeaseDate': 'Dato bør være før leiestart',
        'common.yearly': 'Årlig',
        'common.years': 'År',
        'common.yes': 'Ja',
        'errorMessages.currentPasswordIsIncorrect': 'Passordet er feil',
        'errorMessages.passwordIsEmpty': 'Tomt passord',
        'forgotPasswordPage.backToLogin': 'Tilbake til innlogging  ',
        'forgotPasswordPage.changeYourEmail': 'Endre e-post',
        'forgotPasswordPage.didntGetTheEmail': 'Fikk du ingen e-post?',
        'forgotPasswordPage.emailHasBeenSentWithInstructions': 'En e-post er sendt deg med veiledning for å endre ditt passord',
        'forgotPasswordPage.form.email.placeholder': 'Skriv inn din e-post',
        'forgotPasswordPage.form.email.title': 'E-post',
        'forgotPasswordPage.form.submitBtnText': 'Send link til endring av passord',
        'forgotPasswordPage.header': 'Tilbakestill ditt passord',
        'forgotPasswordPage.instructionsSentTo': 'Veileding er sendt til:',
        'forgotPasswordPage.newCodeSentAt': 'Ny e-post sendt %{date}',
        'forgotPasswordPage.rememberedPassword': 'Husker du ditt passord?',
        'forgotPasswordPage.resendEmail': 'Send e-post på nytt?',
        'forgotPasswordPage.subHeader': 'Ikke vær bekymret, det skjer den beste av oss',
        'forgotPasswordPage.userIsNotExists': 'Det finnnes ingen bruker med dennee-posten',
        'forgotPasswordPage.waitToSend': 'Vent 2 minutter for å sende ny e-post',
        'forgotPasswordPage.wrongEmail': 'Feil e-post',
        'languages.en': 'English',
        'languages.no': 'Norsk',
        'loginSignUpPage.alreadyHaveAnAccount': 'Allerede en konto?',
        'loginSignUpPage.backToSocialLogIn': 'Tilbake til innlogging med sosiale medier',
        'loginSignUpPage.backToSocialSignUp': 'Tilbake til registering med sosiale medier',
        'loginSignUpPage.createAccount': 'Opprett en konto',
        'loginSignUpPage.dontHaveAccount': 'Ingen konto?',
        'loginSignUpPage.form.email.placeholder': 'Skriv inn din e-post',
        'loginSignUpPage.form.email.title': 'E-post',
        'loginSignUpPage.form.firstName.placeholder': 'Skriv inn ditt fornavn',
        'loginSignUpPage.form.firstName.title': 'Fornavn',
        'loginSignUpPage.form.lastName.placeholder': 'Skriv inn ditt etternavn',
        'loginSignUpPage.form.lastName.title': 'Etternavn',
        'loginSignUpPage.form.password.forgottenYourPassword': 'Glemt passord?',
        'loginSignUpPage.form.password.placeholder': 'Skriv inn ditt passord',
        'loginSignUpPage.form.password.title': 'Passord ',
        'loginSignUpPage.form.privacyPolicy.agreeTo': 'Jeg er enig',
        'loginSignUpPage.form.privacyPolicy.and': 'Og',
        'loginSignUpPage.form.privacyPolicy.privacyPolicy': 'Personvernregler',
        'loginSignUpPage.form.privacyPolicy.required': 'Avtaleregler og vilkår er nødvendig',
        'loginSignUpPage.form.privacyPolicy.terms': 'Vilkår for bruk',
        'loginSignUpPage.login': 'Logg inn',
        'loginSignUpPage.loginNow': 'Logg inn',
        'loginSignUpPage.loginTitle': 'Logg inn med e-post',
        'loginSignUpPage.passwordWasReset': 'Passordet er endret',
        'loginSignUpPage.pleaseLoginBelow': 'Logg inn nedenfor',
        'loginSignUpPage.signUpNow': 'Register deg nå',
        'loginSignUpPage.signupTitle': 'Opprett en konto med e-post',
        'loginSignUpPage.wantToUseSocialLogIn': 'Logg inn med sosiale medier?',
        'loginSignUpPage.wantToUseSocialSignUp': 'Register deg med sosiale medier?',
        'validation.email': 'Ugyldig e-post adresse',
        'validation.phone': 'Ugyldig telefonnummer',
    },
};

export default translations;
