import { Menu } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { Image } from 'react-bootstrap';
import notificationsBell from '../../assets/images/bell-notifications.svg';

const MobileNotifications = (props) => {
    return (
        <div className="mobile-notifications">
            <h3>{i18next.t('navigation.notifications')}</h3>
            <Menu className="empty-notifications">
                <div>
                    <Image src={notificationsBell} />
                    <p>{i18next.t('navigation.notificationsComingSoon')}</p>
                </div>
            </Menu>
        </div>
    );
};

export default MobileNotifications;
