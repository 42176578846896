import { Button, Dropdown, Menu } from 'antd';
import moment from 'moment';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import contractArchiveDisabled from '../../../assets/images/contract-archive-disabled.svg';
import contractArchive from '../../../assets/images/contract-archive.svg';
import contractRestoreDisabled from '../../../assets/images/contract-restore-disabled.svg';
import contractRestore from '../../../assets/images/contract-restore.svg';
import moreIcon from '../../../assets/images/more.svg';
import ActionButton from '../../action-button';
import Duration from '../../duration';
import TextEllipsis from '../../ellipsis-text';
import DeleteMenuItem from '../../menu-items/delete-menu-item';
import EditMenuItem from '../../menu-items/edit-menu-item';
import TooltipBtn from '../../tooltip-btn';
import contractIcon from '../../../assets/images/new-contract-card-icon.svg';
import { contractStatus } from '../../../common/contract-options';

const ContractCard = (props) => {
    const { t } = useTranslation();

    const handleArchiveClick = (_) => {
        if (!props.hasEditPermission) return;
        props.archiveContract(props.contract);
    };

    const handleUnArchiveClick = (_) => {
        if (!props.hasEditPermission) return;
        props.restoreContract(props.contract);
    };

    const handleDeleteClick = (_) => {
        if (!props.hasEditPermission) return;
        props.deleteContract(props.contract);
    };

    const handleOpenClick = () => {
        props.openContract(props.contract);
    };

    const handleEditClick = () => {
        if (!props.hasEditPermission) return;
        props.editContract(props.contract);
    };

    const restoreMenuItem = (
        <Menu.Item key="2" onClick={handleUnArchiveClick} disabled={!props.hasEditPermission}>
            <ActionButton text={t('contract.restoreContract')} icon={<Image className="ml-2" src={!props.hasEditPermission ? contractRestoreDisabled : contractRestore} />} />
        </Menu.Item>
    );

    const archiveMenuItem = (
        <Menu.Item key="1" onClick={handleArchiveClick} disabled={!props.hasEditPermission}>
            <ActionButton text={t('contract.archiveContract')} icon={<Image className="ml-2" src={!props.hasEditPermission ? contractArchiveDisabled : contractArchive} />} />
        </Menu.Item>
    );

    const dropdownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" onClick={handleEditClick} disabled={!props.hasEditPermission || props.contract.isArchived} text={t('contract.editContract')} />
                {!(props.contract.isDraft || props.contract.isArchived) && (
                    <React.Fragment>
                        {!props.hasEditPermission ? (
                            <TooltipBtn title={t('common.noPermission')} placement="right">
                                {archiveMenuItem}
                            </TooltipBtn>
                        ) : (
                            archiveMenuItem
                        )}
                    </React.Fragment>
                )}
                {props.contract.isArchived && (
                    <React.Fragment>
                        {!props.hasEditPermission ? (
                            <TooltipBtn title={t('common.noPermission')} placement="right">
                                {restoreMenuItem}
                            </TooltipBtn>
                        ) : (
                            restoreMenuItem
                        )}
                    </React.Fragment>
                )}
                <DeleteMenuItem key="3" onClick={handleDeleteClick} disabled={!props.hasEditPermission || props.contract.isArchived} text={t('contract.deleteContract')} />
            </Menu>
        );
    };

    return (
        <div className="entity-card card-wrapper">
            {/* {props.contract.isDraft && (
                <div className="draft-label">
                    <p>{t('common.draft')}</p>
                </div>
            )}
            {props.contract.isArchived && (
                <div className="archived-label">
                    <p>{t('common.archived')}</p>
                </div>
            )} */}
            <div className="image" onClick={handleOpenClick}>
                <Image src={contractIcon} />
                {/* <div className="contract-label">
                    <p>{props.contract.name}</p>
                </div> */}
            </div>
            <div className="card-content">
                <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                    <TextEllipsis>
                        <h6 className="ellipsis">{props.contract.name}</h6>
                    </TextEllipsis>
                    {props.contract.status === contractStatus.draft && (
                        <div className="draft-icon-card">Draft</div>
                    )}
                    <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu(props.contract)}>
                        <Button className="btn-link btn-transparent settings-btn">
                            <Image src={moreIcon} />
                        </Button>
                    </Dropdown>
                </div>
                {props.contract.leaseStartDate && props.contract.leaseEndDate && (
                    <>
                        <div className="d-flex flex-row">
                            <p className="text-secondary">{t('tenant.contractsTab.endDate')}</p>
                            <p className="ml-1">{moment(props.contract.leaseEndDate).format('Do MMMM yyyy')}</p>
                        </div>
                        {(props.contract.status !== contractStatus.draft && props.contract.status !== contractStatus.terminated) && <Duration startDate={props.contract.leaseStartDate} endDate={props.contract.leaseEndDate} format={(value) => value + '%'} />}
                    </>
                )}
            </div>
        </div>
    );
};

export default ContractCard;
