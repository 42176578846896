import { Col, Row } from 'antd';
import React from 'react';
import { Image } from 'react-bootstrap';
import union from '../../../../assets/images/union.svg';

const TurnoverNotification = (props) => {

    const {text, close } = props;

    return (
        <div className={`turnover-notification ${props.className}`}>
            <Row>
                <Col span={20}>
                    {text}
                </Col>
                <Col offset={1} span={3}>
                    <Image src={union} onClick={() => close()} />
                </Col>
            </Row>
        </div>
    )
}

export default TurnoverNotification;