import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DatePicker, Form, Radio, Space, Row as RowAntd, Col as ColAntd } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import { requiredCommonInput } from '../../../common/validators';
import CommonInput from '../../../components/common-input';
import get from 'lodash-es/get';
import moment from 'moment';
import ContentBlock from '../../../components/content-block';
import helper from '../../../helpers/helper';
import { propertyStatuses, propertyStatusesOptions } from '../../../common/property-options';
import { QuestionCircleFilled } from '@ant-design/icons';
import TurnoverNotification from '../../contract/create/turnover/turnover-notification';

const PropertyNameComponent = (props) => {
    const { t } = useTranslation();
    const [notificationVisible, setNotificationVisible] = useState(Object.values(propertyStatuses).map((item) => ({ id: item, value: false })));
    const [propertyCollapsed, setPropertyCollapsed] = useState(false);
    const [status, setStatus] = useState(props.property.propertyStatusId);

    useEffect(() => {
        props.nameRef.current.setFieldsValue({
            propertyName: props.property.propertyName,
            propertyStatusId: props.property.propertyStatusId,
            takeoverDate: props.property.takeoverDate ? moment(props.property.takeoverDate).utc(true) : undefined,
            archiveFrom: props.property.archiveFrom ? moment(props.property.archiveFrom).utc(true) : undefined,
        });
    }, []);

    const handleNotification = (value, id) => {
        setNotificationVisible((prev) => {
            return prev.map((item) => {
                return item.id == id ? { ...item, value: value } : item;
            });
        });
    };

    const getPropertyLabel = () => {
        switch (status) {
            case propertyStatuses.active:
                return (
                    <>
                        {`${t('property.name.propertyStatus')}: `}{' '}
                        <span style={{ color: '#00AE31' }}>{helper.getEnumValue(propertyStatusesOptions, propertyStatuses.active)}!</span>
                    </>
                );

            case propertyStatuses.consideredPurchased:
                return (
                    <>
                        {`${t('property.name.propertyStatus')}: `}
                        <span style={{ color: '#F6C000' }}>{helper.getEnumValue(propertyStatusesOptions, propertyStatuses.consideredPurchased)}!</span>
                    </>
                );

            case propertyStatuses.archive:
                return (
                    <>
                        {`${t('property.name.propertyStatus')}: `}{' '}
                        <span style={{ color: '#F13F3F' }}>{helper.getEnumValue(propertyStatusesOptions, propertyStatuses.archive)}!</span>
                    </>
                );

            default:
                return t('property.name.propertyStatus');
        }
    };

    return (
        <div className="page-container property-name">
            <Row>
                <Col lg={12} xl={6}>
                    <Form.Item name="propertyName" rules={[requiredCommonInput(t('property.name.message') + t('common.isRequired'))]}>
                        <CommonInput maxLength={150} title={t('property.name.title')} placeholder={t('property.name.placeholder')} />
                    </Form.Item>
                </Col>
            </Row>
            <ContentBlock
                collapsed={propertyCollapsed}
                onCollapse={() => setPropertyCollapsed((prev) => !prev)}
                name={propertyCollapsed ? getPropertyLabel() : t('property.name.propertyStatus')}>
                <div className="property-status">
                    <RowAntd>
                        <ColAntd span={10}>
                            <Form.Item name="propertyStatusId" rules={[{ required: true, message: t('property.name.propertyStatus') + t('common.isRequired') }]}>
                                <Radio.Group onChange={(e) => setStatus(e.target.value)}>
                                    <Space direction="vertical">
                                        <RowAntd className="statuses-row">
                                            <ColAntd>
                                                <div className="d-flex align-items-center">
                                                    <Radio value={propertyStatuses.active}>{helper.getEnumValue(propertyStatusesOptions, propertyStatuses.active)}</Radio>
                                                    <QuestionCircleFilled onClick={() => handleNotification(true, propertyStatuses.active)} />
                                                </div>
                                                {notificationVisible.find((item) => item.id == propertyStatuses.active).value && (
                                                    <TurnoverNotification
                                                        text={t('property.name.activeNotification')}
                                                        close={() => handleNotification(false, propertyStatuses.active)}
                                                    />
                                                )}
                                            </ColAntd>
                                        </RowAntd>
                                        <RowAntd className="statuses-row">
                                            <ColAntd span={14}>
                                                <div className="d-flex align-items-center">
                                                    <Radio value={propertyStatuses.consideredPurchased} disabled={props?.property?.propertyStatusId == propertyStatuses.archive}>
                                                        {helper.getEnumValue(propertyStatusesOptions, propertyStatuses.consideredPurchased)}
                                                    </Radio>
                                                    <QuestionCircleFilled onClick={() => handleNotification(true, propertyStatuses.consideredPurchased)} />
                                                </div>
                                                {notificationVisible.find((item) => item.id == propertyStatuses.consideredPurchased).value && (
                                                    <TurnoverNotification
                                                        text={t('property.name.consideredPurchasedNotification')}
                                                        close={() => handleNotification(false, propertyStatuses.consideredPurchased)}
                                                    />
                                                )}
                                            </ColAntd>
                                        </RowAntd>
                                        {props.propertyId && (
                                            <RowAntd className="statuses-row">
                                                <ColAntd>
                                                    <div className="d-flex align-items-center">
                                                        <Radio value={propertyStatuses.archive}>{helper.getEnumValue(propertyStatusesOptions, propertyStatuses.archive)}</Radio>
                                                        <QuestionCircleFilled onClick={() => handleNotification(true, propertyStatuses.archive)} />
                                                    </div>
                                                    {notificationVisible.find((item) => item.id == propertyStatuses.archive).value && (
                                                        <TurnoverNotification
                                                            text={t('property.name.archiveNotification')}
                                                            close={() => handleNotification(false, propertyStatuses.archive)}
                                                        />
                                                    )}
                                                </ColAntd>
                                            </RowAntd>
                                        )}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </ColAntd>
                        <ColAntd span={10} offset={4} className="picker-col">
                            <div>
                                <h6>{`${t('property.name.activeNotification.takeoverDate')}(dd.mm.yyyy)`}</h6>
                                <Form.Item name="takeoverDate" initialValue={props.property.takeoverDate ? moment(props.property.takeoverDate).utc(true) : undefined}>
                                    <DatePicker format={primaryDateFormat} disabled={status != propertyStatuses.active} />
                                </Form.Item>
                            </div>
                            {props.propertyId && (
                                <div>
                                    <h6>{`${t('property.name.activeNotification.archiveFrom')}(dd.mm.yyyy)`}</h6>
                                    <Form.Item name="archiveFrom" initialValue={props.property.archiveFrom ? moment(props.property.archiveFrom).utc(true) : undefined}>
                                        <DatePicker format={primaryDateFormat} disabled={status != propertyStatuses.archive} />
                                    </Form.Item>
                                </div>
                            )}
                        </ColAntd>
                    </RowAntd>
                </div>
            </ContentBlock>
        </div>
    );
};

const mapState = ({ property }) => {
    return {
        property: get(property, 'property'),
    };
};

const PropertyName = connect(mapState, null)(PropertyNameComponent);
export default PropertyName;
