import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deleteRentObject, setValueContract } from '../../../../../reducers/contractReducer';
import ConfirmDeleteModal from '../../../../../components/confirm-delete-modal';
import get from 'lodash-es/get';
import rentObjectService from '../../../../../services/rentObjectService';
import { useParams } from 'react-router';

const ConfirmDeleteRentObjectComponent = (props) => {
    const { t } = useTranslation();

    const { portfolioId, propertyId } = useParams();

    const handleCloseClick = () => {
        props.closeModalWindow();
    };

    const handleDeleteClick = () => {
        if (props.rentObjectOnDeleteFunc) {
            props.rentObjectOnDeleteFunc();
        } else {
            props.deleteRentObject(props.rentObjectOnDelete.id, props.rentObjectOnDelete.contractId, portfolioId, propertyId);
            props.closeModalWindow();
        }
    };

    return (
        <ConfirmDeleteModal
            onClose={handleCloseClick}
            onConfirm={handleDeleteClick}
            title={t('contract.durationAndRentalObjects.rentObject.delete.title', { name: rentObjectService.getRentalObjectDisplayName(props.rentObjectOnDelete) })}
            text={t('contract.durationAndRentalObjects.rentObject.delete.sentence')}
            loading={props.crudRentObjectLoading}
            deleteBtnText={t('contract.durationAndRentalObjects.rentObject.delete.deleteBtnText')}
        />
    );
};

const mapState = ({ contract }) => {
    return {
        crudRentObjectLoading: get(contract, 'crudRentObjectLoading'),
        rentObjectOnDelete: get(contract, 'rentObjectOnDelete'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showRemoveRentObjectModal', false));
        },
        deleteRentObject(id, contractId, portfolioId, propertyId) {
            dispatch(deleteRentObject(id, contractId, portfolioId, propertyId))
        }
    };
};

const ConfirmDeleteRentObject = connect(mapState, mapDispatch)(ConfirmDeleteRentObjectComponent);
export default ConfirmDeleteRentObject;
