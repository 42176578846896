import { Checkbox } from 'antd';
import get from 'lodash-es/get';
import React, { useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import add from '../../../assets/images/add.svg';
import ActionButton from '../../../components/action-button';
import { setValueBuilding } from '../../../reducers/buildingReducer';
import FloorForm from './floor';

const FloorConstructorComponent = (props) => {
    const { t } = useTranslation();
    const [isNonLettable, setIsNonLettable] = useState(props.isNonLettable);

    const addFloor = () => {
        props.setFloors([
            { level: props.floors.length + 1, commonArea: 0, grossArea: null, nonLettable: 0, nettoArea: 0, commonPercent: 0, key: uuidv4(), isBasement: false },
            ...props.floors,
        ]);
        props.setValueBuildingCall('buildingConstructorData.levels', props.floors.length + 1 + props.basementFloors.length);
    };

    const addBasementFloor = () => {
        props.setBasementFloors([
            ...props.basementFloors,
            { level: -props.basementFloors.length - 1, commonArea: 0, grossArea: null, nonLettable: 0, nettoArea: 0, commonPercent: 0, key: uuidv4(), isBasement: true },
        ]);
        props.setValueBuildingCall('buildingConstructorData.levels', props.floors.length + 1 + props.basementFloors.length);
    };

    const onDeleteFloor = (key) => {
        props.setFloors(props.floors.filter((x) => x.key !== key));
        props.setValueBuildingCall('buildingConstructorData.levels', props.floors.length - 1 + props.basementFloors.length);
    };

    const onDeleteBasementFloor = (key) => {
        props.setBasementFloors(props.basementFloors.filter((x) => x.key !== key));
        props.setValueBuildingCall('buildingConstructorData.levels', props.floors.length - 1 + props.basementFloors.length);
    };

    return (
        <div className="building-constructor">
            <h5 className="mb-4">{t('building.buildingConstructor.floorPlan')}</h5>
            <ActionButton icon={<Image src={add} />} onClick={addFloor} className="btn-secondary mb-2 mr-2" text={t('building.buildingConstructor.addFloorLevel')} />
            <ActionButton icon={<Image src={add} />} onClick={addBasementFloor} className="btn-secondary" text={t('building.buildingConstructor.addBasementLevel')} />
            <Checkbox className="mb-3" checked={isNonLettable} onChange={() => setIsNonLettable((prev) => !prev)}>
                {t('building.buildingConstructor.isNonLettable')}
            </Checkbox>
            <Row className="desk-floors-header floor-data-row mx-0">
                <Col sm={1}>
                    <p className="font-weight-bold">{t('building.buildingConstructor.level')}</p>
                </Col>
                <Col sm={9}>
                    <Row className="floor-data-row mx-0">
                        <Col sm={2}>
                            <p className="font-weight-bold">{t('building.buildingConstructor.gross')}</p>
                        </Col>
                        {isNonLettable && (
                            <Col sm={2}>
                                <p className="font-weight-bold">{t('building.buildingConstructor.nonLettable')}</p>
                            </Col>
                        )}
                        <Col sm={isNonLettable ? 2 : 2}>
                            <p className="font-weight-bold">{t('building.buildingConstructor.lettable')}</p>
                        </Col>
                        <Col sm={2}>
                            <p className="font-weight-bold">{t('building.buildingConstructor.common')}</p>
                        </Col>
                        <Col sm={2} className="pl-2">
                            <p className="font-weight-bold">{t('building.buildingConstructor.netto')}</p>
                        </Col>
                        <Col sm={2} className="pl-2">
                            <p className="font-weight-bold">{t('building.buildingConstructor.commonPercent')}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="floors">
                {props.hasFloors ? (
                    props.floors.sort((a, b) => b.level - a.level).map((floor, index) => (
                        <FloorForm
                            floorsForm={props.floorsForm}
                            key={floor.key}
                            isNonLettable={isNonLettable}
                            level={props.floors.length - index}
                            floor={floor}
                            onDelete={onDeleteFloor}
                        />
                    ))
                ) : (
                    <h4 className="text-center my-3">{t('building.buildingConstructor.emptyFloorsList')}</h4>
                )}
            </div>
            <p className="font-weight-bold mb-1 mt-3 ml-1">{t('building.buildingConstructor.basementLevels')}</p>
            <div className="basement-floors">
                {props.hasBasementFloors ? (
                    props.basementFloors.map((floor, index) => (
                        <FloorForm floorsForm={props.floorsForm} key={floor.key} isNonLettable={isNonLettable} level={-index - 1} floor={floor} onDelete={onDeleteBasementFloor} />
                    ))
                ) : (
                    <h4 className="text-center my-3">{t('building.buildingConstructor.emptyBasementFloorsList')}</h4>
                )}
            </div>
        </div>
    );
};

const mapState = ({ building }) => {
    return {
        floors: get(building, 'buildingConstructorData.floors'),
        basementFloors: get(building, 'buildingConstructorData.basementFloors'),
        hasBasementFloors: get(building, 'buildingConstructorData.basementFloors.length') > 0,
        hasFloors: get(building, 'buildingConstructorData.floors.length') > 0,
        isNonLettable: get(building, 'building.floorsData.floors')?.some(item => item?.nonLettable > 0) || get(building, 'building.floorsData.basementFloors')?.some(item => item?.nonLettable > 0),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setFloors(value) {
            dispatch(setValueBuilding('buildingConstructorData.floors', value));
        },
        setBasementFloors(value) {
            dispatch(setValueBuilding('buildingConstructorData.basementFloors', value));
        },
        setValueBuildingCall(key, value) {
            dispatch(setValueBuilding(key, value));
        },
    };
};

const MobileFloorConstructor = connect(mapState, mapDispatch)(FloorConstructorComponent);
export default MobileFloorConstructor;
