import { QuestionCircleFilled } from '@ant-design/icons';
import { Col, Row, Form, Switch, Radio, Input, Select, InputNumber } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { turnoverRecurrencePeriodTypes, turnoverRecurrencePeriodTypesOptions } from '../../../../common/contract-options';
import { validateEmail } from '../../../../common/validators';
import { getUsers, setValueBackoffice } from '../../../../reducers/backofficeReducer';
import { getPortfolioUsers } from '../../../../reducers/portfolioReducer';
import TurnoverNotification from './turnover-notification';

const TurnoverStatemantComponent = (props) => {
    const { t } = useTranslation();

    const { portfolioId } = useParams();

    const { isStatemant, setIsStatemant, turnover } = props;

    const [periodType, setPeriodType] = useState(props.periodType);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [month, setMonth] = useState();

    useEffect(() => {
        props.getUsersCall(portfolioId);
    }, []);

    const getOptionStatement = () => {
        switch (periodType) {
            case turnoverRecurrencePeriodTypes.annually:
                return (
                    <>
                        <Col span={4}>
                            <p className="mt-2">{t('contract.turnover.statemant.on')}</p>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="onDay"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value == undefined) {
                                                return Promise.resolve();
                                            }

                                            if (month) {
                                                if (value > moment().set('month', month).daysInMonth()) {
                                                    return Promise.reject(new Error(`Max ${moment().set('month', month).daysInMonth()}`));
                                                }
                                            }

                                            if (value < 1) {
                                                return Promise.reject(new Error('Min 1'));
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}>
                                <InputNumber className="ant-input hidden-controls" />
                            </Form.Item>
                        </Col>
                        <Col span={8} offset={2}>
                            <Form.Item name="month">
                                <Select
                                    onChange={(value) => setMonth(value)}
                                    options={moment.months().map((item, index) => {
                                        return {
                                            value: index,
                                            label: item,
                                        };
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5} offset={1}>
                            <p className="mt-2">{t('contract.turnover.statemant.ofEachYear')}</p>
                        </Col>
                    </>
                );

            case turnoverRecurrencePeriodTypes.monthly:
                return (
                    <>
                        <Col span={4}>
                            <p className="mt-2">{t('contract.turnover.statemant.onDay')}</p>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="onDay"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value == undefined) {
                                                return Promise.resolve();
                                            }

                                            if (value < 1) {
                                                return Promise.reject(new Error('Min 1'));
                                            }

                                            if (value > 31) {
                                                return Promise.reject(new Error('Max 31'));
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}>
                                <InputNumber className="ant-input hidden-controls" />
                            </Form.Item>
                        </Col>
                        <Col span={5} offset={1}>
                            <p className="mt-2">{t('contract.turnover.statemant.ofEactMonth')}</p>
                        </Col>
                    </>
                );

            case turnoverRecurrencePeriodTypes.quarterly:
                return (
                    <>
                        <Col span={4}>
                            <p className="mt-2">{t('contract.turnover.statemant.onDay')}</p>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="onDay"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value == undefined) {
                                                return Promise.resolve();
                                            }

                                            if (value < 1) {
                                                return Promise.reject(new Error('Min 1'));
                                            }

                                            if (value > 92) {
                                                return Promise.reject(new Error('Max 92'));
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}>
                                <InputNumber className="ant-input hidden-controls" />
                            </Form.Item>
                        </Col>
                        <Col span={5} offset={1}>
                            <p> className="mt-2"{t('contract.turnover.statemant.ofEachQuarter')}</p>
                        </Col>
                    </>
                );

            default:
                break;
        }
    };

    return (
        <div className={`statemant ${isStatemant ? 'statemant-full' : ''}`} style={{ marginBottom: '15px' }}>
            <Row align="middle">
                <Col span={2}>
                    <Form.Item>
                        <Switch checked={isStatemant} onChange={(cheked) => setIsStatemant(cheked)} />
                    </Form.Item>
                </Col>
                <Col>
                    <p className="font-weight-bold">{t('contract.turnover.statemant.title')}</p>
                </Col>
            </Row>
            <h6 className="mt-3">{t('contract.turnover.statemant.recurrencePattern')}</h6>
            <Row>
                <Col span={14}>
                    <Form.Item name="turnoverRecurrencePeriodTypeId">
                        <Radio.Group onChange={(e) => setPeriodType(e.target.value)}>
                            <Radio value={turnoverRecurrencePeriodTypes.annually}>
                                {turnoverRecurrencePeriodTypesOptions.find((item) => item.id == turnoverRecurrencePeriodTypes.annually).name()}
                            </Radio>
                            <Radio value={turnoverRecurrencePeriodTypes.monthly}>
                                {turnoverRecurrencePeriodTypesOptions.find((item) => item.id == turnoverRecurrencePeriodTypes.monthly).name()}
                            </Radio>
                            <Radio value={turnoverRecurrencePeriodTypes.quarterly}>
                                {turnoverRecurrencePeriodTypesOptions.find((item) => item.id == turnoverRecurrencePeriodTypes.quarterly).name()}
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col span={14}>
                    <Row className="mt-4">
                        {getOptionStatement()}
                    </Row>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <h6 className="mt-3">{t('contract.turnover.statemant.assignTurnover')}</h6>
                </Col>
                <Col className="ml-1">
                    <QuestionCircleFilled onClick={() => setNotificationVisible(true)} />
                </Col>
            </Row>
            <Row>
                <Col span={15}>
                    {notificationVisible && <TurnoverNotification text={t('contract.turnover.statemant.eventReminder')} close={() => setNotificationVisible(false)} />}
                </Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Col span={15}>
                    <Form.Item name="assignedUserIds" initialValue={turnover?.auditStatemant?.assignedUsers.map((item) => item.userId)}>
                        <Select
                            mode="multiple"
                            placeholder="Please select"
                            options={props.users.map((item) => {
                                return {
                                    label: item.userName,
                                    value: item.userId,
                                };
                            })}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={15}>
                    {notificationVisible && <TurnoverNotification text={t('contract.turnover.statemant.statementNeedsAproved')} close={() => setNotificationVisible(false)} />}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col span={15}>
                    <h6>{`${t('contract.turnover.statemant.tenantTurnover')}`}</h6>
                    <Form.Item name="tenantEmail" rules={[validateEmail]}>
                        <Input placeholder={t('contract.turnover.statemant.enterEmail')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col span={15}>
                    <h6>{`${t('contract.turnover.statemant.auditTurnover')}`}</h6>
                    <Form.Item name="auditEmail" rules={[validateEmail]}>
                        <Input placeholder={t('contract.turnover.statemant.enterEmail')} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

const mapState = ({ portfolio }) => {
    return {
        users: get(portfolio, 'portfolioUsers'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        getUsersCall(portfolioId) {
            return dispatch(getPortfolioUsers(portfolioId));
        },
        setValueBackofficeCall(key, value) {
            dispatch(setValueBackoffice(key, value));
        },
    };
};

const TurnoverStatemant = connect(mapState, mapDispatch)(TurnoverStatemantComponent);
export default TurnoverStatemant;
