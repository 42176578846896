import { CloseOutlined } from '@ant-design/icons';
import { DatePicker, Form, Modal, Radio, Select } from 'antd';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';
import { digits, endsOptions, eventReminderRepeatOnOption, repeatOption, repeatReminderOnReminderModalOptions, weekDays } from '../../../../common/event-options';
import { onChangeSelectedRepeatOnDay, repeatWeekDays, setValueEvent } from '../../../../reducers/eventsReducer';
import ActionButton from '../../../action-button';
import PositiveNumericInput from '../../../numeric-input';

const { Option } = Select;

const CustomRecurrenceComponent = (props) => {
    const { t } = useTranslation();

    const [selectedRepeatOption, setRepeatOption] = useState(props.eventReminder.repeatOption);
    const [endsOption, setEndsOption] = useState(props.eventReminder.occuranceEndingDate ? endsOptions.date : endsOptions.count);

    const reminderDateMoment = props.eventReminder.reminderDateUtc && moment(props.eventReminder.reminderDateUtc);
    const weekDayOfReminderDate = reminderDateMoment ? reminderDateMoment.day() : null;
    const dayOfReminderDate = reminderDateMoment ? reminderDateMoment.date() : null;

    const reminderRef = React.useRef();

    useEffect(() => {
        if (props.eventReminder.repeatOption === props.eventEditReminderRepeatOption) {
            const reminder = props.eventReminder;
            reminder.occuranceEndingDate = props.eventReminder.occuranceEndingDate ? moment(props.eventReminder.occuranceEndingDate).startOf('day').utc(true) : null;
            reminderRef.current.setFieldsValue(reminder);
        } else {
            reminderRef.current.setFieldsValue({
                repeatCount: null,
                occurancesCount: null,
                occuranceEndingDate: null,
            });
        }
    }, []);

    const onConfirm = () => {
        reminderRef.current.validateFields().then(() => {
            const form = reminderRef.current.getFieldsValue();
            const eventReminder = props.eventReminder;
            props.eventReminder.repeatCount = form.repeatCount;
            props.eventReminder.repeatOption = form.repeatOption;
            props.eventReminder.occuranceEndingDate = form.occuranceEndingDate;
            props.eventReminder.occurancesCount = form.occurancesCount;
            props.setValueEventCall('event.eventReminder', eventReminder);
            props.setValueEventCall('eventEditReminderRepeatOption', form.repeatOption);
            props.closeModalWindow();
        });
    };

    const onCancel = () => {
        props.closeModalWindow();
        props.setValueEventCall('event.eventReminder.repeatOption', props.eventEditReminderRepeatOption);
    };

    const onSelectRepeatOn = (value) => {
        const repeatDays = props.eventReminder.repeatWeekDays;
        const index = repeatDays.findIndex((x) => x.name === value);
        const item = repeatDays[index];

        if (item.isSelected) {
            if (repeatDays.filter((x) => x.isSelected).length > 1) {
                props.onChangeSelectedRepeatOnDayCall(index, false);
            }
        } else {
            props.onChangeSelectedRepeatOnDayCall(index, true);
        }
    };

    const onSelectMonthlyRepeatOn = (value) => {
        props.setValueEventCall('event.eventReminder.repeatOnOption', value);
    };

    const onSelectEndsOption = (value) => {
        setEndsOption(value);
        if (value === endsOptions.date) {
            reminderRef.current.setFieldsValue({ occurancesCount: null });
        } else if (value === endsOptions.count) {
            reminderRef.current.setFieldsValue({ occuranceEndingDate: null });
        }
    };

    const onChangeRepeatReminderOption = (value) => {
        setRepeatOption(value);
        if (value === props.eventEditReminderRepeatOption) {
            const reminder = props.eventReminder;
            reminder.repeatOption = value;
            reminder.occuranceEndingDate = props.eventReminder.occuranceEndingDate ? moment(props.eventReminder.occuranceEndingDate).startOf('day').utc(true) : null;
            reminderRef.current.setFieldsValue(reminder);
            setEndsOption(reminder.endsOption);
            if (selectedRepeatOption === repeatOption.monthly) {
                reminderRef.current.setFieldsValue({ repeatOnOption: null });
            } else if (selectedRepeatOption === repeatOption.weekly && selectedRepeatOption !== value) {
                props.setValueEventCall('event.eventReminder.repeatWeekDays', repeatWeekDays);
            }
        } else {
            if (value === repeatOption.weekly) {
                const repeatDays = cloneDeep(repeatWeekDays);
                const dayNumber = moment().day();
                repeatDays[dayNumber - 1].isSelected = true;
                props.setValueEventCall('event.eventReminder.repeatWeekDays', repeatDays);
            }
            setEndsOption(endsOptions.date);
            reminderRef.current.setFieldsValue({ occurancesCount: null, occuranceEndingDate: null, repeatCount: null, repeatOnOption: null });
        }
    };

    const disableEndDate = (current) => {
        return current && current < moment(props.eventReminder.reminderDateUtc).endOf('day');
    };

    return (
        <Modal
            wrapClassName="result-modal creation-modal custom-reccurence"
            footer={null}
            visible={true}
            maskClosable={false}
            onCancel={onCancel}
            closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header flex-column">
                    <h4 className="mb-0">{t('events.customRecurrence.header')}</h4>
                </div>
                <div className="main">
                    <Form name="basic" autoComplete="off" ref={reminderRef}>
                        <p className="font-weight-bold mb-2">{t('events.customRecurrence.repeatCount.title')}</p>
                        <Row>
                            <Col xs={12} sm={4}>
                                <Form.Item name="repeatCount" className="mb-3" rules={[{ required: true }]}>
                                    <PositiveNumericInput placeholder={t('events.customRecurrence.repeatCount.placeholder')} />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={3}>
                                <Form.Item name="repeatOption" className="mb-3" initialValue={selectedRepeatOption}>
                                    <Select onSelect={onChangeRepeatReminderOption}>
                                        {repeatReminderOnReminderModalOptions.map((item) => (
                                            <Option value={item.id} key={item.id}>
                                                {item.name()}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {selectedRepeatOption === repeatOption.weekly && (
                            <div className="d-flex flex-column mb-3">
                                <p className="font-weight-bold mb-2">{t('events.customRecurrence.repeatOn')}</p>
                                <div className="d-flex flex-row">
                                    {props.repeatWeekDays.map((item) => (
                                        <ActionButton
                                            key={item.name}
                                            text={item.id}
                                            onClick={() => onSelectRepeatOn(item.name)}
                                            className={`repeat-on-btn ${item.isSelected ? 'selected-day' : ''}`}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                        {selectedRepeatOption === repeatOption.monthly && (
                            <div className="d-flex flex-column mb-2">
                                <p className="font-weight-bold mb-1">{t('events.customRecurrence.repeatOn')}</p>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <Form.Item className="mb-0" name="repeatOnOption" rules={[{ required: true }]}>
                                            <Select onSelect={onSelectMonthlyRepeatOn}>
                                                <Option value={eventReminderRepeatOnOption.exactMonthlyDay} key={eventReminderRepeatOnOption.exactMonthlyDay}>
                                                    {t('events.customRecurrence.repeatOnMonthly.day', { day: dayOfReminderDate })}
                                                </Option>
                                                <Option value={eventReminderRepeatOnOption.exactMonthlyWeekDay} key={eventReminderRepeatOnOption.exactMonthlyWeekDay}>
                                                    {t('events.customRecurrence.repeatOnMonthly.weekDay', {
                                                        digit: digits[Math.ceil(dayOfReminderDate / 7) - 1].name(),
                                                        weekDay: weekDays[weekDayOfReminderDate - 1].name(),
                                                    })}
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        <p className="font-weight-bold mb-0">{t('events.customRecurrence.ends.title')}</p>
                        <Row>
                            <Col xs={12} sm={4}>
                                <Radio.Group value={endsOption} className="radio-container my-2" onChange={(e) => onSelectEndsOption(e.target.value)}>
                                    <Radio value={endsOptions.date}>{t('events.customRecurrence.ends.on')}</Radio>
                                    <Radio value={endsOptions.count}>{t('events.customRecurrence.ends.after')}</Radio>
                                </Radio.Group>
                            </Col>
                            <Col xs={12} sm={4} className="align-self-end">
                                <Form.Item
                                    name="occuranceEndingDate"
                                    className="mb-0"
                                    rules={[
                                        {
                                            required: endsOption === endsOptions.date,
                                        },
                                    ]}>
                                    <DatePicker
                                        suffixIcon={<Image src={calendarIcon} />}
                                        allowClear={false}
                                        className="mt-2 mb-1"
                                        format={'MMM DD, YYYY'}
                                        disabledDate={disableEndDate}
                                        disabled={endsOption !== endsOptions.date}
                                    />
                                </Form.Item>
                                <div className="d-flex flex-row mt-2 mb-1 align-items-center">
                                    <Form.Item
                                        className="mb-0"
                                        name="occurancesCount"
                                        rules={[
                                            {
                                                required: endsOption === endsOptions.count,
                                            },
                                        ]}>
                                        <PositiveNumericInput disabled={endsOption !== endsOptions.count} />
                                    </Form.Item>
                                    <p className="text-secondary mb-0 ml-2">{t('events.customRecurrence.ends.occurances')}</p>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="btns">
                    <ActionButton className="btn-secondary" text={t('common.buttons.cancel')} onClick={onCancel}></ActionButton>
                    <ActionButton className="btn-primary" text={t('common.buttons.confirm')} onClick={onConfirm}></ActionButton>
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ events }) => {
    return {
        eventReminder: get(events, 'event.eventReminder'),
        repeatWeekDays: get(events, 'event.eventReminder.repeatWeekDays'),
        eventEditReminderRepeatOption: get(events, 'eventEditReminderRepeatOption'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueEvent('showReminderModalWindow', false));
        },
        setValueEventCall(key, value) {
            dispatch(setValueEvent(key, value));
        },
        onChangeSelectedRepeatOnDayCall(id, value) {
            dispatch(onChangeSelectedRepeatOnDay(id, value));
        },
    };
};

const CustomRecurrence = connect(mapState, mapDispatch)(CustomRecurrenceComponent);
export default CustomRecurrence;
