import { Spin } from 'antd';
import React from 'react';
import spin from '../../assets/images/spin.svg';
import { Image } from 'react-bootstrap';

const LoaderSpinner = ({ size = 'large' }) => {
    return (
        <div className="loader">
            <Spin size={size} spinning={true} indicator={<Image src={spin} className="custom-spinner"/>} />
        </div>
    );
};

export default LoaderSpinner;
