import { QuestionCircleFilled } from '@ant-design/icons';
import { Form, Select } from 'antd';
import get from 'lodash-es/get';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import required from '../../../../assets/images/required.svg';
import { contractStatus, contractStatusOptions } from '../../../../common/contract-options';
import { requiredCommonInput } from '../../../../common/validators';
import CommonInput from '../../../../components/common-input';
import Uploader from '../../../../components/uploader';
import objectHelper from '../../../../helpers/trim-object';
import { deleteFile, downloadFile, setValueContract, updateBasicContractDetails, uploadFile } from '../../../../reducers/contractReducer';
import TurnoverNotification from '../turnover/turnover-notification';
import ConfirmStatusModal from './confirm-status-modal';
import { useParams } from 'react-router';

const { Option } = Select;

const BasicDetailsComponent = (props) => {
    const { t } = useTranslation();

    const { propertyId, portfolioId } = useParams();

    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const [statusNotificationVisible, setStatusNotificationVisible] = useState(false);
    const [contractStatusBuffer, setContractStatusBuffer] = useState(props.contract.status);

    useEffect(() => {
        if (props.contract) {
            props.detailsRef.current.setFieldsValue({ name: props.contract.name });
            if (props.contract.status > 0) {
                props.detailsRef.current.setFieldsValue({ status: props.contract.status });
            }
        }
    }, [props.contract]);

    const uploadFileFunc = (file, options) => {
        return props.uploadFileCall(file, options, props.contract.id, portfolioId, propertyId);
    };

    const deleteFileFunc = (fileId) => {
        return props.deleteFileCall(fileId, props.contract.id, portfolioId, propertyId);
    };

    const handleDownload = (e, file) => {
        e.preventDefault();
        props.downloadFileCall(file, props.contract.id, portfolioId);
    };

    const handleStatusChange = (status) => {
        setContractStatusBuffer(status);
        setConfirmModalOpen(true);
    };

    const handleStatusConfirm = () => {
        setConfirmModalOpen(false);
        props.setValueContractCall('contractStatus', contractStatusBuffer);
        props.updateBasicContractDetailsCall(objectHelper.trimObjectProperties(props.detailsRef.current.getFieldsValue()), props.contract.id, propertyId, portfolioId);
    };

    const handleCancelModal = () => {
        props.detailsRef.current.setFieldsValue({ status: props.contract.status });
        setContractStatusBuffer(props.contract.status);
        setConfirmModalOpen(false);
    };

    return (
        <div className="page-container pb-0">
            <Row>
                <Col lg={12} xl={6}>
                    <Form.Item name="name" rules={[requiredCommonInput(t('contract.details.contractName.title') + t('common.isRequired'))]}>
                        <CommonInput
                            maxLength={150}
                            isRequired={true}
                            placeholder={t('contract.details.contractName.placeholder')}
                            title={t('contract.details.contractName.title')}
                        />
                    </Form.Item>
                    <p className="font-weight-bold d-flex flex-row align-items-center">
                        <Image className="required-img" src={required} />
                        {t('contract.details.contractStatus.title')}
                        <QuestionCircleFilled onClick={() => setStatusNotificationVisible(true)} style={{ marginLeft: '5px' }} />
                    </p>
                    {statusNotificationVisible && (
                        <TurnoverNotification className="mb-2" text={t('contract.details.status.notification')} close={() => setStatusNotificationVisible(false)} />
                    )}
                    {props.contract.status == contractStatus.draft ? (
                        <p className="text-secondary ml-4">{contractStatusOptions.find((item) => item.id === contractStatus.draft).name()}</p>
                    ) : (
                        <Form.Item
                            name="status"
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.details.contractStatus.title') + t('common.isRequired'),
                                },
                            ]}>
                            <Select
                                placeholder={t('contract.details.contractStatus.placeholder')}
                                dropdownClassName="new-design-dropdown"
                                onChange={(value) => handleStatusChange(value)}>
                                {contractStatusOptions
                                    .filter((item) => item.id !== contractStatus.draft)
                                    .map((object) => (
                                        <Option value={object.id} key={object.id} disabled={object.disabled}>
                                            {object.name()}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    )}
                </Col>
            </Row>
            {props.isEditable && (
                <Uploader
                    title={t('contract.details.filesTitle')}
                    files={props.documents}
                    downloadFile={handleDownload}
                    uploadFile={uploadFileFunc}
                    deleteFile={deleteFileFunc}
                    isNew={true}
                />
            )}
            <ConfirmStatusModal
                isConfirmModalOpen={isConfirmModalOpen}
                handleStatusConfirm={handleStatusConfirm}
                handleCancelModal={handleCancelModal}
                contractStatusBuffer={contractStatusBuffer}
            />
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        documents: get(contract, 'documents'),
        isEditable: get(contract, 'contractStatus') != contractStatus.expired && get(contract, 'contractStatus') != contractStatus.terminated,
    };
};

const mapDispatch = (dispatch) => {
    return {
        uploadFileCall(file, options, contractId, portfolioId, propertyId) {
            return dispatch(uploadFile(file, options, contractId, portfolioId, propertyId));
        },
        downloadFileCall(file, contractId, portfolioId) {
            return dispatch(downloadFile(file, contractId, portfolioId));
        },
        deleteFileCall(fileId, contractId, portfolioId, propertyId) {
            return dispatch(deleteFile(fileId, contractId, portfolioId, propertyId));
        },
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
        updateBasicContractDetailsCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateBasicContractDetails(data, contractId, propertyId, portfolioId));
        },
    };
};

const BasicDetails = connect(mapState, mapDispatch)(BasicDetailsComponent);
export default BasicDetails;
