import { Progress } from 'antd';
import { get } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import routingService from '../../services/routingService';
import ActionButton from '../action-button';
import back_icon from '../../assets/images/back.svg';
import Logo from '../logo-container';
import LoaderSpinner from '../spinner';
import { contractStatus } from '../../common/contract-options';
import { contractStepNames } from '../../pages/contract/create';
import { editPermisionTypes } from '../../common/constants';

const ObjectLayoutComponent = ({
    topRightSideBar,
    steps,
    navigationLoading,
    className,
    bottomRightSideBar,
    saveBtnLoading,
    disabledNextBtn,
    exitUrl,
    loading,
    onSaveAndExit,
    isNewDesign,
    moveToStepName,
    isEditable,
    onPrevious,
    setValueContractCall,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [stepData, setStepData] = useState({ step: 0, subStep: 0 });
    const [changeStepLoading, setChangeStepLoading] = useState(false);

    const headerHeight = 114;

    const navigateToStep = (key) => {
        const el = document.querySelector(`#substep-${key}`);

        if (el) {
            window.scrollTo({
                top: el.offsetTop - headerHeight,
                behavior: 'smooth',
            });
        }
    };

    const isStepFullyCompleted = (step) => {
        return !steps[step]?.underSteps.some((us) => !us.isStepCompleted());
    };

    const currentStep = steps[stepData.step];
    const currentStepIndex = steps.findIndex((x) => x.key === currentStep.key);

    const isCurrentStepCompleted = isStepFullyCompleted(stepData.step);

    const executeIfExists = (func, param) => {
        if (func) {
            return func(param);
        }
    };

    const handleExitWithoutSaving = () => {
        if (exitUrl) {
            routingService.goTo(exitUrl);
        } else {
            routingService.goBack();
        }
    };

    const handleExitAndSave = async () => {
        try {
            if (isEditable) {
                for (let i = 0; i < currentStep.underSteps.length; i++) {
                    try {
                        await executeIfExists(currentStep.underSteps[i].validate);
                    } catch (e) {
                        throw i;
                    }
                }
            } else {
                await executeIfExists(currentStep.underSteps[0].validate);
            }

            setChangeStepLoading(true);

            if (stepData.step === steps.length - 1) {
                await executeIfExists(onSaveAndExit);
            } else {
                if (exitUrl) {
                    routingService.goTo(exitUrl);
                } else {
                    routingService.goBack();
                }
            }

            setChangeStepLoading(false);
        } catch (i) {
            setChangeStepLoading(false);
            navigateToStep(currentStep.underSteps[i].key);
        }
    };

    const handleNext = async () => {
        if (isCurrentStepCompleted || currentStep.underSteps.length - 1 === stepData.subStep) {
            setChangeStepLoading(true);

            try {
                for (let i = 0; i < currentStep.underSteps.length; i++) {
                    try {
                        await executeIfExists(currentStep.underSteps[i].validate);
                    } catch (_) {
                        throw i;
                    }
                }

                if (stepData.step === steps.length - 1) {
                    await executeIfExists(onSaveAndExit);
                } else {
                    setStepData({ step: stepData.step + 1, subStep: 0 });
                }

                setChangeStepLoading(false);
            } catch (i) {
                setChangeStepLoading(false);
                navigateToStep(currentStep.underSteps[i].key);
            }

            return;
        }

        await executeIfExists(steps[stepData.step].underSteps[stepData.subStep].validate);

        setStepData({ ...stepData, subStep: stepData.subStep + 1 });
    };

    const handleBackClick = () => {
        if (stepData.step === 0 && stepData.subStep === 0) {
            return;
        }

        if (stepData.subStep !== 0) {
            setStepData({ step: stepData.step, subStep: stepData.subStep - 1 });
        } else {
            setStepData({ step: stepData.step - 1, subStep: steps[stepData.step - 1].underSteps.length - 1 });
        }
    };

    const moveToStep = async (step, underStep) => {
        if (step === stepData.step && underStep <= stepData.subStep) {
            navigateToStep(steps[step].underSteps[underStep].key);
            return;
        }

        window.scrollTo(0, document.body.scrollHeight);

        let newStepData = { step, subStep: underStep };

        for (let i = 0; i <= step; i++) {
            if (!isStepFullyCompleted(i)) {
                newStepData = { step: i, subStep: steps[i].underSteps.findIndex((us) => !us.isStepCompleted()) };
                break;
            }
        }

        setChangeStepLoading(true);

        if (newStepData.step !== stepData.step) {
            for (let i = 0; i < currentStep.underSteps.length; i++) {
                try {
                    await executeIfExists(currentStep.underSteps[i].validate);
                } catch (_) {}
            }
        }

        setChangeStepLoading(false);

        setStepData(newStepData);
    };

    useEffect(() => {
        if (currentStep.underSteps[stepData.subStep]?.key) {
            navigateToStep(currentStep.underSteps[stepData.subStep].key);
        }
    }, [stepData]);

    useEffect(() => {
        if (moveToStepName != null) {
            setStepData({ step: steps.findIndex((item) => item.key == moveToStepName), subStep: 0 });
        }

        return () => {
            history.replace({ ...history.location, state: undefined });
        };
    }, [moveToStepName]);

    return (
        <React.Fragment>
            {(navigationLoading || loading || changeStepLoading) && <LoaderSpinner />}
            <div style={{ display: navigationLoading || loading || changeStepLoading ? 'none' : 'block' }} className={`object-layout new-design-layout ${className}`}>
                <div className="header">
                    <Logo />
                    <div className="draft-btns">
                        <ActionButton onClick={handleExitWithoutSaving} className="btn-secondary next-btn" text={t('exitFromDraft.exitBtn')} />
                        <ActionButton htmlType="submit" onClick={handleExitAndSave} className="btn-primary ml-3" text={t('common.buttons.saveAndExit')} />
                    </div>
                </div>
                <div className="page h-100" style={{ paddingBottom: isNewDesign && '0px' }}>
                    <div className="mobile-progress-container">
                        <Progress width={32} strokeWidth={10} type="circle" percent={(currentStepIndex + 1) * (100 / steps.length)} />
                        <div className="d-flex flex-column">
                            <h6 className="mb-0">{currentStep.sectionTitle}</h6>
                            <p className="mb-0">{t('common.steps', { current: currentStepIndex + 1, steps: steps.length })}</p>
                        </div>
                    </div>
                    <div className="main-wrapper">
                        <div className="main" id={`substep-${currentStep.underSteps[0].key}`}>
                            <div className="main-header">
                                <span className="icon">
                                    <Image src={currentStep.logo} />
                                </span>
                                <div className="d-flex flex-column">
                                    <h4 className="mb-2">{currentStep.underSteps[0].header}</h4>
                                    <p className="text-secondary mb-0">{t('layout.message')}</p>
                                </div>
                            </div>
                            <div className="content">{currentStep.underSteps[0].content}</div>
                        </div>
                        {currentStep?.underSteps?.length &&
                            isEditable &&
                            currentStep.underSteps
                                .filter((step) => step.key !== currentStep.key)
                                .slice(0, !isCurrentStepCompleted ? stepData.subStep : currentStep.underSteps.length - 1)
                                .map((item) => (
                                    <div className="main" id={`substep-${item.key}`}>
                                        <div className="content">{item.content}</div>
                                    </div>
                                ))}
                        <div className="new-footer">
                            {(stepData.step > 0 || stepData.subStep !== 0) && (
                                <ActionButton className="btn-secondary" text={t('common.buttons.previous')} onClick={handleBackClick} icon={<Image src={back_icon} />} />
                            )}
                            {isEditable ? (
                                <ActionButton
                                    className="btn-primary next-btn"
                                    onClick={handleNext}
                                    loading={saveBtnLoading}
                                    text={
                                        stepData.step === steps.length - 1 && (stepData.subStep === currentStep.underSteps.length - 1 || isCurrentStepCompleted)
                                            ? t('common.buttons.saveAndExit')
                                            : t('common.buttons.next')
                                    }
                                    disabled={!!disabledNextBtn}
                                />
                            ) : (
                                <ActionButton htmlType="submit" onClick={handleExitAndSave} className="btn-primary ml-3" text={t('common.buttons.saveAndExit')} />
                            )}
                        </div>
                    </div>
                    <div className="right-side">
                        {topRightSideBar}
                        <div className="web-progress-container">
                            <h4>{t('common.progress')}</h4>
                            <div className="custom-timeline">
                                {steps &&
                                    steps.map((item, index) => {
                                        const StepperItem = () => {
                                            const [expanded, setExpanded] = useState(index === stepData.step);

                                            const isStepCompleted = isStepFullyCompleted(index);
                                            const firstUncompletedIndex = item.underSteps.findIndex((us) => !us.isStepCompleted());
                                            const hasCompletedMandatory = item.underSteps.some((us) => us.isStepCompleted() && !us.isOptional);

                                            return (
                                                <div
                                                    key={index}
                                                    className={`timeline-item ${expanded ? 'expanded' : ''} ${
                                                        isStepCompleted ? 'done' : stepData.step === index || hasCompletedMandatory ? 'undone' : ''
                                                    }`}>
                                                    <div className="item-body" style={{ cursor: isEditable ? '' : 'not-allowed'}} onClick={() => isEditable ? moveToStep(index, 0) : undefined}>
                                                        {item.sectionTitle}
                                                    </div>
                                                    <div onClick={() => setExpanded(!expanded)} className="expand-button">
                                                        {'>'}
                                                    </div>
                                                    <div className="timeline-subitems">
                                                        {item.underSteps &&
                                                            item.underSteps.map((underItem, underIndex) => {
                                                                return (
                                                                    <div
                                                                        key={underIndex}
                                                                        onClick={() => isEditable ? moveToStep(index, underIndex) : undefined}
                                                                        className={`timeline-subitem ${
                                                                            (stepData.step === index && underIndex === stepData.subStep) ||
                                                                            (stepData.step !== index && hasCompletedMandatory && firstUncompletedIndex === underIndex)
                                                                                ? 'current'
                                                                                : ''
                                                                        } ${
                                                                            (
                                                                                !isStepCompleted
                                                                                    ? stepData.step === index
                                                                                        ? underIndex < stepData.subStep
                                                                                        : hasCompletedMandatory && underIndex < firstUncompletedIndex
                                                                                    : true
                                                                            )
                                                                                ? 'done'
                                                                                : ''
                                                                        }`}>
                                                                        <div className="item-body" style={{ cursor: isEditable ? '' : 'not-allowed'}}>{underItem.header}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            );
                                        };

                                        return <StepperItem />;
                                    })}
                            </div>
                        </div>
                        {bottomRightSideBar}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapState = ({ navigation, contract }) => {
    return {
        navigationLoading: get(navigation, 'navigationLoading'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        isEditable: get(contract, 'contractStatus') != contractStatus.expired && get(contract, 'contractStatus') != contractStatus.terminated,
    };
};

const NewDesignObjectLayout = connect(mapState)(ObjectLayoutComponent);
export default NewDesignObjectLayout;
