import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { setValueCommonCost } from '../../../../reducers/commonCostReducer';
import get from 'lodash-es/get';
import CurrencyFormat from '../../../../components/currency-format';
import NumberFormat from '../../../../components/number-format';
import ActionButton from '../../../../components/action-button';
import commonCostService from '../../../../services/commonCostService';
import TextEllipsis from '../../../../components/ellipsis-text';
import LettableAreaModal from '../area-modal';
import { editPermisionTypes } from '../../../../common/constants';

const CommonCostVacancyComponent = (props) => {
    const { t } = useTranslation();

    const [areaModalVisible, setAreaModalVisible] = useState(false);

    return (
        <>
            <p className="font-weight-bold">{t('commonCosts.calculations.vacancy')}</p>
            <div className="common-cost-vacancy">
                <div className="common-cost-vacancy-header">
                    {props.buildings || props.plots ? (
                        <TextEllipsis>
                            <p className="ellipsis mb-0 font-weight-bold">{commonCostService.getRelatedEntityName(props.commonCost, props.buildings, props.plots)}</p>
                        </TextEllipsis>
                    ) : null}
                    <ActionButton
                        disabled={!props.hasEditPermission}
                        className="edit-lettable-btn"
                        text={t('commonCost.calculations.editLettableArea')}
                        onClick={() => setAreaModalVisible(true)}
                    />
                </div>
                <div className="table-flex">
                    <div className="d-flex flex-column">
                        <Row className="table-header visible-md">
                            <Col xs={12} md={3}>
                                <div className="th">{t('commonCost.analysisDetails.budget')}</div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="th">{t('commonCost.calculations.lettableArea')}</div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="th">{t('commonCost.calculations.vacancyArea')}</div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="th">{t('commonCost.calculations.fraction')}</div>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="th">{t('commonCost.calculations.fractionAmount')}</div>
                            </Col>
                        </Row>
                        <div className="divider hidden-md" />
                        <div className="table-body">
                            <div className="table-row">
                                <Row align={'middle'}>
                                    <Col xs={12} md={3}>
                                        <div className="td">
                                            <span className="hidden-md">{t('commonCost.analysisDetails.budget')}:</span>
                                            <CurrencyFormat value={props.vacancyInfo.budgetTotal} />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <div className="td">
                                            <span className="hidden-md">{t('commonCost.calculations.lettableArea')}:</span>
                                            <NumberFormat value={props.vacancyInfo.lettable} />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <div className="td">
                                            <span className="hidden-md">{t('commonCost.calculations.vacancyArea')}:</span>
                                            <NumberFormat value={props.vacancyInfo.vacancyArea > 0 ? props.vacancyInfo.vacancyArea : 0} />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <div className="td">
                                            <span className="hidden-md">{t('commonCost.calculations.fraction')}:</span>
                                            <NumberFormat value={props.vacancyInfo.fractionPercent > 0 ? props.vacancyInfo?.fractionPercent?.toFixed(1) : 0} suffix="%" />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <div className="td">
                                            <span className="hidden-md">{t('commonCost.calculations.fractionAmount')}:</span>
                                            {<CurrencyFormat value={props.vacancyInfo.fractionAmount > 0 ? props.vacancyInfo.fractionAmount : 0} />}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LettableAreaModal isAreaModalVisible={areaModalVisible} closeModal={() => setAreaModalVisible(false)} />
        </>
    );
};

const mapState = ({ commonCost, property, navigation }) => {
    return {
        commonCost: get(commonCost, 'commonCost'),
        vacancyInfo: get(commonCost, 'commonCostCalculations.vacancyInfo'),
        buildings: get(property, 'property.buildings'),
        plots: get(property, 'property.plots'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueCommonCostCall(key, value) {
            dispatch(setValueCommonCost(key, value));
        },
    };
};

const CommonCostVacancy = connect(mapState, mapDispatch)(CommonCostVacancyComponent);
export default CommonCostVacancy;
