import { StatusCodes } from 'http-status-codes';
import { setValueProperty } from './propertyReducer';
import update from '../helpers/update';
import { v4 as uuidv4 } from 'uuid';
import i18next from 'i18next';
import toaster from '../common/toaster';
import buildingService from '../services/buildingService';
import { maxPercents } from '../common/constants';

const SET_VALUE_BUILDING = 'SET_VALUE_BUILDING';
const SET_VALUE_BUILDING_FLOOR = 'SET_VALUE_BUILDING_FLOOR';
const SET_VALUE_BUILDING_BASEMENT_FLOOR = 'SET_VALUE_BUILDING_BASEMENT_FLOOR';
const SET_LIST_OF_UPLOAD_ITEMS_BUILDING = 'SET_LIST_OF_UPLOAD_ITEMS_BUILDING';
const CLEAR_BUILDING = 'CLEAR_BUILDING';
const CLEAR_DELETE_BUILDING_MODEL = 'CLEAR_DELETE_BUILDING_MODEL';
const SET_DELETE_BUILDING_MODEL = 'SET_DELETE_BUILDING_MODEL';

export const defaultFloor = {
    key: uuidv4(),
    level: 0,
    commonArea: null,
    grossArea: null,
    nettoArea: 0.0,
    commonPercent: 0,
};

export const defaultFloorsData = {
    floors: [],
    basementFloors: [],
};

export const defaultBuilding = {
    buildingHasName: false,
    assignedPlots: [],
    plans: [],
    floorsData: defaultFloorsData,
};

const initialState = {
    building: defaultBuilding,
    buildings: [],
    isUserHasAnyBuildings: false,

    buildingConstructorData: null,

    showConstructorModal: false,
    showCreationResultModal: false,
    showItemUpdatedResultModal: false,
    showConfirmDeleteModal: false,

    buildingFormData: {},

    showDraftModal: false,

    buildingNotFound: false,

    buildingLoading: false,
    deleteLoading: false,

    deleteBuildingModel: null,

    resetAssignedPlots: false,
};

const buildingReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_BUILDING:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case CLEAR_BUILDING:
            newState = update.set(newState, 'building', defaultBuilding);
            break;
        case CLEAR_DELETE_BUILDING_MODEL:
            newState = update.set(newState, 'deleteBuildingModel', null);
            break;
        case SET_DELETE_BUILDING_MODEL:
            newState = update.set(newState, 'deleteBuildingModel', action.payload);
            break;
        case SET_LIST_OF_UPLOAD_ITEMS_BUILDING:
            newState = update.set(newState, 'building.plans', action.payload);
            break;
        case SET_VALUE_BUILDING_FLOOR:
            let floors = newState.buildingConstructorData.floors;
            floors.find((x) => x.key === action.payload.index)[action.payload.key] = action.payload.value;
            newState = update.set(newState, 'buildingConstructorData.floors', floors);
            break;
        case SET_VALUE_BUILDING_BASEMENT_FLOOR:
            let basementFloors = newState.buildingConstructorData.basementFloors;
            basementFloors.find((x) => x.key === action.payload.index)[action.payload.key] = action.payload.value;
            newState = update.set(newState, 'buildingConstructorData.basementFloors', basementFloors);
            break;
        default:
            break;
    }

    return newState;
};

export const setValueBuilding = (key, value) => {
    return {
        type: SET_VALUE_BUILDING,
        payload: {
            key,
            value,
        },
    };
};

export const setValueBuildingFloor = (key, value, index) => {
    return {
        type: SET_VALUE_BUILDING_FLOOR,
        payload: {
            key,
            value,
            index,
        },
    };
};

export const setValueBuildingBasementFloor = (key, value, index) => {
    return {
        type: SET_VALUE_BUILDING_BASEMENT_FLOOR,
        payload: {
            key,
            value,
            index,
        },
    };
};

export const setPlans = (value) => {
    return {
        type: SET_LIST_OF_UPLOAD_ITEMS_BUILDING,
        payload: value,
    };
};

export const clearBuilding = () => {
    return {
        type: CLEAR_BUILDING,
    };
};

export const clearDeleteBuildingModel = () => {
    return {
        type: CLEAR_DELETE_BUILDING_MODEL,
    };
};

export const setDeleteBuildingModel = (value) => {
    return {
        type: SET_DELETE_BUILDING_MODEL,
        payload: value,
    };
};

const handleBuildingError = (error, dispatch) => {
    if (error?.response?.status === StatusCodes.NOT_FOUND) {
        dispatch(setValueBuilding('buildingNotFound', true));
    } else if (error?.response?.status === StatusCodes.FORBIDDEN) {
        dispatch(setValueProperty('propertyNotFound', true));
    } else if (error?.response?.data?.title) {
        toaster.error(error?.response?.data?.title, null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const uploadFile = (file, options, propertyId, buildingId, portfolioId) => {
    return (dispatch, getState) => {
        return buildingService
            .uploadFile(file, options, propertyId, buildingId, portfolioId)
            .then((response) => {
                const files = [...getState().building.building.plans];
                let newFile = response.data;
                newFile.uid = file.uid;
                newFile.percent = maxPercents;
                files.push(newFile);
                dispatch(setPlans(files));
                return newFile;
            })
            .catch((error) => handleBuildingError(error, dispatch));
    };
};

export const downloadFile = (document, propertyId, portfolioId) => {
    return (dispatch) => {
        buildingService
            .getBuildingPlanDownloadLink(document.id, propertyId, portfolioId)
            .then((response) => {
                const secureFileId = response.data;
                const url = `/files/buildings?fileId=${secureFileId}&returnUrl=${window.location.pathname}`;
                const link = window.document.createElement('a');
                link.href = url;
                link.setAttribute('target', '_blank');
                window.document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
                    toaster.error(i18next.t('building.buildingPlanNotFound'), null);
                } else {
                    handleBuildingError(error, dispatch);
                }
            });
    };
};

export const downloadBuildingDocument = (document, name, portfolioId, propertyId) => {
    return (dispatch) => {
        buildingService
            .downloadBuildingPlan(document.id, portfolioId, propertyId)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = window.document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);

                window.document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
                    toaster.error(i18next.t('building.buildingPlanNotFound'), null);
                } else {
                    handleBuildingError(error, dispatch);
                }
            });
    };
};

export const openPdfBuildingDocument = (document, portfolioId, propertyId) => {
    return (dispatch) => {
        buildingService
            .downloadBuildingPlan(document.id, portfolioId, propertyId)
            .then((response) => {
                const file = new Blob([response.data], { type: "application/pdf" });

                const fileURL = URL.createObjectURL(file);

                 const pdfWindow = window.open();
                 pdfWindow.location.href = fileURL; 
            })
            .catch((error) => {
                if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
                    toaster.error(i18next.t('building.buildingPlanNotFound'), null);
                } else {
                    handleBuildingError(error, dispatch);
                }
            });
    };
}

export const deleteFile = (fileId, propertyId, portfolioId) => {
    return (dispatch, getState) =>
        buildingService
            .deleteFile(fileId, propertyId, portfolioId)
            .then((_) => {
                const files = [...getState().building.building.plans].filter((item) => item.id !== fileId);
                dispatch(setPlans(files));
                return true;
            })
            .catch((error) => handleBuildingError(error, dispatch));
};

export const createBuildings = (data, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueBuilding('buildingLoading', true));
        return buildingService
            .createBuildings(data, propertyId, portfolioId)
            .then(() => {
                return true;
            })
            .catch((error) => handleBuildingError(error, dispatch))
            .finally(() => {
                dispatch(setValueBuilding('buildingLoading', false));
            });
    };
};

export const editBuilding = (data, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueBuilding('buildingLoading', true));
        return buildingService
            .edit(data, portfolioId)
            .then(() => {
                return true;
            })
            .catch((error) => handleBuildingError(error, dispatch))
            .finally(() => {
                dispatch(setValueBuilding('buildingLoading', false));
            });
    };
};

export const deleteBuilding = (id, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueBuilding('deleteLoading', true));
        return buildingService
            .delete(id, propertyId, portfolioId)
            .then((_) => true)
            .catch((error) => {
                if (error && error.response && error.response.status && error.response.status === StatusCodes.BAD_REQUEST) {
                    toaster.error(i18next.t('building.deleteForbidden'), null);
                } else {
                    handleBuildingError(error, dispatch);
                }
            })
            .finally(() => {
                dispatch(setValueBuilding('deleteLoading', false));
            });
    };
};

export const getBuildingById = (portfolioId, buildingId, propertyId) => {
    return (dispatch) => {
        dispatch(setValueBuilding('buildingLoading', true));
        buildingService
            .getBuildingById(portfolioId, buildingId, propertyId)
            .then((response) => {
                const building = response.data;
                building.assignedPlots = building.assignedPlots.map((x) => x.id);
                building.buildingHasName = !!building.name;
                const floors = (building.floors || [])
                    .filter((item) => !item.isBasement)
                    .map((obj) => ({
                        ...obj,
                        key: uuidv4(),
                        commonPercent: obj.nettoArea > 0 ? Math.round((obj.commonArea / obj.grossArea) * 100) : 0,
                    }));
                const basementFloors = (building.floors || [])
                    .filter((item) => item.isBasement)
                    .map((obj) => ({
                        ...obj,
                        key: uuidv4(),
                        commonPercent: obj.nettoArea > 0 ? Math.round((obj.commonArea / obj.grossArea) * 100) : 0,
                    }));
                building.floorsData = { basementFloors, floors };
                building.perFloor = basementFloors.length + floors.length > 0;
                dispatch(setValueBuilding('building', building));
            })
            .catch((error) => handleBuildingError(error, dispatch))
            .finally(() => {
                dispatch(setValueBuilding('buildingLoading', false));
            });
    };
};

export default buildingReducer;
