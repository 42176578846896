import { Form, Tooltip } from 'antd';
import get from 'lodash-es/get';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import lastFloor from '../../../assets/images/last-floor.svg';
import trash from '../../../assets/images/cancel-icon.svg';
import basement from '../../../assets/images/basement.svg';
import firstFloor from '../../../assets/images/building-first-floor.svg';
import floor from '../../../assets/images/floor.svg';
import PositiveNumericInput from '../../../components/numeric-input';
import { setValueBuilding, setValueBuildingBasementFloor, setValueBuildingFloor } from '../../../reducers/buildingReducer';

const FloorFormComponent = (props) => {
    const { t } = useTranslation();
    const [nettoValue, setNettoValue] = useState(props.floor.nettoArea || 0);
    const [commonPercentValue, setCommonPercentValue] = useState(props.floor.commonPercent || 0);
    const [lettableValue, setLettableValue] = useState(props.floor.lettable || 0);
    const [nonLettable, setNonLettable] = useState(props.floor.nonLettable || 0);

    useEffect(() => {
        if (props.floor.id) {
            let floor = {};
            floor['commonArea-' + props.floor.key] = props.floor.commonArea;
            floor['grossArea-' + props.floor.key] = props.floor.grossArea;
            floor['nonLettable-' + props.floor.key] = nonLettable;
            props.floorsForm.setFieldsValue(floor);
        }
    }, []);

    const updateFields = (nonLettable) => {
        const nonLettableValue = nonLettable || props.floor.nonLettable;
        const lettable = props.isNonLettable ? +(props.floor.grossArea - nonLettableValue).toFixed(2) : props.floor.grossArea;
        const netto = +(lettable - props.floor.commonArea).toFixed(2);
        const commonPercent = netto >= 0 ? Math.round((props.floor.commonArea / lettable) * 100) : 0;
        setLettableValue(lettable);
        setCommonPercentValue(commonPercent);
        setNettoValue(netto);
        if (props.floor.isBasement) {
            props.setValueBuildingBasementFloorCall('commonPercent', commonPercent, props.floor.key);
            props.setValueBuildingBasementFloorCall('nettoArea', netto, props.floor.key);
            props.setValueBuildingBasementFloorCall('lettable', lettable, props.floor.key);
            props.setValueBuildingBasementFloorCall('nonLettable', props.isNonLettable ? nonLettableValue : 0, props.floor.key);
            setGrossAndCommonArea(props.floors, props.basementFloors);
        } else {
            props.setValueBuildingFloorCall('commonPercent', commonPercent, props.floor.key);
            props.setValueBuildingFloorCall('nettoArea', netto, props.floor.key);
            props.setValueBuildingFloorCall('lettable', lettable, props.floor.key);
            props.setValueBuildingFloorCall('nonLettable', props.isNonLettable ? nonLettableValue : 0, props.floor.key);
            setGrossAndCommonArea(props.floors, props.basementFloors);
        }
    };

    useEffect(() => {
        updateFields(nonLettable);
    }, [props.isNonLettable]);

    const setValue = (key, value) => {
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value !== '' || value !== '-') {
            const newValue = value !== null ? +value.toFixed(2) : null;
            if (props.floor.isBasement) {
                console.log(key, value);
                props.setValueBuildingBasementFloorCall(key, newValue, props.floor.key);
            } else {
                props.setValueBuildingFloorCall(key, newValue, props.floor.key);
            }
            if (props.floor.commonArea >= 0 && props.floor.grossArea >= 0) {
                updateFields();
            } else {
                if (props.floor.isBasement) {
                    clearBasementFloor();
                } else {
                    clearFloor();
                }
            }
            props.floorsForm.validateFields();
        }
    };

    const setGrossAndCommonArea = (floors, basementFloors) => {
        const grossArea = floors.reduce((n, { grossArea }) => (typeof grossArea === 'number' ? n + grossArea : 0), 0).roundNum();
        const grossAreaBasement = basementFloors.reduce((n, { grossArea }) => (typeof grossArea === 'number' ? n + grossArea : 0), 0).roundNum();
        const commonArea = floors.reduce((n, { commonArea }) => (typeof commonArea === 'number' ? n + commonArea : 0), 0).roundNum();
        const commonAreaBasement = basementFloors.reduce((n, { commonArea }) => (typeof commonArea === 'number' ? n + commonArea : 0), 0).roundNum();
        props.setValueBuildingCall('buildingConstructorData.grossArea', grossArea + grossAreaBasement);
        props.setValueBuildingCall('buildingConstructorData.commonArea', commonArea + commonAreaBasement);

        const nonLettableArea = props.isNonLettable ? floors.reduce((n, { nonLettable }) => (typeof nonLettable === 'number' ? n + nonLettable : 0), 0) : 0;
        const nonLettableAreaBasement = props.isNonLettable
            ? basementFloors.reduce((n, { nonLettable }) => (typeof nonLettable === 'number' && props.isNonLettable ? n + nonLettable : 0), 0)
            : 0;
        props.setValueBuildingCall('buildingConstructorData.nonLettable', nonLettableArea + nonLettableAreaBasement);
    };

    const clearFloor = () => {
        props.setValueBuildingFloorCall('commonPercent', 0, props.floor.key);
        props.setValueBuildingFloorCall('nettoArea', 0, props.floor.key);
        setCommonPercentValue(0);
        setNettoValue(0);
    };

    const clearBasementFloor = () => {
        props.setValueBuildingBasementFloorCall('commonPercent', 0, props.floor.key);
        props.setValueBuildingBasementFloorCall('nettoArea', 0, props.floor.key);
        setCommonPercentValue(0);
        setNettoValue(0);
    };

    const onDelete = () => {
        if (!props.floor.isUsedAsRentalObject) {
            props.onDelete(props.floor.key);
            const floors = props.floors.filter((item) => item.key !== props.floor.key);
            const basementFloors = props.basementFloors.filter((item) => item.key !== props.floor.key);
            setGrossAndCommonArea(floors, basementFloors);
        }
    };

    const commonAreaValidator = (_) => {
        let array = props.floor.isBasement ? [...props.basementFloors] : [...props.floors];
        const floor = array.find((x) => x.key === props.floor.key);
        if (floor && floor.grossArea && floor.commonArea && floor.commonArea > lettableValue) {
            return Promise.reject(new Error(' '));
        }
        return Promise.resolve();
    };

    const nonLettableAreaValidator = (_) => {
        let array = props.floor.isBasement ? [...props.basementFloors] : [...props.floors];
        const floor = array.find((x) => x.key === props.floor.key);
        if (floor && floor.grossArea && floor.nonLettable && floor.nonLettable > floor.grossArea) {
            return Promise.reject(new Error(' '));
        }
        return Promise.resolve();
    };

    return (
        <div className="floor-row" key={props.floor.key}>
            <Row gutter={[8, 8]} className="mx-0 floor-data-row">
                <Col xs={12} sm={1} className="align-self-center floor-display-data-column">
                    <div className="floor-header">
                        <p className="floor-header-text">{t('building.buildingConstructor.level')}</p>
                        <p className="font-weight-bold">{props.level}</p>
                    </div>
                </Col>
                <Col xs={12} sm={9}>
                    <Row className="mx-0 floor-data-row">
                        <Col xs={12} sm={2} className="pl-2 align-self-center floor-column">
                            <Form.Item
                                initialValue={props.floor.grossArea}
                                name={`grossArea-${props.floor.key}`}
                                rules={[
                                    {
                                        required: true,
                                        message: t('building.buildingConstructor.gross') + t('common.isRequired'),
                                    },
                                ]}>
                                <div className="floor-header">
                                    <p className="floor-header-text">{t('building.buildingConstructor.gross')}</p>
                                    <PositiveNumericInput defaultValue={props.floor.grossArea} className="floor-input" onChange={(value) => setValue('grossArea', value)} />
                                </div>
                            </Form.Item>
                        </Col>
                        {props.isNonLettable && (
                            <Col xs={12} sm={2} className="pl-2 floor-column align-self-center">
                                <Form.Item
                                    initialValue={props.floor.nonLettable}
                                    name={`nonLettable-${props.floor.key}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: ' ',
                                        },
                                        {
                                            validator(_, value) {
                                                return nonLettableAreaValidator();
                                            },
                                        },
                                    ]}>
                                    <div className="floor-header align-self-center">
                                        <p className="floor-header-text">{t('building.buildingConstructor.nonLettable')}</p>
                                        <PositiveNumericInput
                                            defaultValue={nonLettable}
                                            className="floor-input"
                                            onChange={(value) => {
                                                setNonLettable(value);
                                                setValue('nonLettable', value);
                                            }}
                                        />
                                    </div>
                                </Form.Item>
                            </Col>
                        )}
                        <Col xs={12} sm={props.isNonLettable ? 2 : 2} className="floor-column align-self-center floor-display-data-column">
                            <div className="floor-header">
                                <p className="floor-header-text">{t('building.buildingConstructor.lettable')}</p>
                                <p className="text-secondary" style={{ fontSize: '14px' }}>
                                    {lettableValue}
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} sm={2} className="pl-2 floor-column align-self-center">
                            <Form.Item
                                initialValue={props.floor.commonArea}
                                name={`commonArea-${props.floor.key}`}
                                rules={[
                                    {
                                        required: true,
                                        message: ' ',
                                    },
                                    {
                                        validator(_, value) {
                                            return commonAreaValidator();
                                        },
                                    },
                                ]}>
                                <div className="floor-header align-self-center">
                                    <p className="floor-header-text">{t('building.buildingConstructor.common')}</p>
                                    <PositiveNumericInput defaultValue={props.floor.commonArea} avoidNull={false} className="floor-input" onChange={(value) => setValue('commonArea', value)} />
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={2} className="floor-column align-self-center floor-display-data-column">
                            <div className="floor-header">
                                <p className="floor-header-text">{t('building.buildingConstructor.netto')}</p>
                                <p className="text-secondary" style={{ fontSize: '14px' }}>
                                    {nettoValue}
                                </p>
                            </div>
                        </Col>
                        <div className="mobile-part">
                            <Col xs={12} className="floor-column align-self-center">
                                <div className="floor-header">
                                    <p className="floor-header-text">{t('building.buildingConstructor.commonPercent')}</p>
                                    <p className="text-secondary" style={{ fontSize: '14px' }}>
                                        {commonPercentValue}
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} className={`floor-column align-self-center ${props.floor.isUsedAsRentalObject ? 'trash-button-disabled' : ''}`}>
                                <div className="d-flex h-100 justify-content-end">
                                    <Image className="pointer" src={trash} onClick={onDelete} />
                                </div>
                            </Col>
                        </div>
                        <Col xs={12} sm={2} className="align-self-center floor-display-data-column">
                            <div className="desktop-part h-100">
                                <p className="floor-header-text">{t('building.buildingConstructor.commonPercent')}</p>
                                <div className="floor-header">
                                    <p className="text-secondary" style={{ fontSize: '14px' }}>
                                        {commonPercentValue}%
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={2}>
                    <Row className="floor-data-row floor-row-image">
                        <Col xs={12} sm={1} className="align-self-center">
                            <div className="constructor-image">
                                {props.level > 1 ? (
                                    props.level === props.floors.length ? (
                                        <Image src={lastFloor} />
                                    ) : (
                                        <Image src={floor} />
                                    )
                                ) : props.level <= 1 && props.floor.isBasement ? (
                                    <Image src={basement} />
                                ) : (
                                    <Image src={firstFloor} />
                                )}
                            </div>
                        </Col>
                        {props.floor.isUsedAsRentalObject ? (
                            <Col xs={12} sm={1} className='desktop-part align-self-center trash-button trash-button-disabled'>
                                <div className="d-flex h-100">
                                    <Tooltip
                                        title={t('building.buildingConstructor.disabledDelete')}>
                                        <Image className="delete-icon" src={trash} onClick={onDelete} />
                                    </Tooltip>
                                </div>
                            </Col>
                        ) : (
                            <Col xs={12} sm={1} className='desktop-part align-self-center trash-button'>
                                <div className="d-flex h-100">
                                    <Image className="delete-icon" src={trash} onClick={onDelete} />
                                </div>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

const mapState = ({ building }) => {
    return {
        floors: get(building, 'buildingConstructorData.floors'),
        basementFloors: get(building, 'buildingConstructorData.basementFloors'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueBuildingFloorCall(key, value, index) {
            dispatch(setValueBuildingFloor(key, value, index));
        },
        setValueBuildingBasementFloorCall(key, value, index) {
            dispatch(setValueBuildingBasementFloor(key, value, index));
        },
        setValueBuildingCall(key, value) {
            dispatch(setValueBuilding(key, value));
        },
    };
};

const FloorForm = connect(mapState, mapDispatch)(FloorFormComponent);
export default FloorForm;
