import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import LoginSignUpLayout from '../../components/layouts/login-layout';
import { Button } from 'antd';
import { validateInvitationToken } from '../../reducers/signUpReducer';
import get from 'lodash-es/get';
import { connect } from 'react-redux';
import clear from '../../common/clear';
import LoaderSpinner from '../../components/spinner';
import routingService from '../../services/routingService';

const Invited = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        var token = queryString.parse(window.location.search).token;

        if (!token) {
            routingService.navigateToLogin();
        } else {
            props.validateInvitationTokenCall(token);
        }
    }, []);

    return props.invitationLoading ? (
        <LoaderSpinner />
    ) : (
        <LoginSignUpLayout header={t('common.thankYou')}>
            <div className="h-100 registered">
                <div className="d-flex align-items-center flex-column justify-content-center">
                    <div className="inner-content mb-3">
                        <div>{t('invitedMessage')}</div>
                    </div>
                    <div className="bottom-content">
                        <Button className="btn-primary btn-block" onClick={() => routingService.navigateToLogin()}>
                            {t('loginSignUpPage.login')}
                        </Button>
                    </div>
                </div>
            </div>
        </LoginSignUpLayout>
    );
};

const mapState = ({ register }) => {
    return {
        invitationLoading: get(register, 'invitationLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        validateInvitationTokenCall(token) {
            dispatch(validateInvitationToken(token));
        },
    };
};

export default clear(connect(mapState, mapDispatch)(Invited));
