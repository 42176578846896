import i18next from 'i18next';
import React from 'react';
import routingService from '../../services/routingService';
import ActionButton from '../action-button';

const NotFoundPage = ({ header }) => (
    <div className="error-page">
        <div className="elements">
            <p className="number-of-error">404</p>
            <div className="d-flex flex-column">
                <p className="error-header">{header ? header : null}</p>
                <p className="message">{i18next.t('common.notFoundMessage')}</p>
                <ActionButton className="btn-primary ml-3 mt-4" text={i18next.t('common.backToPortfolio')} onClick={() => routingService.navigateToPortfolios()} />
            </div>
        </div>
    </div>
);

export default NotFoundPage;
