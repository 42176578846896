import i18next from 'i18next';
import React from 'react';
import { Image } from 'react-bootstrap';
import trash from '../../assets/images/trash-default.svg';
import ActionButton from '../action-button';

const CreatedComponent = (props) => {
    return (
        <div className="created-object" key={props.id}>
            <h5>{props.title}</h5>
            <div className="d-flex flex-row align-items-basement">
                {/* <ActionButton className="btn-transparent btn-link" text={i18next.t('common.buttons.edit')} onClick={() => props.editObject(props.id)} /> */}
                {props.deleteObject && (
                    <ActionButton className="btn-transparent btn-link ml-2" icon={<Image src={trash} className="m-0" />} onClick={() => props.deleteObject(props.id)} />
                )}
            </div>
        </div>
    );
};

export default CreatedComponent;
