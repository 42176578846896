import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import chartService from '../../../../services/chartService';
import { selectMergedBudgetAndActualItems, showActualsSelector, commonCostBudgetItemsSelector } from '../../selectors';

const BudgetsAndActualsChartComponent = () => {
    const id = 'budgets-and-actuals-chart';
    const [chart, setChart] = useState(null);
    const showActuals = useSelector(showActualsSelector);
    const budgetItems = useSelector(commonCostBudgetItemsSelector);
    const mergedItems = useSelector(selectMergedBudgetAndActualItems);

    useEffect(() => {
        initChart();
    }, [budgetItems, showActuals]);

    const mapBudgetsAndActualsChartData = () => {
        return mergedItems.map((x) => ({
            name: x.key,
            valueBudget: x.budgetItem?.value ?? 0,
            valueActual: x.actualItem?.value ?? 0,
        }));
    };

    const mapBudgetsChartData = () => {
        return budgetItems.map((cost) => ({
            name: cost.name,
            value: cost.value || 0,
        }));
    };

    const initChart = () => {
        setChart(showActuals ? chartService.costsBudgetsAndActualsChart(id, mapBudgetsAndActualsChartData()) : chartService.costsBudgetsChart(id, mapBudgetsChartData()));
    };

    return <div id={id} style={{ height: '224px' }}></div>;
};

export default BudgetsAndActualsChartComponent;
