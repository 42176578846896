import { Col, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { contractStatus, unitType, warrantyTypeOptions } from '../../../common/contract-options';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import helper from '../../../helpers/helper';
import { cellColors, emptyMark } from './rent-roll-table';

const { Text } = Typography;

export const getValueByNestedProp = (object, prop) => {
    const props = prop.split('.');
    let value = object;

    for (let i = 0; i < props.length; i++) {
        if (value && value.hasOwnProperty(props[i])) {
            value = value[props[i]];
        } else {
            value = null;
            break;
        }
    }

    return value;
};

export const calculateTotalRentMetersValue = (contract, totalMeters) => {
    const yearlyRentSum = contract.rentObjects.filter(item => !!item.squareMeters).reduce((sum, elem) => {
        if (elem?.contractedRentByYears?.yearlyRentPaymentPlanItem?.fullRentAmount) {
            sum += elem?.contractedRentByYears?.yearlyRentPaymentPlanItem?.fullRentAmount;
        }
        return sum; 
    }, 0);

    return yearlyRentSum / totalMeters;
}

export const calculateTotalRentUnitsValue = (contract, totalUnits) => {
    const yearlyRentSum = contract.rentObjects.filter(item => !!item.units).reduce((sum, elem) => {
        if (elem?.contractedRentByYears?.yearlyRentPaymentPlanItem?.fullRentAmount) {
            sum += elem?.contractedRentByYears?.yearlyRentPaymentPlanItem?.fullRentAmount;
        }
        return sum; 
    }, 0);

    return yearlyRentSum / totalUnits;
}

export const calculateContractRentWithExemptions = (contract) => {
    return contract.rentObjects.reduce((sum, element) => {
        const value = element?.rentByYears?.yearlyRentPaymentPlanItem?.fullRentAmount - element?.rentByYears?.yearlyRentPaymentPlanItem?.exemptionAmount;
        sum += value;

        return sum;
    }, 0);
}

const RentRollTotalRow = (props) => {
    const { contract, isHeader, isActive, isContractExpiring, isContractStarting } = props;

    const { t } = useTranslation();

    const calculateRentObjectPropSum = (props) => {
        return contract.rentObjects.reduce((sum, element) => {
            for (let i = 0; i < props.length; i++) {
                const value = getValueByNestedProp(element, props[i]);
                if (value !== null) {
                    sum += value;
                    break;
                }
            }
            return sum;
        }, 0);
    };

    const getRowCell = (value, mark, isNumber = true, cellColor, decimalPoints = 1, isTooltipNeeded, textAlign = 'center') => {
        if (!value) {
            return (
                <Text ellipsis={{ tooltip: isTooltipNeeded }} className="rent-object-cell" style={{ textAlign: textAlign }}>
                    {mark}
                </Text>
            );
        }

        const roundedValue = isNumber ? value?.toFixed(decimalPoints) : value;

        return (
            <Text className="rent-object-cell" ellipsis={{ tooltip: isTooltipNeeded }} style={{ color: cellColor, textAlign: textAlign }}>
                {isNumber ? helper.numberWithSpaces(roundedValue) : value}
            </Text>
        );
    };

    const getCellColor = () => {
        if (isContractExpiring) {
            return cellColors.red;
        }

        if (isContractStarting) {
            return cellColors.green;
        }

        return cellColors.default;
    };

    const getTurnoverCellValue = () => {
        if (contract?.rentObjects?.length === 1) {
            if (contract?.rentObjects[0]?.turnoverId) {
                return contract?.rentObjects[0]?.turnover?.turnoverRates?.map((item) => `${item?.rate}%`)?.join(' | ');
            } else {
                return emptyMark.notApplicable;
            }
        } else {
            if (contract?.rentObjects?.some((item) => !!item?.turnoverId)) {
                return t('rentRoll.mix');
            } else {
                return emptyMark.notApplicable;
            }
        }
    };

    const getVatCellValue = () => {
        const isSameValues = [...new Set(contract?.rentObjects?.map((item) => item.hasVatCalculation))].length == 1;
        return isSameValues ? (contract?.rentObjects[0].hasVatCalculation ? t('common.yes') : t('common.no')) : t('rentRoll.mix');
    };

    return (
        <Row className={`${isHeader ? 'rent-roll-total-row-header' : 'rent-roll-table-total'}`}>
            <Col span={4} justify="center" className="contract-name">
                {isHeader ? getRowCell(contract?.name, emptyMark.empty, false, getCellColor(), 1, true, 'left') : ''}
            </Col>
            {!(isHeader && isActive) && (
                <>
                    <Col span={1}>{getRowCell(calculateRentObjectPropSum(['squareMeters']), emptyMark.hyphen, true, getCellColor(), null, null, 'end')}</Col>
                    <Col span={1}>{getRowCell(calculateRentObjectPropSum(['units']), emptyMark.hyphen, true, getCellColor(), null, null, 'end')}</Col>
                    <Col span={1}>
                        {getRowCell(
                            calculateTotalRentMetersValue(contract, calculateRentObjectPropSum(['rentSquareMetersCpiAdjusted.currentRentCpiAdjusted', 'rentSquareMeters'])),
                            emptyMark.hyphen,
                            true,
                            null,
                            null,
                            null,
                            'end'
                        )}
                    </Col>
                    <Col span={2}>
                        {getRowCell(calculateTotalRentUnitsValue(contract, calculateRentObjectPropSum(['rentUnitsCpiAdjusted.currentRentCpiAdjusted', 'rentUnits'])), emptyMark.hyphen, true, null, null, null, 'end')}
                    </Col>
                    <Col span={1}>{getRowCell(getTurnoverCellValue(), emptyMark.notApplicable, false, null, null, null, 'end')}</Col>
                    <Col span={2}>
                        {getRowCell(calculateContractRentWithExemptions(contract), emptyMark.hyphen, true, getCellColor(), 2, null, 'end')}
                    </Col>
                    <Col span={2}>
                        {getRowCell(
                            calculateRentObjectPropSum(['contractedRentByYears.yearlyRentPaymentPlanItem.fullRentAmount']),
                            emptyMark.hyphen,
                            true,
                            getCellColor(),
                            2,
                            null,
                            'end'
                        )}
                    </Col>
                    <Col span={2}>
                        {getRowCell(
                            moment(contract?.leaseStartDate).format(primaryDateFormat),
                            emptyMark.hyphen,
                            false,
                            isContractStarting ? cellColors.green : cellColors.default,
                            null,
                            null,
                            'end'
                        )}
                    </Col>
                    <Col span={2}>
                        {getRowCell(
                            moment(contract?.leaseEndDate).format(primaryDateFormat),
                            emptyMark.hyphen,
                            false,
                            isContractExpiring ? cellColors.red : cellColors.default,
                            null,
                            null,
                            'start'
                        )}
                    </Col>
                    <Col span={2}>
                        {contract?.status == contractStatus.terminated
                            ? getRowCell(`Term. ${moment(contract?.terminatedDate).format(primaryDateFormat)}`, emptyMark.hyphen, false, cellColors.red, null, null, 'center')
                            : getRowCell(contract?.remaining, emptyMark.hyphen, true, null, null, null, 'center')}
                    </Col>
                    <Col span={1}>{getRowCell(contract?.hasRentAdjustmentOptions && `${contract?.cpiRate}%`, emptyMark.hyphen, false, null, null, null, 'end')}</Col>
                    <Col span={1}>{getRowCell(emptyMark.hyphen, emptyMark.hyphen, false)}</Col>
                    <Col span={1}>{getRowCell(getVatCellValue(), emptyMark.hyphen, false, null, null, null, 'end')}</Col>
                    <Col span={1}>
                        {getRowCell(
                            contract?.contractWarranties?.length > 0 && helper.getEnumValue(warrantyTypeOptions, contract?.contractWarranties[0]?.warrantyTypeId),
                            t('rentRoll.mix'),
                            false,
                            null,
                            null,
                            true,
                            'end'
                        )}
                    </Col>
                </>
            )}
        </Row>
    );
};

export default RentRollTotalRow;
