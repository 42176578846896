import i18next from 'i18next';
import { StatusCodes } from 'http-status-codes';
import localStorageService from '../services/localStorageService';
import authService from '../services/authService';
import loginService from '../services/loginService';
import routingService from '../services/routingService';
import toaster from '../common/toaster';
import update from '../helpers/update';
import { clearNavigationData } from './navigationReducer';

const SET_VALUE_LOGIN = 'SET_VALUE_LOGIN';
const SET_ERROR_LOGIN = 'SET_ERROR_LOGIN';

const initialState = {
    user: {},
    loading: false,
    error: null,
};

const loginReducer = (state = initialState, action) => {
    let newState = { ...state };
    switch (action.type) {
        case SET_VALUE_LOGIN:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case SET_ERROR_LOGIN:
            newState = update.set(newState, 'error', action.payload);
            break;
        default:
            break;
    }

    return newState;
};

export const setValueLogin = (key, value) => {
    return {
        type: SET_VALUE_LOGIN,
        payload: {
            key,
            value,
        },
    };
};

export const setError = (value) => {
    if (value.response && value.response.data) {
        return {
            type: SET_ERROR_LOGIN,
            payload: value.response.data.title,
        };
    }
    return {
        type: SET_ERROR_LOGIN,
        payload: i18next.t('common.serverErrorMessage'),
    };
};

export const login = (userEmail, password, returnUrl) => {
    return (dispatch) => {
        dispatch(setValueLogin('loading', true));
        loginService
            .getToken(userEmail, password)
            .then((response) => {
                dispatch(clearNavigationData());
                authService.setIdentity(response.data);
                localStorageService.setUser(response.data.user);
                const url = returnUrl || routingService.portfoliosUrl();
                routingService.goTo(url);
            })
            .catch((error) => {
                if (error && error.response && error.response.status && error.response.status === StatusCodes.UNAUTHORIZED) {
                    toaster.error(error.response.data.title, null);
                } else {
                    toaster.error(i18next.t('common.serverErrorMessage'), null);
                }
            })
            .finally(() => {
                dispatch(setValueLogin('loading', false));
            });
    };
};

export default loginReducer;
