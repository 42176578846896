import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Collapse, Form, Row, Switch } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import RentAdjustmentOnHandover from '../../../../components/contract/rent-adjustment/on-handover';
import RentAdjustmentOptions from '../../../../components/contract/rent-adjustment/general';
import RentAdjustmentSettings from '../../../../components/contract/rent-adjustment/settings';
import RentAdjustmentRentalObjectCard from './rent-object-edit-card';
import { defaultRentAdjustmentOptions } from '../../../../reducers/contractReducer';
import { cpiIndexTypes } from '../../../../common/contract-options';

const RentAdjustmentComponent = (props) => {
    const { t } = useTranslation();

    const [cpiAdjustment, setCpiAdjustment] = useState();
    const [activeKeys, setActiveKeys] = useState([]);

    useEffect(() => {
        if (props.contract) {
            const defaultOptions = { ...defaultRentAdjustmentOptions };
            let {
                originalBaseIndexDate,
                yearlyCpiIndexDate,
                firstTimeCpiAdjustmentDate,
                yearlyCpiAdjustmentDate,
                cpiIndexType,
                cpiRate,
                cpiSource,
                yearlyTenantReminderOnCpiAdjustment,
                lastKnownCpiRegulation,
                cpiAdjustmentOnHandover,
                hasRentAdjustmentOptions
            } = props.contract;

            originalBaseIndexDate = originalBaseIndexDate ? moment(originalBaseIndexDate).utc(true) : null;
            yearlyCpiIndexDate = yearlyCpiIndexDate ? moment(yearlyCpiIndexDate).utc(true) : defaultOptions.yearlyCpiIndexDate;
            firstTimeCpiAdjustmentDate = firstTimeCpiAdjustmentDate ? moment(firstTimeCpiAdjustmentDate).utc(true) : null;
            yearlyCpiAdjustmentDate = yearlyCpiAdjustmentDate ? moment(yearlyCpiAdjustmentDate).utc(true) : defaultOptions.yearlyCpiAdjustmentDate;
            cpiRate = cpiRate || defaultOptions.cpiRate;
            cpiSource = cpiSource || defaultOptions.cpiSource;
            cpiIndexType = cpiIndexType || cpiIndexTypes.floating;
            hasRentAdjustmentOptions = hasRentAdjustmentOptions != undefined ? hasRentAdjustmentOptions : !props.rentObjects.some(item => item.turnover?.minimunRentYearlyId)
            setCpiAdjustment(hasRentAdjustmentOptions);

            props.rentAdjustmentRef.current.setFieldsValue({
                originalBaseIndexDate,
                yearlyCpiIndexDate,
                firstTimeCpiAdjustmentDate,
                yearlyCpiAdjustmentDate,
                cpiIndexType,
                cpiRate,
                cpiSource,
                yearlyTenantReminderOnCpiAdjustment,
                lastKnownCpiRegulation,
                cpiAdjustmentOnHandover,
                hasRentAdjustmentOptions
            });
        }
    }, []);

    return (
        <React.Fragment>
            <Form name="basic" autoComplete="off" onValuesChange={props.onValuesChange} ref={props.rentAdjustmentRef}>
                <div className="page-container mb-2 pb-0">
                    <h5>{t('contract.rentAdjustment.title')}</h5>
                    <Row>
                        <Col span={6}>
                            <p>{t('contract.rentAdjustment.cpiAdjustment')}</p>
                            <p>{cpiAdjustment ? 'On' : 'Off'}</p>
                        </Col>
                        <Col>
                            <Form.Item name="hasRentAdjustmentOptions" valuePropName="checked" >
                                <Switch checked={cpiAdjustment} onChange={(checked) => setCpiAdjustment(checked)} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <div className="page-container">
                    <RentAdjustmentOptions formRef={props.rentAdjustmentRef} cpiAdjustment={cpiAdjustment} />
                </div>
                <div className="page-container cpi-adjustment-handover pb-2">
                    <RentAdjustmentOnHandover onValuesChange={props.onValuesChange} cpiAdjustment={cpiAdjustment} />
                </div>
                <div className="page-container cpi-settings pb-2">
                    <RentAdjustmentSettings cpiAdjustment={cpiAdjustment} />
                </div>
            </Form>
            <div className="page-container edit-rent-part edit-rent-cpi">
                <h5 className="mb-1">{t('contract.rentAdjustment.editRentalObjectsCpi.title')}</h5>
                <p className="text-secondary">{t('contract.rentAdjustment.editRentalObjectsCpi.subTitle')}</p>
                <Collapse
                    activeKey={activeKeys}
                    onChange={(values) => setActiveKeys(values)}
                    className='contract-collapse'
                    expandIcon={({ isActive }) => isActive ? <div className='collapse-btn btn-transparent' /> : <div className='collapse-btn collapsed btn-transparent' />}>
                    {props.rentObjects.map((rentObject) => (
                            <Collapse.Panel
                                className='contract-collapse-item'
                                header={<h5 className='mb-0'>{rentObject.displayName}</h5>}
                                key={rentObject.id}
                            >
                                <RentAdjustmentRentalObjectCard key={rentObject.uId} rentObject={rentObject} setActiveKeys={setActiveKeys} />
                            </Collapse.Panel>
                        ))
                    }
                </Collapse>
            </div>
        </React.Fragment>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        rentObjects: get(contract, 'rentObjects'),
    };
};

const RentAdjustment = connect(mapState, null)(RentAdjustmentComponent);
export default RentAdjustment;
