import get from 'lodash-es/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import toaster from '../../../../common/toaster';
import ConfirmDeleteModal from '../../../../components/confirm-delete-modal';
import { deleteInvoice, setValueInvoice } from '../../../../reducers/invoiceReducer';

const ConfirmDeleteInvoiceComponent = (props) => {
    const { t } = useTranslation();

    const onClose = () => {
        props.closeModalWindow();
        props.clearInvoice();
    };

    const handleDeleteClick = async () => {
        await props.deleteInvoiceCall(props.invoice.id);

        onClose();
        toaster.success(t('common.itemDeletedSuccessfully', { name: props.invoice.invoiceNumber }));
    };

    return (
        <ConfirmDeleteModal
            onClose={onClose}
            onConfirm={handleDeleteClick}
            title={t('backoffice.invoices.delete.title', { name: props.invoice.invoiceNumber })}
            text={t('backoffice.invoices.delete.sentence')}
            loading={props.loading}
            deleteBtnText={t('backoffice.invoices.delete.deleteBtnText')}
        />
    );
};

const mapState = ({ invoice }) => {
    return {
        invoice: get(invoice, 'invoice'),
        loading: get(invoice, 'loading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueInvoice('showConfirmDeleteModal', false));
        },
        deleteInvoiceCall(invoiceId) {
            return dispatch(deleteInvoice(invoiceId));
        },
        clearInvoice() {
            dispatch(setValueInvoice('invoice', null));
        },
    };
};

const ConfirmDeleteInvoice = connect(mapState, mapDispatch)(ConfirmDeleteInvoiceComponent);
export default ConfirmDeleteInvoice;
