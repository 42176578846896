import { StatusCodes } from 'http-status-codes';
import { setValueProperty } from './propertyReducer';
import i18next from 'i18next';
import update from '../helpers/update';
import tenantService from '../services/tenantService';
import toaster from '../common/toaster';
import commonService from '../services/commonService';
import { filterOptions } from '../common/property-options';

const SET_VALUE_TENANT = 'SET_VALUE_TENANT';
const SUCCESS_SEARCH_TENANT = 'SUCCESS_SEARCH_TENANT';
const SUCCESS_GET_TENANT_BY_ID = 'SUCCESS_GET_TENANT_BY_ID';
const SUCCESS_GET_TENANT_DETAILS = 'SUCCESS_GET_TENANT_DETAILS';
const SET_ADDITIONAL_TENANT_INFO = 'SET_ADDITIONAL_TENANT_INFO';
const CLEAR_ADDITIONAL_TENANT_INFO = 'CLEAR_ADDITIONAL_TENANT_INFO';
const CLEAR_TENANT = 'CLEAR_TENANT';
const CLEAR_DELETE_TENANT_MODEL = 'CLEAR_DELETE_TENANT_MODEL';
const SET_DELETE_TENANT_MODEL = 'SET_DELETE_TENANT_MODEL';

export const defaultTenant = {
    ownedBy: commonService.ownerRadioGroupTypes.company,
};

const initialState = {
    tenants: [],
    tenant: defaultTenant,
    tenantDetails: null,
    tenantSummary: null,
    isUserHasAnyTenants: false,

    portfolioTenants: [],

    searchLoading: false,
    tenantLoading: false,
    deleteLoading: false,
    tenantsLoading: false,

    showDraftModal: false,

    tenantNotFound: false,

    showCreationResultModal: false,
    showCreateTenantResultModal: false,
    showItemUpdatedResultModal: false,
    showConfirmDeleteModal: false,
    showModalForTenantCreation: false,

    deleteTenantModel: null,

    filterOption: filterOptions.active
};

const tenantReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_TENANT:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case CLEAR_DELETE_TENANT_MODEL:
            newState = update.set(newState, 'deleteTenantModel', null);
            break;
        case SET_DELETE_TENANT_MODEL:
            newState = update.set(newState, 'deleteTenantModel', action.payload);
            break;
        case SUCCESS_SEARCH_TENANT:
            newState = update.set(newState, 'tenants', action.payload.tenants);
            newState = update.set(newState, 'searchLoading', false);
            break;
        case SUCCESS_GET_TENANT_BY_ID:
            newState = update.set(newState, 'tenant', action.payload);
            break;
        case SUCCESS_GET_TENANT_DETAILS:
            newState = update.set(newState, 'tenantDetails', action.payload);
            break;
        case SET_ADDITIONAL_TENANT_INFO:
            newState = update.set(newState, 'tenant.companyInfo', action.payload);
            break;
        case CLEAR_ADDITIONAL_TENANT_INFO:
            newState = update.set(newState, 'tenant.companyInfo', null);
            break;
        case CLEAR_TENANT:
            newState = update.set(newState, 'tenant', defaultTenant);
            break;
        default:
            break;
    }

    return newState;
};

export const setValueTenant = (key, value) => {
    return {
        type: SET_VALUE_TENANT,
        payload: {
            key,
            value,
        },
    };
};

export const successSearchTenants = (value) => {
    return {
        type: SUCCESS_SEARCH_TENANT,
        payload: value,
    };
};

export const clearTenant = () => {
    return {
        type: CLEAR_TENANT,
    };
};

export const clearDeleteTenantModel = () => {
    return {
        type: CLEAR_DELETE_TENANT_MODEL,
    };
};

export const setDeleteTenantModel = (value) => {
    return {
        type: SET_DELETE_TENANT_MODEL,
        payload: value,
    };
};

export const successGetTenantById = (value) => {
    return {
        type: SUCCESS_GET_TENANT_BY_ID,
        payload: value,
    };
};

export const successGetTenantDetails = (value) => {
    return {
        type: SUCCESS_GET_TENANT_DETAILS,
        payload: value,
    };
};

export const setAdditionalTenantInfo = (value) => {
    return {
        type: SET_ADDITIONAL_TENANT_INFO,
        payload: value,
    };
};

export const clearAdditionalTenantInfo = () => {
    return {
        type: CLEAR_ADDITIONAL_TENANT_INFO,
    };
};

const handleTenantError = (error, dispatch) => {
    if (error && error.response && error.response.status && error.response.status === StatusCodes.NOT_FOUND) {
        dispatch(setValueTenant('tenantNotFound', true));
    } else if (error && error.response && error.response.status && error.response.status === StatusCodes.FORBIDDEN) {
        dispatch(setValueProperty('propertyNotFound', true));
    } else if (error && error.response && error.response.title) {
        toaster.error(error.response.title, null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const createTenants = (values, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueTenant('tenantLoading', true));
        return tenantService
            .create(values, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueTenant('tenants', response.data));
                return response.data;
            })
            .catch((error) => handleTenantError(error, dispatch))
            .finally(() => {
                dispatch(setValueTenant('tenantLoading', false));
            });
    };
};

export const editTenant = (value, propertyId,  portfolioId) => {
    return (dispatch) => {
        dispatch(setValueTenant('tenantLoading', true));
        return tenantService
            .edit(value, propertyId, portfolioId)
            .then((response) => {
                return response.data.id;
            })
            .catch((error) => handleTenantError(error, dispatch))
            .finally(() => {
                dispatch(setValueTenant('tenantLoading', false));
            });
    };
};

export const deleteTenant = (id, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueTenant('deleteLoading', true));
        return tenantService
            .delete(id, propertyId, portfolioId)
            .then((_) => true)
            .catch((error) => {
                if (error && error.response && error.response.status && error.response.status === StatusCodes.BAD_REQUEST) {
                    toaster.error(i18next.t('tenant.deleteForbidden'), null);
                } else {
                    handleTenantError(error, dispatch);
                }
            })
            .finally(() => {
                dispatch(setValueTenant('deleteLoading', false));
            });
    };
};

export const searchTenants = (propertryId, search, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueTenant('searchLoading', true));
        tenantService
            .search(propertryId, search, portfolioId)
            .then((response) => {
                if (!response.data || !response.data.length) {
                    response.data = [];
                }
                dispatch(successSearchTenants({ tenants: response.data }));
            })
            .catch((error) => handleTenantError(error, dispatch))
            .finally(() => {
                dispatch(setValueTenant('searchLoading', false));
            });
    };
};

export const getTenantById = (portfolioId, tenantId, propertyId, status) => {
    return (dispatch) => {
        dispatch(setValueTenant('tenantLoading', true));
        tenantService
            .getById(portfolioId, tenantId, propertyId, status)
            .then((response) => {
                dispatch(successGetTenantById(response.data));
            })
            .catch((error) => handleTenantError(error, dispatch))
            .finally(() => {
                dispatch(setValueTenant('tenantLoading', false));
            });
    };
};

export const getTenantDetails = (portfolioId, tenantId, propertyId) => {
    return (dispatch) => {
        dispatch(setValueTenant('tenantLoading', true));
        tenantService
            .getDetails(portfolioId, tenantId, propertyId)
            .then((response) => {
                dispatch(successGetTenantDetails(response.data));
            })
            .catch((error) => handleTenantError(error, dispatch))
            .finally(() => {
                dispatch(setValueTenant('tenantLoading', false));
            });
    };
};

export const getTenantSummary = (tenantId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueTenant('tenantSummaryLoading', true));
        tenantService
            .getSummary(tenantId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueTenant('tenantSummary', response.data));
            })
            .catch((error) => handleTenantError(error, dispatch))
            .finally(() => {
                dispatch(setValueTenant('tenantSummaryLoading', false));
            });
    };
};

// Portfolio Tenants

export const getTenantsByPortfolioId = (portfolioId) => {
    return (dispatch) => {
        dispatch(setValueTenant('tenantsLoading', true));
        return tenantService
            .getTenantsByPortfolioId(portfolioId)
            .then((response) => {
                dispatch(setValueTenant('portfolioTenants', response.data));
            })
            .catch((error) => handleTenantError(error, dispatch))
            .finally(() => {
                dispatch(setValueTenant('tenantsLoading', false));
            });
    };
};

// Property Tenants

export const getTenantsByPropertyId = (propertyId) => {
    return (dispatch) => {
        dispatch(setValueTenant('tenantsLoading', true));
        return tenantService
            .getTenantsByPropertyId(propertyId)
            .then((response) => {
                dispatch(setValueTenant('tenants', response.data));
            })
            .catch((error) => handleTenantError(error, dispatch))
            .finally(() => {
                dispatch(setValueTenant('tenantsLoading', false));
            });
    };
};

export default tenantReducer;
