import React from 'react';
import LoginSignUpLayout from '../../components/layouts/login-layout';

const TermsOfUse = () => {
    return (
        <LoginSignUpLayout header="Brukervilkår">
            <div className="privacy-policy">
                <p>Sist oppdatert: 13.10.2022</p>
                <h5>Innledning</h5>
                <p>
                    Estatelab PM AS takker for at dere valgte vår Tjeneste. («Estatelab PM AS», heretter kalt, «vår», «Selskapet»). Disse Brukervilkårene gjelder for våre nettsider
                    (Estatelab.no) og programvare/mobilapplikasjoner, eid eller driftet av Estatelab PM AS. Våre nettsider blir referert til «tjenesten» i denne avtale.
                    <br />
                    Estatelab PM AS sitt produkt er en løsning som gir brukerne muligheten til å administrere sine eiendommer i en digital-plattform løsning. Property Management er
                    utviklet og administreres av Estatelab PM AS, med registrert forretningsadresse Drammensveien 127, 0277 OSLO og med org. nr. 928 078 396. Tjenestene er
                    tilgjengelig via nettsiden Estatelab.no ("Nettstedet").
                </p>
                <p>
                    Bruk av Tjenestene som innebærer behandling av leietakeres eller leverandører sine («Sluttbrukere») personopplysninger, krever at utleier har en avtale med
                    Sluttbrukere om behandling av personopplysninger i forbindelse med bruk av tjenesten. Disse brukervilkårene («Brukervilkår») er en avtale mellom utleier
                    («Kunden») som bruker av Tjenestene og Estatelab PM AS som tilbyder av tjenestene.
                </p>
                <p>
                    Estatelab PM AS kan til enhver tid oppdatere Tjenestene, og implementere ny funksjonalitet, samt modifisere eller fjerne eksisterende funksjonalitet. Slike
                    endringer blir kommunisert til deg som bruker.
                </p>
                <p>
                    Du bekrefter at du har lest Brukervilkårene, i tillegg til vår Personvernerklæring og databehandleravtale (sammen, «Avtalene»), samt aksepterer disse Avtalene
                    og godtar å være bundet av dem. Hvis du ikke godtar Vilkårene, eller ikke kan etterkomme dem, kan du heller ikke benytte vår tjeneste.
                </p>
                <p>Ved endringer i «Avtalen» vil du bli varslet fra «Selskapet»</p>
                <h5>Prøveperiode</h5>
                <p>
                    For at du skal kunne teste ut Property Management har Estatelab PM AS anledning til å kunne tilby en avtalt prøveperiode, til en rabatert pris. Hvis
                    abonnementet ikke blir aktivert før en Prøveperiode ender, er abonnementet å regne som utløpt. Vanlige vilkår for utløpte abonnement gjelder (se «Utløpt
                    abonnement»).
                </p>
                <h5>Priser og betaling</h5>
                <p>
                    Property Management tilbys gjennom et abonnement som faktureres i henhold til den til enhver tid gjeldende prisliste. Prisliste er tilgjengelig ved å kontakte
                    «selskapet». Brukeren som står ansvarlig for abonnementet varsles skriftlig om endringer i prislisten med minst en måneds varsel.
                </p>
                <h5>Personopplysninger</h5>
                <p>
                    Estatelab PM AS tar ditt personvern på alvor og garanterer kun å lagre og benytte personopplysninger i den grad og utstrekning det er nødvendig for Tjenestenes
                    funksjonalitet, sikkerhet og forbedringer av funksjonalitet. Dine personopplysninger behandles i tråd med de krav som følger av norsk lov, herunder EUs
                    generelle personvernerklæring, kjent som GDPR.
                </p>
                <p>
                    Tjenestene leveres til deg av Estatelab PM AS, men det er Kunden som setter rammene for behandlingen av Sluttbrukernes personopplysninger. Estatelab PM AS
                    opptrer med andre ord som databehandler og behandler personopplysninger på vegne av Kunden. Dette fremgår i personvernerklæringen og databehandleravtalen.
                </p>
                <p>
                    Det er Kunden som er behandlingsansvarlig for behandling av sine leietagere/kunders personopplysninger for levering av tjenesten, og Estatelab PM AS er
                    databehandler på vegne av Kunden. Det er en databehandleravtale mellom kunden som behandlingsansvarlig og Estatelab PM AS som Kundens databehandler som sikrer
                    at Estatelab PM AS behandler dine personopplysninger slik det fremgår her på vegne av Kunden.
                </p>
                <p>
                    Estatelab PM AS vil ikke selge dine personopplysninger videre til tredjeparter. Det er kun autorisert personell hos Estatelab PM AS, våre underleverandører og
                    Kundens kontaktperson som har tilgang til personopplysningene lagret i Tjenestene. Mer informasjon om Estatelab PM AS sin behandling av dine personopplysninger
                    finner du i vår personvernerklæring som er å anse som bilag til Brukervilkårene.
                </p>
                <h5>Utløpt abonnement</h5>
                <p>
                    Estatelab PM AS kan ikke holdes ansvarlig for oppbevaring av informasjon, avvik, filer eller annet som du har lastet opp til Tjenesten etter at abonnementet er
                    sagt opp. Estatelab PM AS forbeholder seg retten til å umiddelbart slette dine data etter at abonnementet er utløpt.
                </p>
                <h5>Ansvarlig bruk</h5>
                <p>
                    Enhver bruk av Property Management som strider mot lov vil anses som brudd på Brukeravtalen. Når du laster opp informasjon om andre personer, bekrefter du at du
                    er autorisert til å oppgi denne informasjonen. Med dette menes blant annet at du behandler personopplysningene på en lovlig, rettferdig og åpen måte, har lovlig
                    behandlingsgrunnlag og ivaretar de registrertes rettigheter etter personvernforordningen.
                </p>
                <p>
                    Estatelab PM AS forbeholder seg retten til å endre vilkårene i Brukervilkårene. Ved endringer av vesentlig betydning, vil du bli varslet minimum 1 måned før
                    endringen trer i kraft. Om du ikke ønsker å godta vilkårene etter endring, kan du si opp Brukeravtalen med øyeblikkelig virkning ved å opphøre bruk av
                    Tjenestene. Dersom du senere tar i bruk Tjenestene igjen, anses dette som ny aksept av de oppdaterte Brukervilkårene.
                </p>
                <p>
                    Vesentlige brudd på Brukervilkårene gir Estatelab PM AS rett til å terminere Brukervilkårene med umiddelbar virkning. Ved terminering fra Estatelab PM AS side
                    må din bruk av Tjenestene opphøre med umiddelbar virkning og Estatelab PM AS har rett til å lukke din tilgang.
                    <br />
                    Du kan selv terminere Brukervilkårene med umiddelbar virkning ved å sende e-post til <a href="mailto:account@estatelab.no">account@estatelab.no</a>. Da
                    bortfaller bruken av Tjenesten, med mindre du godtar Brukervilkårene på nytt.
                </p>
                <h5>Opphavsrett</h5>
                <p>
                    Estatelab PM AS forbeholder seg opphavsretten samt andre immaterielle rettigheter, deriblant, men ikke begrenset til, patenter, varemerker, merkenavn, design og
                    produktdesign, kildekoder, databaser og brukergrensesnitt, knyttet til Property Management. Kunden har en begrenset og ikke-eksklusiv rett til å benytte seg av
                    Tjenesten så lenge det er et aktivt abonnement.
                </p>
                <p>
                    Blant annet kan du ikke:
                    <ul>
                        <li>selge, videreselge, leie eller lease ut Property Management.</li>
                        <li>
                            forstyrre eller avbryte integriteten i eller funksjonaliteten i Property Management, men ikke begrenset til opplasting eller distribuering på noen måte
                            av filer som inneholder virus eller ødelagte filer som kan skade driften av Tjenesten
                        </li>
                        <li>forsøke å få uautorisert tilgang til Property Management eller tilhørende systemer eller nettverk</li>
                        <li>Stjele eller dekompilering av Tjenesten</li>
                        <li>få tilgang til Property Management for å bygge en konkurransedyktig tjeneste eller produkt, eller kopiere opphavsrettslig beskyttet materiale</li>
                    </ul>
                </p>
                <p>Kundens data er ikke omfattet av opphavsretten.</p>
                <h5>Tredjeparts applikasjoner</h5>
                <p>
                    Tilgangen til enhver tredjeparts nettsider, produkter eller moduler innebærer ikke at Estatelab PM AS støtter eller garanterer for nevnte tredjeparts innhold.
                </p>
                <h5>Brukerdata</h5>
                <p>
                    Tjenesten baseres på brukerdata og er avhengig av at brukere laster opp eiendomsinformasjon og alt annet nødvendig innhold som Kunden har nytte av i Tjenesten
                    («Brukerdata»).
                </p>
                <p>
                    Du garanterer at, når det gjelder all Brukerdata du laster opp til Property Management, (1) du har alle nødvendige rettigheter til å laste opp denne typen
                    Brukerdata til Tjenesten, og (2) denne typen Brukerdata bryter ikke med Avtalene, gjeldende lover, åndsverk, eller andres rettigheter uten skriftlig samtykke
                    fra vedkommende.
                </p>
                <p>
                    Estatelab PM AS kan gjennomgå, holde oppsyn med, endre eller fjerne Brukerdata etter eget skjønn, men er ikke forpliktet til å gjøre det. Estatelab PM AS
                    forbeholder seg retten til å fjerne eller deaktivere tilgang til Brukerdata som, etter Estatelab PM AS skjønn, bryter med Avtalene.
                </p>
                <h5>Aggregerte og anonymiserte datasett</h5>
                <p>
                    Kunden innehar opphavsretten til sin egen Brukerdata. Estatelab PM AS gis rett til å benytte slik Brukerdata til å generere aggregerte og anonymiserte datasett
                    og statistikk. Estatelab PM AS beholder selv opphavsretten samt andre immaterielle rettigheter til slike datasett og statistikk.
                </p>
                <h5>Kontosikkerhet</h5>
                <p>
                    Som eier av din egen bruker hos Estatalab PM AS har du tilgang til og kontroll over brukeren og enhetene som brukes til å få tilgang til Tjenesten. For å
                    opprettholde kontrollen over brukeren og for å forhindre andre fra å få tilgang til brukeren, bør du beholde kontroll over enhetene du bruker til å få tilgang
                    til Tjenesten, i tillegg til å ikke avsløre passordet tilknyttet brukeren til noen andre. Du er ansvarlig for å unngå ikke-autorisert tilgang til kontoen din av
                    andre enn deg selv.
                </p>
                <h5>Garanti og forbehold</h5>
                <p>
                    Estatelab PM AS skal treffe rimelige tiltak, tekniske, fysiske og administrative for å yte en best mulig tjeneste, men kan ikke gi noen garanti for at Property
                    Management til enhver tid er fullstendig fri for feil. Med unntak av de uttrykkelige garantier som er gitt i denne Avtale, leveres Property Management «som det
                    er».
                </p>
                <p>
                    Estatelab PM AS er under enhver omstendighet ikke ansvarlig for dine indirekte tap, konsekvenstap eller andre skader, herunder tap av data eller kunder,
                    forretningsavbrudd, avsavn, tapt fortjeneste mm.
                </p>
                <p>
                    Du godtar herved, så langt det lar seg gjøre under gjeldende lovverk, at løsningen på eventuelle problemer eller enhver form for misnøye begrenses til at du
                    avinstallerer all programvare fra Estatelab PM AS og slutter å bruke Tjenesten.
                </p>
                <p>
                    Estatelab PM AS sitt erstatningsansvar er under alle omstendigheter oppad begrenset til et beløp tilsvarende vederlaget Kunden betalte til Estatelab PM AS for
                    bruk av Tjenesten, de siste 12 månedene før hendelsen som førte til erstatningsansvar.
                </p>
                <h5>Lovvalg og verneting</h5>
                <p>
                    Brukervilkårene skal reguleres og tolkes i samsvar med til enhver tid gjeldende norsk rett. Tvister skal være underlagt norske domstolers jurisdiksjon. Oslo
                    tingrett avtales som vedtatt verneting.
                </p>
                <h5>Kontakt</h5>
                <p>
                    For å ta kontakt, send en e-post til <a href="mailto:account@estatelab.no">account@estatelab.no</a>
                </p>
            </div>
        </LoginSignUpLayout>
    );
};

export default TermsOfUse;
