import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { connect } from 'react-redux';
import { register, validateRegistrationToken } from '../../reducers/signUpReducer';
import LoginSignUpLayout from '../../components/layouts/login-layout';
import Password from '../../components/password-form';
import objectHelper from '../../helpers/trim-object';
import queryString from 'query-string';
import clear from '../../common/clear';
import get from 'lodash-es/get';
import OwnPhoneInput from '../../components/phone-input';

const Register = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onSubmit = () => {
        form.validateFields()
            .then(() => {
                var parsed = queryString.parse(window.location.search);
                var values = form.getFieldsValue();
                var object = objectHelper.trimObjectProperties(values);
                if (object.phone && object.phone[0] !== '+') {
                    object.phone = `+${object.phone}`;
                }
                props.registerCall({ password: object.password, phoneNumber: object.phone, userId: parsed.userId, registrationToken: parsed.token.replaceAll(' ', '+') });
            })
            .catch((_) => {});
    };

    useEffect(() => {
        var parsed = queryString.parse(window.location.search);
        props.validateRegistrationTokenCall({ registrationToken: parsed.token.replaceAll(' ', '+'), userId: parsed.userId });
    }, []);

    return (
        <LoginSignUpLayout header={t('registration.title', { name: props.user && props.user.firstName })}>
            <Form form={form} name="basic" className="mt-4" autoComplete="off" onFinish={onSubmit}>
                <p className="mb-2 font-weight-bold">{t('registration.form.phone.title')}</p>
                <Form.Item
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: t('registration.form.phone.title') + t('common.isRequired'),
                        },
                    ]}>
                    <OwnPhoneInput placeholder={t('registration.form.phone.placeholder')} />
                </Form.Item>
                <Password form={form} onSubmit={onSubmit} passwordIsRequired={true} submitBtnText={t('common.continue')} />
            </Form>
        </LoginSignUpLayout>
    );
};

const mapState = ({ register, common }) => {
    return {
        registrationLoading: get(register, 'registrationLoading'),
        user: get(common, 'user'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        registerCall(values) {
            dispatch(register(values));
        },
        validateRegistrationTokenCall(values) {
            dispatch(validateRegistrationToken(values));
        },
    };
};

const Registration = clear(connect(mapState, mapDispatch)(Register));
export default Registration;
