import React from 'react';
import { useTranslation } from 'react-i18next';

const KartverketDataComponent = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {props.data && (
                <div className="kartverket-data">
                    <h5>{t('kartverketData.title')}</h5>
                    {props.data.organizationForm && (
                        <div className="info-block">
                            <p className="label">{t('kartverketData.organisasjonsForm')}</p>
                            <p className="value">{props.data.organizationForm}</p>
                        </div>
                    )}
                    {props.data.businessCode && props.data.businessCodeDescription && (
                        <div className="info-block">
                            <p className="label">{t('kartverketData.næringskode')}</p>
                            <p className="value">{`${props.data.businessCode} ${props.data.businessCodeDescription}`}</p>
                        </div>
                    )}
                    {(props.data.isRegisteredInRegisterOfBusinessEnterprises || props.data.isRegisteredInVatRegister || props.data.latestFinancialStatementYear) && (
                        <div className="info-block">
                            <p className="label">{t('kartverketData.særligeOpplysninger')}</p>
                            {props.data.isRegisteredInRegisterOfBusinessEnterprises && <p className="value">Registrert i Foretaksregisteret</p>}
                            {props.data.isRegisteredInVatRegister && <p className="value">Registrert i Merverdiavgiftsregisteret</p>}
                            {props.data.latestFinancialStatementYear && <p className="value">Sist innsendte årsregnskap {props.data.latestFinancialStatementYear}</p>}
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default KartverketDataComponent;
