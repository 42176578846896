import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { reSendSignUpEmail } from '../../reducers/signUpReducer';
import LoginSignUpLayout from '../../components/layouts/login-layout';
import moment from 'moment';
import routingService from '../../services/routingService';

class AccountVerificationComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sentDate: null,
            canResendAgain: false,
            timer: null,
        };
    }

    setSentDate = (value) => {
        this.setState({ sentDate: value });
    };

    setCanResendAgain = (value) => {
        this.setState({ canResendAgain: value });
    };

    resendEmail = (e) => {
        e.preventDefault();
        if (this.props.email) {
            this.props.reSendSignUpEmailCall(this.props.email).then(() => {
                this.setSentDate(moment());
                this.setCanResendAgain(false);
                const timer = setTimeout(() => {
                    this.setCanResendAgain(true);
                    this.setState({ timer: null });
                }, 1000 * 60 * 2);
                this.setState({ timer: timer });
            });
        }
    };

    componentWillUnmount() {
        if (this.state.timer) {
            clearTimeout(this.state.timer);
        }
    }

    render() {
        const t = this.props.t;

        return (
            <LoginSignUpLayout header={t('accountVerification.header')}>
                <p className="text-secondary mb-4">{t('accountVerification.subHeader')}</p>
                <p className="font-weight-bold mb-0">
                    {t('accountVerification.verificationLinkSent')} {this.props.email}
                </p>
                <p className="text-secondary mb-4"> {t('accountVerification.message')}</p>
                {this.state.sentDate ? (
                    <p className="mb-0">
                        <span className="text-secondary">{t('accountVerification.newCodeSentAt', { date: this.state.sentDate.format('HH:mm') })} </span>
                        {this.state.canResendAgain ? (
                            <Link onClick={(e) => this.resendEmail(e)}>{t('accountVerification.resendEmail')}</Link>
                        ) : (
                            <span className="text-secondary">{t('accountVerification.waitToSend')} </span>
                        )}
                    </p>
                ) : (
                    <p className="mb-0">
                        <span className="text-secondary">{t('accountVerification.didntGetTheEmail')} </span>
                        <Link onClick={(e) => this.resendEmail(e)}>{t('accountVerification.resendEmail')}</Link>
                    </p>
                )}
                <p>
                    <span className="text-secondary">{t('accountVerification.forgotPassword')} </span>
                    <Link to={routingService.forgotPasswordUrl()}>{t('accountVerification.changePassword')}</Link>
                </p>
                <p>
                    <Link to={routingService.loginUrl()}>{t('accountVerification.backToLogin')}</Link>
                </p>
            </LoginSignUpLayout>
        );
    }
}

const mapDispatch = (dispatch) => {
    return {
        reSendSignUpEmailCall(email) {
            return dispatch(reSendSignUpEmail(email));
        },
    };
};

const AccountVerification = connect(null, mapDispatch)(withTranslation()(AccountVerificationComponent));
export default AccountVerification;
