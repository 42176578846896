import { generatePath } from 'react-router';
import { history } from '..';
import routes from '../common/routes';

const routingService = {
    loginUrl(returnUrl = null) {
        return returnUrl ? `${routes.login}?returnUrl=${returnUrl}` : routes.login;
    },
    forgotPasswordUrl() {
        return routes.forgotPassword;
    },
    forgotPasswordInfoUrl() {
        return routes.forgotPasswordInfo;
    },
    newPasswordUrl() {
        return routes.newPassword;
    },
    signupUrl(email = null) {
        return routes.signup + (email ? `?email=${email}` : '');
    },
    registerUrl() {
        return routes.register;
    },
    registeredUrl() {
        return routes.registered;
    },
    invitedUrl() {
        return routes.invited;
    },
    invitationExpiredUrl(reason) {
        return generatePath(routes.invitationExpired, { reason });
    },
    policyUrl() {
        return routes.policy;
    },
    termsUrl() {
        return routes.terms;
    },
    storybookUrl() {
        return routes.storybook;
    },
    homeUrl() {
        return routes.home;
    },
    userSettingsUrl() {
        return routes.userSettings;
    },
    createPortfolioUrl() {
        return routes.createPortfolio;
    },
    editPortfolioUrl(portfolioId) {
        return generatePath(routes.editPortfolio, { portfolioId });
    },
    portfoliosUrl() {
        return routes.portfolios;
    },
    portfolioDashboardUrl(portfolioId) {
        return generatePath(routes.portfolioDashboard, { portfolioId });
    },
    editPropertyUrl(portfolioId, propertyId) {
        return generatePath(routes.editProperty, { portfolioId, propertyId });
    },
    createPropertyUrl(portfolioId) {
        return generatePath(routes.createProperty, { portfolioId });
    },
    propertiesUrl(portfolioId) {
        return generatePath(routes.properties, { portfolioId });
    },
    propertyUrl(portfolioId, propertyId) {
        return generatePath(routes.property, { portfolioId, propertyId });
    },
    rentRollUrl(portfolioId, propertyId) {
        return generatePath(routes.property, { portfolioId, propertyId }) + '?tab=rentRoll';
    },
    buildingsUrl(portfolioId, propertyId) {
        return generatePath(routes.property, { portfolioId, propertyId }) + '?tab=buildings';
    },
    createBuildingUrl(portfolioId, propertyId) {
        return generatePath(routes.createBuilding, { portfolioId, propertyId });
    },
    editBuildingUrl(portfolioId, propertyId, buildingId) {
        return generatePath(routes.editBuilding, { portfolioId, propertyId, buildingId });
    },
    buildingDetailsUrl(portfolioId, propertyId, buildingId) {
        return generatePath(routes.buildingDetails, { portfolioId, propertyId, buildingId });
    },
    commonCostsUrl(portfolioId, propertyId) {
        return generatePath(routes.property, { portfolioId, propertyId }) + '?tab=commonCosts';
    },
    createCommonCostUrl(portfolioId, propertyId) {
        return generatePath(routes.createCommonCost, { portfolioId, propertyId });
    },
    editCommonCostUrl(portfolioId, propertyId, commonCostId) {
        return generatePath(routes.editCommonCost, { portfolioId, propertyId, commonCostId });
    },
    addCommonCostActualsUrl(portfolioId, propertyId, commonCostId) {
        return generatePath(routes.addCommonCostActuals, { portfolioId, propertyId, commonCostId });
    },
    commonCostDetailsUrl(portfolioId, propertyId, commonCostId) {
        return generatePath(routes.commonCostDetails, { portfolioId, propertyId, commonCostId });
    },
    plotsUrl(portfolioId, propertyId) {
        return generatePath(routes.property, { portfolioId, propertyId }) + '?tab=plots';
    },
    createPlotUrl(portfolioId, propertyId) {
        return generatePath(routes.createPlot, { portfolioId, propertyId });
    },
    editPlotUrl(portfolioId, propertyId, plotId) {
        return generatePath(routes.editPlot, { portfolioId, propertyId, plotId });
    },
    plotDetailsUrl(portfolioId, propertyId, plotId) {
        return generatePath(routes.plotDetails, { portfolioId, propertyId, plotId });
    },
    createTenantUrl(portfolioId, propertyId) {
        return generatePath(routes.createTenant, { portfolioId, propertyId });
    },
    propertyTenantsUrl(portfolioId, propertyId) {
        return generatePath(routes.property, { portfolioId, propertyId }) + '?tab=tenants';
    },
    editTenantUrl(portfolioId, propertyId, tenantId, isArchived) {
        return generatePath(routes.editTenant, { portfolioId, propertyId, tenantId, isArchived });
    },
    tenantDetailsUrl(portfolioId, propertyId, tenantId) {
        return generatePath(routes.tenantDetails, { portfolioId, propertyId, tenantId });
    },
    tenantContractsUrl(portfolioId, propertyId, tenantId) {
        return generatePath(routes.tenantDetails, { portfolioId, propertyId, tenantId }) + '?tab=contracts';
    },
    createContractUrl(portfolioId, propertyId, tenantId) {
        return generatePath(routes.createContract, { portfolioId, propertyId, tenantId });
    },
    contractRentObjectsUrl(portfolioId, propertyId, tenantId, contractId, isArchived = false) {
        return generatePath(routes.contractDetails, { portfolioId, propertyId, tenantId, contractId, isArchived }) + '?tab=rentObjects';
    },
    contractDetailsUrl(portfolioId, propertyId, tenantId, contractId, isArchived = false) {
        return generatePath(routes.contractDetails, { portfolioId, propertyId, tenantId, contractId, isArchived });
    },
    editContractUrl(portfolioId, propertyId, tenantId, contractId) {
        return generatePath(routes.editContract, { portfolioId, propertyId, tenantId, contractId });
    },
    rentObjectCalculationsUrl(portfolioId, propertyId, tenantId, contractId, rentObjectId) {
        return generatePath(routes.rentObjectCalculations, { portfolioId, propertyId, tenantId, contractId, rentObjectId });
    },
    portfolioTenantsUrl(portfolioId) {
        return generatePath(routes.portfolioTenants, { portfolioId });
    },
    portfolioContractsUrl(portfolioId) {
        return generatePath(routes.portfolioContracts, { portfolioId });
    },
    translationsManagerUrl() {
        return routes.translationsManager;
    },
    translationsImportUrl() {
        return routes.translationsImport;
    },
    backOfficeUsersUrl() {
        return routes.backOfficeUsers;
    },
    ownerCostsUrl(portfolioId, propertyId) {
        return generatePath(routes.property, { portfolioId, propertyId }) + '?tab=ownerCosts';
    },
    createOwnerCostUrl(portfolioId, propertyId) {
        return generatePath(routes.createOwnerCost, { portfolioId, propertyId });
    },
    editOwnerCostUrl(portfolioId, propertyId, ownerCostId) {
        return generatePath(routes.editOwnerCost, { portfolioId, propertyId, ownerCostId });
    },
    addOwnerCostActualsUrl(portfolioId, propertyId, ownerCostId) {
        return generatePath(routes.addOwnerCostActuals, { portfolioId, propertyId, ownerCostId });
    },
    ownerCostDetailsUrl(portfolioId, propertyId, ownerCostId) {
        return generatePath(routes.ownerCostDetails, { portfolioId, propertyId, ownerCostId });
    },

    navigateToLogin(returnUrl = null) {
        this.goTo(this.loginUrl(returnUrl));
    },
    navigateToForgotPassword() {
        this.goTo(this.forgotPasswordUrl());
    },
    navigateToForgotPasswordInfo() {
        this.goTo(this.forgotPasswordInfoUrl());
    },
    navigateToNewPassword() {
        this.goTo(this.newPasswordUrl());
    },
    navigateToSignup(email = null) {
        this.goTo(this.signupUrl(email));
    },
    navigateToRegister() {
        this.goTo(this.registerUrl());
    },
    navigateToRegistered() {
        this.goTo(this.registeredUrl());
    },
    navigateToInvited() {
        this.goTo(this.invitedUrl());
    },
    navigateToInvitationExpired(reason) {
        this.goTo(this.invitationExpiredUrl(reason));
    },
    navigateToPolicy() {
        this.goTo(this.policyUrl());
    },
    navigateToTerms() {
        this.goTo(this.termsUrl());
    },
    navigateToStorybook() {
        this.goTo(this.storybookUrl());
    },
    navigateToHome() {
        this.goTo(this.homeUrl());
    },
    navigateToUserSettings() {
        this.goTo(this.userSettingsUrl());
    },
    navigateToCreatePortfolio() {
        this.goTo(this.createPortfolioUrl());
    },
    navigateToEditPortfolio(portfolioId) {
        this.goTo(this.editPortfolioUrl(portfolioId));
    },
    navigateToPortfolios(state = null) {
        this.goTo(this.portfoliosUrl(), state);
    },
    navigateToPortfolioDashboard(portfolioId) {
        this.goTo(this.portfolioDashboardUrl(portfolioId));
    },
    navigateToEditProperty(portfolioId, propertyId) {
        this.goTo(this.editPropertyUrl(portfolioId, propertyId));
    },
    navigateToCreateProperty(portfolioId) {
        this.goTo(this.createPropertyUrl(portfolioId));
    },
    navigateToProperties(portfolioId, state = null) {
        this.goTo(this.propertiesUrl(portfolioId), state);
    },
    navigateToProperty(portfolioId, propertyId) {
        this.goTo(this.propertyUrl(portfolioId, propertyId));
    },
    navigateToRentRoll(portfolioId, propertyId) {
        this.goTo(this.rentRollUrl(portfolioId, propertyId));
    },
    navigateToBuildings(portfolioId, propertyId) {
        this.goTo(this.buildingsUrl(portfolioId, propertyId));
    },
    navigateToCreateBuilding(portfolioId, propertyId) {
        this.goTo(this.createBuildingUrl(portfolioId, propertyId));
    },
    navigateToEditBuilding(portfolioId, propertyId, buildingId) {
        this.goTo(this.editBuildingUrl(portfolioId, propertyId, buildingId));
    },
    navigateToBuildingDetails(portfolioId, propertyId, buildingId) {
        this.goTo(this.buildingDetailsUrl(portfolioId, propertyId, buildingId));
    },
    navigateToCommonCosts(portfolioId, propertyId, state = null) {
        this.goTo(this.commonCostsUrl(portfolioId, propertyId), state);
    },
    navigateToCreateCommonCost(portfolioId, propertyId) {
        this.goTo(this.createCommonCostUrl(portfolioId, propertyId));
    },
    navigateToEditCommonCost(portfolioId, propertyId, commonCostId) {
        this.goTo(this.editCommonCostUrl(portfolioId, propertyId, commonCostId));
    },
    navigateToAddCommonCostActuals(portfolioId, propertyId, commonCostId) {
        this.goTo(this.addCommonCostActualsUrl(portfolioId, propertyId, commonCostId));
    },
    navigateToCommonCostDetails(portfolioId, propertyId, commonCostId) {
        this.goTo(this.commonCostDetailsUrl(portfolioId, propertyId, commonCostId));
    },
    navigateToPlots(portfolioId, propertyId) {
        this.goTo(this.plotsUrl(portfolioId, propertyId));
    },
    navigateToCreatePlot(portfolioId, propertyId) {
        this.goTo(this.createPlotUrl(portfolioId, propertyId));
    },
    navigateToEditPlot(portfolioId, propertyId, plotId) {
        this.goTo(this.editPlotUrl(portfolioId, propertyId, plotId));
    },
    navigateToPlotDetails(portfolioId, propertyId, plotId) {
        this.goTo(this.plotDetailsUrl(portfolioId, propertyId, plotId));
    },
    navigateToCreateTenant(portfolioId, propertyId) {
        this.goTo(this.createTenantUrl(portfolioId, propertyId));
    },
    navigateToPropertyTenants(portfolioId, propertyId) {
        this.goTo(this.propertyTenantsUrl(portfolioId, propertyId));
    },
    navigateToEditTenant(portfolioId, propertyId, tenantId, isArchived) {
        this.goTo(this.editTenantUrl(portfolioId, propertyId, tenantId, isArchived));
    },
    navigateToTenantDetails(portfolioId, propertyId, tenantId) {
        this.goTo(this.tenantDetailsUrl(portfolioId, propertyId, tenantId));
    },
    navigateToTenantContracts(portfolioId, propertyId, tenantId) {
        this.goTo(this.tenantContractsUrl(portfolioId, propertyId, tenantId));
    },
    navigateToCreateContract(portfolioId, propertyId, tenantId) {
        this.goTo(this.createContractUrl(portfolioId, propertyId, tenantId));
    },
    navigateToEditContract(portfolioId, propertyId, tenantId, contractId, stepName) {
        this.goTo(this.editContractUrl(portfolioId, propertyId, tenantId, contractId), { moveToStepName: stepName});
    },
    navigateToContractDetails(portfolioId, propertyId, tenantId, contractId, isArchived) {
        this.goTo(this.contractDetailsUrl(portfolioId, propertyId, tenantId, contractId, isArchived));
    },
    navigateToContractRentObjects(portfolioId, propertyId, tenantId, contractId) {
        this.goTo(this.contractRentObjectsUrl(portfolioId, propertyId, tenantId, contractId));
    },
    navigateToRentObjectCalculations(portfolioId, propertyId, tenantId, contractId, rentObjectId) {
        this.goTo(this.rentObjectCalculationsUrl(portfolioId, propertyId, tenantId, contractId, rentObjectId));
    },
    navigateToPortfolioTenants(portfolioId) {
        this.goTo(this.portfolioTenantsUrl(portfolioId));
    },
    navigateToPortfolioContracts(portfolioId) {
        this.goTo(this.portfolioContractsUrl(portfolioId));
    },
    navigateToTranslationsManager() {
        this.goTo(this.translationsManagerUrl());
    },
    navigateToTranslationsImport() {
        this.goTo(this.translationsImportUrl());
    },
    navigateToBackOfficeUsers() {
        this.goTo(this.backOfficeUsersUrl());
    },
    navigateToOwnerCosts(portfolioId, propertyId, state = null) {
        this.goTo(this.ownerCostsUrl(portfolioId, propertyId), state);
    },
    navigateToCreateOwnerCost(portfolioId, propertyId) {
        this.goTo(this.createOwnerCostUrl(portfolioId, propertyId));
    },
    navigateToEditOwnerCost(portfolioId, propertyId, ownerCostId) {
        this.goTo(this.editOwnerCostUrl(portfolioId, propertyId, ownerCostId));
    },
    navigateToAddOwnerCostActuals(portfolioId, propertyId, ownerCostId) {
        this.goTo(this.addOwnerCostActualsUrl(portfolioId, propertyId, ownerCostId));
    },
    navigateToOwnerCostDetails(portfolioId, propertyId, ownerCostId) {
        this.goTo(this.ownerCostDetailsUrl(portfolioId, propertyId, ownerCostId));
    },

    goTo: (url, state = null) => history.push(url, state),
    goBack: () => history.goBack(),
    replace: (path) => history.replace(path),
};

export default routingService;
