import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { contractStatus, contractStatuses } from '../../common/contract-options';
import { archiveContract, deleteContract, getContractsByPortfolioId, getDataForContractCreation, restoreContract, setValueContract } from '../../reducers/contractReducer';
import emptyListOfContracts from '../../assets/images/empty-contract.svg';
import toaster from '../../common/toaster';
import add from '../../assets/images/plus.svg';
import get from 'lodash-es/get';
import MainLayout from '../../components/layouts/main-layout';
import ActionButton from '../../components/action-button';
import ContractCard from '../../components/contract/contract-card';
import NotFoundPage from '../../components/error-page';
import EmptyList from '../../components/empty-list/empty-list';
import ConfirmDeleteContract from './modals/confirm-delete-modal';
import ConfirmArchiveContract from './modals/confirm-archive-modal';
import SearchInput from '../../components/search-input';
import ModalForContractCreation from './modals/for-contract-creation';
import LoaderSpinner from '../../components/spinner';
import routingService from '../../services/routingService';
import Filter from '../../components/filter';
import ContractFilterModal from './modals/filter-contracts';
import { editPermisionTypes } from '../../common/constants';

const ContractsComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId, propertyId } = useParams();
    const [filteredContracts, setFilteredContracts] = useState([]);
    const [isArchivedShowed, setIsArchivedShowed] = useState(false);

    useEffect(() => {
        props.setValueContractCall('status', props.isArchivedProperty === undefined ? [contractStatuses.active] : (props.isArchivedProperty ? [contractStatuses.archived] : [contractStatuses.active]))
        getActiveAndDraftContracts();
    }, []);

    useEffect(() => {
        setFilteredContracts(props.contracts || []);
    }, [props.contracts]);

    const createContract = (propertyId, tenantId) => {
        routingService.navigateToCreateContract(portfolioId, propertyId, tenantId);
    };

    const openContract = (contract) => {
        routingService.navigateToContractDetails(portfolioId, contract.propertyId, contract.tenantId, contract.id, contract.isArchived);
    };

    const editContract = (contract) => {
        routingService.navigateToEditContract(portfolioId, contract.propertyId, contract.tenantId, contract.id);
    };

    const showModalForContractCreation = (_) => {
        if (!props.hasEditPermission) return;
        props.getDataForContractCreationCall(portfolioId);
        props.showModalForContractCreationCall();
    };

    const showDeleteContractConfirmModal = (contract) => {
        if (!props.hasEditPermission) return;
        props.setContractToDelete(contract);
    };

    const showArchiveContractConfirmModal = (contract) => {
        if (!props.hasEditPermission) return;
        props.setContractToArchive(contract);
    };

    const restoreContract = (contract) => {
        if (!props.hasEditPermission) return;
        props.restoreContractCall(contract.id, portfolioId, contract.propertyId).then((isSuccess) => {
            if (isSuccess) {
                toaster.success(t('contract.itemRestoredSuccessfully', { name: contract.name }));
                const contracts = [...props.contracts];
                const contractIndex = contracts.findIndex((x) => x.id === contract.id);
                if (contractIndex > -1) {
                    contracts[contractIndex].isArchived = false;
                }
                props.setValueContractCall(
                    'portfolioContracts',
                    contracts.filter((x) => x.id !== contract.id)
                );
            }
        });
    };

    const handleDeleteContract = (contract) => {
        if (!props.hasEditPermission) return;
        props.deleteContractCall(contract.id, portfolioId, contract.propertyId).then((isDeleted) => {
            if (isDeleted) {
                toaster.success(t('common.itemDeletedSuccessfully', { name: contract.name }));
                props.closeDeleteContractModalWindow();
                const contracts = [...props.contracts].filter((x) => x.id !== contract.id);
                props.setValueContractCall('portfolioContracts', contracts);
            }
        });
    };

    const handleArchiveContract = (contract) => {
        if (!props.hasEditPermission) return;
        props.archiveContractCall(contract.id, portfolioId, contract.propertyId).then((isSuccess) => {
            if (isSuccess) {
                toaster.success(t('contract.itemArchivedSuccessfully', { name: contract.name }));
                props.closeArchiveContractModalWindow();
                const contracts = [...props.contracts];
                const contractIndex = contracts.findIndex((x) => x.id === contract.id);
                if (contractIndex > -1) {
                    contracts[contractIndex].isArchived = true;
                }
                props.setValueContractCall(
                    'portfolioContracts',
                    contracts.filter((x) => x.id !== contract.id)
                );
            }
        });
    };

    const onSearch = (filterText) => {
        if (!props.hasAnyContracts) {
            return;
        }

        filterText = (filterText || '').toUpperCase();
        const contracts = props.contracts || [];
        const filteredContracts = filterText ? contracts.filter((contract) => contract.name && contract.name.toUpperCase().includes(filterText)) : contracts;
        setFilteredContracts(filteredContracts);
    };

    const getActiveAndDraftContracts = () => {
        const filter = {
            contractStatuses: [contractStatus.active],
            status: props.isArchivedProperty === undefined ? contractStatuses.active : (props.isArchivedProperty ? contractStatuses.archived : contractStatuses.active)
        };
        onFilter(filter);
    };

    const onFilter = (filter) => {
        if (portfolioId) {
            setIsArchivedShowed(filter.status === contractStatuses.archived);
            props.getContractsByPortfolioIdCall(portfolioId, filter);
        }
    };

    const onClear = () => {
        getActiveAndDraftContracts();
    };

    const addNewContractBtn = (label) => (
        <ActionButton
            className="btn-primary add-btn"
            text={label}
            icon={<Image src={add} />}
            disabled={true}
            onClick={showModalForContractCreation}
        />
    );

    return (
        <MainLayout>
            {props.portfolioNotFound ? (
                <NotFoundPage header={t('portfolio.notFound')} />
            ) : (
                <div className="list">
                    <div className="d-flex flex-row justify-content-between mb-4">
                        <h3 className="mb-2">{t('contract.contractsPage.header')}</h3>
                    </div>
                    <div className="search-header with-filter">
                        <SearchInput placeholder={t('contract.searchPlaceholder')} onSearch={onSearch} />
                        <Filter
                            onClick={() => props.setValueContractCall('showContractFilterModalWindow', true)}
                            filters={[props.status !== contractStatuses.active, props.hideDraftContracts].reduce((a, b) => a + b, 0)}
                        />
                    </div>
                    {props.contractsLoading ? (
                        <LoaderSpinner />
                    ) : (
                        <React.Fragment>
                            {props.hasAnyContracts ? (
                                <div className="cards">
                                    {filteredContracts.map((contract) => (
                                        <ContractCard
                                            key={contract.id}
                                            contract={contract}
                                            hasEditPermission={props.hasEditPermission}
                                            openContract={openContract}
                                            editContract={editContract}
                                            deleteContract={showDeleteContractConfirmModal}
                                            archiveContract={showArchiveContractConfirmModal}
                                            restoreContract={restoreContract}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <EmptyList
                                    image={emptyListOfContracts}
                                    title={isArchivedShowed ? t('contract.contractsPage.emptyList.archivedTitle') : t('contract.contractsPage.emptyList.title')}
                                    subTitle={!isArchivedShowed && t('contract.contractsPage.emptyList.subtitle')}>
                                    {!isArchivedShowed && addNewContractBtn(t('contract.addContract'))}
                                </EmptyList>
                            )}
                        </React.Fragment>
                    )}
                </div>
            )}
            {props.confirmDeleteContract && <ConfirmDeleteContract onConfirm={handleDeleteContract} />}
            {props.confirmArchiveContract && <ConfirmArchiveContract onConfirm={handleArchiveContract} />}
            {props.showModalForContractCreation && <ModalForContractCreation goToCreation={createContract} />}
            {props.showContractFilterModalWindow && <ContractFilterModal onFilter={onFilter} onClear={onClear} />}
        </MainLayout>
    );
};

const mapState = ({ contract, property, navigation }) => {
    return {
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        contracts: get(contract, 'portfolioContracts'),
        hasAnyContracts: get(contract, 'portfolioContracts.length') > 0,
        contractsLoading: get(contract, 'contractsLoading'),
        portfolioNotFound: get(property, 'portfolioNotFound'),
        confirmDeleteContract: !!get(contract, 'contractToDelete'),
        confirmArchiveContract: !!get(contract, 'contractToArchive'),
        showModalForContractCreation: get(contract, 'showModalForContractCreation'),
        showContractFilterModalWindow: get(contract, 'showContractFilterModalWindow'),
        hideDraftContracts: get(contract, 'hideDraftContracts'),
        status: get(contract, 'status'),
        isArchivedProperty: get(property, 'property.isArchived')
    };
};

const mapDispatch = (dispatch) => {
    return {
        getContractsByPortfolioIdCall(id, filter) {
            dispatch(getContractsByPortfolioId(id, filter));
        },
        getDataForContractCreationCall(id) {
            dispatch(getDataForContractCreation(id));
        },
        setContractToDelete(contract) {
            dispatch(setValueContract('contractToDelete', contract));
        },
        setContractToArchive(contract) {
            dispatch(setValueContract('contractToArchive', contract));
        },
        closeDeleteContractModalWindow() {
            dispatch(setValueContract('contractToDelete', null));
        },
        closeArchiveContractModalWindow() {
            dispatch(setValueContract('contractToArchive', null));
        },
        showModalForContractCreationCall() {
            dispatch(setValueContract('showModalForContractCreation', true));
        },
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
        deleteContractCall(contractId, portfolioId, propertyId) {
            return dispatch(deleteContract(contractId, portfolioId, propertyId));
        },
        archiveContractCall(contractId, portfolioId, propertyId) {
            return dispatch(archiveContract(contractId, portfolioId, propertyId));
        },
        restoreContractCall(contractId, portfolioId, propertyId) {
            return dispatch(restoreContract(contractId, portfolioId, propertyId));
        },
    };
};

const Contracts = connect(mapState, mapDispatch)(ContractsComponent);
export default Contracts;
