import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form, Input, Upload } from 'antd';
import { Col, Image, Row } from 'react-bootstrap';
import { setValueUserSettings, updateUser, uploadPhoto } from '../../reducers/userSettingsReducer';
import { setUser } from '../../reducers/commonReducer';
import { languageService } from '../../services/languageService';
import { requiredCommonInput } from '../../common/validators';
import { maxPercents } from '../../common/constants';
import MainLayout from '../../components/layouts/main-layout';
import Password from '../../components/password-form';
import CommonInput from '../../components/common-input';
import passwordHide from '../../assets/images/password-hide.svg';
import passwordShow from '../../assets/images/password-show.svg';
import LanguageSwitcher from '../../components/language-switcher';
import ActionButton from '../../components/action-button';
import OwnPhoneInput from '../../components/phone-input';
import LoaderSpinner from '../../components/spinner';
import toaster from '../../common/toaster';
import clear from '../../common/clear';
import get from 'lodash-es/get';

const UserSettingsComponent = (props) => {
    const { t } = useTranslation();
    const [uploadItemPercent, setUploadItemPercent] = useState();
    const [newPasswordIsEntered, setNewPasswordIsEntered] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(props.user);
    }, []);

    const onSubmit = () => {
        form.validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const settings = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phone: values.phone,
                    language: values.language,
                    changePasswordRequest: values.lastPassword
                        ? {
                              currentPassword: values.lastPassword,
                              newPassword: values.password,
                          }
                        : null,
                };
                if (values.phone && values.phone[0] !== '+') {
                    settings.phone = `+${settings.phone}`;
                }
                if ((!values.password && !values.lastPassword) || (values.password && values.lastPassword)) {
                    props.updateUserCall(settings).then((user) => {
                        if (user) {
                            languageService.changeLanguage(user.language);
                            setNewPasswordIsEntered(false);
                            form.resetFields(['lastPassword', 'password', 'confirmed_password']);
                            props.setUserCall({ ...user });
                            toaster.success(t('userSettings.userUpdated'), null);
                        }
                    });
                }
            })
            .catch((_) => {});
    };

    const propsDragger = {
        name: 'file',
        multiple: false,
        accept: '.jpg, .jpeg, .gif, .crw, .cr2, .nef, .dng, .orf, .raf, .arw, .pef, .srw, .rw2, .png',
        customRequest: (data) => customRequest(data),
        showUploadList: false,
    };

    const customRequest = (data) => {
        props.setUploadPhotoCall(data.file);
        sendFile(data.file);
    };

    const sendFile = (file) => {
        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * maxPercents) / total);
                if (percent < maxPercents) {
                    setUploadItemPercent(percent);
                }
            },
        };

        props.uploadPhotoCall(file, options).then((response) => {
            const item = [...response];
            item.status = 'done';
            props.setUploadPhotoCall(item);
            setUploadItemPercent(maxPercents);
        });
    };

    return (
        <MainLayout>
            {props.userLoading ? (
                <LoaderSpinner />
            ) : (
                <Row>
                    <Col md={12} lg={10}>
                        <div className="user-settings-page">
                            <h3 className="mb-4">{t('userSettings.header')}</h3>
                            <div className="user-settings-form">
                                <Form form={form} name="basic" autoComplete="off">
                                    <div className="page-container">
                                        <h5>{t('userSettings.userDetails.title')}</h5>
                                        <Row>
                                            <Col lg={12} xl={6}>
                                                <Form.Item name="firstName" rules={[requiredCommonInput(t('userSettings.userDetails.firstName') + t('common.isRequired'))]}>
                                                    <CommonInput
                                                        maxLength={100}
                                                        title={t('userSettings.userDetails.firstName')}
                                                        placeholder={t('userSettings.userDetails.firstName')}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="lastName" rules={[requiredCommonInput(t('userSettings.userDetails.lastName') + t('common.isRequired'))]}>
                                                    <CommonInput
                                                        maxLength={100}
                                                        title={t('userSettings.userDetails.lastName')}
                                                        placeholder={t('userSettings.userDetails.lastName')}
                                                    />
                                                </Form.Item>
                                                <p className="mb-2 font-weight-bold">{t('userSettings.userDetails.phoneNumber')}</p>
                                                <Form.Item
                                                    name="phone"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('registration.form.phone.title') + t('common.isRequired'),
                                                        },
                                                    ]}>
                                                    <OwnPhoneInput placeholder={t('registration.form.phone.placeholder')} />
                                                </Form.Item>
                                                <p className="mb-2 font-weight-bold">{t('userSettings.userDetails.email')}</p>
                                                <p>{props.user.email}</p>
                                                {/* <p className='mb-2 mt-1 font-weight-bold'>{t('userSettings.userDetails.uploadPhoto')}</p>
                                                <Upload.Dragger {...propsDragger}>
                                                    <Image src={upload} />
                                                    <p>{t('dragAndDrop')}</p>
                                                </Upload.Dragger> */}
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr />
                                    <div className="page-container">
                                        <Row>
                                            <Col lg={12} xl={6}>
                                                <h5>{t('userSettings.language.title')}</h5>
                                                <p className="mb-2 font-weight-bold">{t('userSettings.language.chooseLanguage')}</p>
                                                <Form.Item name="language" className="mb-0">
                                                    <LanguageSwitcher />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr />
                                    <div className="page-container pb-0">
                                        <Row>
                                            <Col lg={12} xl={6}>
                                                <h5>{t('userSettings.changePassword.title')}</h5>
                                                <p className="mb-2 font-weight-bold">{t('userSettings.userDetails.lastPassword')}</p>
                                                <Form.Item
                                                    name="lastPassword"
                                                    className="mb-3"
                                                    rules={[
                                                        {
                                                            required: newPasswordIsEntered,
                                                            message: t('userSettings.userDetails.lastPasswordIsRequired'),
                                                        },
                                                    ]}>
                                                    <Input.Password
                                                        autoComplete="new-password"
                                                        placeholder={t('userSettings.userDetails.lastPassword')}
                                                        iconRender={(visible) => (visible ? <Image src={passwordShow} /> : <Image src={passwordHide} />)}
                                                    />
                                                </Form.Item>
                                                <Password
                                                    setNewPasswordIsEntered={setNewPasswordIsEntered}
                                                    firstFieldTitle={t('userSettings.userDetails.newPassword')}
                                                    passwordIsRequired={false}
                                                    form={form}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                                <div className="page-container">
                                    <ActionButton
                                        className="btn-primary"
                                        disabled={form.getFieldsError().filter(({ errors }) => errors.length).length}
                                        text={t('common.buttons.saveChanges')}
                                        onClick={onSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </MainLayout>
    );
};

const mapState = ({ common, userSettings }) => {
    return {
        user: get(common, 'user'),
        uploadPhoto: get(userSettings, 'uploadPhoto'),
        userLoading: get(userSettings, 'userLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        updateUserCall(settings) {
            return dispatch(updateUser(settings));
        },
        setUserCall(user) {
            dispatch(setUser(user));
        },
        setUploadPhotoCall(value) {
            dispatch(setValueUserSettings('uploadPhoto', value));
        },
        uploadPhotoCall(file, options) {
            return dispatch(uploadPhoto(file, options));
        },
    };
};

const UserSettings = clear(connect(mapState, mapDispatch)(UserSettingsComponent));
export default UserSettings;
