import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { commonCostBudgetItemsSelector, selectMergedBudgetAndActualItems, showActualsSelector } from '../../selectors';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { predefinedColors } from '../../../../services/chartService';
import CurrencyFormat from '../../../../components/currency-format';

const CostsTableComponent = () => {
    const maxRowsNumberInCollapsedState = 5;

    const { t } = useTranslation();
    const [isTableExpanded, setTableExpanded] = useState(false);
    const showActuals = useSelector(showActualsSelector);
    const buildingId = useSelector(({ commonCost }) => commonCost.commonCost?.buildingId);
    const lettableArea = useSelector(({ commonCost }) => commonCost.commonCost?.lettable);
    const mergedCostItems = useSelector(selectMergedBudgetAndActualItems);
    const budgetItems = useSelector(commonCostBudgetItemsSelector);
    const items = showActuals ? mergedCostItems : budgetItems;

    const handleExpandClick = (e) => {
        e.preventDefault();
        setTableExpanded(true);
    };

    const handleCollapseClick = (e) => {
        e.preventDefault();
        setTableExpanded(false);
    };

    const getLegendMarkerColor = (index) => {
        return predefinedColors[index % predefinedColors.length];
    };

    return (
        <div className="d-flex flex-column">
            <Row className="table-header visible-md">
                <Col xs={12} md={showActuals ? 3 : 4}></Col>
                <Col xs={12} md={showActuals ? 3 : 4}>
                    <div className="th">{t('commonCost.analysisDetails.budget')}</div>
                </Col>
                {showActuals && (
                    <>
                        <Col xs={12} md={3}>
                            <div className="th">{t('commonCost.analysisDetails.actual')}</div>
                        </Col>
                        <Col xs={12} md={3}>
                            <div className="th">{t('commonCost.costsTable.discrepancy')}</div>
                        </Col>
                    </>
                )}
                {!showActuals && (
                    <Col xs={12} md={2}>
                        <div className="th">{buildingId ? t('commonCost.costsTable.prm2') : t('commonCost.costsTable.units')}</div>
                    </Col>
                )}
            </Row>
            <div className="table-body">
                {(isTableExpanded ? mergedCostItems : mergedCostItems.slice(0, maxRowsNumberInCollapsedState)).map((item, index) => {
                    return (
                        <div className={`table-row ${index % 2 === 1 ? 'alternate' : ''}`} key={item.key}>
                            <Row align={'middle'}>
                                <Col xs={12} md={showActuals ? 3 : 4}>
                                    <div className="td d-flex flex-row align-items-center">
                                        <span className="legend-marker" style={{ backgroundColor: getLegendMarkerColor(index) }}></span>
                                        {item.key}
                                    </div>
                                </Col>
                                <Col xs={12} md={showActuals ? 3 : 4}>
                                    <div className="td">
                                        <span className="hidden-md">{t('commonCost.analysisDetails.budget')}:</span>
                                        {item.budgetItem ? <CurrencyFormat value={item.budgetItem.value} /> : 'N/A'}
                                    </div>
                                </Col>
                                {showActuals && (
                                    <>
                                        <Col xs={12} md={3}>
                                            <div className="td">
                                                <span className="hidden-md">{t('commonCost.analysisDetails.actual')}:</span>
                                                {item.actualItem ? <CurrencyFormat value={item.actualItem.value} /> : 'N/A'}
                                            </div>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <div className="td">
                                                <span className="hidden-md">{t('commonCost.costsTable.discrepancy')}:</span>
                                                {item.budgetItem && item.actualItem ? <CurrencyFormat value={item.budgetItem.value - item.actualItem.value} /> : 'N/A'}
                                            </div>
                                        </Col>
                                    </>
                                )}
                                {!showActuals && (
                                    <Col xs={12} md={4}>
                                        <div className="td">
                                            <span className="hidden-md">{t('commonCost.costsTable.prm2')}:</span>
                                            {showActuals ? (
                                                item.actualItem ? (
                                                    <CurrencyFormat value={item.actualItem.value / lettableArea} />
                                                ) : (
                                                    'N/A'
                                                )
                                            ) : item.budgetItem ? (
                                                <CurrencyFormat value={item.budgetItem.value / lettableArea} />
                                            ) : (
                                                'N/A'
                                            )}
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    );
                })}
            </div>
            <div className="table-footer">
                {mergedCostItems.length > maxRowsNumberInCollapsedState ? (
                    isTableExpanded ? (
                        <Link onClick={handleCollapseClick} className="expand-btn">{t('common.buttons.collapse')}</Link>
                    ) : (
                        <Link onClick={handleExpandClick} className="expand-btn">{t('common.buttons.expand')}</Link>
                    )
                ) : null}
            </div>
        </div>
    );
};

export default CostsTableComponent;
