import { CloseOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import notificationsBell from '../../assets/images/bell-notifications.svg';
import bell from '../../assets/images/bell.svg';
import logOutIcon from '../../assets/images/logout-icon.svg';
import mobileMenu from '../../assets/images/mobile-menu.svg';
import selectArrow from '../../assets/images/select-arrow.svg';
import settingsDefault from '../../assets/images/settings-default.svg';
import personIcon from '../../assets/images/user-active.svg';
import authService from '../../services/authService';
import routingService from '../../services/routingService';
import ActionButton from '../action-button';
import Logo from '../logo-container';
import MobileNotifications from '../navigation/mobile-notifications';
import Navigation, { navigationEnum } from '../navigation/navigation';
import localStorageService from './../../services/localStorageService';

const mobileWindows = {
    notifications: 'notifications',
    menu: 'menu',
};

const Header = (props) => {
    const { t } = useTranslation();
    const [isOpenedMobileMenu, setIsOpenedMobileMenu] = useState(false);
    const [mobileWindow, setMobileWindow] = useState(null);

    const openFullMenuItem = (value) => {
        setIsOpenedMobileMenu(!!value);
        setMobileWindow(value);
    };

    const notifications = (
        <Menu className="empty-notifications">
            <div>
                <Image src={notificationsBell} />
                <p>{t('navigation.notificationsComingSoon')}</p>
            </div>
        </Menu>
    );

    const goToUserSettings = (_) => {
        routingService.navigateToUserSettings();
    };

    const goToLogin = (_) => {
        authService.logout();
        localStorageService.clearUser();
        routingService.navigateToLogin();
    };

    const userMenu = (
        <Menu className="edit-menu">
            <Menu.Item key={navigationEnum.accountSettings} onClick={goToUserSettings}>
                <ActionButton icon={<Image className="ml-2" src={settingsDefault} />} text={t('navigation.accountSettings')} />
            </Menu.Item>
            <Menu.Item key={navigationEnum.logout} onClick={goToLogin}>
                <ActionButton icon={<Image className="ml-2" src={logOutIcon} />} text={t('navigation.logOut')} />
            </Menu.Item>
        </Menu>
    );

    return (
        <React.Fragment>
            <div className="header">
                <Logo />
                <div className="d-flex flex-row">
                    <div className="user-navigation">
                        <div className="user-menu">
                            <Dropdown overlay={userMenu} trigger={['click']} overlayClassName="user-dropdown-menu">
                                <div className="pointer">
                                    <div className="person-dot">
                                        <Image src={personIcon} />
                                    </div>
                                    <Image src={selectArrow} />
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="notifications">
                        <Dropdown trigger={['click']} placement="bottomRight" overlay={notifications}>
                            <div>
                                <div className="bell pointer">
                                    <Image src={bell} />
                                </div>
                            </div>
                        </Dropdown>
                    </div>
                    {isOpenedMobileMenu ? (
                        <div className="close-mobile-window">
                            <div onClick={() => openFullMenuItem(null)}>
                                <div className="bell pointer">
                                    <CloseOutlined />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="mobile-notifications-btn mr-3">
                                <div onClick={() => openFullMenuItem(mobileWindows.notifications)}>
                                    <div className="bell pointer">
                                        <Image src={bell} />
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-user-navigation-btn">
                                <div onClick={() => openFullMenuItem(mobileWindows.menu)}>
                                    <div className="bell pointer">
                                        <Image src={mobileMenu} />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
            {mobileWindow && isOpenedMobileMenu ? (
                mobileWindow === mobileWindows.menu ? (
                    <Navigation className="mobile-navigation" />
                ) : mobileWindow === mobileWindows.notifications ? (
                    <MobileNotifications />
                ) : null
            ) : null}
        </React.Fragment>
    );
};

export default Header;
