import { Divider, Progress, Row, Col } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { contractStatus, contractStatusOptions, invoiceIssueDateOptions, paymentPlanOptions } from '../../../../common/contract-options';
import tenants from '../../../../assets/images/tenants.svg';
import mail from '../../../../assets/images/mail-default.svg';
import helper from '../../../../helpers/helper';

const Overview = ({ contract, tenant }) => {
    const { t } = useTranslation();

    const notApplicable = 'N/A';

    const [contractDurationProgress, setContractDurationProgress] = useState();

    useEffect(() => {
        const contractDuration = moment.duration(moment(contract.leaseEndDate).diff(moment(contract.leaseStartDate))).asDays();
        const daysPaste = moment.duration(moment().diff(moment(contract.leaseStartDate))).asDays();
        setContractDurationProgress(Math.round((daysPaste * 100) / contractDuration));
    }, []);

    const getStatusColor = (id) => {
        switch (id) {
            case contractStatus.active:
                return '#FFFFFF';

            case contractStatus.inNegotiation:
            case contractStatus.draft:
                return '#F6C000';

            case contractStatus.expired:
            case contractStatus.terminated:
                return '#F13F3F';

            default:
                return '#FFFFFF';
        }
    }

    return (
        <div className="contract-overview">
            <h5>{t('contract.preview.overview')}</h5>
            <Row>
                <Col span={18}>
                    <Row>
                        <Col span={6} className="overview-column">
                            <div>
                                <p className="title-text">{t('contract.preview.contractSetup.contractStatus')}</p>
                                <p className="main-text" style={{ color: getStatusColor(contract.status) }}>{helper.getEnumValue(contractStatusOptions, contract.status)}</p>
                            </div>
                            <div>
                                <p className="title-text">{t('contract.preview.overview.startDate')}</p>
                                <p className="main-text">{contract.leaseStartDate ? moment(contract.leaseStartDate).format('DD.MM.YYYY') : notApplicable}</p>
                            </div>
                            <div>
                                <p className="title-text">{t('contract.signatureTenantDate.subtitle')}</p>
                                <p className="main-text">{contract.signatureTenantDate ? moment(contract.signatureTenantDate).format('DD.MM.YYYY') : notApplicable}</p>
                            </div>
                        </Col>
                        <Col span={6} className="overview-column">
                            <div></div>
                            <div style={{ marginTop: '62px' }}>
                                <p className="title-text">{t('contract.preview.overview.endDate')}</p>
                                <p className="main-text">{contract.leaseEndDate ? moment(contract.leaseEndDate).format('DD.MM.YYYY') : notApplicable}</p>
                                {(contract.status !== contractStatus.draft && contract.status !== contractStatus.terminated) && <Progress percent={contractDurationProgress} />}
                            </div>
                            <div>
                                <p className="title-text">{t('contract.signatureLandLordDate.subtitle')}</p>
                                <p className="main-text">{contract.signatureLandLordDate ? moment(contract.signatureLandLordDate).format('DD.MM.YYYY') : notApplicable}</p>
                            </div>
                        </Col>
                        <Col span={6} className="overview-column">
                            <div>
                                <p className="title-text">{t('contract.preview.overview.kpiPeriodStart')}</p>
                                <p className="main-text">{contract.originalBaseIndexDate ? moment(contract.originalBaseIndexDate).format('MMM YYYY') : notApplicable}</p>
                            </div>
                            <div>
                                <p className="title-text">{t('contract.preview.overview.kpiFisrtTime')}</p>
                                <p className="main-text">
                                    {contract.firstTimeCpiAdjustmentDate ? moment(contract.firstTimeCpiAdjustmentDate).format('DD.MM.YYYY') : notApplicable}
                                </p>
                            </div>
                            <div>
                                <p className="title-text">{t('contract.preview.overview.kpi')}</p>
                                <p className="main-text">{contract.cpiRate ? `${contract.cpiRate}%` : notApplicable}</p>
                            </div>
                        </Col>
                        <Col span={6} className="overview-column">
                            <div>
                                <p className="title-text">{t('contract.preview.overview.firstInvoice')}</p>
                                <p className="main-text">{contract.firstInvoiceDueDate ? moment(contract.firstInvoiceDueDate).format('DD.MM.YYYY') : notApplicable}</p>
                            </div>
                            <div>
                                <p className="title-text">{t('contract.preview.overview.invoiceDate')}</p>
                                <p className="main-text">
                                    {contract.invoiceIssueDateOption ? helper.getEnumValue(invoiceIssueDateOptions, contract.invoiceIssueDateOption) : notApplicable}
                                </p>
                            </div>
                            <div>
                                <p className="title-text">{t('contract.preview.overview.paymentPlan')}</p>
                                <p className="main-text">{contract.paymentPlan ? helper.getEnumValue(paymentPlanOptions, contract.paymentPlan) : notApplicable}</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <div className="tenant-card">
                        <h5>{t('contract.preview.overview.tenant')}</h5>
                        <Divider />
                        <Image src={tenants} />
                        <p className="main-text tenant-text">{tenant.displayName}</p>
                        <p className="title-text tenant-text">{tenant.address}</p>
                        {tenant.corporateEmail && (
                            <p className="title-text tenant-text">
                                <Image src={mail} style={{ marginRight: '10px' }} />
                                {tenant.corporateEmail}
                            </p>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Overview;
