import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deleteStairRentInterval, setValueContract } from '../../../../../reducers/contractReducer';
import get from 'lodash-es/get';
import ConfirmDeleteModal from '../../../../../components/confirm-delete-modal';
import { useParams } from 'react-router';

const ConfirmDeleteStairRentIntervalComponent = (props) => {
    const { t } = useTranslation();

    const { portfolioId, propertyId } = useParams();

    const handleCloseClick = () => {
        props.closeModalWindow();
    };

    const handleDeleteClick = () => {
        props.deleteStairRentIntervalCall(props.stairRentIntervalModel.stairRentIntervalId, props.contractId, propertyId, portfolioId);
    };

    return (
        <ConfirmDeleteModal
            onClose={handleCloseClick}
            onConfirm={handleDeleteClick}
            title={t('contract.finances.deleteStairRentInterval.title')} //{ name: props.stairRentIntervalModel.name }
            text={t('contract.finances.deleteStairRentInterval.sentence')}
            loading={props.stairRentIntervalLoading}
            deleteBtnText={t('contract.finances.deleteStairRentInterval.deleteBtnText')}
        />
    );
};

const mapState = ({ contract }) => {
    return {
        contractId: get(contract, 'contract.id'),
        stairRentIntervalModel: get(contract, 'stairRentIntervalModel'),
        stairRentIntervalLoading: get(contract, 'stairRentIntervalLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showRemoveStairRentIntervalModal', false));
        },
        deleteStairRentIntervalCall(id, contractId, propertyId, portfolioId) {
            dispatch(deleteStairRentInterval(id, contractId, propertyId, portfolioId));
        },
    };
};

const ConfirmDeleteStairRentInterval = connect(mapState, mapDispatch)(ConfirmDeleteStairRentIntervalComponent);
export default ConfirmDeleteStairRentInterval;
