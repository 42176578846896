import { Button, Col, Collapse, Row } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router';
import rentRollService from '../../../services/rentRollService';
import RentRollHeader from './rent-roll-header';
import RentRollTable from './rent-roll-table';
import arrowIcon from '../../../assets/images/arrow-icon.svg';
import { Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import YearPicker from './year-picker';
import RentRollTotalRow from './rent-roll-total-row';
import RentRollFooter from './rent-roll-footer';
import LoaderSpinner from '../../../components/spinner';
import ExportToExcel from './export-to-excel';
import ExportToPdf from './export-to-pdf';
import send from '../../../assets/images/send.svg';
import SendInvite from './send-invite';
import { contractStatus } from '../../../common/contract-options';
import { editPermisionTypes, userPermisionsTypes } from '../../../common/constants';

const RentRoll = (props) => {
    const { propertyId, portfolioId } = useParams();

    const { t } = useTranslation();

    const { property } = props;

    const [rentRollData, setRentRollData] = useState({});
    const [currentYear, setCurrentYear] = useState(moment().year());
    const [openItemsKeys, setOpenItemsKeys] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        getRentRollData(currentYear);
    }, [currentYear]);
    
    const getRentRollData = async (year) => {
        setIsLoading(true);
        const rentRollResponse = await rentRollService.getRentRollByPropertyId({ contractStatuses: [contractStatus.active, contractStatus.terminated, contractStatus.expired] }, propertyId, year, portfolioId);
        setRentRollData(rentRollResponse.data);
        setIsLoading(false);
    };

    const expandAllContracts = () => {
        setOpenItemsKeys(rentRollData?.tenants?.map((tenant) => tenant?.contracts?.map(contract => contract.id.toString())).flat(1));
    }

    return (
        <div className="rent-roll-wrapper">
            <p className="rent-roll-title">{`${t('rentRoll.title')} - ${props.isRentRollPermision ? '' : property?.propertyName}`}</p>
            <p className="current-date">{moment().format(primaryDateFormat)}</p>
            <div className="d-flex flex-row justify-content-between">
                <YearPicker year={currentYear} setYear={setCurrentYear} isRentRollLoading={isLoading} />
                {!isLoading && (
                    <div>
                        <ExportToExcel dataSource={rentRollData} propertyName={property?.propertyName} currentYear={currentYear} isRentRollLoading={isLoading} disabled={!props.hasEditPermission} />
                        <Button className="btn-secondary" style={{ marginRight: '5px' }} onClick={() => setModalVisible(true)} disabled={!props.hasEditPermission}>
                            <Image src={send} />
                            {t('rentRoll.sendInvite.button')}
                        </Button>
                        <ExportToPdf dataSource={rentRollData} propertyName={property?.propertyName} currentYear={currentYear} isRentRollLoading={isLoading} disabled={!props.hasEditPermission} />
                    </div>
                )}
            </div>
            {isLoading ? (
                <LoaderSpinner />
            ) : (
                <>
                    <RentRollHeader isTotal={true} expandAll={expandAllContracts} collapseAll={() => setOpenItemsKeys([])} />
                    <Collapse
                        expandIcon={({ isActive }) =>
                            isActive ? <Image className="arrow-icon-not-collapsed" src={arrowIcon} /> : <Image className="arrow-icon-collapsed" src={arrowIcon} />
                        }
                        onChange={(activeKeys) => setOpenItemsKeys(activeKeys)}
                        activeKey={openItemsKeys}
                        className="rent-roll-collapse">
                        {rentRollData?.tenants?.map((tenant) => {
                            return tenant?.contracts?.map((contract) => {
                                const isContractExpiring = moment(contract?.leaseEndDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'));
                                const isContractStarting = moment(contract?.leaseStartDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'));

                                return (
                                    <Collapse.Panel
                                        header={
                                            <RentRollTotalRow
                                                isContractExpiring={isContractExpiring}
                                                isContractStarting={isContractStarting}
                                                contract={contract}
                                                isHeader={true}
                                                isActive={openItemsKeys.includes(contract.id.toString())}
                                            />
                                        }
                                        key={contract.id}
                                        className="rent-roll-custom-panel">
                                        <RentRollTable contract={contract} isContractStarting={isContractStarting} isContractExpiring={isContractExpiring} currentYear={currentYear} />
                                    </Collapse.Panel>
                                );
                            });
                        })}
                    </Collapse>
                    <RentRollFooter rentObjectsTotal={rentRollData?.rentObjectsTotal} />
                </>
            )}
            <SendInvite modalVisible={modalVisible} setModalVisible={setModalVisible} year={currentYear} />
        </div>
    );
};

const mapState = ({ property, navigation }) => {
    return {
        property: get(property, 'property'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        isRentRollPermision: get(navigation, 'selectedPortfolio.permissionId') == userPermisionsTypes.rentRolleUser
    };
};

export default connect(mapState, null)(RentRoll);
