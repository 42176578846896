import axios from 'axios';

const plotService = {
    create: (data) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/plots?propertyId=${data.propertyId}&portfolioId=${data.portfolioId}`,
        });
    },

    createPlots: (data, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/plots/create-plots?propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    update: (data, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/plots?propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    delete: (plotId, propertyId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/plots/${plotId}?propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    getPlotById: (portfolioId, plotId, propertyId) => {
        return axios({
            method: 'get',
            url: `/api/contract/plots/${plotId}?portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    getPlotInfo: (gnrNumber, city, bnrNumber, cancelToken) => {
        return axios.get(`/api/contract/plots/info/${city}/${gnrNumber}/${bnrNumber}`, { cancelToken: cancelToken.token });
    },

    getPlotAddresses: (cadastreId, cancelToken) => {
        return axios.get(`/api/contract/plots/info/addresses/${cadastreId}`, { cancelToken: cancelToken.token });
    },

    searchMunicipalityAddresses: (searchString, cancelToken) => {
        return axios.get(`/api/contract/plots/municipality-addresses?searchString=${searchString}`, { cancelToken: cancelToken.token });
    },

    searchMunicipality: (searchString, municipalityNumber, cancelToken) => {
        return axios.get(`/api/contract/plots/search-municipality?searchString=${searchString}&municipalityNumber=${municipalityNumber || ''}`, { cancelToken: cancelToken.token });
    },

    getPlotDisplayName: ({ name, mainAddress }) => {
        return name ? name.trim() : mainAddress.trim();
    },
};

export default plotService;
