import i18next from 'i18next';
import update from '../helpers/update';
import { StatusCodes } from 'http-status-codes';
import toaster from '../common/toaster';
import languageService from '../services/languageService';
import routingService from '../services/routingService';

const SET_VALUE_TRANSLATIONS = 'SET_VALUE_TRANSLATIONS';

const initialState = {
    localizationRecords: [],
    importedLocalizationRecords: null,
    loading: false,
    crudLoading: false,
    importLoading: false,
    publishLoading: false,
};

const translationsReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_TRANSLATIONS:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;

        default:
            break;
    }

    return newState;
};

const handleError = (error) => {
    if (error && error.response && error.response.status === StatusCodes.FORBIDDEN) {
        routingService.navigateToHome();
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const setValueTranslations = (key, value) => {
    return {
        type: SET_VALUE_TRANSLATIONS,
        payload: {
            key,
            value,
        },
    };
};

export const getAllLocalizationRecords = (module, sourceLang, destinationLang) => {
    return (dispatch) => {
        dispatch(setValueTranslations('loading', true));
        languageService
            .getAllLocalizationRecords(module, sourceLang, destinationLang)
            .then((response) => {
                dispatch(setValueTranslations('localizationRecords', response.data || []));
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                dispatch(setValueTranslations('loading', false));
            });
    };
};

export const updateLocalizationKey = (data) => {
    return (dispatch) => {
        return languageService
            .updateLocalizationKey(data)
            .then((response) => {
                return true;
            })
            .catch((error) => {
                handleError(error);
            });
    };
};

export const deleteLocalizationKey = (key) => {
    return (dispatch) => {
        return languageService
            .deleteLocalizationKey(key)
            .then((response) => {
                return true;
            })
            .catch((error) => {
                if (error && error.response && error.response.status === StatusCodes.BAD_REQUEST && error.response.title) {
                    const message = i18next.t(error.response.title);
                    toaster.error(message, null);
                } else {
                    handleError(error);
                }
            });
    };
};

export const addLocalizationValue = (data) => {
    return (dispatch) => {
        dispatch(setValueTranslations('crudLoading', true));
        return languageService
            .addLocalizationValue(data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.title && error.response.status === StatusCodes.BAD_REQUEST) {
                    const message = i18next.t('translationManager.errorMessages.' + error.response.data.title);
                    toaster.error(message, null);
                } else {
                    handleError(error);
                }
            })
            .finally(() => {
                dispatch(setValueTranslations('crudLoading', false));
            });
    };
};

export const updateLocalizationValue = (data) => {
    return (dispatch) => {
        return languageService
            .updateLocalizationValue(data)
            .then((response) => {
                return true;
            })
            .catch((error) => {
                handleError(error);
            });
    };
};

export const exportTranslations = (module) => {
    return (dispatch) => {
        dispatch(setValueTranslations('importExportLoading', true));
        return languageService
            .exportTranslations(module)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                dispatch(setValueTranslations('importExportLoading', false));
            });
    };
};

export const importTranslationsIntoDb = (data) => {
    return (dispatch) => {
        dispatch(setValueTranslations('importExportLoading', true));
        return languageService
            .importTranslations(data)
            .then((response) => {
                return true;
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                dispatch(setValueTranslations('importExportLoading', false));
            });
    };
};

export const compareTranslations = (data) => {
    return (dispatch) => {
        dispatch(setValueTranslations('importExportLoading', true));
        return languageService
            .compareTranslations(data)
            .then((response) => {
                dispatch(setValueTranslations('deletedLocalizationKeys', response.data.keysToRemove));
                dispatch(setValueTranslations('addedLocalizationKeys', response.data.newKeys));
                dispatch(
                    setValueTranslations('importedLocalizationRecords', {
                        sourceLanguageValues: response.data.sourceLanguageValues,
                        destinationLanguageValues: response.data.destinationLanguageValues,
                    })
                );
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                dispatch(setValueTranslations('importExportLoading', false));
            });
    };
};

export const exportFlatTranslations = (module) => {
    return (dispatch) => {
        dispatch(setValueTranslations('exportLoading', true));
        return languageService
            .exportFlatTranslations(module)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                dispatch(setValueTranslations('exportLoading', false));
            });
    };
};

export const publishTranslations = (module) => {
    return (dispatch) => {
        dispatch(setValueTranslations('publishLoading', true));
        return languageService
            .publishTranslations(module)
            .then((response) => {
                return true;
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                dispatch(setValueTranslations('publishLoading', false));
            });
    };
};

export default translationsReducer;
