import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import commonCostBudget from '../../assets/images/budget-icon.svg';
import commonCostActual from '../../assets/images/common-cost-actual.svg';
import commonCostProperties from '../../assets/images/common-cost-properties.svg';
import commonCostImg from '../../assets/images/new-cost-card-icon.svg';
import moreIcon from '../../assets/images/more.svg';
import CurrencyFormat from '../currency-format';
import TextEllipsis from '../ellipsis-text';
import DeleteMenuItem from '../menu-items/delete-menu-item';
import EditMenuItem from '../menu-items/edit-menu-item';

const CommonCostCard = (props) => {
    const { t } = useTranslation();

    const openCommonCost = () => {
        props.openCommonCost(props.commonCost.id);
    };

    const editCommonCost = () => {
        props.editCommonCost && props.editCommonCost(props.commonCost);
    };

    const deleteCommonCost = () => {
        props.deleteCommonCost && props.deleteCommonCost(props.commonCost);
    };

    const dropdownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem
                    key="0"
                    onClick={editCommonCost}
                    disabled={!props.hasEditPermission || props.isArchived}
                    text={props.commonCost.hasAnyActuals ? t('commonCost.editCommonCostWithActuals') : t('commonCost.editCommonCost')}
                />
                <DeleteMenuItem key="1" onClick={deleteCommonCost} disabled={!props.hasEditPermission || props.isArchived} text={t('commonCost.deleteCommonCost')} />
            </Menu>
        );
    };

    return (
        <div className="entity-card common-cost-card">
            <div className="cost-image"  onClick={openCommonCost}>
                <Image src={commonCostImg} />
            </div>
            <div className="card-content">
                <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                    <TextEllipsis>
                        <h6 className="ellipsis">{props.commonCost.name}</h6>
                    </TextEllipsis>
                    <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu()}>
                        <Button className="btn-link btn-transparent settings-btn">
                            <Image src={moreIcon} />
                        </Button>
                    </Dropdown>
                </div>
                <div className="property p-0">
                    <Image src={commonCostProperties} />
                    <p className="text-secondary mb-0">{t('commonCost.card.property')}</p>
                    <p className="text-secondary ml-1 mb-0">{props.property.propertyName}</p>
                </div>
                <div className="property p-0">
                    <Image src={commonCostBudget} />
                    <p className="text-secondary mb-0">{t('commonCost.card.budget')}</p>
                    <p className="text-secondary ml-1 mb-0">
                        <CurrencyFormat value={props.commonCost.budgetTotal} />
                    </p>
                </div>
                {props.commonCost.hasAnyActuals && (
                    <div className="property p-0">
                        <Image src={commonCostActual} />
                        <p className="text-secondary mb-0">{t('commonCost.card.actual')}</p>
                        <p className="text-secondary ml-1 mb-0">
                            <CurrencyFormat value={props.commonCost.actualTotal} />
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CommonCostCard;
