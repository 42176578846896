import axios from 'axios';

const userService = {
    getCurrent: () => {
        return axios({
            method: 'get',
            url: `/api/access/current`,
        });
    },

    getUser(userId) {
        return axios({
            method: 'get',
            url: `/api/access/get-my-profile/${userId}`,
        });
    },

    updateUser: (user) => {
        return axios.put(`/api/access/update`, user);
    },

    uploadPhoto: (file, options) => {
        // TO DO: need to implement
        //return axios.put(`/api/access/update`, user);
    },
};

export default userService;
