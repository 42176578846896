import React from 'react';
import arrowIcon from '../../../assets/images/arrow-icon.svg';
import { Image } from 'react-bootstrap';

const YearPicker = (props) => {
    const { year, setYear, isRentRollLoading } = props;

    return (
        <div className="year-picker">
            <Image
                className={`left-arrow ${isRentRollLoading ? 'disabled-arrow' : ''}`}
                src={arrowIcon}
                onClick={() => !isRentRollLoading ? setYear(prev => prev - 1) : undefined}
            />
            <p>{year}</p>
            <Image
                className={`right-arrow ${isRentRollLoading ? 'disabled-arrow' : ''}`}
                src={arrowIcon}
                onClick={() => !isRentRollLoading ? setYear(prev => prev + 1) : undefined}
            />
        </div>
    );
};

export default YearPicker;
