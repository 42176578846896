import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Form, Radio } from 'antd';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setValueTenant, setAdditionalTenantInfo, clearAdditionalTenantInfo, defaultTenant } from '../../../reducers/tenantReducer';
import { requiredCommonInput, validateEmail } from '../../../common/validators';
import companyService from '../../../services/companyService';
import commonService from '../../../services/commonService';
import useDebounce from '../../../helpers/useDebounse';
import get from 'lodash-es/get';
import axios from 'axios';
import CompanyAutoComplete from '../../../components/company-autocomplete';
import KartverketDataComponent from '../../../components/kartverket-data';
import CommonInput from '../../../components/common-input';
import CountrySelect from '../../../components/country-selector';
import MaskedInput from 'antd-mask-input';

const TenantByCompanyComponent = (props) => {
    const { t } = useTranslation();
    const [showAllert, setShowAllert] = useState(false);
    const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
    const [searchOrgNumber, setSearchOrgNumber] = useState('');
    const debouncedSearchCompany = useDebounce(searchOrgNumber, 500);

    useEffect(() => {
        props.tenantRef.current.setFieldsValue(props.tenant);
    }, []);

    const onChangeRadioButton = (e) => {
        props.setValueTenantCall('tenant.ownedBy', e.target.value);
    };

    const onChange = (value) => {
        if (value === undefined) {
            setShowAllert(false);
            props.setValueTenantCall('tenant', {
                ...defaultTenant,
                id: props.tenant.id,
                uId: props.tenant.uId,
                ownedBy: props.tenant.ownedBy,
                country: props.tenant.country,
                activeContractsCount: props.tenant.activeContractsCount,
            });
            props.tenantRef.current.setFieldsValue({ orgName: '', orgNumber: '', post: '', address: '', municipality: '' });
            props.tenantRef.current.validateFields(['orgName', 'orgNumber', 'post', 'address', 'municipality']);
            props.clearAdditionalTenantInfoCall();
            setSearchOrgNumber(null);
        } else if (value.name) {
            setShowAllert(true);
            props.setValueTenantCall('tenant', { ...props.tenant, companyInfo: value.info });
            props.tenantRef.current.setFieldsValue({
                orgName: value.name,
                post: value.info.zipCode,
                orgNumber: value.info.identificationNumber,
                municipality: value.info.postal,
                address: value.info.address ? value.info.address.join(', ') : '',
            });
        } else if (typeof value === 'string' && !parseInt(value)) {
            props.tenantRef.current.setFieldsValue({
                orgName: value,
            });
        }
    };

    const onGetAdditionalInfo = (value) => {
        props.setAdditionalTenantInfoCall(value);
    };

    const onChangeOrgNumber = (value) => {
        setSearchOrgNumber(value.replace(/\s/g, ''));
        if (!value) {
            setShowAllert(false);
            cancelToken.cancel();
            setCancelToken(axios.CancelToken.source());
        }
    };

    useEffect(() => {
        if (debouncedSearchCompany) {
            if (/^\d{9}$/g.test(searchOrgNumber)) {
                companyService
                    .getCompanyByOrgNumber(searchOrgNumber)
                    .then((res) => {
                        props.tenantRef.current.setFieldsValue({
                            orgName: res.data.name,
                            post: res.data.zipCode,
                            orgNumber: res.data.identificationNumber,
                            municipality: res.data.postal,
                            address: res.data.address ? res.data.address.join(', ') : '',
                        });
                        setShowAllert(true);
                        props.setAdditionalTenantInfoCall(res.data);
                    })
                    .catch((error) => {
                        setShowAllert(false);
                        props.tenantRef.current.resetFields(['orgName', 'post', 'municipality', 'address']);
                        props.clearAdditionalTenantInfoCall();
                    });
            }
        }
    }, [debouncedSearchCompany, cancelToken]);

    return (
        <div className="object">
            <Col lg={12} xl={6} className="p-0">
                {!props.tenant.id && <h5>{t('tenant.details.title')}</h5>}
                <Form.Item name="ownedBy">
                    <Radio.Group defaultValue={props.tenant.ownedBy} className="radio-container" onChange={onChangeRadioButton}>
                        <Radio value={commonService.ownerRadioGroupTypes.company}>{t('common.company')}</Radio>
                        <Radio disabled={props.tenant.id && props.tenant.ownedBy === commonService.ownerRadioGroupTypes.company} value={commonService.ownerRadioGroupTypes.person}>
                            {t('common.person')}
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                <p className="font-weight-bold mb-2">{t('tenant.details.orgName.title')}</p>
                <Form.Item
                    name="orgName"
                    rules={[
                        {
                            required: true,
                            message: t('tenant.details.orgName.title') + t('common.isRequired'),
                        },
                    ]}>
                    <CompanyAutoComplete
                        value={props.tenant.companyInfo ? props.tenant.companyInfo.name : null}
                        isSelected={props.tenant.orgNumber && props.tenant.orgNumber}
                        initCompanyNumber={props.tenant.orgNumber}
                        getAdditionalInfo={onGetAdditionalInfo}
                        placeholder={t('tenant.details.orgName.placeholder')}
                        onChange={onChange}
                    />
                </Form.Item>
            </Col>
            {showAllert && props.companyInfo && <Alert message={t('common.companyAlertMessage') + ' '} description={t('common.companyAlertDescription')} type="success" showIcon />}
            <Col lg={12} xl={6} className="p-0">
                <p className="font-weight-bold">{t('tenant.details.orgNumber.title')}</p>
                <Form.Item
                    name="orgNumber"
                    rules={[
                        {
                            required: true,
                            message: t('tenant.details.orgNumber.title') + t('common.isRequired'),
                        },
                    ]}>
                    <MaskedInput onChange={(e) => onChangeOrgNumber(e.target.value)} mask="111 111 111" placeholder={t('tenant.details.orgNumber.placeholder')} />
                </Form.Item>
                <Form.Item name="corporateContact">
                    <CommonInput maxLength={150} title={t('tenant.details.corporateContact.title')} placeholder={t('tenant.details.corporateContact.placeholder')} />
                </Form.Item>
                <Form.Item name="corporateEmail" rules={[validateEmail]}>
                    <CommonInput type="email" maxLength={150} title={t('tenant.details.corporateEmail.title')} placeholder={t('tenant.details.corporateEmail.placeholder')} />
                </Form.Item>
                <Form.Item name="emailForInvoices" rules={[validateEmail]}>
                    <CommonInput type="email" maxLength={150} title={t('tenant.details.emailForInvoices.title')} placeholder={t('tenant.details.emailForInvoices.placeholder')} />
                </Form.Item>
                <Form.Item name="address" rules={[requiredCommonInput(t('tenant.details.address.title') + t('common.isRequired'))]}>
                    <CommonInput maxLength={150} title={t('tenant.details.address.title')} placeholder={t('tenant.details.address.placeholder')} />
                </Form.Item>
                <Form.Item name="post" rules={[requiredCommonInput(t('tenant.details.post.title') + t('common.isRequired'))]}>
                    <CommonInput maxLength={30} title={t('tenant.details.post.title')} placeholder={t('tenant.details.post.placeholder')} />
                </Form.Item>
                <Form.Item name="municipality" rules={[requiredCommonInput(t('tenant.details.municipality.title') + t('common.isRequired'))]}>
                    <CommonInput maxLength={150} title={t('tenant.details.municipality.title')} placeholder={t('tenant.details.municipality.placeholder')} />
                </Form.Item>
                <p className="font-weight-bold">{t('tenant.details.country.title')}</p>
                <Form.Item
                    name="country"
                    initialValue={t('defaultCountry')}
                    rules={[
                        {
                            required: true,
                            message: t('tenant.details.country.title') + t('common.isRequired'),
                        },
                    ]}>
                    <CountrySelect placeholder={t('tenant.details.country.placeholder')} />
                </Form.Item>
                {props.companyInfo && <KartverketDataComponent data={props.companyInfo} />}
            </Col>
        </div>
    );
};

const mapState = ({ tenant }) => {
    return {
        tenant: get(tenant, 'tenant'),
        companyInfo: get(tenant, 'tenant.companyInfo'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setAdditionalTenantInfoCall(value) {
            dispatch(setAdditionalTenantInfo(value));
        },
        clearAdditionalTenantInfoCall() {
            dispatch(clearAdditionalTenantInfo());
        },
        setValueTenantCall(key, value) {
            dispatch(setValueTenant(key, value));
        },
    };
};

const TenantByCompany = connect(mapState, mapDispatch)(TenantByCompanyComponent);
export default TenantByCompany;
