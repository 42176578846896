import React from 'react';
import { notification } from 'antd';
import { Image } from 'react-bootstrap';
import notificationSuccess from '../assets/images/notification-success.svg';
import notificationError from '../assets/images/notification-warning.svg';

const toaster = {
    success: (message, description) => {
        notification.success({
            message: message,
            bottom: 64,
            description: description,
            placement: 'bottomLeft',
            icon: <Image src={notificationSuccess} />,
        });
    },
    error: (message, description) => {
        notification.error({
            message: message,
            bottom: 64,
            description: description,
            placement: 'bottomLeft',
            icon: <Image src={notificationError} />,
        });
    },
};

export default toaster;
