import React, { useEffect } from 'react';
import { Form, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { requiredCommonInput } from '../../../common/validators';
import { setValueCommonCost } from '../../../reducers/commonCostReducer';
import uuidV4 from 'uuid/v4';
import get from 'lodash-es/get';
import ActionButton from '../../../components/action-button';
import CommonInput from '../../../components/common-input';

const AddAdditionalCostModalComponent = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const uniqueCosts = props.commonCostBudgets.concat(props.commonCostActuals).map((x) => x.name.toLowerCase());

    useEffect(() => {
        if (props.additionalCostEditModel) {
            form.setFieldsValue(props.additionalCostEditModel);
        }
    }, []);

    const handleClose = (_) => {
        props.closeModalWindow();
    };

    const onConfirm = (_) => {
        form.validateFields().then(() => {
            saveAdditionalCommonCost();
            handleClose();
        });
    };

    const saveAdditionalCommonCost = (_) => {
        const formData = form.getFieldsValue();
        const commonCostItem = props.additionalCostEditModel ? { ...props.additionalCostEditModel, ...formData } : formData;
        commonCostItem.uId = commonCostItem.uId || uuidV4();
        props.onSave && props.onSave(commonCostItem);
    };

    const validateAdditionalName = () => ({
        validator(rule, value) {
            if (!value || !value.length) {
                return Promise.resolve();
            }

            const listOfCostNames = [...uniqueCosts];
            if (props.additionalCostEditModel) {
                const costNameIndex = listOfCostNames.findIndex((x) => x === props.additionalCostEditModel.name.toLowerCase());
                if (costNameIndex >= 0) {
                    listOfCostNames.splice(costNameIndex, 1);
                }
            }

            return listOfCostNames.includes(value.toLowerCase()) ? Promise.reject(new Error(t('commonCost.additionalCost.validationMessage'))) : Promise.resolve();
        },
    });

    return (
        <Modal wrapClassName="result-modal additional-cost-modal" footer={null} visible={true} maskClosable={false} onCancel={handleClose} closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <h4 className="mb-0">{t('commonCost.additionalCost.header')}</h4>
                </div>
                <div className="main">
                    <Form name="additionalCost" form={form} autoComplete="off">
                        <Form.Item
                            name="name"
                            className="mb-3"
                            rules={[requiredCommonInput(t('commonCost.additionalCost.name.title') + t('common.isRequired')), validateAdditionalName()]}>
                            <CommonInput maxLength={150} title={t('commonCost.additionalCost.name.title')} placeholder={t('commonCost.additionalCost.name.placeholder')} />
                        </Form.Item>
                    </Form>
                </div>
                <div className="btns">
                    <ActionButton className="btn-secondary mr-3" text={t('common.buttons.cancel')} onClick={handleClose}></ActionButton>
                    <ActionButton
                        className="btn-primary"
                        text={t(props.additionalCostEditModel ? 'common.buttons.confirm' : 'commonCost.additionalCost.addCost')}
                        onClick={onConfirm}></ActionButton>
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ commonCost }) => {
    return {
        additionalCostEditModel: get(commonCost, 'additionalCostEditModel'),
        commonCostBudgets: get(commonCost, 'commonCost.commonCostBudgets') || [],
        commonCostActuals: get(commonCost, 'commonCost.commonCostActuals') || [],
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueCommonCost('showAddAdditionalCostModal', false));
            dispatch(setValueCommonCost('additionalCostEditModel', null));
        },
    };
};

const AddAdditionalCostModal = connect(mapState, mapDispatch)(AddAdditionalCostModalComponent);
export default AddAdditionalCostModal;
