import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import { Image } from 'react-bootstrap';
import notificationImage from '../../assets/images/notification-image.svg';

const Notification = ({ showImage = false, textElements, onClose }) => {
    return (
        <div className="notification">
            {showImage && <Image src={notificationImage} />}
            <div className="d-flex flex-column">{textElements}</div>
            {onClose && <CloseOutlined className="close-icon" onClick={onClose} />}
        </div>
    );
};

export default Notification;
