import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Menu, Tabs, Dropdown, Button } from 'antd';
import { Col, Image, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { deleteBuilding, downloadBuildingDocument, downloadFile, getBuildingById, openPdfBuildingDocument, setDeleteBuildingModel, setValueBuilding } from '../../../reducers/buildingReducer';
import { AreaItem, BuildingCommonPercents, GetImageOfBuilding } from '../building-data';
import DeleteMenuItem from '../../../components/menu-items/delete-menu-item';
import EditMenuItem from '../../../components/menu-items/edit-menu-item';
import ConfirmDeleteBuilding from '../modals/confirm-delete-modal';
import MainLayout from '../../../components/layouts/main-layout';
import ActionButton from '../../../components/action-button';
import NotFoundPage from '../../../components/error-page';
import buildingService from '../../../services/buildingService';
import routingService from '../../../services/routingService';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import backArrow from '../../../assets/images/back-arrow.svg';
import moreIcon from '../../../assets/images/more.svg';
import LoaderSpinner from '../../../components/spinner';
import NumberFormat from '../../../components/number-format';
import TextEllipsis from '../../../components/ellipsis-text';
import PropertyRow from '../../../components/property-row';
import { editPermisionTypes, pdfExtension } from '../../../common/constants';
import helper from '../../../helpers/helper';
import { downloadDocument, openPdfDocument } from '../../../reducers/contractReducer';

const { TabPane } = Tabs;

const buildingTabs = {
    details: '1',
};

const BuildingDetailsComponent = (props) => {
    const { t } = useTranslation();
    const { buildingId, propertyId, portfolioId } = useParams();
    const buildingsUrl = routingService.buildingsUrl(portfolioId, propertyId);

    useEffect(() => {
        if (portfolioId && buildingId && propertyId) {
            props.getBuilding(portfolioId, buildingId, propertyId);
        } else if (portfolioId && propertyId) {
            routingService.goTo(buildingsUrl);
        } else {
            routingService.navigateToPortfolios();
        }
    }, []);

    const goToBuildingEdit = () => {
        if (!props.hasEditPermission) return;
        routingService.navigateToEditBuilding(portfolioId, propertyId, buildingId);
    };

    const defaultIcon = () => {
        var randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return <div className="entity-image" style={{ backgroundColor: '#' + randomColor }} />;
    };

    const handleDownload = (e, file) => {
        e.preventDefault();
        if (helper.getFileExtension(file.name) == pdfExtension) {
            props.openPdfDocumentCall(file, portfolioId, propertyId);
        } else {
            props.downloadFileCall(file, file.name, portfolioId, propertyId);
        }
    };

    const onDeleteClick = (_) => {
        if (!props.hasEditPermission) return;
        props.setDeleteBuildingModelCall(props.building);
        props.showBuildingDeleteModal();
    };

    const handleDeleteBuilding = async () => {
        const isSuccess = await props.deleteBuildingCall(props.building.id, props.building.propertyId, portfolioId);
        if (isSuccess) {
            routingService.goTo(buildingsUrl);
            return true;
        }
    };

    const dropDownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" disabled={!props.hasEditPermission || props.isArchived} onClick={goToBuildingEdit} text={t('building.editBuilding')} />
                <DeleteMenuItem key="1" disabled={!props.hasEditPermission || props.isArchived} onClick={onDeleteClick} text={t('building.deleteBuilding')} />
            </Menu>
        );
    };

    const buildingCommonArea = buildingService.getBuildingCommonArea(props.building.floorsData.floors, props.building.floorsData.basementFloors);
    const buildingLettableArea = buildingService.getBuildingLettableArea(props.building.floorsData.floors, props.building.floorsData.basementFloors);
    const buildingCommonPercents = ((buildingCommonArea / buildingLettableArea) * 100).toFixed(2);

    return (
        <MainLayout>
            {props.buildingNotFound ? <NotFoundPage header={t('building.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {!props.propertyNotFound && !props.buildingNotFound && !props.buildingLoading && props.building && props.building.id ? (
                <div className="details-page">
                    <div className="title-container">
                        <div className="title">
                            <Link to={buildingsUrl}>
                                <Image className="m-0" src={backArrow} />
                            </Link>
                            {props.building.image ? <Image src={props.building.image} /> : defaultIcon()}
                            <TextEllipsis>
                                <h3 className="ellipsis mb-0">{props.building.name || props.building.address}</h3>
                            </TextEllipsis>
                            <Dropdown trigger={['click']} placement="bottomRight" overlay={dropDownMenu()}>
                                <Button className="btn-link px-0">
                                    <Image className="m-0" src={moreIcon} />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                    <Tabs defaultActiveKey={buildingTabs.details}>
                        <TabPane tab={t('building.tabs.details')} key={buildingTabs.details}>
                            <Row>
                                <Col lg={12}>
                                    <div className="details-card details-info">
                                        <div className="main">
                                            <Row>
                                                <Col lg={6} md={12}>
                                                    <h5>{t('building.detailsTab.title')}</h5>
                                                    {!!props.building.name && <PropertyRow title={t('building.detailsTab.buildingName')} value={props.building.name} />}
                                                    {!!props.building.owners && props.building.owners.length > 0 && (
                                                        <PropertyRow
                                                            title={t('building.detailsTab.relatedPlots')}
                                                            value={props.building.owners.map((plot) => plot.displayName).join(', ')}
                                                        />
                                                    )}
                                                    <PropertyRow title={t('building.detailsTab.address')} value={props.building.address} />
                                                    {!!props.building.buildYear && <PropertyRow title={t('building.detailsTab.buildYear')} value={props.building.buildYear} />}
                                                    {!!props.building.grossArea && (
                                                        <PropertyRow
                                                            title={t('building.detailsTab.grossArea')}
                                                            value={
                                                                <span>
                                                                    <NumberFormat value={props.building.grossArea} />
                                                                    {` m2`}
                                                                </span>
                                                            }
                                                        />
                                                    )}
                                                    {!!props.building.nonLettable && (
                                                        <PropertyRow
                                                            title={t('building.detailsTab.nonLettable')}
                                                            value={
                                                                <span>
                                                                    <NumberFormat value={props.building.nonLettable} />
                                                                    {` m2`}
                                                                </span>
                                                            }
                                                        />
                                                    )}
                                                    {!!props.building.lettable && (
                                                        <PropertyRow
                                                            title={t('building.detailsTab.lettable')}
                                                            value={
                                                                <span>
                                                                    <NumberFormat value={props.building.lettable} />
                                                                    {` m2`}
                                                                </span>
                                                            }
                                                        />
                                                    )}
                                                    {!!props.building.commonArea && (
                                                        <PropertyRow
                                                            title={t('building.detailsTab.commonArea')}
                                                            value={
                                                                <span>
                                                                    <NumberFormat value={props.building.commonArea} />
                                                                    {` m2`}
                                                                </span>
                                                            }
                                                        />
                                                    )}
                                                    {!!props.building.levels && <PropertyRow title={t('building.detailsTab.levels')} value={props.building.levels} />}
                                                    {props.building.plans && props.building.plans.length > 0 && (
                                                        <PropertyRow
                                                            title={t('building.detailsTab.buildingPlan')}
                                                            value={props.building.plans.map((plan) => (
                                                                <span key={plan.name}>
                                                                    <Link className="mr-1" onClick={(e) => handleDownload(e, plan)}>
                                                                        {plan.name}
                                                                    </Link>
                                                                </span>
                                                            ))}
                                                        />
                                                    )}
                                                </Col>
                                                {props.building.floorsData.floors.length > 0 || props.building.floorsData.basementFloors.length > 0 ? (
                                                    <Col lg={6} md={12}>
                                                        <div className="building-data">
                                                            <Row>
                                                                <Col md={6} sm={12} className="align-self-center">
                                                                    <div className="d-flex flex-column">
                                                                        <h6>{t('building.buildingConstructor.floorPlan')}</h6>
                                                                        <div className="d-flex flex-row justify-content-between">
                                                                            <p className="font-weight-bold mr-2">{t('building.buildingConstructor.floorLevels')}</p>
                                                                            <p className="font-weight-bold">{props.building.floorsData.floors.length}</p>
                                                                        </div>
                                                                        <div className="d-flex flex-row justify-content-between">
                                                                            <p className="font-weight-bold mr-4">{t('building.buildingConstructor.basementLevels')}</p>
                                                                            <p className="font-weight-bold">{props.building.floorsData.basementFloors.length}</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6} sm={12}>
                                                                    <div className="images">
                                                                        <GetImageOfBuilding
                                                                            floors={props.building.floorsData.floors}
                                                                            basementFloors={props.building.floorsData.basementFloors}
                                                                        />
                                                                    </div>
                                                                    <div className="building-details-row">
                                                                        <div className="building-details-item">
                                                                            <AreaItem value={buildingLettableArea} title={t('building.review.lettable')} />
                                                                        </div>
                                                                        <div className="building-details-item">
                                                                            <AreaItem value={buildingCommonArea} title={t('building.review.commonArea')} />
                                                                        </div>
                                                                        <div className="building-details-item">
                                                                            <BuildingCommonPercents value={buildingCommonPercents} />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        </div>
                                        <div className="btns">
                                            <ActionButton
                                                className="btn-secondary"
                                                text={t('common.buttons.edit')}
                                                disabled={!props.hasEditPermission || props.isArchived}
                                                onClick={goToBuildingEdit}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </div>
            ) : (
                <LoaderSpinner />
            )}
            {props.confirmDeleteBuilding && <ConfirmDeleteBuilding handleDeleteBuilding={handleDeleteBuilding} />}
        </MainLayout>
    );
};

const mapState = ({ building, property, navigation }) => {
    return {
        building: get(building, 'building'),
        buildingLoading: get(building, 'buildingLoading'),
        buildingNotFound: get(building, 'buildingNotFound'),
        confirmDeleteBuilding: get(building, 'showConfirmDeleteModal'),
        propertyNotFound: get(property, 'propertyNotFound'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        isArchived: get(building, 'building.isArchived')
    };
};

const mapDispatch = (dispatch) => {
    return {
        getBuilding(portfolioId, buildingId, propertyId) {
            dispatch(getBuildingById(portfolioId, buildingId, propertyId));
        },
        showBuildingDeleteModal() {
            dispatch(setValueBuilding('showConfirmDeleteModal', true));
        },
        deleteBuildingCall(buildingId, propertyId, portfolioId) {
            return dispatch(deleteBuilding(buildingId, propertyId, portfolioId));
        },
        setDeleteBuildingModelCall(value) {
            dispatch(setDeleteBuildingModel(value));
        },
        downloadFileCall(file, contractId, name, portfolioId, propertyId) {
            return dispatch(downloadBuildingDocument(file, contractId, name, portfolioId, propertyId));
        },
        openPdfDocumentCall(document, portfolioId, propertyId) {
            return dispatch(openPdfBuildingDocument(document, portfolioId, propertyId));
        },
    };
};

const BuildingDetails = clear(connect(mapState, mapDispatch)(BuildingDetailsComponent));
export default BuildingDetails;
