import { Form } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import costIcon from '../../../assets/images/new-design-cost-icon.svg';
import clear from '../../../common/clear';
import toaster from '../../../common/toaster';
import NotFoundPage from '../../../components/error-page';
import CostsObjectLayout from '../../../components/layouts/costs-object-layout';
import LeaveModal from '../../../components/leave-modal';
import objectHelper from '../../../helpers/trim-object';
import { getOwnerCostById, setValueOwnerCost, updateOwnerCost } from '../../../reducers/ownerCostReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';
import ownerCostService from '../../../services/ownerCostService';
import routingService from '../../../services/routingService';
import OwnerCostDetails from '../add-budget/details-step';
import CommonCostBudgetsReview from '../add-budget/review-step';
import HowToAddYourCommonCost from '../info-container';
import EditOwnerCostActual from './edit-actual-items-step';
import EditOwnerCostBudgetItems from './edit-budget-items-step';
import OwnerCostEditReview from './review-step';

const EditOwnerCostComponent = (props) => {
    const { t } = useTranslation();

    const stepNames = {
        details: 'details',
        budgets: 'budgets',
        actuals: 'actuals',
        review: 'review',
    };

    const [currentStepKey, setCurrentStepKey] = useState(stepNames.details);
    const [timelineSteps, setTimelineSteps] = useState([]);
    const timelineStepsRef = useRef([]);
    const detailsRef = useRef();
    const budgetRef = useRef();
    const actualRef = useRef();
    const { portfolioId, propertyId, ownerCostId } = useParams();

    useEffect(() => {
        if (portfolioId && propertyId && ownerCostId) {
            props.getPropertyCall(portfolioId, propertyId);
            props.getOwnerCostByIdCall(portfolioId, propertyId, ownerCostId);
        } else if (portfolioId && propertyId) {
            goOutOfPage();
        }

        mapTimelineSteps();
    }, []);

    //#region navigation

    useEffect(() => {
        mapTimelineSteps();
    }, [props.ownerCost]);

    const mapTimelineSteps = (_) => {
        const timelineSteps = steps.map((x) => ({
            key: x.key,
            stepTitle: x.stepTitle,
            isCompleted: isStepCompleted(x.key),
            canSkip: x.canSkip,
        }));

        setTimelineSteps(timelineSteps);
        timelineStepsRef.current = timelineSteps;
    };

    const isStepCompleted = (stepKey) => {
        if (!props.ownerCost) {
            return false;
        }

        switch (stepKey) {
            case stepNames.details:
                return props.ownerCost && props.ownerCost.name && props.ownerCost.startDate && props.ownerCost.endDate;
            case stepNames.budgets:
                return props.hasAnyBudgets;
            case stepNames.actuals:
                return props.hasAnyActuals;
        }

        return false;
    };

    const onNext = () => {
        const currentStepIndex = timelineSteps.findIndex((x) => x.key === currentStepKey);
        const nextStep = timelineSteps[currentStepIndex + 1];

        if (!nextStep) {
            return;
        }

        onStepChanging().then((updatedOwnerCost) => updatedOwnerCost && setCurrentStepKey(nextStep.key));
    };

    const onPrevious = (previousStep) => {
        if (previousStep) {
            setCurrentStepKey(previousStep.key);
        } else {
            const currentStepIndex = steps.findIndex((x) => x.key === currentStepKey);
            const previousStep = steps[currentStepIndex - 1];
            previousStep && setCurrentStepKey(previousStep.key);
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious(newStep);
        } else {
            onStepChanging().then((updatedOwnerCost) => updatedOwnerCost && onChangeStep(newStep.key));
        }
    };

    const onChangeStep = (newStepKey) => {
        const timelineStepsByRef = timelineStepsRef.current;
        const newIndex = timelineStepsByRef.findIndex((x) => x.key === newStepKey);
        const previousSteps = timelineStepsByRef.slice(0, newIndex);
        const isPreviousStepsAllCompleted = previousSteps.every((x) => x.isCompleted || x.canSkip);

        if (isPreviousStepsAllCompleted) {
            setCurrentStepKey(newStepKey);
        } else {
            const firstInCompletedStep = timelineStepsByRef.find((x) => !x.isCompleted || x.canSkip);
            firstInCompletedStep && setCurrentStepKey(firstInCompletedStep.key);
        }
    };

    //#endregion

    const getDataFromDetailsForm = (_) => {
        const values = detailsRef.current.getFieldsValue();
        return objectHelper.trimObjectProperties(values);
    };

    const validateStep = (formRef) => {
        return formRef.current.validateFields();
    };

    const onStepChanging = () => {
        const ownerCost = { ...props.ownerCost };
        switch (currentStepKey) {
            case stepNames.details: {
                return validateStep(detailsRef).then((_) => {
                    const formData = getDataFromDetailsForm();
                    ownerCost.startDate = moment(formData.costPeriod[0]).startOf('day').utc(true);
                    ownerCost.endDate = moment(formData.costPeriod[1]).startOf('day').utc(true);
                    ownerCost.name = formData.name;
                    ownerCost.buildingId = formData.buildingId;
                    ownerCost.plotId = formData.plotId;
                    ownerCost.grossArea = formData.grossArea;
                    ownerCost.lettable = formData.grossArea;
                    ownerCost.nonLettable = 0;
                    props.setValueOwnerCostCall('ownerCost', ownerCost);

                    return Promise.resolve(ownerCost);
                });
            }
            case stepNames.budgets: {
                const formDataBudgets = budgetRef.current.getFieldsValue();
                const budgetItems = mapBudgetCostItems(formDataBudgets, props.ownerCostBudgets);

                if (ownerCostService.listOfCostsIsEmpty(budgetItems)) {
                    toaster.error(t('commonCost.budgets.atLeastOneBudgetCost'), null);
                    return Promise.reject();
                }

                props.setValueOwnerCostCall('ownerCost', ownerCost);
                props.setValueOwnerCostCall('ownerCost.ownerCostBudgets', budgetItems);
                props.setValueOwnerCostCall('ownerCost.ownerCostItems', [...ownerCost.ownerCostActuals, ...budgetItems]);

                return Promise.resolve(ownerCost);
            }
            case stepNames.actuals: {
                const formDataActuals = actualRef.current.getFieldsValue();
                const actualItems = mapActualCostItems(formDataActuals, props.ownerCostActuals);

                if (ownerCostService.listOfCostsIsEmpty(actualItems)) {
                    toaster.error(t('commonCost.actuals.atLeastOneActualCost'), null);
                    return Promise.reject();
                }

                props.setValueOwnerCostCall('ownerCost', ownerCost);
                props.setValueOwnerCostCall('ownerCost.ownerCostActuals', actualItems);
                props.setValueOwnerCostCall('ownerCost.ownerCostItems', [...ownerCost.ownerCostBudgets, ...actualItems]);

                return Promise.resolve(ownerCost);
            }
            default:
                return Promise.resolve(ownerCost);
        }
    };

    const mapBudgetCostItems = (formData, storedItems) => {
        const predefinedItems = storedItems.filter((x) => x.id);
        const additionalItems = storedItems.filter((x) => !x.id);
        predefinedItems.forEach((x) => (x.value = formData['budget_id_' + x.id]));
        const items = [...predefinedItems, ...additionalItems];

        return items;
    };

    const mapActualCostItems = (formData, storedItems) => {
        const predefinedItems = storedItems.filter((x) => !x.isAdditional);
        const additionalItems = storedItems.filter((x) => x.isAdditional);
        predefinedItems.forEach((x) => (x.value = formData['actual_id_' + (x.id || x.uId)]));
        const items = [...predefinedItems, ...additionalItems];

        return items;
    };

    const onSaveAndExit = (_) => {
        onStepChanging().then((updatedOwnerCost) => {
            updatedOwnerCost.portfolioId = portfolioId;
            updatedOwnerCost.ownerCostBudgets = ownerCostService.filterOwnerCostItemsByValue(updatedOwnerCost.ownerCostBudgets);
            updatedOwnerCost.ownerCostActuals = ownerCostService.filterOwnerCostItemsByValue(updatedOwnerCost.ownerCostActuals);
            updatedOwnerCost && props.updateOwnerCostCall(updatedOwnerCost).then((isSuccess) => isSuccess && updated());
        });
    };

    const updated = (_) => {
        goOutOfPage();
        props.showOwnerCostUpdatedResult();
    };

    const goOutOfPage = (_) => {
        routingService.navigateToOwnerCostDetails(portfolioId, propertyId, ownerCostId);
    };

    const onOpenCloseDraftModal = (_) => {
        props.setValueOwnerCostCall('showDraftModal', !props.showDraftModal);
    };

    const steps = [
        {
            key: stepNames.details,
            header: t('ownerCost.actualsStep.header'),
            stepTitle: t('commonCost.detailsStep.stepTitle'),
            logo: costIcon,
            content: (
                <Form name="details" autoComplete="off" ref={detailsRef}>
                    <OwnerCostDetails detailsRef={detailsRef} />
                </Form>
            ),
        },
        {
            key: stepNames.budgets,
            header: t('commonCost.budgetsStep.header'),
            stepTitle: t('commonCost.budgetsStep.stepTitle'),
            canSkip: true,
            content: (
                <Form name="budgets" autoComplete="off" ref={budgetRef}>
                    <EditOwnerCostBudgetItems budgetRef={budgetRef} />
                </Form>
            ),
        },
        ...(props.ownerCost && props.ownerCost.hasAnyActuals
            ? [
                  {
                      key: stepNames.actuals,
                      header: t('commonCost.actualsStep.header'),
                      stepTitle: t('commonCost.actualsStep.stepTitle'),
                      content: (
                          <Form name="actuals" autoComplete="off" ref={actualRef}>
                              <EditOwnerCostActual actualRef={actualRef} />
                          </Form>
                      ),
                  },
              ]
            : []),
        {
            key: stepNames.review,
            header: t('commonCost.reviewStep.header'),
            stepTitle: t('commonCost.reviewStep.header'),
            content: props.hasAnyActuals ? (
                <OwnerCostEditReview goToStep={() => setCurrentStepKey(stepNames.budgets)} />
            ) : (
                <CommonCostBudgetsReview goToStep={() => setCurrentStepKey(stepNames.budgets)} />
            ),
        },
    ];

    return (
        <React.Fragment>
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.ownerCostNotFound ? <NotFoundPage header={t('commonCost.notFound')} /> : null}
            {!props.portfolioNotFound && !props.propertyNotFound && !props.ownerCostNotFound ? (
                <CostsObjectLayout
                    className="common-cost-new-design"
                    isNewDesign={true}
                    steps={steps}
                    timelineSteps={timelineSteps}
                    currentStepKey={currentStepKey}
                    bottomRightSideBar={<HowToAddYourCommonCost />}
                    exitUrl={routingService.propertyUrl(portfolioId, propertyId)}
                    isEdit={ownerCostId}
                    loading={props.ownerCostLoading}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onSwitchStep={onSwitchStep}
                    onSaveAndExit={onSaveAndExit}
                    onCancel={onOpenCloseDraftModal}
                />
            ) : null}
            {props.showDraftModal && <LeaveModal onCancel={onOpenCloseDraftModal} onLeave={goOutOfPage} onSave={onSaveAndExit} loading={props.ownerCostLoading}  className="cost-draft-modal"/>}
        </React.Fragment>
    );
};

const mapState = ({ property, portfolio, ownerCost }) => {
    return {
        nonLettable: get(ownerCost, 'assignedAssetAreaData.nonLettable'),
        ownerCost: get(ownerCost, 'ownerCost'),
        ownerCostBudgets: get(ownerCost, 'ownerCost.ownerCostBudgets'),
        ownerCostActuals: get(ownerCost, 'ownerCost.ownerCostActuals'),
        hasAnyBudgets: get(ownerCost, 'ownerCost.ownerCostBudgets.length') > 0,
        hasAnyActuals: get(ownerCost, 'ownerCost.ownerCostActuals.length') > 0,
        showDraftModal: get(ownerCost, 'showDraftModal'),
        ownerCostLoading: get(ownerCost, 'ownerCostLoading'),
        ownerCostNotFound: get(ownerCost, 'ownerCostNotFound'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        updateOwnerCostCall(values) {
            return dispatch(updateOwnerCost(values));
        },
        getOwnerCostByIdCall(portfolioId, propertyId, ownerCostId) {
            return dispatch(getOwnerCostById(portfolioId, propertyId, ownerCostId));
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        setValueOwnerCostCall(key, value) {
            dispatch(setValueOwnerCost(key, value));
        },
        showOwnerCostUpdatedResult() {
            dispatch(setValueOwnerCost('showOwnerCostUpdatedResultModal', true));
        },
    };
};

const EditOwnerCost = clear(connect(mapState, mapDispatch)(EditOwnerCostComponent));
export default EditOwnerCost;
