import axios from 'axios';

const tenantService = {
    getTenantsByPortfolioId: (portfolioId) => {
        return axios({
            method: 'get',
            url: `/api/contract/tenants/get-by-portfolio-id?portfolioId=${portfolioId}`,
        });
    },

    getById: (portfolioId, id, propertyId, status) => {
        return axios({
            method: 'post',
            url: `/api/contract/tenants/${id}?portfolioId=${portfolioId}&propertyId=${propertyId}`,
            data: {
                status: status
            }
        });
    },

    getAll: (propertyId, portfolioId) => {
        return axios({
            method: 'get',
            url: `/api/contract/tenants/get-by-property?propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    getDetails: (portfolioId, tenantId, propertyId) => {
        return axios({
            method: 'get',
            url: `/api/contract/tenants/details/${tenantId}?portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    getSummary: (id, propertyId, portfolioId) => {
        return axios({
            method: 'get',
            url: `/api/contract/tenants/summary/${id}?propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    search: (propertyId, search = '', portfolioId) => {
        return axios({
            method: 'get',
            url: `/api/contract/tenants/search?propertyId=${propertyId}&search=${search}&portfolioId=${portfolioId}`,
        });
    },

    // create: (data) => {
    //     return axios({
    //         method: 'post',
    //         url: `/api/contract/tenants?propertyId=${data.propertyId}`,
    //         data: data,
    //     });
    // },

    edit: (data, propertyId, portfolioId) => {
        return axios({
            method: 'put',
            url: `/api/contract/tenants?propertyId=${propertyId}&portfolioId=${portfolioId}`,
            data: data,
        });
    },

    create: (data, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            url: `/api/contract/tenants/create-tenants?propertyId=${propertyId}&portfolioId=${portfolioId}`,
            data: data,
        });
    },

    delete: (tenantId, propertyId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/tenants/${tenantId}?propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },
};

export default tenantService;
