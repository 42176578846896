import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal, DatePicker, Input } from 'antd';
import { connect } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { Image } from 'react-bootstrap';
import { addContractNote, setValueContract } from '../../../../reducers/contractReducer';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';
import ActionButton from '../../../../components/action-button';
import get from 'lodash-es/get';
import moment from 'moment';
import { useParams } from 'react-router';

const CreateNoteModalComponent = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const { portfolioId, propertyId } = useParams();

    const onClose = () => {
        props.setNoteFormVisible(false);
    };

    const addContractNote = () => {
        form.validateFields()
            .then(() => {
                const values = {
                    ...form.getFieldsValue(),
                    date: moment()
                };
                props.addNoteCall(values, props.contractId, propertyId, portfolioId).then((isSuccess) => {
                    if (isSuccess) {
                        props.setNoteFormVisible(false);
                        form.resetFields();
                    }
                });
            })
            .catch(() => { });
    };

    return (
        <div className={`contract-notes ${props.noteFormVisible ? 'collapsed' : ''}`} >
            <Form form={form} autoComplete="off">
                <div>
                    <p className="mb-2 font-weight-bold d-flex justify-content-between">{t('common.date')}<span>{moment().format('LL')}</span></p>
                    <p className="mb-2 font-weight-bold">{t('contract.notes.noteHeading')}</p>
                    <Form.Item
                        name="noteHeading"
                        rules={[
                            {
                                required: true,
                                message: t('contract.notes.noteHeading') + t('common.isRequired'),
                            },
                        ]}>
                        <Input placeholder={t('contract.notes.noteHeading')} />
                    </Form.Item>
                    <p className="mb-2 font-weight-bold">{t('contract.notes.note.label')}</p>
                    <Form.Item
                        name="note"
                        rules={[
                            {
                                required: true,
                                message: t('contract.notes.note.title') + t('common.isRequired'),
                            },
                        ]}>
                        <Input.TextArea rows={5} maxLength={1024} placeholder={t('contract.notes.note.placeholder')} />
                    </Form.Item>
                </div>
            </Form>
            <div className="btns">
                <ActionButton onClick={onClose} className="btn-secondary" text={t('common.buttons.cancel')} />
                <ActionButton onClick={addContractNote} loading={props.contractNoteLoading} className="btn-primary" text={t('common.buttons.save')} />
            </div>
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        contractId: get(contract, 'contract.id'),
        contractNoteLoading: get(contract, 'contractNoteLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showCreateNoteModal', false));
        },
        addNoteCall(data, contractId, propertyId, portfolioId) {
            return dispatch(addContractNote(data, contractId, propertyId, portfolioId));
        },
    };
};

const CreateNoteModal = connect(mapState, mapDispatch)(CreateNoteModalComponent);
export default CreateNoteModal;
