import { Col, Row, Switch, Form, InputNumber } from 'antd';
import React, { useCallback, useState } from 'react';
import TurnoverNotification from './turnover-notification';
import { Image } from 'react-bootstrap';
import union from '../../../../assets/images/union.svg';
import PositiveNumericInput from '../../../../components/numeric-input';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/action-button';
import { QuestionCircleFilled } from '@ant-design/icons';
import helper from '../../../../helpers/helper';

const TurnoverRateIntervals = (props) => {
    const { t } = useTranslation();

    const { sourceName, initialMultipleIntervals, hideDeleteButton, turnoverRateForm, rateIndex } = props;

    const [multipleIntervals, setMultipleIntervals] = useState(initialMultipleIntervals ? initialMultipleIntervals : false);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [turnoverRate, setTurnoverRate] = useState();

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    return (
        <div>
            <h6>{t('contract.turnover.turnoverRate.title')}</h6>
            <Form.Item
                name={[sourceName, 'rate']}
                className="rate-input"
                rules={[
                    {
                        required: true,
                        message: t('contract.turnover.turnoverRate.title') + t('common.isRequired'),
                    },
                    {
                        type: 'number',
                        min: 0,
                        message: t('contract.turnover.turnoverRate.title') + t('contract.turnover.turnoverRate.min'),
                    },
                    {
                        type: 'number',
                        max: 100,
                        message: t('contract.turnover.turnoverRate.title') + t('contract.turnover.turnoverRate.max'),
                    },
                ]}>
                <InputNumber
                    className="ant-input ant-input"
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    onChange={(value) => setTurnoverRate(value)}
                />
            </Form.Item>
            <Row className="mt-3">
                <Col>
                    <h6 className="mb-0">{t('contract.turnover.turnoverRate.multipleIntervals')}</h6>
                    <p>{multipleIntervals ? 'On' : 'Off'}</p>
                </Col>
                <Col offset={1}>
                    <Form.Item name={[sourceName, 'multipleInterval']} valuePropName="checked" initialValue={false}>
                        <Switch onChange={(cheked) => setMultipleIntervals(cheked)} />
                    </Form.Item>
                </Col>
            </Row>
            <div className={`multiple-rates ${multipleIntervals ? 'multiple-rates-full' : ''}`}>
                <Row className="mt-3">
                    <Col>
                        <p>{t('contract.turnover.turnoverRate.addAdditionalRates')}</p>
                    </Col>
                    <Col className="ml-1">
                        <QuestionCircleFilled onClick={() => setNotificationVisible(true)} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {notificationVisible && (
                            <TurnoverNotification text={t('contract.turnover.turnoverRate.addAdditionalRatesNotification')} close={() => setNotificationVisible(false)} />
                        )}
                    </Col>
                </Row>
                <Form.List name={[sourceName, 'turnoverIntervals']} initialValue={[{}]}>
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map(({ key, name, ...restField }, index) => {
                                const prevValue = turnoverRateForm.getFieldsValue()?.turnoverRates && turnoverRateForm.getFieldsValue()?.turnoverRates[rateIndex]?.turnoverIntervals[index - 1]?.interval;
                                const prevValueFormatted = key == 0 || prevValue == undefined ? 0 : helper.numberWithSpaces(prevValue);

                                return (
                                    <div className="turnover-rate-interval" key={key}>
                                        <h6 className="mt-3">{`${t('contract.turnover.turnoverRate.turnoverInterval')} ${index + 1}`}</h6>
                                        <p className="ml-3">{`${t('contract.turnover.turnoverRate.between')} ${prevValueFormatted} NOK ${t('contract.turnover.turnoverRate.and')}`}</p>
                                        <Row>
                                            <Col span={12}>
                                                <Form.Item
                                                    name={[name, 'interval']}
                                                    rules={[
                                                        {
                                                            required: multipleIntervals,
                                                            message: t('contract.turnover.turnoverRate.turnoverInterval') + t('common.isRequired'),
                                                        },
                                                    ]}>
                                                    <PositiveNumericInput prefix={t('currency.norway')} onChange={() => forceUpdate()}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={11} offset={1} className="d-flex">
                                                <Form.Item
                                                    name={[name, 'rate']}
                                                    className="rate-input"
                                                    rules={[
                                                        {
                                                            required: multipleIntervals,
                                                            message: t('contract.turnover.turnoverRate.title') + t('common.isRequired'),
                                                        },
                                                        {
                                                            type: 'number',
                                                            min: 0,
                                                            message: t('contract.turnover.turnoverRate.title') + t('contract.turnover.turnoverRate.min'),
                                                        },
                                                        {
                                                            type: 'number',
                                                            max: 100,
                                                            message: t('contract.turnover.turnoverRate.title') + t('contract.turnover.turnoverRate.max'),
                                                        },
                                                    ]}>
                                                    <InputNumber className="ant-input ant-input" formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} />
                                                    <Image src={union} className="ml-3" onClick={() => remove(name)}></Image>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <p>{t('contract.turnover.turnoverRate.cpiAdjusted')}</p>
                                    </div>
                                );
                            })}
                            {turnoverRate && (
                                <Row>
                                    <Col span={12}>
                                        <p>{`${t('contract.turnover.turnoverRate.turnoverAmountExceedPart1')} ${turnoverRate}% ${t(
                                            'contract.turnover.turnoverRate.turnoverAmountExceedPart2'
                                        )}`}</p>
                                    </Col>
                                    <Col offset={1}>
                                        <p>{`${turnoverRate}%`}</p>
                                    </Col>
                                </Row>
                            )}
                            <ActionButton className="btn-secondary add-interval mb-3" onClick={() => add()} text={t('contract.turnover.turnoverRate.addInterval')} />
                        </>
                    )}
                </Form.List>
            </div>
            {!hideDeleteButton && (
                <Row>
                    <Col span={24} className="d-flex justify-content-end">
                        <ActionButton className="btn-secondary add-interval mb-3" onClick={() => props.deleteSource()} text={t('contract.turnover.turnoverRate.deleteSource')} />
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default TurnoverRateIntervals;
