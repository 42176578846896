import { Checkbox, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const RentAdjustmentOnHandover = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h5>{t('contract.rentAdjustment.cpiAdjustmentOnHandoverTitle')}</h5>
            <Form.Item name="cpiAdjustmentOnHandover" valuePropName="checked">
                <Checkbox disabled={!props.cpiAdjustment} className="mb-0">{t('contract.rentAdjustment.cpiAdjustmentOnHandoverOption')}</Checkbox>
            </Form.Item>
        </React.Fragment>
    );
};

export default RentAdjustmentOnHandover;
