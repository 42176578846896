import { Menu, Select } from 'antd';
import get from 'lodash-es/get';
import React from 'react';
import { Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import languagesActive from '../../assets/images/languages-active.svg';
import languagesWhite from '../../assets/images/languages-white.svg';
import logOutIcon from '../../assets/images/logout-icon.svg';
import settingsActive from '../../assets/images/settings-active.svg';
import settingsDefault from '../../assets/images/settings-default.svg';
import settingsWhite from '../../assets/images/settings-white.svg';
import usersActive from '../../assets/images/users-active.svg';
import usersWhite from '../../assets/images/users-white.svg';
import newDashboard from '../../assets/images/new-dashboard.svg';
import newPortfolio from '../../assets/images/new-portfolio.svg';
import newProperties from '../../assets/images/new-properties.svg';
import newContracts from '../../assets/images/new-contracts.svg';
import newTenant from '../../assets/images/new-tenant.svg';
import { getNavigationData, setValueNavigation } from '../../reducers/navigationReducer';
import authService from '../../services/authService';
import routingService from '../../services/routingService';
import TextEllipsis from '../ellipsis-text';
import LoaderSpinner from '../spinner';
import { userPermisionsTypes } from '../../common/constants';

const { Option } = Select;

export const navigationEnum = {
    portfolios: '0',
    portfolio: '1',
    properties: '2',
    portfolioSettings: '3',
    accountSettings: '4',
    logout: '5',
    backofficeUsers: '6',
    backofficeTranslations: '7',
    contracts: '8',
    tenants: '9',
};

class NavigationComponent extends React.Component {
    state = {
        selectedKeys: [],
    };

    portfolioId = +this.props.match.params.portfolioId;
    url = this.props.location.pathname;

    dashboardLocation = this.url.includes('/dashboard');
    portfolioLocation = this.url.includes('/portfolio/');
    portfoliosLocation = this.url.includes('portfolios') || this.url === '/';

    propertyLocation = this.url.includes('/property/');
    propertiesLocation = this.url.includes('/properties');

    contractsLocation = this.url.includes('/contracts');
    contractLocation = this.url.includes('/contract');
    tenantsLocation = this.url.includes('/tenants');
    tenantLocation = this.url.includes('/tenant');

    backofficeLocation = this.url.includes('/backoffice');
    userSettingsLocation = this.url.includes('/user-settings');
    usersLocation = this.backofficeLocation && this.url.includes('/users');
    translationsLocation = this.backofficeLocation && this.url.includes('/translations');

    componentDidMount() {
        this.loadNavigationData(this.portfolioId);

        if (this.portfoliosLocation) {
            this.setState({ selectedKeys: [navigationEnum.portfolios] });
        } else if (this.contractsLocation || this.contractLocation) {
            this.setState({ selectedKeys: [navigationEnum.contracts] });
        } else if (this.tenantsLocation || this.tenantLocation) {
            this.setState({ selectedKeys: [navigationEnum.tenants] });
        } else if (this.propertiesLocation || this.propertyLocation) {
            this.setState({ selectedKeys: [navigationEnum.properties] });
        } else if (this.portfolioLocation) {
            this.setState({ selectedKeys: [navigationEnum.portfolio] });
        } else if (this.userSettingsLocation) {
            this.setState({ selectedKeys: [navigationEnum.accountSettings] });
        }

        if (this.usersLocation) {
            this.setState({ selectedKeys: [navigationEnum.backofficeUsers] });
        } else if (this.translationsLocation) {
            this.setState({ selectedKeys: [navigationEnum.backofficeTranslations] });
        }
    }

    componentDidUpdate() {
        const currentPortfolioId = +this.props.match.params.portfolioId;
        const selectedPortfolio = this.props.selectedPortfolio;

        if (!this.props.hasPortfolios && !this.props.navigationLoading && currentPortfolioId) {
            this.loadNavigationData(currentPortfolioId);
        }

        if (!currentPortfolioId) {
            this.props.clearSelectedPortfolioCall();
        } else if (!selectedPortfolio || selectedPortfolio.id != currentPortfolioId) {
            this.setSelectedPortfolio(currentPortfolioId, this.props.portfolios);
        }
    }

    loadNavigationData = (portfolioId) => {
        if (this.props.hasPortfolios) {
            return;
        }

        this.props.getNavigationDataCall().then((portfolios) => {
            this.setSelectedPortfolio(portfolioId, portfolios);
        });
    };

    setSelectedPortfolio = (portfolioId, portfolios) => {
        const portfolio = portfolioId ? portfolios.find((x) => x.id === portfolioId) : null;
        this.props.setSelectedPortfolioCall(portfolio);
    };

    goToPortfolio = (portfolioId) => {
        if (!portfolioId) {
            routingService.navigateToPortfolios();
            return;
        }

        this.setSelectedPortfolio(portfolioId, this.props.portfolios);
        routingService.navigateToPortfolioDashboard(portfolioId);
    };

    renderPortfolioName = () => {
        return this.props.selectedPortfolio ? <p className="ml-3 mx-3 navigation-header">{this.props.selectedPortfolio.name}</p> : null;
    };

    render() {
        const { t, portfolios, selectedPortfolio, navigationLoading } = this.props;
        const notFoundPortfolio = portfolios && !selectedPortfolio;
        const loading = navigationLoading && !this.userSettingsLocation && !this.backofficeLocation;

        return (
            <div className={`navigation ${this.props.className || ''}`}>
                {loading ? (
                    <LoaderSpinner />
                ) : (
                    <React.Fragment>
                        <div className="mobile-part">
                            <h3>{t('navigation.menu')}</h3>
                        </div>
                        <Menu className="menu" selectedKeys={this.state.selectedKeys}>
                            {!this.portfoliosLocation ? (
                                <Menu.Item key={navigationEnum.portfolios} className={this.portfoliosLocation ? '' : 'menu-item'}>
                                    <Link to={routingService.portfoliosUrl()}>
                                        <div className="image-wrapper">
                                            <Image src={newPortfolio} />
                                        </div>
                                        <span className="menu-item-text">{t('navigation.portfolios')}</span>
                                    </Link>
                                </Menu.Item>
                            ) : null}
                            {this.portfolioLocation && !notFoundPortfolio && !this.props.isRentRollPermision ? (
                                <Menu.Item key={navigationEnum.portfolio} className={this.dashboardLocation ? '' : 'menu-item'}>
                                    <Link to={routingService.portfolioDashboardUrl(selectedPortfolio.id)}>
                                        <div className="image-wrapper">
                                            <Image src={newDashboard} />
                                        </div>
                                        <span className="menu-item-text">{t('navigation.dashboard')}</span>
                                    </Link>
                                </Menu.Item>
                            ) : null}
                            {this.portfolioLocation && !notFoundPortfolio ? (
                                <Menu.Item
                                    key={navigationEnum.properties}
                                    className={this.propertiesLocation || (this.propertyLocation && !this.tenantLocation) ? '' : 'menu-item'}>
                                    <Link to={routingService.propertiesUrl(selectedPortfolio.id)}>
                                        <div className="image-wrapper">
                                            <Image src={newProperties} />
                                        </div>
                                        <span className="menu-item-text">{t('navigation.properties')}</span>
                                    </Link>
                                </Menu.Item>
                            ) : null}
                            {this.portfolioLocation && !notFoundPortfolio && !this.props.isRentRollPermision ? (
                                <Menu.Item key={navigationEnum.contracts} className={this.contractsLocation || this.contractLocation ? '' : 'menu-item'}>
                                    <Link to={routingService.portfolioContractsUrl(selectedPortfolio.id)}>
                                        <div className="image-wrapper">
                                            <Image src={newContracts} />
                                        </div>
                                        <span className="menu-item-text">{t('navigation.contracts')}</span>
                                    </Link>
                                </Menu.Item>
                            ) : null}
                            {this.portfolioLocation && !notFoundPortfolio && !this.props.isRentRollPermision ? (
                                <Menu.Item key={navigationEnum.tenants} className={this.tenantsLocation || (this.tenantLocation && !this.contractLocation) ? '' : 'menu-item'}>
                                    <Link to={routingService.portfolioTenantsUrl(selectedPortfolio.id)}>
                                        <div className="image-wrapper">
                                            <Image src={newTenant} />
                                        </div>
                                        <span className="menu-item-text">{t('navigation.tenants')}</span>
                                    </Link>
                                </Menu.Item>
                            ) : null}
                            {this.portfolioLocation && !notFoundPortfolio && !this.props.isRentRollPermision ? (
                                <Menu.Item key={navigationEnum.portfolioSettings} disabled={!selectedPortfolio.hasEditPermission} className="menu-item">
                                    <Link to={routingService.editPortfolioUrl(selectedPortfolio.id)}>
                                        <div className="image-wrapper">
                                            <Image src={settingsWhite} />
                                        </div>
                                        <span className="menu-item-text">{t('navigation.portfolioSettings')}</span>
                                    </Link>
                                </Menu.Item>
                            ) : null}
                            {authService.isAdmin() &&
                                (this.backofficeLocation ? (
                                    <>
                                        <Menu.Item key={navigationEnum.backofficeUsers} className={this.usersLocation ? '' : 'menu-item'}>
                                            <Link to={routingService.backOfficeUsersUrl()}>
                                                <div className="image-wrapper">
                                                    <Image src={usersWhite} />
                                                </div>
                                                <span className="menu-item-text">{t('navigation.backoffice.users')}</span>
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key={navigationEnum.backofficeTranslations} className={this.translationsLocation ? '' : 'menu-item'}>
                                            <Link to={routingService.translationsManagerUrl()}>
                                                <div className="image-wrapper">
                                                    <Image src={languagesWhite} />
                                                </div>
                                                <span className="menu-item-text">{t('navigation.backoffice.translations')}</span>
                                            </Link>
                                        </Menu.Item>
                                    </>
                                ) : (
                                    authService.isAdmin() && (
                                        <Menu.Item key={navigationEnum.backofficeUsers} className={this.backofficeLocation ? '' : 'menu-item'}>
                                            <Link to={routingService.backOfficeUsersUrl()}>
                                                <div className="image-wrapper">
                                                    <Image src={settingsWhite} />
                                                </div>
                                                <span className="menu-item-text">{t('navigation.backoffice')}</span>
                                            </Link>
                                        </Menu.Item>
                                    )
                                ))}
                        </Menu>
                        <div className="mobile-part">
                            <p className="ml-3 mb-0 mt-3 navigation-header">{t('navigation.account')}</p>
                            <Menu className="menu user-menu" selectedKeys={this.state.selectedKeys}>
                                <Menu.Item key={navigationEnum.accountSettings}>
                                    <Link to={routingService.userSettingsUrl()}>
                                        <div className="image-wrapper">
                                            <Image src={this.userSettingsLocation ? settingsActive : settingsDefault} />
                                        </div>
                                        {t('navigation.accountSettings')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={navigationEnum.logout}>
                                    <Link to={routingService.loginUrl()}>
                                        <div className="image-wrapper">
                                            <Image src={logOutIcon} />
                                        </div>
                                        {t('navigation.logOut')}
                                    </Link>
                                </Menu.Item>
                            </Menu>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapState = ({ navigation }) => {
    return {
        portfolios: get(navigation, 'navigationPortfolios'),
        hasPortfolios: get(navigation, 'navigationPortfolios.length') > 0,
        navigationLoading: get(navigation, 'navigationLoading'),
        selectedPortfolio: get(navigation, 'selectedPortfolio'),
        isRentRollPermision: get(navigation, 'selectedPortfolio.permissionId') == userPermisionsTypes.rentRolleUser
    };
};

const mapDispatch = (dispatch) => {
    return {
        getNavigationDataCall() {
            return dispatch(getNavigationData());
        },
        setSelectedPortfolioCall(portfolio) {
            dispatch(setValueNavigation('selectedPortfolio', portfolio));
        },
        clearSelectedPortfolioCall() {
            dispatch(setValueNavigation('selectedPortfolio', null));
        },
    };
};

const Navigation = withRouter(connect(mapState, mapDispatch)(withTranslation()(NavigationComponent)));
export default Navigation;
