import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deleteRentExemption, setValueContract } from '../../../../../reducers/contractReducer';
import get from 'lodash-es/get';
import ConfirmDeleteModal from '../../../../../components/confirm-delete-modal';
import { useParams } from 'react-router';

const ConfirmDeleteExemptionComponent = (props) => {
    const { t } = useTranslation();

    const { portfolioId, propertyId} = useParams();

    const handleCloseClick = () => {
        props.closeModalWindow();
    };

    const handleDeleteClick = () => {
        props.deleteRentExemptionCall(props.rentExemptionModel.rentExemptionId, props.contractId, propertyId, portfolioId);
    };

    return (
        <ConfirmDeleteModal
            onClose={handleCloseClick}
            onConfirm={handleDeleteClick}
            title={t('contract.finances.deleteExemption.title')} // { name: props.building.name }
            text={t('contract.finances.deleteExemption.sentence')}
            loading={props.rentExemptionLoading}
            deleteBtnText={t('contract.finances.deleteExemption.deleteBtnText')}
        />
    );
};

const mapState = ({ contract }) => {
    return {
        contractId: get(contract, 'contract.id'),
        rentExemptionModel: get(contract, 'rentExemptionModel'),
        rentExemptionLoading: get(contract, 'rentExemptionLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showRemoveRentExemptionModal', false));
        },
        deleteRentExemptionCall(id, contractId, propertyId, portfolioId) {
            dispatch(deleteRentExemption(id, contractId, propertyId, portfolioId));
        },
    };
};

const ConfirmDeleteExemption = connect(mapState, mapDispatch)(ConfirmDeleteExemptionComponent);
export default ConfirmDeleteExemption;
