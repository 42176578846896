import { Form } from 'antd';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import trash from '../../assets/images/trash-default.svg';
import ActionButton from '../../components/action-button';
import PositiveNumericInput from '../numeric-input';

const AdditionalCommonCostCard = (props) => {
    const { t } = useTranslation();

    const editAdditionalCommonCost = () => {
        props.editAdditionalCommonCost && props.editAdditionalCommonCost(props.cost);
    };

    const deleteAdditionalCommonCost = () => {
        props.deleteAdditionalCommonCost && props.deleteAdditionalCommonCost(props.cost);
    };

    const handleChange = (e) => {
        props.handleSaveAdditionalCost({
            ...props.cost,
            value: e,
        });
    };

    return (
        <div key={props.cost.uId}>
            <p className="mb-2 font-weight-bold">{props.cost.name}</p>
            <div className="d-flex flex-row">
                <Form.Item name={'budget_id_' + props.cost.uId} className={`w-100`}>
                    <PositiveNumericInput suffix={t('currency.norway')} onChange={(e) => handleChange(e)} />
                </Form.Item>
                <div className="d-flex align-items-center additional-action-buttons">
                    <ActionButton className="btn-thirdy btn-transparent pr-0" onClick={deleteAdditionalCommonCost} icon={<Image src={trash} className="m-0" />} />
                </div>
            </div>
        </div>
    );
};

export default AdditionalCommonCostCard;
