import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { getCommonCostCalculations } from '../../../../reducers/commonCostReducer';
import LoaderSpinner from '../../../../components/spinner';
import CommonCostVacancy from './vacancy';
import CommonCostTenantsList from './tenants-list';
import CommonCostDetailsRightSideComponents from '../right-side-components';
import ActualToggleComponent from '../analysis-tab/actual-toggle';

const CommonCostCalculation = () => {
    const { portfolioId, propertyId, commonCostId } = useParams();
    const dispatch = useDispatch();
    const getCommonCostCalculationsCall = (portfolioId, propertyId, commonCostId) => dispatch(getCommonCostCalculations(portfolioId, propertyId, commonCostId));
    const commonCostCalculationsLoading = useSelector(({ commonCost }) => commonCost.commonCostCalculationsLoading);

    useEffect(() => {
        getCommonCostCalculationsCall(portfolioId, propertyId, commonCostId);
    }, []);

    return (
        <div className="h-100">
            {commonCostCalculationsLoading ? (
                <LoaderSpinner />
            ) : (
                <Row>
                    <Col sm={12} lg={8}>
                        <ActualToggleComponent />
                        <CommonCostVacancy />
                        <CommonCostTenantsList />
                    </Col>
                    <CommonCostDetailsRightSideComponents />
                </Row>
            )}
        </div>
    );
};

export default CommonCostCalculation;
