import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import trash from '../../../assets/images/trash-default.svg';
import ActionButton from '../../../components/action-button';
import AdditionalCommonCostCard from '../../../components/common-cost/additional-common-cost-card';
import PositiveNumericInput from '../../../components/numeric-input';
import { setValueOwnerCost } from '../../../reducers/ownerCostReducer';
import AddAdditionalCostModal from '../modals/create-edit-additional-cost-modal';
import { commonCostActualItemsSelector, ownerCostBudgetItemsSelector, ownerCostSelector } from '../selectors';

const EditOwnerCostBudgetItems = ({ budgetRef }) => {
    const { t } = useTranslation();
    const ownerCost = useSelector(ownerCostSelector);
    const ownerCostBudgets = useSelector(ownerCostBudgetItemsSelector);
    const ownerCostActuals = useSelector(commonCostActualItemsSelector);
    const showAddAdditionalCostModal = useSelector(({ ownerCost }) => ownerCost.showAddAdditionalCostModal);

    const [predefinedItems, setPredefinedItems] = useState([]);
    const [additionalItems, setAdditionalItems] = useState([]);

    const dispatch = useDispatch();
    const setAdditionalCostEditModel = (cost) => {
        dispatch(setValueOwnerCost('additionalCostEditModel', cost));
    };
    const updateOwnerCostBudgets = (newList) => {
        dispatch(setValueOwnerCost('ownerCost.ownerCostBudgets', newList));
    };
    const updateOwnerCostActuals = (newList) => {
        dispatch(setValueOwnerCost('ownerCost.ownerCostActuals', newList));
    };
    const showAddAdditionalCostCall = () => {
        dispatch(setValueOwnerCost('showAddAdditionalCostModal', true));
    };

    useEffect(() => {
        const defaultItems = ownerCostBudgets.filter((x) => x.id);

        const formData = defaultItems.reduce((obj, item) => Object.assign(obj, { ['budget_id_' + item.id]: item.value }), {});
        budgetRef.current.setFieldsValue(formData);
    }, []);

    useEffect(() => {
        setPredefinedItems(ownerCostBudgets.filter((x) => x.id));
        setAdditionalItems(ownerCostBudgets.filter((x) => !x.id));
    }, [ownerCostBudgets]);

    const editAdditionalOwnerCost = (cost) => {
        setAdditionalCostEditModel(cost);
        showAddAdditionalCostCall();
    };

    const deleteOwnerCostItem = (cost) => {
        const currentList = [...ownerCostBudgets];
        const newList = cost.id ? currentList.filter((x) => x.id !== cost.id) : currentList.filter((x) => x.uId !== cost.uId);
        updateOwnerCostBudgets(newList);

        const currentListActuals = [...ownerCostActuals];
        const costIndex = currentListActuals.findIndex((x) => x.name === cost.name);
        if (costIndex >= 0 && (currentListActuals[costIndex]?.value === undefined ?? true)) {
            currentListActuals.splice(costIndex, 1);
            updateOwnerCostActuals(currentListActuals);
        }
    };

    // ToDo: use another modal with both fields (actual and budget inputs)
    const handleSaveAdditionalCost = (item) => {
        handleSaveBudgetAdditionalCost(item);

        if (ownerCostActuals?.length > 0) {
            handleSaveActualAdditionalCost({ ...item, isBudget: false });
        }
    };
    const handleSaveBudgetAdditionalCost = (item) => {
        const items = [...(ownerCostBudgets || [])];
        const existingItemIndex = items.findIndex((x) => (x.id > 0 && x.id === item.id) || x.uId === item.uId);

        if (existingItemIndex >= 0) {
            items[existingItemIndex] = item;
        } else {
            item.isBudget = true;
            items.push(item);
        }

        updateOwnerCostBudgets(items);
    };

    const handleSaveActualAdditionalCost = (item) => {
        const items = [...(ownerCostActuals || [])];
        const existingItemIndex = items.findIndex((x) => (x.id > 0 && x.id === item.id) || x.uId === item.uId);

        item.value = item.actualValue;

        if (existingItemIndex >= 0) {
            items[existingItemIndex] = item;
        } else {
            item.isBudget = false;
            items.push(item);
        }
        updateOwnerCostActuals(items);
    };

    return (
        <React.Fragment>
            {predefinedItems.length > 0 ? (
                <div className="page-container bottom-border">
                    <Row>
                        <Col lg={12} xl={6}>
                            {predefinedItems.map((cost) => (
                                <div key={cost.id}>
                                    <p className="mb-2 font-weight-bold">{cost.name}</p>
                                    <div className="d-flex flex-row">
                                        <Form.Item
                                            name={'budget_id_' + cost.id}
                                            className={`${predefinedItems[predefinedItems.length - 1].id === cost.id ? 'mb-0' : ''} w-100`}
                                            rules={[{ type: 'number', min: 0, message: `${cost.name} ${t('commonCost.budgetStep.cantBeLess')}` }]}>
                                            <PositiveNumericInput prefix={t('currency.norway')} />
                                        </Form.Item>
                                        <ActionButton
                                            className="btn-thirdy btn-transparent pr-0"
                                            onClick={() => deleteOwnerCostItem(cost)}
                                            icon={<Image src={trash} className="m-0" />}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </div>
            ) : null}
            <div className="page-container bottom-border">
                <p className="font-weight-bold">{t('commonCost.budgetStep.additionalCosts')}</p>
                <Row>
                    <Col lg={12} xl={6}>
                        {additionalItems.map((item) => (
                            <AdditionalCommonCostCard
                                key={item.name}
                                cost={item}
                                editAdditionalCommonCost={editAdditionalOwnerCost}
                                deleteAdditionalCommonCost={deleteOwnerCostItem}
                                handleSaveAdditionalCost={handleSaveAdditionalCost}
                            />
                        ))}
                    </Col>
                </Row>
                <ActionButton className="btn-secondary" onClick={showAddAdditionalCostCall} text={t('commonCost.budgetStep.additionalCosts.addBtn')} />
            </div>
            {showAddAdditionalCostModal && <AddAdditionalCostModal onSave={handleSaveAdditionalCost} />}
        </React.Fragment>
    );
};

export default EditOwnerCostBudgetItems;
