import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import ActionButton from '../../../components/action-button';
import PropertyRow from '../../../components/property-row';
import helper from '../../../helpers/helper';
import { userPermisionsTypesOptions } from '../../../common/constants';

const PortfolioReviewComponent = (props) => {
    const { t } = useTranslation();

    const goToStep = (stepKey) => {
        props.goToStep(stepKey);
    };

    return (
        <div className="page-container review">
            <div className="review-part details-info">
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                    <h5>{t('portfolio.review.portfolioName')}</h5>
                    <ActionButton onClick={() => goToStep('name')} className="btn-link" text={t('common.buttons.edit')} />
                </div>
                <PropertyRow title={t('portfolio.review.portfolioName')} value={props.portfolio.name} />
            </div>
            <div className="review-part details-info">
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                    <h5>{t('portfolio.review.portfolioDetails')}</h5>
                    <ActionButton onClick={() => goToStep('details')} className="btn-link" text={t('common.buttons.edit')} />
                </div>
                {props.portfolio.manager.orgName ? (
                    <React.Fragment>
                        <PropertyRow title={t('portfolio.manager.details.orgName.title')} value={props.portfolio.manager.orgName} />
                        <PropertyRow title={t('portfolio.manager.details.orgNumber.title')} value={helper.numberWithSpaces(props.portfolio.manager.orgNumber)} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <PropertyRow title={t('portfolio.manager.details.firstName.title')} value={props.portfolio.manager.firstName} />
                        <PropertyRow title={t('portfolio.manager.details.lastName.title')} value={props.portfolio.manager.lastName} />
                    </React.Fragment>
                )}
            </div>
            <div className="review-part details-info">
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                    <h5>{t('portfolio.review.portfolioManager')}</h5>
                    <ActionButton onClick={() => goToStep('details')} className="btn-link" text={t('common.buttons.edit')} />
                </div>
                {props.portfolio.manager.orgName && <PropertyRow title={t('portfolio.manager.contacts.name.title')} value={props.portfolio.manager.name} />}
                <PropertyRow title={t('portfolio.manager.contacts.address.title')} value={props.portfolio.manager.address} />
                <PropertyRow title={t('portfolio.manager.contacts.phone.title')} value={props.portfolio.manager.phone} />
                <PropertyRow title={t('portfolio.manager.contacts.email.title')} value={props.portfolio.manager.email} />
                <PropertyRow title={t('portfolio.manager.contacts.post.title')} value={props.portfolio.manager.post} />
                <PropertyRow title={t('portfolio.manager.contacts.country.title')} value={props.portfolio.manager.country} />
            </div>
            {props.portfolio.invites && props.portfolio.invites.length && !!props.portfolio.invites[0].email ? (
                <div className="review-part details-info">
                    <div className="d-flex flex-row justify-content-between align-items-baseline">
                        <h5>{t('portfolio.review.invited')}</h5>
                        <ActionButton onClick={() => goToStep('invites')} className="btn-link" text={t('common.buttons.edit')} />
                    </div>
                    {props.portfolio.invites.map((invite) => (
                        <div className="invitation" key={invite.email}>
                            <div className="d-flex flex-column" style={{ width: '33%' }}>
                                <PropertyRow title={t('portfolio.manager.contacts.email.title')} value={invite.email} />
                            </div>
                            <div className="d-flex flex-column" style={{ width: '33%' }}>
                                <PropertyRow title={t('portfolio.manager.contacts.name.title')} value={invite.name} />
                            </div>
                            <div className="d-flex flex-column" style={{ width: '33%' }}>
                                <PropertyRow title={t('portfolio.manager.contacts.name.permission')} value={helper.getEnumValue(userPermisionsTypesOptions, invite?.permissionId)} />
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

const mapState = ({ portfolio }) => {
    return {
        portfolio: get(portfolio, 'portfolio'),
    };
};

const PortfolioReview = connect(mapState, null)(PortfolioReviewComponent);
export default PortfolioReview;
