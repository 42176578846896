import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { defaultPlot, getPlotAddresses, getPlotInfo, setValuePlot } from '../../../reducers/plotReducer';
import { requiredCommonInput } from '../../../common/validators';
import get from 'lodash-es/get';
import axios from 'axios';
import CommonInput from '../../../components/common-input';
import Municipality from '../../../components/plot-components/municipality';
import Addresses from '../../../components/plot-components/addresses';
import MainAddress from '../../../components/plot-components/main-address';

const DetailsStepComponent = (props) => {
    const { t } = useTranslation();
    const [searchMainAddress, setSearchMainAddress] = useState();
    const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());

    useEffect(() => {
        props.plotRef.current.setFieldsValue({
            name: props.plot.name,
            mainAddress: props.plot.mainAddress,
            municipality: props.plot.municipality > 0 ? props.plot.municipality + '/' + props.plot.city : '',
        });
    }, []);

    useEffect(() => {
        if (props.plot.municipality && props.plot.gnrNumber && props.plot.bnrNumber) {
            props.getPlotInfoCall(props.plot.gnrNumber, props.plot.municipality, props.plot.bnrNumber, getAddresses, cancelToken);
        }
    }, [props.plot.municipality, props.plot.gnrNumber, props.plot.bnrNumber]);

    const callCancelToken = () => {
        cancelToken.cancel();
        setCancelToken(axios.CancelToken.source());
    };

    const onChangeMunicipality = (value) => {
        if (typeof value === 'object') {
            props.setValuePlotCall('plot.city', value.name);
            props.setValuePlotCall('plot.municipality', value.number);
        } else if ((value === '' && props.plot.gnrNumber !== null) || value === undefined) {
            clearFields();
            props.setValuePlotCall('plot.city', '');
            props.setValuePlotCall('plot.municipality', 0);
            props.plotRef.current.setFieldsValue({ municipality: '' });
            props.plotRef.current.validateFields(['municipality']);
        }
    };

    const onSelectMainAddress = (value) => {
        if (typeof value === 'object') {
            setSearchMainAddress(null);
            const plot = { ...props.plot };
            const address = value.address
                ? `${value.address}, ${value.gnr}/${value.bnr}`
                : value.gnr && value.bnr
                ? `${value.municipalityName} ${value.municipalityNumber}-${value.gnr}/${value.bnr}`
                : '';
            props.plotRef.current.setFieldsValue({ mainAddress: address });
            if (!props.plotRef.current.getFieldValue('name') && props.plot.plotHasName) {
                props.plotRef.current.setFieldsValue({ name: address });
                plot.name = address;
            }
            plot.gnrNumber = value.gnr;
            plot.bnrNumber = value.bnr;
            plot.mainAddress = address;
            plot.address = value.address;
            props.setValuePlotCall('plot', plot);
            props.plotRef.current.resetFields(['addresses']);
            props.setValuePlotCall('plot.addresses', []);
        }
    };

    const onChangeMainAddress = (value) => {
        value = value ? value.trim() : '';
        if (props.plot.municipality !== 0 && value) {
            setSearchMainAddress(value);
        }
        props.setValuePlotCall('hasPlotDuplicate', false);
        if (!value) {
            clearFields();
        }
    };

    const onSearchMainAddress = (value) => {
        if (!value) {
            clearFields();
        }
    };

    const clearFields = () => {
        const plot = { ...defaultPlot, city: props.plot.city, municipality: props.plot.municipality, id: props.plot.id };
        setSearchMainAddress(null);
        props.setValuePlotCall('plot', plot);
        props.setValuePlotCall('addresses', []);
        props.setValuePlotCall('plotAddresses', null);
        props.setValuePlotCall('hasPlotDuplicate', false);
        props.plotRef.current.setFieldsValue({ mainAddress: '', addresses: '' });
        props.plotRef.current.validateFields(['mainAddress']);
        callCancelToken();
    };

    const getAddresses = (cadastreId) => {
        cadastreId && props.getPlotAddressesCall(cadastreId, cancelToken);
    };

    const onChangeHasName = (e) => {
        if (!e.target.checked) {
            props.plotRef.current.resetFields(['name']);
            props.setValuePlotCall('plot.name', '');
        }
        props.setValuePlotCall('plot.plotHasName', e.target.checked);
    };

    const onChangeHasSecondaryAddresses = (value) => {
        if (!value) {
            props.plotRef.current.resetFields(['addresses']);
            props.setValuePlotCall('plot.addresses', []);
        }
        props.setValuePlotCall('plot.hasSecondaryAddresses', !props.plot.hasSecondaryAddresses);
    };

    const validateMunicipalityOnChangeAddress = (value) => {
        if (props.plot.municipality === 0 && value) {
            return Promise.reject(new Error(t('plot.notSelectedMunicipality')));
        }
        return Promise.resolve();
    };

    return (
        <div className="page-container object">
            <Row>
                <Col md={12} lg={6}>
                    {!props.plot.id && <h5>{t('plot.details.title')}</h5>}
                    <Checkbox checked={props.plot.plotHasName} className="ml-1 mb-3" onChange={onChangeHasName}>
                        {t('plot.details.checkboxTitle')}
                    </Checkbox>
                    {props.plot.plotHasName && (
                        <Form.Item name="name" rules={[requiredCommonInput(t('plot.details.name.title') + t('common.isRequired'))]}>
                            <CommonInput autofocus={true} value={props.plot.name} title={t('plot.details.name.title')} placeholder={t('plot.details.name.placeholder')} />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="municipality"
                        rules={[
                            {
                                required: true,
                                message: t('plot.details.municipality.title') + t('common.isRequired'),
                            },
                        ]}>
                        <Municipality title={t('plot.details.municipality.title')} placeholder={t('plot.details.municipality.placeholder')} onChange={onChangeMunicipality} />
                    </Form.Item>
                    <Form.Item
                        name="mainAddress"
                        rules={[
                            requiredCommonInput(t('plot.details.mainAddress.title') + t('common.isRequired')),
                            {
                                validator(_, value) {
                                    return validateMunicipalityOnChangeAddress(value);
                                },
                            },
                        ]}>
                        <MainAddress
                            searchMainAddress={searchMainAddress}
                            title={t('plot.details.mainAddress.title')}
                            placeholder={t('plot.details.mainAddress.placeholder')}
                            onSelect={onSelectMainAddress}
                            onChange={onChangeMainAddress}
                            onSearch={onSearchMainAddress}
                        />
                    </Form.Item>
                    {props.hasPlotDuplicate && <p className="plot-duplication-message">{t('plot.haveTheSamePlot')}</p>}
                </Col>
            </Row>
            <Checkbox
                checked={props.plot.hasSecondaryAddresses || props.plot.addresses.length > 0}
                disabled={!props.plot.address}
                className="ml-1 mb-3"
                onChange={(e) => onChangeHasSecondaryAddresses(e.target.checked)}>
                {t('plot.details.addressesCheckboxTitle')}
            </Checkbox>
            {props.plot.hasSecondaryAddresses || props.plot.addresses.length > 0 ? (
                <Form.Item name="addresses">
                    <Addresses
                        title={t('plot.details.addresses.title')}
                        description={t('plot.details.addresses.description')}
                        placeholder={t('plot.details.addresses.placeholder')}
                    />
                </Form.Item>
            ) : null}
        </div>
    );
};

const mapState = ({ plot }) => {
    return {
        plot: get(plot, 'plot'),
        plotAddresses: get(plot, 'plotAddresses'),
        hasPlotAddresses: get(plot, 'plotAddresses.length') > 0,
        hasPlotDuplicate: get(plot, 'hasPlotDuplicate'),
        plotInfo: get(plot, 'plotInfo'),
        name: get(plot, 'plot.name'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValuePlotCall(key, value) {
            dispatch(setValuePlot(key, value));
        },
        getPlotInfoCall(gnrNumber, municipality, bnrNumber, callback, cancelToken) {
            dispatch(getPlotInfo(gnrNumber, municipality, bnrNumber, callback, cancelToken));
        },
        getPlotAddressesCall(cadastreId, cancelToken) {
            return dispatch(getPlotAddresses(cadastreId, cancelToken));
        },
    };
};

const DetailsStep = connect(mapState, mapDispatch)(DetailsStepComponent);
export default DetailsStep;
