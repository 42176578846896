import i18next from 'i18next';
import { endsOptions, eventPeriodes, eventStatuses, repeatOption } from '../common/event-options';
import eventsService from '../services/eventsService';
import update from '../helpers/update';
import toaster from '../common/toaster';
import cloneDeep from 'lodash-es/cloneDeep';

const SET_VALUE_EVENT = 'SET_VALUE_EVENT';
const SUCCESS_GET_RELATED_DATA = 'SUCCESS_GET_RELATED_DATA';
const SUCCESS_GET_EVENTS = 'SUCCESS_GET_EVENTS';
const ON_CHANGE_SELECTED_REPEAT_ON_DAY = 'ON_CHANGE_SELECTED_REPEAT_ON_DAY';

export const repeatWeekDays = [
    { id: 'M', name: 'MON', isSelected: false },
    { id: 'T', name: 'TUE', isSelected: false },
    { id: 'W', name: 'WED', isSelected: false },
    { id: 'T', name: 'THU', isSelected: false },
    { id: 'F', name: 'FRI', isSelected: false },
    { id: 'S', name: 'SAT', isSelected: false },
    { id: 'S', name: 'SUN', isSelected: false },
];

export const defaultReminder = {
    repeatCount: null,
    repeatOption: repeatOption.doesNotRepeat,
    repeatWeekDays: repeatWeekDays,
    shouldNotifyByEmail: false,
    repeatOnOption: null,
    endsOption: endsOptions.date,
    occuranceEndingDate: null,
    occurancesCount: null,
    reminderDateUtc: null,
};

export const defaultEvent = {
    hasReminder: false,
    assignedUserIds: [],
    eventReminder: defaultReminder,
};

const initialState = {
    event: defaultEvent,
    events: [],

    showEventModalWindow: false,
    showReminderModalWindow: false,
    showEventFilterModalWindow: false,
    showConfirmDeleteEventModalWindow: false,

    eventStatus: eventStatuses.active,
    eventPeriod: eventPeriodes.current,
    getAssignedToMeEvents: false,

    getLoading: false,
    eventLoading: false,
    deleteLoading: false,
    getEventsLoading: false,
    getEventLoading: false,
    getRelatedDataLoading: false,
    completeLoading: false,

    disableLoadPastEventsBtn: false,
    disableLoadFutureEventsBtn: false,

    contracts: [],
    properties: [],
    portfolioUsers: [],

    eventEditReminderRepeatOption: repeatOption.doesNotRepeat,
};

const eventsReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_EVENT:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case SUCCESS_GET_RELATED_DATA:
            newState = update.set(newState, 'contracts', action.payload.contracts);
            newState = update.set(newState, 'properties', action.payload.properties);
            newState = update.set(newState, 'portfolioUsers', action.payload.portfolioUsers);
            break;
        case ON_CHANGE_SELECTED_REPEAT_ON_DAY:
            newState.event.eventReminder.repeatWeekDays = update(newState.event.eventReminder.repeatWeekDays, {
                [action.payload.id]: {
                    isSelected: { $set: action.payload.value },
                },
            });
            break;
        case SUCCESS_GET_EVENTS:
            newState = update.set(newState, 'events', action.payload);
            break;
        default:
            break;
    }

    return newState;
};

export const setValueEvent = (key, value) => {
    return {
        type: SET_VALUE_EVENT,
        payload: {
            key,
            value,
        },
    };
};

export const successGetRelatedData = (value) => {
    return {
        type: SUCCESS_GET_RELATED_DATA,
        payload: value,
    };
};

export const successGetEvents = (value) => {
    return {
        type: SUCCESS_GET_EVENTS,
        payload: value,
    };
};

export const onChangeSelectedRepeatOnDay = (id, value) => {
    return {
        type: ON_CHANGE_SELECTED_REPEAT_ON_DAY,
        payload: {
            id: id,
            value: value,
        },
    };
};

const handleEventError = (error, dispatch) => {
    if (error && error.response && error.response.title) {
        toaster.error(error.response.title, null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const createEvent = (data, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueEvent('eventLoading', true));
        return eventsService
            .create(data, portfolioId)
            .then(() => {
                return true;
            })
            .catch((error) => handleEventError(error, dispatch))
            .finally(() => {
                dispatch(setValueEvent('eventLoading', false));
            });
    };
};

export const editEvent = (data, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueEvent('eventLoading', true));
        return eventsService
            .edit(data, portfolioId)
            .then(() => {
                return true;
            })
            .catch((error) => handleEventError(error, dispatch))
            .finally(() => {
                dispatch(setValueEvent('eventLoading', false));
            });
    };
};

export const deleteEvent = (eventId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueEvent('deleteLoading', true));
        return eventsService
            .delete(eventId, portfolioId)
            .then(() => {
                return true;
            })
            .catch((error) => handleEventError(error, dispatch))
            .finally(() => {
                dispatch(setValueEvent('deleteLoading', false));
            });
    };
};

const eventMachining = (event) => {
    if (event.eventReminder) {
        if (event.eventReminder.occuranceEndingDate) {
            event.eventReminder.endsOption = endsOptions.date;
        } else if (event.eventReminder.occurancesCount) {
            event.eventReminder.endsOption = endsOptions.count;
        }
        if (event.eventReminder.selectedWeekDays) {
            const listOfDays = event.eventReminder.selectedWeekDays.split(',');
            let eventRepeatWeekDays = cloneDeep(repeatWeekDays);
            listOfDays.forEach((element) => {
                const selectedDay = eventRepeatWeekDays.find((x) => x.name === element);
                if (selectedDay) {
                    selectedDay.isSelected = true;
                }
            });
            event.eventReminder.repeatWeekDays = eventRepeatWeekDays;
        } else {
            event.eventReminder.repeatWeekDays = repeatWeekDays;
        }
    } else {
        event.eventReminder = defaultReminder;
    }

    event.assignedUserIds = event.assignedUsers.map((x) => x.userId);
    return event;
};

export const getEventForEditByIdAndGetRelatedData = (portfolioId, propertyId, id) => {
    return (dispatch) => {
        dispatch(setValueEvent('getEventLoading', true));
        return eventsService
            .getEventForEditByIdAndGetRelatedData(portfolioId, propertyId, id)
            .then((response) => {
                const event = eventMachining(response[0].data);
                dispatch(setValueEvent('event', event));
                dispatch(successGetRelatedData(response[1].data));
                return true;
            })
            .catch((error) => handleEventError(error, dispatch))
            .finally(() => {
                dispatch(setValueEvent('getEventLoading', false));
            });
    };
};

export const getPortfolioEvents = (portfolioId, filter) => {
    return (dispatch) => {
        dispatch(setValueEvent('getEventsLoading', true));
        return eventsService
            .getPortfolioEvents(portfolioId, filter)
            .then((response) => {
                return response.data.items;
            })
            .catch((error) => handleEventError(error, dispatch))
            .finally(() => {
                dispatch(setValueEvent('getEventsLoading', false));
            });
    };
};

export const getPropertyEvents = (portfolioId, propertyId, filter) => {
    return (dispatch) => {
        dispatch(setValueEvent('getEventsLoading', true));
        return eventsService
            .getPropertyEvents(portfolioId, propertyId, filter)
            .then((response) => {
                return response.data.items;
            })
            .catch((error) => handleEventError(error, dispatch))
            .finally(() => {
                dispatch(setValueEvent('getEventsLoading', false));
            });
    };
};

export const getContractEvents = (portfolioId, propertyId, contractId, filter) => {
    return (dispatch) => {
        dispatch(setValueEvent('getEventsLoading', true));
        return eventsService
            .getContractEvents(portfolioId, propertyId, contractId, filter)
            .then((response) => {
                return response.data.items;
            })
            .catch((error) => handleEventError(error, dispatch))
            .finally(() => {
                dispatch(setValueEvent('getEventsLoading', false));
            });
    };
};

export const getEventRelatedData = (portfolioId, propertyId, contractId) => {
    return (dispatch) => {
        dispatch(setValueEvent('getRelatedDataLoading', true));
        return eventsService
            .getEventRelatedData(portfolioId, propertyId, contractId)
            .then((response) => {
                dispatch(successGetRelatedData(response.data));
                dispatch(setValueEvent('event.assignedUserIds', [response.data.portfolioUsers.find((x) => x.isPortfolioOwner).userId]));
                return true;
            })
            .catch((error) => handleEventError(error, dispatch))
            .finally(() => {
                dispatch(setValueEvent('getRelatedDataLoading', false));
            });
    };
};

export const setAsCompleted = (portfolioId, eventTaskId) => {
    return (dispatch, getState) => {
        dispatch(setValueEvent('completeLoading', true));
        return eventsService
            .setAsCompleted(portfolioId, eventTaskId)
            .then((_) => {
                const events = getState().events.events;
                const event = events.find((x) => x.id === eventTaskId);
                const eventId = events.findIndex((x) => x.id === eventTaskId);
                if (event && eventId > -1) {
                    event.isCompleted = true;
                    events[eventId] = event;
                }
                return event;
            })
            .catch((error) => handleEventError(error, dispatch))
            .finally(() => {
                dispatch(setValueEvent('completeLoading', false));
            });
    };
};

export const setAsNotCompleted = (portfolioId, eventTaskId) => {
    return (dispatch, getState) => {
        dispatch(setValueEvent('completeLoading', true));
        return eventsService
            .setAsNotCompleted(portfolioId, eventTaskId)
            .then((_) => {
                const events = getState().events.events;
                const event = events.find((x) => x.id === eventTaskId);
                const eventId = events.findIndex((x) => x.id === eventTaskId);
                if (event && eventId > -1) {
                    event.isCompleted = false;
                    events[eventId] = event;
                }
                return event;
            })
            .catch((error) => handleEventError(error, dispatch))
            .finally(() => {
                dispatch(setValueEvent('completeLoading', false));
            });
    };
};

export default eventsReducer;
