import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import helper from '../../helpers/helper';

const CurrencyFormat = (props) => {

    const format = (num) => {
        var number;
        try {
            number = Number(num);
        } catch (err) {
            return 0;
        }

        if (!number) {
            return 0;
        }
        const result = number % 1 !== 0 ? number.toFixed(2) : number;
        return helper.numberWithSpaces(result);
    };

    return (
        <div style={props.style}>{`${!props.isHyphen || props.isHyphen == undefined ? i18next.t('currency.norway') : ''} ${format(props.value)}${props.isHyphen ? '.-' : ''}`}</div>
    );
};

export default CurrencyFormat;
