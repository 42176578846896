import { Col, Row, Switch, Form, Radio } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { minimumRentTypes, minimumRentTypeOptions } from '../../../../common/contract-options';
import PositiveNumericInput from '../../../../components/numeric-input';

const ThesholdAmount = (props) => {
    const { t } = useTranslation();
    const { isThesholdAmountOn, setIsThesholdAmountOn, maximumRent } = props;

    return (
        <div className={`theshold-amount ${isThesholdAmountOn ? 'theshold-amount-full' : ''}`}>
            <Row align="middle">
                <Col span={2}>
                    <Form.Item>
                        <Switch checked={isThesholdAmountOn} onChange={(cheked) => setIsThesholdAmountOn(cheked)} />
                    </Form.Item>
                </Col>
                <Col>
                    <p className="font-weight-bold">{t('contract.turnover.thesholdAmount.title')}</p>
                </Col>
            </Row>
            <Row align="middle" className="minimum-rent-yearly-body mt-4">
                <Col span={10}>
                    <h6>{t('contract.turnover.thesholdAmount.maxAmount')}</h6>
                    <Form.Item
                        name="thesholdAmountValue"
                        initialValue={maximumRent?.annuallyPerUnit ? maximumRent?.annuallyPerUnit : maximumRent?.thesholdAmountValue}
                        rules={[
                            {
                                required: true,
                                message: t('contract.turnover.thesholdAmount.maxAmount') + t('common.isRequired'),
                            },
                        ]}>
                        <PositiveNumericInput prefix={t('currency.norway')} suffix="NOK"/>
                    </Form.Item>
                    <p>{t('contract.turnover.thesholdAmount.cpiAdjusted')}</p>
                </Col>
                <Col span={8} offset={2}>
                    <Form.Item
                        name="thesholdAmountType"
                        initialValue={maximumRent ? (maximumRent?.annuallyPerUnit ? minimumRentTypes.annuallyPerUnit : minimumRentTypes.totalAnnualAmount) : minimumRentTypes.annuallyPerUnit}
                        rules={[
                            {
                                required: true,
                                message: t('contract.turnover.minimumYearly.chooseOption'),
                            },
                        ]}>
                        <Radio.Group>
                            <Radio value={minimumRentTypes.annuallyPerUnit}>{minimumRentTypeOptions.find((item) => item.id == minimumRentTypes.annuallyPerUnit).name()}</Radio>
                            <Radio value={minimumRentTypes.totalAnnualAmount}>{minimumRentTypeOptions.find((item) => item.id == minimumRentTypes.totalAnnualAmount).name()}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default ThesholdAmount;
