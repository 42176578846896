import axios from 'axios';

const buildingService = {
    getBuildingGrossArea: (floors, basementFloors) => {
        const grossArea = floors.map((x) => x.grossArea || 0).reduce((a, b) => a + b, 0) + basementFloors.map((x) => x.grossArea || 0).reduce((a, b) => a + b, 0);
        return grossArea != 0 ? grossArea : null;
    },

    getBuildingNettoArea: (floors, basementFloors) => {
        return floors.map((x) => x.nettoArea || 0).reduce((a, b) => a + b, 0) + basementFloors.map((x) => x.nettoArea || 0).reduce((a, b) => a + b, 0);
    },

    getBuildingCommonArea: (floors, basementFloors) => {
        const commonArea = floors.map((x) => x.commonArea || 0).reduce((a, b) => a + b, 0) + basementFloors.map((x) => x.commonArea || 0).reduce((a, b) => a + b, null);
        return commonArea != 0 ? commonArea : null;
    },

    getBuildingNonLettableArea: (floors, basementFloors) => {
        const nonLettableArea = floors.map((x) => x.nonLettable || 0).reduce((a, b) => a + b, 0) + basementFloors.map((x) => x.nonLettable || 0).reduce((a, b) => a + b, 0);
        return nonLettableArea != 0 ? nonLettableArea : null;
    },

    getBuildingLettableArea: (floors, basementFloors) => {
        const leattableArea = floors.map((x) => x.lettable || 0).reduce((a, b) => a + b, 0) + basementFloors.map((x) => x.lettable || 0).reduce((a, b) => a + b, 0);
        return leattableArea != 0 ? leattableArea : null;
    },

    create: (data, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/buildings?portfolioId=${portfolioId}&propertyId=${data.propertyId}`,
        });
    },

    createBuildings: (data, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/buildings/create-buildings?propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    edit: (data, portfolioId) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/buildings?portfolioId=${portfolioId}&propertyId=${data.propertyId}`,
        });
    },

    delete: (id, propertyId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/buildings/${id}?portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    uploadFile: (file, options, propertyId, buildingId, portfolioId) => {
        buildingId = buildingId || '';
        const data = new FormData();
        data.append('file', file);

        return axios.post(`/api/contract/buildings/upload-building-plan?portfolioId=${portfolioId}&propertyId=${propertyId}&buildingId=${buildingId}`, data, options);
    },

    deleteFile: (fileId, propertyId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/buildings/delete-document/${fileId}?portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    getBuildingPlanDownloadLink: (documentId, propertyId, portfolioId) => {
        return axios({
            method: 'post',
            url: `/api/contract/buildings/get-document-link/${documentId}?portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    downloadBuildingPlan: (documentId, portfolioId, propertyId) => {
        return axios({
            method: 'get',
            responseType: 'blob',
            url: `/api/contract/buildings/download-building-plan/${documentId}?&portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },

    getBuildingById: (portfolioId, buildingId, propertyId) => {
        return axios({
            method: 'get',
            url: `/api/contract/buildings/${buildingId}?portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },
};

export default buildingService;
