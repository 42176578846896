import { Checkbox, DatePicker, Form, Select } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';
import { deadlineTypes, notificationTypes, warrantyTypes } from '../../../../common/constants';
import ContentBlock, { actionButtonTypes } from '../../../../components/content-block';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import PositiveNumericInput from '../../../../components/numeric-input';
import Uploader from '../../../../components/uploader';
import { createWarrantyDraft, deleteWarranty, deleteWarrantyDocument, updateWarranty, uploadWarrantyDocument } from '../../../../reducers/contractReducer';

const WarrantyFormComponent = (props) => {
    const { t } = useTranslation();

    const { portfolioId, propertyId } = useParams();
    const [warrantyForm] = Form.useForm();

    const [collapsed, setCollapsed] = useState(true);

    const done = async () => {
        await warrantyForm.validateFields();
        const warranty = warrantyForm.getFieldsValue();

        warranty.isDraft = false;
        warranty.id = props.warranty.id;
        warranty.rentObjectId = props.warranty.rentObjectId || undefined;

        warranty.notification = {
            portfolioId: props.portfolioId,
            propertyId: props.contract.propertyId,
            contractId: props.contract.id,
            notificationTypeId: notificationTypes.ContractWarranty,
            reminderDate: warranty.warrantyExpiryDate,
            hasReminder: true,
            remindByEmail: true,
            assignedUserIds: [props.userId],
            reminders: [
                {
                    reminderPeriodTypeId: warranty.warrantyDeadlineType,
                    timeBeforeExpirity: warranty.warrantyDeadline,
                },
            ],
        };

        props.updateWarrantyCall(warranty, props.contract.id, portfolioId, propertyId);
    };

    const deleteWarranty = () => {
        props.deleteWarrantyCall(props.warranty.id, props.contract.id, portfolioId, propertyId);
        warrantyForm.resetFields();
        setCollapsed(true);
    };

    useEffect(() => {
        if (props.warranty) {
            let warranty = { ...props.warranty };

            warranty.warrantyExpiryDate = warranty.warrantyExpiryDate ? moment(warranty.warrantyExpiryDate) : moment();
            warranty.warrantyHandoverDate = warranty.warrantyHandoverDate ? moment(warranty.warrantyHandoverDate) : moment();

            if (warranty.notification?.reminders?.length) {
                warranty.warrantyDeadlineType = warranty.notification.reminders[0].reminderPeriodTypeId;
                warranty.warrantyDeadline = warranty.notification.reminders[0].timeBeforeExpirity;
            }

            warrantyForm.setFieldsValue(warranty);
        }
    }, []);

    const uploadFileFunc = (file, options) => {
        return props.uploadFileCall(file, options, props.warranty.id, props.contract.id, portfolioId, propertyId);
    };

    const deleteFileFunc = (fileId) => {
        return props.deleteFileCall(fileId, props.warranty.id, props.contract.id, portfolioId, propertyId);
    };

    const handleDownload = (e, file) => {
        e.preventDefault();
    };

    return (
        <Form form={warrantyForm}>
            <ContentBlock
                collapsed={collapsed}
                onCollapse={() => {
                    setCollapsed(!collapsed);

                    if (!props.warranty) {
                        props.createWarrantyDraftCall(
                            {
                                rentObjectId: props.rentObject?.id,
                                hasEventOnHandoverDueDate: false,
                                hasCpiRegulateWarrantyValue: false,
                                hasAnnualTenantReminderAdjustmentCpiRegulation: false,
                            },
                            props.contract.id,
                            portfolioId,
                            propertyId
                        );
                    }
                }}
                loading={props.loading}
                actions={[
                    {
                        type: actionButtonTypes.secondary,
                        label: props.warranty?.isDraft ? t('common.buttons.cancel') : t('common.buttons.delete'),
                        onClick: deleteWarranty,
                        disabled: warrantyForm && warrantyForm.getFieldsError().filter(({ errors }) => errors.length).length,
                    },
                    {
                        type: actionButtonTypes.primary,
                        label: props.warranty?.isDraft ? t('common.buttons.add') : t('common.buttons.saveChanges'),
                        onClick: () => {
                            done();
                        },
                        disabled: warrantyForm && warrantyForm.getFieldsError().filter(({ errors }) => errors.length).length,
                    },
                ]}
                name={props.rentObject?.displayName ? props.rentObject.displayName : t('contract.warranty.title')}>
                <Row className="warranty-card">
                    <Col>
                        <Form.Item valuePropName="checked" name="hasEventOnHandoverDueDate">
                            <Checkbox>{t('contract.warranty.activeEvent')}</Checkbox>
                        </Form.Item>
                        <Form.Item valuePropName="checked" name="hasCpiRegulateWarrantyValue">
                            <Checkbox disabled={props.rentObject ? !props.rentObject?.hasOverridedRentAdjustmentOptions : !props.contract?.hasRentAdjustmentOptions}>{t('contract.warranty.cpiRegulate')}</Checkbox>
                        </Form.Item>
                        <Form.Item valuePropName="checked" name="hasAnnualTenantReminderAdjustmentCpiRegulation">
                            <Checkbox>{t('contract.warranty.annualTenant')}</Checkbox>
                        </Form.Item>
                        <p className="font-weight-bold">{t('contract.warranty.warrantyType.title')}</p>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.warranty.warrantyType.title') + t('common.isRequired'),
                                },
                            ]}
                            name="warrantyTypeId">
                            <Select placeholder={t('contract.warranty.warrantyType.placeholder')}>
                                {warrantyTypes.map((item) => {
                                    return (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name()}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <p className="font-weight-bold">{t('contract.warranty.warrantyValue.title')}</p>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.warranty.warrantyValue.title') + t('common.isRequired'),
                                },
                            ]}
                            name="warrantyValue">
                            <PositiveNumericInput placeholder={t('contract.warranty.warrantyValue.placeholder')} />
                        </Form.Item>
                        <p className="font-weight-bold mb-0">{t('contract.warranty.warrantyHandoverDate.title')}</p>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.warranty.warrantyHandoverDate.title') + t('common.isRequired'),
                                },
                            ]}
                            name="warrantyHandoverDate">
                            <DatePicker suffixIcon={<Image src={calendarIcon} />} allowClear={false} dropdownClassName={'new-design-date-picker'} format={primaryDateFormat} />
                        </Form.Item>
                        <p className="font-weight-bold mb-0">{t('contract.warranty.warrantyExpiryDate.title')}</p>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.warranty.warrantyExpiryDate.title') + t('common.isRequired'),
                                },
                            ]}
                            name="warrantyExpiryDate">
                            <DatePicker suffixIcon={<Image src={calendarIcon} />} allowClear={false} dropdownClassName={'new-design-date-picker'} format={primaryDateFormat} />
                        </Form.Item>
                        <p className="font-weight-bold mb-0">{t('contract.warranty.warrantyDeadline.title')}</p>
                        <div className="pre-select-input-wrapper">
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: t('contract.warranty.warrantyDeadline') + t('common.isRequired'),
                                    },
                                ]}
                                className="pre-input"
                                name="warrantyDeadline">
                                <PositiveNumericInput placeholder={t('contract.warranty.warrantyDeadline.placeholder ')} onKeyDownCapture={e => e.key == '.' && e.preventDefault()} />
                            </Form.Item>
                            <Form.Item initialValue={deadlineTypes[0].id} className="pre-select" name="warrantyDeadlineType">
                                <Select dropdownClassName="new-design-dropdown">
                                    {deadlineTypes.map((item, index) => {
                                        return (
                                            <Select.Option value={item.id} key={item.id}>
                                                {item.name()}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <Uploader
                            files={props.warranty?.documents || []}
                            downloadFile={handleDownload}
                            uploadFile={uploadFileFunc}
                            deleteFile={deleteFileFunc}
                            title={t('contract.warranty.uploadWarranty')}
                            isNew={true}
                        />
                    </Col>
                </Row>
            </ContentBlock>
        </Form>
    );
};

const mapState = ({ contract, property, common }) => {
    return {
        portfolioId: lodash.get(property, 'property.portfolioId'),
        contract: lodash.get(contract, 'contract'),
        warranties: lodash.get(contract, 'warranties'),
        hasRentObjects: lodash.get(contract, 'rentObjects.length') > 0,
        loading: lodash.get(contract, 'warrantiesLoading'),
        userId: lodash.get(common, 'user.id'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        createWarrantyDraftCall(warranty, contractId, portfolioId, propertyId) {
            dispatch(createWarrantyDraft(warranty, contractId, portfolioId, propertyId));
        },
        updateWarrantyCall(warranty, contractId, portfolioId, propertyId) {
            dispatch(updateWarranty(warranty, contractId, portfolioId, propertyId));
        },
        deleteWarrantyCall(warrantyId, contractId, portfolioId, propertyId) {
            dispatch(deleteWarranty(warrantyId, contractId, portfolioId, propertyId));
        },
        uploadFileCall(file, options, warrantyId, contractId, portfolioId, propertyId) {
            return dispatch(uploadWarrantyDocument(file, options, warrantyId, contractId, portfolioId, propertyId));
        },
        deleteFileCall(documentId, warrantyId, contractId, portfolioId, propertyId) {
            return dispatch(deleteWarrantyDocument(documentId, warrantyId, contractId, portfolioId, propertyId));
        },
    };
};

const WarrantyForm = connect(mapState, mapDispatch)(WarrantyFormComponent);
export default WarrantyForm;
