import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Form, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { requiredCommonInput } from '../../../../../common/validators';
import ActionButton from '../../../../../components/action-button';
import CommonInput from '../../../../../components/common-input';
import CustomContractDatePicker from '../../../../../components/custom-contract-date-picker';
import { primaryDateFormat } from '../../../../../components/dateFormatter/dateFormats';
import objectHelper from '../../../../../helpers/trim-object';
import { addContractOption, setValueContract, updateContractOption } from '../../../../../reducers/contractReducer';
import { Image } from 'react-bootstrap';
import calendarIcon from '../../../../../assets/images/calendar-icon.svg';
import { useParams } from 'react-router';

const ContractOptionComponent = (props) => {
    const { t } = useTranslation();
    const [hasReminder, setHasReminder] = useState((props.contractOptionModel && props.contractOptionModel.reminderDate) || false);
    const [remindByEmail, setRemindByEmail] = useState(props.contractOptionModel ? props.contractOptionModel.remindByEmail : false);

    const { portfolioId, propertyId} = useParams();

    const contractOptionRef = React.useRef();

    const confirm = () => {
        contractOptionRef.current.validateFields().then(() => {
            const values = contractOptionRef.current.getFieldsValue();
            const contractOptionObject = objectHelper.trimObjectProperties(values);
            if (!hasReminder) {
                contractOptionObject.reminderDate = null;
            } else {
                contractOptionObject.reminderDate = contractOptionObject.reminderDate.startOf('day').utc(true);
            }
            contractOptionObject.date = contractOptionObject.date.startOf('day').utc(true);
            contractOptionObject.remindByEmail = remindByEmail;
            if (props.contractOptionModel) {
                contractOptionObject.id = props.contractOptionModel.id;
                props.updateContractOptionCall(contractOptionObject, props.contract.id, propertyId, portfolioId).then(() => {
                    onClose();
                });
            } else {
                props.addContractOptionCall(contractOptionObject, props.contract.id, propertyId, portfolioId).then(() => {
                    onClose();
                });
            }
        });
    };

    const onClose = () => {
        props.closeModalWindow();
    };

    const onChangeHasReminder = () => {
        contractOptionRef.current.setFieldsValue({ reminderDate: null });
        if (!hasReminder) {
            contractOptionRef.current.validateFields(['date']).then(() => {
                setHasReminder(true);
            });
        } else {
            setHasReminder(false);
        }
    };

    const disabledReminderDate = (current) => {
        const optionDate = contractOptionRef.current.getFieldValue('date');
        return current && current > moment(optionDate).endOf('day');
    };

    return (
        <Modal
            className="new-design-modal"
            wrapClassName="result-modal creation-modal"
            footer={null}
            visible={true}
            maskClosable={false}
            onCancel={onClose}
            closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <h4 className="mb-0">{t('contract.leaseDurationOptionAndContractOptionModal.contractOption.header')}</h4>
                </div>
                <div className="main">
                    <Form name="basic" autoComplete="off" ref={contractOptionRef}>
                        <Form.Item
                            name="name"
                            initialValue={props.contractOptionModel ? props.contractOptionModel.name : null}
                            rules={[requiredCommonInput(t('contract.leaseDurationOptionAndContractOptionModal.contractOption.optionName.title') + t('common.isRequired'))]}>
                            <CommonInput
                                title={t('contract.leaseDurationOptionAndContractOptionModal.contractOption.optionName.title')}
                                placeholder={t('contract.leaseDurationOptionAndContractOptionModal.contractOption.optionName.placeholder')}
                            />
                        </Form.Item>
                        <p className="font-weight-bold">{t('contract.leaseDurationOptionAndContractOptionModal.date')}</p>
                        <Form.Item
                            name="date"
                            initialValue={props.contractOptionModel && props.contractOptionModel.date ? moment(props.contractOptionModel.date) : null}
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.leaseDurationOptionAndContractOptionModal.date') + t('common.isRequired'),
                                },
                            ]}>
                            <DatePicker dropdownClassName={'new-design-date-picker'} suffixIcon={<Image src={calendarIcon} />} format={primaryDateFormat} allowClear={false} />
                        </Form.Item>
                        <p className="font-weight-bold">{t('contract.leaseDurationOptionAndContractOptionModal.contractOption.description.title')}</p>
                        <Form.Item
                            name="description"
                            initialValue={props.contractOptionModel ? props.contractOptionModel.description : null}
                            rules={[requiredCommonInput(t('contract.leaseDurationOptionAndContractOptionModal.contractOption.description.title') + t('common.isRequired'))]}>
                            <TextArea
                                placeholder={t('contract.leaseDurationOptionAndContractOptionModal.contractOption.description.placeholder')}
                                rows={5}
                                style={{ minHeight: '48px' }}
                            />
                        </Form.Item>
                        <Checkbox checked={hasReminder} onChange={onChangeHasReminder}>
                            {t('contract.leaseDurationOptionAndContractOptionModal.contractOption.setReminder')}
                        </Checkbox>
                        {hasReminder && (
                            <React.Fragment>
                                <p className="mt-3">{t('contract.leaseDurationOptionAndContractOptionModal.contractOption.reminderDate')}</p>
                                <Form.Item
                                    name="reminderDate"
                                    initialValue={props.contractOptionModel && props.contractOptionModel.reminderDate ? moment(props.contractOptionModel.reminderDate) : null}
                                    className="m-0"
                                    rules={[
                                        {
                                            required: hasReminder,
                                            message: t('contract.leaseDurationOptionAndContractOptionModal.contractOption.reminderDate') + t('common.isRequired'),
                                        },
                                    ]}>
                                    <DatePicker
                                        dropdownClassName={'new-design-date-picker'}
                                        suffixIcon={<Image src={calendarIcon} />}
                                        format={primaryDateFormat}
                                        allowClear={false}
                                    />
                                </Form.Item>
                                <Checkbox
                                    className="mt-3"
                                    checked={remindByEmail}
                                    onChange={() => {
                                        setRemindByEmail(!remindByEmail);
                                    }}>
                                    {t('contract.leaseDurationOptionAndContractOptionModal.remindMeByEmail')}
                                </Checkbox>
                            </React.Fragment>
                        )}
                    </Form>
                </div>
                <div className="btns">
                    <ActionButton onClick={onClose} className="btn-secondary" text={t('common.buttons.cancel')} disabled={props.contractOptionLoading} />
                    <ActionButton
                        onClick={confirm}
                        className="btn-primary"
                        text={t('common.buttons.confirm')}
                        loading={props.contractOptionLoading}
                        disabled={props.contractOptionLoading}
                    />
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        contractOptionModel: get(contract, 'contractOptionModel'),
        contractOptionLoading: get(contract, 'contractOptionLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showContractOptionEventModal', false));
            dispatch(setValueContract('contractOptionModel', null));
        },
        addContractOptionCall(data, contractId, propertyId, portfolioId) {
            return dispatch(addContractOption(data, contractId, propertyId, portfolioId));
        },
        updateContractOptionCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateContractOption(data, contractId, propertyId, portfolioId));
        },
    };
};

const ContractOption = connect(mapState, mapDispatch)(ContractOptionComponent);
export default ContractOption;
