import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import get from 'lodash.get';
import chartService from '../../services/chartService';

class RentExpirationProfileChartComponent extends React.Component {
    id = 'rent-expiration-profile-chart';

    constructor(props) {
        super(props);
        this.state = { chart: null };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.setState({
            chart: chartService.rentExpirationProfileChart(this.id, this.props.chartData),
        });
    }

    render() {
        return (
            <div>
                <div className="chart-title mb-2">{i18next.t('property.rentExpirationProfileChart.name')}</div>
                <div id={this.id} style={{ height: '306px' }}></div>
            </div>
        );
    }
}

const mapState = ({ property }) => {
    return {
        chartData: get(property, 'kpis.rentDurationExpiringProfileChartData'),
    };
};

const RentExpirationProfileChart = connect(mapState)(RentExpirationProfileChartComponent);
export default RentExpirationProfileChart;
