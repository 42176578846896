import { Checkbox, DatePicker, Form, Row as RowAntd, Col as ColAntd } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { durationFormater } from '../../../../helpers/durationFormater';
import { setPropertyToRentalObject, setValueContract, updateRentObject } from '../../../../reducers/contractReducer';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import ActionButton from '../../../../components/action-button';
import { useParams } from 'react-router';

const RentObjectDurationComponent = (props) => {
    const { t } = useTranslation();

    const rentalObjectIndex = useMemo(() => [...props.rentObjects].findIndex((x) => x.uId === props.rentObject.uId), [props.rentObjects.length]);

    const { portfolioId, propertyId } = useParams();

    const rentObjectDuration = React.useRef();
    const [loading, setLoading] = useState(false);

    const [leaseStartDate, setLeaseStartDate] = useState(
        props.rentObject.hasCustomDuration
            ? moment(props.rentObject.leaseStartDate)
            : props.contract && props.contract.leaseStartDate
                ? moment(props.contract.leaseStartDate)
                : moment()
    );

    const [leaseEndDate, setLeaseEndDate] = useState(
        props.rentObject.hasCustomDuration
            ? moment(props.rentObject.leaseEndDate)
            : props.contract && props.contract.leaseEndDate
                ? moment(props.contract.leaseEndDate)
                : moment().add(1, 'years')
    );

    const done = async () => {
        var rentObject = { ...props.rentObject };

        if (rentObject.hasCustomDuration) {
            rentObject.leaseStartDate = leaseStartDate;
            rentObject.leaseEndDate = leaseEndDate;
        } else {
            rentObject.leaseStartDate = null;
            rentObject.leaseEndDate = null;
        }

        setLoading(true);

        return props.updateRentObjectCall(rentObject, props.contract.id, portfolioId, propertyId).then((_) => {
            setLoading(false);
        });
    };

    return (
        <Form name="basic" autoComplete="off" ref={rentObjectDuration}>
            <Checkbox
                checked={props.rentObject.hasCustomDuration}
                onChange={(e) => {
                    props.setPropertyToRentalObjectCall(rentalObjectIndex, 'hasCustomDuration', e.target.checked);
                }}
                className="ml-3">
                {t('contract.durationAndRentalObjects.customDuration')}
            </Checkbox>
            <Row className={`rent-object-duration pl-0 ${props.rentObject.hasCustomDuration ? 'rent-object-duration-visible' : ''}`}>
                <Col lg={12} xl={5}>
                    <p className="font-weight-bold">{t('contract.durationAndRentalObjects.contractDates.leaseStartDate')}</p>
                    <Form.Item
                        name="leaseStartDate"
                        className="mb-3"
                        initialValue={leaseStartDate}
                        rules={[
                            {
                                required: true,
                                message: t('contract.durationAndRentalObjects.contractDates.leaseStartDate') + t('common.isRequired'),
                            }
                        ]}>
                        <DatePicker
                            dropdownClassName={'new-design-date-picker'}
                            suffixIcon={<Image src={calendarIcon} />}
                            format={primaryDateFormat}
                            onChange={(date) => {
                                if (props.rentObject.hasCustomDuration) {
                                    setLeaseStartDate(date.utc(true));
                                    props.setPropertyToRentalObjectCall(rentalObjectIndex, 'leaseStartDate', moment(date).isUTC(true));
                                }
                            }}
                            allowClear={false}
                            disabledDate={current => current && current > leaseEndDate}
                        />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={5}>
                    <p className="font-weight-bold">{t('contract.durationAndRentalObjects.contractDates.leaseEndDate')}</p>
                    <Form.Item
                        name="leaseEndDate"
                        className="mb-3"
                        initialValue={leaseEndDate}
                        rules={[
                            {
                                required: true,
                                message: t('contract.durationAndRentalObjects.contractDates.leaseEndDate') + t('common.isRequired'),
                            },
                            ({ getFieldValue }) => ({
                                validator: (_, value) => {
                                    const { leaseStartDate } = getFieldValue();
                                    return value?.isAfter(leaseStartDate) ? Promise.resolve() : Promise.reject('');
                                },
                            }),
                        ]}>
                        <DatePicker
                            dropdownClassName={'new-design-date-picker'}
                            suffixIcon={<Image src={calendarIcon} />}
                            format={primaryDateFormat}
                            onChange={(date) => {
                                if (props.rentObject.hasCustomDuration) {
                                    setLeaseEndDate(date.utc(true));
                                    props.setPropertyToRentalObjectCall(rentalObjectIndex, 'leaseEndDate', moment(date));
                                }
                            }}
                            allowClear={false}
                            disabledDate={current => current && current < leaseStartDate}
                        />
                    </Form.Item>
                </Col>
                {leaseStartDate && leaseEndDate ? (
                    <Col lg={12} xl={6}>
                        <div className="mb-3">
                            <p className="font-weight-bold">{t('contract.durationAndRentalObjects.contractDates.duration')}</p>
                            <p className="duration">{durationFormater(leaseStartDate, leaseEndDate)}</p>
                        </div>
                    </Col>
                ) : null}
            </Row>
            <RowAntd justify="end" className="content-block-actions m-0">
                <ColAntd className="ml-3">
                    <ActionButton
                        className='content-block-action btn-primary'
                        loading={loading}
                        disabled={rentObjectDuration.current && rentObjectDuration.current.getFieldsError().filter(({ errors }) => errors.length).length}
                        text={t('common.buttons.saveChanges')}
                        onClick={() => {
                            done()
                        }}
                    />
                </ColAntd>
            </RowAntd>
        </Form>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        rentObjects: get(contract, 'rentObjects'),
        leaseDurationOptions: get(contract, 'leaseDurationOptions'),
        hasLeaseDurationOptions: get(contract, 'leaseDurationOptions.length') > 0,
    };
};

const mapDispatch = (dispatch) => {
    return {
        updateRentObjectCall(data, contractId, portfolioId, propertyId) {
            return dispatch(updateRentObject(data, contractId, portfolioId, propertyId));
        },
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
        setPropertyToRentalObjectCall(id, key, value) {
            dispatch(setPropertyToRentalObject(id, key, value));
        },
    };
};

const RentObjectDuration = connect(mapState, mapDispatch)(RentObjectDurationComponent);
export default RentObjectDuration;
