import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { setValueTenant } from '../../../reducers/tenantReducer';
import { Image } from 'react-bootstrap';
import { CloseOutlined } from '@ant-design/icons';
import ActionButton from '../../../components/action-button';
import done from '../../../assets/images/popup-done.svg';
import routingService from '../../../services/routingService';
import contractService from '../../../services/contractService';

const TenantCreatedResultComponent = (props) => {
    const { t } = useTranslation();

    const onClose = () => {
        props.closeModalWindow();
    };

    const addTenant = () => {
        routingService.navigateToCreateTenant(props.portfolioId, props.propertyId);
        props.closeModalWindow();
    };

    const addContract = () => {
        contractService
            .createDraft(props.tenantId, props.propertyId, props.portfolioId).then((response) => {
                props.closeModalWindow();
                routingService.navigateToEditContract(props.portfolioId, props.propertyId, props.tenantId, response.data.id);
            })
    };

    return (
        <Modal wrapClassName="result-modal" footer={null} visible={true} maskClosable={false} onCancel={onClose} closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <span className="icon">
                        <Image src={done} />
                    </span>
                    <div className="d-flex flex-column">
                        <h4 className="mb-2">{t('tenant.tenantCreatedResult.title')}</h4>
                        <p className="text-secondary">{t('common.greatJob')}</p>
                    </div>
                </div>
                <div className="main">
                    <h6>{t('tenant.tenantCreatedResult.mainTitle')}</h6>
                    <p className="text-secondary">{t('tenant.tenantCreatedResult.description')}</p>
                </div>
                <div className="btns">
                    <ActionButton onClick={addTenant} className="btn-secondary" text={t('tenant.tenantCreatedResult.addTenantBtn')} />
                    <ActionButton onClick={addContract} className="btn-primary" text={t('tenant.tenantCreatedResult.addContractBtn')} />
                </div>
            </div>
        </Modal>
    );
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueTenant('showCreateTenantResultModal', false));
        },
    };
};

const TenantCreatedResult = connect(null, mapDispatch)(TenantCreatedResultComponent);
export default TenantCreatedResult;
