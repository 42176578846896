import axios from 'axios';

const rentRollService = {

    getRentRollByPropertyId: (data, propertyId, year, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/rentroll/get-by-property-id?portfolioId=${portfolioId}&propertyId=${propertyId}&year=${year}`,
        });
    },

    sendRentRollLinkByEmail: (request, portfolioId, propertyId) => {
        return axios({
            method: 'post',
            data: request,
            url: `/api/contract/rentroll/send-rent-roll-link-by-email?portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },
};

export default rentRollService;
