import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../action-button';

const LeaveModal = (props) => {
    const { t } = useTranslation();

    return (
        <Modal wrapClassName={`result-modal draft-modal ${props.className}`} visible={true} maskClosable={false} closeIcon={<CloseOutlined className="close-icon" onClick={props.onCancel} />}>
            <div>
                <div className="header">
                    <h4 className="mb-0">{t('exitFromDraft.header')}</h4>
                </div>
                <div className="main">
                    <span>
                        <h6 className="mb-0">
                            {t('exitFromDraft.title')}
                            <p className="text-secondary mb-0">{t('exitFromDraft.message')}</p>
                        </h6>
                    </span>
                </div>
                <div className="btns">
                    {props.onCancel && (
                        <ActionButton className="btn-thirdy mb-2" text={t('common.buttons.cancel')} onClick={props.onCancel} disabled={props.loading}></ActionButton>
                    )}
                    {props.onLeave && (
                        <ActionButton className="btn-secondary ml-3 mb-3" text={t('exitFromDraft.exitBtn')} onClick={props.onLeave} disabled={props.loading}></ActionButton>
                    )}
                    {props.onSave && <ActionButton className="btn-primary ml-3" text={t('exitFromDraft.saveBtn')} onClick={props.onSave} loading={props.loading}></ActionButton>}
                </div>
            </div>
        </Modal>
    );
};

export default LeaveModal;
