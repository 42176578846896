import React from 'react';
import Navigation from '../navigation/navigation';
import Header from './header';

class MainLayout extends React.Component {
    render() {
        return (
            <div className="main-layout">
                <Header />
                <Navigation />
                <div className="content">{this.props.children}</div>
            </div>
        );
    }
}

export default MainLayout;
