import { CloseOutlined } from '@ant-design/icons';
import { DatePicker, Form, Modal, Select } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';
import { invoiceIssueDateOptions, invoiceIssueDates } from '../../../../common/contract-options';
import { invoiceStatuses, invoiceStatusesOptions } from '../../../../common/invoiceOptions';
import { requiredCommonInput, validateEmail } from '../../../../common/validators';
import ActionButton from '../../../../components/action-button';
import CommonInput from '../../../../components/common-input';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import PositiveNumericInput from '../../../../components/numeric-input';
import { createInvoice, getLastInvoiceNumber, setValueInvoice, updateInvoice } from '../../../../reducers/invoiceReducer';

const { Option } = Select;

const InvoiceModalComponent = (props) => {
    const { t } = useTranslation();

    const defaultInvoiceAmount = 1050;
    const invoiceRef = React.useRef();

    useEffect(() => {
        props.getLastInvoiceNumberCall().then((lastInvoiceNumber) => {
            lastInvoiceNumber = lastInvoiceNumber || 0;
            if (invoiceRef.current) {
                const invoice = props.invoice || {
                    status: invoiceStatuses.new,
                    amount: defaultInvoiceAmount,
                    orgNumberOrFullName: props.invoicesProperty.orgNumberOrFullName,
                    invoiceIssueDate: invoiceIssueDates.fourteenDays,
                    invoiceNumber: lastInvoiceNumber + 1,
                };
                invoice.dateOfPayment = invoice.dateOfPayment ? moment(invoice.dateOfPayment) : null;
                invoiceRef.current.setFieldsValue(invoice);
            }
        });
    }, []);

    const confirm = async () => {
        await invoiceRef.current.validateFields();

        const invoice = invoiceRef.current.getFieldsValue();
        invoice.dateOfPayment = moment(invoice.dateOfPayment).startOf('day').utc(true);
        invoice.propertyId = props.invoicesProperty.propertyId;
        invoice.invoiceNumber = `${invoice.invoiceNumber}`;

        if (props.invoice) {
            invoice.id = props.invoice.id;
            await props.updateInvoiceCall(invoice);
        } else {
            await props.createInvoiceCall(invoice);
        }

        onClose();
    };

    const onClose = () => {
        props.closeModalWindow();
        props.clearInvoice();
    };

    return (
        <Modal
            wrapClassName="result-modal creation-modal"
            footer={null}
            visible={true}
            maskClosable={false}
            onCancel={onClose}
            closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <h4 className="mb-0">{props.invoice ? t('backoffice.invoices.editInvoice') : t('backoffice.invoices.addInvoice')}</h4>
                </div>
                <div className="main">
                    <Form name="basic" autoComplete="off" ref={invoiceRef}>
                        <p className="font-weight-bold">{t('backoffice.invoices.invoiceNumber')}</p>
                        <Form.Item
                            name="invoiceNumber"
                            rules={[
                                {
                                    required: true,
                                    message: t('backoffice.invoices.invoiceNumber') + t('common.isRequired'),
                                },
                            ]}>
                            <PositiveNumericInput placeholder={t('backoffice.invoices.invoiceNumberPlaceholder')} />
                        </Form.Item>
                        <Form.Item name="orgNumberOrFullName" rules={[requiredCommonInput(t('backoffice.invoices.orgNumberOrFullName') + t('common.isRequired'))]}>
                            <CommonInput title={t('backoffice.invoices.orgNumberOrFullName')} placeholder={t('backoffice.invoices.orgNumberOrFullNamePlaceholder')} />
                        </Form.Item>
                        <p className="font-weight-bold">{t('backoffice.invoices.amount')}</p>
                        <Form.Item
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: t('backoffice.invoices.amount') + t('common.isRequired'),
                                },
                            ]}>
                            <PositiveNumericInput placeholder={t('backoffice.invoices.amountPlaceholder')} />
                        </Form.Item>
                        <p className="font-weight-bold">{t('backoffice.invoices.invoiceIssueDate')}</p>
                        <Form.Item
                            name="invoiceIssueDate"
                            rules={[
                                {
                                    required: true,
                                    message: t('backoffice.invoices.invoiceIssueDate') + t('common.isRequired'),
                                },
                            ]}>
                            <Select>
                                {invoiceIssueDateOptions.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                        {item.name()}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <p className="font-weight-bold">{t('backoffice.invoices.dateOfPayment')}</p>
                        <Form.Item
                            name="dateOfPayment"
                            rules={[
                                {
                                    required: true,
                                    message: t('backoffice.invoices.dateOfPayment') + t('common.isRequired'),
                                },
                            ]}>
                            <DatePicker suffixIcon={<Image src={calendarIcon} />} format={primaryDateFormat} allowClear={false} />
                        </Form.Item>
                        <Form.Item name="invoiceEmail" rules={[requiredCommonInput(t('backoffice.invoices.invoiceEmail') + t('common.isRequired')), validateEmail]}>
                            <CommonInput
                                type="email"
                                submitOnEnter={true}
                                title={t('backoffice.invoices.invoiceEmail')}
                                placeholder={t('backoffice.invoices.invoiceEmailPlaceholder')}
                            />
                        </Form.Item>
                        <p className="font-weight-bold">{t('backoffice.invoices.status')}</p>
                        <Form.Item name="status">
                            <Select>
                                {invoiceStatusesOptions.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                        {item.name()}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </div>
                <div className="btns">
                    <ActionButton onClick={onClose} className="btn-secondary" text={t('common.buttons.cancel')} disabled={props.loading} />
                    <ActionButton onClick={confirm} className="btn-primary" text={t('common.buttons.confirm')} loading={props.loading} disabled={props.loading} />
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ invoice }) => {
    return {
        invoice: get(invoice, 'invoice'),
        loading: get(invoice, 'loading'),
        invoicesProperty: get(invoice, 'invoicesProperty'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueInvoice('showInvoiceModal', false));
        },
        createInvoiceCall(data) {
            dispatch(createInvoice(data));
        },
        updateInvoiceCall(data) {
            dispatch(updateInvoice(data));
        },
        clearInvoice() {
            dispatch(setValueInvoice('invoice', null));
        },
        getLastInvoiceNumberCall() {
            return dispatch(getLastInvoiceNumber());
        },
    };
};

const InvoiceModal = connect(mapState, mapDispatch)(InvoiceModalComponent);
export default InvoiceModal;
