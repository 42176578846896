import { Col, Dropdown, Form, Menu, Row, Select, Tooltip, Typography, notification } from 'antd';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import required from '../../../assets/images/required.svg';
import { useTranslation } from 'react-i18next';
import { requiredCommonInput, validateEmail } from '../../../common/validators';
import { connect } from 'react-redux';
import CommonInput from '../../../components/common-input';
import { get } from 'lodash-es';
import ActionButton from '../../../components/action-button';
import portfolioService from '../../../services/portfolioService';
import { useParams } from 'react-router';
import helper from '../../../helpers/helper';
import pendingStatus from '../../../assets/images/pending-status-icon.svg';
import successInvite from '../../../assets/images/success-invite.svg';
import threeDots from '../../../assets/images/three-dots.svg';
import { setValuePortfolio } from '../../../reducers/portfolioReducer';
import { v4 as uuidv4 } from 'uuid';
import { userPermisionsTypesOptions } from '../../../common/constants';
import declinedStatus from '../../../assets/images/declined-invite.svg';
import { modalActions } from './invite-step';

const { Text } = Typography;

const InviteUserRow = ({ owner, isNewInvite, userInvite, setPortfolioCall, portfolio, isEditPortfolio, setInviteModalVisible, setIsInviteLoading }) => {

    const { t } = useTranslation();

    const { portfolioId } = useParams();

    const [form] = Form.useForm();

    const [isEditMode, setEditMode] = useState(false);

    const sendInvite = async () => {
        const invitedUser = await form.validateFields();

        try {
            if (isEditPortfolio) {
                setIsInviteLoading(true);
                const inviteResponse = await portfolioService.sendInvite(portfolioId, invitedUser);
                setPortfolioCall({
                    ...portfolio,
                    invites: [...portfolio.invites, inviteResponse.data]
                });
            } else {
                setPortfolioCall({
                    ...portfolio,
                    invites: [...portfolio.invites, { ...invitedUser, uIdInvite: uuidv4() }]
                });
            }
            openNotification(t('portfolio.invites.sentSuccess'), 'success')
            form.resetFields();
            setIsInviteLoading(false);
        } catch (error) {
            openNotification(t('common.serverErrorMessage'), 'error')
            setIsInviteLoading(false);
        }
    }

    const updateInvite = async () => {
        const invitedUser = await form.validateFields();

        try {
            if (isEditPortfolio) {
                setIsInviteLoading(true);
                const inviteResponse = await portfolioService.updateInvite({ ...invitedUser, id: userInvite.id }, portfolioId);
                setPortfolioCall({
                    ...portfolio,
                    invites: [...portfolio.invites.filter(item => item.id != userInvite.id), inviteResponse.data]
                });
            } else {
                setPortfolioCall({
                    ...portfolio,
                    invites: [...portfolio.invites.filter(item => item.uIdInvite != userInvite.uIdInvite), invitedUser]
                });
            }
            openNotification(t('portfolio.invites.updatedSuccess'), 'success')
            setEditMode(false);
            setIsInviteLoading(false);
        } catch (error) {
            openNotification(t('common.serverErrorMessage'), 'error')
            setIsInviteLoading(false);
        }
    }

    const openNotification = (message, type) => {
        notification[type]({
            message: message,
            placement: 'bottomLeft'
        });
    }

    const resendInvite = async () => {
        setInviteModalVisible({ visible: true, inviteId: isEditPortfolio ? userInvite.id : userInvite.uIdInvite, action: modalActions.resend });
    }

    const deleteUserInvite = async () => {
        setInviteModalVisible({ visible: true, inviteId: isEditPortfolio ? userInvite.id : userInvite.uIdInvite, action: modalActions.delete });
    }

    const openEdit = () => {
        setEditMode(true);
        form.setFieldsValue(userInvite)
    }

    const inviteRowMenu = (
        <Menu>
            <Menu.Item onClick={openEdit}><p>{t('common.buttons.edit')}</p></Menu.Item>
            {isEditPortfolio && <Menu.Item onClick={resendInvite}><p>{t('portfolio.invites.resend')}</p></Menu.Item>}
            <Menu.Item onClick={deleteUserInvite}><p style={{ color: '#F13F3F' }} >{t('portfolio.invites.deleteInvite')}</p></Menu.Item>
        </Menu>
    );

    const getInviteStatusIcon = (isAccepted) => {
        switch (isAccepted) {
            case true:
                return (
                    <Tooltip title={t('portfolio.invites.successInvite')} overlayClassName='invite-tooltip'>
                        <Image style={{ marginTop: isEditMode ? '8px' : '0px' }} src={successInvite} />
                    </Tooltip>
                )

            case false:
                return (
                    <Tooltip title={t('portfolio.invites.declined')} overlayClassName='invite-tooltip' >
                        <Image style={{ marginTop: isEditMode ? '8px' : '0px' }} src={declinedStatus} />
                    </Tooltip>
                )

            default:
                return (
                    <Tooltip title={t('portfolio.invites.pending')} overlayClassName='invite-tooltip' >
                        <Image style={{ marginTop: isEditMode ? '8px' : '0px' }} src={pendingStatus} />
                    </Tooltip>
                )
        }
    }

    return (
        <Form className='invite-user-row' form={form} >
            <Row>
                <Col span={isNewInvite ? 4 : 6} offset={isNewInvite ? 2 : 0}>
                    {isNewInvite && (
                        <p className="font-weight-bold d-flex flex-row align-items-center">
                            <Image className="required-img" src={required} />
                            {t('portfolio.invites.email.title')}
                        </p>
                    )}
                    {(isNewInvite) && (
                        <Form.Item
                            name='email'
                            rules={[
                                validateEmail,
                                requiredCommonInput(t('portfolio.invites.emailPart.email.title') + t('common.isRequired')),
                                () => ({
                                    validator(_, value) {
                                        if (value && value === owner?.email) {
                                            return Promise.reject(new Error(t('portfolio.invites.emailPart.dontAllowAddingOfOwner')));
                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}>
                            <CommonInput placeholder={t('portfolio.invites.emailPart.email.placeholder')} />
                        </Form.Item>
                    )}
                    {!isNewInvite && (
                        <Text className='text-secondary ml-2 w-100' ellipsis={{ tooltip: true}}>{userInvite?.email}</Text>
                    )}
                </Col>
                <Col span={4}>
                    {isNewInvite && (
                        <p className="font-weight-bold d-flex flex-row align-items-center">
                            <Image className="required-img" src={required} />
                            {t('portfolio.invites.firstName.title')}
                        </p>
                    )}
                    {(isEditMode || isNewInvite) && (
                        <Form.Item
                            name='firstName'
                            rules={[
                                requiredCommonInput(t('portfolio.invites.firstName.title') + t('common.isRequired'))
                            ]}>
                            <CommonInput placeholder={t('portfolio.invites.firstName.title')} />
                        </Form.Item>
                    )}
                    {!isEditMode && (
                        <Text className='text-secondary ml-2 w-100' ellipsis={{ tooltip: true}}>{userInvite?.firstName}</Text>
                    )}
                </Col>
                <Col span={4}>
                    {isNewInvite && (
                        <p className="font-weight-bold d-flex flex-row align-items-center">
                            <Image className="required-img" src={required} />
                            {t('portfolio.invites.lastName.title')}
                        </p>
                    )}
                    {(isEditMode || isNewInvite) && (
                        <Form.Item
                            name='lastName'
                            rules={[
                                requiredCommonInput(t('portfolio.invites.lastName.title') + t('common.isRequired'))
                            ]}>
                            <CommonInput placeholder={t('portfolio.invites.lastName.title')} />
                        </Form.Item>
                    )}
                    {!isEditMode && (
                        <Text className='text-secondary ml-2 w-100' ellipsis={{ tooltip: true}}>{userInvite?.lastName}</Text>
                    )}
                </Col>
                <Col span={5}>
                    {isNewInvite && (
                        <p className="font-weight-bold d-flex flex-row align-items-center">
                            <Image className="required-img" src={required} />
                            {t('portfolio.invites.role.title')}
                        </p>
                    )}
                    {(isEditMode || isNewInvite) && (
                        <Form.Item name='permissionId' rules={[
                            {
                                required: true,
                                message: t('portfolio.invites.role.title') + t('common.isRequired')
                            }
                        ]}>
                            <Select placeholder={t('portfolio.invites.role.title')}>
                                {userPermisionsTypesOptions.map((item) => (
                                    <Select.Option value={item.id} key={item.id} disabled={item.disabled} >
                                        {item.name()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    {!isEditMode && (
                        <Text className='text-secondary ml-2 w-100' ellipsis={{ tooltip: true}}>{helper.getEnumValue(userPermisionsTypesOptions, userInvite?.permissionId)}</Text>
                    )}
                </Col>
                <Col span={5}>
                    <div className='actions-btn'>
                        {isNewInvite && (
                            <ActionButton
                                className='btn-primary invite-btn'
                                text={t('portfolio.invites.invite')}
                                onClick={sendInvite}
                            />
                        )}
                        {!isNewInvite && (
                            <>
                                {getInviteStatusIcon(userInvite?.isAccepted)}
                                {isEditMode ? (
                                    <>
                                        <ActionButton
                                            style={{ marginTop: '7px', marginLeft: '7px' }}
                                            className='btn-secondary invite-btn'
                                            text={t('common.buttons.cancel')}
                                            onClick={() => setEditMode(false)}
                                        />
                                        <ActionButton
                                            style={{ marginTop: '7px', marginLeft: '15px' }}
                                            className='btn-primary invite-btn'
                                            text={t('common.buttons.save')}
                                            onClick={updateInvite}
                                        />
                                    </>
                                ) : (
                                    <Dropdown overlay={inviteRowMenu} overlayClassName='invite-menu' trigger={['click']} placement="topRight">
                                        <Image style={{ marginTop: isEditMode ? '8px' : '0px', marginLeft: '20px' }} src={threeDots} />
                                    </Dropdown>
                                )}
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </Form >
    )
}

const mapState = ({ common, portfolio }) => {
    return {
        userEmail: get(common, 'user.email'),
        owner: get(portfolio, 'portfolioOwner'),
        portfolio: get(portfolio, 'portfolio')
    };
};

const mapDispatch = (dispatch) => {
    return {
        setPortfolioCall(value) {
            dispatch(setValuePortfolio('portfolio', value));
        }
    };
};

export default connect(mapState, mapDispatch)(InviteUserRow);