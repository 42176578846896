import { Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import tenant from '../../assets/images/common-cost-tenant.svg';
import edit from '../../assets/images/edit.svg';
import collapse from '../../assets/images/select-arrow.svg';
import warningCircleYellow from '../../assets/images/warning-circle-yellow.svg';
import { rentObjectRentalType, rentObjectRentalTypeOptions, unitType } from '../../common/contract-options';
import helper from '../../helpers/helper';
import { showActualsSelector } from '../../pages/common-cost/selectors';
import { setValueCommonCost } from '../../reducers/commonCostReducer';
import { predefinedColors } from '../../services/chartService';
import ActionButton from '../action-button';
import CurrencyFormat from '../currency-format';
import { primaryDateFormat } from '../dateFormatter/dateFormats';
import DefaultTooltip from './default-tooltip';
import { editPermisionTypes } from '../../common/constants';
import { get } from 'lodash-es';

const CommonCostTenant = ({ tenantColor, tenantInfo, hasEditPermission }) => {
    const { t } = useTranslation();
    const dividerRef = useRef();
    const commonCostCardRef = useRef();
    const [collapsed, setCollapsed] = useState(true);
    const [dividerOffset, setDividerOffset] = useState(0);
    const [selectedRentObject, setSelectedRentObject] = useState(rentObjectRentalType.total);
    const dispatch = useDispatch();
    const commonCostBudgetItems = useSelector(({ commonCost }) => commonCost.commonCost.commonCostItems);
    const showActuals = useSelector(showActualsSelector);
    const buildingId = useSelector(({ commonCost }) => commonCost.commonCost?.buildingId);

    const openFractionCalculator = (rentObjectId) => {
        dispatch(
            setValueCommonCost(
                'fractionItemEditModel',
                tenantInfo.fractions.find((item) => item.rentObjectId === rentObjectId)
            )
        );
        dispatch(setValueCommonCost('showFractionModal', true));
    };

    const getLegendMarkerColor = (index) => {
        return predefinedColors[index % predefinedColors.length];
    };

    const rentObjectsDetails = tenantInfo.fractions.map((fractionItem) => {
        return {
            ...fractionItem,
            name: `${helper.getEnumValue(rentObjectRentalTypeOptions, fractionItem.rentObjectTypeId)} ${fractionItem?.rentObjectName ?? ''}`,
            square: fractionItem.rentObjectUnitTypeId !== unitType.numberOfUnits ? helper.numberWithSpaces(fractionItem.area) : 'N/A',
            fraction: fractionItem.rentObjectUnitTypeId !== unitType.numberOfUnits ? `${helper.numberWithSpaces(fractionItem?.summary?.total?.fractionPercent?.toFixed(1))}%` : 'N/A',
            isTotal: fractionItem.rentObjectTypeId === rentObjectRentalType.total,
            rentObjectId: fractionItem.rentObjectId,
        };
    });


    const currentFraction = tenantInfo.fractions.find((item) => item.rentObjectTypeId === selectedRentObject);
    const isFractionAltered = tenantInfo.fractions.some((item) => item.hasAlteredFraction);

    const arrayTenantDetails = currentFraction.fractionItems.map((item) => {
        const commonCostItem = commonCostBudgetItems.find((x) => x.id === item.commonCostItemId);

        return {
            ...item,
            name: commonCostItem?.name,
            budget: item.budget,
            fractionPercent: item.fractionPercent,
            fractionAmount: item.fractionAmount,
        };
    });

    const expandedTenant = (_) => {
        return (
            <>
                <div className="rent-object-select-wrapper">
                    <p>{`${t('commonCost.tenantCard.rentObject')}:`}</p>
                    <Select
                        defaultValue={rentObjectRentalType.total}
                        onSelect={(value) => setSelectedRentObject(value)}
                        dropdownClassName="new-design-dropdown"
                        placeholder={t('selectEntityForCreation.propertyPlaceholder')}>
                        {tenantInfo.fractions
                            .filter((item) => item.rentObjectUnitTypeId !== unitType.numberOfUnits)
                            .map((item) => (
                                <Select.Option value={item.rentObjectTypeId} key={item.rentObjectTypeId}>
                                    {`${helper.getEnumValue(rentObjectRentalTypeOptions, item.rentObjectTypeId)} ${item.area}m2`}
                                </Select.Option>
                            ))}
                    </Select>
                </div>
                {currentFraction.leaseStartDate && currentFraction.leaseEndDate && (
                    <div className="rent-object-duration">
                        <p>
                            <span className="text-secondary">Rent object duration: </span>
                            {`${moment(currentFraction.leaseStartDate).utc(true).format(primaryDateFormat)} - ${moment(currentFraction.leaseEndDate)
                                .utc(true)
                                .format(primaryDateFormat)}`}
                        </p>
                    </div>
                )}
                <div className="table-flex">
                    <div className="d-flex flex-column">
                        <Row className="table-header visible-md">
                            <Col xs={12} md={6}>
                                <div className="th">{t('commonCost.title')}</div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="th">{t('commonCost.analysisDetails.budget')}</div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="th">{showActuals ? t('commonCost.tenantCard.actualYtd') : t('commonCost.calculations.fraction')}</div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="th">{showActuals ? t('commonCost.tenantCard.remaining') : t('commonCost.tenantCard.share')}</div>
                            </Col>
                        </Row>
                        <div className="divider hidden-md" />
                        <div className="table-body">
                            {arrayTenantDetails.map((item, index) => (
                                <div className={`table-row ${index % 2 === 1 ? 'alternate' : ''}`}>
                                    <Row align={'middle'}>
                                        <Col xs={12} md={6}>
                                            <div className="td d-flex flex-row align-items-center">
                                                {/* <span className="legend-marker" style={{ backgroundColor: getLegendMarkerColor(index), marginLeft: '-15px' }}></span> */}
                                                {item.name}
                                            </div>
                                        </Col>
                                        <Col xs={12} md={2}>
                                            <div className="td">
                                                <span className="hidden-md">{t('commonCost.analysisDetails.budget')}</span>
                                                {item.isBudget ? <CurrencyFormat value={showActuals ? item.fractionAmount?.toFixed(1) : item.budget?.toFixed(1)} isHyphen={true} /> : 'N/A'}
                                            </div>
                                        </Col>
                                        <Col xs={12} md={2}>
                                            <div className="td">
                                                <span className="hidden-md">{t('commonCost.calculations.fraction')}</span>
                                                {showActuals ? `${item.actualAmount?.toFixed(1)}.-` : `${helper.numberWithSpaces(item.fractionPercent?.toFixed(1))}%`}
                                                {!showActuals && item.fractionPercent != item.initialFractionPercent && (
                                                    <DefaultTooltip title="This fraction value has been altered">
                                                        <Image className="ml-2" src={warningCircleYellow} color="#F6C000" style={{ height: '18px' }} />
                                                    </DefaultTooltip>
                                                )}
                                            </div>
                                        </Col>
                                        <Col xs={12} md={2}>
                                            <div className="td">
                                                <span className="hidden-md">{t('commonCost.calculations.contractFraction')}</span>
                                                <CurrencyFormat
                                                    value={showActuals ? item.remaining?.toFixed(1) : item.fractionAmount?.toFixed(1)}
                                                    isHyphen={true}
                                                    style={{ color: showActuals ? (item.remaining > 0 ? '#F13F3F' : '#00AE31') : '' }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </div>
                        <div className="table-footer p-0">
                            <Row className="justify-content-between m-0">
                                <Col xs={12} md={6} className="p-0">
                                    <div className="td">{t('contract.finances.rentExemptions.table.total')}</div>
                                </Col>
                                <Col xs={12} md={2} className="p-0">
                                    <div className="td">
                                        <span className="hidden-md">{t('commonCost.calculations.contractArea')}:</span>
                                        <CurrencyFormat value={showActuals ? currentFraction?.summary?.total?.fractionAmount?.toFixed(1) : currentFraction?.summary?.total?.budget?.toFixed(1)} isHyphen={true} />
                                    </div>
                                </Col>
                                <Col xs={12} md={2} className="p-0">
                                    <div className="td">
                                        <span className="hidden-md">{t('commonCost.calculations.contractArea')}:</span>
                                        {currentFraction?.summary?.total ? (showActuals ? `${currentFraction?.summary?.total?.actualAmount?.toFixed(1)}.-` : `${helper.numberWithSpaces(currentFraction?.summary?.total?.fractionPercent?.toFixed(1))}%`) : 'N/A'}
                                    </div>
                                </Col>
                                <Col xs={12} md={2} className="p-0">
                                    <div className="td">
                                        <span className="hidden-md">{t('commonCost.calculations.contractFraction')}:</span>
                                        <CurrencyFormat
                                            value={showActuals ? currentFraction?.summary?.total?.remaining?.toFixed(1) : currentFraction?.summary?.total?.fractionAmount?.toFixed(1)}
                                            isHyphen={true}
                                            style={{ color: showActuals ? (currentFraction?.summary?.total?.remaining > 0 ? '#F13F3F' : '#00AE31') : '' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-between m-0">
                                <Col xs={12} md={6} className="p-0">
                                    <div className="td">{t('commonCost.calculations.administrationFee')}</div>
                                </Col>
                                <Col xs={12} md={2} className="p-0">
                                    <div className="td">
                                        <span className="hidden-md">{t('commonCost.calculations.contractArea')}:</span>
                                        <CurrencyFormat
                                            value={showActuals ? currentFraction?.summary?.adminFee?.fractionAmount?.toFixed(1) : currentFraction?.summary?.adminFee?.budget?.toFixed(1)}
                                            isHyphen={true}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} md={2} className="p-0">
                                    <div className="td">
                                        <span className="hidden-md">{t('commonCost.calculations.contractArea')}:</span>
                                        {currentFraction?.summary?.adminFee ? (showActuals ? `${currentFraction?.summary?.adminFee?.actualAmount?.toFixed(1)}.-` : `${helper.numberWithSpaces(currentFraction?.summary?.adminFee?.fractionPercent?.toFixed(1))}%`) : 'N/A'}
                                    </div>
                                </Col>
                                <Col xs={12} md={2} className="p-0">
                                    <div className="td">
                                        <span className="hidden-md">{t('commonCost.calculations.contractFraction')}:</span>
                                        <CurrencyFormat
                                            value={showActuals ? currentFraction?.summary?.adminFee?.remaining?.toFixed(1) : currentFraction?.summary?.adminFee?.fractionAmount?.toFixed(1)}
                                            isHyphen={true}
                                            style={{ color: showActuals ? (currentFraction?.summary?.adminFee?.remaining > 0 ? '#F13F3F' : '#00AE31') : '' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-between m-0">
                                <Col xs={12} md={6} className="p-0">
                                    <div className="td">{t('commonCost.calculations.totalCostInkFee')}</div>
                                </Col>
                                <Col xs={12} md={2} className="p-0">
                                    <div className="td">
                                        <span className="hidden-md">{t('commonCost.calculations.contractArea')}:</span>
                                        <CurrencyFormat
                                            value={showActuals ? currentFraction?.summary?.totalCosts?.fractionAmount?.toFixed(1) : currentFraction?.summary?.totalCosts?.budget?.toFixed(1)}
                                            isHyphen={true}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} md={2} className="p-0">
                                    <div className="td">
                                        <span className="hidden-md">{t('commonCost.calculations.contractArea')}:</span>
                                        {currentFraction?.summary?.totalCosts ? (showActuals ? `${currentFraction?.summary?.totalCosts?.actualAmount?.toFixed(1)}.-` : `${helper.numberWithSpaces(currentFraction?.summary?.totalCosts?.fractionPercent?.toFixed(1))}%`) : 'N/A'}
                                    </div>
                                </Col>
                                <Col xs={12} md={2} className="p-0">
                                    <div className="td">
                                        <span className="hidden-md">{t('commonCost.calculations.contractFraction')}:</span>
                                        <CurrencyFormat
                                            value={showActuals ? currentFraction?.summary?.totalCosts?.remaining?.toFixed(1) : currentFraction?.summary?.totalCosts?.fractionAmount?.toFixed(1)}
                                            isHyphen={true}
                                            style={{ color: showActuals ? (currentFraction?.summary?.totalCosts?.remaining > 0 ? '#F13F3F' : '#00AE31') : '' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="common-cost-tenant-card" ref={commonCostCardRef}>
            <div className="common-cost-tenant-header">
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex align-items-center tenant-name">
                        <div className={`common-cost-tenant-image ${tenantColor}`}>
                            <Image src={tenant} />
                        </div>
                        <div className="d-flex flex-column ml-3">
                            <p className="font-weight-bold">{tenantInfo.tenant.displayName}</p>
                            {tenantInfo.tenant.corporateEmail && <p className="text-secondary">{tenantInfo.tenant.corporateEmail}</p>}
                            {tenantInfo.contract?.leaseStartDate && tenantInfo.contract?.leaseEndDate && (
                                <p>
                                    <span className="text-secondary">Lease duration: </span>
                                    {`${moment(tenantInfo.contract?.leaseStartDate).utc(true).format(primaryDateFormat)} - ${moment(tenantInfo.contract?.leaseEndDate)
                                        .utc(true)
                                        .format(primaryDateFormat)}`}
                                </p>
                            )}
                        </div>
                    </div>
                    <Col md={2}>
                        <div className="d-flex align-items-center justify-content-end expanded-btn">
                            <ActionButton
                                className={`collapse-btn btn-transparent ${!collapsed ? 'not-collapsed-btn' : ''}`}
                                icon={<Image src={collapse} />}
                                onClick={() => setCollapsed(!collapsed)}
                            />
                        </div>
                    </Col>
                </div>
                <div className="rent-objects-details table-flex">
                    <div className="table-body">
                        <Row className="table-row header-row">
                            <Col md={6} style={{ paddingLeft: '15px' }}>
                                <div className="th">{`${t('commonCost.tenantCard.rentObject')}:`}</div>
                            </Col>
                            <Col md={2}>
                                <div className="th">{buildingId ? t('commonCost.tenantCard.m2') : t('commonCost.costsTable.units')}</div>
                            </Col>
                            <Col md={2}>
                                <div className="th">{t('commonCost.tenantCard.fraction')}</div>
                            </Col>
                        </Row>
                        {rentObjectsDetails.map((item, index) => {
                            return (
                                <>
                                    <Row className="table-row">
                                        <Col md={6} className="d-flex align-items-center">
                                            {/* {!item.isTotal && <span className="legend-marker" style={{ backgroundColor: getLegendMarkerColor(index) }}></span>} */}
                                            <div className="th">{item.name}</div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="th">{item.square}</div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="th">{item.fraction}</div>
                                        </Col>
                                        {!item.isTotal && item.rentObjectUnitTypeId !== 2 && !showActuals && (
                                            <Col md={1}>
                                                <Image className="ml-2" src={edit} style={{ cursor: hasEditPermission ? 'pointer' : 'not-allowed' }} onClick={() => hasEditPermission ? openFractionCalculator(item.rentObjectId) : null} />
                                            </Col>
                                        )}
                                        {item.hasAlteredFraction && !item.isTotal && (
                                            <Col md={1}>
                                                <DefaultTooltip title="This fraction value has been altered">
                                                    <Image className="ml-2" src={warningCircleYellow} color="#F6C000" style={{ height: '18px' }} />
                                                </DefaultTooltip>
                                            </Col>
                                        )}
                                    </Row>
                                </>
                            );
                        })}
                    </div>
                </div>
                {isFractionAltered && (
                    <div className="fraction-notification">
                        <Image className="ml-2" src={warningCircleYellow} />
                        <p>{t('commonCost.tenantCard.fractionValueAltered')}</p>
                    </div>
                )}
            </div>
            {!collapsed ? expandedTenant(arrayTenantDetails) : null}
        </div>
    );
};

const mapState = ({ navigation }) => {
    return {
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
    };
};

export default connect(mapState, null)(CommonCostTenant);
