import i18next from 'i18next';
import { StatusCodes } from 'http-status-codes';
import axios from 'axios';
import update from '../helpers/update';
import userService from '../services/userService';
import authService from '../services/authService';
import commonService from '../services/commonService';
import { languageService } from '../services/languageService';
import routingService from '../services/routingService';
import toaster from '../common/toaster';

const VALUE_SET = 'COMMON_VALUE_SET';
const SET_ERROR_COMMON = 'SET_ERROR_COMMON';
const SET_USER = 'SET_USER';

const initialState = {
    user: {},
    countries: [],
    loading: true,
    error: null,
};

const commonReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case VALUE_SET:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case SET_ERROR_COMMON:
            newState = update.set(newState, 'error', action.payload);
            newState = update.set(newState, 'loading', false);
            break;
        case SET_USER:
            newState = update.set(newState, 'user', action.payload);
            newState = update.set(newState, 'loading', false);
            break;
        default:
            break;
    }

    return newState;
};

export const setValue = (key, value) => {
    return {
        type: VALUE_SET,
        payload: {
            key,
            value,
        },
    };
};

export const successGetUser = (value) => {
    return {
        type: SET_USER,
        payload: value,
    };
};

export const setError = (value) => {
    if (value.response && value.response.data) {
        return {
            type: SET_ERROR_COMMON,
            payload: value.response.data.title,
        };
    }
    return {
        type: SET_ERROR_COMMON,
        payload: i18next.t('common.serverErrorMessage'),
    };
};

export const getUser = () => {
    return (dispatch) => {
        if (!authService.isLoggedIn()) {
            return;
        }
        dispatch(setValue('loading', true));
        userService
            .getCurrent()
            .then((response) => {
                dispatch(successGetUser(response.data));
                languageService.changeLanguage(response.data.language);
            })
            .catch((error) => {
                if (!axios.isCancel(error) && !error.response) {
                    toaster.error(i18next.t('common.serverErrorMessage'), null);
                    routingService.navigateToLogin(window.location.pathname);
                } else if (error.response && error.response.status && error.response.status === StatusCodes.BAD_REQUEST) {
                    routingService.navigateToLogin();
                } else {
                    dispatch(setError(error));
                }
            });
    };
};

export const getCountries = () => {
    return (dispatch) => {
        commonService
            .getCountries()
            .then((response) => {
                dispatch(setValue('countries', response.data));
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    };
};

export const setUser = (user) => {
    return (dispatch) => {
        dispatch(successGetUser(user));
    };
};

export const clearUser = () => {
    return (dispatch) => {
        dispatch(successGetUser({}));
    };
};

export default commonReducer;
