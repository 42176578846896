import { Menu } from 'antd';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import editDisabled from '../../assets/images/edit-disabled.svg';
import edit from '../../assets/images/edit.svg';
import ActionButton from '../action-button';
import TooltipBtn from '../tooltip-btn';

const EditMenuItem = ({ text, onClick, key, disabled }) => {
    const { t } = useTranslation();

    const menuItem = (
        <Menu.Item key={key} onClick={onClick} disabled={disabled}>
            <ActionButton icon={<Image className="ml-2" src={disabled ? editDisabled : edit} />} text={text} />
        </Menu.Item>
    );

    return (
        <React.Fragment>
            {disabled ? (
                <TooltipBtn title={t('common.noPermission')} placement="right">
                    {menuItem}
                </TooltipBtn>
            ) : (
                menuItem
            )}
        </React.Fragment>
    );
};

export default EditMenuItem;
