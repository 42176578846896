import { DatePicker, Form, Input, Select } from 'antd';
import { get } from 'lodash-es';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { invoiceIssueDateOptions, paymentPlanOptions } from '../../../../common/contract-options';
import CustomContractDatePicker from '../../../../components/custom-contract-date-picker';
import { updatePreview } from '../../../../reducers/contractReducer';
import moment from 'moment';
import { Image } from 'react-bootstrap';
import required from '../../../../assets/images/required.svg';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';

const { Option } = Select;

const PaymentsTermsOptions = (props) => {
    const { t } = useTranslation();

    const getRentInvoicedFromDate = () => {
        let { rentInvoicedFromDate, leaseStartDate } = props.contract;

        rentInvoicedFromDate = rentInvoicedFromDate ? moment(rentInvoicedFromDate).startOf('day').utc(true) : null;

        return rentInvoicedFromDate || moment(leaseStartDate).startOf('day').utc(true);
    };

    const getFirstInvoiceDueDate = () => {
        let { firstInvoiceDueDate, leaseStartDate } = props.contract;

        firstInvoiceDueDate = firstInvoiceDueDate ? moment(firstInvoiceDueDate).startOf('day').utc(true) : null;

        return firstInvoiceDueDate || moment(leaseStartDate).startOf('day').utc(true);
    };

    return (
        <Row>
            <Col lg={12} xl={6}>
                <p className="font-weight-bold">{t('contract.paymentTerms.rentInvoicedFrom')}</p>
                <Form.Item
                    name="rentInvoicedFromDate"
                    initialValue={getRentInvoicedFromDate()}
                    rules={[
                        {
                            required: true,
                            message: t('contract.paymentTerms.rentInvoicedFrom') + t('common.isRequired'),
                        },
                    ]}>
                    <DatePicker
                        style={{ width: '50%' }}
                        dropdownClassName={'new-design-date-picker'}
                        suffixIcon={<Image src={calendarIcon} />}
                        format={primaryDateFormat}
                        allowClear={false}
                    />
                </Form.Item>
                <p className="font-weight-bold">{t('contract.paymentTerms.firstInvoiceDueDate')}</p>
                <Form.Item
                    name="firstInvoiceDueDate"
                    initialValue={getFirstInvoiceDueDate()}
                    rules={[
                        {
                            required: true,
                            message: t('contract.paymentTerms.firstInvoiceDueDate') + t('common.isRequired'),
                        },
                    ]}>
                    <DatePicker
                        style={{ width: '50%' }}
                        dropdownClassName={'new-design-date-picker'}
                        suffixIcon={<Image src={calendarIcon} />}
                        format={primaryDateFormat}
                        allowClear={false}
                    />
                </Form.Item>
                <p className="font-weight-bold">
                    <Image className="required-img" src={required} />
                    {t('contract.paymentTerms.invoiceIssueDate.title')}
                </p>
                <Form.Item
                    name="invoiceIssueDateOption"
                    rules={[
                        {
                            required: true,
                            message: t('contract.paymentTerms.invoiceIssueDate.title') + t('common.isRequired'),
                        },
                    ]}>
                    <Select placeholder={t('contract.paymentTerms.invoiceIssueDate.placeholder')}>
                        {invoiceIssueDateOptions.map((object) => (
                            <Option value={object.id} key={object.id}>
                                {object.name()}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <p className="font-weight-bold">
                    <Image className="required-img" src={required} />
                    {t('contract.paymentTerms.paymentPlan.title')}
                </p>
                <Form.Item
                    name="paymentPlan"
                    rules={[
                        {
                            required: true,
                            message: t('contract.paymentTerms.paymentPlan.title') + t('common.isRequired'),
                        },
                    ]}>
                    <Select
                        onChange={(value) => {
                            props.updatePreviewPaymentPlay(value);
                        }}
                        placeholder={t('contract.paymentTerms.paymentPlan.placeholder')}>
                        {paymentPlanOptions.map((object) => (
                            <Option value={object.id} key={object.id}>
                                {object.name()}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <p className="font-weight-bold">{t('contract.paymentTerms.paymentReference')}</p>
                <Form.Item name="paymentReference">
                    <Input placeholder={t('contract.paymentTerms.paymentReference')} />
                </Form.Item>
            </Col>
        </Row>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        updatePreviewPaymentPlay(value) {
            return dispatch(updatePreview({ paymentPlan: value }));
        },
    };
};

export default connect(mapState, mapDispatch)(PaymentsTermsOptions);
