import lodash from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { deleteWarrantiesIfDraft, setValueContract } from '../../../../reducers/contractReducer';
import WarrantyForm from './warranty-form';
import { useParams } from 'react-router';

const WarrantyComponent = (props) => {
    const { t } = useTranslation();

    const { portfolioId, propertyId } = useParams();

    useEffect(() => {
        return () => {
            props.deleteWarrantiesIfDraftCall(props.contractId, portfolioId, propertyId);
        };
    }, []);

    return (
        <>
            <div className="page-container pb-0">
                <WarrantyForm warranty={props.warranties?.length ? props.warranties.find((w) => !w.rentObjectId) : undefined}></WarrantyForm>
                <h5 className="mb-1 pt-4">{t('contract.warranty.editRentalObjectsWarranty.title')}</h5>
                <p className="text-secondary mb-4">{t('contract.warranty.editRentalObjectsWarranty.subTitle')}</p>
                {props.hasRentObjects
                    ? props.rentObjects.map((ro) => (
                          <WarrantyForm
                              warranty={props.warranties?.length ? props.warranties.find((w) => w.rentObjectId == ro.id) || undefined : undefined}
                              rentObject={ro}></WarrantyForm>
                      ))
                    : null}
            </div>
        </>
    );
};

const mapState = ({ contract }) => {
    return {
        contractId: lodash.get(contract, 'contract.id'),
        rentObjects: lodash.get(contract, 'rentObjects'),
        warranties: lodash.get(contract, 'contractWarranties'),
        hasRentObjects: lodash.get(contract, 'rentObjects.length') > 0,
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
        deleteWarrantiesIfDraftCall(contractId, portfolioId, propertyId) {
            dispatch(deleteWarrantiesIfDraft(contractId, portfolioId, propertyId));
        },
    };
};

const Warranty = connect(mapState, mapDispatch)(WarrantyComponent);
export default Warranty;
