import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ActionButton from '../../../../components/action-button';

const ConfirmRemoveCustomDuration = (props) => {
    const { t } = useTranslation();

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const onConfirm = () => {
        if (props.onConfirm) {
            props.onConfirm();
        }
    };

    return (
        <Modal wrapClassName="result-modal confirm-delete-modal" visible={true} maskClosable={false} onCancel={onClose} closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <div className="d-flex flex-column">
                        <h4 className="mb-0">{t('contract.durationAndRentalObjects.deleteCustomDuration.title', { name: props.name })}</h4>
                    </div>
                </div>
                <div className="btns">
                    <ActionButton onClick={onClose} className="btn-secondary" text={t('common.buttons.cancel')} />
                    <ActionButton onClick={onConfirm} className="btn-error" text={t('contract.durationAndRentalObjects.deleteCustomDuration.deleteBtnText')} />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmRemoveCustomDuration;
