import { Checkbox, Col, Collapse, DatePicker, Form, Row, Select } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { unitType } from '../../../../common/contract-options';
import PositiveNumericInput from '../../../../components/numeric-input';
import { addRentExemption, setValueContract, updateRentExemption } from '../../../../reducers/contractReducer';
import { durationFormater } from '../../../../helpers/durationFormater';
import { Image } from 'react-bootstrap';
import required from '../../../../assets/images/required.svg';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import ActionButton from '../../../../components/action-button';
import { useParams } from 'react-router';

const { Option } = Select;

const AddRentExemptionBlockComponent = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [currentRentObject, setCurrentRentObject] = useState();
    const [forAll, setForAll] = useState(false);
    const [fullExemption, setFullExemption] = useState(false);
    const [activeKeys, setActiveKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(moment(props.contract.leaseStartDate));
    const [endDate, setEndDate] = useState();
    const { portfolioId, propertyId} = useParams();

    useEffect(() => {
        if (props.rentExemptionModel) {
            const values = { ...props.rentExemptionModel };
            values.startDate = moment(values.startDate).utc(true);
            values.endDate = moment(values.endDate).utc(true);
            values.exemptionAmount = values.fullExemption ? null : values.exemptionAmount;
            setForAll(!values.rentObjectId);
            setFullExemption(values.fullExemption);
            form.setFieldsValue(values);
            setCurrentRentObject(getSelectedRentObject());
        }
    }, []);

    const resetFields = () => {
        form.resetFields();
        setForAll(false);
        setStartDate(moment(props.contract.leaseStartDate));
        setEndDate(moment(props.contract.leaseEndDate));
    };

    const onSubmit = async () => {
        try {
            await form.validateFields();
            setLoading(true);
            const values = form.getFieldsValue();
            values.fullExemption = fullExemption;
            values.startDate = values.startDate.startOf('day').utc(true);
            values.endDate = values.endDate.startOf('day').utc(true);
            resetFields();
            if (props.rentExemptionModel) {
                values.id = props.rentExemptionModel.id;
                return await props.updateRentExemptionCall(values, props.contractId, propertyId, portfolioId);
            } else {
                return await props.addRentExemptionCall(values, props.contractId, propertyId, portfolioId);
            }
        } finally {
            setLoading(false);
        }
    };

    const setExemptionForAll = () => {
        setForAll(!forAll);
        form.setFieldsValue({ rentObjectId: null });
        setCurrentRentObject(null);
        validateDates();
    };

    const setFullExemptionFunc = () => {
        setFullExemption(!fullExemption);
        form.setFieldsValue({ exemptionAmount: null });
    };

    const handleSelectedRentObject = () => {
        validateDates();
        setCurrentRentObject(getSelectedRentObject());
        form.setFieldsValue({ ...form.getFieldsValue(), startDate: getSelectedRentObject()?.leaseStartDate ? moment(getSelectedRentObject()?.leaseStartDate).add(1, 'day') : moment(props.contract.leaseStartDate).add(1, 'day')});
    };

    const validateDates = () => {
        const startDate = form.getFieldValue('startDate');
        const endDate = form.getFieldValue('endDate');

        startDate && form.validateFields(['startDate']);
        endDate && form.validateFields(['endDate']);
    };

    const validateStartDate = (date) => {
        if (!date) {
            return Promise.resolve();
        }
        const leaseStartDate = getLeaseStartDate();
        const endDate = form.getFieldValue('endDate');

        if (leaseStartDate && date.isBefore(leaseStartDate) && !date.utc(true).isSame(leaseStartDate)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.startDate')));
        }
        if (hasOverlapWithExistingExemption(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherRentExemption')));
        }
        if (hasOverlapWithExistingStairIntervals(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherStairRent')));
        }
        if (endDate && date.isAfter(endDate)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.startDate')));
        }

        return Promise.resolve();
    };

    const validateExemptionAmount = (value) => {
        if (currentRentObject && value > currentRentObject.pricePerYearPerUnit) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.exemptionAmount')));
        }
        return Promise.resolve();
    };

    const validateEndDate = (date) => {
        if (!date) {
            return Promise.resolve();
        }
        const leaseEndDate = getLeaseEndDate();
        const startDate = form.getFieldValue('startDate');
        if (leaseEndDate && date.isAfter(leaseEndDate) && !date.utc(true).isSame(leaseEndDate)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.endDate')));
        }
        if (hasOverlapWithExistingExemption(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherRentExemption')));
        }
        if (hasOverlapWithExistingStairIntervals(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherStairRent')));
        }
        if (startDate && startDate?.isAfter(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.endDate')));
        }
        return Promise.resolve();
    };

    const getLeaseStartDate = () => {
        if (!forAll) {
            return currentRentObject ? moment(currentRentObject.leaseStartDate || props.contract.leaseStartDate) : null;
        }
        return moment(props.contract.leaseStartDate);
    };

    const getLeaseEndDate = () => {
        if (!forAll) {
            return currentRentObject ? moment(currentRentObject.leaseEndDate || props.contract.leaseEndDate) : null;
        }
        return moment(props.contract.leaseEndDate);
    };

    const getSelectedRentObject = () => {
        const selectedRentObjectId = form.getFieldValue('rentObjectId');
        return props.rentObjects.find((x) => x.id === selectedRentObjectId);
    };

    const hasOverlapWithExistingExemption = (date) => {
        if (!props.rentExemptions || !props.rentExemptions.length) {
            return false;
        }

        const anotherRentExemptions = props.rentExemptionModel ? props.rentExemptions.filter((x) => x?.id !== props.rentExemptionModel.id && x.rentObjectId == currentRentObject?.id) : props.rentExemptions.filter(item => item.rentObjectId == currentRentObject?.id);

        for (const exemption of anotherRentExemptions) {
            if (date.isBetween(exemption.startDate, exemption.endDate) || date.isSame(exemption.startDate) || date.isSame(exemption.endDate)) {
                return true;
            }
        }

        return false;
    };

    const hasOverlapWithExistingStairIntervals = (date) => {
        if (!props.stairRentIntervals || !props.stairRentIntervals.length) {
            return false;
        }

        for (const interval of props.stairRentIntervals) {
            if (date.isBetween(interval.startDate, interval.endDate, null, '[]') || date.isSame(interval.startDate) || date.isSame(interval.endDate)) {
                return true;
            }
        }

        return false;
    };

    return (
        <Collapse
            activeKey={activeKeys}
            onChange={(values) => setActiveKeys(values)} 
            className='contract-collapse'
            expandIcon={({ isActive }) => isActive ? <div className='collapse-btn btn-transparent' /> : <div className='collapse-btn collapsed btn-transparent' />}>
            <Collapse.Panel className='contract-collapse-item'
                header={<h5 className="mb-0">{t('contract.finances.rentExemptions.addRentExemption')}</h5>}
                key='1'>
                <Form form={form} autoComplete="off" style={{ width: '60%' }}>
                    <Checkbox className="mb-5" checked={forAll} onChange={setExemptionForAll}>
                        {t('contract.finances.rentExemptions.exemptionModal.addForAll')}
                    </Checkbox>
                    <p className="mb-2 font-weight-bold">
                        <Image className="required-img" src={required} />
                        {t('contract.finances.rentExemptions.exemptionModal.rentalName')}
                    </p>
                    <Form.Item
                        className='mb-5'
                        name="rentObjectId"
                        rules={[
                            {
                                required: !forAll,
                                message: t('contract.rentObject') + t('common.isRequired'),
                            },
                        ]}>
                        <Select disabled={forAll} onChange={handleSelectedRentObject} placeholder={t('contract.rentObjectSelectPlaceholder')}>
                            {props.rentObjects.map((item) => (
                                <Option value={item.id || item.uId} key={item.id || item.uId}>
                                    {item.name || item.displayName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div className="d-flex flex-row">
                        {currentRentObject && currentRentObject.rentObjectUnit === unitType.numberOfUnits ? (
                            <p className="mb-2 mr-3 font-weight-bold">
                                <Image className="required-img" src={required} />
                                {t('contract.finances.rentExemptions.exemptionModal.exemptionAmountPerUnit')}
                            </p>
                        ) : (
                            <p className="mb-2 mr-3 font-weight-bold">
                                <Image className="required-img" src={required} />
                                {t('contract.finances.rentExemptions.exemptionModal.exemptionAmountPerSqM')}
                            </p>
                        )}
                        <Checkbox className="mb-3" checked={fullExemption} onChange={setFullExemptionFunc}>
                            {t('contract.finances.rentExemptions.exemptionModal.fullExemption')}
                        </Checkbox>
                    </div>
                    <Form.Item
                        className='mb-5'
                        name="exemptionAmount"
                        rules={[
                            {
                                required: !fullExemption,
                                message: t('contract.finances.rentExemptions.title') + t('common.isRequired'),
                            },
                            {
                                min: 0,
                                type: 'number',
                                max: !forAll ? currentRentObject?.pricePerYearPerUnit : props.rentObjects.map((item) => item?.pricePerYearPerUnit).reduce((a, b) => a + b, 0),
                                message: t('contract.finances.rentExemptions.validation.exemptionAmount'),
                            }
                        ]}>
                        <PositiveNumericInput prefix={t('currency.norway')} disabled={fullExemption} />
                    </Form.Item>
                    <Row className='mb-4 d-flex justify-content-between'>
                        <Col xl={8}>
                            <p className="mb-2 font-weight-bold">{t('common.start')}</p>
                            <Form.Item
                                initialValue={startDate}
                                name="startDate"
                                rules={[
                                    {
                                        required: true,
                                        message: t('common.start') + t('common.isRequired'),
                                    },
                                    {
                                        validator(_, value) {
                                            return validateStartDate(value);
                                        },
                                    },
                                ]}>
                                <DatePicker
                                    dropdownClassName={'new-design-date-picker'}
                                    suffixIcon={<Image src={calendarIcon} />}
                                    format={primaryDateFormat}
                                    onChange={date => setStartDate(date.utc(true))}
                                    allowClear={false}
                                    disabled={!forAll && !currentRentObject}
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={8} className="ml-4">
                            <p className="mb-2 font-weight-bold">{t('common.end')}</p>
                            <Form.Item
                                name="endDate"
                                rules={[
                                    {
                                        required: true,
                                        message: t('common.end') + t('common.isRequired'),
                                    },
                                    {
                                        validator(_, value) {
                                            return validateEndDate(value);
                                        },
                                    },
                                ]}>
                                <DatePicker
                                    dropdownClassName={'new-design-date-picker'}
                                    suffixIcon={<Image src={calendarIcon} />}
                                    format={primaryDateFormat}
                                    onChange={date => setEndDate(date.utc(true))}
                                    allowClear={false}
                                    disabled={!forAll && !currentRentObject}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {startDate && endDate ? (
                        <Col xl={12}>
                            <div className="mb-3">
                                <p className="font-weight-bold">{t('contract.durationAndRentalObjects.contractDates.duration')}</p>
                                <p className="duration">{durationFormater(startDate, endDate)}</p>
                            </div>
                        </Col>
                    ) : null}
                </Form>
                <Row justify="end" className="content-block-actions m-0">
                    <Col className="ml-3">
                        <ActionButton
                            className='content-block-action btn-primary'
                            loading={loading}
                            disabled={form.current && form.current.getFieldsError().filter(({ errors }) => errors.length).length}
                            text={t('common.buttons.add')}
                            onClick={() => {
                                onSubmit().then((isSuccess) => {
                                    setActiveKeys([]);
                                    form.resetFields();
                                });
                            }}
                        />
                    </Col>
                </Row>
            </Collapse.Panel>
        </Collapse>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        contractId: get(contract, 'contract.id'),
        rentExemptionModel: get(contract, 'rentExemptionModel'),
        rentObjects: get(contract, 'rentObjects'),
        rentExemptions: get(contract, 'rentExemptions'),
        rentExemptionLoading: get(contract, 'rentExemptionLoading'),
        stairRentIntervals: get(contract, 'stairRentIntervals'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showRentExemptionModal', false));
            dispatch(setValueContract('rentExemptionModel', null));
        },
        addRentExemptionCall(data, contractId, propertyId, portfolioId) {
            return dispatch(addRentExemption(data, contractId, propertyId, portfolioId));
        },
        updateRentExemptionCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateRentExemption(data, contractId, propertyId, portfolioId));
        },
    };
};

const AddRentExemptionBlock = connect(mapState, mapDispatch)(AddRentExemptionBlockComponent);
export default AddRentExemptionBlock;
