import moment from 'moment';
import React from 'react';

const DateFormatter = (props) => {
    const format = (date, format) => {
        return moment(date).format(format);
    };

    return <>{format(props.date, props.format)}</>;
};

export default DateFormatter;
