import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clearDeleteCommonCostModel, setValueCommonCost } from '../../../reducers/commonCostReducer';
import get from 'lodash-es/get';
import ConfirmDeleteModal from '../../../components/confirm-delete-modal';
import toaster from '../../../common/toaster';

const ConfirmDeleteCommonCostComponent = (props) => {
    const { t } = useTranslation();

    const onClose = (_) => {
        props.closeModalWindow();
        props.clearDeleteCommonCostModelCall();
    };

    const deleteCommonCost = async (_) => {
        if (props.handleDeleteCommonCost) {
            await props.handleDeleteCommonCost();
            onClose();
            toaster.success(t('common.itemDeletedSuccessfully', { name: props.commonCost.name }));
        }
    };

    return (
        <ConfirmDeleteModal
            onClose={onClose}
            onConfirm={deleteCommonCost}
            title={t('commonCost.delete.title', { name: props.commonCost.name })}
            text={t('commonCost.delete.sentence')}
            loading={props.deleteCommonCostLoading}
            deleteBtnText={t('commonCost.delete.deleteBtnText')}
        />
    );
};

const mapState = ({ commonCost }) => {
    return {
        commonCost: get(commonCost, 'deleteCommonCostModel'),
        deleteCommonCostLoading: get(commonCost, 'deleteCommonCostLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        clearDeleteCommonCostModelCall() {
            dispatch(clearDeleteCommonCostModel());
        },
        closeModalWindow() {
            dispatch(setValueCommonCost('showConfirmDeleteCommonCostModal', false));
        },
    };
};

const ConfirmDeleteCommonCost = connect(mapState, mapDispatch)(ConfirmDeleteCommonCostComponent);
export default ConfirmDeleteCommonCost;
