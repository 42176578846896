import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CommonCostTenant from '../../../../components/common-cost/common-cost-tenant-card';

const predefinedTenantColors = ['red-linear-gradient', 'yellow-linear-gradient', 'blue-linear-gradient', 'purple-linear-gradient', 'green-linear-gradient'];

const CommonCostTenantsList = () => {
    const { t } = useTranslation();
    const tenants = useSelector(({ commonCost }) => commonCost.commonCostCalculations.tenants);

    return (
        tenants.length > 0 && (
            <>
                <p className="font-weight-bold">{t('commonCosts.calculations.tenants')}</p>
                {tenants.map((item, index) => (
                    <CommonCostTenant key={item.tenant.id} tenantInfo={item} tenantColor={predefinedTenantColors[index % predefinedTenantColors.length]} />
                ))}
            </>
        )
    );
};

export default CommonCostTenantsList;
