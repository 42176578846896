import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Tabs, Dropdown, Menu, Button } from 'antd';
import { Image } from 'react-bootstrap';
import { archiveContract, deleteContract, getContractAndRelatedTenant, restoreContract, setValueContract } from '../../../reducers/contractReducer';
import routingService from '../../../services/routingService';
import toaster from '../../../common/toaster';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import contractRestoreDisabled from '../../../assets/images/contract-restore-disabled.svg';
import contractArchiveDisabled from '../../../assets/images/contract-archive-disabled.svg';
import contractRestore from '../../../assets/images/contract-restore.svg';
import contractArchive from '../../../assets/images/contract-archive.svg';
import backArrow from '../../../assets/images/back-arrow.svg';
import moreIcon from '../../../assets/images/more.svg';
import DeleteMenuItem from '../../../components/menu-items/delete-menu-item';
import EditMenuItem from '../../../components/menu-items/edit-menu-item';
import ConfirmArchiveContract from '../modals/confirm-archive-modal';
import ConfirmDeleteContract from '../modals/confirm-delete-modal';
import MainLayout from '../../../components/layouts/main-layout';
import ActionButton from '../../../components/action-button';
import NotFoundPage from '../../../components/error-page';
import TooltipBtn from '../../../components/tooltip-btn';
import LoaderSpinner from '../../../components/spinner';
import Events from '../../../components/events';
import RentObjects from './rent-objects';
import ContractDetails from './details';
import NewContractPreviewLayout from './new-contract-preview-layout';
import { filterOptions } from '../../../common/property-options';
import { editPermisionTypes } from '../../../common/constants';

const { TabPane } = Tabs;

export const contractTabs = {
    details: '1',
    events: '2',
    rentObjects: '3',
    getKeyName: function (value) {
        return Object.keys(this).find((key) => this[key] === value) || 'details';
    },
};

const ContractComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId, propertyId, tenantId, contractId, isArchived } = useParams();
    const [currentTabKey, setCurrentTabKey] = useState(contractTabs.details);

    useEffect(() => {
        if (portfolioId && propertyId && tenantId && contractId) {
            props.getContractAndRelatedTenantCall(contractId, tenantId, propertyId, portfolioId, isArchived === 'true' ? filterOptions.active : filterOptions.archived);
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(decodeURI(props.location.search));
        const selectedTab = contractTabs[params.has('tab') ? params.get('tab') : ''];
        if (selectedTab && currentTabKey !== selectedTab) {
            setCurrentTabKey(selectedTab);
        }
    }, []);

    const changeTab = (id) => {
        setCurrentTabKey(id);
        const params = new URLSearchParams(props.location.search);
        const selectedTabName = contractTabs.getKeyName(id);
        params.set('tab', selectedTabName);
        routingService.replace('?' + params.toString());
    };

    const showDeleteContractConfirmModal = (_) => {
        if (!props.hasEditPermission) return;
        props.setContractToDelete(props.contract);
    };

    const openContractOnEdit = (_) => {
        if (!props.hasEditPermission) return;
        routingService.navigateToEditContract(portfolioId, propertyId, tenantId, contractId);
    };

    const showArchiveContractConfirmModal = (_) => {
        if (!props.hasEditPermission) return;
        props.setContractToArchive(props.contract);
    };

    const restoreContractFunc = (_) => {
        if (!props.hasEditPermission) return;
        props.restoreContractCall(props.contract.id, portfolioId, propertyId).then((isSuccess) => {
            if (isSuccess) {
                toaster.success(t('contract.itemRestoredSuccessfully', { name: props.contract.name }));
                const contract = { ...props.contract };
                contract.isArchived = false;
                props.setValueContractCall('contract', contract);
            }
        });
    };

    const handleDeleteContract = (_) => {
        if (!props.hasEditPermission) return;
        props.deleteContractCall(props.contract.id, portfolioId, propertyId).then((isDeleted) => {
            if (isDeleted) {
                props.closeDeleteContractModalWindow();
                props.setValueContractCall('contract', null);
                routingService.navigateToTenantContracts(portfolioId, propertyId, tenantId);
                toaster.success(t('common.itemDeletedSuccessfully', { name: props.contract.name }));
            }
        });
    };

    const handleArchiveContract = (_) => {
        if (!props.hasEditPermission) return;
        props.archiveContractCall(props.contract.id, portfolioId, propertyId).then((isSuccess) => {
            if (isSuccess) {
                toaster.success(t('contract.itemArchivedSuccessfully', { name: props.contract.name }));
                props.closeArchiveContractModalWindow();
                const contract = { ...props.contract };
                contract.isArchived = true;
                props.setValueContractCall('contract', contract);
            }
        });
    };

    const archiveMenuItem = (
        <Menu.Item key="0" disabled={!props.hasEditPermission} onClick={showArchiveContractConfirmModal}>
            <ActionButton text={t('contract.archiveContract')} icon={<Image className="ml-2" src={!props.hasEditPermission ? contractArchiveDisabled : contractArchive} />} />
        </Menu.Item>
    );

    const restoreMenuItem = (
        <Menu.Item key="1" onClick={restoreContractFunc} disabled={!props.hasEditPermission}>
            <ActionButton text={t('contract.restoreContract')} icon={<Image className="ml-2" src={!props.hasEditPermission ? contractRestoreDisabled : contractRestore} />} />
        </Menu.Item>
    );

    const dropdownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                {!(props.contract.isDraft || props.contract.isArchived) && (
                    <React.Fragment>
                        {!props.hasEditPermission ? (
                            <TooltipBtn title={t('common.noPermission')} placement="right">
                                {archiveMenuItem}
                            </TooltipBtn>
                        ) : (
                            archiveMenuItem
                        )}
                    </React.Fragment>
                )}
                {props.contract.isArchived && (
                    <React.Fragment>
                        {!props.hasEditPermission ? (
                            <TooltipBtn title={t('common.noPermission')} placement="right">
                                {restoreMenuItem}
                            </TooltipBtn>
                        ) : (
                            restoreMenuItem
                        )}
                    </React.Fragment>
                )}
                <EditMenuItem key="2" onClick={openContractOnEdit} disabled={!props.hasEditPermission} text={t('contract.editContract')} />
                <DeleteMenuItem key="3" onClick={showDeleteContractConfirmModal} disabled={!props.hasEditPermission} text={t('contract.deleteContract')} />
            </Menu>
        );
    };

    return (
        <MainLayout>
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.contractNotFound ? <NotFoundPage header={t('contract.notFoundContract')} /> : null}
            {!props.portfolioNotFound && !props.propertyNotFound && !props.contractNotFound ? (
                !props.contractAndTenantLoading && !props.navigationLoading && props.contract ? (
                    <div className="details-page">
                        <div className="title-container">
                            <div className="title">
                                <Link to={routingService.tenantContractsUrl(portfolioId, propertyId, tenantId)}>
                                    <Image className="m-0" src={backArrow} />
                                </Link>
                                <h3 className="mb-0">{props.contract.name}</h3>
                                <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu()}>
                                    <Button className="btn-link px-0">
                                        <Image className="m-0" src={moreIcon} />
                                    </Button>
                                </Dropdown>
                            </div>
                        </div>
                        <Tabs activeKey={currentTabKey} onChange={changeTab} className='contract-preview-tabs'>
                            <TabPane tab={t('contract.contractDetails.details')} key={contractTabs.details}>
                                <NewContractPreviewLayout />
                            </TabPane>
                            <TabPane tab={t('contract.contractDetails.events')} key={contractTabs.events} className="p-5">
                                <Events />
                            </TabPane>
                            <TabPane tab={t('contract.contractDetails.rentObjects')} key={contractTabs.rentObjects}>
                                <RentObjects />
                            </TabPane>
                        </Tabs>
                    </div>
                ) : (
                    <LoaderSpinner />
                )
            ) : null}
            {props.confirmDeleteContract && <ConfirmDeleteContract onConfirm={handleDeleteContract} />}
            {props.confirmArchiveContract && <ConfirmArchiveContract onConfirm={handleArchiveContract} />}
        </MainLayout>
    );
};

const mapState = ({ contract, portfolio, property, navigation }) => {
    return {
        contract: get(contract, 'contract'),
        contractAndTenantLoading: get(contract, 'contractAndTenantLoading'),

        confirmDeleteContract: !!get(contract, 'contractToDelete'),
        confirmArchiveContract: !!get(contract, 'contractToArchive'),

        contractNotFound: get(contract, 'contractNotFound'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        contracts: get(contract, 'portfolioContracts')
    };
};

const mapDispatch = (dispatch) => {
    return {
        getContractAndRelatedTenantCall(contractId, tenantId, propertyId, portfolioId, status) {
            dispatch(getContractAndRelatedTenant(contractId, tenantId, propertyId, portfolioId, status));
        },
        setValueContractCall(key, props) {
            dispatch(setValueContract(key, props));
        },
        setContractToDelete(contract) {
            dispatch(setValueContract('contractToDelete', contract));
        },
        setContractToArchive(contract) {
            dispatch(setValueContract('contractToArchive', contract));
        },
        closeDeleteContractModalWindow() {
            dispatch(setValueContract('contractToDelete', null));
        },
        closeArchiveContractModalWindow() {
            dispatch(setValueContract('contractToArchive', null));
        },
        deleteContractCall(contractId, portfolioId, propertyId) {
            return dispatch(deleteContract(contractId, portfolioId, propertyId));
        },
        archiveContractCall(contractId, portfolioId, propertyId) {
            return dispatch(archiveContract(contractId, portfolioId, propertyId));
        },
        restoreContractCall(contractId, portfolioId, propertyId) {
            return dispatch(restoreContract(contractId, portfolioId, propertyId));
        },
    };
};

const Contract = clear(connect(mapState, mapDispatch)(ContractComponent));
export default Contract;
