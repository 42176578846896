import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import success from '../../assets/images/success-download.svg';
import pdfIcon from '../../assets/images/pdf-icon.svg';
import docIcon from '../../assets/images/txt-icon.svg';
import xlsIcon from '../../assets/images/xls-icon.svg';
import gifIcon from '../../assets/images/gif-icon.svg';
import pptIcon from '../../assets/images/ppt-icon.svg';
import mkvIcon from '../../assets/images/mkv-icon.svg';
import psdIcon from '../../assets/images/psd-icon.svg';
import aiIcon from '../../assets/images/ai-icon.svg';
import jpgIcon from '../../assets/images/jpg-icon.svg';
import svgIcon from '../../assets/images/svg-icon.svg';
import close from '../../assets/images/close-button-icon.svg';
import { maxPercents } from '../../common/constants';
import TextEllipsis from '../ellipsis-text';

export const fileExtensions = {
    pdf: '.pdf',
    word: '.doc',
    wordX: '.docx',
    xls: '.xls',
    gif: '.gif',
    ppt: '.ppt',
    mkv: '.mkv',
    psd: '.psd',
    ai: '.ai',
    jpg: '.jpg',
    svg: '.svg'
};

const UploadedFile = (props) => {
    const { t } = useTranslation();

    const bytesToSize = (bytes) => {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const sizeIndex = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, sizeIndex), 2) + ' ' + sizes[sizeIndex];
    };

    const renderFileFormatIcon = () => {
        const extension = props.file.name.substr(props.file.name.lastIndexOf('.'));
        switch (extension) {
            case fileExtensions.pdf:
                return <Image src={pdfIcon} onClick={props.handleDownload} className="extension-icon" />;

            case fileExtensions.word:
                return <Image src={docIcon} onClick={props.handleDownload} className="extension-icon" />;

            case fileExtensions.wordX:
                return <Image src={docIcon} onClick={props.handleDownload} className="extension-icon" />;

            case fileExtensions.xls:
                return <Image src={xlsIcon} onClick={props.handleDownload} className="extension-icon" />;

            case fileExtensions.gif:
                return <Image src={gifIcon} onClick={props.handleDownload} className="extension-icon" />;

            case fileExtensions.ppt:
                return <Image src={pptIcon} onClick={props.handleDownload} className="extension-icon" />;

            case fileExtensions.mkv:
                return <Image src={mkvIcon} onClick={props.handleDownload} className="extension-icon" />;

            case fileExtensions.psd:
                return <Image src={psdIcon} onClick={props.handleDownload} className="extension-icon" />;

            case fileExtensions.ai:
                return <Image src={aiIcon} onClick={props.handleDownload} className="extension-icon" />;

            case fileExtensions.jpg:
                return <Image src={jpgIcon} onClick={props.handleDownload} className="extension-icon" />;

            case fileExtensions.svg:
                return <Image src={svgIcon} onClick={props.handleDownload} className="extension-icon" />;
            default:
                return <p onClick={props.handleDownload}>{props.file.name.substr(props.file.name.lastIndexOf('.'))}</p>;
        }
    };

    return (
        <div className="upload-item" key={props.file.name} style={props.style}>
            <div className="extension">
                {renderFileFormatIcon()}
            </div>
            <div className="info">
                <div className="name">
                    <TextEllipsis>
                        <p className="ellipsis">{props.file.name}</p>
                    </TextEllipsis>
                </div>
                <div className="size">
                    <p>{bytesToSize(props.file.fileSizeInBytes || props.file.size)}</p>
                </div>
                {props.file.percent ? (
                    <React.Fragment>
                        {props.file.percent === maxPercents ? (
                            <div className="d-flex flex-row successful-upload">
                                <p className="mr-1">{t('successfullyUploaded')}</p>
                                <Image src={success} />
                            </div>
                        ) : (
                            <div className="d-flex flex-row align-items-center">
                                <p className="text-secondary mr-2">{t('uploading')}</p>
                                <p className="text-secondary mr-1">{`${props.file.percent}%`}</p>
                                <LoadingOutlined className="text-secondary primary-icon" />
                            </div>
                        )}
                    </React.Fragment>
                ) : null}
            </div>
            {props.file.id ? <Image src={close} onClick={() => props.onDeleteFileFunc(props.file)} /> : null}
        </div>
    );
};

export default UploadedFile;
