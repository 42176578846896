import { Divider } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ActionButton from '../../../components/action-button';
import CommonCostMergedItemReview from '../../../components/common-cost/cost-item-merged-review';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import NumberFormat from '../../../components/number-format';
import PropertyRow from '../../../components/property-row';
import SuccessMessage from '../../../components/success-message';
import ownerCostService from '../../../services/ownerCostService';
import { ownerCostSelector, selectFilteredActualCosts, selectFilteredBudgetCosts } from '../selectors';

const OwnerCostEditReview = (props) => {
    const { t } = useTranslation();
    const ownerCost = useSelector(ownerCostSelector);
    const ownerCostBudgets = useSelector(selectFilteredBudgetCosts);
    const ownerCostActuals = useSelector(selectFilteredActualCosts);
    const propertyName = useSelector(({ property }) => property.property.propertyName);
    const ownerCostMergedItems = ownerCostService.mergeBudgetAndActualItems(ownerCostBudgets, ownerCostActuals);

    const goToStep = (stepKey) => {
        props.goToStep(stepKey);
    };

    const isValidValue = (value) => value !== null && value !== undefined;

    const showActualAdjustmentAmount = isValidValue(ownerCost.actualLandlordAdjustment);
    const showActualAdjustmentPercent = isValidValue(ownerCost.actualLandlordAdjustmentPercent);
    const showFooterBlock = showActualAdjustmentAmount || showActualAdjustmentPercent;

    return (
        <div className="page-container review">
            <div className="review-part" key={'details'}>
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                    <h5>{t('commonCost.review.costDetails')}</h5>
                    <ActionButton className="btn-link" onClick={() => goToStep('details')} text={t('common.buttons.edit')} />
                </div>
                <PropertyRow title={t('commonCost.review.costDetails.costName')} value={ownerCost.name} className="common-cost-review-details-item" />
                <PropertyRow
                    title={t('commonCost.review.costDetails.costPeriod')}
                    value={`${moment(ownerCost.startDate).format(primaryDateFormat)} - ${moment(ownerCost.endDate).format(primaryDateFormat)}`}
                    className="common-cost-review-details-item"
                />
                <PropertyRow title={t('commonCost.review.costDetails.assignedTo')} value={propertyName} className="common-cost-review-details-item" />
                <PropertyRow
                    title={t('commonCost.review.costDetails.grossArea')}
                    value={ownerCost.grossArea + t('commonCost.tenantCard.m2')}
                    className="common-cost-review-details-item"
                    style={{ marginBottom: '45px' }}
                />
                <div className="review-items-wrapper">
                    {ownerCostMergedItems.map((item) => (
                        <CommonCostMergedItemReview key={item.key} item={item} goToStep={goToStep} />
                    ))}
                    {showFooterBlock && (
                        <>
                            <Divider className="common-cost-items-review-divider" />
                            <PropertyRow
                                title={t('commonCost.review.costDetails.totalBudget')}
                                value={<NumberFormat value={ownerCost.calculatedBudgetTotalAmount} />}
                                className="common-cost-review-merged-item"
                            />
                            <PropertyRow
                                title={t('commonCost.review.costDetails.totalActual')}
                                value={<NumberFormat value={ownerCost.calculatedActualTotalAmount} />}
                                className="common-cost-review-merged-item"
                            />
                        </>
                    )}
                </div>
                <SuccessMessage textElements={<><p className="font-weight-bold">{t('commonCost.review.successMessage.looksGood')}</p><p>{t('commonCost.review.successMessage.notification')}</p></>}/>
            </div>
        </div>
    );
};

export default OwnerCostEditReview;
