import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clearDeleteOwnerCostModel, setValueOwnerCost } from '../../../reducers/ownerCostReducer';
import get from 'lodash-es/get';
import ConfirmDeleteModal from '../../../components/confirm-delete-modal';
import toaster from '../../../common/toaster';

const ConfirmDeleteOwnerCostComponent = (props) => {
    const { t } = useTranslation();

    const onClose = (_) => {
        props.closeModalWindow();
        props.clearDeleteOwnerCostModelCall();
    };

    const deleteOwnerCost = async (_) => {
        if (props.handleDeleteOwnerCost) {
            await props.handleDeleteOwnerCost();
            onClose();
            toaster.success(t('common.itemDeletedSuccessfully', { name: props.ownerCost.name }));
        }
    };

    return (
        <ConfirmDeleteModal
            onClose={onClose}
            onConfirm={deleteOwnerCost}
            title={t('commonCost.delete.title', { name: props.ownerCost.name })}
            text={t('ownerCost.delete.sentence')}
            loading={props.deleteOwnerCostLoading}
            deleteBtnText={t('ownerCost.delete.deleteBtnText')}
        />
    );
};

const mapState = ({ ownerCost }) => {
    return {
        ownerCost: get(ownerCost, 'deleteOwnerCostModel'),
        deleteOwnerCostLoading: get(ownerCost, 'deleteOwnerCostLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        clearDeleteOwnerCostModelCall() {
            dispatch(clearDeleteOwnerCostModel());
        },
        closeModalWindow() {
            dispatch(setValueOwnerCost('showConfirmDeleteOwnerCostModal', false));
        },
    };
};

const ConfirmDeleteOwnerCost = connect(mapState, mapDispatch)(ConfirmDeleteOwnerCostComponent);
export default ConfirmDeleteOwnerCost;
