import axios from 'axios';

const loginService = {
    getToken: (email, password) => {
        return axios.post(`/api/access/token`, {
            email,
            password,
        });
    },

    refreshToken: (refreshModel) => {
        return axios.post(`/api/access/refreshToken`, refreshModel);
    },
};

export default loginService;
