import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import building from '../../assets/images/new-building-card-icon.svg';
import moreIcon from '../../assets/images/more.svg';
import TextEllipsis from '../ellipsis-text';
import DeleteMenuItem from '../menu-items/delete-menu-item';
import EditMenuItem from '../menu-items/edit-menu-item';

const BuildingCard = (props) => {
    const { t } = useTranslation();

    const openBuilding = () => {
        props.openBuilding(props.building.id);
    };

    const editBuilding = () => {
        if (props.editBuilding) {
            props.editBuilding(props.building);
        }
    };

    const deleteBuilding = () => {
        if (props.deleteBuilding) {
            props.deleteBuilding(props.building);
        }
    };

    const dropdownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" onClick={editBuilding} disabled={!props.hasEditPermission || props.isArchived} text={t('building.editBuilding')} />
                <DeleteMenuItem key="1" onClick={deleteBuilding} disabled={!props.hasEditPermission || props.isArchived} text={t('building.deleteBuilding')} />
            </Menu>
        );
    };

    return (
        <div className="entity-card card-wrapper">
            <div className="image" onClick={openBuilding}>
                <Image src={building} />
            </div>
            <div className="card-content">
                <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                    <TextEllipsis>
                        <h6 className="ellipsis">{props.building.name || props.building.address}</h6>
                    </TextEllipsis>
                    <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu()}>
                        <Button className="btn-link btn-transparent settings-btn">
                            <Image src={moreIcon} />
                        </Button>
                    </Dropdown>
                </div>
                {props.building.assignedPlots.map((plot) => (
                    <p className="mb-3" key={plot.id}>
                        {plot.mainAddress}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default BuildingCard;
