import { Col, Form, Row, Switch } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { cpiIndexTypes } from '../../../../common/contract-options';
import RentAdjustmentOptions from '../../../../components/contract/rent-adjustment/general';
import RentAdjustmentOnHandover from '../../../../components/contract/rent-adjustment/on-handover';
import RentAdjustmentSettings from '../../../../components/contract/rent-adjustment/settings';
import { defaultRentAdjustmentOptions, updateRentObjectRentAdjustmentOptions } from '../../../../reducers/contractReducer';
import ActionButton from '../../../../components/action-button';
import { useParams } from 'react-router';

const RentAdjustmentRentalObjectCardComponent = (props) => {
    const { t } = useTranslation();

    const editRentObjectRef = React.useRef();

    const { portfolioId, propertyId } = useParams();

    const [loading, setLoading] = useState(false);
    const [cpiAdjust, setCpiAdjust] = useState(true);

    useEffect(() => {
        if (props.rentObject) {
            const defaultOptions = { ...defaultRentAdjustmentOptions };
            let {
                originalBaseIndexDate,
                yearlyCpiIndexDate,
                firstTimeCpiAdjustmentDate,
                yearlyCpiAdjustmentDate,
                cpiIndexType,
                cpiRate,
                cpiSource,
                yearlyTenantReminderOnCpiAdjustment,
                lastKnownCpiRegulation,
                cpiAdjustmentOnHandover,
                hasOverridedRentAdjustmentOptions,
            } = props.rentObject;

            originalBaseIndexDate = originalBaseIndexDate ? moment(originalBaseIndexDate).utc(true) : null;
            yearlyCpiIndexDate = yearlyCpiIndexDate ? moment(yearlyCpiIndexDate).utc(true) : defaultOptions.yearlyCpiIndexDate;
            firstTimeCpiAdjustmentDate = firstTimeCpiAdjustmentDate ? moment(firstTimeCpiAdjustmentDate).utc(true) : null;
            yearlyCpiAdjustmentDate = yearlyCpiAdjustmentDate ? moment(yearlyCpiAdjustmentDate).utc(true) : defaultOptions.yearlyCpiAdjustmentDate;
            cpiRate = cpiRate || defaultOptions.cpiRate;
            cpiSource = cpiSource || defaultOptions.cpiSource;
            cpiIndexType = cpiIndexType || cpiIndexTypes.floating;
            hasOverridedRentAdjustmentOptions = hasOverridedRentAdjustmentOptions != undefined ? hasOverridedRentAdjustmentOptions : true;
            setCpiAdjust(hasOverridedRentAdjustmentOptions);

            editRentObjectRef.current.setFieldsValue({
                originalBaseIndexDate,
                yearlyCpiIndexDate,
                firstTimeCpiAdjustmentDate,
                yearlyCpiAdjustmentDate,
                cpiIndexType,
                cpiRate,
                cpiSource,
                yearlyTenantReminderOnCpiAdjustment,
                lastKnownCpiRegulation,
                cpiAdjustmentOnHandover,
                hasOverridedRentAdjustmentOptions,
            });
        }
    }, []);

    const done = () => {
        return editRentObjectRef.current.validateFields().then(() => {
            const rentObject = editRentObjectRef.current.getFieldsValue();
            rentObject.hasRentAdjustmentOptions = rentObject.hasOverridedRentAdjustmentOptions;
            if (rentObject.hasOverridedRentAdjustmentOptions) {
                rentObject.originalBaseIndexDate = rentObject.originalBaseIndexDate.startOf('day').utc(true);
                rentObject.yearlyCpiIndexDate = rentObject.yearlyCpiIndexDate.startOf('day').utc(true);
                rentObject.firstTimeCpiAdjustmentDate = rentObject.firstTimeCpiAdjustmentDate.startOf('day').utc(true);
                rentObject.yearlyCpiAdjustmentDate = rentObject.yearlyCpiAdjustmentDate.startOf('day').utc(true);
            }
            setLoading(true);
            props.updateRentObjectRentAdjustmentOptionsCall(rentObject, props.rentObject.id, props.contract.id, portfolioId, propertyId).then(() => {
                setLoading(false);
            });
        });
    };

    return (
        <Form name="basic" autoComplete="off" ref={editRentObjectRef}>
            <div className="top-part">
                <div className="page-container mb-2 pb-0">
                    <Row>
                        <Col span={6}>
                            <p>{t('contract.rentAdjustment.cpiAdjustment')}</p>
                            <p>{cpiAdjust ? 'On' : 'Off'}</p>
                        </Col>
                        <Col>
                            <Form.Item name="hasOverridedRentAdjustmentOptions" valuePropName="checked">
                                <Switch checked={cpiAdjust} onChange={(checked) => setCpiAdjust(checked)} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <RentAdjustmentOptions formRef={editRentObjectRef} rentObject={props.rentObject} cpiAdjustment={cpiAdjust} />
            </div>
            <div className="part">
                <RentAdjustmentOnHandover rentObject={props.rentObject} cpiAdjustment={cpiAdjust} />
            </div>
            <div className="part">
                <RentAdjustmentSettings rentObject={props.rentObject} cpiAdjustment={cpiAdjust} />
            </div>
            <Row justify="end" className="content-block-actions m-0">
                <Col className="ml-3">
                    <ActionButton
                        className='content-block-action btn-primary'
                        loading={loading}
                        disabled={editRentObjectRef.current && editRentObjectRef.current.getFieldsError().filter(({ errors }) => errors.length).length}
                        text={t('common.buttons.done')}
                        onClick={() => {
                            done().then(() => {
                                props.setActiveKeys(prev => prev.filter(item => item != props.rentObject.id))
                            });
                        }}
                    />
                </Col>
            </Row>
        </Form>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        rentObjects: get(contract, 'rentObjects'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        updateRentObjectRentAdjustmentOptionsCall(value, id, contractId, portfolioId, propertyId) {
            return dispatch(updateRentObjectRentAdjustmentOptions(value, id, contractId, portfolioId, propertyId));
        },
    };
};

const RentAdjustmentRentalObjectCard = connect(mapState, mapDispatch)(RentAdjustmentRentalObjectCardComponent);
export default RentAdjustmentRentalObjectCard;
