import { Checkbox, Form, Select } from 'antd';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { rentObjectType, rentObjectTypeOptions, typeOfYearlyRentOptions, unitType } from '../../../../common/contract-options';
import PositiveNumericInput from '../../../../components/numeric-input';
import TurnoverRent from '../turnover';
import { Image } from 'react-bootstrap';
import required from '../../../../assets/images/required.svg';

const { Option } = Select;

const RentalObjectFinanceCard = (props) => {
    const { t } = useTranslation();
    const [isTurnover, setIsTurnover] = useState(props.rentObject.rentObjectType > 0 ? props.rentObject.rentObjectType == rentObjectType.turnoverRentalObject : false);
    const [selectedTypeOfYearlyRent, setSelectedTypeOfYearlyRent] = useState(typeOfYearlyRentOptions.perUnit);

    const setTypeOfYearlyRent = (value) => {
        if (props.setTypeOfYearlyRent) {
            props.setTypeOfYearlyRent(props.rentObject.uId, selectedTypeOfYearlyRent, value);
        }
    };

    const onChangeRentObjectType = (value) => {
        setIsTurnover(value == rentObjectType.turnoverRentalObject);

        if (props.setRentObjectType) {
            props.setRentObjectType(props.rentObject.uId, value);
        }
    };

    const onChangeHasVatCalculation = (value) => {
        if (props.setHasVatCalculation) {
            props.setHasVatCalculation(props.rentObject.uId, value);
        }
    };

    const showRentFieldTitle = () => {
        return selectedTypeOfYearlyRent === typeOfYearlyRentOptions.perUnit
            ? props.rentObject.rentObjectUnit && props.rentObject.rentObjectUnit === unitType.squareMetre
                ? t('contract.finances.rentObject.rent.rentPrM2Title')
                : t('contract.finances.rentObject.rent.rentPrUnitTitle')
            : t('contract.finances.rentObject.rent.rentTotalAmountTitle');
    };

    return (
        <div className="edit-rent-object-property-card" style={{ backgroundColor: 'inherit' }}>
            <div className="d-flex flex-row justify-content-between">
                <h5 className="mb-0">{props.rentObject.displayName}</h5>
            </div>
            <div className="rent-object-content">
                <Row>
                    <Col lg={12} xl={6}>
                        <p className="mb-2 font-weight-bold">
                            <Image className="required-img" src={required} />
                            {t('contract.finances.rentObject.type.title')}
                        </p>
                        <Form.Item
                            name={`rentObjectType-${props.rentObject.uId}`}
                            initialValue={props.rentObject.rentObjectType > 0 ? props.rentObject.rentObjectType : null}
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.finances.rentObject.type.title') + t('common.isRequired'),
                                },
                            ]}>
                            <Select
                                onChange={(e) => onChangeRentObjectType(e)}
                                placeholder={t('contract.finances.rentObject.type.placeholder')}
                                dropdownClassName="new-design-dropdown">
                                {rentObjectTypeOptions.map((item) => (
                                    <Option value={item.id} key={item.id} disabled={item.disabled}>
                                        {item.name()}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {!isTurnover && (
                            <>
                                <p className="mb-2 font-weight-bold">
                                    <Image className="required-img" src={required} />
                                    {showRentFieldTitle()}
                                </p>
                                <div className="pre-select-input-wrapper">
                                    <Form.Item
                                        className={`${selectedTypeOfYearlyRent === typeOfYearlyRentOptions.perUnit ? 'd-block' : 'd-none'} pre-input`}
                                        initialValue={props.rentObject.pricePerYearPerUnit}
                                        name={`pricePerYearPerUnit-${props.rentObject.uId}`}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    t(
                                                        props.rentObject.rentObjectUnit === unitType.squareMetre
                                                            ? 'contract.finances.rentObject.rent.radioRentPrM2'
                                                            : 'contract.finances.rentObject.rent.radioRentPrUnit'
                                                    ) + t('common.isRequired'),
                                            },
                                        ]}>
                                        <PositiveNumericInput
                                            prefix={t('currency.norway')}
                                            onChange={setTypeOfYearlyRent}
                                            inputValue={props.rentObject.pricePerYearPerUnit}
                                            placeholder={
                                                props.rentObject.rentObjectUnit === unitType.squareMetre
                                                    ? t('contract.finances.rentObject.rent.placeholderRentPrM2')
                                                    : t('contract.finances.rentObject.rent.placeholderRentPrUnit')
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className={`${selectedTypeOfYearlyRent === typeOfYearlyRentOptions.totatAmount ? 'd-block' : 'd-none'} pre-input`}
                                        initialValue={props.rentObject.totalPricePerYear}
                                        name={`totalPricePerYear-${props.rentObject.uId}`}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('contract.finances.rentObject.rent.radioRentTotalAmount') + t('common.isRequired'),
                                            },
                                        ]}>
                                        <PositiveNumericInput
                                            prefix={t('currency.norway')}
                                            onChange={setTypeOfYearlyRent}
                                            inputValue={props.rentObject.totalPricePerYear}
                                            placeholder={t('contract.finances.rentObject.rent.placeholderRentTotalAmount')}
                                        />
                                    </Form.Item>
                                    <Form.Item className="pre-select">
                                        <Select
                                            onChange={(e) => setSelectedTypeOfYearlyRent(e)}
                                            defaultValue={selectedTypeOfYearlyRent}
                                            placeholder={t('contract.finances.rentObject.type.placeholder')}
                                            dropdownClassName="new-design-dropdown">
                                            <Option value={typeOfYearlyRentOptions.perUnit} key={1}>
                                                {props.rentObject.rentObjectUnit === unitType.squareMetre
                                                    ? t('contract.finances.rentObject.rent.radioRentPrM2')
                                                    : t('contract.finances.rentObject.rent.radioRentPrUnit')}
                                            </Option>
                                            <Option value={typeOfYearlyRentOptions.totatAmount} key={2}>
                                                {t('contract.finances.rentObject.rent.totalAmount')}
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </>
                        )}
                    </Col>
                    <Col xl={6} style={{ alignSelf: 'flex-end' }}>
                        <Form.Item
                            className="is-calculate-vat"
                            name={`hasVatCalculation-${props.rentObject.uId}`}
                            initialValue={props.rentObject.hasVatCalculation}
                            valuePropName="checked">
                            <Checkbox onChange={(e) => onChangeHasVatCalculation(e.target.checked)} className="mb-0">
                                {t('contract.finances.rentObject.rent.isCalculateVat')}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                {isTurnover && <TurnoverRent rentObject={props.rentObject} />}
            </div>
        </div>
    );
};

export default RentalObjectFinanceCard;
