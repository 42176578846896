import { TimePicker } from 'antd';
import React from 'react';

const CustomTimePicker = (props) => {
    const onSelect = (value) => {
        props.onChange(value);
        props.onSelect && props.onSelect(value);
    };

    return <TimePicker onSelect={onSelect} {...props} />;
};

export default CustomTimePicker;
