import { Switch } from 'antd';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import commonCostBudgetImg from '../../assets/images/clock.svg';
import commonCostActualImg from '../../assets/images/common-cost-actual.svg';
import commonCostGrowAreaImg from '../../assets/images/common-cost-grow-area.svg';
import ownerCostService from '../../services/ownerCostService';
import CurrencyFormat from '../currency-format';
import NumberFormat from '../number-format';

const OwnerCostAnalysisDetailsComponent = () => {
    const { t } = useTranslation();
    const [showPerM2, setShowPerM2] = useState();
    const ownerCostDetails = useSelector(({ ownerCost }) => ownerCost.ownerCostDetails);
    const ownerCost = useSelector(({ ownerCost }) => ownerCost.ownerCost);

    const showDetailsRow = (img, title, value) => {
        value = showPerM2 ? value / ownerCostDetails.grossArea : value;

        return title && value ? (
            <div className="details-row">
                <div className="d-flex flex-row">
                    <Image src={img} />
                    <p>{title}</p>
                </div>
                <span className="text">
                    <CurrencyFormat value={value} />
                    <span className="ml-1">{showPerM2 && t('contract.unitTypes.m2')}</span>
                </span>
            </div>
        ) : null;
    };

    const showAreaRow = (img, title, value) => {
        return title && value ? (
            <div className="details-row">
                <div className="d-flex flex-row">
                    <Image src={img} />
                    <p>{title}</p>
                </div>
                <span className="text">
                    <NumberFormat value={value} />
                    <span className="ml-1">{t('contract.unitTypes.m2')}</span>
                </span>
            </div>
        ) : null;
    };

    return (
        <>
            <p className="font-weight-bold">{t('commonCost.analysisDetails.title')}</p>
            {ownerCostDetails && (
                <div className="common-cost-analysis-details">
                    <div className="details-header">
                        <p className="mr-2 mb-0">{t('commonCost.analysisDetails.switch')}</p>
                        <Switch checked={showPerM2} onChange={() => setShowPerM2(!showPerM2)} />
                    </div>
                    {showDetailsRow(commonCostBudgetImg, t('commonCost.analysisDetails.budget'), ownerCostDetails.budgetTotal)}
                    {ownerCost.hasAnyActuals
                        ? showDetailsRow(commonCostActualImg, t('commonCost.analysisDetails.actual'), ownerCostService.actualsTotal(ownerCost.ownerCostActuals))
                        : null}
                    {ownerCostDetails && showAreaRow(commonCostGrowAreaImg, t('commonCost.analysisDetails.grossArea'), ownerCostDetails.grossArea)}
                </div>
            )}
        </>
    );
};

export default OwnerCostAnalysisDetailsComponent;
