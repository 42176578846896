import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import uuidV4 from 'uuid/v4';

const DefaultTooltip = ({ title, placement = 'top', children }) => {
    return (
        <React.Fragment>
            {title ? (
                <OverlayTrigger
                    delay={{ show: 200, hide: 150 }}
                    placement={window.innerWidth > 590 ? placement : 'top'}
                    flip={true}
                    overlay={
                        <Tooltip className="btn-tooltip" id={`tooltip-${uuidV4()}`}>
                            <div className="d-flex flex-row">{title && <p className="tooltip-text">{title}</p>}</div>
                        </Tooltip>
                    }>
                    {children}
                </OverlayTrigger>
            ) : (
                children
            )}
        </React.Fragment>
    );
};

export default DefaultTooltip;
