import React from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyFormat from '../currency-format';
import PropertyRow from '../property-row';

const CommonCostItemReview = ({ cost, goToStep }) => {
    const { t } = useTranslation();

    return (
        <PropertyRow
            title={`${cost.name} ${t(cost.isBudget ? 'common.budget' : 'common.actual')}`}
            value={<CurrencyFormat style={{display: 'inline'}}  value={cost.value} />}
            className="common-cost-review-merged-item"
        />
    );
};

export default CommonCostItemReview;
