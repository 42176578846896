import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { StatusCodes } from 'http-status-codes';
import { setValueTenant } from './tenantReducer';
import { setValueProperty } from './propertyReducer';
import { contractStatus, cpiSourceOptions, typeOfYearlyRentOptions } from '../common/contract-options';
import contractDetailsService from '../services/contractDetailsService';
import rentObjectService from '../services/rentObjectService';
import contractService from '../services/contractService';
import toaster from '../common/toaster';
import update from '../helpers/update';
import moment from 'moment';
import { maxPercents } from '../common/constants';

var fileDownload = require('js-file-download');

const SET_VALUE_CONTRACT = 'SET_VALUE_CONTRACT';
const SET_ERROR_CONTRACT = 'SET_ERROR_CONTRACT';
const SUCCESS_CALCULATED_INVOICES = 'SUCCESS_CALCULATED_INVOICES';
const SUCCESS_DELETE_CONTRACT = 'SUCCESS_DELETE_CONTRACT';
const SET_LIST_OF_UPLOAD_ITEMS_CONTRACT = 'SET_LIST_OF_UPLOAD_ITEMS_CONTRACT';
const SETCONTRACT_DURATION_AND_RENTAL_OBJECTS = 'SETCONTRACT_DURATION_AND_RENTAL_OBJECTS';
const SET_PROPERTY_TO_RENTAL_OBJECT = 'SET_PROPERTY_TO_RENTAL_OBJECT';

export const defaultRentalObject = {
    uId: null,
    rentObjectHasName: false,
    hasCustomDuration: false,
    typeOfYearlyRent: typeOfYearlyRentOptions.perUnit,
    leaseDurationOptions: [],
};

export const defaultRentAdjustmentOptions = {
    yearlyCpiIndexDate: moment.utc([2000, 9, 1]), // default month: October
    yearlyCpiAdjustmentDate: moment.utc([2000]), // default date: 1st January
    cpiRate: 100,
    cpiSource: cpiSourceOptions[0].name,
};

const initialState = {
    contract: null,
    contractNotFound: false,
    contractToDelete: null,
    contractToArchive: null,

    portfolioContracts: [],

    hasContractDuration: false,

    showDraftModal: false,

    crudLoading: false,
    rentObjectLoading: false,
    crudRentObjectLoading: false,
    updateDetailsLoading: false,
    updateDurationAndCreationRentalObjectsLoading: false,
    getRentPaymentPlanTableDataLoading: false,
    getInvoiceScheduleTableDataLoading: false,
    getLoading: false,
    deleteLoading: false,
    contractsLoading: false,
    contractDataLoading: false,
    contractAndTenantLoading: false,

    calculatedInvoices: null,

    rentObjectPaymentPlans: [],

    // Notes
    showCreateNoteModal: false,
    showContractNotesModal: false,
    contractNotes: [],

    // Stair Rent Interval
    showRemoveStairRentIntervalModal: false,
    showStairRentIntervalModal: false,
    stairRentIntervalModel: null,
    stairRentIntervals: [],
    stairRentIntervalLoading: false,

    // Exemption
    showRemoveRentExemptionModal: false,
    showRentExemptionModal: false,
    rentExemptionModel: null,
    rentExemptions: [],
    rentExemptionLoading: false,

    // Lease Duration Option
    leaseDurationOptions: [],
    showContractLeaseDurationModal: false,
    showAddedRentObjectLeaseDurationModal: false,
    showRentObjectLeaseDurationModal: false,
    showModalForContractCreation: false,
    showRemoveLeaseDurationModal: false,
    leaseDurationOptionLoading: false,
    leaseDurationOptionEditModel: null,

    // Rent Object
    showRemoveRentObjectModal: false,
    showRentObjectForm: true,
    rentObject: defaultRentalObject,
    showRentObjectDetailsModal: false,
    rentObjectWithDetails: null,

    rentObjects: [],

    // Custom Duration
    showRentObjectRemoveCustomDurationModal: false,
    showAddedRentObjectRemoveCustomDurationModal: false,

    // Contract Option
    showRemoveContractOptionModal: false,
    showContractOptionEventModal: false,
    contractOptionModel: null,
    contractOptionLoading: false,
    contractOptions: [],

    paymentTermsOptions: null,
    rentPaymentPlanTableData: null,
    invoiceScheduleTableData: [],

    status: [contractStatus.active],
    hideDraftContracts: false,
    showArchived: false,

    moveToStepName: null,

    contractStatus: contractStatus.active,

    turnovers: [],

    updateNoteLoading: false,
    deleteNoteLoading: false,

    documents: [],
    contractWarranties: [],
    commonCostOptions: [],
};

const contractReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_CONTRACT:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case SET_ERROR_CONTRACT:
            newState = update.set(newState, 'error', action.payload);
            break;
        case SUCCESS_DELETE_CONTRACT:
            newState = update.set(newState, 'contract', null);
            break;
        case SUCCESS_CALCULATED_INVOICES:
            newState = update.set(newState, 'calculatedInvoices', action.payload);
            break;
        case SET_PROPERTY_TO_RENTAL_OBJECT:
            newState.rentObjects = update(newState.rentObjects, {
                [action.payload.id]: {
                    [`${action.payload.propertyName}`]: { $set: action.payload.propertyValue },
                },
            });
            break;
        case SET_LIST_OF_UPLOAD_ITEMS_CONTRACT:
            newState = update.set(newState, 'documents', action.payload);
            break;
        case SETCONTRACT_DURATION_AND_RENTAL_OBJECTS:
            newState = update.set(newState, 'contract', action.payload.contract);
            newState = update.set(newState, 'rentObjects', action.payload.rentObjects);
            newState = update.set(newState, 'rentObject', defaultRentalObject);
            break;
        default:
            break;
    }
    return newState;
};

export const setValueContract = (key, value) => {
    return {
        type: SET_VALUE_CONTRACT,
        payload: {
            key,
            value,
        },
    };
};

export const setPropertyToRentalObject = (id, propertyName, propertyValue) => {
    return {
        type: SET_PROPERTY_TO_RENTAL_OBJECT,
        payload: {
            id,
            propertyName,
            propertyValue,
        },
    };
};

export const successGetCalculatedInvoices = (value) => {
    return {
        type: SUCCESS_CALCULATED_INVOICES,
        payload: value,
    };
};

export const setFiles = (value) => {
    return {
        type: SET_LIST_OF_UPLOAD_ITEMS_CONTRACT,
        payload: value,
    };
};

const setContractResponseInStore = (dispatch, contract) => {
    dispatch(setValueContract('hasContractDuration', contract.leaseStartDate && contract.leaseEndDate));
    dispatch(setValueContract('contract', contract));
    dispatch(setValueContract('rentObjects', listOfRentObjects(contract.rentObjects || [])));
    dispatch(setValueContract('contractWarranties', contract.contractWarranties));
    dispatch(setValueContract('leaseDurationOptions', contract.leaseDurationOptions));

    const rentExemptions = contract.rentExemptions && contract.rentExemptions.length > 0 ? contract.rentExemptions.map((obj) => ({ ...obj, uId: uuidv4() })) : [];

    dispatch(setValueContract('rentExemptions', rentExemptions));

    const stairRentIntervals = contract.stairRentIntervals && contract.stairRentIntervals.length > 0 ? contract.stairRentIntervals.map((obj) => ({ ...obj, uId: uuidv4() })) : [];

    dispatch(setValueContract('stairRentIntervals', stairRentIntervals));

    const contractOptions = contract.contractOptions && contract.contractOptions.length > 0 ? contract.contractOptions.map((obj) => ({ ...obj, uId: uuidv4() })) : [];

    dispatch(setValueContract('contractOptions', contractOptions));

    dispatch(setFiles(contract.documents || []));
    dispatch(setValueContract('contractNotes', contract.notes || []));
};

const setContractDurationDetailsAndCreatedRentalObjects = (contract, rentObjects) => {
    return {
        type: SETCONTRACT_DURATION_AND_RENTAL_OBJECTS,
        payload: {
            contract,
            rentObjects,
        },
    };
};

const handleContractError = (error, dispatch) => {
    if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
        dispatch(setValueContract('contractNotFound', true));
    } else if (error && error.response && error.response.title) {
        toaster.error(error.response.title, null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

// CRUD

export const createContract = (contractId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('crudLoading', true));
        return contractService
            .create(contractId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueContract('contract', response.data));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('crudLoading', false));
            });
    };
};

export const getContractById = (id, tenantId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('getLoading', true));
        return contractService
            .getById(id, tenantId, propertyId, portfolioId)
            .then((response) => {
                setContractResponseInStore(dispatch, {
                    ...response.data,
                });
                dispatch(setValueContract('contractStatus', response.data.status));
                return response.data;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('getLoading', false));
            });
    };
};

export const deleteContract = (contractId, portfolioId, propertyId) => {
    return (dispatch) => {
        dispatch(setValueContract('deleteLoading', true));
        return contractService
            .delete(contractId, portfolioId, propertyId)
            .then((_) => true)
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('deleteLoading', false));
            });
    };
};

export const archiveContract = (contractId, portfolioId, propertyId) => {
    return (dispatch) => {
        dispatch(setValueContract('archiveLoading', true));
        return contractService
            .archive(contractId, portfolioId, propertyId)
            .then((_) => true)
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('archiveLoading', false));
            });
    };
};

export const restoreContract = (contractId, portfolioId, propertyId) => {
    return (dispatch) => {
        dispatch(setValueContract('archiveLoading', true));
        return contractService
            .restoreArchivedContract(contractId, portfolioId, propertyId)
            .then((_) => true)
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('archiveLoading', false));
            });
    };
};

// Details

export const updateBasicContractDetails = (data, contractId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('updateDetailsLoading', true));
        return contractDetailsService
            .updateBasicContractDetails(data, contractId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueContract('contract', response.data));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateDetailsLoading', false));
            });
    };
};

export const updateContractDurationDetails = (data, contractId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('updateDurationAndCreationRentalObjectsLoading', true));
        return contractDetailsService
            .updateContractDurationDetails(data, contractId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueContract('contract', response.data));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateDurationAndCreationRentalObjectsLoading', false));
            });
    };
};

export const updateContractDurationDetailsAndCreateRentalObjects = (contractDuration, rentObjects, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('updateDurationAndCreationRentalObjectsLoading', true));
        return contractDetailsService
            .updateContractDurationDetailsAndCreateRentalObjects(contractDuration, rentObjects, contractId, propertyId, portfolioId)
            .then((response) => {
                const contract = response[0].data;
                const oldRentObjects = [...getState().contract.rentObjects].filter((x) => x.id);
                const rentObjects = concatAllRentObjectAfterCreation(response[1].data, oldRentObjects);
                dispatch(setContractDurationDetailsAndCreatedRentalObjects(contract, listOfRentObjects(rentObjects)));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateDurationAndCreationRentalObjectsLoading', false));
            });
    };
};

export const createRentalObjects = (data, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('updateDurationAndCreationRentalObjectsLoading', true));
        return contractDetailsService
            .createRentalObjects(data, contractId, propertyId, portfolioId)
            .then((response) => {
                const oldRentObjects = [...getState().contract.rentObjects].filter((x) => x.id);
                const rentObjects = concatAllRentObjectAfterCreation(response.data, oldRentObjects);
                dispatch(setValueContract('rentObjects', listOfRentObjects(rentObjects)));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateDurationAndCreationRentalObjectsLoading', false));
            });
    };
};

const concatAllRentObjectAfterCreation = (newRentObjects, oldRentObjects) => {
    newRentObjects.forEach((rentObject) => {
        rentObject.uId = uuidv4();
        if (rentObject.leaseEndDate && rentObject.leaseStartDate) {
            rentObject.hasCustomDuration = true;
        }
        rentObject.typeOfYearlyRent = typeOfYearlyRentOptions.perUnit;
        rentObject.displayName = rentObjectService.getRentalObjectDisplayName(rentObject);
    });
    return oldRentObjects.concat(newRentObjects);
};

export const updateCommonCostDetails = (data, contractId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('updateDetailsLoading', true));
        return contractDetailsService
            .updateCommonCostDetails(data, contractId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueContract('contract', response.data));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateDetailsLoading', false));
            });
    };
};

export const updatePaymentTerms = (data, contractId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('updateDetailsLoading', true));
        return contractDetailsService
            .updatePaymentTerms(data, contractId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueContract('contract', response.data));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateDetailsLoading', false));
            });
    };
};

export const updateRentAdjustmentOptions = (data, contractId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('updateDetailsLoading', true));
        return contractDetailsService
            .updateRentAdjustmentOptions(data, contractId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueContract('contract', response.data));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateDetailsLoading', false));
            });
    };
};

export const updateSignatureDate = (data, contractId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('updateDetailsLoading', true));
        return contractDetailsService
            .updateSignatureDate(data, contractId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueContract('contract', response.data));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateDetailsLoading', false));
            });
    };
};

export const upsertDurationOption = (data, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('updateDetailsLoading', true));
        return contractDetailsService
            .upsertDurationOption(data, contractId, propertyId, portfolioId)
            .then((response) => {
                let options = [...(getState().contract.leaseDurationOptions || [])];

                if (!data.id) {
                    options.push(response.data);
                } else {
                    const index = options.findIndex((o) => o.id === data.id);
                    options[index] = response.data;
                }

                dispatch(setValueContract('leaseDurationOptions', options));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateDetailsLoading', false));
            });
    };
};

export const deleteContractLeaseDurationOption = (optionId, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('updateDetailsLoading', true));
        return contractDetailsService
            .deleteContractLeaseDurationOption(optionId, contractId, propertyId, portfolioId)
            .then((_) => {
                dispatch(
                    setValueContract(
                        'leaseDurationOptions',
                        [...(getState().contract.leaseDurationOptions || [])].filter((o) => o.id !== optionId)
                    )
                );
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateDetailsLoading', false));
            });
    };
};

export const addContractNote = (data, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('contractNoteLoading', true));
        return contractDetailsService
            .addContractNote(data, contractId, propertyId, portfolioId)
            .then((response) => {
                const contractNotes = [response.data, ...getState().contract.contractNotes];
                dispatch(setValueContract('contractNotes', contractNotes));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('contractNoteLoading', false));
            });
    };
};

export const updateContractNote = (data, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('updateNoteLoading', true));
        return contractDetailsService
            .updateContractNote(data, contractId, propertyId, portfolioId)
            .then((response) => {
                const contractNotes = [...getState().contract.contractNotes.filter((item) => item.id != data.id), response.data];
                dispatch(setValueContract('contractNotes', contractNotes));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateNoteLoading', false));
            });
    };
};

export const deleteContractNote = (noteId, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('deleteNoteLoading', true));
        return contractDetailsService
            .deleteContractNote(noteId, contractId, propertyId, portfolioId)
            .then((response) => {
                const contractNotes = [...getState().contract.contractNotes.filter((item) => item.id != noteId)];
                dispatch(setValueContract('contractNotes', contractNotes));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('deleteNoteLoading', false));
            });
    };
};

// Rent Exemption

export const addRentExemption = (data, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('rentExemptionLoading', true));
        return contractDetailsService
            .addRentExemption(data, contractId, propertyId, portfolioId)
            .then((response) => {
                const rentExemptions = [...getState().contract.rentExemptions, response.data];
                dispatch(setValueContract('rentExemptions', rentExemptions));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('rentExemptionLoading', false));
            });
    };
};

export const updateRentExemption = (rentExemption, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('rentExemptionLoading', true));
        return contractDetailsService
            .updateRentExemption(rentExemption, contractId, propertyId, portfolioId)
            .then((response) => {
                const rentExemptions = [...getState().contract.rentExemptions.filter((x) => x.id !== response.data.id), response.data];
                dispatch(setValueContract('rentExemptions', rentExemptions));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('rentExemptionLoading', false));
            });
    };
};

export const deleteRentExemption = (rentExemptionId, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('rentExemptionLoading', true));
        return contractDetailsService
            .deleteRentExemption(rentExemptionId, contractId, propertyId, portfolioId)
            .then((_) => {
                const rentExemptions = [...getState().contract.rentExemptions].filter((x) => x.id !== rentExemptionId);
                dispatch(setValueContract('rentExemptions', rentExemptions));
                dispatch(setValueContract('showRemoveRentExemptionModal', false));
                dispatch(setValueContract('rentExemptionModel', null));
                toaster.success(i18next.t('common.itemDeletedSuccessfully', { name: i18next.t('contract.finances.exemption') }));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('rentExemptionLoading', false));
            });
    };
};

// Lease Duration Option of Contract

export const addLeaseDurationOption = (data, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('leaseDurationOptionLoading', true));
        return contractDetailsService
            .addLeaseDurationOption(data, contractId, portfolioId, propertyId)
            .then((response) => {
                const leaseDurationOption = response.data;
                leaseDurationOption.uId = uuidv4();
                dispatch(setValueContract('leaseDurationOptions', [...getState().contract.leaseDurationOptions, leaseDurationOption]));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('leaseDurationOptionLoading', false));
            });
    };
};

export const addLeaseDurationOptionAndContractDuration = (dates, leaseDurationData, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('leaseDurationOptionLoading', true));
        return contractDetailsService
            .updateContractDurationDetails(dates, contractId, portfolioId)
            .then((res) => {
                setContractResponseInStore(dispatch, res.data);
                contractDetailsService
                    .addLeaseDurationOption(leaseDurationData, contractId, portfolioId, propertyId)
                    .then((response) => {
                        const leaseDurationOption = response.data;
                        leaseDurationOption.uId = uuidv4();
                        dispatch(setValueContract('leaseDurationOptions', [...getState().contract.leaseDurationOptions, leaseDurationOption]));
                    })
                    .catch((error) => {
                        handleContractError(error, dispatch);
                    })
                    .finally(() => {
                        dispatch(setValueContract('leaseDurationOptionLoading', false));
                    });
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('leaseDurationOptionLoading', false));
            });
    };
};

export const updateLeaseDurationOption = (data, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('leaseDurationOptionLoading', true));
        return contractDetailsService
            .updateLeaseDurationOption(data, contractId, portfolioId, propertyId)
            .then((response) => {
                const listOfLeaseDurationOptions = [...getState().contract.leaseDurationOptions];
                const leaseDurationOptionId = listOfLeaseDurationOptions.findIndex((x) => x.id === response.data.id);
                if (leaseDurationOptionId > -1) {
                    const leaseDurationOption = response.data;
                    leaseDurationOption.uId = uuidv4();
                    listOfLeaseDurationOptions[leaseDurationOptionId] = leaseDurationOption;
                }
                dispatch(setValueContract('leaseDurationOptions', listOfLeaseDurationOptions));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('leaseDurationOptionLoading', false));
            });
    };
};

export const deleteLeaseDurationOption = (id, contractId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('leaseDurationOptionLoading', true));
        return contractDetailsService
            .deleteLeaseDurationOption(id, contractId, portfolioId)
            .then(() => {
                dispatch(
                    setValueContract(
                        'leaseDurationOptions',
                        [...getState().contract.leaseDurationOptions].filter((x) => x.id !== id)
                    )
                );
                dispatch(setValueContract('showRemoveLeaseDurationModal', false));
                toaster.success(i18next.t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.onDeleteNotification'), null);
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('leaseDurationOptionLoading', false));
            });
    };
};

// Contract Option

export const addContractOption = (data, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('contractOptionLoading', true));
        return contractDetailsService
            .addContractOption(data, contractId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueContract('contractOptions', [...getState().contract.contractOptions, response.data]));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('contractOptionLoading', false));
            });
    };
};

export const updateContractOption = (data, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('contractOptionLoading', true));
        return contractDetailsService
            .updateContractOption(data, contractId, propertyId, portfolioId)
            .then((response) => {
                const listOfContractOptions = [...getState().contract.contractOptions];
                const contractOptionId = listOfContractOptions.findIndex((x) => x.id === response.data.id);
                if (contractOptionId > -1) {
                    const contractOption = response.data;
                    contractOption.uId = uuidv4();
                    listOfContractOptions[contractOptionId] = contractOption;
                }
                dispatch(setValueContract('contractOptions', listOfContractOptions));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('contractOptionLoading', false));
            });
    };
};

export const deleteContractOption = (id, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('contractOptionLoading', true));
        return contractDetailsService
            .deleteContractOption(id, contractId, propertyId, portfolioId)
            .then(() => {
                dispatch(
                    setValueContract(
                        'contractOptions',
                        [...getState().contract.contractOptions].filter((x) => x.id !== id)
                    )
                );
                dispatch(setValueContract('showRemoveContractOptionModal', false));
                dispatch(setValueContract('contractOptionModel', null));
                toaster.success(i18next.t('contract.leaseDurationOptionAndContractOptionModal.contractOption.onDeleteNotification'), null);
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('contractOptionLoading', false));
            });
    };
};

// Stair Rent Interval

export const addStairRentInterval = (data, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('stairRentIntervalLoading', true));
        return contractDetailsService
            .addStairRentInterval(data, contractId, propertyId, portfolioId)
            .then((response) => {
                const stairRentIntervals = [...getState().contract.stairRentIntervals, response.data];
                dispatch(setValueContract('stairRentIntervals', stairRentIntervals));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('stairRentIntervalLoading', false));
            });
    };
};

export const updateStairRentInterval = (stairRentInterval, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('stairRentIntervalLoading', true));
        return contractDetailsService
            .updateStairRentInterval(stairRentInterval, contractId, propertyId, portfolioId)
            .then((response) => {
                const stairRentIntervals = [...getState().contract.stairRentIntervals.filter((x) => x.id !== response.data.id), response.data];
                dispatch(setValueContract('stairRentIntervals', stairRentIntervals));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('stairRentIntervalLoading', false));
            });
    };
};

export const deleteStairRentInterval = (stairRentIntervalId, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('stairRentIntervalLoading', true));
        return contractDetailsService
            .deleteStairRentInterval(stairRentIntervalId, contractId, propertyId, portfolioId)
            .then((res) => {
                const stairRentIntervals = [...getState().contract.stairRentIntervals].filter((x) => x.id !== stairRentIntervalId);
                dispatch(setValueContract('stairRentIntervals', stairRentIntervals.length > 0 ? stairRentIntervals : []));
                dispatch(setValueContract('showRemoveStairRentIntervalModal', false));
                dispatch(setValueContract('stairRentIntervalModel', null));
                toaster.success(i18next.t('common.itemDeletedSuccessfully', { name: i18next.t('contract.finances.exemption') }));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('stairRentIntervalLoading', false));
            });
    };
};

// Modify list of rent objects

const listOfRentObjects = (rentObjects) => {
    if (rentObjects.length > 0) {
        return rentObjects.map((rentObject) => {
            rentObject.uId = uuidv4();

            const leaseDurationOptions =
                rentObject.leaseDurationOptions && rentObject.leaseDurationOptions.length > 0 ? rentObject.leaseDurationOptions.map((obj) => ({ ...obj, uId: uuidv4() })) : [];

            rentObject.leaseDurationOptions = leaseDurationOptions;

            rentObject = {
                ...rentObject,
                displayName: rentObjectService.getRentalObjectDisplayName(rentObject),
            };
            if (rentObject.leaseStartDate && rentObject.leaseEndDate) {
                rentObject = {
                    ...rentObject,
                    hasCustomDuration: true,
                };
            }
            return rentObject;
        });
    } else {
        return [];
    }
};

// Draft

export const getContractDraft = (propertyId, tenantId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('getLoading', true));
        return contractService
            .getDraft(propertyId, tenantId, portfolioId)
            .then((response) => {
                if (response.data) {
                    setContractResponseInStore(dispatch, response.data);
                }
                dispatch(setValueContract('contractStatus', response.data.status));
                return response.data;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('getLoading', false));
            });
    };
};

export const createContractDraft = (tenantId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('saveLoading', true));
        return contractService
            .createDraft(tenantId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueContract('contract', response.data));
                dispatch(setValueContract('documents', []));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('saveLoading', false));
            });
    };
};

// Files

export const uploadFile = (file, options, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        return contractService
            .uploadFile(file, options, contractId, portfolioId, propertyId)
            .then((response) => {
                const files = [...getState().contract.documents] || [];
                let newFile = response.data;
                newFile.uid = file.uid;
                newFile.percent = maxPercents;
                files.push(newFile);
                dispatch(setFiles(files.filter((f) => f.id)));
                return newFile;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            });
    };
};

// Files

export const uploadWarrantyDocument = (file, options, warrantyId, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        return contractService
            .uploadWarrantyDocument(file, options, warrantyId, contractId, portfolioId, propertyId)
            .then((response) => {
                const warranties = [...getState().contract.contractWarranties];
                const warrantyIndex = warranties.findIndex((w) => w.id === warrantyId);
                const warranty = { ...warranties[warrantyIndex] };

                let newFile = response.data;
                newFile.uid = file.uid;
                newFile.percent = maxPercents;
                warranty.documents.push(newFile);

                warranty.documents = warranty.documents.filter((d) => d.id);

                warranties[warrantyIndex] = warranty;

                dispatch(setValueContract('contractWarranties', warranties));
                return newFile;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            });
    };
};

export const downloadFile = (document, contractId, portfolioId, propertyId) => {
    return (dispatch) => {
        contractService
            .getContractDocumentDownloadLink(document.id, contractId, portfolioId, propertyId)
            .then((response) => {
                const secureFileId = response.data;
                const url = `/files/contracts?fileId=${secureFileId}&returnUrl=${window.location.pathname}`;
                const link = window.document.createElement('a');
                link.href = url;
                link.setAttribute('target', '_blank');
                window.document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
                    toaster.error(i18next.t('contract.contractFileNotFound'), null);
                } else {
                    handleContractError(error, dispatch);
                }
            });
    };
};

export const downloadDocument = (document, contractId, name, portfolioId) => {
    return (dispatch) => {
        contractService
            .downloadDocument(document.id, contractId, portfolioId)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = window.document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);

                window.document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
                    toaster.error(i18next.t('contract.contractFileNotFound'), null);
                } else {
                    handleContractError(error, dispatch);
                }
            });
    };
};

export const openPdfDocument = (document, contractId, portfolioId, propertyId) => {
    return (dispatch) => {
        contractService
            .downloadDocument(document.id, contractId, portfolioId, propertyId)
            .then((response) => {
                const file = new Blob([response.data], { type: 'application/pdf' });

                const fileURL = URL.createObjectURL(file);

                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            })
            .catch((error) => {
                if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
                    toaster.error(i18next.t('contract.contractFileNotFound'), null);
                } else {
                    handleContractError(error, dispatch);
                }
            });
    };
};

export const downloadWarrantyDocument = (document, contractId, name, portfolioId, propertyId) => {
    return (dispatch) => {
        contractService
            .downloadWarrantyDocument(document.id, contractId, portfolioId, propertyId)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = window.document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);

                window.document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
                    toaster.error(i18next.t('contract.contractFileNotFound'), null);
                } else {
                    handleContractError(error, dispatch);
                }
            });
    };
};

export const openWarrantyPdfDocument = (document, contractId, portfolioId, propertyId) => {
    return (dispatch) => {
        contractService
            .downloadWarrantyDocument(document.id, contractId, portfolioId, propertyId)
            .then((response) => {
                const file = new Blob([response.data], { type: "application/pdf" });

                const fileURL = URL.createObjectURL(file);

                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            })
            .catch((error) => {
                if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
                    toaster.error(i18next.t('contract.contractFileNotFound'), null);
                } else {
                    handleContractError(error, dispatch);
                }
            });
    };
}

export const downloadWarrantyFile = (document, contractId, portfolioId, propertyId) => {
    return (dispatch) => {
        contractService
            .getWarrantyDocumentDownloadLink(document.id, contractId, portfolioId, propertyId)
            .then((response) => {
                const secureFileId = response.data;
                const url = `/files/contracts?fileId=${secureFileId}&returnUrl=${window.location.pathname}`;
                const link = window.document.createElement('a');
                link.href = url;
                link.setAttribute('target', '_blank');
                window.document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                if (error && error.response && error.response.status && (error.response.status === StatusCodes.NOT_FOUND || error.response.status === StatusCodes.FORBIDDEN)) {
                    toaster.error(i18next.t('contract.contractFileNotFound'), null);
                } else {
                    handleContractError(error, dispatch);
                }
            });
    };
};

export const deleteFile = (documentId, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        return contractService
            .deleteFile(documentId, contractId, portfolioId, propertyId)
            .then(() => {
                const files = [...getState().contract.documents].filter((item) => item.id !== documentId);
                dispatch(setFiles(files));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            });
    };
};

export const deleteWarrantyDocument = (documentId, warrantyId, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        return contractService
            .deleteWarrantyDocument(documentId, contractId, portfolioId, propertyId)
            .then(() => {
                const warranties = [...getState().contract.contractWarranties];
                const warrantyIndex = warranties.findIndex((w) => w.id === warrantyId);
                const warranty = { ...warranties[warrantyIndex] };
                warranty.documents = warranty.documents.filter((item) => item.id !== documentId);
                warranties[warrantyIndex] = warranty;
                dispatch(setValueContract('contractWarranties', warranties));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            });
    };
};

// Calculations

export const getCalculatedInvoices = (contractId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('getLoading', true));
        return contractService
            .getCalculatedInvoices(contractId, portfolioId)
            .then((res) => {
                dispatch(successGetCalculatedInvoices(res.data));
                return res.data;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('getLoading', false));
            });
    };
};

export const getRentObjectCalculations = (rentObjectId, contractId, portfolioId, propertyId) => {
    return (dispatch) => {
        dispatch(setValueContract('getLoading', true));
        contractService
            .getRentObjectCalculations(rentObjectId, contractId, portfolioId, propertyId)
            .then((response) => {
                dispatch(setValueContract('rentObjectPaymentPlans', response.data));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('getLoading', false));
            });
    };
};

// Rent Object

export const updateRentObject = (data, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('crudRentObjectLoading', true));
        return rentObjectService
            .updateRentObject(data, contractId, portfolioId, propertyId)
            .then((response) => {
                dispatch(setValueContract('rentObjects', updateRentObjectInStore(response.data, [...getState().contract.rentObjects])));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('crudRentObjectLoading', false));
            });
    };
};

export const deleteRentObject = (id, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('crudRentObjectLoading', true));
        return rentObjectService
            .deleteRentObject(id, contractId, portfolioId, propertyId)
            .then(() => {
                const rentObjects = [...getState().contract.rentObjects].filter((x) => x.id !== id);
                dispatch(setValueContract('rentObjects', rentObjects));
                dispatch(setValueContract('contract.rentObjects', rentObjects));
                toaster.success(i18next.t('common.itemDeletedSuccessfully', { name: i18next.t('contract.deleteRent.rentObject') }));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('crudRentObjectLoading', false));
            });
    };
};

const updateRentObjectInStore = (rentObject, rentObjects) => {
    const rentObjectIndex = rentObjects.findIndex((x) => x.id === rentObject.id);
    if (rentObjectIndex > -1) {
        rentObject.uId = uuidv4();
        rentObject.displayName = rentObjectService.getRentalObjectDisplayName(rentObject);
        rentObject.hasCustomDuration = rentObject.leaseEndDate && rentObject.leaseStartDate;
        rentObjects[rentObjectIndex] = rentObject;
    }
    return rentObjects;
};

export const updateRentObjectRentOptions = (data, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('updateDetailsLoading', true));
        return rentObjectService
            .updateRentObjectRentOptions(data, contractId, portfolioId, propertyId)
            .then((response) => {
                dispatch(
                    setValueContract(
                        'rentObjects',
                        getState().contract.rentObjects.map((item) => {
                            return {
                                ...item,
                                ...response.data.find((i) => i.id == item.id),
                            };
                        })
                    )
                );
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('updateDetailsLoading', false));
            });
    };
};

export const updateRentObjectCommonCostDetails = (data, rentObjectId, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('rentObjectLoading', true));
        return rentObjectService
            .updateRentObjectCommonCostDetails(data, rentObjectId, contractId, portfolioId, propertyId)
            .then((response) => {
                dispatch(setValueContract('rentObjects', updateRentObjectInStore(response.data, [...getState().contract.rentObjects])));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('rentObjectLoading', false));
            });
    };
};

export const updateRentObjectsCommonCostDetails = (data, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('rentObjectLoading', true));
        return rentObjectService
            .updateRentObjectsCommonCostDetails(data, contractId, portfolioId, propertyId)
            .then((response) => {
                for (let index = 0; index < response.data.length; index++) {
                    dispatch(setValueContract('rentObjects', updateRentObjectInStore(response.data[index], [...getState().contract.rentObjects])));
                }
            })
            .catch((error) => {
                handleContractError(error, dispatch);
                dispatch(setValueContract('rentObjectLoading', false));
            })
            .finally(() => {
                dispatch(setValueContract('rentObjectLoading', false));
            });
    };
};

export const updatePreview = (data) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('preview', { ...getState().contract.preview, ...data }));
    };
};

export const updateRentObjectPaymentTerms = (data, rentObjectId, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('rentObjectLoading', true));
        return rentObjectService
            .updateRentObjectPaymentTerms(data, rentObjectId, contractId, portfolioId, propertyId)
            .then((response) => {
                dispatch(setValueContract('rentObjects', updateRentObjectInStore(response.data, [...getState().contract.rentObjects])));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('rentObjectLoading', false));
            });
    };
};

export const updateRentObjectRentAdjustmentOptions = (data, rentObjectId, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('rentObjectLoading', true));
        return rentObjectService
            .updateRentObjectRentAdjustmentOptions(data, rentObjectId, contractId, portfolioId, propertyId)
            .then((response) => {
                dispatch(setValueContract('rentObjects', updateRentObjectInStore(response.data, [...getState().contract.rentObjects])));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('rentObjectLoading', false));
            });
    };
};

export const getRentPaymentPlanTableData = (rentObjects, contractId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('getRentPaymentPlanTableDataLoading', true));
        return contractService
            .getRentPaymentPlanTableData(rentObjects, contractId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueContract('rentPaymentPlanTableData', response.data));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('getRentPaymentPlanTableDataLoading', false));
            });
    };
};

export const getRentPaymentPlanTableDataIncludeCpi = (rentObjects, contractId, propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('getRentPaymentPlanTableDataLoading', true));
        return contractService
            .getRentPaymentPlanTableDataIncludeCPI(rentObjects, contractId, propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueContract('rentPaymentPlanTableData', response.data));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('getRentPaymentPlanTableDataLoading', false));
            });
    };
};

// Lease Duration Option of Rent Object

export const addRentObjectLeaseDurationOption = (data, rentObjectId, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('leaseDurationOptionLoading', true));
        return rentObjectService
            .addLeaseDurationOption(data, rentObjectId, contractId, propertyId, portfolioId)
            .then((response) => {
                const listOfRentObjects = [...getState().contract.rentObjects];
                const currectRentObjectId = listOfRentObjects.findIndex((x) => x.id === rentObjectId);
                if (currectRentObjectId > -1) {
                    let currectRentObject = listOfRentObjects[currectRentObjectId];
                    const leaseDurationOption = response.data;
                    leaseDurationOption.uId = uuidv4();
                    currectRentObject.leaseDurationOptions.push(leaseDurationOption);
                    listOfRentObjects[currectRentObjectId] = currectRentObject;
                }
                dispatch(setValueContract('rentObjects', listOfRentObjects));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('leaseDurationOptionLoading', false));
            });
    };
};

export const updateRentObjectLeaseDurationOption = (data, rentObjectId, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('leaseDurationOptionLoading', true));
        return rentObjectService
            .updateLeaseDurationOption(data, contractId, propertyId, portfolioId)
            .then((response) => {
                const listOfRentObjects = [...getState().contract.rentObjects];
                const currectRentObjectId = listOfRentObjects.findIndex((x) => x.id === rentObjectId);
                if (currectRentObjectId > -1) {
                    let currectRentObject = listOfRentObjects[currectRentObjectId];
                    const leaseDurationOption = response.data;
                    leaseDurationOption.uId = uuidv4();
                    const currectRentObjectLeaseDurationOptionId = currectRentObject.leaseDurationOptions.findIndex((x) => x.id === response.data.id);
                    if (currectRentObjectLeaseDurationOptionId > -1) {
                        currectRentObject.leaseDurationOptions[currectRentObjectLeaseDurationOptionId] = leaseDurationOption;
                    }
                    listOfRentObjects[currectRentObjectId] = currectRentObject;
                }
                dispatch(setValueContract('rentObjects', listOfRentObjects));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('leaseDurationOptionLoading', false));
            });
    };
};

export const deleteRentObjectLeaseDurationOption = (id, rentObjectId, contractId, propertyId, portfolioId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('leaseDurationOptionLoading', true));
        return rentObjectService
            .deleteLeaseDurationOption(id, contractId, propertyId, portfolioId)
            .then(() => {
                const listOfRentObjects = [...getState().contract.rentObjects];
                let currectRentObjectId = listOfRentObjects.findIndex((x) => x.id === rentObjectId);
                if (currectRentObjectId > -1) {
                    let currectRentObject = listOfRentObjects[currectRentObjectId];
                    currectRentObject.leaseDurationOptions = currectRentObject.leaseDurationOptions.filter((x) => x.id !== id);
                    listOfRentObjects[currectRentObjectId] = currectRentObject;
                }
                dispatch(setValueContract('rentObjects', listOfRentObjects));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('leaseDurationOptionLoading', false));
            });
    };
};

// Portfolio Contracts

export const getContractsByPortfolioId = (portfolioId, filter) => {
    return (dispatch) => {
        dispatch(setValueContract('contractsLoading', true));
        return contractService
            .getContractsByPortfolioId(portfolioId, filter)
            .then((response) => {
                dispatch(setValueContract('portfolioContracts', response.data));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('contractsLoading', false));
            });
    };
};

export const getDataForContractCreation = (portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('contractDataLoading', true));
        return contractService
            .getDataForContractCreation(portfolioId)
            .then((response) => {
                dispatch(setValueProperty('properties', response[0].data));
                dispatch(setValueTenant('portfolioTenants', response[1].data));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('contractDataLoading', false));
            });
    };
};

export const getContractAndRelatedTenant = (contractId, tenantId, propertyId, portfolioId, status) => {
    return (dispatch) => {
        dispatch(setValueContract('contractAndTenantLoading', true));
        return contractService
            .getContractAndRelatedTenant(contractId, tenantId, propertyId, portfolioId, status)
            .then((response) => {
                dispatch(setValueContract('contract', response[0].data));
                dispatch(setValueContract('rentObjects', listOfRentObjects(response[0].data.rentObjects)));
                dispatch(setValueTenant('tenant', response[1].data));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('contractAndTenantLoading', false));
            });
    };
};

// Tenant Contracts

export const getContractsByTenantId = (tenantId, propertyId, filter, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueContract('contractsLoading', true));
        return contractService
            .getContractsByTenantId(tenantId, propertyId, filter, portfolioId)
            .then((response) => {
                dispatch(setValueTenant('tenantDetails.contracts', response.data));
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('contractsLoading', false));
            });
    };
};

export const createWarrantyDraft = (warranty, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('warrantiesLoading', true));
        return contractDetailsService
            .createWarrantyDraft(warranty, contractId, portfolioId, propertyId)
            .then((response) => {
                dispatch(setValueContract('contractWarranties', [...getState().contract.contractWarranties, response.data]));
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('warrantiesLoading', false));
            });
    };
};

export const updateWarranty = (warranty, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueContract('warrantiesLoading', true));
        return contractDetailsService
            .updateWarranty(warranty, contractId, portfolioId, propertyId)
            .then((response) => {
                const warranties = [...getState().contract.contractWarranties];

                const warrantyIndex = warranties.findIndex((w) => w.id === warranty.id);
                const updatedWarranty = { ...warranties[warrantyIndex], ...response.data };
                warranties[warranties.findIndex((w) => w.id === warranty.id)] = updatedWarranty;

                dispatch(setValueContract('contractWarranties', warranties));

                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueContract('warrantiesLoading', false));
            });
    };
};

export const deleteWarranty = (warrantyId, contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        return contractDetailsService
            .deleteWarranty(warrantyId, contractId, portfolioId, propertyId)
            .then((_) => {
                dispatch(
                    setValueContract(
                        'contractWarranties',
                        getState().contract.contractWarranties.filter((warranty) => warranty.id !== warrantyId)
                    )
                );
                return true;
            })
            .catch((error) => {
                handleContractError(error, dispatch);
            });
    };
};

export const deleteWarrantiesIfDraft = (contractId, portfolioId, propertyId) => {
    return (dispatch, getState) => {
        const warrantiesToDelete = getState().contract?.contractWarranties?.filter((warranty) => warranty.isDraft);

        if (warrantiesToDelete?.length) {
            warrantiesToDelete.forEach((warranty) => {
                dispatch(deleteWarranty(warranty.id, contractId, portfolioId, propertyId));
            });
        }
    };
};

export default contractReducer;
