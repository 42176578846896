import i18next from 'i18next';
import { StatusCodes } from 'http-status-codes';
import { setUser } from './commonReducer';
import signUpService from '../services/signUpService';
import jwtDecode from 'jwt-decode';
import update from '../helpers/update';
import localStorageService from '../services/localStorageService';
import portfolioService from '../services/portfolioService';
import queryString from 'query-string';
import toaster from '../common/toaster';
import routingService from '../services/routingService';

const SET_VALUE_SIGNUP = 'SET_VALUE_SIGNUP';
const SET_ERROR_SIGNUP = 'SET_ERROR_SIGNUP';
const SUCCESS_REGISTER = 'SUCCESS_REGISTER';

const initialState = {
    registrationLoading: false,
    invitationLoading: true,
    success: false,
    error: null,
};

const registerReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_SIGNUP:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case SUCCESS_REGISTER:
            newState = update.set(newState, 'success', true);
            break;
        case SET_ERROR_SIGNUP:
            newState = update.set(newState, 'error', action.payload);
            break;
        default:
            break;
    }

    return newState;
};

export const setValueRegistration = (key, value) => {
    return {
        type: SET_VALUE_SIGNUP,
        payload: {
            key,
            value,
        },
    };
};

export const setError = (value) => {
    if (value.response && value.response.data) {
        return {
            type: SET_ERROR_SIGNUP,
            payload: value.response.data.title,
        };
    }
    return {
        type: SET_ERROR_SIGNUP,
        payload: i18next.t('common.serverErrorMessage'),
    };
};

export const successRegister = () => {
    return {
        type: SUCCESS_REGISTER,
    };
};

const handleSignUpError = (error, dispatch) => {
    if (error && error.response && error.response.status && error.response.status === StatusCodes.BAD_REQUEST) {
        toaster.error(error.response.data.title, null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
    dispatch(setError(error));
};

export const register = (values) => {
    return (dispatch) => {
        dispatch(setValueRegistration('registrationLoading', true));
        signUpService
            .register(values)
            .then((response) => {
                routingService.navigateToRegistered();
                localStorageService.setUser(response.data);
                const params = queryString.parse(window.location.search);
                if (params.returnUrl) {
                    routingService.goTo(params.returnUrl);
                    dispatch(setValueRegistration('registrationLoading', false));
                    return;
                }

                routingService.navigateToLogin();
                dispatch(setValueRegistration('registrationLoading', false));
            })
            .catch((error) => {
                handleSignUpError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueRegistration('registrationLoading', false));
            });
    };
};

export const signUp = (values) => {
    return (dispatch) => {
        dispatch(setValueRegistration('registrationLoading', true));
        return signUpService
            .signUp(values)
            .then((response) => {
                dispatch(successRegister());
                return response.data;
            })
            .catch((error) => {
                handleSignUpError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueRegistration('registrationLoading', false));
            });
    };
};

export const validateInvitationToken = (token) => {
    return (dispatch) => {
        dispatch(setValueRegistration('invitationLoading', true));
        signUpService
            .verifyInvitationToken(token)
            .then((response) => {
                var decodedToken = jwtDecode(token);
                var isUserAlreadyRegistered = response.data && response.data.userAlreadyRegistered;

                if (isUserAlreadyRegistered) {
                    routingService.navigateToPortfolios();
                } else {
                    routingService.navigateToSignup(decodedToken.email);
                }
            })
            .catch(() => {
                routingService.navigateToInvitationExpired('registration');
            })
            .finally(() => {
                dispatch(setValueRegistration('invitationLoading', false));
            });
    };
};

export const validateRegistrationToken = (values) => {
    return (dispatch) => {
        dispatch(setValueRegistration('registrationLoading', true));
        signUpService
            .verifyRegistrationToken(values)
            .then((response) => {
                if (response.data && response.data.userAlreadyRegistered) {
                    routingService.navigateToPortfolios();
                } else {
                    const user = { email: response.data.email, firstName: response.data.firstName, lastName: response.data.lastName };
                    dispatch(setUser(user));
                }
            })
            .catch(() => {
                routingService.navigateToInvitationExpired('registration');
            })
            .finally(() => {
                dispatch(setValueRegistration('registrationLoading', false));
            });
    };
};

export const reSendSignUpEmail = (email) => {
    return (dispatch) => {
        dispatch(setValueRegistration('registrationLoading', true));
        return signUpService
            .reSendSignUpEmail(email)
            .then(() => {})
            .catch((error) => {
                handleSignUpError(error, dispatch);
            })
            .finally(() => {
                dispatch(setValueRegistration('registrationLoading', false));
            });
    };
};

export default registerReducer;
