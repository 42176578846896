import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getUser } from '../../reducers/commonReducer';
import authService from '../../services/authService';

class PrivateRouteComponent extends React.Component {
    componentDidMount() {
        if (!this.props.user.id) {
            this.props.getUserCall();
        }
    }

    getLoginUrl() {
        if (this.props.path.includes('login')) {
            return this.props.path;
        }

        let returnUrl = '/login';
        if (this.props.path) {
            returnUrl += '?returnUrl=' + encodeURI(this.props.location.pathname);
        }

        return returnUrl;
    }

    isRouteAllowedForRole = (allowedRoles) => {
        if (allowedRoles && allowedRoles.length > 0) {
            return authService.isInRoles(allowedRoles);
        }

        return true;
    };

    render() {
        return authService.isLoggedIn() && this.isRouteAllowedForRole(this.props.roles) ? (
            this.props.user.id ? (
                <Route exect path={this.props.path} component={this.props.component} {...this.props} />
            ) : null
        ) : (
            <Redirect to={this.getLoginUrl()} />
        );
    }
}

const mapState = ({ common }) => {
    return {
        user: common.user,
    };
};

const mapDispatch = (dispatch) => {
    return {
        getUserCall() {
            dispatch(getUser());
        },
    };
};

const PrivateRouteContainer = connect(mapState, mapDispatch)(PrivateRouteComponent);
export default PrivateRouteContainer;
