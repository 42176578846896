import i18next from 'i18next';

// Lease Contract State

export const contractStatus = {
    active: 1,
    inNegotiation: 2,
    expired: 3,
    terminated: 4,
    draft: 5
};

export const contractStatusOptions = [
    { id: contractStatus.active, name: () => i18next.t('contract.leaseContractStates.active'), disabled: false },
    { id: contractStatus.inNegotiation, name: () => i18next.t('contract.leaseContractStates.inNegotiation'), disabled: false },
    { id: contractStatus.expired, name: () => i18next.t('contract.leaseContractStates.expired'), disabled: true },
    { id: contractStatus.terminated, name: () => i18next.t('contract.leaseContractStates.terminated'), disabled: false },
    { id: contractStatus.draft, name: () => i18next.t('contract.leaseContractStates.draft'), disabled: true },
];

// Lease Contract State

export const reminderPriorities = {
    oneDay: 1,
    oneWeek: 2,
    twoWeeks: 3,
    threeWeeks: 4,
    fourWeeks: 5,
};

export const reminderPrioritiesOptions = [
    { id: reminderPriorities.oneDay, name: () => i18next.t('contract.reminderPriorities.oneDay') },
    { id: reminderPriorities.oneWeek, name: () => i18next.t('contract.reminderPriorities.oneWeek') },
    { id: reminderPriorities.twoWeeks, name: () => i18next.t('contract.reminderPriorities.twoWeeks') },
    { id: reminderPriorities.threeWeeks, name: () => i18next.t('contract.reminderPriorities.threeWeeks') },
    { id: reminderPriorities.fourWeeks, name: () => i18next.t('contract.reminderPriorities.fourWeeks') },
];

// Lease Option

export const leaseDurationOptionTypeOptions = {
    extendLeaseEndDateTo: 1,
    terminatePriorEndDateTo: 2,
};

// Lease Option Reminder

export const leaseDurationOptionReminderTypeOptions = {
    date: 1,
    prior: 2,
};

// Rent Object Type

export const rentObjectRentalType = {
    office: 1,
    parking: 2,
    warehouse: 3,
    logistics: 4,
    retail: 5,
    hotel: 6,
    advertisingSign: 7,
    antenna: 8,
    foodAndBeverage: 9,
    service: 10,
    other: 11,
    total: 12,
    investmentRent: 13,
    otherUnit: 14
};

export const rentObjectRentalTypeOptions = [
    { id: rentObjectRentalType.office, name: () => i18next.t('contract.rentObjectRentalTypes.office'), unitType: 1 },
    { id: rentObjectRentalType.parking, name: () => i18next.t('contract.rentObjectRentalTypes.parking'), unitType: 2 },
    { id: rentObjectRentalType.warehouse, name: () => i18next.t('contract.rentObjectRentalTypes.warehouse'), unitType: 1 },
    { id: rentObjectRentalType.logistics, name: () => i18next.t('contract.rentObjectRentalTypes.logistics'), unitType: 1 },
    { id: rentObjectRentalType.retail, name: () => i18next.t('contract.rentObjectRentalTypes.retail'), unitType: 1 },
    { id: rentObjectRentalType.hotel, name: () => i18next.t('contract.rentObjectRentalTypes.hotel'), unitType: 1 },
    { id: rentObjectRentalType.advertisingSign, name: () => i18next.t('contract.rentObjectRentalTypes.advertisingSign'), unitType: 2 },
    { id: rentObjectRentalType.antenna, name: () => i18next.t('contract.rentObjectRentalTypes.antenna'), unitType: 2 },
    { id: rentObjectRentalType.foodAndBeverage, name: () => i18next.t('contract.rentObjectRentalTypes.foodAndBeverage'), unitType: 1 },
    { id: rentObjectRentalType.service, name: () => i18next.t('contract.rentObjectRentalTypes.service'), unitType: 1 },
    { id: rentObjectRentalType.other, name: () => i18next.t('contract.rentObjectRentalTypes.other'), unitType: 1 },
    { id: rentObjectRentalType.investmentRent, name: () => i18next.t('contract.rentObjectRentalTypes.investmentRent'), unitType: 2 },
    { id: rentObjectRentalType.otherUnit, name: () => i18next.t('contract.rentObjectRentalTypes.otherUnit'), unitType: 2 },
    { id: rentObjectRentalType.total, name: () => 'Total' },
];

// Unit Type

export const unitType = {
    squareMetre: 1, // m2
    numberOfUnits: 2, // no. units
};

export const unitTypeOptions = [
    { id: unitType.squareMetre, name: () => i18next.t('contract.unitTypes.m2') },
    { id: unitType.numberOfUnits, name: () => i18next.t('contract.unitTypes.numberOfUnits') },
];

// Payment Plan

export const paymentPlan = {
    monthly: 1,
    quarterly: 2,
    yearly: 3,
};

export const paymentPlanOptions = [
    { id: paymentPlan.monthly, name: () => i18next.t('common.monthly') },
    { id: paymentPlan.quarterly, name: () => i18next.t('common.quarterly') },
    { id: paymentPlan.yearly, name: () => i18next.t('common.yearly') },
];

// Invoice Issue Dates

export const invoiceIssueDates = {
    sevenDays: 1,
    tenDays: 2,
    fourteenDays: 3,
    twentyDays: 4,
    thirdyDays: 5,
};

export const invoiceIssueDateOptions = [
    { id: invoiceIssueDates.sevenDays, name: () => i18next.t('contract.invoiceIssueDates.sevenDays') },
    { id: invoiceIssueDates.tenDays, name: () => i18next.t('contract.invoiceIssueDates.tenDays') },
    { id: invoiceIssueDates.fourteenDays, name: () => i18next.t('contract.invoiceIssueDates.fourteenDays') }
];

// Contract Rental Object Type

export const rentObjectType = {
    generalRentalObject: 1,
    turnoverRentalObject: 2,
    advancedRentalObject: 3,
};

export const rentObjectTypeOptions = [
    { id: rentObjectType.generalRentalObject, name: () => i18next.t('contract.rentObjectTypes.fixedRent'), disabled: false },
    { id: rentObjectType.turnoverRentalObject, name: () => i18next.t('contract.rentObjectTypes.turnover'), disabled: false },
    { id: rentObjectType.advancedRentalObject, name: () => i18next.t('contract.rentObjectTypes.advanced'), disabled: true },
];

// Yearly Cpi

export const cpiIndexTypes = {
    floatingLimited: 1,
    floating: 2,
    positiveFlatNeverNegative: 3,
};

export const cpiIndexTypeOptions = [
    { id: cpiIndexTypes.floatingLimited, name: () => i18next.t('contract.rentAdjustment.floatingLimited') },
    { id: cpiIndexTypes.floating, name: () => i18next.t('contract.rentAdjustment.floating') },
    { id: cpiIndexTypes.positiveFlatNeverNegative, name: () => i18next.t('contract.rentAdjustment.positivFlatNeverNegative') },
];

// Cpi Source

export const cpiSourceOptions = [{ id: 1, name: 'SSB.no' }];

// Recurrence Types

export const recurrenceTypes = {
    endByDate: 1,
    noEndDate: 2,
};

// Type Of Yearly Rent

export const typeOfYearlyRentOptions = {
    perUnit: 'perUnit',
    totatAmount: 'Total Amount',
};

export const assignedTypeOptions = {
    plot: 'plot',
    building: 'building'
}

export const rentObjectRowDataTypes = {
    fullRent: 0,
    exemption: 1,
    rentPayable: 2,
};

export const rentObjectRowDataTypeOptions = [
    { id: rentObjectRowDataTypes.fullRent, name: () => i18next.t('contract.finances.rentExemptions.rentPaymentPlanTypeOptions.fullRent') },
    { id: rentObjectRowDataTypes.exemption, name: () => i18next.t('contract.finances.rentExemptions.rentPaymentPlanTypeOptions.exemption') },
    { id: rentObjectRowDataTypes.rentPayable, name: () => i18next.t('contract.finances.rentExemptions.rentPaymentPlanTypeOptions.rentPayable') },
];

export const contractStatuses = {
    active: 1,
    archived: 2,
};

export const contractStatusesOptions = [
    { id: contractStatuses.active, name: () => i18next.t('contracts.filterModal.activeContracts') },
    { id: contractStatuses.archived, name: () => i18next.t('contracts.filterModal.archivedContracts') },
];

// Warranty
export const warrantyType = {
    deposit: 1,
    depositGuarantee: 2,
    bank: 3,
    surety: 4,
    parentCompanyGuarantee: 5,
    rentalGuarantee: 6,
    other: 7
}

export const warrantyTypeOptions = [
    { id: warrantyType.deposit, name: () => i18next.t('contract.warrantyType.deposit') },
    { id: warrantyType.depositGuarantee, name: () => i18next.t('contract.warrantyType.depositGuarantee') },
    { id: warrantyType.bank, name: () => i18next.t('contract.warrantyType.bank') },
    { id: warrantyType.surety, name: () => i18next.t('contract.warrantyType.suretyBond') },
    { id: warrantyType.parentCompanyGuarantee, name: () => i18next.t('contract.warrantyType.parentCompanyGuarantee') },
    { id: warrantyType.rentalGuarantee, name: () => i18next.t('contract.warrantyType.rentalGuarantee') },
    { id: warrantyType.other, name: () => i18next.t('contract.warrantyType.other') },
]

export const turnoverRecurrencePeriodTypes = {
    annually: 1,
    quarterly: 2,
    monthly: 3
}

export const turnoverRecurrencePeriodTypesOptions = [
    { id: turnoverRecurrencePeriodTypes.annually, name: () => i18next.t('contract.turnover.annually') },
    { id: turnoverRecurrencePeriodTypes.monthly, name: () => i18next.t('contract.turnover.monthly') },
    { id: turnoverRecurrencePeriodTypes.quarterly, name: () => i18next.t('contract.turnover.quarterly') },
]

export const turnoverRecurrenceTypes = {
    audit: 1,
    arrearsBaseInvoice: 2
}

export const turnoverInvoiceTypes = {
    minimumRent: 1,
    budget: 2,
    arrearsBased: 3
}

export const turnoverInvoiceTypesOptions = [
    { id: turnoverInvoiceTypes.minimumRent, name: () => i18next.t('contract.turnover.invoicing.minimumRent') },
    { id: turnoverInvoiceTypes.budget, name: () => i18next.t('contract.turnover.invoicing.budget') },
    { id: turnoverInvoiceTypes.arrearsBased, name: () => i18next.t('contract.turnover.invoicing.arrearsBased') }
]

export const minimumRentTypes = {
    annuallyPerUnit: 1,
    totalAnnualAmount: 2
}

export const minimumRentTypeOptions = [
    { id: minimumRentTypes.annuallyPerUnit, name: () => i18next.t('contract.turnover.minimumYearly.anuallyPerUnit') },
    { id: minimumRentTypes.totalAnnualAmount, name: () => i18next.t('contract.turnover.minimumYearly.totalAnual') },
]

export const entityStatusRequestOptions = {
    none: 0,
    active: 1,
    archived: 2
}

export const measurementTypes = {
    units: 1,
    total: 2
}

export const measurementPeriodTypes = {
    month: 1,
    quarter: 2,
    annual: 3
}

export const measurements = [
    { id: 1, value: JSON.stringify({ measurementTypeId: measurementTypes.units, measurementPeriodTypeId: measurementPeriodTypes.annual }), name: () => i18next.t('contract.commonCost.measurements.anualUnits') },
    { id: 2, value: JSON.stringify({ measurementTypeId: measurementTypes.units, measurementPeriodTypeId: measurementPeriodTypes.quarter }), name: () => i18next.t('contract.commonCost.measurements.quarterUnits') },
    { id: 3, value: JSON.stringify({ measurementTypeId: measurementTypes.total, measurementPeriodTypeId: measurementPeriodTypes.annual }), name: () => i18next.t('contract.commonCost.measurements.anualTotal') },
    { id: 4, value: JSON.stringify({ measurementTypeId: measurementTypes.total, measurementPeriodTypeId: measurementPeriodTypes.quarter }), name: () => i18next.t('contract.commonCost.measurements.quarterTotal') },
    { id: 5, value: JSON.stringify({ measurementTypeId: measurementTypes.total, measurementPeriodTypeId: measurementPeriodTypes.month }), name: () => i18next.t('contract.commonCost.measurements.monthTotal') }
]