import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { Image } from 'react-bootstrap';
import { CloseOutlined } from '@ant-design/icons';
import { setValueCommonCost } from '../../../reducers/commonCostReducer';
import done from '../../../assets/images/popup-done.svg';
import ActionButton from '../../../components/action-button';

const CommonCostActualsAddResultComponent = (props) => {
    const { t } = useTranslation();

    const onClose = () => {
        props.closeModalWindow();
    };

    return (
        <Modal wrapClassName="result-modal" footer={null} visible={true} maskClosable={false} onCancel={onClose} closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <span className="icon">
                        <Image src={done} />
                    </span>
                    <div className="d-flex flex-column">
                        <h4 className="mb-2">{t('commonCost.actuals.result.title')}</h4>
                        <p className="text-secondary">{t('common.greatJob')}</p>
                    </div>
                </div>
                <div className="main">
                    <h6>{t('commonCost.actuals.result.mainTitle')}</h6>
                    <p className="text-secondary">{t('common.resultOrUpdateMessage')}</p>
                </div>
                <div className="btns">
                    <ActionButton onClick={onClose} className="btn-secondary" text={t('common.buttons.close')} />
                </div>
            </div>
        </Modal>
    );
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueCommonCost('showActualsAddResultModal', false));
        },
    };
};

const CommonCostActualsAddResult = connect(null, mapDispatch)(CommonCostActualsAddResultComponent);
export default CommonCostActualsAddResult;
