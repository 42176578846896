import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import trash from '../../../assets/images/trash-default.svg';
import ActionButton from '../../../components/action-button';
import AdditionalCommonCostCard from '../../../components/common-cost/additional-common-cost-card';
import PositiveNumericInput from '../../../components/numeric-input';
import { setValueOwnerCost } from '../../../reducers/ownerCostReducer';
import AddAdditionalCostModal from '../modals/create-edit-additional-cost-modal';
import { commonCostActualItemsSelector, ownerCostSelector } from '../selectors';

const CommonCostActual = ({ actualRef }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const setAdditionalCostEditModel = (cost) => {
        dispatch(setValueOwnerCost('additionalCostEditModel', cost));
    };
    const updateOwnerCostActuals = (newList) => {
        dispatch(setValueOwnerCost('ownerCost.ownerCostActuals', newList));
    };
    const showAddAdditionalCostCall = () => {
        dispatch(setValueOwnerCost('showAddAdditionalCostModal', true));
    };

    const commonCost = useSelector(ownerCostSelector);
    const ownerCostActuals = useSelector(commonCostActualItemsSelector);
    const showAddAdditionalCostModal = useSelector(({ ownerCost }) => ownerCost.showAddAdditionalCostModal);

    const [predefinedItems, setPredefinedItems] = useState([]);
    const [additionalItems, setAdditionalItems] = useState([]);

    useEffect(() => {
        const defaultItems = (ownerCostActuals || []).filter((x) => x.formId);

        const formData = defaultItems.reduce((obj, item) => Object.assign(obj, { [item.formId]: item.value }), {});

        actualRef.current.setFieldsValue(formData);
    }, []);

    useEffect(() => {
        if (ownerCostActuals) {
            setPredefinedItems(ownerCostActuals.filter((x) => x.formId));
            setAdditionalItems(ownerCostActuals.filter((x) => !x.formId));
        }
    }, [ownerCostActuals]);

    const editAdditionalCommonCost = (cost) => {
        setAdditionalCostEditModel(cost);
        showAddAdditionalCostCall();
    };

    const deleteCommonCostItem = (cost) => {
        const currentList = [...ownerCostActuals];
        const newList = currentList.filter((x) => x.uId !== cost.uId);
        updateOwnerCostActuals(newList);
    };

    const handleSaveAdditionalCost = (item) => {
        const items = [...(ownerCostActuals || [])];
        const existingItemIndex = items.findIndex((x) => (x.id > 0 && x.id === item.id) || x.uId === item.uId);

        if (existingItemIndex >= 0) {
            items[existingItemIndex] = item;
        } else {
            item.isBudget = false;
            items.push(item);
        }

        updateOwnerCostActuals(items);
    };

    const normalizeTwoDigitsAfterComma = (value) => {
        const strValue = String(value);
        const countDigitsAfterComma = strValue.includes('.') ? strValue.split('.')[1].length : 0;
        return value % 1 !== 0 && countDigitsAfterComma > 2 ? parseFloat(value).toFixed(2) : value;
    };

    return (
        <React.Fragment>
            {predefinedItems.length > 0 ? (
                <div className="page-container bottom-border">
                    <Row>
                        <Col lg={12} xl={6}>
                            {predefinedItems.map((cost) => (
                                <div key={cost.uId}>
                                    <p className="mb-2 font-weight-bold">{cost.name}</p>
                                    <div className="d-flex flex-row">
                                        <Form.Item
                                            name={cost.formId}
                                            className={`${predefinedItems[predefinedItems.length - 1].formId === cost.formId ? 'mb-0' : ''} w-100`}
                                            rules={[{ type: 'number', min: 0, message: `${cost.name} ${t('commonCost.budgetStep.cantBeLess')}` }]}>
                                            <PositiveNumericInput
                                                prefix={t('currency.norway')}
                                                onChange={() =>
                                                    actualRef.current.setFieldsValue({ [cost.formId]: normalizeTwoDigitsAfterComma(actualRef.current.getFieldValue(cost.formId)) })
                                                }
                                            />
                                        </Form.Item>
                                        <ActionButton
                                            className="btn-thirdy btn-transparent pr-0"
                                            onClick={() => deleteCommonCostItem(cost)}
                                            icon={<Image src={trash} className="m-0" />}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </div>
            ) : null}
            <div className="page-container bottom-border">
                <p className="font-weight-bold">{t('commonCost.budgetStep.additionalCosts')}</p>
                <Row>
                    <Col lg={12} xl={6}>
                        {additionalItems.map((item) => (
                            <AdditionalCommonCostCard
                                key={item.uId}
                                cost={item}
                                editAdditionalCommonCost={editAdditionalCommonCost}
                                deleteAdditionalCommonCost={deleteCommonCostItem}
                                handleSaveAdditionalCost={handleSaveAdditionalCost}
                            />
                        ))}
                    </Col>
                </Row>
                <ActionButton className="btn-secondary" onClick={showAddAdditionalCostCall} text={t('commonCost.budgetStep.additionalCosts.addBtn')} />
            </div>
            {showAddAdditionalCostModal && <AddAdditionalCostModal onSave={handleSaveAdditionalCost} additionalCostActual={true} />}
        </React.Fragment>
    );
};

export default CommonCostActual;
