import { StatusCodes } from 'http-status-codes';
import { setValuePortfolio } from './portfolioReducer';
import i18next from 'i18next';
import propertyService from '../services/propertyService';
import toaster from '../common/toaster';
import update from '../helpers/update';
import commonService from '../services/commonService';
import { filterOptions } from '../common/property-options';

const SET_VALUE_PROPERTY = 'SET_VALUE_PROPERTY';
const SUCCESS_GET_PROPERTIES = 'SUCCESS_GET_PROPERTIES';
const SUCCESS_GET_PROPERTY_BY_ID = 'SUCCESS_GET_PROPERTY_BY_ID';
const SUCCESS_UPDATE_PROPERTY = 'SUCCESS_UPDATE_PROPERTY';
const SET_ADDITIONAL_OWNER_INFO = 'SET_ADDITIONAL_OWNER_INFO';
const CLEAR_ADDITIONAL_OWNER_INFO = 'CLEAR_ADDITIONAL_OWNER_INFO';
const SUCCESS_GET_PROPERTY_KPIS = 'SUCCESS_GET_PROPERTY_KPIS';
const CLEAR_PROPERTY = 'CLEAR_PROPERTY';
const SET_DELETE_PROPERTY_MODEL = 'SET_DELETE_PROPERTY_MODEL';
const CLEAR_DELETE_PROPERTY_MODEL = 'CLEAR_DELETE_PROPERTY_MODEL';

export const defaultOwner = {
    ownedBy: commonService.ownerRadioGroupTypes.company,
};

const initialState = {
    properties: [],
    property: {
        usePortfolioOwner: false,
    },
    propertyOwnerCompanyInfo: null,

    deletePropertyModel: null,

    showDraftModal: false,

    kpis: null,
    kpisLoading: false,

    propertyLoading: false,
    propertiesLoading: false,
    deletePropertyLoading: false,
    documentsLoading: false,

    propertyNotFound: false,

    showCreationResultModal: false,
    showItemUpdatedResultModal: false,
    showConfirmDeletePropertyModal: false,

    filterOption: filterOptions.active,
};

const propertyReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_PROPERTY:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case CLEAR_DELETE_PROPERTY_MODEL:
            newState = update.set(newState, 'deletePropertyModel', null);
            break;
        case SET_DELETE_PROPERTY_MODEL:
            newState = update.set(newState, 'deletePropertyModel', action.payload);
            break;
        case SUCCESS_GET_PROPERTY_BY_ID:
            newState = update.set(newState, 'property', action.payload);
            newState = update.set(newState, 'propertyLoading', false);
            break;
        case SUCCESS_GET_PROPERTIES:
            newState = update.set(newState, 'properties', action.payload);
            break;
        case SUCCESS_UPDATE_PROPERTY:
            newState = update.set(newState, 'properties', [...newState.properties.filter((x) => x.id !== action.payload.id), action.payload]);
            newState = update.set(newState, 'property', action.payload);
            break;
        case SET_ADDITIONAL_OWNER_INFO:
            newState = update.set(newState, 'propertyOwnerCompanyInfo', action.payload);
            break;
        case CLEAR_ADDITIONAL_OWNER_INFO:
            newState = update.set(newState, 'propertyOwnerCompanyInfo', null);
            break;
        case SUCCESS_GET_PROPERTY_KPIS:
            newState = update.set(newState, 'kpis', action.payload);
            break;
        case CLEAR_PROPERTY:
            newState = update.set(newState, 'property', {
                usePortfolioOwner: false,
            });
            break;
        default:
            break;
    }

    return newState;
};

export const setValueProperty = (key, value) => {
    return {
        type: SET_VALUE_PROPERTY,
        payload: {
            key,
            value,
        },
    };
};

export const clearDeletePropertyModel = () => {
    return {
        type: CLEAR_DELETE_PROPERTY_MODEL,
    };
};

export const setDeletePropertyModel = (value) => {
    return {
        type: SET_DELETE_PROPERTY_MODEL,
        payload: value,
    };
};

export const clearAdditionalOwnerInfo = () => {
    return {
        type: CLEAR_ADDITIONAL_OWNER_INFO,
    };
};

export const setAdditionalOwnerInfo = (value) => {
    return {
        type: SET_ADDITIONAL_OWNER_INFO,
        payload: value,
    };
};

export const successGetProperty = (value) => {
    return {
        type: SUCCESS_GET_PROPERTY_BY_ID,
        payload: value,
    };
};

export const successGetProperties = (value) => {
    return {
        type: SUCCESS_GET_PROPERTIES,
        payload: value,
    };
};

export const successUpdateProperty = (value) => {
    return {
        type: SUCCESS_UPDATE_PROPERTY,
        payload: value,
    };
};

export const successGetPropertyKpis = (value) => {
    return {
        type: SUCCESS_GET_PROPERTY_KPIS,
        payload: value,
    };
};

export const clearProperty = () => {
    return {
        type: CLEAR_PROPERTY,
    };
};

const handlePropertyError = (error, dispatch) => {
    if (error && error.response && error.response.status && error.response.status === StatusCodes.NOT_FOUND) {
        dispatch(setValueProperty('propertyNotFound', true));
    } else if (error && error.response && error.response.status && error.response.status === StatusCodes.FORBIDDEN) {
        dispatch(setValuePortfolio('portfolioNotFound', true));
    } else if (error && error.response && error.response.title) {
        toaster.error(error.response.title, null);
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const createProperty = (values) => {
    return (dispatch) => {
        dispatch(setValueProperty('propertyLoading', true));
        return propertyService
            .create(values, values.portfolioId)
            .then((response) => {
                dispatch(successUpdateProperty(response.data));
                return { propertyId: response.data.id, propertyName: response.data.propertyName };
            })
            .catch((error) => handlePropertyError(error, dispatch))
            .finally(() => {
                dispatch(setValueProperty('propertyLoading', false));
            });
    };
};

export const editProperty = (values) => {
    return (dispatch) => {
        dispatch(setValueProperty('propertyLoading', true));
        return propertyService
            .update(values, values.portfolioId)
            .then((response) => {
                dispatch(successUpdateProperty(response.data));
                return response.data.id;
            })
            .catch((error) => handlePropertyError(error, dispatch))
            .finally(() => {
                dispatch(setValueProperty('propertyLoading', false));
            });
    };
};

export const deleteProperty = (propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueProperty('deletePropertyLoading', true));
        return propertyService
            .delete(propertyId, portfolioId)
            .then((_) => true)
            .catch((error) => handlePropertyError(error, dispatch))
            .finally(() => {
                dispatch(setValueProperty('deletePropertyLoading', false));
            });
    };
};

export const getPropertyById = (propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueProperty('propertyLoading', true));
        return propertyService
            .get(propertyId, portfolioId)
            .then((response) => {
                dispatch(successGetProperty(response.data));
            })
            .catch((error) => handlePropertyError(error, dispatch))
            .finally(() => {
                dispatch(setValueProperty('propertyLoading', false));
            });
    };
};

export const getPropertiesByPortfolioId = (portfolioId, propertyId) => {
    return (dispatch, getState) => {
        dispatch(setValueProperty('propertiesLoading', true));
        propertyService
            .getPropertiesByPortfolioId(portfolioId, propertyId, getState().property.filterOption)
            .then((response) => {
                dispatch(successGetProperties(response.data));
            })
            .catch((error) => handlePropertyError(error, dispatch))
            .finally(() => {
                dispatch(setValueProperty('propertiesLoading', false));
            });
    };
};

export const getPropertyKpis = (portfolioId, propertyId) => {
    return (dispatch) => {
        dispatch(setValueProperty('kpisLoading', true));
        propertyService
            .getKpis(portfolioId, propertyId)
            .then((response) => {
                dispatch(successGetPropertyKpis(response.data));
            })
            .catch((error) => handlePropertyError(error, dispatch))
            .finally(() => {
                dispatch(setValueProperty('kpisLoading', false));
            });
    };
};

export const getPropertyDocuments = (propertyId, portfolioId) => {
    return (dispatch) => {
        dispatch(setValueProperty('documentsLoading', true));
        propertyService
            .getDocuments(propertyId, portfolioId)
            .then((response) => {
                dispatch(setValueProperty('property.documents', response.data));
            })
            .catch((error) => handlePropertyError(error, dispatch))
            .finally(() => {
                dispatch(setValueProperty('documentsLoading', false));
            });
    };
};

export default propertyReducer;
