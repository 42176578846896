import i18next from 'i18next';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import languageService, { supportedLanguages } from './languageService';

export const predefinedColors = ['#A105D8', '#7000FF', '#0092E3', '#3AC5B4', '#3B8561', '#3AC569', '#BEB819', '#BE9019'];

const setNumberFormatterLocale = (chart) => {
    chart.numberFormatter.language.locale = languageService.getLanguageForCharts(languageService.getDefaultLanguage());
    chart.numberFormatter.language.locale["_thousandSeparator"] = " "; 
    chart.numberFormatter.language.locale["_decimalSeparator"] = languageService.getDefaultLanguage() == supportedLanguages.Norway ? "," : '.';
};

const chartService = {
    rentPerPropertyChart: (id, data) => {
        var chart = am4core.create(id, am4charts.XYChart);

        chart.data = data || [];

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'id';
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 2;
        categoryAxis.renderer.line.stroke = am4core.color('rgb(61, 62, 67)');
        categoryAxis.renderer.labels.template.disabled = true;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.minGridDistance = 60;
        valueAxis.renderer.grid.template.strokeWidth = 2;
        valueAxis.renderer.grid.template.strokeDasharray = '9,3';
        valueAxis.renderer.grid.template.strokeOpacity = 0.1;
        valueAxis.renderer.grid.template.stroke = am4core.color('rgb(183, 178, 178)');
        valueAxis.renderer.labels.template.fill = am4core.color('#B7B2B2');
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.min = 0;

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = 'yearlyIncome';
        series.dataFields.categoryX = 'id';
        series.tooltipText = '{propertyName}: {valueY}';
        series.tooltip.pointerOrientation = 'vertical';
        series.columns.template.strokeWidth = 0;
        series.columns.template.column.fillOpacity = 1;
        series.columns.template.width = am4core.percent(65);
        series.columns.template.fill = am4core.color('#376FFF');

        let bullet = series.columns.template.createChild(am4core.RoundedRectangle);
        bullet.align = 'center';
        bullet.valign = 'top';
        bullet.fillOpacity = 1;
        bullet.width = am4core.percent(100);
        bullet.height = 8;
        bullet.strokeWidth = 0;
        bullet.cornerRadiusTopLeft = 8;
        bullet.cornerRadiusTopRight = 8;
        bullet.cornerRadiusBottomLeft = 0;
        bullet.cornerRadiusBottomRight = 0;
        bullet.dy = -6;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'none';
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;

        series.columns.template.adapter.add('fill', (fill, target) => {
            return am4core.color(predefinedColors[target.dataItem.index % predefinedColors.length]);
        });

        return chart;
    },

    costsBudgetsChart: (id, data) => {
        var chart = am4core.create(id, am4charts.XYChart);
        setNumberFormatterLocale(chart);
        chart.data = data || [];

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'name';
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 2;
        categoryAxis.renderer.line.stroke = am4core.color('rgb(61, 62, 67)');
        categoryAxis.renderer.labels.template.disabled = true;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.minGridDistance = 60;
        valueAxis.renderer.grid.template.strokeWidth = 2;
        valueAxis.renderer.grid.template.strokeDasharray = '9,3';
        valueAxis.renderer.grid.template.strokeOpacity = 0.1;
        valueAxis.renderer.grid.template.stroke = am4core.color('rgb(183, 178, 178)');
        valueAxis.renderer.labels.template.fill = am4core.color('#B7B2B2');
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.min = 0;

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = 'value';
        series.dataFields.categoryX = 'name';
        series.tooltipText = '{name}:\n{valueY}';
        series.tooltip.pointerOrientation = 'vertical';
        series.columns.template.strokeWidth = 0;
        series.columns.template.column.fillOpacity = 1;
        series.columns.template.width = am4core.percent(66);
        series.columns.template.fill = am4core.color('#376FFF'); // fill

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'none';
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;

        series.columns.template.adapter.add('fill', (fill, target) => {
            return am4core.color(predefinedColors[target.dataItem.index % predefinedColors.length]);
        });

        let bullet = series.columns.template.createChild(am4core.RoundedRectangle);
        bullet.align = 'center';
        bullet.valign = 'top';
        bullet.fillOpacity = 1;
        bullet.width = am4core.percent(100);
        bullet.height = 8;
        bullet.strokeWidth = 0;
        bullet.cornerRadiusTopLeft = 8;
        bullet.cornerRadiusTopRight = 8;
        bullet.cornerRadiusBottomLeft = 0;
        bullet.cornerRadiusBottomRight = 0;
        bullet.dy = -6;

        return chart;
    },

    costsBudgetsAndActualsChart: (id, data) => {
        var chart = am4core.create(id, am4charts.XYChart);
        setNumberFormatterLocale(chart);
        chart.data = data || [];

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'name';
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 2;
        categoryAxis.renderer.line.stroke = am4core.color('rgb(61, 62, 67)');
        categoryAxis.renderer.labels.template.disabled = true;

        categoryAxis.renderer.cellStartLocation = 0.16;
        categoryAxis.renderer.cellEndLocation = 0.83;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.minGridDistance = 60;
        valueAxis.renderer.grid.template.strokeWidth = 2;
        valueAxis.renderer.grid.template.strokeDasharray = '9,3';
        valueAxis.renderer.grid.template.strokeOpacity = 0.1;
        valueAxis.renderer.grid.template.stroke = am4core.color('rgb(183, 178, 178)');
        valueAxis.renderer.labels.template.fill = am4core.color('#B7B2B2');
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.min = 0;

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = 'valueBudget';
        series.tooltipText = `{name}
        ${i18next.t('commonCost.analysisDetails.budget')}: {valueBudget}
        ${i18next.t('commonCost.analysisDetails.actual')}: {valueActual}`;
        configureSeries(series);
        series.columns.template.column.fillOpacity = 0.5;

        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = 'valueActual';
        configureSeries(series2);

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'none';
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;

        addFillAdapter(series);
        addFillAdapter(series2);

        var bullet1 = createBullet(series);
        bullet1.opacity = 0.5;
        bullet1.dy = -8;
        createBullet(series2);

        function configureSeries(target) {
            target.dataFields.categoryX = 'name';
            target.tooltip.pointerOrientation = 'vertical';
            target.columns.template.strokeWidth = 0;
            target.columns.template.column.fillOpacity = 1;
            target.columns.template.width = am4core.percent(100);
            target.columns.template.fill = am4core.color('#376FFF'); // fill
        }

        function addFillAdapter(target) {
            target.columns.template.adapter.add('fill', (fill, target) => {
                return am4core.color(predefinedColors[target.dataItem.index % predefinedColors.length]);
            });
        }

        function createBullet(target) {
            let bullet = target.columns.template.createChild(am4core.RoundedRectangle);
            bullet.align = 'center';
            bullet.valign = 'top';
            bullet.fillOpacity = 1;
            bullet.width = am4core.percent(100);
            bullet.height = 8;
            bullet.strokeWidth = 0;
            bullet.cornerRadiusTopLeft = 8;
            bullet.cornerRadiusTopRight = 8;
            bullet.cornerRadiusBottomLeft = 0;
            bullet.cornerRadiusBottomRight = 0;
            bullet.dy = -6;

            return bullet;
        }

        return chart;
    },

    rentPerTenantChart: (id, data) => {
        var chart = am4core.create(id, am4charts.XYChart);
        setNumberFormatterLocale(chart);
        chart.data = data || [];

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'id';
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 2;
        categoryAxis.renderer.line.stroke = am4core.color('rgb(61, 62, 67)');
        categoryAxis.renderer.labels.template.disabled = true;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.minGridDistance = 60;
        valueAxis.renderer.grid.template.strokeWidth = 2;
        valueAxis.renderer.grid.template.strokeDasharray = '9,3';
        valueAxis.renderer.grid.template.strokeOpacity = 0.1;
        valueAxis.renderer.grid.template.stroke = am4core.color('rgb(183, 178, 178)');
        valueAxis.renderer.labels.template.fill = am4core.color('#B7B2B2');
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.min = 0;

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = 'value';
        series.dataFields.categoryX = 'id';
        series.tooltipText = '{name}:\n{valueY}';
        series.tooltip.pointerOrientation = 'vertical';
        series.columns.template.strokeWidth = 0;
        series.columns.template.column.cornerRadiusTopLeft = 4;
        series.columns.template.column.cornerRadiusTopRight = 4;
        series.columns.template.column.fillOpacity = 1;
        series.columns.template.width = am4core.percent(65);
        series.columns.template.fill = am4core.color('#376FFF'); // fill

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'none';
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;

        series.columns.template.adapter.add('fill', (fill, target) => {
            return am4core.color(predefinedColors[target.dataItem.index % predefinedColors.length]);
        });

        let bullet = series.columns.template.createChild(am4core.RoundedRectangle);
        bullet.align = 'center';
        bullet.valign = 'top';
        bullet.fillOpacity = 1;
        bullet.width = am4core.percent(100);
        bullet.height = 8;
        bullet.strokeWidth = 0;
        bullet.cornerRadiusTopLeft = 8;
        bullet.cornerRadiusTopRight = 8;
        bullet.cornerRadiusBottomLeft = 0;
        bullet.cornerRadiusBottomRight = 0;
        bullet.dy = -6;

        // var legend = new am4charts.Legend();
        // chart.legend = legend;
        // var markerTemplate = legend.markers.template;
        // markerTemplate.width = 8;
        // markerTemplate.height = 8;
        // var marker = legend.markers.template.children.getIndex(0);
        // marker.cornerRadius(8, 8, 8, 8);

        // legend.labels.template.fill = am4core.color("#B7B2B2");
        // legend.labels.template.fontSize = 12;
        // legend.valueLabels.template.fill = am4core.color("#fff");
        // legend.valueLabels.template.fontSize = 12;
        // legend.valueLabels.template.align = 'left';
        // legend.valueLabels.template.textAlign = 'start';

        // legend.itemContainers.template.clickable = false;
        // legend.itemContainers.template.focusable = false;
        // legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
        // legend.contentAlign = "left";
        // legend.position = 'bottom';
        // legend.data = chart.data;

        return chart;
    },

    rentExpirationProfileChart: (id, data) => {
        var chart = am4core.create(id, am4charts.XYChart);
        setNumberFormatterLocale(chart);
        chart.data = data || [];

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'year';
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.numberFormatter.numberFormat = '#';
        categoryAxis.renderer.labels.template.fill = am4core.color('#B7B2B2');
        categoryAxis.renderer.labels.template.fontSize = 14;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 2;
        categoryAxis.renderer.line.stroke = am4core.color('rgb(61, 62, 67)');

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 50;
        valueAxis.min = 0;
        valueAxis.max = 100;
        //valueAxis.strictMinMax = true;
        valueAxis.numberFormatter = new am4core.NumberFormatter();
        valueAxis.numberFormatter.numberFormat = "#'%'";
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.minGridDistance = 60;
        valueAxis.renderer.grid.template.strokeWidth = 2;
        valueAxis.renderer.grid.template.strokeDasharray = '9,3';
        valueAxis.renderer.grid.template.strokeOpacity = 0.1;
        valueAxis.renderer.grid.template.stroke = am4core.color('rgb(183, 178, 178)');
        valueAxis.renderer.labels.template.fill = am4core.color('#B7B2B2');
        valueAxis.renderer.labels.template.fontSize = 12;

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.sequencedInterpolation = true;
        series.dataFields.valueY = 'yearlyIncomePercentage';
        series.dataFields.categoryX = 'year';
        series.tooltipText = "{valueY.formatNumber('#')}%[/]";
        series.tooltip.pointerOrientation = 'vertical';
        series.columns.template.strokeWidth = 0;
        series.columns.template.column.cornerRadiusTopLeft = 4;
        series.columns.template.column.cornerRadiusTopRight = 4;
        series.columns.template.column.fillOpacity = 0.3;
        series.columns.template.width = am4core.percent(37);
        series.columns.template.fill = am4core.color('#376FFF'); // fill

        var hoverState = series.columns.template.column.states.create('hover');
        hoverState.properties.fillOpacity = 1;

        var paretoSeries = chart.series.push(new am4charts.LineSeries());
        paretoSeries.dataFields.valueY = 'remainingPercentage';
        paretoSeries.dataFields.categoryX = 'year';
        paretoSeries.yAxis = valueAxis;
        paretoSeries.tooltipText = "{valueY.formatNumber('#')}%[/]";
        paretoSeries.bullets.push(new am4charts.CircleBullet());
        paretoSeries.strokeWidth = 2;
        paretoSeries.fill = new am4core.color('#C1CFFF');
        paretoSeries.stroke = new am4core.color('#C1CFFF');
        paretoSeries.strokeOpacity = 1;

        // Cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'panX';

        chart.cursor.lineX.stroke = am4core.color('rgb(183, 178, 178)');
        chart.cursor.lineX.strokeWidth = 2;
        chart.cursor.lineX.strokeOpacity = 0.1;

        chart.cursor.lineY.stroke = am4core.color('rgb(183, 178, 178)');
        chart.cursor.lineY.strokeWidth = 2;
        chart.cursor.lineY.strokeOpacity = 0.1;

        chart.seriesContainer.zIndex = 1;
        return chart;
    },

    rentalPercentageChart: (id, data) => {
        var chart = am4core.create(id, am4charts.PieChart);
        setNumberFormatterLocale(chart);
        chart.data = data.chartData;

        var series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = 'value';
        series.dataFields.radiusValue = 'radius';
        series.labels.template.disabled = true;
        series.slices.template.tooltipText = '';
        series.slices.template.propertyFields.fill = 'color';

        var slice = series.slices.template;
        slice.states.getKey('hover').properties.scale = 1;
        slice.states.getKey('active').properties.shiftRadius = 0;
        series.slices.template.strokeWidth = 4;
        series.slices.template.stroke = new am4core.color('#303136');

        chart.innerRadius = am4core.percent(55);
        chart.seriesContainer.align = 'left';

        var label = chart.seriesContainer.createChild(am4core.Label);
        label.textAlign = 'middle';
        label.horizontalCenter = 'middle';
        label.verticalCenter = 'middle';
        label.wrap = true;
        label.maxWidth = 75;

        label.adapter.add('html', (text, target) => {
            return `<div class="chart-label">
                    <p class="label-value">${data.percent}%</p>
                    <p class="label-title text-secondary">${i18next.t('tenant.details.summary.rentalPercentageTitle')}</p>
                </div>`;
        });

        return chart;
    },
};

export default chartService;
