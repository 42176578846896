import React from 'react';
import { Image } from 'react-bootstrap';
import propertyInfoImage from '../../assets/images/property-for-info-container.svg';

const InformationComponent = ({ showImage = true, title, text }) => {
    return (
        <div className="information-container">
            {showImage && <Image src={propertyInfoImage} />}
            {title && <h5>{title}</h5>}
            {text && <p className="text-secondary">{text}</p>}
        </div>
    );
};

export default InformationComponent;
