import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setValueContract } from '../../../reducers/contractReducer';
import get from 'lodash-es/get';
import ConfirmDeleteModal from '../../../components/confirm-delete-modal';

const ConfirmDeleteContractComponent = (props) => {
    const { t } = useTranslation();

    const handleCloseClick = () => {
        props.closeModalWindow();
    };

    const handleDeleteClick = () => {
        props.onConfirm(props.contract);
    };

    return (
        <ConfirmDeleteModal
            onClose={handleCloseClick}
            onConfirm={handleDeleteClick}
            title={t('contract.delete.title', { name: props.contract.name })}
            text={t('contract.delete.sentence')}
            loading={props.deleteLoading}
            deleteBtnText={t('contract.delete.deleteBtnText')}
        />
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contractToDelete'),
        deleteLoading: get(contract, 'deleteLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('contractToDelete', null));
        },
    };
};

const ConfirmDeleteContract = connect(mapState, mapDispatch)(ConfirmDeleteContractComponent);
export default ConfirmDeleteContract;
