import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Form } from 'antd';
import { downloadFile, editBuilding, setValueBuilding, getBuildingById } from '../../../reducers/buildingReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';
import objectHelper from '../../../helpers/trim-object';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import propertyName from '../../../assets/images/property.svg';
import review from '../../../assets/images/review.svg';
import InformationComponent from '../../../components/information-container';
import ObjectLayout from '../../../components/layouts/object-layout';
import BuildingConstructor from '../building-constructor';
import NotFoundPage from '../../../components/error-page';
import LeaveModal from '../../../components/leave-modal';
import BuildingReview from './review-step';
import DetailsStep from './details-step';
import routingService from '../../../services/routingService';
import * as rounding from '../../../helpers/number-helper';

const EditBuildingComponent = (props) => {
    const [buildingForm] = Form.useForm();

    const stepNames = {
        details: 'details',
        review: 'review',
    };

    const { t } = useTranslation();
    const detailsRef = React.useRef();
    const [currentStepKey, setCurrentStepKey] = useState(stepNames.details);
    const { propertyId, portfolioId, buildingId } = useParams();

    useEffect(() => {
        props.getPropertyCall(portfolioId, propertyId);
        props.getBuildingByIdCall(portfolioId, buildingId, propertyId);
    }, []);

    const goOutOfPage = () => {
        routingService.navigateToBuildings(portfolioId, propertyId);
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious();
        } else {
            onNext();
        }
    };

    const onPrevious = () => {
        setCurrentStepKey(stepNames.details);
    };

    const onNext = async () => {
        try {
            await validateDetailsRef();
        } catch (err) {
            props.togglePerFloor(false);
            throw err;
        }

        try {
            await buildingForm.validateFields();
        } catch (err) {
            props.togglePerFloor(true);
            throw err;
        }

        const buildingConstructorData = props.buildingConstructorData;

            let level = buildingConstructorData.floors.length;
            if (level > 0) {
                buildingConstructorData.floors = buildingConstructorData.floors.map((obj) => ({ ...obj, level: level-- }));
            }
    
            if (buildingConstructorData.basementFloors.length > 0) {
                let basementLevel = 1;
                buildingConstructorData.basementFloors = buildingConstructorData.basementFloors.map((obj) => ({ ...obj, level: -basementLevel++ }));
            }
            const building = getBuildingFromForm();
            building.id = props.building.id;
            building.floorsData = props.building.floorsData;
            building.plans = props.building.plans;
            building.perFloor = props.building.perFloor;
            building.buildingHasName = props.building.buildingHasName;
            building.assignedPlots = props.building.assignedPlots;
            building.floorsData = {
                floors: buildingConstructorData.floors,
                basementFloors: buildingConstructorData.basementFloors,
            };
            props.setValueBuildingCall('building.floorsData', buildingConstructorData);
            props.setValueBuildingCall('building', building);
    
            setCurrentStepKey(stepNames.review);
    };

    const validateDetailsRef = () => {
        return detailsRef.current.validateFields();
    };

    const getBuildingFromForm = () => {
        const values = detailsRef.current.getFieldsValue();
        const building = objectHelper.trimObjectProperties(values);
        return building;
    };

    const goToEdit = (stepKey) => {
        setCurrentStepKey(stepKey);
    };

    const handleDownload = (file) => {
        props.downloadFileCall(file, propertyId, portfolioId);
    };

    const onSaveAndExit = () => {
        let building = { ...props.building };
        building.propertyId = +propertyId;
        const floors = building.floorsData.floors && building.perFloor ? building.floorsData.floors.map((obj) => ({ ...obj, isBasement: false })) : [];
        const basementFloors = building.floorsData.basementFloors && building.perFloor ? building.floorsData.basementFloors.map((obj) => ({ ...obj, isBasement: true })) : [];
        const allFloors = floors.concat(basementFloors);
        building.floors = allFloors;
        building.buildYear = building.buildYear ? +building.buildYear : null;
        building.levels = !building.perFloor ? (building.levels ? +building.levels : null) : allFloors.length;
        building.commonArea = !building.perFloor ? (building.commonArea ? +building.commonArea : null) : 0;
        building.grossArea = !building.perFloor ? (building.grossArea ? +building.grossArea : null) : 0;
        building.nonLettable = !building.perFloor ? building.nonLettable || 0 : 0;
        building.lettable = building.grossArea - building.nonLettable;

        if (currentStepKey === stepNames.details) {
            validateDetailsRef()
                .then((_) => {
                    const buildingForm = getBuildingFromForm();
                    building.assignedPlots = props.building.assignedPlots;
                    building.address = buildingForm.address;
                    building.name = buildingForm.name;
                    building.buildYear = buildingForm.buildYear ? +buildingForm.buildYear : null;
                    building.levels = !building.perFloor ? (building.levels ? +building.levels : null) : allFloors.length;
                    building.commonArea = !building.perFloor ? (building.commonArea ? +building.commonArea : null) : 0;
                    building.grossArea = !building.perFloor ? (building.grossArea ? +building.grossArea : null) : 0;
                    building.nonLettable = !building.perFloor ? (building.nonLettable ? +building.nonLettable : null) : 0;
                    building.lettable = !building.perFloor ? building.grossArea - building.nonLettable : 0;

                    props.editBuildingCall(building, portfolioId).then((isUpdated) => updated(isUpdated));
                })
                .catch((_) => { });
        } else {
            props.editBuildingCall(building, portfolioId).then((isUpdated) => updated(isUpdated));
        }
    };

    const updated = (isUpdated) => {
        if (isUpdated) {
            goOutOfPage();
            props.showBuildingUpdatedResult();
        }
    };

    const onOpenCloseDraftModal = () => {
        props.setValueBuildingCall('showDraftModal', !props.showDraftModal);
    };

    const steps = [
        {
            key: stepNames.details,
            header: t('building.editBuilding'),
            stepTitle: t('building.editBuilding'),
            logo: propertyName,
            canShowAsCompleted: true,
            content: (
                <div className="page-container">
                    <DetailsStep handleDownload={handleDownload} detailsRef={detailsRef} buildingForm={buildingForm} />
                </div>
            ),
        },
        {
            key: stepNames.review,
            header: t('building.review.header'),
            stepTitle: t('building.review.stepTitle'),
            logo: review,
            content: <BuildingReview handleDownload={handleDownload} goToStep={goToEdit} />,
        },
    ];

    return (
        <React.Fragment>
            {props.buildingNotFound ? <NotFoundPage header={t('building.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {!props.propertyNotFound && !props.buildingNotFound ? (
                <Form name="basic" autoComplete="off" className="h-100" ref={detailsRef}>
                    <ObjectLayout
                        bottomRightSideBar={
                            <React.Fragment>
                                {currentStepKey === stepNames.details && <InformationComponent title={t('building.information.title')} text={t('building.information.text')} />}
                            </React.Fragment>
                        }
                        steps={steps}
                        timelineSteps={steps}
                        isEdit={true}
                        loading={props.buildingLoading || props.propertyLoading}
                        currentStepKey={currentStepKey}
                        exitUrl={routingService.buildingsUrl(portfolioId, propertyId)}
                        onNext={onNext}
                        onPrevious={onPrevious}
                        onSwitchStep={onSwitchStep}
                        onSaveAndExit={onSaveAndExit}
                        onCancel={onOpenCloseDraftModal}
                    />
                    {props.showConstructorModal && <BuildingConstructor buildingRef={detailsRef} />}
                </Form>
            ) : null}
            {props.showDraftModal && <LeaveModal onLeave={goOutOfPage} onSave={onSaveAndExit} onCancel={onOpenCloseDraftModal} loading={props.buildingLoading} />}
        </React.Fragment>
    );
};

const mapState = ({ building, property }) => {
    return {
        building: get(building, 'building'),
        perFloor: get(building, 'perFloor'),
        buildingLoading: get(building, 'buildingLoading'),
        buildingNotFound: get(building, 'buildingNotFound'),
        propertyLoading: get(property, 'propertyLoading'),
        propertyNotFound: get(property, 'propertyNotFound'),
        showDraftModal: get(building, 'showDraftModal'),
        showConstructorModal: get(building, 'showConstructorModal'),
        buildingConstructorData: get(building, 'buildingConstructorData'),
        floors: get(building, 'buildingConstructorData.floors'),
        basementFloors: get(building, 'buildingConstructorData.basementFloors'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueBuildingCall(key, value) {
            dispatch(setValueBuilding(key, value));
        },
        showBuildingUpdatedResult() {
            dispatch(setValueBuilding('showItemUpdatedResultModal', true));
        },
        getBuildingByIdCall(portfolioId, buildingId, propertyId) {
            dispatch(getBuildingById(portfolioId, buildingId, propertyId));
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        downloadFileCall(file, propertyId, portfolioId) {
            return dispatch(downloadFile(file, propertyId, portfolioId));
        },
        editBuildingCall(values, portfolioId) {
            return dispatch(editBuilding(values, portfolioId));
        },
        togglePerFloor(perFloor) {
            return dispatch(setValueBuilding('building.perFloor', perFloor));
        }
    };
};

const EditBuilding = clear(connect(mapState, mapDispatch)(EditBuildingComponent));
export default EditBuilding;
