import { CloudUploadOutlined } from '@ant-design/icons';
import { Popconfirm, Upload } from 'antd';
import get from 'lodash-es/get';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import clear from '../../../common/clear';
import toaster from '../../../common/toaster';
import ActionButton from '../../../components/action-button';
import MainLayout from '../../../components/layouts/main-layout';
import { compareTranslations, exportTranslations, importTranslationsIntoDb, setValueTranslations } from '../../../reducers/translationsReducer';
import { supportedLanguages } from '../../../services/languageService';
import TranslationTable from './translations-table';
//import flat from 'flat';

const ImportTranslationsComponent = (props) => {
    const { t } = useTranslation();
    const [module, setModule] = useState('frontend');
    const [sourceLanguage, setSourceLang] = useState(supportedLanguages.English);
    const [destinationLanguage, setDestinationLang] = useState(supportedLanguages.Norway);

    useEffect(() => {
        props.setLocalizationRecordsCall([]);
        const tableData = mapTranslationsToRenderTable(props.importedLocalizationRecords);
        props.setLocalizationRecordsCall(tableData);
    }, [props.importedLocalizationRecords]);

    const mapTranslationsToRenderTable = (translations) => {
        if (!translations) {
            return [];
        }

        const sourceLanguageTranslations = (translations.sourceLanguageValues || []).reduce((result, item) => {
            result[item.key] = item.value;
            return result;
        }, {});
        const destinationLanguageTranslations = (translations.destinationLanguageValues || []).reduce((result, item) => {
            result[item.key] = item.value;
            return result;
        }, {});
        const keys = [...Object.keys(sourceLanguageTranslations), ...Object.keys(destinationLanguageTranslations)];
        const uniqueKeys = [...new Set(keys)];

        let data = uniqueKeys
            .map((key) => ({
                key,
                sourceLanguageValue: sourceLanguageTranslations[key],
                destinationLanguageValue: destinationLanguageTranslations[key],
                missingTranslations: sourceLanguageTranslations[key] && destinationLanguageTranslations[key],
            }))
            .sort((a, b) => a.key.localeCompare(b.key));
        data = [...data.filter((x) => !x.missingTranslations), ...data.filter((x) => x.missingTranslations)];

        return data;
    };

    const importToDb = () => {
        const request = getImportRequest();
        props.importTranslationsCall(request).then((isSuccess) => {
            isSuccess && toaster.success(t('translationManager.importSuccess'));
        });
    };

    const handleRemoveKey = (key) => {};

    const handleChangeKey = (oldKey, newKey) => {};

    const handleChangeTranslation = (data, language, value) => {};

    const beforeUpload = (file) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
            const content = e.target.result;
            try {
                const json = JSON.parse(content);
                setImportedLocalizationRecordsFromFile(json);
            } catch (error) {
                alert(error);
            }
        };
        return false;
    };

    const setImportedLocalizationRecordsFromFile = (translations) => {
        const sourceLanguageValues = translations[sourceLanguage] || [];
        const destinationLanguageValues = translations[destinationLanguage] || [];

        const value = {
            sourceLanguageValues,
            destinationLanguageValues,
        };
        props.setImportedLocalizationRecordsCall(value);
    };

    const handleExport = () => {
        props.exportTranslationsCall(module).then((response) => {
            if (response) {
                const json = JSON.stringify(response, null, 4);
                const url = window.URL.createObjectURL(new Blob([json]));
                const link = window.document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'translations.json');
                window.document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        });
    };

    const handleCompare = () => {
        const request = getImportRequest();
        props.compareTranslationsCall(request);
    };

    const getImportRequest = (_) => {
        const sourceLanguageValues = props.importedLocalizationRecords.sourceLanguageValues;
        const destinationLanguageValues = props.importedLocalizationRecords.destinationLanguageValues;

        return {
            module,
            sourceLanguage,
            destinationLanguage,
            sourceLanguageValues,
            destinationLanguageValues,
        };
    };

    const saveMerged = (_) => {
        const object = {
            [sourceLanguage]: props.importedLocalizationRecords.sourceLanguageValues,
            [destinationLanguage]: props.importedLocalizationRecords.destinationLanguageValues.sort((a, b) => a.key.localeCompare(b.key)),
        };
        const json = JSON.stringify(object, null, 4);
        const url = window.URL.createObjectURL(new Blob([json]));
        const link = window.document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'translations_merged.json');
        window.document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    return (
        <MainLayout>
            <Row noGutters>
                <Col md={12}>
                    <div className="translation-manager">
                        <div className="d-flex flex-row justify-content-between">
                            <h3 className="mb-4">{t('translationManager.importTitle')}</h3>
                            <ActionButton text={t('translationManager.export')} className="ml-auto mr-5 btn-primary" loading={props.loading} onClick={handleExport} />
                            <Upload className="mr-2" beforeUpload={beforeUpload}>
                                <ActionButton className="btn-secondary" text={'Select File'} icon={<CloudUploadOutlined />} />
                            </Upload>
                            <ActionButton
                                text={t('translationManager.merge')}
                                className="mr-2 btn-primary"
                                loading={props.loading}
                                disabled={!props.importedLocalizationRecords}
                                onClick={handleCompare}
                            />
                            <ActionButton
                                text={t('translationManager.saveMerged')}
                                className="mr-2 btn-primary"
                                disabled={!props.importedLocalizationRecords}
                                onClick={saveMerged}
                            />
                            <Popconfirm
                                overlayClassName="translation-manager-confirm"
                                title={'It will delete all existing records from database. ' + t('common.areYouSure')}
                                cancelButtonProps={{ className: 'btn-secondary-sm' }}
                                okButtonProps={{ className: 'btn-primary-sm' }}
                                onConfirm={importToDb}>
                                <ActionButton text={t('translationManager.import')} className="btn-primary" disabled={!props.importedLocalizationRecords} loading={props.loading} />
                            </Popconfirm>
                        </div>
                        {props.deletedLocalizationKeys && (
                            <div>
                                <h5>{t('translationManager.keysThatWereRemoved')}</h5>
                                {props.deletedLocalizationKeys.map((key) => (
                                    <p key={key}>{key}</p>
                                ))}
                            </div>
                        )}
                        {props.addedLocalizationKeys && (
                            <div>
                                <h5>{t('translationManager.keysThatWereAdded')}</h5>
                                {props.addedLocalizationKeys.map((key) => (
                                    <p key={key}>{key}</p>
                                ))}
                            </div>
                        )}
                        <TranslationTable
                            module={module}
                            sourceLang={sourceLanguage}
                            destinationLang={destinationLanguage}
                            onRemove={handleRemoveKey}
                            onChangeKey={handleChangeKey}
                            onChangeTranslation={handleChangeTranslation}
                        />
                    </div>
                </Col>
            </Row>
        </MainLayout>
    );
};

const mapState = ({ translations }) => {
    return {
        importedLocalizationRecords: get(translations, 'importedLocalizationRecords'),
        deletedLocalizationKeys: get(translations, 'deletedLocalizationKeys'),
        addedLocalizationKeys: get(translations, 'addedLocalizationKeys'),
        loading: get(translations, 'importExportLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setLocalizationRecordsCall(value) {
            dispatch(setValueTranslations('localizationRecords', value));
        },
        setImportedLocalizationRecordsCall(value) {
            dispatch(setValueTranslations('importedLocalizationRecords', value));
        },
        exportTranslationsCall(module) {
            return dispatch(exportTranslations(module));
        },
        importTranslationsCall(data) {
            return dispatch(importTranslationsIntoDb(data));
        },
        compareTranslationsCall(data) {
            dispatch(compareTranslations(data));
        },
    };
};

const ImportTranslations = clear(connect(mapState, mapDispatch)(ImportTranslationsComponent));
export default ImportTranslations;
