import i18next from 'i18next';

export const enterBtnCode = 13;
export const maxPercents = 100;
export const daysInYear = 365;

export const deadlineTypes = [
    {
        id: 1,
        name: () => i18next.t('warranty.warrantyDeadlineTypes.days'),
    },
    {
        id: 2,
        name: () => i18next.t('warranty.warrantyDeadlineTypes.weeks'),
    },
    {
        id: 3,
        name: () => i18next.t('warranty.warrantyDeadlineTypes.months'),
    },
];

export const warrantyTypes = [
    {
        id: 1,
        name: () => i18next.t('warranty.warrantyTypes.deposit'),
    },
    {
        id: 2,
        name: () => i18next.t('warranty.warrantyTypes.depositGuarantee'),
    },
    {
        id: 3,
        name: () => i18next.t('warranty.warrantyTypes.bank'),
    },
    {
        id: 4,
        name: () => i18next.t('warranty.warrantyTypes.surety'),
    },
    {
        id: 5,
        name: () => i18next.t('warranty.warrantyTypes.parentCompanyGuarantee'),
    },
    {
        id: 6,
        name: () => i18next.t('warranty.warrantyTypes.rentalGuarantee')
    },
    {
        id: 7,
        name: () => i18next.t('warranty.warrantyTypes.other')
    },
];

export const userPermisionsTypes = {
    portefolioOwner: 1,
    portefolioAdmin: 2,
    propertyEditor: 3,
    propertyReader: 4,
    rentRolleUser: 5
}

export const userPermisionsTypesOptions = [
    { id: userPermisionsTypes.portefolioOwner, name: () => i18next.t('portfolio.userPermisions.portefolioOwner'), disabled: true },
    { id: userPermisionsTypes.portefolioAdmin, name: () => i18next.t('portfolio.userPermisions.portefolioAdmin'), disabled: false },
    { id: userPermisionsTypes.propertyEditor, name: () => i18next.t('portfolio.userPermisions.propertyEditor'), disabled: false },
    { id: userPermisionsTypes.propertyReader, name: () => i18next.t('portfolio.userPermisions.propertyReader'), disabled: false },
    { id: userPermisionsTypes.rentRolleUser, name: () => i18next.t('portfolio.userPermisions.rentRolleUser'), disabled: true },
];

export const editPermisionTypes = [userPermisionsTypes.portefolioAdmin, userPermisionsTypes.portefolioOwner, userPermisionsTypes.propertyEditor];

export const notificationTypes = {
    ContractLeaseDurationOptions: 1,
    RentObjectLeaseDurationOptions: 2,
    ContractWarranty: 3,
};

export const leaseDurationOptionTypes = {
    extendTo: 1,
    terminateTo: 2,
};

export const localStorageKeys = {
    identity: 'IDENTITY',
    langKey: 'lang',
    userKey: 'currentUser',
};

export const pdfExtension = 'pdf';
