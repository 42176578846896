import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import get from 'lodash.get';
import helper from '../../helpers/helper';
import chartService from '../../services/chartService';
import NumberFormat from '../../components/number-format';

class RentPerTenantChartComponent extends React.Component {
    id = 'rent-per-tenant-chart';

    constructor(props) {
        super(props);
        this.state = { chart: null };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.setState({
            chart: chartService.rentPerTenantChart(this.id, this.props.chartData),
        });
    }

    render() {
        return (
            <div>
                <div className="chart-title">
                    <span>{i18next.t('property.rentPerTenantChart.name')}</span>
                    <div className="value-title text-secondary">
                        <NumberFormat value={this.props.totalIncome || 0} />
                        <p className="label text-secondary">{i18next.t('property.rentPerTenantChart.totalContractIncomeYear')}</p>
                    </div>
                </div>
                <div id={this.id} style={{ height: '224px' }}></div>
            </div>
        );
    }
}

const mapState = ({ property }) => {
    return {
        chartData: get(property, 'kpis.rentPerTenantChartData'),
        totalIncome: get(property, 'kpis.totalContractsIncomeYear'),
    };
};

const RentPerTenantChart = connect(mapState)(RentPerTenantChartComponent);
export default RentPerTenantChart;
