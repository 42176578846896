import axios from 'axios';

const companyService = {
    getCompaniesList: (searchText, cancelToken) => {
        if (searchText && searchText.length) {
            return axios.get(`/api/contract/companies?searchText=${encodeURIComponent(searchText)}`, { cancelToken: cancelToken.token });
        }
        return axios.get(`/api/contract/companies`);
    },

    getCompanyByOrgNumber: (orgNumber) => {
        return axios({
            method: 'get',
            url: `api/contract/companies/get-by-orgnumber/${orgNumber}`,
        });
    },
};

export default companyService;
