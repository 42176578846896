import get from 'lodash-es/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setPropertyToRentalObject, setValueContract } from '../../../../reducers/contractReducer';
import AddRentExemptionBlock from './add-rent-exemption-block';
import AddStairRentInterval from './add-stair-rent-interval';
import RentExemptionModal from './modals/rent-exemption-modal';
import StairRentInterval from './modals/stair-rent-interval-modal';
import RentPaymentPlanTable from './rent-payment-plan-table';

const RentExemptionsComponent = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="page-container rent-exemptions pb-0">
                <h5>{t('contract.finances.rentExemptions.title')}</h5>
                <AddRentExemptionBlock />
                <AddStairRentInterval />
                <RentPaymentPlanTable />
            </div>
            {props.showRentExemptionModal && <RentExemptionModal />}
            {props.showStairRentIntervalModal && <StairRentInterval />}
        </React.Fragment>
    );
};

const mapState = ({ contract }) => {
    return {
        rentObjects: get(contract, 'rentObjects'),
        showRentExemptionModal: get(contract, 'showRentExemptionModal'),
        showStairRentIntervalModal: get(contract, 'showStairRentIntervalModal'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setPropertyToRentalObjectCall(id, name, value) {
            dispatch(setPropertyToRentalObject(id, name, value));
        },
        openRentExemptionModalWindowCall() {
            dispatch(setValueContract('showRentExemptionModal', true));
        },
        openStairRentIntervalModalWindowCall() {
            dispatch(setValueContract('showStairRentIntervalModal', true));
        },
    };
};

const RentExemptions = connect(mapState, mapDispatch)(RentExemptionsComponent);
export default RentExemptions;
