import React from 'react';
import { useTranslation } from 'react-i18next';

const HowToAddYourCommonCost = (props) => {
    const { t } = useTranslation();

    return (
        <div className="information-container">
            <h5>{t('commonCost.info.title')}</h5>
            <h6 className="text-secondary mb-3 font-weight-normal">{t('commonCost.info.firstTextPart')}</h6>
            <h6 className="text-secondary font-weight-normal">{t('commonCost.info.firstStep')}</h6>
            <h6 className="text-secondary font-weight-normal">{t('commonCost.info.secondStep')}</h6>
            <h6 className="text-secondary mt-3 font-weight-normal">{t('commonCost.info.secondTextPart')}</h6>
        </div>
    );
};

export default HowToAddYourCommonCost;
