import React from 'react';
import { Image } from 'react-bootstrap';

const EmptyList = ({ image, title, subTitle, children, className }) => {
    return (
        <div className={`empty-list-container ${className}`}>
            {image && (
                <div className="image-wrapper">
                    <Image src={image} />
                </div>
            )}
            {title && <h3>{title}</h3>}
            {subTitle && <p className="text-secondary">{subTitle}</p>}
            {children}
        </div>
    );
};

export default EmptyList;
