import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { deleteRentExemption, deleteStairRentInterval, getRentPaymentPlanTableData, getRentPaymentPlanTableDataIncludeCpi, setValueContract } from '../../../../reducers/contractReducer';
import { rentObjectRowDataTypeOptions, rentObjectRowDataTypes } from '../../../../common/contract-options';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import get from 'lodash.get';
import moment from 'moment';
import trash from '../../../../assets/images/trash-default.svg';
import helper from '../../../../helpers/helper';
import useDebounce from '../../../../helpers/useDebounse';
import CurrencyFormat from '../../../../components/currency-format';
import ActionButton from '../../../../components/action-button';
import ConfirmDeleteExemption from './modals/remove-exemption-modal';
import ConfirmDeleteStairRentInterval from './modals/remove-stair-rent-interval-modal';
import LoaderSpinner from '../../../../components/spinner';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const RentPaymentPlanTableComponent = (props) => {
    const { portfolioId, propertyId } = useParams();
    const { t } = useTranslation();
    const debouncedRentObjects = useDebounce(props.rentObjects, 1000);

    useEffect(() => {
        if (!props.rentObjects || !props.rentObjects.length) {
            return;
        }

        const data = props.rentObjects.map((rentObject) => {
            // Dates from local time to utc
            let rentLeaseStartDate = moment.isMoment(rentObject?.leaseStartDate) ? rentObject?.leaseStartDate : moment(rentObject?.leaseStartDate);
            let rentLeaseEndDate = moment.isMoment(rentObject?.leaseEndDate) ? rentObject?.leaseEndDate : moment(rentObject?.leaseEndDate);
            const utcLeaseStartDate = rentLeaseStartDate?.utc(true);
            const utcLeaseEndDate = rentLeaseEndDate?.utc(true);

            return {
                id: rentObject?.id,
                name: rentObject?.displayName,
                amount: rentObject?.amount,
                leaseStartDate: utcLeaseStartDate,
                leaseEndDate: utcLeaseEndDate,
                pricePerYearPerUnit: rentObject?.pricePerYearPerUnit || 0,
                totalPricePerYear: rentObject?.totalPricePerYear || 0,
            };
        });
        if (!props.disableEditing) {
            props.getRentPaymentPlanTableDataCall(data, props.contractId, props.propertyId, portfolioId);
        } else {
            props.getRentPaymentPlanTableDataIncludeCpiCall(data, props.contractId, props.propertyId, portfolioId);
        }

    }, [debouncedRentObjects, props.rentExemptions, props.stairRentIntervals]);

    const editItem = (item) => {
        if (item.rentExemptionId) {
            editRentExemption(item.rentExemptionId);
        } else if (item.stairRentIntervalId) {
            editStairRentInterval(item.stairRentIntervalId);
        }
    };

    const editRentExemption = (rentExemptionId) => {
        const rentExemption = props.rentExemptions.find((x) => x.id === rentExemptionId);
        rentExemption && props.editRentExemptionCall(rentExemption);
    };

    const editStairRentInterval = (stairRentIntervalId) => {
        const stairRentInterval = props.stairRentIntervals.find((x) => x.id === stairRentIntervalId);
        stairRentInterval && props.editStairRentIntervalCall(stairRentInterval);
    };

    const deleteItemShowModal = (item) => {
        if (item.rentExemptionId) {
            props.setValueContractCall('rentExemptionModel', item);
            props.setValueContractCall('showRemoveRentExemptionModal', true);
        } else if (item.stairRentIntervalId) {
            props.setValueContractCall('stairRentIntervalModel', item);
            props.setValueContractCall('showRemoveStairRentIntervalModal', true);
        }
    };

    const getStartDateByRentExemption = (rentExemptionId) => {
        const rentExemption = props.rentExemptions.find(item => item.id === rentExemptionId);
        const rentObject = props.rentObjects.find(item => item.id === rentExemption?.rentObjectId);

        return rentObject?.leaseStartDate ? rentObject?.leaseStartDate : props.contract.leaseStartDate;
    }

    const renderRentObjectsDataItem = (item, isAlternate, rentObjectRowDataType, amount) => {
        return (
            <div className={`table-row ${isAlternate ? 'alternate' : ''}`}>
                <Row align={'middle'}>
                    <Col xs={12} md={3}>
                        <span className="hidden-md">{t('contract.finances.rentExemptions.table.rentObject')}:</span>
                        <div className="td">{item.name}</div>
                    </Col>
                    <Col xs={12} md={3}>
                        <span className="hidden-md">{t('contract.finances.rentExemptions.table.date')}:</span>
                        <div className="td">
                            {moment(item.startDate).format(primaryDateFormat)} - {moment(item.endDate).format(primaryDateFormat)}
                            {(rentObjectRowDataType === rentObjectRowDataTypes.exemption && moment(item.startDate).isBefore(getStartDateByRentExemption(item.rentExemptionId))) && (
                                <Tooltip
                                    placement="right"
                                    title={t('contract.finances.rentExemptions.table.contractDurationUpdatedWarning')}
                                >
                                    <InfoCircleOutlined className='exemption-warning-icon'/>
                                </Tooltip>
                            )}
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div className="td">
                            <span className="hidden-md">{t('contract.finances.rentExemptions.table.type')}:</span>
                            {helper.getEnumValue(rentObjectRowDataTypeOptions, rentObjectRowDataType)}
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div className="td">
                            <span className="hidden-md">{t('contract.finances.rentExemptions.table.amount')}:</span>
                            {amount ? <CurrencyFormat value={amount} /> : '-'}
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        {rentObjectRowDataType === rentObjectRowDataTypes.exemption && (
                            <div className="td edit-container">
                                <ActionButton className="btn-link" onClick={() => editItem(item)} text={t('common.buttons.edit')} />
                                <ActionButton className="btn-link p-0 btn-transparent" icon={<Image src={trash} />} onClick={() => deleteItemShowModal(item)} />
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <div className="table-flex rent-payment-plan-table mt-4">
            <h5 className="table-title mb-0">{t('contract.finances.rentExemptions.table.header')}</h5>
            {props.getRentPaymentPlanTableDataLoading ? (
                <LoaderSpinner />
            ) : (
                <div className="d-flex flex-column">
                    {props.rentObjectsData && (
                        <Row className="table-header visible-md">
                            <Col xs={12} md={3}>
                                <div className="th">{t('contract.finances.rentExemptions.table.rentObject')}</div>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="th">{t('contract.finances.rentExemptions.table.date')}</div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="th">{t('contract.finances.rentExemptions.table.type')}</div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="th">{t('contract.finances.rentExemptions.table.amount')}</div>
                            </Col>
                            <Col xs={12} md={2}></Col>
                        </Row>
                    )}
                    <div className="table-body">
                        {props.rentObjectsData &&
                            props.rentObjectsData.map((item, index) => (
                                <>
                                    {renderRentObjectsDataItem(item, index % 2 === 0, rentObjectRowDataTypes.fullRent, item.fullRentAmount)}
                                    {renderRentObjectsDataItem(item, index % 2 !== 0, rentObjectRowDataTypes.exemption, -item.exemptionAmount)}
                                    {renderRentObjectsDataItem(item, index % 2 === 0, rentObjectRowDataTypes.rentPayable, item.rentPayableAmount)}
                                </>
                            ))}
                    </div>
                    <Row className="table-header visible-md year-total">
                        <Col xs={12} md={3}>
                            <div className="th">{t('contract.finances.rentExemptions.table.year')}</div>
                        </Col>
                        <Col xs={12} md={3}>
                            <div className="th">{t('contract.finances.rentExemptions.table.rent')}</div>
                        </Col>
                        <Col xs={12} md={3}>
                            <div className="th">{t('contract.finances.rentExemptions.table.exemptions')}</div>
                        </Col>
                        <Col xs={12} md={3}>
                            <div className="th">{t('contract.finances.rentExemptions.table.yearTotal')}</div>
                        </Col>
                    </Row>
                    <div className="table-body">
                        {props.yearlyRentData &&
                            props.yearlyRentData
                                .sort((a, b) => (a.year > b.year ? 1 : -1))
                                .map((item, index) => (
                                    <div className={`table-row ${index % 2 === 1 ? 'alternate' : ''}`}>
                                        <Row align={'middle'} className={'year-total'}>
                                            <Col xs={12} md={3}>
                                                <div className="td">
                                                    <span className="hidden-md">{t('contract.finances.rentExemptions.table.year')}:</span>
                                                    {item.year}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="td">
                                                    <span className="hidden-md">{t('contract.finances.rentExemptions.table.rent')}:</span>
                                                    <CurrencyFormat value={item.fullRentAmount} />
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="td">
                                                    <span className="hidden-md">{t('contract.finances.rentExemptions.table.exemptions')}:</span>
                                                    {item.exemptionAmount ? <CurrencyFormat value={-item.exemptionAmount} /> : '-'}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="td">
                                                    <span className="hidden-md">{t('contract.finances.rentExemptions.table.yearTotal')}:</span>
                                                    <CurrencyFormat value={item.totalAmount} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                    </div>
                    <div className="table-footer">
                        <Row>
                            <Col xs={6} md={9}>
                                <div className="td">{t('contract.finances.rentExemptions.table.total')}</div>
                            </Col>
                            <Col xs={6} md={3}>
                                <div className="td">
                                    <CurrencyFormat value={(props.totalAmount || 0).toFixed()} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
            {props.showRemoveRentExemptionModal && <ConfirmDeleteExemption />}
            {props.showRemoveStairRentIntervalModal && <ConfirmDeleteStairRentInterval />}
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        contractId: get(contract, 'contract.id'),
        contract: get(contract, 'contract'),
        propertyId: get(contract, 'contract.propertyId'),
        rentExemptions: get(contract, 'rentExemptions'),
        stairRentIntervals: get(contract, 'stairRentIntervals'),
        rentObjects: get(contract, 'rentObjects'),
        rentObjectsData: get(contract, 'rentPaymentPlanTableData.rentObjectsData'),
        yearlyRentData: get(contract, 'rentPaymentPlanTableData.yearlyRentData'),
        totalAmount: get(contract, 'rentPaymentPlanTableData.totalAmount'),
        getRentPaymentPlanTableDataLoading: get(contract, 'getRentPaymentPlanTableDataLoading'),
        rentExemptionModel: get(contract, 'rentExemptionModel'),
        stairRentIntervalModel: get(contract, 'stairRentIntervalModel'),
        showRemoveRentExemptionModal: get(contract, 'showRemoveRentExemptionModal'),
        showRemoveStairRentIntervalModal: get(contract, 'showRemoveStairRentIntervalModal'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        getRentPaymentPlanTableDataCall(rentObjectsData, contractId, propertyId, portfolioId) {
            dispatch(getRentPaymentPlanTableData(rentObjectsData, contractId, propertyId, portfolioId));
        },
        getRentPaymentPlanTableDataIncludeCpiCall(rentObjectsData, contractId, propertyId, portfolioId) {
            dispatch(getRentPaymentPlanTableDataIncludeCpi(rentObjectsData, contractId, propertyId, portfolioId));
        },
        editRentExemptionCall(rentExemption) {
            dispatch(setValueContract('rentExemptionModel', rentExemption));
            dispatch(setValueContract('showRentExemptionModal', true));
        },
        deleteRentExemptionCall(rentExemptionId, contractId, propertyId, portfolioId) {
            return dispatch(deleteRentExemption(rentExemptionId, contractId, propertyId, portfolioId));
        },
        editStairRentIntervalCall(stairRentInterval) {
            dispatch(setValueContract('stairRentIntervalModel', stairRentInterval));
            dispatch(setValueContract('showStairRentIntervalModal', true));
        },
        deleteStairRentIntervalCall(stairRentInterval, contractId, propertyId, portfolioId) {
            return dispatch(deleteStairRentInterval(stairRentInterval, contractId, propertyId, portfolioId));
        },
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
    };
};

const RentPaymentPlanTable = connect(mapState, mapDispatch)(RentPaymentPlanTableComponent);
export default RentPaymentPlanTable;
