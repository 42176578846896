import { Progress } from 'antd';
import React from 'react';

const EProgress = (props) => {
    const progressColors = {
        primary: {
            strokeColor: '#376FFF',
            trailColor: 'rgba(55, 111, 255, 0.3)',
        },
        secondary: {
            strokeColor: '#FF6600',
            trailColor: 'rgba(255, 102, 0, 0.3)',
        },
        danger: {
            strokeColor: '#FF3A3A',
            trailColor: 'rgba(255, 58, 58, 0.38)',
        },
    };

    const className = 'd-flex align-items-center ' + (props.color || 'primary');
    const colors = progressColors[props.color] || progressColors.primary;
    const type = props.type || 'line';

    return (
        <Progress
            className={className}
            type={type}
            strokeColor={colors.strokeColor}
            trailColor={colors.trailColor}
            percent={props.percent}
            width={props.width}
            showInfo={props.showInfo}
            format={props.format}
            strokeWidth={props.strokeWidth || 4}
        />
    );
};

export default EProgress;
