import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { Form } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router';
import { createTenants, defaultTenant, setValueTenant } from '../../../reducers/tenantReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';
import { getCountries } from '../../../reducers/commonReducer';
import commonService from '../../../services/commonService';
import objectHelper from '../../../helpers/trim-object';
import toaster from '../../../common/toaster';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import tenantIcon from '../../../assets/images/new-design-tenant-icon.svg';
import reviewIcon from '../../../assets/images/review.svg';
import add from '../../../assets/images/add.svg';
import InformationComponent from '../../../components/information-container';
import CreatedComponent from '../../../components/object/created-object';
import ObjectLayout from '../../../components/layouts/object-layout';
import ActionButton from '../../../components/action-button';
import TenantByCompany from './tenant-company';
import TenantByPerson from './tenant-person';
import TenantReview from './review-step';
import routingService from '../../../services/routingService';

const CreateTenantComponent = (props) => {
    const stepNames = {
        details: 'details',
        review: 'review',
    };

    const { t } = useTranslation();
    const { portfolioId, propertyId } = useParams();
    const [currentStepKey, setCurrentStepKey] = useState(stepNames.details);
    const [currentTenantIdOnEdit, setCurrentTenantIdOnEdit] = useState();
    const tenantRef = useRef();
    const tenantsUrl = routingService.propertyTenantsUrl(portfolioId, propertyId);

    useEffect(() => {
        props.getPropertyCall(portfolioId, propertyId);
        if (!props.hasCountries) {
            props.getCountriesCall();
        }
    }, []);

    const onNext = () => {
        validateTenantRef()
            .then((_) => {
                addNewTenantToStore();
                tenantRef.current.resetFields();
                setCurrentStepKey(stepNames.review);
            })
            .catch(() => { });
    };

    const addNewTenantToStore = () => {
        const tenants = [...props.tenants];
        let newTenant = getTenantFromForm();
        if (!props.tenant.uId) {
            newTenant.uId = uuidv4();
            tenants.push(newTenant);
        } else {
            const index = tenants.findIndex((item) => item.uId === props.tenant.uId);
            if (index !== -1) {
                newTenant.uId = props.tenant.uId;
                tenants[index] = newTenant;
            }
        }
        setCurrentTenantIdOnEdit(null);
        props.setValueTenantCall('tenants', tenants);
    }

    const onPrevious = () => {
        setCurrentStepKey(stepNames.details);

        const tenants = [...props.tenants];
        if (tenants[0]) {
            const tenant = tenants[0];
            setCurrentTenantIdOnEdit(tenant.uId);
            props.setValueTenantCall('tenant', tenant);
            tenantRef.current.setFieldsValue(tenant);
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious();
        } else {
            onNext();
        }
    };

    const validateTenantRef = () => {
        return tenantRef.current.validateFields();
    };

    const getTenantFromForm = () => {
        const values = tenantRef.current.getFieldsValue();
        const tenant = objectHelper.trimObjectProperties(values);
        if (tenant.phone && tenant.phone[0] !== '+') {
            tenant.phone = `+${tenant.phone}`;
        }
        return tenant;
    };

    const deleteTenant = (id) => {
        props.setValueTenantCall(
            'tenants',
            props.tenants.filter((item) => item.uId !== id)
        );
    };

    const goToEdit = (stepKey, objectId) => {
        setCurrentStepKey(stepKey);
        editTenant(objectId, true);
    };

    const editTenant = (id, fromReview) => {
        addAnotherTenant(id, fromReview);
    };

    const setTenant = (id) => {
        const tenant = props.tenants.find((item) => item.uId === id);
        if (tenant) {
            setCurrentTenantIdOnEdit(tenant.uId);
            tenantRef.current.setFieldsValue(tenant);
            props.setValueTenantCall('tenant', tenant);
        }
    };

    const addAnotherTenant = (id, fromReview) => {
        validateTenantRef()
            .then(() => {
                const tenant = getTenantFromForm();
                const tenants = [...props.tenants];
                if (currentTenantIdOnEdit) {
                    const index = tenants.findIndex((item) => item.uId === currentTenantIdOnEdit);
                    const olDTenant = tenants.find((item) => item.uId === currentTenantIdOnEdit);
                    if (index !== -1) {
                        tenant.uId = olDTenant.uId;
                        tenants[index] = tenant;
                        props.setValueTenantCall('tenants', tenants);
                    }
                } else if (!fromReview || (!currentTenantIdOnEdit && !id)) {
                    tenant.uId = uuidv4();
                    tenants.push(tenant);
                    props.setValueTenantCall('tenants', tenants);
                }
                props.setValueTenantCall('tenant', defaultTenant);
                tenantRef.current.resetFields();
                tenantRef.current.setFieldsValue(defaultTenant);
                setCurrentTenantIdOnEdit(null);

                if (id) {
                    setTenant(id);
                }
            })
            .catch((e) => {
                if (!e.values.orgName && !e.values.orgNumber && !e.values.address) {
                    setTenant(id);
                } else {
                    if (id) {
                        toaster.error(t('common.editAddedEntity'), null);
                    }
                }
            });
    };

    const created = (tenants) => {
        if (tenants) {
            if (tenants.length === 1) {
                const tenantId = tenants[0].id;
                routingService.navigateToTenantDetails(portfolioId, propertyId, tenantId);
                props.showTenantCreatedResult();
            } else if (tenants.length > 1) {
                routingService.goTo(tenantsUrl);
                props.showTenantsCreationResult();
            }
        }
    };

    const onSaveAndExit = () => {
        if (propertyId) {
            const tenants = [...props.tenants].map((tenant) => {
                tenant.propertyId = +propertyId;
                return tenant;
            });
            props.createTenantsCall(tenants, propertyId, portfolioId).then((createdTenants) => created(createdTenants));
        }
    };

    const steps = [
        {
            key: stepNames.details,
            header: `${t('tenant.details.header')} ${props.propertyName}`,
            stepTitle: t('tenant.details.stepTitle'),
            logo: tenantIcon,
            canShowAsCompleted: true,
            content: (
                <div className="page-container">
                    {props.hasTenants &&
                        props.tenants.map((tenant) => (
                            <React.Fragment>
                                {props.tenant.uId === tenant.uId ? null : (
                                    <CreatedComponent
                                        key={tenant.uId}
                                        id={tenant.uId}
                                        title={tenant.orgName || tenant.firstName + ' ' + tenant.lastName}
                                        editObject={editTenant}
                                        deleteObject={deleteTenant}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    {currentStepKey === stepNames.details &&
                        (props.tenant.ownedBy === commonService.ownerRadioGroupTypes.company ? (
                            <TenantByCompany tenantRef={tenantRef} />
                        ) : (
                            <TenantByPerson tenantRef={tenantRef} />
                        ))}
                    {currentStepKey === stepNames.details && (
                        <ActionButton icon={<Image src={add} />} onClick={addAnotherTenant} className="btn-secondary" text={t('tenant.details.btnAdd')} />
                    )}
                </div>
            ),
        },
        {
            key: stepNames.review,
            header: t('tenant.review.header'),
            stepTitle: t('tenant.review.stepTitle'),
            logo: reviewIcon,
            content: <TenantReview goToStep={goToEdit} />,
        },
    ];

    return (
        <Form name="basic" autoComplete="off" className="h-100" ref={tenantRef}>
            <ObjectLayout
                bottomRightSideBar={
                    <React.Fragment>
                        {currentStepKey === stepNames.details && <InformationComponent title={t('tenant.information.title')} text={t('tenant.information.text')} />}
                    </React.Fragment>
                }
                steps={steps}
                timelineSteps={steps}
                loading={props.tenantLoading || props.propertyLoading}
                exitUrl={tenantsUrl}
                currentStepKey={currentStepKey}
                onNext={onNext}
                onPrevious={onPrevious}
                onSwitchStep={onSwitchStep}
                onSaveAndExit={onSaveAndExit}
            />
        </Form>
    );
};

const mapState = ({ tenant, property, common }) => {
    return {
        propertyName: get(property, 'property.propertyName'),
        tenant: get(tenant, 'tenant'),
        tenants: get(tenant, 'tenants'),
        hasTenants: get(tenant, 'tenants.length') > 0,
        tenantLoading: get(tenant, 'tenantLoading'),
        hasCountries: get(common, 'countries.length') > 0,
        propertyLoading: get(property, 'propertyLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        createTenantsCall(values, propertyId, portfolioId) {
            return dispatch(createTenants(values, propertyId, portfolioId));
        },
        setValueTenantCall(key, value) {
            dispatch(setValueTenant(key, value));
        },
        showTenantsCreationResult() {
            dispatch(setValueTenant('showCreationResultModal', true));
        },
        showTenantCreatedResult() {
            dispatch(setValueTenant('showCreateTenantResultModal', true));
        },
        getCountriesCall() {
            dispatch(getCountries());
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
    };
};

const CreateTenant = clear(connect(mapState, mapDispatch)(CreateTenantComponent));
export default CreateTenant;
