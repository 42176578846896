import update from '../helpers/update';
import i18next from 'i18next';
import { StatusCodes } from 'http-status-codes';
import passwordService from '../services/newPasswordService';
import toaster from '../common/toaster';
import routingService from '../services/routingService';

const SET_VALUE_PASSWORD = 'SET_VALUE_PASSWORD';
const SET_ERROR_PASSWORD = 'SET_ERROR_PASSWORD';

const initialState = {
    loading: false,
    error: null,
    email: null,
};

const passwordReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_PASSWORD:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case SET_ERROR_PASSWORD:
            newState = update.set(newState, 'error', action.payload);
            break;
        default:
            break;
    }

    return newState;
};

export const setValuePassword = (key, value) => {
    return {
        type: SET_VALUE_PASSWORD,
        payload: {
            key,
            value,
        },
    };
};

export const setError = (value) => {
    if (value.response && value.response.data) {
        return {
            type: SET_ERROR_PASSWORD,
            payload: value.response.data.title,
        };
    }
    return {
        type: SET_ERROR_PASSWORD,
        payload: i18next.t('common.serverErrorMessage'),
    };
};

export const forgotPassword = (username) => {
    return (dispatch) => {
        dispatch(setValuePassword('loading', true));
        return passwordService
            .forgotPassword(username)
            .then((response) => {
                if (response && response.data) {
                    dispatch(setValuePassword('email', response.data.email));
                    return response.data;
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.status && error.response.status === StatusCodes.BAD_REQUEST) {
                    toaster.error(error.response.data.title, null);
                } else {
                    toaster.error(i18next.t('common.serverErrorMessage'), null);
                }
            })
            .finally(() => {
                dispatch(setValuePassword('loading', false));
            });
    };
};

export const changePassword = (values) => {
    return (dispatch) => {
        dispatch(setValuePassword('loading', true));
        passwordService
            .changePassword(values)
            .then((res) => {
                const loginUrl = routingService.loginUrl() + '?password-was-reset';
                routingService.goTo(loginUrl);
            })
            .catch((error) => {
                if (error && error.response && error.response.status && error.response.status === StatusCodes.BAD_REQUEST) {
                    toaster.error(error.response.data.title, null);
                }
                dispatch(setError(error));
            })
            .finally(() => {
                dispatch(setValuePassword('loading', false));
            });
    };
};

export default passwordReducer;
