import axios from 'axios';

const portfolioService = {
    create: (data) => {
        return axios({
            method: 'post',
            url: `/api/contract/portfolios/create`,
            data: data,
        });
    },

    search: async (showMyPortfolios) => {
        return axios({
            method: 'post',
            url: `/api/contract/portfolios/search`,
            data: {
                showOnlyMy: showMyPortfolios
            }
        });
    },

    delete: (id) => {
        return axios({
            method: 'post',
            url: `/api/contract/portfolios/delete/${id}`,
        });
    },

    getById: (id) => {
        return axios({
            method: 'get',
            url: `/api/contract/portfolios/${id}`,
        });
    },

    getOwnerByProtfolioId: (portfolioId) => {
        return axios({
            method: 'get',
            url: `/api/contract/portfolios/owner?portfolioId=${portfolioId}`,
        });
    },

    edit: (portfolioId, data) => {
        return axios({
            method: 'put',
            url: `/api/contract/portfolios?portfolioId=${portfolioId}`,
            data: data,
        });
    },

    sendInvite: (portfolioId, invite) => {
        return axios({
            method: 'post',
            url: `/api/contract/portfolios/invite?portfolioId=${portfolioId}`,
            data: invite,
        });
    },

    acceptInvite: (portfolioInviteId) => {
        return axios({
            method: 'post',
            url: `/api/contract/portfolios/invites/accept/${portfolioInviteId}`
        });
    },

    declineInvite: (portfolioInviteId) => {
        return axios({
            method: 'post',
            url: `/api/contract/portfolios/invites/decline/${portfolioInviteId}`
        });
    },

    updateInvite: (invite, portfolioId) => {
        return axios({
            method: 'put',
            url: `/api/contract/portfolios/invite?portfolioId=${portfolioId}`,
            data: invite,
        });
    },

    resendInvite: (portfolioId, invite) => {
        return axios({
            method: 'post',
            url: `/api/contract/portfolios/resend-invite?portfolioId=${portfolioId}`,
            data: invite,
        });
    },

    deleteInvite: (portfolioInviteId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/portfolios/invites/delete/${portfolioInviteId}?portfolioId=${portfolioId}`,
        });
    },

    reSendInvites: (portfolioId, invites) => {
        return axios({
            method: 'post',
            url: `/api/contract/portfolios/resend-invites?portfolioId=${portfolioId}`,
            data: invites,
        });
    },

    acceptAllPortfolioInvites: (token) => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        return axios.post('/api/contract/portfolios/invites/accept', {}, config);
    },

    removeUserFromPortfolio: (portfolioId) => {
        return axios({
            method: 'post',
            url: `/api/contract/portfolios/invites/remove/${portfolioId}`,
        });
    },

    getKpis: (portfolioId, proprtyId) => {
        return axios({
            method: 'get',
            url: `/api/contract/dashboard/portfolio-kpis?portfolioId=${portfolioId}&propertyId=${proprtyId}`,
        });
    },

    getUsersByPortfolio: (portfolioId) => {
        return axios({
            method: 'get',
            url: `/api/contract/portfolios/portfolio-users?portfolioId=${portfolioId}`,
        });
    },
};

export default portfolioService;
