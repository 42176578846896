import get from 'lodash-es/get';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SearchInput from '../../../components/search-input';
import TranslationRow from './translation-row';

const TranslationTableComponent = (props) => {
    const { t } = useTranslation();
    const { module, sourceLang, destinationLang } = props;
    const [filteredLocalizationRecords, setFilteredLocalizationRecords] = useState([]);
    const [filterKeyText, setFilterKeyText] = useState('');
    const [filterSourceLangText, setFilterSourceLangText] = useState('');
    const [filterDestinationLangText, setFilterDestinationLangText] = useState('');

    useEffect(() => {
        filterLocalizationRecords();
    }, [props.localizationRecords, filterKeyText, filterSourceLangText, filterDestinationLangText]);

    const filterLocalizationRecords = () => {
        let filteredData = props.localizationRecords;

        filteredData = filterKeyText ? filteredData.filter((x) => x.key.toLowerCase().includes(filterKeyText)) : filteredData;
        filteredData = filterSourceLangText
            ? filteredData.filter((x) => x.sourceLanguageValue && x.sourceLanguageValue.toLowerCase().includes(filterSourceLangText))
            : filteredData;
        filteredData = filterDestinationLangText
            ? filteredData.filter((x) => x.destinationLanguageValue && x.destinationLanguageValue.toLowerCase().includes(filterDestinationLangText))
            : filteredData;

        setFilteredLocalizationRecords(filteredData);
    };

    const handleSearchByKey = (filterText) => {
        filterText = (filterText || '').toLowerCase();
        setFilterKeyText(filterText);
    };

    const handleSearchBySourceLang = (filterText) => {
        filterText = (filterText || '').toLowerCase();
        setFilterSourceLangText(filterText);
    };

    const handleSearchByDestinationLang = (filterText) => {
        filterText = (filterText || '').toLowerCase();
        setFilterDestinationLangText(filterText);
    };

    return (
        <div className="translations-table">
            <Row className="mb-3">
                <Col>
                    <p>{t('translationManager.key')}</p>
                    <SearchInput placeholder={t('translationManager.search')} onSearch={handleSearchByKey} />
                </Col>
                <Col>
                    <p>{t('languages.' + sourceLang)}</p>
                    <SearchInput placeholder={t('translationManager.search')} onSearch={handleSearchBySourceLang} />
                </Col>
                <Col>
                    <p>{t('languages.' + destinationLang)}</p>
                    <SearchInput placeholder={t('translationManager.search')} onSearch={handleSearchByDestinationLang} />
                </Col>
                <Col className="remove-icon-col"></Col>
            </Row>
            {filteredLocalizationRecords.map((item) => (
                <TranslationRow
                    key={item.key}
                    data={item}
                    sourceLang={sourceLang}
                    destinationLang={destinationLang}
                    onRemove={props.onRemove}
                    onChangeKey={props.onChangeKey}
                    onChangeTranslation={props.onChangeTranslation}
                />
            ))}
        </div>
    );
};

const mapState = ({ translations }) => {
    return {
        localizationRecords: get(translations, 'localizationRecords'),
    };
};

const TranslationTable = connect(mapState, null)(TranslationTableComponent);
export default TranslationTable;
