import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { supportedLanguages } from '../../services/languageService';

const OwnPhoneInput = (props) => {
    const onChange = (value) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };

    return (
        <div className="phone-input">
            <PhoneInput onChange={onChange} value={props.value || ''} country={supportedLanguages.Norway} placeholder={props.placeholder} countryCodeEditable={false} />
        </div>
    );
};

export default OwnPhoneInput;
