import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Progress } from 'antd';
import { Row, Col } from 'react-bootstrap';
import routingService from '../../services/routingService';
import get from 'lodash.get';
import moment from 'moment';
import NumberFormat from '../../components/number-format';
import TextEllipsis from '../../components/ellipsis-text';

const ContractsTableComponent = (props) => {
    const maxRowsNumberInCollapsedState = 3;
    const { t } = useTranslation();
    const { portfolioId } = useParams();
    const [isTableExpanded, setTableExpanded] = useState(props.tableData.length <= maxRowsNumberInCollapsedState);

    const formatRemainingTime = (endDate) => {
        const currentDate = moment(Date.now());
        endDate = moment(endDate);

        if (endDate > currentDate) {
            const years = endDate.diff(currentDate, 'years');
            currentDate.add(years, 'years');
            const months = endDate.diff(currentDate, 'months');
            return t('common.durationYearsAndMonths', { years: years, months: months });
        } else {
            return '';
        }
    };

    const handleExpandClick = (e) => {
        e.preventDefault();
        setTableExpanded(true);
    };

    const handleCollapseClick = (e) => {
        e.preventDefault();
        setTableExpanded(false);
    };

    const renderProgress = (progress) => {
        return <Progress type="line" strokeColor="#3F66F1" trailColor="#C1CFFF" percent={progress} strokeWidth={8} format={(value) => value + '%'} />;
    };

    return (
        <div className="table-flex contracts-table">
            <div className="d-flex flex-column">
                <Row className="table-header visible-md">
                    <Col xs={12} md={3}>
                        <div className="th">{t('property.topContractsTable.contractName')}</div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className="th">{t('property.topContractsTable.completionPercentage')}</div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div className="th">{t('property.topContractsTable.remaining')}</div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div className="th">{t('property.topContractsTable.yearlyIncome')}</div>
                    </Col>
                </Row>
                <div className="table-body">
                    {(isTableExpanded ? props.tableData : props.tableData.slice(0, maxRowsNumberInCollapsedState)).map((contract, index) => (
                        <div className={`table-row ${index % 2 === 1 ? 'alternate' : ''}`} key={contract.contractId}>
                            <Row align={'middle'}>
                                <Col xs={12} md={3}>
                                    <div className="td">
                                        <Link to={routingService.tenantDetailsUrl(portfolioId, props.propertyId, contract.tenantId)}>
                                            <TextEllipsis>
                                                <p className="ellipsis">{contract.contractName}</p>
                                            </TextEllipsis>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div className="td progress-cell">{renderProgress(contract.completed)}</div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <div className="td">
                                        <span className="hidden-md">{t('property.topContractsTable.remaining')}:</span>
                                        {formatRemainingTime(contract.endDate)}
                                    </div>
                                </Col>
                                <Col xs={12} md={2}>
                                    <div className="td">
                                        <span className="hidden-md">{t('property.topContractsTable.yearlyIncome')}:</span>
                                        <NumberFormat value={contract.yearlyIncome} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
                <div className="table-footer">
                    {props.tableData.length > maxRowsNumberInCollapsedState ? (
                        isTableExpanded ? (
                            <Link onClick={handleCollapseClick}>{t('common.buttons.collapse')}</Link>
                        ) : (
                            <Link onClick={handleExpandClick}>{t('common.buttons.expand')}</Link>
                        )
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const mapState = ({ property }) => {
    return {
        propertyId: get(property, 'property.id'),
        tableData: get(property, 'kpis.contracts'),
    };
};

const ContractsTable = connect(mapState)(ContractsTableComponent);
export default ContractsTable;
