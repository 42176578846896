import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal, Switch, Select, Checkbox, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { setValueContract } from '../../../reducers/contractReducer';
import { contractStatuses, contractStatusOptions } from '../../../common/contract-options';
import ActionButton from '../../../components/action-button';
import get from 'lodash-es/get';

const ContractFilterModalComponent = (props) => {
    const { t } = useTranslation();
    const [contractCurrentFilter, setContractCurrentFilter] = useState(props.status);
    const [showArchived, setShowArchived] = useState(props.showArchived);

    const [contractFilterForm] = Form.useForm();

    const filterIsChanged = props.status !== contractCurrentFilter || props.showArchived !== showArchived;

    const onFilter = () => {
        if (filterIsChanged) {
            if (props.onFilter) {
                props.setValueContractCall('status', contractCurrentFilter);
                props.setValueContractCall('showArchived', showArchived);
                props.onFilter({ contractStatuses: contractCurrentFilter, status: showArchived ? contractStatuses.archived : contractStatuses.active});
                props.closeModalWindow();
            }
        }
    };

    const onClear = () => {
        if (props.status !== contractStatuses.active || props.showArchived) {
            if (props.onClear) {
                props.setValueContractCall('status', contractStatuses.active);
                props.setValueContractCall('showArchived', false);
                props.onClear();
            }
        }
        props.closeModalWindow();
    };

    const onCancel = () => {
        props.closeModalWindow();
    };

    return (
        <Modal
            wrapClassName="result-modal creation-modal"
            footer={null}
            visible={true}
            maskClosable={false}
            onCancel={onCancel}
            closeIcon={<CloseOutlined className="close-icon" />}>
            <div className="header flex-column">
                <h4 className="mb-0">{t('filters')}</h4>
            </div>
            <div className="main contract-filter-modal">
                <Form form={contractFilterForm}>
                    <Form.Item name="contractStatus">
                        <Select
                            dropdownClassName="contract-statuses-dropdown new-design-dropdown"
                            mode="multiple"
                            defaultValue={contractCurrentFilter}
                            onChange={(values) => setContractCurrentFilter(values)}
                            maxTagCount="responsive"
                            placeholder={t('contract.filterModal.selectStatuses')}>
                            {contractStatusOptions.map((object) => (
                                <Select.Option value={object.id} key={object.id}>
                                    <Checkbox className="mr-2" onClick={(e) => e.preventDefault()} checked={contractCurrentFilter.includes(object.id)}></Checkbox>
                                    {object.name()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-row">
                        <p className="mb-0">{t('contract.filterModal.showArchived')}:</p>
                    </div>
                    <Switch checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
                </div>
            </div>
            <div className="btns justify-content-end">
                    <ActionButton onClick={onCancel} className="btn-secondary" text={t('common.buttons.cancel')}></ActionButton>
                    <ActionButton onClick={onFilter} disabled={!filterIsChanged} className="btn-primary ml-3" text={t('common.buttons.saveChanges')}></ActionButton>
            </div>
        </Modal>
    );
};

const mapState = ({ contract }) => {
    return {
        status: get(contract, 'status'),
        showArchived: get(contract, 'showArchived'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
        closeModalWindow() {
            dispatch(setValueContract('showContractFilterModalWindow', false));
        },
    };
};

const ContractFilterModal = connect(mapState, mapDispatch)(ContractFilterModalComponent);
export default ContractFilterModal;
