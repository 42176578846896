import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import moment from 'moment';
import ActionButton from '../../../components/action-button';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import PropertyRow from '../../../components/property-row';
import helper from '../../../helpers/helper';

const PropertyReviewComponent = (props) => {
    const { t } = useTranslation();

    const goToStep = (stepKey) => {
        props.goToStep(stepKey);
    };

    return (
        <div className="page-container review">
            <div className="review-part details-info">
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                    <h5>{t('property.review.propertyName')}</h5>
                    <ActionButton onClick={() => goToStep('name')} className="btn-link" text={t('common.buttons.edit')} />
                </div>
                <PropertyRow title={t('property.review.propertyName')} value={props.property.propertyName} />
            </div>
            {props.property.owner && (
                <div className="review-part details-info">
                    <div className="d-flex flex-row justify-content-between align-items-baseline">
                        <h5>{t('property.review.propertyDetails')}</h5>
                        <ActionButton onClick={() => goToStep('details')} className="btn-link" text={t('common.buttons.edit')} />
                    </div>
                    {props.property.owner.orgName ? (
                        <React.Fragment>
                            <PropertyRow title={t('property.owner.details.orgName.title')} value={props.property.owner.orgName} />
                            <PropertyRow title={t('property.owner.details.orgNumber.title')} value={helper.numberWithSpaces(props.property.owner.orgNumber)} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <PropertyRow title={t('property.owner.details.firstName.title')} value={props.property.owner.firstName} />
                            <PropertyRow title={t('property.owner.details.lastName.title')} value={props.property.owner.lastName} />
                        </React.Fragment>
                    )}
                </div>
            )}
            {props.property.owner && (
                <div className="review-part details-info">
                    <div className="d-flex flex-row justify-content-between align-items-baseline">
                        <h5>{t('property.review.propertyManager')}</h5>
                        <ActionButton onClick={() => goToStep('details')} className="btn-link" text={t('common.buttons.edit')} />
                    </div>
                    <PropertyRow title={t('property.owner.contacts.name.title')} value={props.property.owner.name} />
                    <PropertyRow title={t('property.owner.contacts.address.title')} value={props.property.owner.address} />
                    <PropertyRow title={t('property.owner.contacts.phone.title')} value={props.property.owner.phone} />
                    <PropertyRow title={t('property.owner.contacts.email.title')} value={props.property.owner.email} />
                    <PropertyRow title={t('property.owner.contacts.post.title')} value={props.property.owner.post} />
                    <PropertyRow title={t('property.owner.contacts.country.title')} value={props.property.owner.country} />
                </div>
            )}
        </div>
    );
};

const mapState = ({ property }) => {
    return {
        property: get(property, 'property'),
    };
};

const PropertyReview = connect(mapState, null)(PropertyReviewComponent);
export default PropertyReview;
