import React from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyFormat from '../../../../components/currency-format';
import { showActualsSelector } from '../../selectors';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const CommonCostTotal = (props) => {
    const { t } = useTranslation();

    const showActuals = useSelector(showActualsSelector);
    const commonCost = useSelector(({ commonCost }) => commonCost.commonCost);
    const buildingId = useSelector(({ commonCost }) => commonCost.commonCost?.buildingId);

    return (
        <>
            <div className="d-flex flex-column">
                <Row className="table-header visible-md">
                    <Col xs={12} md={showActuals ? 3 : 4}>
                        <div className="th">{t('commonCost.analysisDetails.summary')}</div>
                    </Col>
                    <Col xs={12} md={showActuals ? 3 : 4}>
                        <div className="th">{t('commonCost.analysisDetails.budget')}</div>
                    </Col>
                    {showActuals && (
                        <>
                            <Col xs={12} md={3}>
                                <div className="th">{t('commonCost.analysisDetails.actual')}</div>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="th">{t('commonCost.costsTable.discrepancy')}</div>
                            </Col>
                        </>
                    )}
                    {!showActuals && (
                        <Col xs={12} md={4}>
                            <div className="th">{buildingId ? t('commonCost.costsTable.prm2') : t('commonCost.costsTable.units')}</div>
                        </Col>
                    )}
                </Row>
            </div>
            <div className={`table-row`}>
                <Row align={'middle'}>
                    <Col xs={12} md={showActuals ? 3 : 4}>
                        <div className="td" style={{ paddingLeft: '15px' }}>
                            {t('commonCost.analysisDetails.total')}
                        </div>
                    </Col>
                    <Col xs={12} md={showActuals ? 3 : 4}>
                        <div className="td">
                            <span className="hidden-md">{t('commonCost.analysisDetails.budget')}:</span>
                            <CurrencyFormat value={commonCost.calculatedBudgetTotalAmount - commonCost.calculatedBudgetAdministrationFeeAmount} />
                        </div>
                    </Col>
                    {showActuals && (
                        <>
                            <Col xs={12} md={3}>
                                <div className="td">
                                    <span className="hidden-md">{t('commonCost.analysisDetails.actual')}:</span>
                                    <CurrencyFormat value={commonCost.calculatedActualTotalAmount - commonCost.calculatedActualAdministrationFeeAmount} />
                                </div>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="td">
                                    <span className="hidden-md">{t('commonCost.costsTable.discrepancy')}:</span>
                                    <CurrencyFormat
                                        value={
                                            commonCost.calculatedBudgetTotalAmount -
                                            commonCost.calculatedBudgetAdministrationFeeAmount -
                                            commonCost.calculatedActualTotalAmount +
                                            commonCost.calculatedActualAdministrationFeeAmount
                                        }
                                    />
                                </div>
                            </Col>
                        </>
                    )}
                    {!showActuals && (
                        <Col xs={12} md={4}>
                            <div className="td">
                                <span className="hidden-md">{t('commonCost.costsTable.prm2')}:</span>
                                {showActuals ? (
                                    <CurrencyFormat value={commonCost.calculatedActualTotalAmount / commonCost.lettable} />
                                ) : (
                                    <CurrencyFormat value={(commonCost.calculatedBudgetTotalAmount - commonCost.calculatedBudgetAdministrationFeeAmount) / commonCost.lettable} />
                                )}
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
            <div className={`table-row alternate`}>
                <Row align={'middle'}>
                    <Col xs={12} md={showActuals ? 3 : 4}>
                        <div className="td" style={{ paddingLeft: '15px' }}>
                            {t('commonCost.analysisDetails.administrationFee')}
                        </div>
                    </Col>
                    <Col xs={12} md={showActuals ? 3 : 4}>
                        <div className="td">
                            <span className="hidden-md">{t('commonCost.analysisDetails.budget')}:</span>
                            <CurrencyFormat value={commonCost.calculatedBudgetAdministrationFeeAmount} />
                        </div>
                    </Col>
                    {showActuals && (
                        <>
                            <Col xs={12} md={3}>
                                <div className="td">
                                    <span className="hidden-md">{t('commonCost.analysisDetails.actual')}:</span>
                                    <CurrencyFormat value={commonCost.calculatedActualAdministrationFeeAmount} />
                                </div>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="td">
                                    <span className="hidden-md">{t('commonCost.costsTable.discrepancy')}:</span>
                                    <CurrencyFormat value={commonCost.calculatedBudgetAdministrationFeeAmount - commonCost.calculatedActualAdministrationFeeAmount} />
                                </div>
                            </Col>
                        </>
                    )}
                    {!showActuals && (
                        <Col xs={12} md={4}>
                            <div className="td">
                                <span className="hidden-md">{t('commonCost.costsTable.prm2')}:</span>
                                {showActuals ? (
                                    <CurrencyFormat value={commonCost.calculatedActualAdministrationFeeAmount / commonCost.lettable} />
                                ) : (
                                    <CurrencyFormat value={commonCost.calculatedBudgetAdministrationFeeAmount / commonCost.lettable} />
                                )}
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
            <div className={`table-row`}>
                <Row align={'middle'}>
                    <Col xs={12} md={showActuals ? 3 : 4}>
                        <div className="td" style={{ paddingLeft: '15px' }}>
                            {t('commonCost.analysisDetails.totalInkFee')}
                        </div>
                    </Col>
                    <Col xs={12} md={showActuals ? 3 : 4}>
                        <div className="td">
                            <span className="hidden-md">{t('commonCost.analysisDetails.budget')}:</span>
                            <CurrencyFormat value={commonCost.calculatedBudgetTotalAmount} />
                        </div>
                    </Col>
                    {showActuals && (
                        <>
                            <Col xs={12} md={3}>
                                <div className="td">
                                    <span className="hidden-md">{t('commonCost.analysisDetails.actual')}:</span>
                                    <CurrencyFormat value={commonCost.calculatedActualTotalAmount} />
                                </div>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="td">
                                    <span className="hidden-md">{t('commonCost.costsTable.discrepancy')}:</span>
                                    <CurrencyFormat value={commonCost.calculatedBudgetTotalAmount - commonCost.calculatedActualTotalAmount} />
                                </div>
                            </Col>
                        </>
                    )}
                    {!showActuals && (
                        <Col xs={12} md={4}>
                            <div className="td">
                                <span className="hidden-md">{t('commonCost.costsTable.prm2')}:</span>
                                {showActuals ? (
                                    <CurrencyFormat value={commonCost.calculatedActualTotalAmount / commonCost.lettable} />
                                ) : (
                                    <CurrencyFormat value={commonCost.calculatedBudgetTotalAmount / commonCost.lettable} />
                                )}
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        </>
    );
};

export default CommonCostTotal;
