import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getPropertyKpis } from '../../reducers/propertyReducer';
import get from 'lodash-es/get';
import RentPerTenantChart from './rent-per-tenant-chart';
import RentObjectTypeChart from './rent-object-type-chart';
import RentExpirationProfileChart from './rent-expiration-profile-chart';
import ContractsTable from './contracts-table';
import LoaderSpinner from '../../components/spinner';
import { userPermisionsTypes } from '../../common/constants';

const PropertyDashboardComponent = (props) => {
    const { portfolioId, propertyId } = useParams();

    useEffect(() => {
        if (portfolioId && propertyId && !props.kpis && !props.isRentRollPermision) {
            props.getKpis(portfolioId, propertyId);
        }
    }, []);

    return (
        <React.Fragment>
            {!props.kpisLoading && props.kpis ? (
                <div className="dashboard property-dashboard">
                    <Row>
                        <Col sm={12} lg={8}>
                            <Row>
                                <Col sm={12} className="mb-4">
                                    <div className="chart-area">
                                        <RentPerTenantChart />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="mb-4">
                                    <div className="chart-area">
                                        <RentObjectTypeChart />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="chart-area rent-epiration-chart">
                                        <RentExpirationProfileChart />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={8} className="mb-4">
                            <ContractsTable />
                        </Col>
                        <Col sm={12} lg={4}></Col>
                    </Row>
                </div>
            ) : (
                <LoaderSpinner />
            )}
        </React.Fragment>
    );
};

const mapState = ({ property, navigation }) => {
    return {
        kpis: get(property, 'kpis'),
        kpisLoading: get(property, 'kpisLoading'),
        isRentRollPermision: get(navigation, 'selectedPortfolio.permissionId') == userPermisionsTypes.rentRolleUser
    };
};

const mapDispatch = (dispatch) => {
    return {
        getKpis(portfolioId, propertyId) {
            dispatch(getPropertyKpis(portfolioId, propertyId));
        },
    };
};

const PropertyDashboard = connect(mapState, mapDispatch)(PropertyDashboardComponent);
export default PropertyDashboard;
