import i18next from 'i18next';
import { enterBtnCode } from '../common/constants';
import localStorageService from '../services/localStorageService';

const helper = {
    transformNumberToLocale: (input) => {
        const value = +input;
        return value.toLocalizedString();
    },

    numberWithSpaces: (x) => {
        const currentSeparator = i18next.language == 'en' ? '.' : ',';

        if (!x) {
            return;
        }

        var parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return parts.join(currentSeparator);
    },

    numberWithTwoDigits: (num) => {
        if (!num) {
            return 0;
        }

        let number = typeof num == 'string' ? Number(num) : num;

        const result = number % 1 !== 0 ? number.roundToTwoDecimals() : number;
        return helper.numberWithSpaces(result);
    },

    preventEnterKeydownEvent: (event) => {
        return event.keyCode === enterBtnCode ? event.preventDefault() : '';
    },

    getEnumValue: (list, value) => {
        const item = list.find((x) => x.id === value);
        return item ? item.name() : '';
    },

    getFileExtension: (inputString) => {
        const fileTypeRegex = /\.([a-zA-Z0-9]+)$/;
        const match = fileTypeRegex.exec(inputString);

        if (!match) {
            return null;
        }

        const fileExtension = match[1];
        return fileExtension;
    },

    getPortfolioPropertyFromLocalStorage: (portfolioId) => {
        const currentUser = localStorageService.getUser() ?? localStorageService.getIdentityData()?.user;
        return currentUser?.userPortfolios.find((item) => item.portfolioId == portfolioId)?.propertyIds || [];
    },
};

export default helper;
