import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import mail from '../../assets/images/mail-default.svg';
import moreIcon from '../../assets/images/more.svg';
import phone from '../../assets/images/phone.svg';
import tenant from '../../assets/images/new-tenant-card-icon.svg';
import TextEllipsis from '../ellipsis-text';
import DeleteMenuItem from '../menu-items/delete-menu-item';
import EditMenuItem from '../menu-items/edit-menu-item';

const TenantCard = (props) => {
    const { t } = useTranslation();

    const openTenant = (_) => {
        props.openTenant(props.tenant);
    };

    const editTenant = (_) => {
        props.editTenant && props.editTenant(props.tenant);
    };

    const deleteTenant = (_) => {
        props.deleteTenant && props.deleteTenant(props.tenant);
    };

    const dropdownMenu = () => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" onClick={editTenant} disabled={!props.hasEditPermission || props.isArchived} text={t('tenant.editTenant')} />
                <DeleteMenuItem key="1" onClick={deleteTenant} disabled={!props.hasEditPermission || props.isArchived} text={t('tenant.deleteTenant')} />
            </Menu>
        );
    };

    return (
        <div className="entity-card card-wrapper pt-3">
            <div className="image" onClick={openTenant}>
                <Image src={tenant} />
            </div>
            <div className="card-content">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <TextEllipsis>
                        <h6 className="ellipsis">{props.tenant.orgName || props.tenant.firstName + ' ' + props.tenant.lastName}</h6>
                    </TextEllipsis>
                    {props.deleteTenant && props.editTenant ? (
                        <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu()}>
                            <Button className="btn-link btn-transparent settings-btn">
                                <Image src={moreIcon} />
                            </Button>
                        </Dropdown>
                    ) : null}
                </div>
                <p className="text">{props.tenant.address}</p>
                {props.tenant.phone && (
                    <div className="property">
                        <Image src={phone} />
                        <p className="text-secondary mb-0">{props.tenant.phone}</p>
                    </div>
                )}
                {props.tenant.corporateEmail && (
                    <div className="property">
                        <Image src={mail} />
                        <TextEllipsis>
                            <p className="text-secondary mb-0 ellipsis">{props.tenant.corporateEmail}</p>
                        </TextEllipsis>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TenantCard;
