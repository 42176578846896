import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import backofficeReducer from './reducers/backofficeReducer';
import buildingReducer from './reducers/buildingReducer';
import commonCostReducer from './reducers/commonCostReducer';
import commonReducer from './reducers/commonReducer';
import contractReducer from './reducers/contractReducer';
import eventsReducer from './reducers/eventsReducer';
import invoiceReducer from './reducers/invoiceReducer';
import loginReducer from './reducers/loginReducer';
import navigationReducer from './reducers/navigationReducer';
import passwordReducer from './reducers/newPasswordReducer';
import ownerCostReducer from './reducers/ownerCostReducer';
import plotReducer from './reducers/plotReducer';
import portfolioReducer from './reducers/portfolioReducer';
import propertyReducer from './reducers/propertyReducer';
import registerReducer from './reducers/signUpReducer';
import tenantReducer from './reducers/tenantReducer';
import translationsReducer from './reducers/translationsReducer';
import userSettingsReducer from './reducers/userSettingsReducer';

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    login: loginReducer,
    register: registerReducer,
    password: passwordReducer,
    plot: plotReducer,
    building: buildingReducer,
    portfolio: portfolioReducer,
    navigation: navigationReducer,
    userSettings: userSettingsReducer,
    tenant: tenantReducer,
    property: propertyReducer,
    contract: contractReducer,
    translations: translationsReducer,
    backOffice: backofficeReducer,
    events: eventsReducer,
    invoice: invoiceReducer,
    commonCost: commonCostReducer,
    ownerCost: ownerCostReducer,
};

export default function configureStore(history, initialState) {
    const middleware = [thunk, routerMiddleware(history)];

    const appReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
        common: commonReducer,
    });

    const rootReducer = (state, action) => {
        if (action.type === 'CLEAR') {
            state = {
                router: state.router,
                common: state.common,
                navigation: state.navigation,
            };
        }

        return appReducer(state, action);
    };

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
