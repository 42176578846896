import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { DatePicker, Form, Radio, Select } from 'antd';
import { Col, Image, Row } from 'react-bootstrap';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import calendarIcon from '../../../assets/images/calendar-icon.svg';
import mobileRangePickerPanel from '../../../components/mobile-range-picker-panel';
import get from 'lodash-es/get';
import moment from 'moment';
import PositiveNumericInput from '../../../components/numeric-input';
import CommonInput from '../../../components/common-input';
import { requiredCommonInput } from '../../../common/validators';
import { getAreaForAssignedAsset } from '../../../reducers/commonCostReducer';

const { RangePicker } = DatePicker;
const { Option } = Select;

const CommonCostDetailsComponent = (props) => {
    const { t } = useTranslation();
    const { propertyId, portfolioId } = useParams();
    const [assignedType, setAssignedType] = useState(props.commonCost && props.commonCost.plotId ? 'plot' : 'building');
    const [selectedDates, setSelectedDates] = useState([
        props.commonCost?.startDate ? moment.utc(props.commonCost.startDate) : moment().startOf('year').utc(),
        props.commonCost?.endDate ? moment.utc(props.commonCost?.endDate) : moment().endOf('year').utc(),
    ]);
    const [clonedSelectedDates, setClonedSelectedDates] = useState(selectedDates);
    const [pickerOpened, setPickerOpened] = useState(false);

    useEffect(() => {
        const { name, startDate, endDate, buildingId, plotId, lettable, vacancyArea } = { ...(props.commonCost || {}) };
        const costPeriod = startDate && endDate ? [moment(startDate).utc(true), moment(endDate).utc(true)] : [moment([moment().year()]), moment([moment().year()]).endOf('year')];
        props.detailsRef.current.setFieldsValue({
            name,
            costPeriod,
            buildingId,
            plotId,
            lettable,
            grassArea: lettable,
            vacancyArea,
        });
    }, [props.commonCost]);

    useEffect(() => {
        props.assignedAssetAreaData &&
            props.detailsRef.current.setFieldsValue({
                lettable: props.assignedAssetAreaData.lettable,
                vacancyArea: props.assignedAssetAreaData.vacancyArea,
            });
    }, [props.assignedAssetAreaData]);

    const plotOptions = props.plots
        ? props.plots.map((plot) => {
              return { id: 'p-' + plot.id, name: plot.displayName, value: plot.id };
          })
        : [];

    const buildingOptions = props.buildings
        ? props.buildings.map((building) => {
              return { id: 'b-' + building.id, name: building.name || building.address, value: building.id };
          })
        : [];

    const onChangeAssignEntity = (e) => {
        props.detailsRef.current.resetFields(['buildingId', 'plotId', 'lettable', 'vacancyArea']);
        setAssignedType(e.target.value);
    };

    const onBuildingSelected = (buildingId) => {
        props.detailsRef.current.resetFields(['lettable', 'vacancyArea']);
        props.getAreaForAssignedAssetCall(propertyId, null, buildingId, portfolioId);
    };

    const onPlotSelected = (plotId) => {
        props.detailsRef.current.resetFields(['lettable', 'vacancyArea']);
        props.getAreaForAssignedAssetCall(propertyId, plotId, null, portfolioId);
    };

    useEffect(() => {
        props.detailsRef.current.setFieldsValue({
            costPeriod: selectedDates,
        });
    }, [selectedDates]);

    const disabledDate = (current) => {
        if (!selectedDates) {
            return false;
        }
        const daysInYear = moment().set('year', current.year).set('month', 11).set('date', 31).dayOfYear();
        const tooLate = selectedDates[0] && current.diff(selectedDates[0], 'days') > daysInYear;
        const tooEarly = selectedDates[1] && selectedDates[1].diff(current, 'days') > daysInYear;
        return !!tooEarly || !!tooLate;
    };

    const validateDateRange = (_, value) => {
        if (value?.length && value[0] && value[1]) {
            return Promise.resolve();
        }
        return Promise.reject(t('commonCost.detailsStep.costPeriod') + t('common.isRequired'));
    };

    return (
        <div className="page-container">
            <Row>
                <Col lg={12} xl={6}>
                    <Form.Item name="name" rules={[requiredCommonInput(t('commonCost.detailsStep.name') + t('common.isRequired'))]}>
                        <CommonInput isRequired={true} maxLength={150} title={t('commonCost.detailsStep.name.title')} placeholder={t('commonCost.detailsStep.name.placeholder')} />
                    </Form.Item>
                    <div className="common-input">
                        <p>{t('commonCost.detailsStep.costPeriod.title')}</p>
                    </div>
                    <Form.Item
                        name="costPeriod"
                        rules={[
                            {
                                required: true,
                                message: t('commonCost.detailsStep.costPeriod') + t('common.isRequired'),
                            },
                            { validator: validateDateRange },
                        ]}>
                        <RangePicker
                            allowClear={false}
                            panelRender={mobileRangePickerPanel}
                            suffixIcon={<Image src={calendarIcon} />}
                            format={primaryDateFormat}
                            dropdownClassName="new-design-date-picker"
                            disabledDate={disabledDate}
                            onCalendarChange={(dates, b, { range }) => {
                                setSelectedDates(dates);
                                setClonedSelectedDates(dates);
                            }}
                            onOpenChange={(open) => {
                                if (open) {
                                    setSelectedDates([null, null]);
                                } else {
                                    if (!selectedDates[0] || !selectedDates[1]) {
                                        setSelectedDates(clonedSelectedDates);
                                    }
                                }

                                setPickerOpened(open);
                            }}
                            open={pickerOpened}
                        />
                    </Form.Item>
                    <p className="font-weight-bold mb-2">{t('commonCost.detailsStep.assign.title')}</p>
                    <Radio.Group defaultValue={assignedType} className="radio-container vertical-radio-container my-3" onChange={onChangeAssignEntity}>
                        <Radio value={'plot'}>{t('commonCost.detailsStep.assign.radioBtns.plot')}</Radio>
                        <Radio value={'building'}>{t('commonCost.detailsStep.assign.radioBtns.building')}</Radio>
                    </Radio.Group>
                    {assignedType === 'building' ? (
                        <Form.Item
                            name="buildingId"
                            rules={[
                                {
                                    required: true,
                                    message: t('commonCost.detailsStep.assign.title') + t('common.isRequired'),
                                },
                            ]}>
                            <Select placeholder={t('commonCost.detailsStep.assign.placeholderBuilding')} onChange={onBuildingSelected}>
                                {buildingOptions.map((option) => (
                                    <Option value={option.value} info={{ type: 'building' }} key={option.id}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name="plotId"
                            rules={[
                                {
                                    required: true,
                                    message: t('commonCost.detailsStep.assign.title') + t('common.isRequired'),
                                },
                            ]}>
                            <Select placeholder={t('commonCost.detailsStep.assign.placeholderPlot')} onChange={onPlotSelected}>
                                {plotOptions.map((option) => (
                                    <Option value={option.value} info={{ type: 'plot' }} key={option.id}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    <p className="font-weight-bold mb-2">{t('commonCost.detailsStep.lettableArea.title')}</p>
                    <Form.Item
                        name="lettable"
                        rules={[
                            {
                                required: true,
                                message: t('commonCost.detailsStep.lettableArea.title') + t('common.isRequired'),
                            },
                        ]}>
                        <PositiveNumericInput placeholder={t('commonCost.detailsStep.lettableArea.placeholder')} />
                    </Form.Item>
                    <p className="font-weight-bold mb-2">{t('commonCost.detailsStep.vacancyArea.title')}</p>
                    <Form.Item name="vacancyArea">
                        <PositiveNumericInput placeholder={t('commonCost.detailsStep.vacancyArea.placeholder')} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

const mapState = ({ commonCost, property }) => {
    return {
        commonCost: get(commonCost, 'commonCost'),
        assignedAssetAreaData: get(commonCost, 'assignedAssetAreaData'),
        plots: get(property, 'property.plots'),
        buildings: get(property, 'property.buildings'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        getAreaForAssignedAssetCall(propertyId, plotId, buildingId, portfolioId) {
            dispatch(getAreaForAssignedAsset(propertyId, plotId, buildingId, portfolioId));
        },
    };
};

const CommonCostDetails = connect(mapState, mapDispatch)(CommonCostDetailsComponent);
export default CommonCostDetails;
