import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { commonCostDetailsSelector, ownerCostSelector, showActualsSelector } from '../../selectors';
import { setValueCommonCost } from '../../../../reducers/commonCostReducer';
import { Switch } from 'antd';
import DefaultTooltip from '../../../../components/common-cost/default-tooltip';

const ActualToggleComponent = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const commonCost = useSelector(ownerCostSelector);
    const showActuals = useSelector(showActualsSelector);
    const setValueCommonCostCall = (key, value) => dispatch(setValueCommonCost(key, value));

    const onToggleActualSwitch = () => {
        setValueCommonCostCall('showActuals', !showActuals);
    };

    return (
        <div className="d-flex justify-content-between">
            <div className="chart-title mb-2">
                {props.isAnalysisTab ? showActuals ? <span>{t('commonCost.analysis.budgetAndActuals')}</span> : <span>{t('commonCost.analysisDetails.budget')}</span> : <></>}
            </div>
            <div className="d-flex">
                <p className={'mr-2 mb-0 ' + (commonCost.hasAnyActuals ? '' : 'text-secondary')}>{t('commonCost.analysis.showActuals')}</p>
                <DefaultTooltip title={!commonCost.hasAnyActuals && t('commonCost.analysis.warning')} placement="bottom">
                    <Switch disabled={!commonCost.hasAnyActuals} checked={showActuals} onChange={onToggleActualSwitch} />
                </DefaultTooltip>
            </div>
        </div>
    );
};

export default ActualToggleComponent;
