import React, { useEffect, useState } from 'react';
import { Form, Select, Switch } from 'antd';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { deleteCommonCost, getListOfCommonCosts, setDeleteCommonCostModel, setValueCommonCost } from '../../reducers/commonCostReducer';
import routingService from '../../services/routingService';
import get from 'lodash-es/get';
import moment from 'moment';
import emptyCommonCost from '../../assets/images/empty-cost.svg';
import add from '../../assets/images/plus.svg';
import ActionButton from '../../components/action-button';
import EmptyList from '../../components/empty-list/empty-list';
import ConfirmDeleteCommonCost from './modals/confirm-delete-modal';
import CommonCostCard from '../../components/common-cost/common-cost-card';
import CommonCostBudgetsAddResult from './modals/budgets-add-result-modal';
import CommonCostUpdateResult from './modals/common-cost-updated-result-modal';
import LoaderSpinner from '../../components/spinner';
import { filterOptions } from '../../common/property-options';
import { editPermisionTypes } from '../../common/constants';

const { Option } = Select;

const CommonCostsComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId, propertyId } = useParams();
    const listRef = React.useRef();
    const [filteredCommonCosts, setFilteredCommonCosts] = useState(props.commonCosts || []);
    const plots = useSelector(({ property }) => property.property.plots);

    useEffect(() => {
        props.getListOfCommonCostsCall(portfolioId, propertyId);
    }, [props.filterOption]);

    useEffect(() => {
        const commonCostsYears = props.commonCosts?.map((c) => c.year);
        if (commonCostsYears && commonCostsYears.length) {
            commonCostsYears.sort((f, s) => s - f);
            const newestYear = commonCostsYears[0];

            setFilteredCommonCosts(props.commonCosts.filter((commonCost) => newestYear === commonCost.year));
            listRef.current.setFieldsValue({ year: props.hasCommonCosts ? newestYear : null });
        } else {
            setFilteredCommonCosts(props.commonCosts.filter((commonCost) => moment().year() === commonCost.year));
            listRef.current.setFieldsValue({ year: props.hasCommonCosts ? moment().year() : null });
        }

        const selectedYear = props.location?.state?.selectedYear;
        if (selectedYear) {
            const parsedYear = moment.utc(selectedYear).year();

            onSelectYear(parsedYear);
            listRef.current.setFieldsValue({ year: props.hasCommonCosts ? parsedYear : null });
        }
    }, [props.commonCosts]);

    const openCommonCost = (commonCostId) => {
        routingService.navigateToCommonCostDetails(portfolioId, propertyId, commonCostId);
    };

    const createCommonCost = () => {
        if (!props.hasEditPermission) return;
        routingService.navigateToCreateCommonCost(portfolioId, propertyId);
    };

    const editCommonCost = (commonCost) => {
        routingService.navigateToEditCommonCost(portfolioId, propertyId, commonCost.id);
    };

    const deleteCommonCost = (commonCost) => {
        props.setDeleteCommonCostModelCall(commonCost);
        props.showCommonCostDeleteModal();
    };

    const handleDeleteCommonCost = async () => {
        await props.deleteCommonCostCall(props.deleteCommonCostModel.propertyId, props.deleteCommonCostModel.id, portfolioId);
        const list = props.commonCosts.filter((x) => x.id !== props.deleteCommonCostModel.id);
        props.setValueCommonCostCall('commonCosts', list);
    };

    const onSelectYear = (year) => {
        setFilteredCommonCosts(props.commonCosts.filter((commonCost) => year === commonCost.year));
    };

    const addNewCommonCostBtn = (className) => (
        <ActionButton
            className={`btn-primary width-fit-content ${className ? className : ''}`}
            icon={<Image src={add} />}
            text={t('commonCost.btnAddNew')}
            disabled={!props.hasEditPermission || plots.length === 0 || props.isArchived}
            onClick={createCommonCost}
        />
    );

    return (
        <div className="list common-costs">
            <div className="search-header">
                <Form name="commonCostsList" autoComplete="off" ref={listRef}>
                    <div className="select-wrapper">
                        <Form.Item name="year">
                            <Select onSelect={onSelectYear} placeholder={t('commonCost.selectYearPlaceholder')} disabled={!props.hasCommonCosts}>
                                {props.commonCostsYears.map((year) => (
                                    <Option value={year} key={year}>
                                        {year}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Switch
                            checkedChildren="Active"
                            checked={props.filterOption == 1 ? true : false}
                            unCheckedChildren="Archived"
                            onChange={(checked) => props.setValueCommonCostCall('filterOption', checked ? filterOptions.active : filterOptions.archived)}
                        />
                    </div>
                </Form>
                {addNewCommonCostBtn()}
            </div>
            {props.loading ? (
                <LoaderSpinner />
            ) : props.hasCommonCosts ? (
                <div className="cards">
                    {filteredCommonCosts.map((commonCost) => (
                        <CommonCostCard
                            key={commonCost.id}
                            commonCost={commonCost}
                            openCommonCost={openCommonCost}
                            editCommonCost={editCommonCost}
                            deleteCommonCost={deleteCommonCost}
                            hasEditPermission={props.hasEditPermission}
                            property={props.property}
                            isArchived={props.isArchived}
                        />
                    ))}
                </div>
            ) : (
                <EmptyList image={emptyCommonCost} title={t('commonCost.emptyList.title')} subTitle={t('commonCost.emptyList.subTitle')}>
                    {addNewCommonCostBtn('hide-on-mobile')}
                </EmptyList>
            )}
            {props.showConfirmDeleteCommonCostModal && <ConfirmDeleteCommonCost handleDeleteCommonCost={handleDeleteCommonCost} />}
            {props.showBudgetsAddResultModal && <CommonCostBudgetsAddResult portfolioId={portfolioId} propertyId={propertyId} commonCostId={props.location.state.commonCostId} />}
            {props.showCommonCostUpdatedResultModal && <CommonCostUpdateResult />}
        </div>
    );
};

const mapState = ({ navigation, commonCost, property }) => {
    return {
        loading: get(commonCost, 'commonCostLoading'),
        commonCosts: get(commonCost, 'commonCosts'),
        hasCommonCosts: get(commonCost, 'commonCosts.length') > 0,
        commonCostsYears: get(commonCost, 'commonCostsYears'),
        showBudgetsAddResultModal: get(commonCost, 'showBudgetsAddResultModal'),
        showCommonCostUpdatedResultModal: get(commonCost, 'showCommonCostUpdatedResultModal'),
        showConfirmDeleteCommonCostModal: get(commonCost, 'showConfirmDeleteCommonCostModal'),
        deleteCommonCostModel: get(commonCost, 'deleteCommonCostModel'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        property: get(property, 'property'),
        filterOption: get(commonCost, 'filterOption'),
        isArchived: get(property, 'property.isArchived'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        deleteCommonCostCall(propertyId, commonCostId, portfolioId) {
            return dispatch(deleteCommonCost(propertyId, commonCostId, portfolioId));
        },
        getListOfCommonCostsCall(portfolioId, propertyId, year) {
            return dispatch(getListOfCommonCosts(portfolioId, propertyId, year));
        },
        showCommonCostDeleteModal() {
            dispatch(setValueCommonCost('showConfirmDeleteCommonCostModal', true));
        },
        setDeleteCommonCostModelCall(value) {
            dispatch(setDeleteCommonCostModel(value));
        },
        setValueCommonCostCall(key, value) {
            dispatch(setValueCommonCost(key, value));
        },
    };
};

const CommonCosts = withRouter(connect(mapState, mapDispatch)(CommonCostsComponent));
export default CommonCosts;
