import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import LoginSignUpLayout from '../../components/layouts/login-layout';
import { Link } from 'react-router-dom';
import routingService from '../../services/routingService';

const InvitationExpired = ({ match }) => {
    const { t } = useTranslation();

    return (
        <LoginSignUpLayout header={t('expiredInvitation.greeting')}>
            <React.Fragment>
                {match.params.reason === 'registration' ? (
                    <div>
                        {t('expiredInvitation.registrationExpired')}
                        <Link to={routingService.signupUrl()}>{t('expiredInvitation.signUp')}</Link>
                        {t('expiredInvitation.receiveNewOne')}
                    </div>
                ) : (
                    <div>{t('expiredInvitation.invitext')}</div>
                )}
            </React.Fragment>
        </LoginSignUpLayout>
    );
};

export default withRouter(InvitationExpired);
