import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { getCountries } from '../../../reducers/commonReducer';
import { editTenant, getTenantById, setValueTenant } from '../../../reducers/tenantReducer';
import get from 'lodash-es/get';
import clear from '../../../common/clear';
import objectHelper from '../../../helpers/trim-object';
import tenant from '../../../assets/images/new-design-tenant-icon.svg';
import review from '../../../assets/images/review.svg';
import commonService from '../../../services/commonService';
import InformationComponent from '../../../components/information-container';
import ObjectLayout from '../../../components/layouts/object-layout';
import LeaveModal from '../../../components/leave-modal';
import TenantByCompany from './tenant-company';
import TenantByPerson from './tenant-person';
import TenantReview from './review-step';
import NotFoundPage from '../../../components/error-page';
import routingService from '../../../services/routingService';
import { filterOptions } from '../../../common/property-options';

const EditTenantComponent = (props) => {
    const stepNames = {
        details: 'details',
        review: 'review',
    };

    const { t } = useTranslation();
    const { portfolioId, propertyId, tenantId, isArchived } = useParams();
    const [currentStepKey, setCurrentStepKey] = useState(stepNames.details);
    const tenantRef = useRef();
    const tenantsUrl = routingService.propertyTenantsUrl(portfolioId, propertyId);

    useEffect(() => {
        if (!props.hasCountries) {
            props.getCountriesCall();
        }
        if (portfolioId && propertyId && tenantId && isArchived) {
            props.getTenantByIdCall(portfolioId, tenantId, propertyId, isArchived ? filterOptions.archived : filterOptions.active);
        } else if (portfolioId && propertyId) {
            goOutOfPage();
        } else {
            routingService.navigateToPortfolios();
        }
    }, []);

    const goOutOfPage = () => {
        routingService.goTo(tenantsUrl);
    };

    const onNext = () => {
        validateTenantRef()
            .then((_) => {
                const tenant = getTenantFromForm();
                tenant.id = props.tenant.id;
                props.setValueTenantCall('tenant', tenant);

                setCurrentStepKey(stepNames.review);
            })
            .catch((_) => {});
    };

    const onPrevious = () => {
        setCurrentStepKey(stepNames.details);
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious();
        } else {
            onNext();
        }
    };

    const getTenantFromForm = () => {
        if (tenantRef.current) {
            const values = tenantRef.current.getFieldsValue();
            const tenant = objectHelper.trimObjectProperties(values);
            if (tenant.phone && tenant.phone[0] !== '+') {
                tenant.phone = `+${tenant.phone}`;
            }
            return tenant;
        }
        return null;
    };

    const validateTenantRef = () => {
        return tenantRef.current.validateFields();
    };

    const onSaveAndExit = () => {
        if (propertyId) {
            if (currentStepKey === stepNames.details) {
                validateTenantRef()
                    .then((_) => {
                        updateTenant(getTenantFromForm(), propertyId);
                    })
                    .catch((_) => {});
            } else {
                updateTenant(props.tenant, propertyId);
            }
        }
    };

    const updateTenant = (tenant, propertyId) => {
        tenant.propertyId = +propertyId;
        tenant.id = props.tenant.id;
        props.editTenantCall(tenant, propertyId, portfolioId).then((tenantId) => updated(tenantId));
    };

    const updated = (tenantId) => {
        if (tenantId) {
            goOutOfPage();
            props.showTenantUpdatedResult();
        }
    };

    const onOpenCloseDraftModal = () => {
        props.setValueTenantCall('showDraftModal', !props.showDraftModal);
    };

    const steps = [
        {
            key: stepNames.details,
            header: t('tenant.editTenant'),
            stepTitle: t('tenant.editTenant'),
            logo: tenant,
            canShowAsCompleted: true,
            content: (
                <div className="page-container">
                    <Form name="basic" autoComplete="off" ref={tenantRef}>
                        {props.tenant.ownedBy === commonService.ownerRadioGroupTypes.company ? <TenantByCompany tenantRef={tenantRef} /> : <TenantByPerson tenantRef={tenantRef} />}
                    </Form>
                </div>
            ),
        },
        {
            key: stepNames.review,
            header: t('tenant.review.header'),
            stepTitle: t('tenant.review.stepTitle'),
            logo: review,
            content: <TenantReview goToStep={setCurrentStepKey} />,
        },
    ];

    return (
        <React.Fragment>
            {props.tenantNotFound ? <NotFoundPage header={t('tenant.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {!props.tenantNotFound && !props.propertyNotFound ? (
                <ObjectLayout
                    bottomRightSideBar={
                        <React.Fragment>
                            {currentStepKey === stepNames.details && <InformationComponent title={t('tenant.information.title')} text={t('tenant.information.text')} />}
                        </React.Fragment>
                    }
                    steps={steps}
                    timelineSteps={steps}
                    isEdit={true}
                    currentStepKey={currentStepKey}
                    exitUrl={tenantsUrl}
                    onNext={onNext}
                    loading={props.tenantLoading}
                    onPrevious={onPrevious}
                    onSaveAndExit={onSaveAndExit}
                    onSwitchStep={onSwitchStep}
                    onCancel={onOpenCloseDraftModal}
                />
            ) : null}
            {props.showDraftModal && <LeaveModal onCancel={onOpenCloseDraftModal} onLeave={goOutOfPage} onSave={onSaveAndExit} loading={props.tenantLoading} />}
        </React.Fragment>
    );
};

const mapState = ({ tenant, property, common }) => {
    return {
        tenant: get(tenant, 'tenant'),
        tenantLoading: get(tenant, 'tenantLoading'),
        tenantNotFound: get(tenant, 'tenantNotFound'),
        propertyNotFound: get(property, 'propertyNotFound'),
        showDraftModal: get(tenant, 'showDraftModal'),
        hasCountries: get(common, 'countries.length') > 0,
    };
};

const mapDispatch = (dispatch) => {
    return {
        editTenantCall(value, propertyId, portfolioId) {
            return dispatch(editTenant(value, propertyId, portfolioId));
        },
        setValueTenantCall(key, value) {
            dispatch(setValueTenant(key, value));
        },
        getTenantByIdCall(portfolioId, tenantId, propertyId) {
            return dispatch(getTenantById(portfolioId, tenantId, propertyId));
        },
        showTenantUpdatedResult() {
            dispatch(setValueTenant('showItemUpdatedResultModal', true));
        },
        getCountriesCall() {
            dispatch(getCountries());
        },
    };
};

const EditTenant = clear(connect(mapState, mapDispatch)(EditTenantComponent));
export default EditTenant;
