import React, { useEffect } from 'react';
import { Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setValueTenant } from '../../../reducers/tenantReducer';
import commonService from '../../../services/commonService';
import get from 'lodash-es/get';
import CountrySelect from '../../../components/country-selector';
import OwnPhoneInput from '../../../components/phone-input';
import CommonInput from '../../../components/common-input';
import { requiredCommonInput, validateEmail } from '../../../common/validators';

const TenantByPersonComponent = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.tenantRef.current.setFieldsValue(props.tenant);
    }, []);

    const onChangeRadioButton = (e) => {
        props.setValueTenantCall('tenant.ownedBy', e.target.value);
    };

    return (
        <div className="page-container object">
            <Row>
                <Col lg={12} xl={6}>
                    {!props.tenant.id && <h5>{t('tenant.details.title')}</h5>}
                    <Form.Item name="ownedBy">
                        <Radio.Group defaultValue={props.tenant.ownedBy} className="radio-container" onChange={onChangeRadioButton}>
                            <Radio
                                disabled={props.tenant.id && props.tenant.ownedBy === commonService.ownerRadioGroupTypes.person}
                                value={commonService.ownerRadioGroupTypes.company}>
                                {t('common.company')}
                            </Radio>
                            <Radio value={commonService.ownerRadioGroupTypes.person}>{t('common.person')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="firstName" rules={[requiredCommonInput(t('tenant.details.firstName.title') + t('common.isRequired'))]}>
                        <CommonInput autoFocus={true} maxLength={150} title={t('tenant.details.firstName.title')} placeholder={t('tenant.details.firstName.placeholder')} />
                    </Form.Item>
                    <Form.Item name="lastName" rules={[requiredCommonInput(t('tenant.details.lastName.title') + t('common.isRequired'))]}>
                        <CommonInput maxLength={150} title={t('tenant.details.lastName.title')} placeholder={t('tenant.details.lastName.placeholder')} />
                    </Form.Item>
                    <p className="font-weight-bold">{t('tenant.details.phone.title')}</p>
                    <Form.Item name="phone" style={{ marginBottom: '22px' }}>
                        <OwnPhoneInput placeholder={t('tenant.details.phone.placeholder')} />
                    </Form.Item>
                    <Form.Item name="emailForInvoices" rules={[validateEmail]}>
                        <CommonInput
                            type="email"
                            maxLength={150}
                            title={t('tenant.details.emailForInvoices.title')}
                            placeholder={t('tenant.details.emailForInvoices.placeholder')}
                        />
                    </Form.Item>
                    <Form.Item name="address" rules={[requiredCommonInput(t('tenant.details.address.title') + t('common.isRequired'))]}>
                        <CommonInput maxLength={150} title={t('tenant.details.address.title')} placeholder={t('tenant.details.address.placeholder')} />
                    </Form.Item>
                    <Form.Item name="post" rules={[requiredCommonInput(t('tenant.details.post.title') + t('common.isRequired'))]}>
                        <CommonInput maxLength={30} title={t('tenant.details.post.title')} placeholder={t('tenant.details.post.placeholder')} />
                    </Form.Item>
                    <Form.Item name="municipality" rules={[requiredCommonInput(t('tenant.details.municipality.title') + t('common.isRequired'))]}>
                        <CommonInput maxLength={150} title={t('tenant.details.municipality.title')} placeholder={t('tenant.details.municipality.placeholder')} />
                    </Form.Item>
                    <p className="font-weight-bold">{t('tenant.details.country.title')}</p>
                    <Form.Item
                        name="country"
                        initialValue={t('defaultCountry')}
                        rules={[
                            {
                                required: true,
                                message: t('tenant.details.country.title') + t('common.isRequired'),
                            },
                        ]}>
                        <CountrySelect placeholder={t('tenant.details.country.placeholder')} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

const mapState = ({ tenant }) => {
    return {
        tenant: get(tenant, 'tenant'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueTenantCall(key, value) {
            dispatch(setValueTenant(key, value));
        },
    };
};

const TenantByPerson = connect(mapState, mapDispatch)(TenantByPersonComponent);
export default TenantByPerson;
