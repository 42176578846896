import { CloseOutlined } from '@ant-design/icons';
import { Modal, Radio, Switch } from 'antd';
import get from 'lodash-es/get';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { eventPeriodes, eventStatuses, eventStatusesOptions } from '../../../../common/event-options';
import { setValueEvent } from '../../../../reducers/eventsReducer';
import eventsService from '../../../../services/eventsService';
import ActionButton from '../../../action-button';

const EventsFilterModalComponent = (props) => {
    const { t } = useTranslation();
    const [eventCurrentFilter, setEventCurrentFilter] = useState(props.eventStatus);
    const [getAssignedToMeEvents, setGetAssignedToMeEvents] = useState(props.getAssignedToMeEvents);

    const filterIsChanged = props.eventStatus !== eventCurrentFilter || props.getAssignedToMeEvents !== getAssignedToMeEvents;

    const onFilter = () => {
        if (filterIsChanged) {
            if (props.onFilter) {
                props.setValueEventCall('eventStatus', eventCurrentFilter);
                props.setValueEventCall('getAssignedToMeEvents', getAssignedToMeEvents);
                props.setValueEventCall('disableLoadPastEventsBtn', false);
                props.setValueEventCall('disableLoadFutureEventsBtn', false);
                props.onFilter({
                    eventPeriod: eventPeriodes.current,
                    eventStatus: eventCurrentFilter,
                    getAssignedToMeEvents: getAssignedToMeEvents,
                    pagination: eventsService.defaultPagination,
                });
                props.closeModalWindow();
            }
        }
    };

    const onClear = () => {
        if (props.eventStatus !== eventStatuses.active || props.getAssignedToMeEvents) {
            if (props.onClear) {
                props.setValueEventCall('eventStatus', eventStatuses.active);
                props.setValueEventCall('getAssignedToMeEvents', false);
                props.setValueEventCall('disableLoadPastEventsBtn', false);
                props.setValueEventCall('disableLoadFutureEventsBtn', false);
                props.onClear();
            }
        }
        props.closeModalWindow();
    };

    const onCancel = () => {
        props.closeModalWindow();
    };

    return (
        <Modal
            wrapClassName="result-modal creation-modal"
            footer={null}
            visible={true}
            maskClosable={false}
            onCancel={onCancel}
            closeIcon={<CloseOutlined className="close-icon" />}>
            <div className="header flex-column">
                <h4 className="mb-0">{t('events.filterModal.header')}</h4>
            </div>
            <div className="main">
                <p className="font-weight-bold mb-0">{t('filterModal.showMe')}</p>
                <Radio.Group defaultValue={props.eventStatus} className="radio-container vertical-radio-container my-2" onChange={(e) => setEventCurrentFilter(e.target.value)}>
                    {eventStatusesOptions.map((item) => (
                        <Radio key={item.id} value={item.id}>
                            {item.name()}
                        </Radio>
                    ))}
                </Radio.Group>
                <p className="font-weight-bold mb-0">{t('events.filterModal.assigned.title')}</p>
                <div className="d-flex flex-row justify-content-between">
                    <p className="mb-0">
                        {t('events.filterModal.assigned.message')}
                        <span className="ml-1">{getAssignedToMeEvents ? t('common.on') : t('common.off')}</span>
                    </p>
                    <Switch checked={getAssignedToMeEvents} onChange={() => setGetAssignedToMeEvents(!getAssignedToMeEvents)} />
                </div>
            </div>
            <div className="btns justify-content-between">
                <ActionButton
                    onClick={onClear}
                    className="btn-thirdy"
                    text={t('filterModal.btnClear')}
                    disabled={eventCurrentFilter === eventStatuses.active && !getAssignedToMeEvents}></ActionButton>
                <div>
                    <ActionButton onClick={onCancel} className="btn-secondary" text={t('common.buttons.cancel')}></ActionButton>
                    <ActionButton onClick={onFilter} disabled={!filterIsChanged} className="btn-primary ml-3" text={t('common.buttons.saveChanges')}></ActionButton>
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ events }) => {
    return {
        eventStatus: get(events, 'eventStatus'),
        getAssignedToMeEvents: get(events, 'getAssignedToMeEvents'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueEventCall(key, value) {
            dispatch(setValueEvent(key, value));
        },
        closeModalWindow() {
            dispatch(setValueEvent('showEventFilterModalWindow', false));
        },
    };
};

const EventsFilterModal = connect(mapState, mapDispatch)(EventsFilterModalComponent);
export default EventsFilterModal;
