import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Form } from 'antd';
import { getCommonCostById, setValueCommonCost, updateCommonCost } from '../../../reducers/commonCostReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';
import costIcon from '../../../assets/images/new-design-cost-icon.svg';
import routingService from '../../../services/routingService';
import objectHelper from '../../../helpers/trim-object';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import moment from 'moment';
import NotFoundPage from '../../../components/error-page';
import LeaveModal from '../../../components/leave-modal';
import HowToAddYourCommonCost from '../info-container';
import CommonCostBudgetsReview from '../add-budget/review-step';
import CommonCostEditReview from './review-step';
import CommonCostDetails from '../add-budget/details-step';
import EditCommonCostBudgetItems from './edit-budget-items-step';
import EditCommonCostActual from './edit-actual-items-step';
import toaster from '../../../common/toaster';
import commonCostService from '../../../services/commonCostService';
import CostsObjectLayout from '../../../components/layouts/costs-object-layout';

const EditCommonCostComponent = (props) => {
    const { t } = useTranslation();

    const stepNames = {
        details: 'details',
        budgets: 'budgets',
        actuals: 'actuals',
        review: 'review',
    };

    const [currentStepKey, setCurrentStepKey] = useState(stepNames.details);
    const [timelineSteps, setTimelineSteps] = useState([]);
    const timelineStepsRef = useRef([]);
    const detailsRef = useRef();
    const budgetRef = useRef();
    const actualRef = useRef();
    const { portfolioId, propertyId, commonCostId } = useParams();

    useEffect(() => {
        if (portfolioId && propertyId && commonCostId) {
            props.getPropertyCall(portfolioId, propertyId);
            props.getCommonCostByIdCall(portfolioId, propertyId, commonCostId);
        } else if (portfolioId && propertyId) {
            goOutOfPage();
        }

        mapTimelineSteps();
    }, []);

    //#region navigation

    useEffect(() => {
        mapTimelineSteps();
    }, [props.commonCost]);

    const mapTimelineSteps = (_) => {
        const timelineSteps = steps.map((x) => ({
            key: x.key,
            stepTitle: x.stepTitle,
            isCompleted: isStepCompleted(x.key),
            canSkip: x.canSkip,
        }));

        setTimelineSteps(timelineSteps);
        timelineStepsRef.current = timelineSteps;
    };

    const isStepCompleted = (stepKey) => {
        if (!props.commonCost) {
            return false;
        }

        switch (stepKey) {
            case stepNames.details:
                return props.commonCost && props.commonCost.name && props.commonCost.startDate && props.commonCost.endDate;
            case stepNames.budgets:
                return props.hasAnyBudgets;
            case stepNames.actuals:
                return props.hasAnyActuals;
        }

        return false;
    };

    const onNext = () => {
        const currentStepIndex = timelineSteps.findIndex((x) => x.key === currentStepKey);
        const nextStep = timelineSteps[currentStepIndex + 1];

        if (!nextStep) {
            return;
        }

        onStepChanging().then((updatedCommonCost) => updatedCommonCost && setCurrentStepKey(nextStep.key));
    };

    const onPrevious = (previousStep) => {
        if (previousStep) {
            setCurrentStepKey(previousStep.key);
        } else {
            const currentStepIndex = steps.findIndex((x) => x.key === currentStepKey);
            const previousStep = steps[currentStepIndex - 1];
            previousStep && setCurrentStepKey(previousStep.key);
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious(newStep);
        } else {
            onStepChanging().then((updatedCommonCost) => updatedCommonCost && onChangeStep(newStep.key));
        }
    };

    const onChangeStep = (newStepKey) => {
        const timelineStepsByRef = timelineStepsRef.current;
        const newIndex = timelineStepsByRef.findIndex((x) => x.key === newStepKey);
        const previousSteps = timelineStepsByRef.slice(0, newIndex);
        const isPreviousStepsAllCompleted = previousSteps.every((x) => x.isCompleted || x.canSkip);

        if (isPreviousStepsAllCompleted) {
            setCurrentStepKey(newStepKey);
        } else {
            const firstInCompletedStep = timelineStepsByRef.find((x) => !x.isCompleted || x.canSkip);
            firstInCompletedStep && setCurrentStepKey(firstInCompletedStep.key);
        }
    };

    //#endregion

    const getDataFromDetailsForm = (_) => {
        const values = detailsRef.current.getFieldsValue();
        return objectHelper.trimObjectProperties(values);
    };

    const validateStep = (formRef) => {
        return formRef.current.validateFields();
    };

    const onStepChanging = async () => {
        const commonCost = { ...props.commonCost };
        switch (currentStepKey) {
            case stepNames.details: {
                return validateStep(detailsRef).then((_) => {
                    const formData = getDataFromDetailsForm();
                    commonCost.startDate = moment(formData.costPeriod[0]).startOf('day').utc(true);
                    commonCost.endDate = moment(formData.costPeriod[1]).startOf('day').utc(true);
                    commonCost.name = formData.name;
                    commonCost.buildingId = formData.buildingId;
                    commonCost.plotId = formData.plotId;
                    commonCost.lettable = formData.lettable;
                    commonCost.grossArea = formData.lettable;
                    commonCost.nonLettable = 0;
                    commonCost.vacancyArea = formData.vacancyArea;
                    props.setValueCommonCostCall('commonCost', commonCost);

                    return Promise.resolve(commonCost);
                });
            }
            case stepNames.budgets: {
                await budgetRef.current.validateFields();
                const formDataBudgets = budgetRef.current.getFieldsValue();
                const budgetItems = mapBudgetCostItems(formDataBudgets, props.commonCostBudgets);

                if (commonCostService.listOfCostsIsEmpty(budgetItems)) {
                    toaster.error(t('commonCost.budgets.atLeastOneBudgetCost'), null);
                    return Promise.reject();
                }

                commonCost.budgetAdministrationFee = formDataBudgets.budgetAdministrationFee;
                commonCost.budgetAdministrationFeePercent = formDataBudgets.budgetAdministrationFeePercent;

                props.setValueCommonCostCall('commonCost', commonCost);
                props.setValueCommonCostCall('commonCost.commonCostBudgets', budgetItems);
                props.setValueCommonCostCall('commonCost.commonCostItems', [...commonCost.commonCostActuals, ...budgetItems]);

                return Promise.resolve(commonCost);
            }
            case stepNames.actuals: {
                await actualRef.current.validateFields();
                const formDataActuals = actualRef.current.getFieldsValue();
                const actualItems = mapActualCostItems(formDataActuals, props.commonCostActuals);

                if (commonCostService.listOfCostsIsEmpty(actualItems)) {
                    toaster.error(t('commonCost.actuals.atLeastOneActualCost'), null);
                    return Promise.reject();
                }

                commonCost.actualAdministrationFeePercent = formDataActuals.actualAdministrationFeePercent;
                commonCost.actualAdministrationFee = formDataActuals.actualAdministrationFee;
                commonCost.actualLandlordAdjustment = formDataActuals.actualLandlordAdjustment;
                commonCost.actualLandlordAdjustmentPercent = formDataActuals.actualLandlordAdjustmentPercent;

                props.setValueCommonCostCall('commonCost', commonCost);
                props.setValueCommonCostCall('commonCost.commonCostActuals', actualItems);
                props.setValueCommonCostCall('commonCost.commonCostItems', [...commonCost.commonCostBudgets, ...actualItems]);

                return Promise.resolve(commonCost);
            }
            default:
                return Promise.resolve(commonCost);
        }
    };

    const mapBudgetCostItems = (formData, storedItems) => {
        const predefinedItems = storedItems.filter((x) => x.id);
        const additionalItems = storedItems.filter((x) => !x.id);
        predefinedItems.forEach((x) => (x.value = formData['budget_id_' + x.id]));
        const items = [...predefinedItems, ...additionalItems];

        return items;
    };

    const mapActualCostItems = (formData, storedItems) => {
        const predefinedItems = storedItems.filter((x) => !x.isAdditional);
        const additionalItems = storedItems.filter((x) => x.isAdditional);
        predefinedItems.forEach((x) => (x.value = formData['actual_id_' + (x.id || x.uId)]));
        const items = [...predefinedItems, ...additionalItems].filter((i) => i.value);

        return items;
    };

    const onSaveAndExit = (_) => {
        onStepChanging().then((updatedCommonCost) => {
            updatedCommonCost.portfolioId = portfolioId;
            updatedCommonCost.commonCostBudgets = commonCostService.filterCommonCostItemsByValue(updatedCommonCost.commonCostBudgets);
            updatedCommonCost.commonCostActuals = commonCostService.filterCommonCostItemsByValue(updatedCommonCost.commonCostActuals);
            updatedCommonCost && props.updateCommonCostCall(updatedCommonCost).then((isSuccess) => isSuccess && updated());
        });
    };

    const updated = (_) => {
        goOutOfPage();
        props.showCommonCostUpdatedResult();
    };

    const goOutOfPage = (_) => {
        routingService.navigateToCommonCostDetails(portfolioId, propertyId, commonCostId);
    };

    const onOpenCloseDraftModal = (_) => {
        props.setValueCommonCostCall('showDraftModal', !props.showDraftModal);
    };

    const steps = [
        {
            key: stepNames.details,
            header: t('commonCost.detailsStep.header'),
            stepTitle: t('commonCost.detailsStep.stepTitle'),
            logo: costIcon,
            content: (
                <Form name="details" autoComplete="off" ref={detailsRef}>
                    <CommonCostDetails detailsRef={detailsRef} />
                </Form>
            ),
        },
        {
            key: stepNames.budgets,
            header: t('commonCost.budgetsStep.header'),
            stepTitle: t('commonCost.budgetsStep.stepTitle'),
            canSkip: true,
            content: (
                <Form name="budgets" autoComplete="off" ref={budgetRef}>
                    <EditCommonCostBudgetItems budgetRef={budgetRef} />
                </Form>
            ),
        },
        ...(props.commonCost && props.commonCost.hasAnyActuals
            ? [
                  {
                      key: stepNames.actuals,
                      header: t('commonCost.actualsStep.header'),
                      stepTitle: t('commonCost.actualsStep.stepTitle'),
                      content: (
                          <Form name="actuals" autoComplete="off" ref={actualRef}>
                              <EditCommonCostActual actualRef={actualRef} />
                          </Form>
                      ),
                  },
              ]
            : []),
        {
            key: stepNames.review,
            header: t('commonCost.reviewStep.header'),
            stepTitle: t('commonCost.reviewStep.header'),
            content: props.hasAnyActuals ? <CommonCostEditReview goToStep={setCurrentStepKey} /> : <CommonCostBudgetsReview goToStep={setCurrentStepKey} />,
        },
    ];

    return (
        <React.Fragment>
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.commonCostNotFound ? <NotFoundPage header={t('commonCost.notFound')} /> : null}
            {!props.portfolioNotFound && !props.propertyNotFound && !props.commonCostNotFound ? (
                <CostsObjectLayout
                    className="common-cost-new-design"
                    isNewDesign={true}
                    steps={steps}
                    timelineSteps={timelineSteps}
                    currentStepKey={currentStepKey}
                    bottomRightSideBar={<HowToAddYourCommonCost />}
                    exitUrl={routingService.propertyUrl(portfolioId, propertyId)}
                    isEdit={commonCostId}
                    loading={props.commonCostLoading}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onSwitchStep={onSwitchStep}
                    onSaveAndExit={onSaveAndExit}
                    onCancel={onOpenCloseDraftModal}
                />
            ) : null}
            {props.showDraftModal && <LeaveModal onCancel={onOpenCloseDraftModal} onLeave={goOutOfPage} onSave={onSaveAndExit} loading={props.commonCostLoading} className="cost-draft-modal"/>}
        </React.Fragment>
    );
};

const mapState = ({ property, portfolio, commonCost }) => {
    return {
        commonCost: get(commonCost, 'commonCost'),
        commonCostBudgets: get(commonCost, 'commonCost.commonCostBudgets'),
        commonCostActuals: get(commonCost, 'commonCost.commonCostActuals'),
        hasAnyBudgets: get(commonCost, 'commonCost.commonCostBudgets.length') > 0,
        hasAnyActuals: get(commonCost, 'commonCost.commonCostActuals.length') > 0,
        showDraftModal: get(commonCost, 'showDraftModal'),
        commonCostLoading: get(commonCost, 'commonCostLoading'),
        commonCostNotFound: get(commonCost, 'commonCostNotFound'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        updateCommonCostCall(values) {
            return dispatch(updateCommonCost(values));
        },
        getCommonCostByIdCall(portfolioId, propertyId, commonCostId) {
            return dispatch(getCommonCostById(portfolioId, propertyId, commonCostId));
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        setValueCommonCostCall(key, value) {
            dispatch(setValueCommonCost(key, value));
        },
        showCommonCostUpdatedResult() {
            dispatch(setValueCommonCost('showCommonCostUpdatedResultModal', true));
        },
    };
};

const EditCommonCost = clear(connect(mapState, mapDispatch)(EditCommonCostComponent));
export default EditCommonCost;
