import React, { useEffect, useState } from 'react';
import { Button, Divider, Tabs } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
    contractStatus,
    cpiIndexTypeOptions,
    invoiceIssueDateOptions,
    measurements,
    paymentPlanOptions,
    rentObjectRentalTypeOptions,
    rentObjectTypeOptions,
    turnoverRecurrencePeriodTypesOptions,
    unitTypeOptions,
} from '../../../../common/contract-options';
import helper from '../../../../helpers/helper';
import RentPaymentPlanTable from '../../create/finances/rent-payment-plan-table';
import moment from 'moment';
import ContentBlock from '../../../../components/content-block';
import { contractStepNames } from '../../create';

const CostAndPayment = ({ contract, editContract }) => {
    const { t, i18n } = useTranslation();
    const notApplicable = 'N/A';
    const [costAndPaymentsCollapsed, setCostAndPaymentsCollapsed] = useState(true);
    const [rentAdjustmentCollapse, setRentAdjustmentCollapse] = useState(
        contract.rentObjects.map((item) => {
            return {
                id: item.id,
                collapsed: true,
            };
        })
    );
    const [addedRentalPaymentPlanCollapse, setAddedRentalPaymentPlanCollapse] = useState(
        contract.rentObjects.map((item) => {
            return {
                id: item.id,
                collapsed: true,
            };
        })
    );
    const [addedRentalCommonCostCollapse, setAddedRentalCommonCostCollapse] = useState(
        contract.rentObjects.map((item) => {
            return {
                id: item.id,
                collapsed: true,
            };
        })
    );

    const collapseRentAdjustment = (id) => {
        const collapsedItem = rentAdjustmentCollapse.find((item) => item.id == id);

        setRentAdjustmentCollapse((prev) => {
            return prev.map((item) => {
                if (item.id === collapsedItem.id) {
                    return {
                        id: item.id,
                        collapsed: !item.collapsed,
                    };
                } else {
                    return item;
                }
            });
        });
    };

    const collapseAddedRentCommonCost = (id) => {
        const collapsedItem = addedRentalCommonCostCollapse.find((item) => item.id == id);

        setAddedRentalCommonCostCollapse((prev) => {
            return prev.map((item) => {
                if (item.id === collapsedItem.id) {
                    return {
                        id: item.id,
                        collapsed: !item.collapsed,
                    };
                } else {
                    return item;
                }
            });
        });
    };

    const collapseAddedRentPayment = (id) => {
        const collapsedItem = addedRentalPaymentPlanCollapse.find((item) => item.id == id);

        setAddedRentalPaymentPlanCollapse((prev) => {
            return prev.map((item) => {
                if (item.id === collapsedItem.id) {
                    return {
                        id: item.id,
                        collapsed: !item.collapsed,
                    };
                } else {
                    return item;
                }
            });
        });
    };

    return (
        <div
            className={`cost-and-payment ${costAndPaymentsCollapsed ? '' : 'cost-and-payment-full'}`}
            style={{ height: costAndPaymentsCollapsed ? `${200 + contract.rentObjects.length * 150}px` : 'fit-content' }}>
            <div className="contract-setup-header">
                <h5>{t('contract.preview.costAndPayment.header')}</h5>
            </div>
            <h6>{t('contract.preview.costAndPayment.rentalObject')}</h6>
            {contract.rentObjects.length > 0 &&
                contract.rentObjects.map((item) => {
                    return (
                        <div className="rent-object-card">
                            <p className="main-text font-weight-bold">{`${helper.getEnumValue(rentObjectRentalTypeOptions, item?.rentObjectRentalType)} - ${helper.numberWithSpaces(
                                item.amount
                            )}${helper.getEnumValue(unitTypeOptions, item.rentObjectUnit)}`}</p>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.type')}</p>
                                <p className="main-text">{item.rentObjectType ? helper.getEnumValue(rentObjectTypeOptions, item.rentObjectType) : notApplicable}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.calculateVac')}</p>
                                {item?.hasVatCalculation ? t('contract.preview.costAndPayment.yes') : t('contract.preview.costAndPayment.no')}
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.rent')}</p>
                                <p className="main-text">{`${
                                    item.pricePerYearPerUnit ? helper.numberWithSpaces(item.pricePerYearPerUnit) : notApplicable
                                } NOK anual per ${helper.getEnumValue(unitTypeOptions, item.rentObjectUnit)}`}</p>
                            </div>
                            {!costAndPaymentsCollapsed && (
                                <>
                                    {item?.turnover?.minimunRentYearlyId && (
                                        <>
                                            <div className="text-row mt-3">
                                                <p className="title-text">{t('contract.preview.costAndPayment.minimumAnnualRent')}</p>
                                                <p className="main-text">{t('contract.preview.costAndPayment.yes')}</p>
                                            </div>
                                            {item?.turnover?.minimunRentYearly?.annuallyPerUnit && (
                                                <div className="text-row">
                                                    <p className="title-text">{t('contract.preview.costAndPayment.minimumAmount')}</p>
                                                    <p className="main-text">{`${item?.turnover?.minimunRentYearly?.annuallyPerUnit} NOK ${helper.getEnumValue(
                                                        unitTypeOptions,
                                                        2
                                                    )}`}</p>
                                                </div>
                                            )}
                                            {item?.turnover?.minimunRentYearly?.totalAnnualAmount && (
                                                <div className="text-row">
                                                    <p className="title-text">{t('contract.preview.costAndPayment.totalAnnual')}</p>
                                                    <p className="main-text">{`${item?.turnover?.minimunRentYearly?.totalAnnualAmount} NOK`}</p>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {item?.turnover?.ascendingMinimumRents?.length > 0 && (
                                        <>
                                            <div className="text-row mt-3">
                                                <p className="title-text">{t('contract.preview.costAndPayment.ascendingMinimumRent')}</p>
                                                <p className="main-text">{t('contract.preview.costAndPayment.yes')}</p>
                                            </div>
                                            {item?.turnover?.ascendingMinimumRents?.map((rent, index) => {
                                                return (
                                                    <>
                                                        <div className="text-row">
                                                            <p className="title-text">{`${t('contract.preview.costAndPayment.minimumRent')} ${index + 1}`}</p>
                                                            <p className="main-text">{`${rent?.annuallyPerUnit} NOK`}</p>
                                                        </div>
                                                        <div className="text-row">
                                                            <p className="title-text">{`${t('contract.preview.costAndPayment.intervalPeriod')}`}</p>
                                                            <p className="main-text">{`${moment(rent.intervalFrom).format('DD.MM.YYYY')} - ${moment(rent.intervalTo).format(
                                                                'DD.MM.YYYY'
                                                            )}`}</p>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </>
                                    )}
                                    {item?.turnover?.turnoverRates?.length > 0 && <h5 className="mt-4">{t('contract.preview.costAndPayment.turnoverRates')}</h5>}
                                    {item?.turnover?.turnoverRates?.length > 0 && (
                                        <Tabs>
                                            {item?.turnover?.turnoverRates?.map((rate, index) => {
                                                return (
                                                    <Tabs.TabPane tab={rate?.incomeSource}>
                                                        <div className="text-row mt-4">
                                                            <p className="title-text">{t('contract.preview.costAndPayment.multipleIntervalTurnoverRates')}</p>
                                                            <p className="main-text">
                                                                {rate?.multipleInterval ? t('contract.preview.costAndPayment.yes') : t('contract.preview.costAndPayment.no')}
                                                            </p>
                                                        </div>
                                                        {rate?.multipleInterval &&
                                                            rate?.turnoverIntervals.map((iterval, index) => {
                                                                return (
                                                                    <div className="text-row">
                                                                        <p className="title-text">{`${t('contract.preview.costAndPayment.between')} ${
                                                                            index == 0 ? '0' : rate?.turnoverIntervals[index - 1]?.interval
                                                                        } - ${iterval?.interval} NOK`}</p>
                                                                        <p className="main-text">{`${iterval?.rate} %`}</p>
                                                                    </div>
                                                                );
                                                            })}
                                                        <div className="text-row">
                                                            <p className="title-text">{`${rate?.multipleInterval ? t('contract.preview.costAndPayment.over') : ''} ${
                                                                rate?.multipleInterval ? rate?.turnoverIntervals[rate?.turnoverIntervals?.length - 1]?.interval : ''
                                                            } ${t('contract.preview.costAndPayment.mainTurnoverRate')}`}</p>
                                                            <p className="main-text">{`${rate?.rate} %`}</p>
                                                        </div>
                                                    </Tabs.TabPane>
                                                );
                                            })}
                                        </Tabs>
                                    )}
                                    {item?.turnover?.maximunRentYearlyId && (
                                        <>
                                            <h5 className="mt-4">{t('contract.preview.costAndPayment.maxThreshold')}</h5>
                                            <div className="text-row mt-3">
                                                <p className="title-text">{t('contract.preview.costAndPayment.maximumTurnoverRent')}</p>
                                                <p className="main-text">{t('contract.preview.costAndPayment.yes')}</p>
                                            </div>
                                            {item?.turnover?.maximunRentYearly?.annuallyPerUnit && (
                                                <div className="text-row">
                                                    <p className="title-text">{t('contract.preview.costAndPayment.maxTurnoverRentAmount')}</p>
                                                    <p className="main-text">{`${item?.turnover?.maximunRentYearly?.annuallyPerUnit} NOK ${helper.getEnumValue(
                                                        unitTypeOptions,
                                                        2
                                                    )}`}</p>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {!!item?.turnover?.turnoverInvoiceId && (
                                        <>
                                            <h5 className="mt-4">{t('contract.preview.costAndPayment.invoicing')}</h5>
                                            <p className="title-text mt-3">{t('contract.preview.costAndPayment.rentShellBeInvoiced')}</p>
                                        </>
                                    )}
                                    {item?.turnover?.auditStatemantId && (
                                        <>
                                            <h5 className="mt-4">{t('contract.preview.costAndPayment.auditedTurnoverStatement')}</h5>
                                            <div className="text-row mt-3">
                                                <p className="title-text">{t('contract.preview.costAndPayment.auditedTurnoverStatement')}</p>
                                                <p className="main-text">{t('contract.preview.costAndPayment.yes')}</p>
                                            </div>
                                            <div className="text-row">
                                                <p className="title-text">{t('contract.preview.costAndPayment.recurrencePatternTurnoverStatement')}</p>
                                                <p className="main-text">{`${helper.getEnumValue(
                                                    turnoverRecurrencePeriodTypesOptions,
                                                    item?.turnover?.auditStatemant?.turnoverRecurrencePeriodTypeId
                                                )}`}</p>
                                            </div>
                                            <div className="text-row">
                                                <p className="title-text">{t('contract.preview.costAndPayment.sendStatementOn')}</p>
                                                <p className="main-text">{`${moment()
                                                    .month(item?.turnover?.auditStatemant?.month - 1)
                                                    .format('MMMM')} ${item?.turnover?.auditStatemant?.onDay}, ${helper.getEnumValue(
                                                    turnoverRecurrencePeriodTypesOptions,
                                                    item?.turnover?.auditStatemant?.turnoverRecurrencePeriodTypeId
                                                )}`}</p>
                                            </div>
                                            <div className="text-row">
                                                <p className="title-text">{t('contract.preview.costAndPayment.turnoverTenant')}</p>
                                                <p className="main-text">{item?.turnover?.auditStatemant?.tenantEmail}</p>
                                            </div>
                                            <div className="text-row">
                                                <p className="title-text">{t('contract.preview.costAndPayment.auditEmail')}</p>
                                                <p className="main-text">{item?.turnover?.auditStatemant?.auditEmail}</p>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    );
                })}
            <Divider style={{ visibility: costAndPaymentsCollapsed ? 'hidden' : '' }} />
            <h6 style={{ visibility: costAndPaymentsCollapsed ? 'hidden' : '' }}>{t('contract.preview.costAndPayment.rentExemptions')}</h6>
            {contract?.status !== contractStatus.draft && <RentPaymentPlanTable style={{ visibility: costAndPaymentsCollapsed ? 'hidden' : '' }} disableEditing={true} />}
            <Divider />
            <h6>{t('contract.preview.costAndPayment.paymentTerms')}</h6>
            <p className="main-text font-weigth-bold">{t('contract.preview.costAndPayment.generalTerms')}</p>
            <div className="text-row mt-4">
                <p className="title-text">{t('contract.preview.costAndPayment.rentInvoicedFrom')}</p>
                <p className="main-text">{contract.rentInvoicedFromDate ? moment(contract.rentInvoicedFromDate).format('DD.MM.YYYY') : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.costAndPayment.invoiceIssueDate')}</p>
                <p className="main-text">{contract.firstInvoiceDueDate ? moment(contract.firstInvoiceDueDate).format('DD.MM.YYYY') : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.costAndPayment.type')}</p>
                <p className="main-text">{contract.invoiceIssueDateOption ? helper.getEnumValue(invoiceIssueDateOptions, contract.invoiceIssueDateOption) : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.costAndPayment.paymentPlan')}</p>
                <p className="main-text">{contract.paymentPlan ? helper.getEnumValue(paymentPlanOptions, contract.paymentPlan) : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.paymentTerms.paymentReference')}</p>
                <p className="main-text">{contract.paymentReference ? contract.paymentReference : notApplicable}</p>
            </div>
            <p className="main-text font-weigth-bold mt-4">{t('contract.preview.costAndPayment.paymentTermsForIndividual')}</p>
            {contract.rentObjects.length > 0 &&
                contract.rentObjects.map((item) => {
                    return (
                        <ContentBlock
                            collapsed={addedRentalPaymentPlanCollapse.find((a) => a.id == item.id).collapsed}
                            onCollapse={() => collapseAddedRentPayment(item.id)}
                            actions={[]}
                            name={`${item.name ? item.name : helper.getEnumValue(rentObjectRentalTypeOptions, item?.rentObjectRentalType)} - ${helper.numberWithSpaces(
                                item.amount
                            )}${helper.getEnumValue(unitTypeOptions, item.rentObjectUnit)}`}>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.rentInvoicedFrom')}</p>
                                <p className="main-text">{item.rentInvoicedFromDate ? moment(item.rentInvoicedFromDate).format('DD.MM.YYYY') : notApplicable}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.invoiceIssueDate')}</p>
                                <p className="main-text">{item.firstInvoiceDueDate ? moment(item.firstInvoiceDueDate).format('DD.MM.YYYY') : notApplicable}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.type')}</p>
                                <p className="main-text">
                                    {item.invoiceIssueDateOption ? helper.getEnumValue(invoiceIssueDateOptions, item.invoiceIssueDateOption) : notApplicable}
                                </p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.paymentPlan')}</p>
                                <p className="main-text">{item.paymentPlan ? helper.getEnumValue(paymentPlanOptions, item.paymentPlan) : notApplicable}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.paymentTerms.paymentReference')}</p>
                                <p className="main-text">{item.paymentReference ? item.paymentReference : notApplicable}</p>
                            </div>
                        </ContentBlock>
                    );
                })}
            <Divider />
            <h6>{t('contract.preview.costAndPayment.stipulatedCommonCost')}</h6>
            <p className="main-text font-weigth-bold mt-3">{t('contract.preview.costAndPayment.commonCostIndividual')}</p>
            {contract.rentObjects.length > 0 &&
                contract.rentObjects.map((item) => {
                    const selectedMeasurement = !!item.commonCostMeasurements && item.commonCostMeasurements.find((item) => item.isSelected);
                    return (
                        <ContentBlock
                            collapsed={addedRentalCommonCostCollapse.find((a) => a.id == item.id).collapsed}
                            onCollapse={() => collapseAddedRentCommonCost(item.id)}
                            actions={[]}
                            name={`${item.name ? item.name : helper.getEnumValue(rentObjectRentalTypeOptions, item?.rentObjectRentalType)} - ${helper.numberWithSpaces(
                                item.amount
                            )}${helper.getEnumValue(unitTypeOptions, item.rentObjectUnit)}`}>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.isTenantCover')}</p>
                                <p className="main-text">{item.isTenantCoverPropertyTax ? t('common.yes') : t('common.no')}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.noCommonCost')}</p>
                                <p className="main-text">{item.noCommonCost ? t('common.yes') : t('common.no')}</p>
                            </div>
                            {!item.noCommonCost && !!selectedMeasurement && (
                                <div className="text-row">
                                    <p className="title-text">{t('contract.preview.costAndPayment.commonCostPerYear')}</p>
                                    <p className="main-text">{`${selectedMeasurement.value} NOK ${measurements
                                        .find(
                                            (item) =>
                                                item.value ==
                                                JSON.stringify({
                                                    measurementTypeId: selectedMeasurement.measurementTypeId,
                                                    measurementPeriodTypeId: selectedMeasurement.measurementPeriodTypeId,
                                                })
                                        )
                                        .name()}`}</p>
                                </div>
                            )}
                        </ContentBlock>
                    );
                })}
            <Divider />
            <h6>{t('contract.preview.costAndPayment.rentCpi')}</h6>
            <p className="main-text font-weigth-bold mt-3">{t('contract.preview.costAndPayment.generalRentAdjustment')}</p>
            <div className="text-row mt-4">
                <p className="title-text">{t('contract.preview.costAndPayment.annualCpiReminder')}</p>
                <p className="main-text">{contract.yearlyTenantReminderOnCpiAdjustment ? t('common.yes') : t('common.no')}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.costAndPayment.firstTimeCpi')}</p>
                <p className="main-text">{contract.firstTimeCpiAdjustmentDate ? moment(contract.firstTimeCpiAdjustmentDate).format('DD.MM.YYYY') : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.costAndPayment.annualCpiAdjustment')}</p>
                <p className="main-text">{contract.yearlyCpiAdjustmentDate ? moment(contract.yearlyCpiAdjustmentDate).format('MMM DD') : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.costAndPayment.cpiIndexType')}</p>
                <p className="main-text">{contract?.cpiIndexType ? helper.getEnumValue(cpiIndexTypeOptions, contract?.cpiIndexType) : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.costAndPayment.originalContractDate')}</p>
                <p className="main-text">{contract.originalBaseIndexDate ? moment(contract.originalBaseIndexDate).format('MMMM YYYY') : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.costAndPayment.yearliCpi')}</p>
                <p className="main-text">{contract.yearlyCpiIndexDate ? moment(contract.yearlyCpiIndexDate).format('MMMM') : notApplicable}</p>
            </div>
            <p className="main-text font-weigth-bold mt-4">{t('contract.preview.costAndPayment.cpiOnHandover')}</p>
            <div className="text-row mt-3">
                <p className="title-text">{t('contract.preview.costAndPayment.cpiOnHandoverLeaseStart')}</p>
                <p className="main-text">{contract.cpiAdjustmentOnHandover ? t('common.yes') : t('common.no')}</p>
            </div>
            <p className="main-text font-weigth-bold mt-4">{t('contract.preview.costAndPayment.cpiSettings')}</p>
            <div className="text-row mt-3">
                <p className="title-text">{t('contract.preview.costAndPayment.cpiRate')}</p>
                <p className="main-text">{contract.cpiRate ? `${contract.cpiRate}%` : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.costAndPayment.cpiSource')}</p>
                <p className="main-text">{contract.cpiSource ? contract.cpiSource : notApplicable}</p>
            </div>
            <p className="main-text font-weigth-bold mt-3">{t('contract.preview.costAndPayment.rentAdjustmentForIndividual')}</p>
            {contract.rentObjects.length > 0 &&
                contract.rentObjects.map((item, index) => {
                    return (
                        <ContentBlock
                            collapsed={rentAdjustmentCollapse.find((a) => a.id == item.id).collapsed}
                            onCollapse={() => collapseRentAdjustment(item.id)}
                            style={{ marginBottom: index === contract.rentObjects.length - 1 ? '40px' : '15px' }}
                            actions={[]}
                            name={`${item.name ? item.name : helper.getEnumValue(rentObjectRentalTypeOptions, item?.rentObjectRentalType)} - ${helper.numberWithSpaces(
                                item.amount
                            )}${helper.getEnumValue(unitTypeOptions, item.rentObjectUnit)}`}>
                            <div className="text-row mt-4">
                                <p className="title-text">{t('contract.preview.costAndPayment.annualCpiReminder')}</p>
                                <p className="main-text">{item.yearlyTenantReminderOnCpiAdjustment ? t('common.yes') : t('common.no')}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.firstTimeCpi')}</p>
                                <p className="main-text">{item.firstTimeCpiAdjustmentDate ? moment(item.firstTimeCpiAdjustmentDate).format('DD.MM.YYYY') : notApplicable}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.annualCpiAdjustment')}</p>
                                <p className="main-text">{item.yearlyCpiAdjustmentDate ? moment(item.yearlyCpiAdjustmentDate).format('MMM DD') : notApplicable}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.cpiIndexType')}</p>
                                <p className="main-text">{item?.cpiIndexType ? helper.getEnumValue(cpiIndexTypeOptions, item?.cpiIndexType) : notApplicable}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.originalContractDate')}</p>
                                <p className="main-text">{item.originalBaseIndexDate ? moment(item.originalBaseIndexDate).format('MMMM YYYY') : notApplicable}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.yearliCpi')}</p>
                                <p className="main-text">{item.yearlyCpiIndexDate ? moment(item.yearlyCpiIndexDate).format('MMMM') : notApplicable}</p>
                            </div>
                            <p className="main-text font-weigth-bold mt-4">{t('contract.preview.costAndPayment.cpiOnHandover')}</p>
                            <div className="text-row mt-3">
                                <p className="title-text">{t('contract.preview.costAndPayment.cpiOnHandoverLeaseStart')}</p>
                                <p className="main-text">{item.cpiAdjustmentOnHandover ? t('common.yes') : t('common.no')}</p>
                            </div>
                            <p className="main-text font-weigth-bold mt-4">{t('contract.preview.costAndPayment.cpiSettings')}</p>
                            <div className="text-row mt-3">
                                <p className="title-text">{t('contract.preview.costAndPayment.cpiRate')}</p>
                                <p className="main-text">{item.cpiRate ? `${item.cpiRate}%` : notApplicable}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.cpiSource')}</p>
                                <p className="main-text">{item.cpiSource ? item.cpiSource : notApplicable}</p>
                            </div>
                        </ContentBlock>
                    );
                })}
            <Button type="primary" className="contract-expand-btn" onClick={() => setCostAndPaymentsCollapsed((prev) => !prev)}>
                {costAndPaymentsCollapsed ? t('contract.preview.costAndPayment.expandButton') : t('contract.preview.costAndPayment.collapseButton')}
            </Button>
        </div>
    );
};

export default CostAndPayment;
