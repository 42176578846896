import React from 'react';
import { connect } from 'react-redux';

const mapDispatch = (dispatch) => {
    return {
        clear: () => {
            dispatch({
                type: 'CLEAR',
            });
        },
    };
};

const clear = (ComposedComponent) => {
    class hoc extends React.Component {
        constructor(props) {
            super(props);
            props.clear();
        }
        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    return connect(() => ({}), mapDispatch)(hoc);
};

export default clear;
