import React from 'react';
import PortfolioTotal from './portfolio-total';
import RentPerPropertyChart from './rent-per-property-chart';
import PropertiesTable from './properties-table';

const AnalysisTab = (props) => {
    return (
        <div className="dashboard portfolio-dashboard">
            <div className="portfolio-totals visible-md">
                <PortfolioTotal />
            </div>
            <div className="rent-per-property-chart-area">
                <div className="chart-area">
                    <RentPerPropertyChart />
                </div>
                <div className="table-flex">
                    <PropertiesTable />
                </div>
            </div>
        </div>
    );
};

export default AnalysisTab;
