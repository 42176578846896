import { DatePicker, Form } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CustomContractDatePicker from '../../../../components/custom-contract-date-picker';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import { durationFormater } from '../../../../helpers/durationFormater';
import { setValueContract } from '../../../../reducers/contractReducer';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';

const ContractDatesComponent = (props) => {
    const { t } = useTranslation();

    const leaseDurationOptions =
        props.rentObject && props.rentObject.leaseDurationOptions && props.rentObject.leaseDurationOptions.length > 0
            ? props.rentObject.leaseDurationOptions
            : props.openContractLeaseDurationOptionModalWindow && props.contract && props.hasLeaseDurationOptions
            ? props.leaseDurationOptions
            : null;

    const [leaseStartDate, setLeaseStartDate] = useState(
        props.rentObject ? moment(props.rentObject.leaseStartDate) : props.contract && props.contract.leaseStartDate ? moment(props.contract.leaseStartDate) : moment()
    );

    const [leaseEndDate, setLeaseEndDate] = useState(
        props.rentObject ? moment(props.rentObject.leaseEndDate) : props.contract && props.contract.leaseEndDate ? moment(props.contract.leaseEndDate) : moment().add(1, 'years')
    );

    const onChangeDate = (dates) => {
        setLeaseStartDate(dates[0]);
        setLeaseEndDate(dates[1]);

        props.setValueContractCall('preview.leaseStartDate', dates[0]);
        props.setValueContractCall('preview.leaseEndDate', dates[1]);
        if (props.onSelectLeaseStartDate && props.onSelectLeaseEndDate) {
            props.onSelectLeaseStartDate(dates[0]);
            props.onSelectLeaseEndDate(dates[1]);
        }
    };

    const onEdit = (id) => {
        const leaseDurationOption = leaseDurationOptions.find((x) => x.uId === id);
        if (leaseDurationOption) {
            props.setValueContractCall('leaseDurationOptionEditModel', leaseDurationOption);
            openLeaseDurationOptionOnEdit();
        }
    };

    const onDelete = (id) => {
        if (props.openAddedRentObjectLeaseDurationOptionOnDelete) {
            props.openAddedRentObjectLeaseDurationOptionOnDelete(id);
        } else if (props.openRentObjectLeaseDurationOptionOnDelete) {
            props.openRentObjectLeaseDurationOptionOnDelete(id);
        } else if (props.openContractLeaseDurationOptionOnDelete) {
            props.openContractLeaseDurationOptionOnDelete(id);
        }
    };

    const openLeaseDurationOptionOnEdit = () => {
        if (props.openAddedRentObjectLeaseDurationOptionModalWindow) {
            props.openAddedRentObjectLeaseDurationOptionModalWindow();
        } else if (props.openRentObjectLeaseDurationOptionModalWindow) {
            props.openRentObjectLeaseDurationOptionModalWindow();
        } else if (props.openContractLeaseDurationOptionModalWindow) {
            props.openContractLeaseDurationOptionModalWindow();
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col lg={12} xl={5}>
                    <p className="font-weight-bold">{t('contract.durationAndRentalObjects.contractDates.leaseStartDate')}</p>
                    <Form.Item
                        name="leaseStartDate"
                        className="mb-3"
                        initialValue={leaseStartDate}
                        rules={[
                            {
                                required: true,
                                message: t('contract.durationAndRentalObjects.contractDates.leaseStartDate') + t('common.isRequired'),
                            },
                        ]}>
                        <DatePicker
                            dropdownClassName={'new-design-date-picker'}
                            suffixIcon={<Image src={calendarIcon} />}
                            format={primaryDateFormat}
                            onChange={(date) => onChangeDate([date, leaseEndDate])}
                            allowClear={false}
                            disabledDate={current => current && current > leaseEndDate}
                        />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={5}>
                    <p className="font-weight-bold">{t('contract.durationAndRentalObjects.contractDates.leaseEndDate')}</p>
                    <Form.Item
                        name="leaseEndDate"
                        className="mb-3"
                        initialValue={leaseEndDate}
                        rules={[
                            {
                                required: true,
                                message: t('contract.durationAndRentalObjects.contractDates.leaseEndDate') + t('common.isRequired'),
                            },
                        ]}>
                        <DatePicker
                            dropdownClassName={'new-design-date-picker'}
                            suffixIcon={<Image src={calendarIcon} />}
                            format={primaryDateFormat}
                            onChange={(date) => onChangeDate([leaseStartDate, date])}
                            allowClear={false}
                            disabledDate={current => current && current < leaseStartDate}
                        />
                    </Form.Item>
                </Col>
                {leaseStartDate && leaseEndDate ? (
                    <Col lg={12} xl={6}>
                        <div className="mb-3">
                            <p className="font-weight-bold">{t('contract.durationAndRentalObjects.contractDates.duration')}</p>
                            <p className="duration">{durationFormater(leaseStartDate, leaseEndDate)}</p>
                        </div>
                    </Col>
                ) : null}
            </Row>
            {/*leaseDurationOptions &&
                leaseDurationOptions.length > 0 &&
                leaseDurationOptions.map((option) => (
                    <OptionCard
                        key={option.id}
                        className={props.rentObject ? 'rentObjectLeaseDurationOption' : null}
                        rentObject={props.rentObject}
                        option={option}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                ))*/}
        </React.Fragment>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        leaseDurationOptions: get(contract, 'leaseDurationOptions'),
        hasLeaseDurationOptions: get(contract, 'leaseDurationOptions.length') > 0,
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
    };
};

const ContractDates = connect(mapState, mapDispatch)(ContractDatesComponent);
export default ContractDates;
