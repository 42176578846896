import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signUp } from '../../reducers/signUpReducer';
import { requiredCommonInput, validateEmail } from '../../common/validators';
import AccountVerification from './account-verification';
import LoginSignUpLayout from '../../components/layouts/login-layout';
import ActionButton from '../../components/action-button';
import CommonInput from '../../components/common-input';
import get from 'lodash-es/get';
import queryString from 'query-string';
import clear from '../../common/clear';
import localStorageService from '../../services/localStorageService';
import objectHelper from '../../helpers/trim-object';
import routingService from '../../services/routingService';

const SignUpComponent = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [email, setEmail] = useState();
    const [isInvitedUser, setIsInvitedUser] = useState(false);

    const onSubmit = () => {
        form.validateFields()
            .then(() => {
                const user = objectHelper.trimObjectProperties(form.getFieldsValue());
                props.signUpCall(user).then((response) => {
                    if (response) {
                        setEmail(response.email);
                    }
                });
            })
            .catch((_) => {});
    };

    useEffect(() => {
        localStorageService.setUser(null);
        var queryParams = queryString.parse(window.location.search);
        if (queryParams.email) {
            form.setFieldsValue({ email: queryParams.email });
            setIsInvitedUser(true);
        }
        if (queryParams.firstName) {
            form.setFieldsValue({ firstName: queryParams.firstName });
        }
        if (queryParams.lastName) {
            form.setFieldsValue({ lastName: queryParams.lastName });
        }
    }, []);

    if (!props.registrationLoading && props.success) {
        return <AccountVerification email={email} />;
    }

    return (
        <LoginSignUpLayout header={t('loginSignUpPage.signupTitle')}>
            <div className="d-flex flex-direction-row mb-3">
                {/* ToDo: Implement login via social networks first */}
                {/* <p className='text-secondary'>
                    <span>{t('loginSignUpPage.wantToUseSocialSignUp')} </span>
                    <Link to="/">{t('loginSignUpPage.backToSocialSignUp')}</Link>
                </p> */}
            </div>
            <Form form={form} autoComplete="off" name="basic" onFinish={onSubmit}>
                <Form.Item name="firstName" rules={[requiredCommonInput(t('loginSignUpPage.form.firstName.title') + t('common.isRequired'))]}>
                    <CommonInput
                        submitOnEnter={true}
                        maxLength={100}
                        placeholder={t('loginSignUpPage.form.firstName.placeholder')}
                        title={t('loginSignUpPage.form.firstName.title')}
                    />
                </Form.Item>
                <Form.Item name="lastName" rules={[requiredCommonInput(t('loginSignUpPage.form.lastName.title') + t('common.isRequired'))]}>
                    <CommonInput
                        submitOnEnter={true}
                        maxLength={100}
                        placeholder={t('loginSignUpPage.form.lastName.placeholder')}
                        title={t('loginSignUpPage.form.lastName.title')}
                    />
                </Form.Item>
                <Form.Item name="email" rules={[!isInvitedUser && requiredCommonInput(t('loginSignUpPage.form.email.title') + t('common.isRequired')), validateEmail]}>
                    <CommonInput
                        type="email"
                        submitOnEnter={true}
                        maxLength={100}
                        disabled={isInvitedUser}
                        placeholder={t('loginSignUpPage.form.email.placeholder')}
                        title={t('loginSignUpPage.form.email.title')}
                    />
                </Form.Item>
                <Form.Item
                    name="policy"
                    valuePropName="checked"
                    rules={[
                        {
                            required: true,
                            type: 'boolean',
                            transform: (value) => value || undefined,
                            message: t('loginSignUpPage.form.privacyPolicy.required'),
                        },
                    ]}>
                    <Checkbox>
                        <span>{t('loginSignUpPage.form.privacyPolicy.agreeTo')} </span>
                        <Link to={routingService.policyUrl()} target="_blank">
                            {t('loginSignUpPage.form.privacyPolicy.privacyPolicy')}
                        </Link>
                        <span> {t('loginSignUpPage.form.privacyPolicy.and')} </span>
                        <Link to={routingService.termsUrl()} target="_blank">
                            {t('loginSignUpPage.form.privacyPolicy.terms')}
                        </Link>
                    </Checkbox>
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <ActionButton
                            disabled={form.getFieldsError().filter(({ errors }) => errors.length).length || props.loading}
                            text={t('loginSignUpPage.createAccount')}
                            onClick={onSubmit}
                            className="btn-primary btn-block"
                            loading={props.loading}
                        />
                    )}
                </Form.Item>
            </Form>
            <div className="d-flex flex-direction-row">
                <span>{t('loginSignUpPage.alreadyHaveAnAccount')}&nbsp;</span>
                <Link to={routingService.loginUrl()}>{t('loginSignUpPage.loginNow')}</Link>
            </div>
        </LoginSignUpLayout>
    );
};

const mapState = ({ register }) => {
    return {
        loading: get(register, 'registrationLoading'),
        success: get(register, 'success'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        signUpCall(values) {
            return dispatch(signUp(values));
        },
    };
};

const SignUp = clear(connect(mapState, mapDispatch)(SignUpComponent));
export default SignUp;
