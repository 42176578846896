import { Col, Form, Row } from 'antd';
import get from 'lodash-es/get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import toaster from '../../../../common/toaster';
import objectHelper from '../../../../helpers/trim-object';
import { createRentalObjects, setPropertyToRentalObject, setValueContract, updateRentObject } from '../../../../reducers/contractReducer';
import rentObjectService from '../../../../services/rentObjectService';
import ConfirmRemoveCustomDuration from '../contract-dates/remove-custom-duration-modal';
import LeaseContractDuration from '../lease-duration-modal';
import ActionButton from '../../../../components/action-button';
import RentalObjectEditForm, { EXTERIOR_VALUE } from './rent-object-edit-form';
import { useParams } from 'react-router';

const RentalObjectSectionComponent = (props) => {
    const { t } = useTranslation();

    const { portfolioId, propertyId} = useParams();

    const addedRentObjectRef = React.useRef();

    const [loading, setLoading] = useState(false);
    const [addedRentObjectStartDate, setAddedRentObjectStartDate] = useState(props.rentObject.leaseStartDate);
    const [addedRentObjectEndDate, setAddedRentObjectEndDate] = useState(props.rentObject.leaseEndDate);
    const [addedRentObjectHasCustomDuration, setAddedRentObjectHasCustomDuration] = useState(props.rentObject.hasCustomDuration || false);

    useEffect(() => {
        if (props.rentObject) {
            addedRentObjectRef.current.setFieldsValue({
                name: props.rentObject.name,
                rentObjectRentalType: props.rentObject.rentObjectRentalType,
                rentObjectUnit: props.rentObject.rentObjectUnit,
                amount: props.rentObject.amount,
            });
        }
    }, []);

    const onChangeAddedRentObjectHasCustomDuration = (value) => {
        if (!value && addedRentObjectStartDate && addedRentObjectEndDate) {
            props.setValueContractCall('showAddedRentObjectRemoveCustomDurationModal', true);
        } else {
            setAddedRentObjectHasCustomDuration(!addedRentObjectHasCustomDuration);
        }
    };

    const done = (id) => {
        return addedRentObjectRef.current.validateFields().then(() => {
            const assignedFloors = addedRentObjectRef.current.getFieldsValue().assignedFloorIds;

            const rentObject = {
                ...addedRentObjectRef.current.getFieldsValue(),
                isExterior: assignedFloors?.includes(EXTERIOR_VALUE),
                assignedFloorIds: assignedFloors?.filter(item => item != EXTERIOR_VALUE)
            };

            if (id === -1) {
                rentObject.leaseStartDate = addedRentObjectStartDate;
                rentObject.leaseEndDate = addedRentObjectEndDate;
                rentObject.hasCustomDuration = addedRentObjectHasCustomDuration;

                if (!rentObject.name) {
                    rentObject.displayName = rentObjectService.getRentalObjectDisplayName(rentObject);
                }

                setLoading(true);

                const index = props.rentObjects.findIndex((ro) => ro.id === -1 && ro.uId === rentObject.uId);
                props.setValueContractCall('rentObjects', [...props.rentObjects.slice(0, index), ...props.rentObjects.slice(index, props.rentObject.length - 1)]);

                props.createRentalObjectsCall([objectHelper.trimObjectProperties(rentObject)], props.contract.id, propertyId, portfolioId).then((isSuccess) => {
                    if (isSuccess) {
                        setLoading(false);
                    }
                });
            } else {
                rentObject.id = id;
                rentObject.leaseDurationOptions = props.rentObject.leaseDurationOptions;

                if (props.rentObject.hasCustomDuration) {
                    rentObject.leaseStartDate = addedRentObjectStartDate;
                    rentObject.leaseEndDate = addedRentObjectEndDate;
                } else {
                    rentObject.leaseStartDate = null;
                    rentObject.leaseEndDate = null;
                }

                setLoading(true);

                props.updateRentObjectCall(rentObject, props.contract.id, portfolioId, propertyId).then((isSuccess) => {
                    if (isSuccess) {
                        setLoading(false);
                    }
                });
            }
        });
    };

    // Lease Duration Option
    const openAddedRentObjectLeaseDurationOptionOnDelete = (uId) => {
        const newRentObjects = [...props.rentObjects];
        const currectRentObjectId = newRentObjects.findIndex((x) => x.id === props.rentObject.id);
        if (currectRentObjectId > -1) {
            props.setPropertyToRentalObjectCall(
                currectRentObjectId,
                'leaseDurationOptions',
                props.rentObject.leaseDurationOptions.filter((x) => x.uId !== uId)
            );
            toaster.success(t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.onDeleteNotification'), null);
        }
    };

    // Custom Duration
    const onConfirmRemoveCustomDurationAddedRentObject = () => {
        setAddedRentObjectHasCustomDuration(!addedRentObjectHasCustomDuration);

        const rentalObjectIndex = [...props.rentObjects].findIndex((x) => x.uId === props.rentObject.uId);
        if (rentalObjectIndex > -1) {
            props.setPropertyToRentalObjectCall(rentalObjectIndex, 'leaseStartDate', null);
            props.setPropertyToRentalObjectCall(rentalObjectIndex, 'leaseEndDate', null);
            props.setPropertyToRentalObjectCall(rentalObjectIndex, 'leaseDurationOptions', []);
            props.setPropertyToRentalObjectCall(rentalObjectIndex, 'hasCustomDuration', false);
            addedRentObjectRef.current.setFieldsValue({ leaseStartDate: null, leaseEndDate: null });
        }
        onCloseRemoveCustomDurationAddedRentObject();
    };

    const onCloseRemoveCustomDurationAddedRentObject = () => {
        props.setValueContractCall('showAddedRentObjectRemoveCustomDurationModal', false);
    };

    // Show Remove Modal
    const rentObjectOnDeleteShowModal = () => {
        const rentObject = [...props.rentObjects].find((x) => x.uId === props.rentObject.uId);
        if (rentObject) {
            props.setValueContractCall('rentObjectOnDelete', rentObject);
            props.setValueContractCall('showRemoveRentObjectModal', true);
        }
    };

    const handleNewRentObjectOnDelete = () => {
        const rentObjects = [...props.rentObjects].filter((x) => x.uId !== props.rentObject.uId);
        props.setValueContractCall('rentObjects', rentObjects);
    };

    const openAddedRentObjectLeaseDurationOptionModalWindow = () => {
        addedRentObjectRef.current.validateFields([`dates`]).then(() => {
            props.openAddedRentObjectLeaseDurationOptionModal();
        });
    };

    const onClearNameField = (_) => {
        addedRentObjectRef.current.setFieldsValue({ name: null });
    };

    return (
        <React.Fragment>
            <Form name="basic" autoComplete="off" ref={addedRentObjectRef}>
                <RentalObjectEditForm
                    rentalRef={addedRentObjectRef}
                    rentObject={props.rentObject}
                    hasCustomDuration={addedRentObjectHasCustomDuration}
                    onClearNameField={onClearNameField}
                    onSelectLeaseEndDate={setAddedRentObjectEndDate}
                    onSelectLeaseStartDate={setAddedRentObjectStartDate}
                    onChangeHasCustomDuration={onChangeAddedRentObjectHasCustomDuration}
                    openAddedRentObjectLeaseDurationOptionOnDelete={openAddedRentObjectLeaseDurationOptionOnDelete}
                    openAddedRentObjectLeaseDurationOptionModalWindow={openAddedRentObjectLeaseDurationOptionModalWindow}
                />
                <Row justify="end" className="content-block-actions m-0">
                    {props.rentObject?.newlyCreated ? (
                        <Col className="ml-3">
                            <ActionButton
                                className='content-block-action btn-secondary'
                                text={t('common.buttons.cancel')}
                                onClick={() => handleNewRentObjectOnDelete()}
                            />
                        </Col>
                    ) : (
                        <Col className="ml-3">
                            <ActionButton
                                className='content-block-action btn-secondary'
                                loading={loading}
                                disabled={props.rentObject?.newlyCreated || props.rentObjects?.length == 1}
                                text={t('common.buttons.delete')}
                                onClick={() => rentObjectOnDeleteShowModal()}
                            />
                        </Col>
                    )}
                    <Col className="ml-3">
                        <ActionButton
                            className='content-block-action btn-primary'
                            loading={loading}
                            disabled={addedRentObjectRef.current && addedRentObjectRef.current.getFieldsError().filter(({ errors }) => errors.length).length}
                            text={!props.rentObject?.newlyCreated ? t('common.buttons.saveChanges') : t('common.buttons.add')}
                            onClick={() => {
                                done(props.rentObject.id)
                            }}
                        />
                    </Col>
                </Row>
                {props.showAddedRentObjectRemoveCustomDurationModal && (
                    <ConfirmRemoveCustomDuration
                        name={addedRentObjectRef.current.getFieldValue('name') || props.rentObject.displayName}
                        onConfirm={onConfirmRemoveCustomDurationAddedRentObject}
                        onClose={onCloseRemoveCustomDurationAddedRentObject}
                    />
                )}
            </Form>
            {
                props.showAddedRentObjectLeaseDurationModal && (
                    <LeaseContractDuration leaseStartDate={addedRentObjectStartDate} leaseEndDate={addedRentObjectEndDate} rentObject={props.rentObject} />
                )
            }
        </React.Fragment >
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        rentObjects: get(contract, 'rentObjects'),
        showAddedRentObjectLeaseDurationModal: get(contract, 'showAddedRentObjectLeaseDurationModal'),
        showAddedRentObjectRemoveCustomDurationModal: get(contract, 'showAddedRentObjectRemoveCustomDurationModal'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        updateRentObjectCall(data, contractId, portfolioId, propertyId) {
            return dispatch(updateRentObject(data, contractId, portfolioId, propertyId));
        },
        setPropertyToRentalObjectCall(id, key, value) {
            dispatch(setPropertyToRentalObject(id, key, value));
        },
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
        openAddedRentObjectLeaseDurationOptionModal() {
            dispatch(setValueContract('showAddedRentObjectLeaseDurationModal', true));
        },
        createRentalObjectsCall(rentObjects, contractId, propertyId, portfolioId) {
            return dispatch(createRentalObjects(rentObjects, contractId, propertyId, portfolioId));
        },
    };
};

const RentalObjectSection = connect(mapState, mapDispatch)(RentalObjectSectionComponent);
export default RentalObjectSection;
