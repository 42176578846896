import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { Image } from 'react-bootstrap';
import { Form } from 'antd';
import { createBuildings, downloadFile, setValueBuilding, clearBuilding } from '../../../reducers/buildingReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';
import objectHelper from '../../../helpers/trim-object';
import toaster from '../../../common/toaster';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import propertyName from '../../../assets/images/property.svg';
import review from '../../../assets/images/review.svg';
import add from '../../../assets/images/add.svg';
import InformationComponent from '../../../components/information-container';
import CreatedComponent from '../../../components/object/created-object';
import ObjectLayout from '../../../components/layouts/object-layout';
import ActionButton from '../../../components/action-button';
import BuildingReview from './review-step';
import DetailsStep from './details-step';
import routingService from '../../../services/routingService';

const CreateEditBuilding = (props) => {
    const stepNames = {
        details: 'details',
        review: 'review',
    };

    const { t } = useTranslation();
    const { propertyId, portfolioId } = useParams();
    const detailsRef = React.useRef();
    const [buildingForm] = Form.useForm();
    const [currentStepKey, setCurrentStepKey] = useState(stepNames.details);
    const [currentBuildingIdOnEdit, setCurrentBuildingIdOnEdit] = useState();

    useEffect(() => {
        props.getPropertyCall(portfolioId, propertyId);
    }, []);

    const validateBuildingForm = async () => {
        await buildingForm.validateFields();

        const buildingConstructorData = props.buildingConstructorData;

        let level = buildingConstructorData.floors.length;
        if (level > 0) {
            buildingConstructorData.floors = buildingConstructorData.floors.map((obj) => ({ ...obj, level: level-- }));
        }

        if (buildingConstructorData.basementFloors.length > 0) {
            let basementLevel = 1;
            buildingConstructorData.basementFloors = buildingConstructorData.basementFloors.map((obj) => ({ ...obj, level: -basementLevel++ }));
        }

        props.setValueBuildingCall('building.floorsData', buildingConstructorData);
    };

    const onNext = useCallback(async () => {
        const buildingConstructorData = props.buildingConstructorData;

        let level = buildingConstructorData.floors.length;
        if (level > 0) {
            buildingConstructorData.floors = buildingConstructorData.floors.map((obj) => ({ ...obj, level: level-- }));
        }

        if (buildingConstructorData.basementFloors.length > 0) {
            let basementLevel = 1;
            buildingConstructorData.basementFloors = buildingConstructorData.basementFloors.map((obj) => ({ ...obj, level: -basementLevel++ }));
        }

        try {
            await detailsRef.current.validateFields();
        } catch (err) {
            toaster.error(t('building.addBasicDetails'), null);
            throw(err)
        }

        const buildings = [...props.buildings];
        const newBuilding = getBuildingFromForm();
        newBuilding.assignedPlots = props.building.assignedPlots;
        newBuilding.plans = props.building.plans;
        newBuilding.perFloor = props.building.perFloor;
        newBuilding.floorsData = {
            floors: buildingConstructorData.floors,
            basementFloors: buildingConstructorData.basementFloors,
        };
        newBuilding.buildingHasName = props.building.buildingHasName;
        if (!props.building.uId) {
            newBuilding.uId = uuidv4();
            buildings.push(newBuilding);
        } else {
            const index = buildings.findIndex((item) => item.uId === props.building.uId);
            if (index !== -1) {
                newBuilding.uId = props.building.uId;
                buildings[index] = newBuilding;
            }
        }
        setCurrentBuildingIdOnEdit(null);
        props.setValueBuildingCall('building.floorsData', buildingConstructorData);
        props.setValueBuildingCall('buildings', buildings);

        setCurrentStepKey(stepNames.review);
    }, [props.buildings, props.buildingConstructorData, props.building]);

    const onPrevious = () => {
        setCurrentStepKey(stepNames.details);

        const buildings = [...props.buildings];
        if (buildings[0]) {
            const building = buildings[0];
            setCurrentBuildingIdOnEdit(building.uId);
            props.setValueBuildingCall('building', building);
            detailsRef.current.setFieldsValue(building);
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious();
        } else {
            onNext();
        }
    };

    const validateDetailsRef = () => {
        return detailsRef.current.validateFields();
    };

    const getBuildingFromForm = () => {
        const values = detailsRef.current.getFieldsValue();
        const building = objectHelper.trimObjectProperties(values);
        return building;
    };

    const deleteBuilding = (id) => {
        props.setValueBuildingCall(
            'buildings',
            props.buildings.filter((item) => item.uId !== id)
        );
    };

    const goToEdit = (stepKey, objectId) => {
        setCurrentStepKey(stepKey);
        editBuilding(objectId, true);
    };

    const editBuilding = (id, fromReview) => {
        addAnotherBuilding(id, fromReview);
    };

    const setBuilding = (id) => {
        const building = props.buildings.find((item) => item.uId === id);
        if (building) {
            setCurrentBuildingIdOnEdit(building.uId);
            detailsRef.current.setFieldsValue(building);
            props.setValueBuildingCall('building', building);
            props.setValueBuildingCall('buildingFormData', building);
        }
    };

    const addAnotherBuilding = (id, fromReview) => {
        validateDetailsRef()
            .then(() => {
                const buildingConstructorData = props.buildingConstructorData;

                let level = buildingConstructorData.floors.length;
                if (level > 0) {
                    buildingConstructorData.floors = buildingConstructorData.floors.map((obj) => ({ ...obj, level: level-- }));
                }

                if (buildingConstructorData.basementFloors.length > 0) {
                    let basementLevel = 1;
                    buildingConstructorData.basementFloors = buildingConstructorData.basementFloors.map((obj) => ({ ...obj, level: -basementLevel++ }));
                }
                const building = getBuildingFromForm();
                building.floorsData = props.building.floorsData;
                building.plans = props.building.plans;
                building.assignedPlots = props.building.assignedPlots;
                building.buildingHasName = props.building.buildingHasName;
                building.perFloor = props.building.perFloor;
                building.floorsData = {
                    floors: buildingConstructorData.floors,
                    basementFloors: buildingConstructorData.basementFloors,
                };
                const buildings = [...props.buildings];
                if (currentBuildingIdOnEdit) {
                    const index = buildings.findIndex((item) => item.uId === currentBuildingIdOnEdit);
                    const oldBuilding = buildings.find((item) => item.uId === currentBuildingIdOnEdit);
                    if (index !== -1) {
                        building.uId = oldBuilding.uId;
                        buildings[index] = building;
                    }
                } else if (!fromReview || (!currentBuildingIdOnEdit && !id)) {
                    building.uId = uuidv4();
                    buildings.push(building);
                }
                props.setValueBuildingCall('building.floorsData', buildingConstructorData);
                props.setValueBuildingCall('buildings', buildings);
                props.setValueBuildingCall('resetAssignedPlots', true);
                props.clearBuildingCall();
                detailsRef.current.resetFields();
                setCurrentBuildingIdOnEdit(null);

                if (id) {
                    setBuilding(id);
                } else {
                    props.setValueBuildingCall('buildingFormData', {});
                }
            })
            .catch((e) => {
                if ((!e.values.assignedPlots || !(e.values.assignedPlots.length > 0)) && !e.values.address) {
                    setBuilding(id);
                } else {
                    if (id) {
                        toaster.error(t('common.editAddedEntity'), null);
                    }
                }
            });
    };

    const handleDownload = (file) => {
        props.downloadFileCall(file, propertyId, portfolioId);
    };

    const created = (isSuccess) => {
        if (isSuccess) {
            routingService.navigateToBuildings(portfolioId, propertyId);
            props.showBuildingCreationResult();
            props.clearBuildingCall();
        }
    };

    const onSaveAndExit = () => {
        if (propertyId) {
            const buildings = [...props.buildings].map((building) => {
                building.propertyId = +propertyId;
                building.uploadedFileIds = building.plans ? building.plans.map((x) => x.id) : [];
                const floors = building.floorsData.floors || [];
                const basementFloors = building.floorsData.basementFloors || [];
                building.floors = floors.concat(basementFloors);
                building.levels = !building.perFloor ? (building.levels ? +building.levels : null) : building.floors.length;
                building.buildYear = building.buildYear ? +building.buildYear : null;
                building.commonArea = !building.perFloor ? (building.commonArea ? +building.commonArea : null) : 0;
                building.grossArea = !building.perFloor ? (building.grossArea ? +building.grossArea : null) : 0;
                building.nonLettable = !building.perFloor ? building.nonLettable || 0 : 0;
                building.lettable = building.grossArea - building.nonLettable;
                return building;
            });
            props.createBuildingsCall(buildings, propertyId, portfolioId).then((isSuccess) => created(isSuccess));
        }
    };

    const steps = [
        {
            key: stepNames.details,
            header: t('building.details.header', { propertyName: props.propertyName }),
            stepTitle: t('building.details.stepTitle'),
            logo: propertyName,
            canShowAsCompleted: true,
            content: (
                <div className="page-container">
                    {props.hasBuildings &&
                        props.buildings.map((building) =>
                            props.building.uId === building.uId ? null : (
                                <CreatedComponent
                                    key={building.uId}
                                    id={building.uId}
                                    title={building.name || building.address}
                                    editObject={editBuilding}
                                    deleteObject={deleteBuilding}
                                />
                            )
                        )}
                    {currentStepKey === stepNames.details && <DetailsStep handleDownload={handleDownload} detailsRef={detailsRef} buildingForm={buildingForm} />}
                    {currentStepKey === stepNames.details && <ActionButton icon={<Image src={add} />} onClick={addAnotherBuilding} className="btn-secondary" text={t('building.btnAdd')} />}
                </div>
            ),
        },
        {
            key: stepNames.review,
            header: t('building.review.header'),
            stepTitle: t('building.review.stepTitle'),
            logo: review,
            content: <BuildingReview handleDownload={handleDownload} goToStep={goToEdit} />,
        },
    ];

    return (
        <Form name="basic" autoComplete="off" className="h-100" ref={detailsRef}>
            <ObjectLayout
                bottomRightSideBar={
                    <React.Fragment>
                        {currentStepKey === stepNames.details && <InformationComponent title={t('building.information.title')} text={t('building.information.text')} />}
                    </React.Fragment>
                }
                steps={steps}
                timelineSteps={steps}
                loading={props.buildingLoading || props.propertyLoading}
                exitUrl={routingService.buildingsUrl(portfolioId, propertyId)}
                currentStepKey={currentStepKey}
                onNext={onNext}
                onPrevious={onPrevious}
                onSwitchStep={onSwitchStep}
                onSaveAndExit={onSaveAndExit}
            />
        </Form>
    );
};

const mapState = ({ building, property }) => {
    return {
        building: get(building, 'building'),
        buildings: get(building, 'buildings'),
        hasBuildings: get(building, 'buildings.length') > 0,
        buildingLoading: get(building, 'buildingLoading'),
        propertyName: get(property, 'property.propertyName'),
        propertyLoading: get(property, 'propertyLoading'),
        buildingConstructorData: get(building, 'buildingConstructorData'),
        floors: get(building, 'buildingConstructorData.floors'),
        basementFloors: get(building, 'buildingConstructorData.basementFloors'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        createBuildingsCall(values, propertyId, portfolioId) {
            return dispatch(createBuildings(values, propertyId, portfolioId));
        },
        downloadFileCall(file, propertyId, portfolioId) {
            return dispatch(downloadFile(file, propertyId, portfolioId));
        },
        setValueBuildingCall(key, value) {
            dispatch(setValueBuilding(key, value));
        },
        showBuildingCreationResult() {
            dispatch(setValueBuilding('showCreationResultModal', true));
        },
        clearBuildingCall() {
            dispatch(clearBuilding());
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
    };
};

const CreateBuildings = clear(connect(mapState, mapDispatch)(CreateEditBuilding));
export default CreateBuildings;
