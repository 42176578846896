import axios from 'axios';

const propertyService = {
    get: (propertyId, portfolioId) => {
        return axios({
            method: 'post',
            url: `/api/contract/properties/get-by-id?propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    getPropertiesByPortfolioId: (portfolioId, propertyId, request) => {
        return axios({
            method: 'post',
            url: `/api/contract/properties/get-by-portfolio?portfolioId=${portfolioId}&propertyId=${propertyId}`,
            data: {
                status: request,
            },
        });
    },

    create: (data) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/properties?portfolioId=${data.portfolioId}`,
        });
    },

    update: (data) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/properties?propertyId=${data.id}&portfolioId=${data.portfolioId}`,
        });
    },

    delete: (propertyId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/properties?propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    getDocuments: (propertyId, portfolioId) => {
        return axios({
            method: 'get',
            url: `/api/contract/properties/documents?propertyId=${propertyId}&portfolioId=${portfolioId}`,
        });
    },

    getKpis: (portfolioId, propertyId) => {
        return axios({
            method: 'get',
            url: `/api/contract/v2.0/dashboard/property-kpis?portfolioId=${portfolioId}&propertyId=${propertyId}`,
        });
    },
};

export default propertyService;
