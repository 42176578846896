import roles from '../common/roles';
import localStorageService from './localStorageService';

export const REFRESH_TOKEN_ENDPOINT = '/api/access/refreshToken';

const authService = {
    logout() {
        return localStorageService.setIdentityData(null);
    },

    setIdentity(identity) {
        localStorageService.setIdentityData(identity);
    },

    getIdentity() {
        return localStorageService.getIdentityData();
    },

    updateTokens(tokens) {
        let identity = {
            ...this.getIdentity(),
            token: tokens,
        };

        this.setIdentity(identity);
    },

    isLoggedIn() {
        const identity = this.getIdentity();

        return identity?.token?.accessToken && identity?.token?.refreshToken;
    },

    isInRoles: (roles) => {
        const user = localStorageService.getUser();
        return (
            user &&
            user.roles &&
            user.roles.filter((x) => {
                return roles.includes(x);
            }).length > 0
        );
    },

    isAdmin() {
        return this.isInRoles([roles.ADMIN, roles.SUDO_ADMIN]);
    },

    isSudoAdmin() {
        return this.isInRoles([roles.SUDO_ADMIN]);
    }
};

export default authService;
