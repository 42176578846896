import { Switch, Form, Row, Col, Radio, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { minimumRentTypes, minimumRentTypeOptions } from '../../../../common/contract-options';
import PositiveNumericInput from '../../../../components/numeric-input';
import helper from '../../../../helpers/helper';

const MinimumRentYearly = (props) => {
    const { t } = useTranslation();

    const { isMinimumYearlyOn, setIsMinimumYearlyOn, minimumRent, setMinimumRent, amount, minimumRentValue, minimumRentYearlyForm, setIsAscendingMinimumOn } = props;

    const handleMinimumRentTypeChange = (value) => {
        if (minimumRentYearlyForm.getFieldValue('minimumRentValue')) {
            minimumRentYearlyForm.setFieldsValue({
                minimumRentValue:
                    value == minimumRentTypes.totalAnnualAmount
                        ? (minimumRentYearlyForm.getFieldValue('minimumRentValue') * 12).toFixed(2)
                        : (minimumRentYearlyForm.getFieldValue('minimumRentValue') / 12).toFixed(2),
            });
        }
    };

    const handleMinimumYerlySwitchChange = (value) => {
        setIsMinimumYearlyOn(value);
        if (!value) {
            setIsAscendingMinimumOn(false);
        }
    }

    return (
        <div className={`minimum-rent-yearly ${isMinimumYearlyOn ? 'minimum-rent-yearly-full' : ''}`}>
            <Row align="middle">
                <Col span={2}>
                    <Form.Item>
                        <Switch checked={isMinimumYearlyOn} onChange={(cheked) => handleMinimumYerlySwitchChange(cheked)} />
                    </Form.Item>
                </Col>
                <Col>
                    <p className="font-weight-bold">{t('contract.turnover.minimumYearly.title')}</p>
                </Col>
            </Row>
            <h6 className="mt-5">{t('contract.turnover.minimumYearly.title')}</h6>
            <Row align="middle" className="minimum-rent-yearly-body">
                <Col span={12}>
                    <Form.Item
                        name="minimumRentValue"
                        initialValue={minimumRent?.annuallyPerUnit ? minimumRent?.annuallyPerUnit : minimumRent?.totalAnnualAmount}
                        rules={[
                            {
                                required: true,
                                message: t('contract.turnover.minimumYearly.title') + t('common.isRequired'),
                            },
                        ]}>
                        <PositiveNumericInput prefix={t('currency.norway')} suffix="NOK" onChange={(value) => setMinimumRent(value)} />
                    </Form.Item>
                </Col>
                <Col span={8} offset={2}>
                    <Form.Item
                        name="minimumRentType"
                        initialValue={!!minimumRent ? (!!minimumRent?.annuallyPerUnit ? minimumRentTypes.annuallyPerUnit : minimumRentTypes.totalAnnualAmount) : minimumRentTypes.annuallyPerUnit}
                        rules={[
                            {
                                required: true,
                                message: t('contract.turnover.minimumYearly.chooseOption'),
                            },
                        ]}>
                        <Radio.Group onChange={(e) => handleMinimumRentTypeChange(e.target.value)}>
                            <Space direction="vertical">
                                <Radio value={minimumRentTypes.annuallyPerUnit}>{minimumRentTypeOptions.find((item) => item.id == minimumRentTypes.annuallyPerUnit).name()}</Radio>
                                <Radio value={minimumRentTypes.totalAnnualAmount}>
                                    {minimumRentTypeOptions.find((item) => item.id == minimumRentTypes.totalAnnualAmount).name()}
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            {minimumRentYearlyForm.getFieldValue('minimumRentValue') && (
                <p>
                    {`${t('contract.turnover.minimumYearly.helperTextPart1')} ${
                        minimumRentYearlyForm.getFieldValue('minimumRentType') == minimumRentTypes.totalAnnualAmount ? minimumRentYearlyForm.getFieldValue('minimumRentValue') : helper.numberWithSpaces(amount * minimumRentValue)
                    }`}
                    <br />
                    {`(${t('contract.turnover.minimumYearly.helperTextPart2')})`}
                </p>
            )}
        </div>
    );
};

export default MinimumRentYearly;
