import { Col, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import arrowIcon from '../../../assets/images/arrow-icon.svg';

const { Text } = Typography;

const RentRollHeader = (props) => {
    const { t } = useTranslation();

    const { isTotal, expandAll, collapseAll } = props;

    const [isActive, setActive] = useState(false);

    const handleExpandAll = () => {
        isActive ? collapseAll() : expandAll();
        setActive(cur => !cur);
    }

    return (
        <>
            {isTotal &&
                (
                    <Row className='header-action-row'>
                        <div className={isActive ? 'collapse-all-btn' : 'expand-all-btn'} onClick={handleExpandAll}>
                            <p>{isActive ? 'Collapse all' : 'Expand All'}</p>
                            {isActive ? <Image className="arrow-icon-not-collapsed" src={arrowIcon} /> : <Image className="arrow-icon-collapsed" src={arrowIcon} />}
                        </div>
                    </Row>
                )}
            <div className={`${isTotal ? 'rent-roll-total-header' : 'rent-roll-table-header'}`}>
                <Row>
                    <Col span={2}>
                        <Text style={{ width: '100%', textAlign: 'start', marginLeft: '30px' }} ellipsis={true}>
                            {t('rentRoll.header.tenant')}
                        </Text>
                    </Col>
                    <Col span={1}>
                        {!isTotal && (
                            <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                                {t('rentRoll.header.location')}
                            </Text>
                        )}
                    </Col>
                    <Col span={1}>
                        {!isTotal && (
                            <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                                {t('rentRoll.header.floor')}
                            </Text>
                        )}
                    </Col>
                    <Col span={1}>
                        <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                            {t('rentRoll.header.m2')}
                        </Text>
                    </Col>
                    <Col span={1}>
                        <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                            {t('rentRoll.header.unit')}
                        </Text>
                    </Col>
                    <Col span={1}>
                        <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                            {t('rentRoll.header.rentM2')}
                        </Text>
                    </Col>
                    <Col span={2}>
                        <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                            {t('rentRoll.header.rentUnit')}
                        </Text>
                    </Col>
                    <Col span={1}>
                        <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                            {t('rentRoll.header.turnover')}
                        </Text>
                    </Col>
                    <Col span={2}>
                        <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                            {t('rentRoll.header.rent')}
                        </Text>
                    </Col>
                    <Col span={2}>
                        <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                            {t('rentRoll.header.yearlyRent')}
                        </Text>
                    </Col>
                    <Col span={2}>
                        <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                            {t('rentRoll.header.start')}
                        </Text>
                    </Col>
                    <Col span={2}>
                        <Text style={{ width: '100%', textAlign: 'start' }} ellipsis={true}>
                            {t('rentRoll.header.end')}
                        </Text>
                    </Col>
                    <Col span={2}>
                        <Text style={{ width: '100%', textAlign: 'center' }} ellipsis={true}>
                            {t('rentRoll.header.remaining')}
                        </Text>
                    </Col>
                    <Col span={1}>
                        <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                            {t('rentRoll.header.cpi')}
                        </Text>
                    </Col>
                    <Col span={1}>
                        <Text style={{ width: '100%', textAlign: 'center' }} ellipsis={true}>
                            {t('rentRoll.header.durationOprion')}
                        </Text>
                    </Col>
                    <Col span={1}>
                        <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                            {t('rentRoll.header.vat')}
                        </Text>
                    </Col>
                    <Col span={1}>
                        <Text style={{ width: '100%', textAlign: 'end' }} ellipsis={true}>
                            {t('rentRoll.header.warranty')}
                        </Text>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default RentRollHeader;
