import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import uuidV4 from 'uuid/v4';
import { Form } from 'antd';
import { addCommonCostActuals, getCommonCostById, setValueCommonCost } from '../../../reducers/commonCostReducer';
import { getPropertyById } from '../../../reducers/propertyReducer';
import costIcon from '../../../assets/images/new-design-cost-icon.svg';
import routingService from '../../../services/routingService';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import NotFoundPage from '../../../components/error-page';
import LeaveModal from '../../../components/leave-modal';
import HowToAddYourCommonCost from '../info-container';
import CommonCostActualsReview from './review-step';
import CommonCostActual from './actual-items-step';
import toaster from '../../../common/toaster';
import commonCostService from '../../../services/commonCostService';
import CostsObjectLayout from '../../../components/layouts/costs-object-layout';

const AddCommonCostActualComponent = (props) => {
    const { t } = useTranslation();

    const stepNames = {
        actuals: 'actuals',
        review: 'review',
    };

    const [currentStepKey, setCurrentStepKey] = useState(stepNames.actuals);
    const [timelineSteps, setTimelineSteps] = useState([]);
    const actualRef = useRef();
    const { portfolioId, propertyId, commonCostId } = useParams();

    useEffect(() => {
        props.getPropertyCall(portfolioId, propertyId);
        props.getCommonCostByIdCall(portfolioId, propertyId, commonCostId);
        mapTimelineSteps();
    }, []);

    useEffect(() => {
        !props.hasAnyActuals && createDefaultCostItems();
    }, [props.commonCostBudgets]);

    const createDefaultCostItems = (_) => {
        const items = (props.commonCostBudgets ?? []).map((x) => ({
            name: x.name,
            costType: x.costType,
            isBudget: false,
            formId: 'budgetId_' + x.id,
            uId: uuidV4(),
        }));

        props.setValueCommonCostCall('commonCost.commonCostActuals', items);
    };

    //#region navigation

    useEffect(() => {
        mapTimelineSteps();
    }, [props.commonCost]);

    const mapTimelineSteps = (_) => {
        const timelineSteps = steps.map((x) => ({
            key: x.key,
            stepTitle: x.stepTitle,
            isCompleted: isStepCompleted(x.key),
        }));

        setTimelineSteps(timelineSteps);
    };

    const isStepCompleted = (stepKey) => {
        if (!props.commonCost) {
            return false;
        }

        switch (stepKey) {
            case stepNames.actuals:
                return props.hasAnyActuals;
        }

        return false;
    };

    const onPrevious = (previousStep) => {
        if (previousStep) {
            setCurrentStepKey(previousStep.key);
        } else {
            const currentStepIndex = steps.findIndex((x) => x.key === currentStepKey);
            const previousStep = steps[currentStepIndex - 1];
            previousStep && setCurrentStepKey(previousStep.key);
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious(newStep);
        } else {
            onNext();
        }
    };

    //#endregion

    const onNext = (_) => {
        onStepChanging().then((isSuccess) => isSuccess && setCurrentStepKey(stepNames.review));
    };

    const onStepChanging = async () => {
        switch (currentStepKey) {
            case stepNames.actuals: {
                await actualRef.current.validateFields();
                const formData = actualRef.current.getFieldsValue();
                const predefinedItems = props.commonCostActuals.filter((x) => x.formId);
                const additionalItems = props.commonCostActuals.filter((x) => !x.formId);
                predefinedItems.forEach((x) => (x.value = formData[x.formId]));

                const items = [...predefinedItems, ...additionalItems];
                const commonCost = { ...props.commonCost };
                commonCost.actualAdministrationFeePercent = formData.actualAdministrationFeePercent;
                commonCost.actualAdministrationFee = formData.actualAdministrationFee;
                commonCost.actualLandlordAdjustment = formData.actualLandlordAdjustment;
                commonCost.actualLandlordAdjustmentPercent = formData.actualLandlordAdjustmentPercent;

                props.setValueCommonCostCall('commonCost', commonCost);
                props.setValueCommonCostCall('commonCost.commonCostActuals', items);

                if (commonCostService.listOfCostsIsEmpty(predefinedItems.concat(additionalItems))) {
                    toaster.error(t('commonCost.actuals.atLeastOneActualCost'), null);
                    return Promise.reject();
                }

                return Promise.resolve(true);
            }
            default:
                return Promise.resolve(true);
        }
    };

    const onSaveAndExit = (_) => {
        const commonCost = {
            ...props.commonCost,
            nonLettable: props.commonCost.grossArea - props.commonCost.lettable,
            commonCostItems: [...props.commonCost.commonCostItems, ...props.commonCost.commonCostActuals],
        };
        commonCost.commonCostActuals = commonCostService.filterCommonCostItemsByValue(props.commonCostActuals);

        props.addCommonCostActualsCall(commonCost, propertyId, commonCostId, portfolioId).then((isSuccess) => isSuccess && added());
    };

    const added = (_) => {
        goOutOfPage();
        props.showActualsAddResult();
    };

    const goOutOfPage = (_) => {
        routingService.navigateToCommonCostDetails(portfolioId, propertyId, commonCostId);
    };

    const openCloseDraftModal = (_) => {
        props.setValueCommonCostCall('showDraftModal', !props.showDraftModal);
    };

    const steps = [
        {
            key: stepNames.actuals,
            header: t('commonCost.actualsStep.header'),
            stepTitle: t('commonCost.actualsStep.stepTitle'),
            logo: costIcon,
            content: (
                <Form name="actuals" autoComplete="off" ref={actualRef}>
                    <CommonCostActual actualRef={actualRef} />
                </Form>
            ),
        },
        {
            key: stepNames.review,
            header: t('commonCost.reviewStep.header'),
            stepTitle: t('commonCost.reviewStep.header'),
            content: <CommonCostActualsReview goToStep={() => setCurrentStepKey(stepNames.actuals)} />,
        },
    ];

    return (
        <React.Fragment>
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {props.propertyNotFound ? <NotFoundPage header={t('property.notFound')} /> : null}
            {props.commonCostNotFound ? <NotFoundPage header={t('commonCost.notFound')} /> : null}
            {!props.portfolioNotFound && !props.propertyNotFound && !props.commonCostNotFound ? (
                <CostsObjectLayout
                    className="common-cost-new-design"
                    isNewDesign={true}
                    steps={steps}
                    timelineSteps={timelineSteps}
                    currentStepKey={currentStepKey}
                    bottomRightSideBar={<HowToAddYourCommonCost />}
                    exitUrl={routingService.propertyUrl(portfolioId, propertyId)}
                    isEdit={commonCostId && props.hasAnyActuals}
                    loading={props.commonCostLoading}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onSwitchStep={onSwitchStep}
                    onSaveAndExit={onSaveAndExit}
                    onCancel={openCloseDraftModal}
                />
            ) : null}
            {props.showDraftModal && <LeaveModal onCancel={openCloseDraftModal} onLeave={goOutOfPage} onSave={onSaveAndExit} loading={props.commonCostLoading} className="cost-draft-modal" />}
        </React.Fragment>
    );
};

const mapState = ({ property, portfolio, commonCost }) => {
    return {
        commonCost: get(commonCost, 'commonCost'),
        commonCostActuals: get(commonCost, 'commonCost.commonCostActuals'),
        commonCostBudgets: get(commonCost, 'commonCost.commonCostBudgets'),
        hasAnyActuals: get(commonCost, 'commonCost.commonCostActuals.length') > 0,
        showDraftModal: get(commonCost, 'showDraftModal'),
        commonCostLoading: get(commonCost, 'commonCostLoading'),
        commonCostNotFound: get(commonCost, 'commonCostNotFound'),
        propertyNotFound: get(property, 'propertyNotFound'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        addCommonCostActualsCall(data, propertyId, commonCostId, portfolioId) {
            return dispatch(addCommonCostActuals(data, propertyId, commonCostId, portfolioId));
        },
        getCommonCostByIdCall(portfolioId, propertyId, commonCostId) {
            return dispatch(getCommonCostById(portfolioId, propertyId, commonCostId));
        },
        getPropertyCall(portfolioId, propertyId) {
            dispatch(getPropertyById(propertyId, portfolioId));
        },
        setValueCommonCostCall(key, value) {
            dispatch(setValueCommonCost(key, value));
        },
        showActualsAddResult() {
            dispatch(setValueCommonCost('showActualsAddResultModal', true));
        },
    };
};

const AddCommonCostActual = clear(connect(mapState, mapDispatch)(AddCommonCostActualComponent));
export default AddCommonCostActual;
