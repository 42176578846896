import { Checkbox, Col, Collapse, DatePicker, Form, Row, Select } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { unitType } from '../../../../common/contract-options';
import PositiveNumericInput from '../../../../components/numeric-input';
import { durationFormater } from '../../../../helpers/durationFormater';
import { addStairRentInterval, setValueContract, updateStairRentInterval } from '../../../../reducers/contractReducer';
import { Image } from 'react-bootstrap';
import required from '../../../../assets/images/required.svg';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import ActionButton from '../../../../components/action-button';
import { useParams } from 'react-router';

const { Option } = Select;

const AddStairRentIntervalComponent = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [forAll, setForAll] = useState(false);
    const [currentRentObject, setCurrentRentObject] = useState();
    const [activeKeys, setActiveKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(moment(props.contract.leaseStartDate));
    const [endDate, setEndDate] = useState();
    const { portfolioId, propertyId} = useParams();

    useEffect(() => {
        if (props.stairRentIntervalModel) {
            const values = { ...props.stairRentIntervalModel };
            values.startDate = moment(values.startDate).utc(true);
            values.endDate = moment(values.endDate).utc(true);
            setForAll(!values.rentObjectId);
            form.setFieldsValue(values);
            setCurrentRentObject(getSelectedRentObject());
        }
    }, []);

    const resetFields = () => {
        form.resetFields();
        setForAll(false);
        setStartDate(null);
        setEndDate(null);
    };

    const addStairRentInterval = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldsValue();
            values.startDate = values.startDate.startOf('day').utc(true);
            values.endDate = values.endDate.startOf('day').utc(true);
            resetFields();
            setLoading(true);
            if (props.stairRentIntervalModel) {
                values.id = props.stairRentIntervalModel.id;
                return await props.updateStairRentIntervalCall(values, props.contractId, propertyId, portfolioId);
            } else {
                return await props.addStairRentIntervalCall(values, props.contractId, propertyId, portfolioId);
            }
        } finally {
            setLoading(false);
        }
    };

    const setStairForAll = () => {
        setForAll(!forAll);
        form.setFieldsValue({ rentObjectId: null });
        setCurrentRentObject(null);
        validateDates();
    };

    const handleSelectedRentObject = () => {
        validateDates();
        setCurrentRentObject(getSelectedRentObject());
    };

    const validateDates = () => {
        const startDate = form.getFieldValue('startDate');
        const endDate = form.getFieldValue('endDate');

        startDate && form.validateFields(['startDate']);
        endDate && form.validateFields(['endDate']);
    };

    const validateStartDate = (date) => {
        if (!date) {
            return Promise.resolve();
        }

        const leaseStartDate = getLeaseStartDate();
        if (leaseStartDate && date.startOf('day') < leaseStartDate.startOf('day')) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.startDate')));
        }

        if (hasOverlapWithExistingStairIntervals(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherStairRent')));
        }

        if (hasOverlapWithExistingExemption(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherRentExemption')));
        }

        return Promise.resolve();
    };

    const validateEndDate = (date) => {
        if (!date) {
            return Promise.resolve();
        }

        const leaseEndDate = getLeaseEndDate();
        if (leaseEndDate && date.startOf('day') > leaseEndDate) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.endDate')));
        }

        if (hasOverlapWithExistingStairIntervals(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherStairRent')));
        }
        
        if (hasOverlapWithExistingExemption(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherRentExemption')));
        }

        return Promise.resolve();
    };

    const validateStairRentIntervalAmount = (value) => {
        if (currentRentObject && value > currentRentObject.pricePerYearPerUnit) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.stairRentAmount')));
        }
        return Promise.resolve();
    };

    const getLeaseStartDate = () => {
        if (!forAll) {
            return currentRentObject ? moment(currentRentObject.leaseStartDate || props.contract.leaseStartDate) : null;
        }

        return moment(props.contract.leaseStartDate);
    };

    const getLeaseEndDate = () => {
        if (!forAll) {
            return currentRentObject ? moment(currentRentObject.leaseEndDate || props.contract.leaseEndDate) : null;
        }

        return moment(props.contract.leaseEndDate);
    };

    const getSelectedRentObject = () => {
        const selectedRentObjectId = form.getFieldValue('rentObjectId');
        return props.rentObjects.find((x) => x.id === selectedRentObjectId);
    };

    

    const hasOverlapWithExistingStairIntervals = (date) => {
        if (!props.stairRentIntervals || !props.stairRentIntervals.length) {
            return false;
        }

        const anotherStairRentIntervals = props.stairRentIntervalModel
            ? props.stairRentIntervals.filter((x) => x.id !== props.stairRentIntervalModel.id)
            : props.stairRentIntervals;

        for (const interval of anotherStairRentIntervals) {
            if (date.isBetween(interval.startDate, interval.endDate)) {
                return true;
            }
        }

        return false;
    };

    const hasOverlapWithExistingExemption = (date) => {
        if (!props.rentExemptions || !props.rentExemptions.length) {
            return false;
        }

        for (const exemption of props.rentExemptions) {
            if (date.isBetween(exemption.startDate, exemption.endDate) || date.isSame(exemption.startDate) || date.isSame(exemption.endDate)) {
                return true;
            }
        }

        return false;
    };

    return (
        <Collapse
            activeKey={activeKeys}
            onChange={(values) => setActiveKeys(values)}
            className='contract-collapse'
            expandIcon={({ isActive }) => isActive ? <div className='collapse-btn btn-transparent' /> : <div className='collapse-btn collapsed btn-transparent' />}>
            <Collapse.Panel
                className='contract-collapse-item'
                header={<h5 className="mb-0">{t('contract.finances.rentExemptions.addStairRent')}</h5>}
                key='1'>
                <Form form={form} autoComplete="off" style={{ width: '60%' }}>
                    <Checkbox checked={forAll} className="mb-5" onChange={setStairForAll}>
                        {t('contract.finances.rentExemptions.stairModal.addForAll')}
                    </Checkbox>
                    <p className="mb-2 font-weight-bold"><Image className="required-img" src={required} />{t('contract.finances.rentExemptions.stairModal.rentalName')}</p>
                    <Form.Item
                        className="mb-5"
                        name="rentObjectId"
                        rules={[
                            {
                                required: !forAll,
                                message: t('contract.rentObject') + t('common.isRequired'),
                            },
                        ]}>
                        <Select disabled={forAll} onChange={handleSelectedRentObject} placeholder={t('contract.rentObjectSelectPlaceholder')}>
                            {props.rentObjects.map((item) => (
                                <Option value={item.id || item.uId} key={item.id || item.uId}>
                                    {item.name || item.displayName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {currentRentObject && currentRentObject.rentObjectUnit === unitType.numberOfUnits ? (
                        <p className="mb-2 font-weight-bold"><Image className="required-img" src={required} />{t('contract.finances.rentExemptions.stairModal.stairAmountPerUnit')}</p>
                    ) : (
                        <p className="mb-2 font-weight-bold"><Image className="required-img" src={required} />{t('contract.finances.rentExemptions.stairModal.stairAmountPerSqM')}</p>
                    )}
                    <Form.Item
                        className="mb-5"
                        name="stairRentAmount"
                        rules={[
                            {
                                required: true,
                                message: t('contract.finances.rentObject.rent.title') + t('common.isRequired'),
                            },
                            {
                                validator(_, value) {
                                    return validateStairRentIntervalAmount(value);
                                },
                            },
                        ]}>
                        <PositiveNumericInput prefix={t('currency.norway')} />
                    </Form.Item>
                    <Row className='d-flex justify-content-between'>
                        <Col xl={8}>
                            <p className="mb-2 font-weight-bold">{t('common.start')}</p>
                            <Form.Item
                                initialValue={moment(props.contract.leaseStartDate).add(1, 'day')}
                                name="startDate"
                                rules={[
                                    {
                                        required: true,
                                        message: t('common.start') + t('common.isRequired'),
                                    },
                                    {
                                        validator(_, value) {
                                            return validateStartDate(value);
                                        },
                                    },
                                ]}>
                                <DatePicker
                                    dropdownClassName={'new-design-date-picker'}
                                    suffixIcon={<Image src={calendarIcon} />}
                                    format={primaryDateFormat}
                                    onChange={date => setStartDate(date.utc(true))}
                                    allowClear={false}
                                    disabled={!forAll && !currentRentObject}
                                    disabledDate={current => current && current > form.getFieldValue('endDate')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={8} className="ml-4">
                            <p className="mb-2 font-weight-bold">{t('common.end')}</p>
                            <Form.Item
                                name="endDate"
                                rules={[
                                    {
                                        required: true,
                                        message: t('common.end') + t('common.isRequired'),
                                    },
                                    {
                                        validator(_, value) {
                                            return validateEndDate(value);
                                        },
                                    },
                                ]}>
                                <DatePicker
                                    dropdownClassName={'new-design-date-picker'}
                                    suffixIcon={<Image src={calendarIcon} />}
                                    format={primaryDateFormat}
                                    onChange={date => setEndDate(date.utc(true))}
                                    allowClear={false}
                                    disabled={!forAll && !currentRentObject}
                                    disabledDate={current => current && current < form.getFieldValue('startDate')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {startDate && endDate ? (
                        <Col xl={12}>
                            <div className="mb-3">
                                <p className="font-weight-bold">{t('contract.durationAndRentalObjects.contractDates.duration')}</p>
                                <p className="duration">{durationFormater(startDate, endDate)}</p>
                            </div>
                        </Col>
                    ) : null}
                </Form>
                <Row justify="end" className="content-block-actions m-0">
                    <Col className="ml-3">
                        <ActionButton
                            className='content-block-action btn-primary'
                            loading={loading}
                            disabled={form.current && form.current.getFieldsError().filter(({ errors }) => errors.length).length}
                            text={t('common.buttons.add')}
                            onClick={() => {
                                addStairRentInterval().then((isSuccess) => {
                                    setActiveKeys([]);
                                    form.resetFields();
                                });
                            }}
                        />
                    </Col>
                </Row>
            </Collapse.Panel>
        </Collapse>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        contractId: get(contract, 'contract.id'),
        stairRentIntervalModel: get(contract, 'stairRentIntervalModel'),
        rentObjects: get(contract, 'rentObjects'),
        stairRentIntervals: get(contract, 'stairRentIntervals'),
        stairRentIntervalLoading: get(contract, 'stairRentIntervalLoading'),
        rentExemptions: get(contract, 'rentExemptions'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showStairRentIntervalModal', false));
            dispatch(setValueContract('stairRentIntervalModel', null));
        },
        addStairRentIntervalCall(data, contractId, propertyId, portfolioId) {
            return dispatch(addStairRentInterval(data, contractId, propertyId, portfolioId));
        },
        updateStairRentIntervalCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateStairRentInterval(data, contractId, propertyId, portfolioId));
        },
    };
};

const AddStairRentInterval = connect(mapState, mapDispatch)(AddStairRentIntervalComponent);
export default AddStairRentInterval;
