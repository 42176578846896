import axios from 'axios';
import { ownerCostItemTypeOptions } from '../common/owner-cost-options';
import helper from '../helpers/helper';

const ownerCostService = {
    filterOwnerCostItemsByValue: (items) => (items || []).filter((x) => x.value !== null && x.value !== undefined),

    mapOwnerCostItemNames: (ownerCost) => {
        const mapName = (x) => (x.name = helper.getEnumValue(ownerCostItemTypeOptions, x.costType) || x.name);

        if (ownerCost) {
            ownerCost.ownerCostBudgets = ownerCost.ownerCostItems.filter((item) => item.isBudget) || [];
            ownerCost.ownerCostActuals = ownerCost.ownerCostItems.filter((item) => !item.isBudget) || [];

            ownerCost.ownerCostBudgets.forEach(mapName);
            ownerCost.ownerCostActuals.forEach(mapName);
        }

        return ownerCost;
    },

    actualsTotal: (actuals) => {
        console.log(actuals);
        return actuals?.reduce((sum, actual) => sum + actual?.value, 0);
    },

    listOfCostsIsEmpty: (costs) => {
        return !costs.some((x) => x.value);
    },

    mergeBudgetAndActualItems: (budgetItems, actualItems) => {
        const budgetNames = budgetItems.map((x) => x.name);
        const actualNames = actualItems.map((x) => x.name);
        const uniqueKeys = [...new Set([...budgetNames, ...actualNames])];

        return uniqueKeys.map((key) => ({
            key,
            budgetItem: budgetItems.find((x) => x.name === key),
            actualItem: actualItems.find((x) => x.name === key),
        }));
    },

    getOwnerCostById: (portfolioId, propertyId, ownerCostId) => {
        return axios({
            method: 'get',
            url: `/api/contract/ownerCost/${ownerCostId}?portfolioId=${portfolioId}&propertyId=${propertyId}&ownerCostId=${ownerCostId}`,
        });
    },

    getOwnerCostDetails: (propertyId, ownerCostId, portfolioId) => {
        return axios({
            method: 'get',
            url: `/api/contract/ownerCost/${ownerCostId}/details?portfolioId=${portfolioId}&propertyId=${propertyId}&ownerCostId=${ownerCostId}`,
        });
    },

    create: (data) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/ownerCost/create-owner-cost?portfolioId=${data.portfolioId}&propertyId=${data.propertyId}`,
        });
    },

    update: (data) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/ownerCost/update-owner-cost?portfolioId=${data.portfolioId}&propertyId=${data.propertyId}&ownerCostId=${data.id}`,
        });
    },

    delete: (propertyId, ownerCostId, portfolioId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/ownerCost/${ownerCostId}?portfolioId=${portfolioId}&propertyId=${propertyId}&ownerCostId=${ownerCostId}`,
        });
    },

    addActuals: (propertyId, data, portfolioId) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/ownerCost/add-owner-cost-actuals?portfolioId=${portfolioId}&propertyId=${propertyId}&ownerCostId=${data.id}`,
        });
    },

    getListOfOwnerCosts: (portfolioId, propertyId, year, status) => {
        return axios({
            method: 'post',
            url: `/api/contract/ownerCost?portfolioId=${portfolioId}&propertyId=${propertyId}`,
            data: {
                status: status,
            },
        });
    },
};

export default ownerCostService;
