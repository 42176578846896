import moment from 'moment';
import i18next from 'i18next';

export const durationFormater = (startDate, endDate) => {
    if (startDate && endDate) {
        const endMomentDate = moment(endDate).add(1, 'days').startOf('day');
        const startMomentDate = moment(startDate).startOf('day');

        const duration = endMomentDate.diff(startMomentDate, 'month', true);
        const month = Math.floor(duration % 12);
        const years = Math.floor(duration / 12);

        return `${i18next.t('common.durationYearsAndMonths', { years: years, months: month })}`;
    }
    return '';
};
