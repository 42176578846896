import moment from 'moment';
import React, { useEffect, useState } from 'react';
import EProgress from '../../components/e-progress';

const Duration = (props) => {
    const [duration, setDuration] = useState(props.duration);

    useEffect(() => {
        if (!props.duration) {
            getDurationPercent();
        }
    });

    const getDurationPercent = () => {
        const currentDate = moment(Date.now());
        const startDate = moment(props.startDate);
        const endDate = moment(props.endDate);

        if (startDate > currentDate) {
            setDuration(0);
        } else if (endDate < currentDate) {
            setDuration(100);
        } else {
            var totalContractDurationInDays = endDate.diff(startDate, 'days');
            var contractDurationTillTodayInDays = currentDate.diff(startDate, 'days');

            setDuration(Math.round((contractDurationTillTodayInDays * 100) / totalContractDurationInDays * 10) / 10);
        }
    };

    return <EProgress percent={duration} format={props.format} />;
};

export default Duration;
