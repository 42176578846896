import update from '../helpers/update';
import i18next from 'i18next';
import { StatusCodes } from 'http-status-codes';
import toaster from '../common/toaster';
import backOfficeService from '../services/backofficeService';
import routingService from '../services/routingService';

const SET_VALUE_BACKOFFICE = 'SET_VALUE_BACKOFFICE';

const initialState = {
    users: [],
    usersListLoading: false,
    currentUser: null,
    currentPage: 1,
    totalUsersCount: null,
    totalPropertiesCount: null,
};

const backofficeReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_BACKOFFICE:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;

        default:
            break;
    }

    return newState;
};

const handleError = (error) => {
    if (error && error.response && error.response.status === StatusCodes.FORBIDDEN) {
        routingService.navigateToHome();
    } else {
        toaster.error(i18next.t('common.serverErrorMessage'), null);
    }
};

export const setValueBackoffice = (key, value) => {
    return {
        type: SET_VALUE_BACKOFFICE,
        payload: {
            key,
            value,
        },
    };
};

export const getUsers = () => {
    return (dispatch) => {
        dispatch(setValueBackoffice('usersListLoading', true));
        return backOfficeService
            .getUsers()
            .then((response) => {
                return response;
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                dispatch(setValueBackoffice('usersListLoading', false));
            });
    };
};

export default backofficeReducer;
