import React, { useState } from 'react';
import { Switch, Form, Row, Col, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../components/action-button';
import PositiveNumericInput from '../../../../components/numeric-input';
import { Image } from 'react-bootstrap';
import { durationFormater } from '../../../../helpers/durationFormater';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import trash from '../../../../assets/images/trash-default.svg';
import moment from 'moment';

const { RangePicker } = DatePicker;

const AscendingMinimumRent = (props) => {
    const { t } = useTranslation();

    const { isAscendingMinimumOn, setIsAscendingMinimumOn, leaseStartDate, leaseEndDate, ascendingMinimumRentForm, minimumRentYearlyForm, minimumRent, isMinimumYearlyOn } = props;

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const handleNewRow = (addFucntion) => {
        ascendingMinimumRentForm.validateFields().then(() => {
            addFucntion();
        })
    }

    const disableDate = (current, key) => {
        const values = ascendingMinimumRentForm.getFieldsValue().ascendingMinimumRents?.filter((item, index) => index != key && item?.intervalDates?.length);

        return disabledOnLeaseDate(current) || values?.some((item) => current.isBetween(item.intervalDates[0], item.intervalDates[1]));
    }
    const disabledOnLeaseDate = (current) => {
        if (!leaseStartDate || !leaseEndDate) {
            return false;
        }

        const momentStartDate = moment(leaseStartDate);
        const momentEndDate = moment(leaseEndDate);

        const tooLate = momentEndDate && current.diff(momentEndDate, 'days') > 0;
        const tooEarly = momentStartDate && momentStartDate.diff(current, 'days') > 0;

        return !!tooEarly || !!tooLate;
    };
    return (
        <div className={`ascending-minimum-rent ${isAscendingMinimumOn ? 'ascending-minimum-rent-full' : ''}`}>
            <Row align="middle">
                <Col span={2}>
                    <Form.Item>
                        <Switch checked={isAscendingMinimumOn} disabled={!isMinimumYearlyOn} onChange={(cheked) => setIsAscendingMinimumOn(cheked)} />
                    </Form.Item>
                </Col>
                <Col>
                    <p className="font-weight-bold">{t('contract.turnover.ascendingMinimum.title')}</p>
                </Col>
            </Row>
            <p style={{ marginTop: '20px' }}>{t('contract.turnover.ascendingMinimum.warning')}</p>
            <Form.List name="ascendingMinimumRents" initialValue={[{}]}>
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => {
                            const dates =
                                ascendingMinimumRentForm.getFieldsValue().ascendingMinimumRents &&
                                ascendingMinimumRentForm.getFieldsValue().ascendingMinimumRents[index]?.intervalDates;

                            return (
                                <div className="minimum-rent-interval" key={key}>
                                    <h6>{`${t('contract.turnover.ascendingMinimum.minimumRent')} ${index + 1}`}</h6>
                                    <Row>
                                        <Col span={10}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'annuallyPerUnit']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('contract.turnover.ascendingMinimum.minimumRent') + t('common.isRequired'),
                                                    },
                                                ]}>
                                                <PositiveNumericInput prefix={t('currency.norway')} placeholder={t('contract.turnover.ascendingMinimum.minimumRent')} suffix="NOK" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={1} offset={13}>
                                            <Image src={trash} className="remove-icon" onClick={() => remove(name)}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={9}>
                                            <p className="font-weight-bold mt-3">{t('contract.turnover.ascendingMinimum.intervalFrom')}</p>
                                            <Form.Item
                                                name={[name, 'intervalDates']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('contract.turnover.ascendingMinimum.enterIntervals'),
                                                    }
                                                ]}>
                                                <RangePicker format={primaryDateFormat} dropdownClassName="new-design-date-picker" onChange={() => forceUpdate()} disabledDate={(cur) => disableDate(cur, restField.fieldKey)}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {dates ? (
                                        <div className="mt-3">
                                            <p className="font-weight-bold">{t('contract.durationAndRentalObjects.contractDates.duration')}</p>
                                            <p className="duration">{durationFormater(dates[0], dates[1])}</p>
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}
                        {minimumRent && (
                            <p className="mt-4">
                                {t('contract.turnover.ascendingMinimum.helperTextPart1')} <span className="font-bold">{t('contract.turnover.minimumYearly.title')}</span>
                                <br />
                                {`${t('contract.turnover.ascendingMinimum.helperTextPart2')} ${minimumRent}.-, ${t(
                                    'contract.turnover.ascendingMinimum.helperTextPart3'
                                )}`}
                            </p>
                        )}
                        <ActionButton className="btn-secondary add-interval mb-3" onClick={() => handleNewRow(add)} text={t('contract.turnover.ascendingMinimum.addInterval')} />
                    </>
                )}
            </Form.List>
        </div>
    );
};

export default AscendingMinimumRent;
