import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../components/action-button';
import LoginSignUpLayout from '../../components/layouts/login-layout';
import routingService from '../../services/routingService';

const Registered = () => {
    const { t } = useTranslation();

    const goOnHomePage = () => {
        routingService.navigateToHome();
    };

    return (
        <LoginSignUpLayout header={t('registration.registered.title')}>
            <p className="text-secondary" style={{ marginBottom: '36px' }}>
                {t('registration.registered.secondTitle')}
            </p>
            <ActionButton className="btn-primary btn-block" text={t('registration.registered.btn')} onClick={goOnHomePage} />
        </LoginSignUpLayout>
    );
};

export default Registered;
