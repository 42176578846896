import { Menu } from 'antd';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import trash from '../../assets/images/trash-default.svg';
import trashDisabled from '../../assets/images/trash-disabled.svg';
import ActionButton from '../action-button';
import TooltipBtn from '../tooltip-btn';

const DeleteMenuItem = ({ text, onClick, key, disabled }) => {
    const { t } = useTranslation();

    const menuItem = (
        <Menu.Item key={key} disabled={disabled} onClick={onClick}>
            <ActionButton className="btn-delete" icon={<Image className="ml-2" src={disabled ? trashDisabled : trash} />} text={text} />
        </Menu.Item>
    );

    return (
        <React.Fragment>
            {disabled ? (
                <TooltipBtn title={t('common.noPermission')} placement="right">
                    {menuItem}
                </TooltipBtn>
            ) : (
                menuItem
            )}
        </React.Fragment>
    );
};

export default DeleteMenuItem;
