import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { deleteTenant, setDeleteTenantModel, setValueTenant } from '../../reducers/tenantReducer';
import { setValueProperty } from '../../reducers/propertyReducer';
import SearchInput from '../../components/search-input';
import ActionButton from '../../components/action-button';
import TenantCard from '../../components/tenant/tenant-card';
import EmptyList from '../../components/empty-list/empty-list';
import ConfirmDeleteTenant from './modals/confirm-delete-modal';
import emptyTenant from '../../assets/images/new-tenant-card-icon.svg';
import add from '../../assets/images/plus.svg';
import get from 'lodash-es/get';
import routingService from '../../services/routingService';
import toaster from '../../common/toaster';
import { editPermisionTypes } from '../../common/constants';

const TenantsComponent = (props) => {
    const { t } = useTranslation();
    const [filteredTenants, setFilteredTenants] = useState(props.tenants || []);
    const { portfolioId, propertyId } = useParams();

    useEffect(() => {
        setFilteredTenants(props.tenants || []);
    }, [props.tenants]);

    const openTenant = (tenant) => {
        routingService.navigateToTenantDetails(portfolioId, propertyId, tenant.id);
    };

    const createTenant = () => {
        if (!props.hasEditPermission) return;
        routingService.navigateToCreateTenant(portfolioId, propertyId);
    };

    const editTenant = (tenant) => {
        routingService.navigateToEditTenant(portfolioId, tenant.propertyId, tenant.id, tenant.isArchived);
    };

    const deleteTenant = (tenant) => {
        props.setDeleteTenantModelCall(tenant);
        props.showTenantDeleteModal();
    };

    const handleDeleteTenant = () => {
        props.deleteTenantCall(props.deleteTenantModel.id, props.deleteTenantModel.propertyId, portfolioId).then((isSuccess) => {
            props.closeTenantDeleteModal();

            if (isSuccess) {
                const list = props.tenants.filter((x) => x.id !== props.deleteTenantModel.id);
                props.setValuePropertyCall('property.tenants', list);
                toaster.success(t('common.itemDeletedSuccessfully', { name: props.deleteTenantModel.displayName }));
            }
        });
    };

    const onSearch = (filterText) => {
        if (!props.hasTenants) {
            return;
        }

        filterText = (filterText || '').toUpperCase();
        const tenants = props.tenants || [];
        const filteredTenants = filterText
            ? tenants.filter(
                  (tenant) =>
                      (tenant.displayName && tenant.displayName.toUpperCase().includes(filterText)) ||
                      (tenant.address && tenant.address.toUpperCase().includes(filterText)) ||
                      (tenant.orgNumber && tenant.orgNumber.toUpperCase().includes(filterText)) ||
                      (tenant.municipality && tenant.municipality.toUpperCase().includes(filterText))
              )
            : tenants;
        setFilteredTenants(filteredTenants);
    };

    const addNewTenantBtn = (className) => (
        <ActionButton
            className={`btn-primary width-fit-content ${className ? className : ''}`}
            icon={<Image src={add} />}
            text={t('tenant.btnAddNew')}
            disabled={!props.hasEditPermission || props.isArchived}
            onClick={createTenant}
        />
    );

    return (
        <div className="list">
            <div className="search-header">
                <div className="search-wrapper">
                    <SearchInput className="mr-3" placeholder={t('tenant.searchPlaceholder')} onSearch={onSearch} />
                </div>
                {addNewTenantBtn()}
            </div>
            {props.hasTenants ? (
                <div className="cards">
                    {filteredTenants.map((tenant) => (
                        <TenantCard
                            key={tenant.id}
                            tenant={tenant}
                            openTenant={openTenant}
                            editTenant={editTenant}
                            deleteTenant={deleteTenant}
                            hasEditPermission={props.hasEditPermission}
                            isArchived={props.isArchived}
                        />
                    ))}
                </div>
            ) : (
                <EmptyList image={emptyTenant} title={t('tenant.emptyList.title')} subTitle={t('tenant.emptyList.subtitle')}>
                    {addNewTenantBtn('hide-on-mobile')}
                </EmptyList>
            )}
            {props.showConfirmDeleteModal && <ConfirmDeleteTenant handleDeleteTenant={handleDeleteTenant} />}
        </div>
    );
};

const mapState = ({ property, tenant, navigation }) => {
    return {
        tenants: get(property, 'property.tenants'),
        hasTenants: get(property, 'property.tenants.length') > 0,
        showConfirmDeleteModal: get(tenant, 'showConfirmDeleteModal'),
        deleteTenantModel: get(tenant, 'deleteTenantModel'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        isArchived: get(property, 'property.isArchived')
    };
};

const mapDispatch = (dispatch) => {
    return {
        showTenantDeleteModal() {
            dispatch(setValueTenant('showConfirmDeleteModal', true));
        },
        closeTenantDeleteModal() {
            dispatch(setValueTenant('showConfirmDeleteModal', false));
        },
        setValueTenantCall(key, value) {
            dispatch(setValueTenant(key, value));
        },
        setDeleteTenantModelCall(value) {
            dispatch(setDeleteTenantModel(value));
        },
        setValuePropertyCall(key, value) {
            dispatch(setValueProperty(key, value));
        },
        deleteTenantCall(tenantId, propertyId, portfolioId) {
            return dispatch(deleteTenant(tenantId, propertyId, portfolioId));
        },
    };
};

const Tenants = connect(mapState, mapDispatch)(TenantsComponent);
export default Tenants;
