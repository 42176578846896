import { Collapse, Form } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setValueContract } from '../../../../reducers/contractReducer';
import PaymentTermsRentalObjectCard from './rent-object-edit-card';
import PaymentsTermsOptions from './rent-payment-terms';

const PaymentTermsComponent = (props) => {
    const { t } = useTranslation();

    const [activeKeys, setActiveKeys] = useState([]);

    useEffect(() => {
        if (props.contract) {
            const { invoiceIssueDateOption, paymentPlan, rentInvoicedFromDate, firstInvoiceDueDate, leaseStartDate, paymentReference } = props.contract;

            const invoiceIssueDateOptionValue = invoiceIssueDateOption ? invoiceIssueDateOption : null;
            const paymentPlanValue = paymentPlan ? paymentPlan : null;
            const paymentReferenceValue = paymentReference ? paymentReference : null;

            const rentInvoicedFromDateValue = rentInvoicedFromDate ? moment(rentInvoicedFromDate).startOf('day').utc(true) : moment(leaseStartDate).startOf('day').utc(true);

            const firstInvoiceDueDateValue = firstInvoiceDueDate ? moment(firstInvoiceDueDate).startOf('day').utc(true) : moment(leaseStartDate).startOf('day').utc(true);

            props.paymentTermsRef.current.setFieldsValue({
                invoiceIssueDateOption: invoiceIssueDateOptionValue,
                paymentPlan: paymentPlanValue,
                paymentReference: paymentReferenceValue,
            });
            props.setValueContractCall('paymentTermsOptions', {
                ...props.paymentTermsRef.current.getFieldsValue(),
                firstInvoiceDueDate: firstInvoiceDueDateValue,
                rentInvoicedFromDate: rentInvoicedFromDateValue,
            });
        }
    }, []);

    const onValuesChange = (_, allFields) => {
        props.setValueContractCall('paymentTermsOptions', allFields);
        props.onValuesChange && props.onValuesChange();
    };

    return (
        <React.Fragment>
            <div className="page-container">
                <h5>{t('contract.paymentTerms.title')}</h5>
                <Form onValuesChange={onValuesChange} name="basic" autoComplete="off" ref={props.paymentTermsRef}>
                    <PaymentsTermsOptions />
                </Form>
            </div>
            <div className="page-container edit-rent-part">
                <h5 className="mb-1">{t('contract.paymentTerms.editRentalObjectsPaymentTerms.title')}</h5>
                <p className="text-secondary mb-4">{t('contract.paymentTerms.editRentalObjectsPaymentTerms.subTitle')}</p>
                <Collapse
                    activeKey={activeKeys}
                    onChange={(values) => setActiveKeys(values)}
                    className='contract-collapse'
                    expandIcon={({ isActive }) => isActive ? <div className='collapse-btn btn-transparent' /> : <div className='collapse-btn collapsed btn-transparent' />}>
                    {props.rentObjects.map((rentObject) => (
                        <Collapse.Panel
                            className='contract-collapse-item'
                            header={<h5 className='mb-0'>{rentObject.displayName}</h5>}
                            key={rentObject.id}
                        >
                            <PaymentTermsRentalObjectCard key={rentObject.uId} rentObject={rentObject} setActiveKeys={setActiveKeys} />
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </div>
        </React.Fragment>
    );
};

const mapState = ({ contract }) => {
    return {
        rentObjects: get(contract, 'rentObjects'),
        contract: get(contract, 'contract'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
    };
};

const PaymentTerms = connect(mapState, mapDispatch)(PaymentTermsComponent);
export default PaymentTerms;
