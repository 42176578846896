import { Form, Select } from 'antd';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { cpiSourceOptions } from '../../../common/contract-options';
import PositiveNumericInput from '../../numeric-input';

const { Option } = Select;

const RentAdjustmentSettings = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h5>{t('contract.rentAdjustment.cpiSettings')}</h5>
            <Row>
                <Col lg={12} xl={2}>
                    <p className="font-weight-bold mb-0">{t('contract.rentAdjustment.cpiRate.title')}</p>
                    <Form.Item
                        name="cpiRate"
                        rules={[
                            {
                                required: props.cpiAdjustment,
                                message: t('contract.rentAdjustment.cpiRate.title') + t('common.isRequired'),
                            },
                            {
                                type: 'number',
                                min: 0,
                                max: 100,
                                message: t('contract.rentAdjustment.cpiRate.title') + t('contract.rentAdjustment.cpiRate.rangeRule')
                            }
                        ]}>
                        <PositiveNumericInput disabled={!props.cpiAdjustment} placeholder={t('contract.rentAdjustment.cpiRate.placeholder')} />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={4}>
                    <p className="font-weight-bold mb-0">{t('contract.rentAdjustment.selectCpiSource')}</p>
                    <Form.Item
                        name="cpiSource"
                        rules={[
                            {
                                required: props.cpiAdjustment,
                                message: t('contract.rentAdjustment.selectCpiSource') + t('common.isRequired'),
                            },
                        ]}>
                        <Select placeholder={t('contract.rentAdjustment.selectCpiSource')} disabled={!props.cpiAdjustment}>
                            {cpiSourceOptions.map((object) => (
                                <Option value={object.name} key={object.id}>
                                    {object.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default RentAdjustmentSettings;
