import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Form, Modal, Select } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { unitType } from '../../../../../common/contract-options';
import ActionButton from '../../../../../components/action-button';
import CustomContractDatePicker from '../../../../../components/custom-contract-date-picker';
import PositiveNumericInput from '../../../../../components/numeric-input';
import { addRentExemption, setValueContract, updateRentExemption } from '../../../../../reducers/contractReducer';
import { Image } from 'react-bootstrap';
import calendarIcon from '../../../../../assets/images/calendar-icon.svg';
import { primaryDateFormat } from '../../../../../components/dateFormatter/dateFormats';
import { useParams } from 'react-router';

const { Option } = Select;

const RentExemptionModalComponent = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [currentRentObject, setCurrentRentObject] = useState();
    const [forAll, setForAll] = useState(false);
    const [fullExemption, setFullExemption] = useState(false);

    const { portfolioId, propertyId} = useParams();

    useEffect(() => {
        if (props.rentExemptionModel) {
            const values = { ...props.rentExemptionModel };
            values.startDate = moment(values.startDate).utc(true);
            values.endDate = moment(values.endDate).utc(true);
            values.exemptionAmount = values.fullExemption ? null : values.exemptionAmount;
            setForAll(!values.rentObjectId);
            setFullExemption(values.fullExemption);
            form.setFieldsValue(values);
            setCurrentRentObject(getSelectedRentObject());
        }
    }, []);

    const onClose = () => {
        props.closeModalWindow();
    };

    const onSubmit = () => {
        form.validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                values.fullExemption = fullExemption;
                values.startDate = values.startDate.startOf('day').utc(true);
                values.endDate = values.endDate.startOf('day').utc(true);

                if (props.rentExemptionModel) {
                    values.id = props.rentExemptionModel.id;
                    props.updateRentExemptionCall(values, props.contractId, propertyId, portfolioId).then((isSuccess) => isSuccess && props.closeModalWindow());
                } else {
                    props.addRentExemptionCall(values, props.contractId, propertyId, portfolioId).then((isSuccess) => isSuccess && props.closeModalWindow());
                }
            })
            .catch(() => {});
    };

    const setExemptionForAll = () => {
        setForAll(!forAll);
        form.setFieldsValue({ rentObjectId: null });
        setCurrentRentObject(null);
        validateDates();
    };

    const setFullExemptionFunc = () => {
        setFullExemption(!fullExemption);
        form.setFieldsValue({ exemptionAmount: null });
    };

    const handleSelectedRentObject = () => {
        validateDates();
        setCurrentRentObject(getSelectedRentObject());
    };

    const validateDates = () => {
        const startDate = form.getFieldValue('startDate');
        const endDate = form.getFieldValue('endDate');

        startDate && form.validateFields(['startDate']);
        endDate && form.validateFields(['endDate']);
    };

    const validateStartDate = (date) => {
        if (!date) {
            return Promise.resolve();
        }
        const leaseStartDate = getLeaseStartDate();
        if (leaseStartDate && date.startOf('day') < leaseStartDate.startOf('day')) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.startDate')));
        }
        if (hasOverlapWithExistingExemption(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherRentExemption')));
        }
        return Promise.resolve();
    };

    const validateExemptionAmount = (value) => {
        if (currentRentObject && value > currentRentObject.pricePerYearPerUnit) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.exemptionAmount')));
        }
        return Promise.resolve();
    };

    const validateEndDate = (date) => {
        if (!date) {
            return Promise.resolve();
        }
        const leaseEndDate = getLeaseEndDate();
        if (leaseEndDate && date.startOf('day') > leaseEndDate) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.endDate')));
        }
        if (hasOverlapWithExistingExemption(date)) {
            return Promise.reject(new Error(t('contract.finances.rentExemptions.validation.addedAnotherRentExemption')));
        }
        return Promise.resolve();
    };

    const getLeaseStartDate = () => {
        if (!forAll) {
            return currentRentObject ? moment(currentRentObject.leaseStartDate || props.contract.leaseStartDate) : null;
        }
        return moment(props.contract.leaseStartDate);
    };

    const getLeaseEndDate = () => {
        if (!forAll) {
            return currentRentObject ? moment(currentRentObject.leaseEndDate || props.contract.leaseEndDate) : null;
        }
        return moment(props.contract.leaseEndDate);
    };

    const getSelectedRentObject = () => {
        const selectedRentObjectId = form.getFieldValue('rentObjectId');
        return props.rentObjects.find((x) => x.id === selectedRentObjectId);
    };

    const hasOverlapWithExistingExemption = (date) => {
        if (!props.rentExemptions || !props.rentExemptions.length) {
            return false;
        }

        const anotherRentExemptions = props.rentExemptionModel ? props.rentExemptions.filter((x) => x.id !== props.rentExemptionModel.id) : props.rentExemptions;

        for (const exemption of anotherRentExemptions) {
            if (date.isBetween(exemption.startDate, exemption.endDate)) {
                return true;
            }
        }

        return false;
    };

    return (
        <Modal
            className={'new-design-modal'}
            wrapClassName="result-modal creation-modal"
            footer={null}
            visible={true}
            maskClosable={false}
            onCancel={onClose}
            closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <h4 className="mb-0">
                        {props.rentExemptionModel ? t('contract.finances.rentExemptions.editRentExemption') : t('contract.finances.rentExemptions.addRentExemption')}
                    </h4>
                </div>
                <Form form={form} autoComplete="off">
                    <div className="main">
                        <Checkbox className="mb-3" checked={forAll} onChange={setExemptionForAll}>
                            {t('contract.finances.rentExemptions.exemptionModal.addForAll')}
                        </Checkbox>
                        <p className="mb-2 font-weight-bold">{t('contract.finances.rentExemptions.exemptionModal.rentalName')}</p>
                        <Form.Item
                            name="rentObjectId"
                            rules={[
                                {
                                    required: !forAll,
                                    message: t('contract.rentObject') + t('common.isRequired'),
                                },
                            ]}>
                            <Select disabled={forAll} onChange={handleSelectedRentObject} placeholder={t('contract.rentObjectSelectPlaceholder')}>
                                {props.rentObjects.map((item) => (
                                    <Option value={item.id || item.uId} key={item.id || item.uId}>
                                        {item.name || item.displayName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <div className="d-flex flex-row">
                            {currentRentObject && currentRentObject.rentObjectUnit === unitType.numberOfUnits ? (
                                <p className="mb-2 mr-3 font-weight-bold">{t('contract.finances.rentExemptions.exemptionModal.exemptionAmountPerUnit')}</p>
                            ) : (
                                <p className="mb-2 mr-3 font-weight-bold">{t('contract.finances.rentExemptions.exemptionModal.exemptionAmountPerSqM')}</p>
                            )}
                            <Checkbox className="mb-3" checked={fullExemption} onChange={setFullExemptionFunc}>
                                {t('contract.finances.rentExemptions.exemptionModal.fullExemption')}
                            </Checkbox>
                        </div>
                        <Form.Item
                            name="exemptionAmount"
                            rules={[
                                {
                                    required: !fullExemption,
                                    message: t('contract.finances.rentExemptions.title') + t('common.isRequired'),
                                },
                                {
                                    validator(_, value) {
                                        return validateExemptionAmount(value);
                                    },
                                },
                            ]}>
                            <PositiveNumericInput prefix={t('currency.norway')} disabled={fullExemption} />
                        </Form.Item>
                        <p className="mb-2 font-weight-bold">{t('common.start')}</p>
                        <Form.Item
                            name="startDate"
                            rules={[
                                {
                                    required: true,
                                    message: t('common.start') + t('common.isRequired'),
                                },
                                {
                                    validator(_, value) {
                                        return validateStartDate(value);
                                    },
                                },
                            ]}>
                            <DatePicker
                                dropdownClassName={'new-design-date-picker'}
                                suffixIcon={<Image src={calendarIcon} />}
                                format={primaryDateFormat}
                                allowClear={false}
                                disabled={!forAll && !currentRentObject}
                                disabledDate={current => current && current > form.getFieldValue('endDate')}
                            />
                        </Form.Item>
                        <p className="mb-2 font-weight-bold">{t('common.end')}</p>
                        <Form.Item
                            name="endDate"
                            rules={[
                                {
                                    required: true,
                                    message: t('common.end') + t('common.isRequired'),
                                },
                                {
                                    validator(_, value) {
                                        return validateEndDate(value);
                                    },
                                },
                            ]}>
                            <DatePicker
                                dropdownClassName={'new-design-date-picker'}
                                suffixIcon={<Image src={calendarIcon} />}
                                format={primaryDateFormat}
                                allowClear={false}
                                disabled={!forAll && !currentRentObject}
                                disabledDate={current => current && current < form.getFieldValue('startDate')}
                            />
                        </Form.Item>
                    </div>
                </Form>
                <div className="btns">
                    <ActionButton onClick={onClose} className="btn-secondary" text={t('common.buttons.cancel')} />
                    <ActionButton
                        onClick={onSubmit}
                        loading={props.rentExemptionLoading}
                        className="btn-primary"
                        text={
                            props.rentExemptionModel
                                ? t('contract.finances.rentExemptions.exemptionModal.btnEditExemption')
                                : t('contract.finances.rentExemptions.exemptionModal.btnAddExemption')
                        }
                    />
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        contractId: get(contract, 'contract.id'),
        rentExemptionModel: get(contract, 'rentExemptionModel'),
        rentObjects: get(contract, 'rentObjects'),
        rentExemptions: get(contract, 'rentExemptions'),
        rentExemptionLoading: get(contract, 'rentExemptionLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showRentExemptionModal', false));
            dispatch(setValueContract('rentExemptionModel', null));
        },
        addRentExemptionCall(data, contractId, propertyId, portfolioId) {
            return dispatch(addRentExemption(data, contractId, propertyId, portfolioId));
        },
        updateRentExemptionCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateRentExemption(data, contractId, propertyId, portfolioId));
        },
    };
};

const RentExemptionModal = connect(mapState, mapDispatch)(RentExemptionModalComponent);
export default RentExemptionModal;
