import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Image } from 'react-bootstrap';
import { clearProperty, deleteProperty, getPropertiesByPortfolioId, setDeletePropertyModel, setValueProperty } from '../../reducers/propertyReducer';
import add from '../../assets/images/plus.svg';
import get from 'lodash-es/get';
import emptyItem from '../../assets/images/empty-building.svg';
import MainLayout from '../../components/layouts/main-layout';
import ActionButton from '../../components/action-button';
import PropertyCreationResult from './modals/result-modal';
import PropertyUpdatedResult from './modals/item-updated-result-modal';
import PropertyCard from '../../components/property/property-card';
import SearchInput from '../../components/search-input';
import NotFoundPage from '../../components/error-page';
import ConfirmDeleteProperty from './modals/confirm-delete-modal';
import EmptyList from '../../components/empty-list/empty-list';
import LoaderSpinner from '../../components/spinner';
import routingService from '../../services/routingService';
import { Switch } from 'antd';
import { filterOptions } from '../../common/property-options';
import { editPermisionTypes, userPermisionsTypes } from '../../common/constants';
import helper from '../../helpers/helper';

const PropertiesComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId } = useParams();
    const [filteredProperties, setFilteredProperties] = useState(props.properties || []);

    useEffect(() => {
        const propertyIds = helper.getPortfolioPropertyFromLocalStorage(portfolioId);
        props.getPropertiesByPortfolioIdCall(portfolioId, propertyIds ? propertyIds[0] : undefined);
    }, [props.filterOption]);

    useEffect(() => {
        props.clearPropertyCall();
        const propertyIds = helper.getPortfolioPropertyFromLocalStorage(portfolioId);
        setFilteredProperties((propertyIds?.length > 0 && props.properties?.filter((x) => propertyIds.includes(x.id))) || props.properties || []);
    }, [props.properties]);

    const createProperty = () => {
        routingService.navigateToCreateProperty(portfolioId);
    };

    const openProperty = (propertyId) => {
        if (props.isRentRollPermision) {
            routingService.navigateToRentRoll(portfolioId, propertyId);
        } else {
            routingService.navigateToProperty(portfolioId, propertyId);
        }
    };

    const editProperty = (property) => {
        routingService.navigateToEditProperty(portfolioId, property.id);
    };

    const deleteProperty = (property) => {
        props.setDeletePropertyModelCall(property);
        props.showConfirmDeletePropertyModalCall();
    };

    const handleDeleteProperty = async () => {
        await props.deletePropertyCall(props.deletePropertyModel.id, portfolioId);
        const list = props.properties.filter((x) => x.id !== props.deletePropertyModel.id);
        props.setProperties(list);
    };

    const onSearch = (filterText) => {
        if (!props.hasAnyProperties) {
            return;
        }

        filterText = (filterText || '').toUpperCase();
        const properties = props.properties || [];
        const filteredProperties = filterText ? properties.filter((property) => property.propertyName && property.propertyName.toUpperCase().includes(filterText)) : properties;
        setFilteredProperties(filteredProperties);
    };

    const addNewPropertyBtn = (
        <ActionButton className="btn-primary add-btn" icon={<Image src={add} />} text={t('property.addNewProperty')} disabled={!props.hasEditPermission} onClick={createProperty} />
    );

    return (
        <MainLayout>
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {!props.portfolioNotFound && !props.propertiesLoading ? (
                <div className="list">
                    <div className="d-flex flex-row justify-content-between mb-3">
                        <h3>{t('property.properties')}</h3>
                        {props.filterOption == filterOptions.active && addNewPropertyBtn}
                    </div>
                    <div className="search-wrapper">
                        <div className="search-header">
                            <SearchInput placeholder={t('property.searchPlaceholder')} onSearch={onSearch} />
                        </div>
                        <Switch
                            checkedChildren="Active"
                            checked={props.filterOption == 1 ? true : false}
                            unCheckedChildren="Archived"
                            onChange={(checked) => props.setValuePropertyCall('filterOption', checked ? filterOptions.active : filterOptions.archived)}
                        />
                    </div>
                    {props.hasAnyProperties ? (
                        <div className="cards">
                            {filteredProperties.map((property) => (
                                <PropertyCard
                                    key={property.id}
                                    property={property}
                                    openProperty={openProperty}
                                    editProperty={editProperty}
                                    deleteProperty={deleteProperty}
                                    hasEditPermission={props.hasEditPermission}
                                />
                            ))}
                        </div>
                    ) : (
                        <EmptyList
                            image={emptyItem}
                            title={props.filterOption == filterOptions.active ? t('property.emptyList.title') : t('property.emptyArchivedList.title')}
                            subTitle={props.filterOption == filterOptions.active && t('property.emptyList.subtitle')}>
                            {props.filterOption == filterOptions.active && addNewPropertyBtn}
                        </EmptyList>
                    )}
                </div>
            ) : (
                <LoaderSpinner />
            )}
            {props.showCreationResultModal && (
                <PropertyCreationResult propertyName={props.location.state.propertyName} propertyId={props.location.state.propertyId} portfolioId={portfolioId} />
            )}
            {props.showItemUpdatedResultModal && <PropertyUpdatedResult />}
            {props.showConfirmDeletePropertyModal && <ConfirmDeleteProperty handleDeleteProperty={handleDeleteProperty} />}
        </MainLayout>
    );
};

const mapState = ({ property, portfolio, navigation }) => {
    return {
        properties: get(property, 'properties'),
        hasAnyProperties: get(property, 'properties.length') > 0,
        propertiesLoading: get(property, 'propertiesLoading'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
        deletePropertyModel: get(property, 'deletePropertyModel'),
        showCreationResultModal: get(property, 'showCreationResultModal'),
        showItemUpdatedResultModal: get(property, 'showItemUpdatedResultModal'),
        showConfirmDeletePropertyModal: get(property, 'showConfirmDeletePropertyModal'),
        filterOption: get(property, 'filterOption'),
        isRentRollPermision: get(navigation, 'selectedPortfolio.permissionId') == userPermisionsTypes.rentRolleUser,
    };
};

const mapDispatch = (dispatch) => {
    return {
        getPropertiesByPortfolioIdCall(id, propertyId) {
            dispatch(getPropertiesByPortfolioId(id, propertyId));
        },
        showConfirmDeletePropertyModalCall() {
            dispatch(setValueProperty('showConfirmDeletePropertyModal', true));
        },
        setValuePropertyCall(key, value) {
            dispatch(setValueProperty(key, value));
        },
        clearPropertyCall() {
            dispatch(clearProperty());
        },
        setDeletePropertyModelCall(value) {
            dispatch(setDeletePropertyModel(value));
        },
        setProperties(value) {
            dispatch(setValueProperty('properties', value));
        },
        deletePropertyCall(propertyId, portfolioId) {
            return dispatch(deleteProperty(propertyId, portfolioId));
        },
    };
};

const Properties = connect(mapState, mapDispatch)(PropertiesComponent);
export default Properties;
