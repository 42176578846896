import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

export default class ActionButton extends React.Component {
    onClick = () => {
        if (!this.props.loading && this.props.onClick) {
            this.props.onClick();
        }
    };

    render() {
        let className = this.props.loading ? 'btn-loading ' : '';
        className += this.props.className ? this.props.className : '';

        return (
            <Button
                style={this.props.style}
                htmlType={this.props.htmlType || 'button'}
                onClick={this.onClick}
                className={className}
                disabled={this.props.disabled || this.props.loading}>
                {this.props.icon}
                {this.props.text ? <span>{this.props.text}</span> : ''}
                {this.props.loading && (
                    <div className="loading">
                        <span>
                            <LoadingOutlined />
                        </span>
                    </div>
                )}
            </Button>
        );
    }
}
