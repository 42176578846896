import axios from 'axios';

const signUpService = {
    signUp: (data) => {
        return axios.post(`/api/access/signup`, data);
    },

    reSendSignUpEmail: (email) => {
        return axios({
            method: 'post',
            url: `/api/access/resend-signup-email?email=${email}`,
        });
    },

    register: (data) => {
        return axios({
            method: 'post',
            url: `/api/access/register`,
            data: data,
        });
    },

    // verifyPhone: (data, token) => {
    //   const config = {
    //     headers: { Authorization: `Bearer ${token}` }
    //   };
    //   return axios.post(`/api/access/verify`, {
    //     'verificationCode': data
    //   }, config);
    // },

    // executeSmsVerificationCodeResend: (token) => {
    //   const config = {
    //     headers: { Authorization: `Bearer ${token}` }
    //   };
    //   return axios.post(`/api/access/code`, {}, config);
    // },

    verifyInvitationToken: (token) => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        return axios.get('/api/access/verify-invitation-token', config);
    },

    verifyRegistrationToken: (values) => {
        return axios.post('/api/access/verify-registration-token', values);
    },
};

export default signUpService;
