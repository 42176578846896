import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import passwordHide from '../../assets/images/password-hide.svg';
import passwordShow from '../../assets/images/password-show.svg';
import requirment_right from '../../assets/images/new-success.svg';
import requirment_wrong from '../../assets/images/new-alert.svg';
import ActionButton from '../../components/action-button';

const Password = (props) => {
    const { t } = useTranslation();
    const [password, setPassword] = useState(null);

    const onSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit();
        }
    };

    const hasNumber = (string) => /\d/.test(string);

    const hasSpecialSymbols = (string) => /^(?=.*[!@#$%^&*])/.test(string);

    const hasRequiredLength = (string) => {
        const minPasswordLength = 7;
        return !!(string && string.length > minPasswordLength);
    };

    const requiresLowerCase = password => /[a-z]/.test(password) && password?.length > 0;

    const requiresUppercase = password => /[A-Z]/.test(password) && password?.length > 0;

    return (
        <div>
            <p className="mb-2 font-weight-bold">{props.firstFieldTitle ? props.firstFieldTitle : t('passwordForm.firstTitle')}</p>
            <Form.Item
                name="password"
                className="mb-3 no-error-explain"
                rules={[
                    {
                        required: props.passwordIsRequired,
                        message: props.firstFieldTitle ? props.firstFieldTitle : t('passwordForm.firstPlaceholder'),
                    },
                    () => ({
                        validator(_, value) {
                            if (!value || (hasRequiredLength(value) && hasNumber(value) && hasSpecialSymbols(value) && requiresLowerCase(value) && requiresUppercase(value))) {
                                return Promise.resolve();
                            }
                            return Promise.reject('');
                        },
                    }),
                ]}>
                <Input.Password
                    autoComplete="new-password"
                    onPressEnter={onSubmit}
                    placeholder={props.firstFieldTitle ? props.firstFieldTitle : t('passwordForm.firstPlaceholder')}
                    onChange={(e) => {
                        if (props.setNewPasswordIsEntered && e.target.value) {
                            props.setNewPasswordIsEntered(e.target.value);
                        } else if (props.setNewPasswordIsEntered) {
                            props.setNewPasswordIsEntered(false);
                        }
                        setPassword(props.form.getFieldValue('password'));
                    }}
                    iconRender={(visible) => (visible ? <Image src={passwordShow} /> : <Image src={passwordHide} />)}
                />
            </Form.Item>
            <div className="requirements">
                <div>
                    {hasRequiredLength(password) ? <Image src={requirment_right} /> : <Image src={requirment_wrong} />}
                    <p className="text-secondary">{t('passwordForm.firstRequirement')}</p>
                </div>
                <div>
                    {hasNumber(password) ? <Image src={requirment_right} /> : <Image src={requirment_wrong} />}
                    <p className="text-secondary">{t('passwordForm.secondRequirement')}</p>
                </div>
                <div>
                    {hasSpecialSymbols(password) ? <Image src={requirment_right} /> : <Image src={requirment_wrong} />}
                    <p className="text-secondary">{t('passwordForm.thirdRequirement')}</p>
                </div>
                <div>
                    {requiresLowerCase(password) ? <Image src={requirment_right} /> : <Image src={requirment_wrong} />}
                    <p className="text-secondary">{t('passwordForm.requiresLowerCase')}</p>
                </div>
                <div>
                    {requiresUppercase(password) ? <Image src={requirment_right} /> : <Image src={requirment_wrong} />}
                    <p className="text-secondary">{t('passwordForm.requiresUppercase')}</p>
                </div>
            </div>
            <p className="mb-2 font-weight-bold">{t('passwordForm.secondTitle')}</p>
            <Form.Item
                dependencies={['password']}
                name="confirmed_password"
                rules={[
                    {
                        required: password,
                        message: t('passwordForm.mismatch'),
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(t('passwordForm.mismatch'));
                        },
                    }),
                ]}>
                <Input.Password
                    autoComplete="new-password"
                    onPressEnter={onSubmit}
                    placeholder={t('passwordForm.secondPlaceholder')}
                    iconRender={(visible) => (visible ? <Image src={passwordShow} /> : <Image src={passwordHide} />)}
                />
            </Form.Item>
            {props.onSubmit && (
                <Form.Item shouldUpdate>
                    {() => (
                        <ActionButton
                            className="btn-primary btn-block"
                            disabled={props.form.getFieldsError().filter(({ errors }) => errors.length).length || props.loading}
                            text={props.submitBtnText}
                            onClick={onSubmit}
                            loading={props.loading}
                        />
                    )}
                </Form.Item>
            )}
        </div>
    );
};

export default Password;
