import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { Checkbox, Form, Row as RowAntd, Col as ColAntd, Switch, Select } from 'antd';
import PositiveNumericInput from '../../../../components/numeric-input';
import helper from '../../../../helpers/helper';
import TurnoverNotification from '../turnover/turnover-notification';
import { QuestionCircleFilled } from '@ant-design/icons';
import { measurements } from '../../../../common/contract-options';

const CommonCostOptions = (props) => {
    const { t } = useTranslation();

    const [commonCostNotification, setCommonCostNotification] = useState(false);

    return (
        <Row>
            <Col lg={12} xl={6}>
                <Form.Item name="isTenantCoverPropertyTax" valuePropName="checked">
                    <Checkbox className="ml-0">{t('contract.commonCost.tenantCoversPropertyTax')}</Checkbox>
                </Form.Item>
                <RowAntd>
                    <ColAntd span={16}>
                        <p>{t('contract.commonCost.editRentalObjectsCommonCost.noCommonCost')}</p>
                        <p>{props.noCommonCost ? t('common.on') : t('common.off')}</p>
                    </ColAntd>
                    <ColAntd>
                        <Form.Item name="noCommonCost" valuePropName="checked">
                            <Switch onChange={(checked) => props.setNoCommonCostFunc(checked)} />
                        </Form.Item>
                    </ColAntd>
                </RowAntd>
                <p className="mb-2 font-weight-bold">
                    {t('contract.commonCost.estimatedCommonCost.title')}
                    <QuestionCircleFilled className="ml-2" style={{ fontSize: '18px' }} onClick={() => setCommonCostNotification(true)} />
                </p>
                {commonCostNotification && (
                    <TurnoverNotification className="mb-2 mt-2" text={t('contract.commonCost.estimatedCommonCost.notification')} close={() => setCommonCostNotification(false)} />
                )}
                <div className="pre-select-input-wrapper">
                    <Form.Item
                        name="commonCostValue"
                        className="mb-2"
                        style={{ width: '60%' }}
                        rules={[
                            {
                                required: !props.noCommonCost,
                                message: t('contract.commonCost.estimatedCommonCost.title') + t('common.isRequired'),
                            },
                        ]}>
                        <PositiveNumericInput
                            prefix={t('currency.norway')}
                            onChange={(v) => {
                                props.setCommonCostPerUnitPerYear(v);
                            }}
                            disabled={props.noCommonCost}
                            placeholder={t('contract.commonCost.estimatedCommonCost.placeholder')}
                        />
                    </Form.Item>
                    <Form.Item
                        className="pre-select"
                        name="measurement"
                        rules={[
                            {
                                required: !props.noCommonCost,
                                message: t('contract.finances.measurement.type.title') + t('common.isRequired'),
                            },
                        ]}>
                        <Select disabled={props.noCommonCost} placeholder={t('contract.finances.measurement.type.placeholder')} dropdownClassName="new-design-dropdown">
                            {measurements.map((item) => {
                                return (
                                    <Select.Option value={item.value} key={item.id}>
                                        {item.name()}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </div>
            </Col>
        </Row>
    );
};

export default CommonCostOptions;
