import { StatusCodes } from 'http-status-codes';
import { setValuePortfolio } from './portfolioReducer';
import update from '../helpers/update';
import navigationService from '../services/navigationService';
import toaster from '../common/toaster';

const SET_VALUE_NAVIGATION = 'SET_VALUE_NAVIGATION';
const CLEAR_NAVIGATION_DATA = 'CLEAR_NAVIGATION_DATA';

const initialState = {
    navigationPortfolios: [],
    selectedPortfolio: null,
    navigationLoading: false,
    error: null,
};

const navigationReducer = (state = initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case SET_VALUE_NAVIGATION:
            newState = update.set(newState, `${action.payload.key}`, action.payload.value);
            break;
        case CLEAR_NAVIGATION_DATA:
            newState = initialState;
            break;

        default:
            break;
    }

    return newState;
};

export const setValueNavigation = (key, value) => {
    return {
        type: SET_VALUE_NAVIGATION,
        payload: {
            key,
            value,
        },
    };
};

export const clearNavigationData = () => {
    return {
        type: CLEAR_NAVIGATION_DATA,
    };
};

const handleNavigationError = (error, dispatch) => {
    if (error && error.response && error.response.status && (error.response.status === StatusCodes.BAD_REQUEST || error.response.status === StatusCodes.FORBIDDEN)) {
        dispatch(setValuePortfolio('portfolioNotFound', true));
    } else if (error && error.response && error.response.title) {
        toaster.error(error.response.title, null);
    }
};

export const getNavigationData = () => {
    return (dispatch) => {
        dispatch(setValueNavigation('navigationLoading', true));
        return navigationService
            .getNavigationData()
            .then((response) => {
                dispatch(setValueNavigation('navigationPortfolios', response.data));
                return response.data;
            })
            .catch((error) => handleNavigationError(error, dispatch))
            .finally(() => {
                dispatch(setValueNavigation('navigationLoading', false));
            });
    };
};

export default navigationReducer;
