import React from 'react';
import i18next from 'i18next';
import get from 'lodash.get';
import helper from '../../helpers/helper';
import { connect } from 'react-redux';
import { predefinedColors } from '../../services/chartService';
import { rentObjectRentalTypeOptions } from '../../common/contract-options';
import NumberFormat from '../../components/number-format';

class RentObjectTypeChartComponent extends React.Component {
    mapChartData = () => {
        const chartData = this.props.chartData || [];
        const totalValue = chartData.reduce((a, b) => a + b.value, 0);

        return chartData.map((item, index) => {
            const percentage = (item.value * 100) / totalValue;
            item.name = helper.getEnumValue(rentObjectRentalTypeOptions, item.type);
            item.width = Math.ceil(percentage);
            item.percentage = percentage.toFixed(1);
            item.fill = predefinedColors[index % predefinedColors.length];
            return item;
        });
    };

    renderBar(chartData) {
        return (
            <div className="chart-bar">
                {chartData.map((section) => (
                    <div key={section.type + section.value + section.width} style={{ width: `${section.width}%`, backgroundColor: section.fill }} />
                ))}
            </div>
        );
    }

    renderLegend(chartData) {
        return (
            <div className="chart-legend">
                {chartData.map((item) => (
                    <div className="legend-item" key={item.type + item.value + item.width}>
                        <span className="marker" style={{ backgroundColor: item.fill }}></span>
                        <span className="text-secondary">{item.name} </span>
                        <NumberFormat value={item.percentage} suffix='%'/>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const chartData = this.mapChartData();
        return (
            <div>
                <div className="chart-title">{i18next.t('property.rentObjectTypeChart.name')}</div>
                <div className="rent-object-type-chart">
                    {this.renderBar(chartData)}
                    {this.renderLegend(chartData)}
                </div>
            </div>
        );
    }
}

const mapState = ({ property }) => {
    return {
        chartData: get(property, 'kpis.rentObjectTypesChartData'),
    };
};

const RentObjectTypeChart = connect(mapState)(RentObjectTypeChartComponent);
export default RentObjectTypeChart;
