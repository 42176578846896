import axios from 'axios';

const invoiceService = {
    create: (data) => {
        return axios({
            method: 'post',
            data: data,
            url: `/api/contract/backoffice/invoices`,
        });
    },

    getLastInvoiceNumber: (_) => {
        return axios({
            method: 'get',
            url: `/api/contract/backoffice/invoices/get-last-invoice-number`,
        });
    },

    update: (data) => {
        return axios({
            method: 'put',
            data: data,
            url: `/api/contract/backoffice/invoices`,
        });
    },

    delete: (invoiceId) => {
        return axios({
            method: 'delete',
            url: `/api/contract/backoffice/invoices/${invoiceId}`,
        });
    },

    getInvoiceById: (invoiceId) => {
        return axios.get(`/api/contract/backoffice/invoices/${invoiceId}`);
    },

    getInvoices: (propertyId) => {
        return axios.get(`/api/contract/backoffice/invoices/get-by-property?propertyId=${propertyId}`);
    },
};

export default invoiceService;
