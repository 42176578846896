import { Checkbox, DatePicker, Form, Radio, Switch } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import calendarIcon from '../../../assets/images/calendar-icon.svg';
import { cpiIndexTypeOptions } from '../../../common/contract-options';
import CustomContractDatePicker from '../../custom-contract-date-picker';
import { primaryDateFormat } from '../../dateFormatter/dateFormats';

const RentAdjustmentOptionsComponent = (props) => {
    const { t } = useTranslation();
    const [lastKnownCpiRegulation, setLastKnownCpiRegulation] = useState(false);
    const [firstTimeCpiAdjustment, setFirstTimeCpiAdjustment] = useState();

    useEffect(() => {
        if (props.formRef.current) {
            setLastKnownCpiRegulation(props.formRef.current.getFieldValue('lastKnownCpiRegulation'));
            setFirstTimeCpiAdjustment(props.formRef.current.getFieldValue('firstTimeCpiAdjustmentDate'));
        }
    }, [props.formRef.current]);

    const datesValidator = (form) => ({
        validator(_, value) {
            const firstTimeCpiAdjustmentDate = form.getFieldValue('firstTimeCpiAdjustmentDate');
            const originalBaseIndexDate = form.getFieldValue('originalBaseIndexDate');

            console.log(firstTimeCpiAdjustmentDate);
            console.log(originalBaseIndexDate);
            if (originalBaseIndexDate && firstTimeCpiAdjustmentDate) {
                if (originalBaseIndexDate.endOf('month') >= firstTimeCpiAdjustmentDate) {
                    return Promise.reject(new Error(t('contract.rentAdjustment.firstTimeCpiAdjustmentDateError')));
                }
                return Promise.resolve();
            }
            return Promise.resolve();
        },
    });

    const validateDate = (fieldName) => {
        const value = props.formRef.current.getFieldValue(fieldName);
        if (value) {
            props.formRef.current.validateFields([fieldName]);
        }
    };

    const handleLastKnownCpiRegulationChange = (e) => {
        setLastKnownCpiRegulation(e.target.checked);
    };

    function disabledYear(current) {
        let customYear = '11-25';
        return current && current > moment(customYear, 'MM-DD');
    }

    return (
        <React.Fragment>
            <Form.Item name="annualReminder" valuePropName="checked">
                <Checkbox disabled={!props.cpiAdjustment} className="mb-0">
                    {t('contract.rentAdjustment.annualReminder')}
                </Checkbox>
            </Form.Item>
            <Form.Item name="yearlyTenantReminderOnCpiAdjustment" valuePropName="checked">
                <Checkbox disabled={!props.cpiAdjustment} className="mb-0">
                    {t('contract.rentAdjustment.yearlyTenantReminder')}
                </Checkbox>
            </Form.Item>
            <Row>
                <Col lg={12} xl={6}>
                    <p className="font-weight-bold mb-0">{t('contract.rentAdjustment.firstTimeCpiAdjustment')}</p>
                    <Form.Item
                        name="firstTimeCpiAdjustmentDate"
                        rules={[
                            {
                                required: props.cpiAdjustment,
                                message: t('contract.rentAdjustment.firstTimeCpiAdjustment') + t('common.isRequired'),
                            },
                            datesValidator,
                        ]}>
                        <DatePicker
                            style={{ width: '50%' }}
                            disabled={!props.cpiAdjustment}
                            suffixIcon={<Image src={calendarIcon} />}
                            allowClear={false}
                            dropdownClassName={'new-design-date-picker'}
                            format={primaryDateFormat}
                            onChange={(v) => {
                                validateDate('originalBaseIndexDate');
                                setFirstTimeCpiAdjustment(v);
                            }}
                        />
                    </Form.Item>
                    <p className="font-weight-bold mb-0">{t('contract.rentAdjustment.yealyCpiAdjustment')}</p>
                    <Form.Item
                        name="yearlyCpiAdjustmentDate"
                        className="mb-0"
                        rules={[
                            {
                                required: props.cpiAdjustment,
                                message: t('contract.rentAdjustment.yealyCpiAdjustment') + t('common.isRequired'),
                            },
                        ]}>
                        <DatePicker
                            style={{ width: '50%' }}
                            disabled={!props.cpiAdjustment}
                            suffixIcon={<Image src={calendarIcon} />}
                            allowClear={false}
                            dropdownClassName={'new-design-date-picker disable-year'}
                            format="DD MMMM"
                            disabledDate={disabledYear}
                        />
                    </Form.Item>
                    <Form.Item name="cpiIndexType" className="mb-0 ml-2">
                        <Radio.Group className="radio-container vertical-radio-container pl-3 my-1" disabled={!props.cpiAdjustment}>
                            {cpiIndexTypeOptions.map((object) => (
                                <Radio className="my-3" value={object.id} key={object.id}>
                                    {object.name()}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={12} xl={6}>
                    <p className="font-weight-bold mb-0">{t('contract.rentAdjustment.orignalBaseIndex')}</p>
                    <Form.Item
                        name="originalBaseIndexDate"
                        initialValue={moment()}
                        rules={[
                            {
                                required: props.cpiAdjustment,
                                message: t('contract.rentAdjustment.orignalBaseIndex') + t('common.isRequired'),
                            },
                            datesValidator,
                        ]}>
                        <DatePicker
                            style={{ width: '50%' }}
                            suffixIcon={<Image src={calendarIcon} />}
                            dropdownClassName="new-design-date-picker"
                            allowClear={false}
                            disabled={!props.cpiAdjustment}
                            picker="month"
                            onChange={() => validateDate('firstTimeCpiAdjustmentDate')}
                            format="MMMM YYYY"
                        />
                    </Form.Item>
                    <p className="font-weight-bold mb-0">{t('contract.rentAdjustment.yearlyCpiIndex')}</p>
                    <Form.Item
                        name="yearlyCpiIndexDate"
                        rules={[
                            {
                                required: props.cpiAdjustment,
                                message: t('contract.rentAdjustment.yearlyCpiIndex') + t('common.isRequired'),
                            },
                        ]}>
                        <CustomContractDatePicker disabled={lastKnownCpiRegulation || !props.cpiAdjustment} feedbackFormat={false} format={{ month: 'MMMM' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="lastKnownCpiRegulation" valuePropName="checked">
                <Checkbox onChange={handleLastKnownCpiRegulationChange} disabled={!props.cpiAdjustment} className="mb-0">
                    {t('contract.rentAdjustment.basedOnChangesFromTheOriginalLeaseIndexValue')}
                </Checkbox>
            </Form.Item>
        </React.Fragment>
    );
};

const RentAdjustmentOptions = RentAdjustmentOptionsComponent;
export default RentAdjustmentOptions;
