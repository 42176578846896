import React from 'react';
import { connect } from 'react-redux';
import chartService from '../../services/chartService';
import i18next from 'i18next';
import get from 'lodash.get';

class RentPerPropertyChartComponent extends React.Component {
    id = 'rent-per-property-chart';

    constructor(props) {
        super(props);
        this.state = { chart: null };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.setState({
            chart: chartService.rentPerPropertyChart(this.id, this.props.chartData),
        });
    }

    render() {
        return (
            <div>
                <div className="chart-title mb-2">
                    <span>{i18next.t('portfolio.dashboard.rentPerProperty')}</span>
                </div>
                <div id={this.id} style={{ height: '224px' }}></div>
            </div>
        );
    }
}

const mapState = ({ portfolio }) => {
    return {
        chartData: get(portfolio, 'kpis.properties')?.filter((item) => !item.isArchived),
    };
};

const RentPerPropertyChart = connect(mapState)(RentPerPropertyChartComponent);
export default RentPerPropertyChart;
