import i18next from 'i18next';

export const requiredCommonInput = (message = '') => ({
    whitespace: true,
    required: true,
    message: message,
});

export const validateEmail = () => ({
    validator(rule, value) {
        if (!value || !value.length) {
            return Promise.resolve();
        }

        const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (regEx.test(value)) {
            return Promise.resolve();
        }

        return Promise.reject(i18next.t('validation.email'));
    },
});

export const validatePercents = () => ({
    validator(rule, value) {
        if (value >= 0 && value <= 100) {
            return Promise.resolve();
        }

        return Promise.reject(i18next.t('common.onlyPercent'));
    },
});
