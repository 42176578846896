import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import CommonCostMergedItemReview from '../../../components/common-cost/cost-item-merged-review';
import moment from 'moment';
import commonCostService from '../../../services/commonCostService';
import PropertyRow from '../../../components/property-row';
import { commonCostSelector, selectFilteredBudgetCosts, selectFilteredActualCosts } from '../selectors';
import CurrencyFormat from '../../../components/currency-format';
import NumberFormat from '../../../components/number-format';
import { Divider } from 'antd';
import SuccessMessage from '../../../components/success-message';

const CommonCostEditReview = (props) => {
    const { t } = useTranslation();
    const commonCost = useSelector(commonCostSelector);
    const commonCostBudgets = useSelector(selectFilteredBudgetCosts);
    const commonCostActuals = useSelector(selectFilteredActualCosts);
    const propertyName = useSelector(({ property }) => property.property.propertyName);
    const commonCostMergedItems = commonCostService.mergeBudgetAndActualItems(commonCostBudgets, commonCostActuals);

    const goToStep = (stepKey) => {
        props.goToStep(stepKey);
    };

    const isValidValue = (value) => value !== null && value !== undefined;

    const showBudgetAdministrationFeePercent = isValidValue(commonCost.budgetAdministrationFeePercent);
    const showBudgetAdministrationFee = isValidValue(commonCost.budgetAdministrationFee);
    const showActualAdministrationFeePercent = isValidValue(commonCost.actualAdministrationFeePercent);
    const showActualAdministrationFee = isValidValue(commonCost.actualAdministrationFee);
    const showActualAdjustmentAmount = isValidValue(commonCost.actualLandlordAdjustment);
    const showActualAdjustmentPercent = isValidValue(commonCost.actualLandlordAdjustmentPercent);
    const showFeesBlock =
        showBudgetAdministrationFeePercent ||
        showBudgetAdministrationFee ||
        showActualAdministrationFeePercent ||
        showActualAdministrationFee ||
        showActualAdjustmentAmount ||
        showActualAdjustmentPercent;

    return (
        <div className="page-container review">
            <div className="review-part" key={'details'}>
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                    <h5>{t('commonCost.review.costDetails')}</h5>
                </div>
                <PropertyRow title={t('commonCost.review.costDetails.costName')} value={commonCost.name} className="common-cost-review-details-item" />
                <PropertyRow
                    title={t('commonCost.review.costDetails.costPeriod')}
                    value={`${moment(commonCost.startDate).format(primaryDateFormat)} - ${moment(commonCost.endDate).format(primaryDateFormat)}`}
                    className="common-cost-review-details-item"
                />
                <PropertyRow title={t('commonCost.review.costDetails.assignedTo')} value={propertyName} className="common-cost-review-details-item" />
                <PropertyRow
                    title={t('commonCost.review.costDetails.lettableArea')}
                    value={commonCost.lettable + t('commonCost.tenantCard.m2')}
                    className="common-cost-review-details-item"
                    style={{ marginBottom: '45px' }}
                />
                <div className="review-items-wrapper">
                    {commonCostMergedItems.map((item) => (
                        <CommonCostMergedItemReview key={item.key} item={item} goToStep={goToStep} />
                    ))}
                    <Divider className="common-cost-items-review-divider" />
                    {showBudgetAdministrationFeePercent && (
                        <PropertyRow
                            title={`${t('commonCost.costs.administrationFees')} ${t('common.budget')}`}
                            value={<NumberFormat value={commonCost.budgetAdministrationFeePercent} suffix={'%'} />}
                            className="common-cost-review-merged-item"
                        />
                    )}
                    {showBudgetAdministrationFee && (
                        <PropertyRow
                            title={`${t('commonCost.costs.administrationFees')} ${t('common.budget')}`}
                            value={<CurrencyFormat value={commonCost.budgetAdministrationFee} />}
                            className="common-cost-review-merged-item"
                        />
                    )}
                    {showActualAdministrationFeePercent && (
                        <PropertyRow
                            title={`${t('commonCost.costs.administrationFees')} ${t('common.actual')}`}
                            value={<NumberFormat value={commonCost.actualAdministrationFeePercent} suffix={'%'} />}
                            className="common-cost-review-merged-item"
                        />
                    )}
                    {showActualAdministrationFee && (
                        <PropertyRow
                            title={`${t('commonCost.costs.administrationFees')} ${t('common.actual')}`}
                            value={<CurrencyFormat value={commonCost.actualAdministrationFee} />}
                            className="common-cost-review-merged-item"
                        />
                    )}
                    {showActualAdjustmentAmount && (
                        <PropertyRow
                            title={`${t('commonCost.costs.landlordAdjustment')} ${t('common.actual')}`}
                            value={<CurrencyFormat value={commonCost.actualLandlordAdjustment} />}
                            className="common-cost-review-merged-item"
                        />
                    )}
                    {showActualAdjustmentPercent && (
                        <PropertyRow
                            title={`${t('commonCost.costs.landlordAdjustment')} ${t('common.actual')}`}
                            value={<NumberFormat value={commonCost.actualLandlordAdjustmentPercent} suffix={'%'} />}
                            className="common-cost-review-merged-item"
                        />
                    )}
                    <PropertyRow title={t('commonCost.review.costDetails.totalBudget')} value={<NumberFormat value={commonCost.calculatedBudgetTotalAmount} />} className="common-cost-review-merged-item" />
                    <PropertyRow title={t('commonCost.review.costDetails.totalActual')} value={<NumberFormat value={commonCost.calculatedActualTotalAmount} />} className="common-cost-review-merged-item" />
                </div>
                <SuccessMessage textElements={<><p className="font-weight-bold">{t('commonCost.review.successMessage.looksGood')}</p><p>{t('commonCost.review.successMessage.notification')}</p></>}/>
            </div>
        </div>
    );
};

export default CommonCostEditReview;
