import { get } from 'lodash-es';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import rentObjectImg from '../../assets/images/rent-object.svg';
import ActionButton from '../../components/action-button';
import { durationFormater } from '../../helpers/durationFormater';
import Duration from '../duration';

const RentObjectCardComponent = (props) => {
    const { t } = useTranslation();

    const openRentObject = (_) => {
        props.openRentObject(props.rentObject.id);
    };

    const openCalculations = (_) => {
        props.openRentObjectCalculations(props.rentObject.id);
    };

    const leaseStartDate = props.rentObject.leaseStartDate || props.contract.leaseStartDate;
    const leaseEndDate = props.rentObject.leaseEndDate || props.contract.leaseEndDate;

    return (
        <div className="entity-card">
            {props.rentObject.image ? (
                <Image src={props.rentObject.image} />
            ) : (
                <div className="entity-image rent-object-img">
                    <Image src={rentObjectImg} />
                </div>
            )}
            <div className="card-content">
                <h6 className="mb-0">{props.rentObject.displayName}</h6>
                <div className="d-flex flex-row">
                    <p className="text mr-2 mb-0">{t('contract.contractDetails.rentObjectTab.rentObjectCard.remaining')}:</p>
                    <p className="text-secondary mb-0">{durationFormater(leaseStartDate, leaseEndDate)}</p>
                </div>
                <Duration startDate={leaseStartDate} endDate={leaseEndDate} format={(value) => value + '%'} />
                <ActionButton className="btn-link my-2" onClick={openCalculations} text={t('contract.contractDetails.rentObjectTab.rentObjectCard.seeCalculations')} />
                <ActionButton className="btn-secondary w-auto" onClick={openRentObject} text={t('contract.contractDetails.rentObjectTab.rentObjectCard.seeAllDetails')} />
            </div>
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
    };
};

const RentObjectCard = connect(mapState, null)(RentObjectCardComponent);
export default RentObjectCard;
