import axios from 'axios';
import moment from 'moment';

const commonService = {
    todayDate: moment().startOf('day'),

    ownerRadioGroupTypes: {
        company: 'Company',
        person: 'Person',
        sameAsManager: 'SameAsManager',
    },

    getCountries: () => {
        return axios({
            method: 'get',
            url: '/api/contract/localization/countries',
        });
    },
};

export default commonService;
