import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { deleteTenant, getTenantsByPortfolioId, setDeleteTenantModel, setValueTenant } from '../../reducers/tenantReducer';
import { getPropertiesByPortfolioId } from '../../reducers/propertyReducer';
import emptyTenant from '../../assets/images/new-tenant-card-icon.svg';
import add from '../../assets/images/plus.svg';
import get from 'lodash-es/get';
import MainLayout from '../../components/layouts/main-layout';
import ActionButton from '../../components/action-button';
import SearchInput from '../../components/search-input';
import NotFoundPage from '../../components/error-page';
import EmptyList from '../../components/empty-list/empty-list';
import TenantCard from '../../components/tenant/tenant-card';
import ModalForTenantCreation from './modals/for-tenant-creation';
import ConfirmDeleteTenant from './modals/confirm-delete-modal';
import LoaderSpinner from '../../components/spinner';
import routingService from '../../services/routingService';
import toaster from '../../common/toaster';
import { editPermisionTypes } from '../../common/constants';

const TenantsComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId } = useParams();
    const [filteredTenants, setFilteredTenants] = useState(props.tenants || []);

    useEffect(() => {
        props.getTenantsByPortfolioIdCall(portfolioId);
    }, []);

    useEffect(() => {
        setFilteredTenants(props.tenants || []);
    }, [props.tenants]);

    const getProperties = () => {
        props.getPropertiesByPortfolioIdCall(portfolioId);
    };

    const showModalForTenantCreation = (_) => {
        if (!props.hasEditPermission) return;
        getProperties();
        props.showModalForTenantCreationCall();
    };

    const openTenant = (tenant) => {
        routingService.navigateToTenantDetails(portfolioId, tenant.propertyId, tenant.id);
    };

    const createTenant = (propertyId) => {
        routingService.navigateToCreateTenant(portfolioId, propertyId);
    };

    const editTenant = (tenant) => {
        routingService.navigateToEditTenant(portfolioId, tenant.propertyId, tenant.id, tenant.isArchived);
    };

    const deleteTenant = (tenant) => {
        props.setDeleteTenantModelCall(tenant);
        props.showTenantDeleteModal();
    };

    const handleDeleteTenant = () => {
        props.deleteTenantCall(props.deleteTenantModel.id, props.deleteTenantModel.propertyId, portfolioId).then((isSuccess) => {
            props.closeTenantDeleteModal();

            if (isSuccess) {
                const list = props.tenants.filter((x) => x.id !== props.deleteTenantModel.id);
                props.setValueTenantCall('portfolioTenants', list);
                toaster.success(t('common.itemDeletedSuccessfully', { name: props.deleteTenantModel.displayName }));
            }
        });
    };

    const onSearch = (filterText) => {
        if (!props.hasAnyTenants) {
            return;
        }

        filterText = (filterText || '').toUpperCase();
        const tenants = props.tenants || [];
        const filteredTenants = filterText ? tenants.filter((tenant) => tenant.displayName && tenant.displayName.toUpperCase().includes(filterText)) : tenants;
        setFilteredTenants(filteredTenants);
    };

    const addNewTenantBtn = (
        <ActionButton
            className="btn-primary add-btn"
            icon={<Image src={add} />}
            text={t('tenant.btnAddNew')}
            disabled={!props.hasEditPermission}
            onClick={showModalForTenantCreation}
        />
    );

    return (
        <MainLayout>
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {!props.portfolioNotFound && !props.tenantsLoading ? (
                <div className="list">
                    <div className="d-flex flex-row justify-content-between mb-3">
                        <h3>{t('tenant.tenants')}</h3>
                        {addNewTenantBtn}
                    </div>
                    {props.hasAnyTenants && (
                        <div className="search-header">
                            <SearchInput placeholder={t('tenant.searchPlaceholder')} onSearch={onSearch} />
                        </div>
                    )}
                    {props.hasAnyTenants ? (
                        <div className="cards">
                            {filteredTenants.map((tenant) => (
                                <TenantCard
                                    key={tenant.id}
                                    tenant={tenant}
                                    isArchived={tenant.isArchived}
                                    openTenant={openTenant}
                                    editTenant={editTenant}
                                    deleteTenant={deleteTenant}
                                    hasEditPermission={props.hasEditPermission}
                                />
                            ))}
                        </div>
                    ) : (
                        <EmptyList image={emptyTenant} title={t('tenant.tenantsPage.emptyList.title')} subTitle={t('tenant.tenantsPage.emptyList.subtitle')} className="tenant-empty-list">
                            {addNewTenantBtn}
                        </EmptyList>
                    )}
                </div>
            ) : (
                <LoaderSpinner />
            )}
            {props.showConfirmDeleteModal && <ConfirmDeleteTenant handleDeleteTenant={handleDeleteTenant} />}
            {props.showModalForTenantCreation && <ModalForTenantCreation goToCreation={createTenant} />}
        </MainLayout>
    );
};

const mapState = ({ property, navigation, tenant }) => {
    return {
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        tenants: get(tenant, 'portfolioTenants')?.filter(item => !item.isArchived),
        hasAnyTenants: get(tenant, 'portfolioTenants.length') > 0,
        tenantsLoading: get(tenant, 'tenantsLoading'),
        deleteTenantModel: get(tenant, 'deleteTenantModel'),
        portfolioNotFound: get(property, 'portfolioNotFound'),
        showConfirmDeleteModal: get(tenant, 'showConfirmDeleteModal'),
        showModalForTenantCreation: get(tenant, 'showModalForTenantCreation'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        getTenantsByPortfolioIdCall(id) {
            dispatch(getTenantsByPortfolioId(id));
        },
        getPropertiesByPortfolioIdCall(portfolioId) {
            dispatch(getPropertiesByPortfolioId(portfolioId));
        },
        showModalForTenantCreationCall() {
            dispatch(setValueTenant('showModalForTenantCreation', true));
        },
        showTenantDeleteModal() {
            dispatch(setValueTenant('showConfirmDeleteModal', true));
        },
        closeTenantDeleteModal() {
            dispatch(setValueTenant('showConfirmDeleteModal', false));
        },
        setDeleteTenantModelCall(value) {
            dispatch(setDeleteTenantModel(value));
        },
        setValueTenantCall(key, value) {
            dispatch(setValueTenant(key, value));
        },
        deleteTenantCall(tenantId, propertyId, portfolioId) {
            return dispatch(deleteTenant(tenantId, propertyId, portfolioId));
        },
    };
};

const Tenants = connect(mapState, mapDispatch)(TenantsComponent);
export default Tenants;
