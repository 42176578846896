import React from 'react';
import LoginSignUpLayout from '../../components/layouts/login-layout';

const PrivacyPolicy = () => {
    return (
        <LoginSignUpLayout header="Personvernerklæring">
            <div className="privacy-policy">
                <p>Gyldig fra: 13.10.2022</p>
                <p>
                    Denne personvernerklæringen gjelder for bruk av Tjenesten til Estatelab PM AS. Løsningen er en drifts-, administrasjonstjeneste som er utviklet og administreres
                    av Estatelab PM AS (heretter «Selskapet», eller «Estatelab PM AS») med registrert forretningsadresse Drammensveien 127, 0277 OSLO og med org. nr. 928 078 396.
                </p>
                <h5>Anvendelsesområde</h5>
                <p>
                    Estatelab PM AS sine tjenester er tilgjengelig fra nettstedet 
                    <a href="https://www.estatelab.no" _targer="blank">
                        www.estatelab.no
                    </a>
                    . Denne personvernerklæringen beskriver hvordan Estatelab PM AS behandler dine personopplysninger som Kunde eller på vegne av deg som Kunde og
                    behandlingsansvarlig når du bruker Property Management til å behandle personopplysninger i Tjenesten. Personvernerklæringen inneholder en oversikt over Kundens
                    rettigheter vedrørende vår innsamling, behandling, oppbevaring, deling og sikker lagring av dine personopplysninger. Når du bruker Property Management, godtar
                    du denne personvernerklæringen, som er et billag til brukervilkårene for Property Management. Det bemerkes at Kunde som behandlingsansvarlig er ansvarlig for å
                    overholde personvernregelverket for behandlingen av personopplysningene som de selv legger inn.
                </p>
                <p>Personvernerklæringen kan oppdateres, og Estatelab PM AS anbefaler at du gjør deg kjent med de og holder deg oppdatert.</p>
                <h5>Innsamling av personopplysninger</h5>
                <p>
                    For å kunne levere Estatelab PM AS -tjenester til deg slik de er beskrevet i brukervilkårene, må Selskapet samle inn og behandle dine personopplysninger. En
                    personopplysning er enhver opplysning om en identifiserbar fysisk person. Anonymiserte opplysninger, for eksempel aggregert data som ikke kan kobles til en
                    identifiserbar fysisk person, er ikke en personopplysning.
                </p>
                <p>
                    Brukere, Kunde som bruker av tjenestene. Deres ansatte eller samarbeidspartnere med godkjennelse fra Kunde eller behandlingsansvarlig. Estatelab PM AS har
                    hovedansvaret for at personvernregelverket overholdes ved behandling av dine (Kundens) personopplysninger, mens Kunden og deres brukere har hovedansvaret ved
                    behandlingen av personopplysningene de selv legger inn.
                </p>
                <h5>Personopplysninger som samles inn</h5>
                <p>
                    Når du bruker tjenesten, innsamler eller behandler Estatelab PM AS noen opplysninger. Opplysningene omfatter, men er ikke begrenset til, følgende:
                    <ul>
                        <li>Kunde eller brukers Navn</li>
                        <li>Kunde eller brukers e-post adresse</li>
                        <li>Kunde eller brukers telefon nummer</li>
                        <li>Kunde eller brukers Tjeneste versjon av Property Management</li>
                        <li>Betalingsinformasjon for Tjenesten</li>
                    </ul>
                </p>
                <h5>Personopplysninger som behandles på vegne av Kunden</h5>
                <p>
                    Estatelab PM AS behandler en rekke personopplysninger på vegne av Kunden i forbindelse med Kundens bruk av tjenesten. Dette er personopplysninger som legges inn
                    av Kunden, hvor Kunden selv er ansvarlig for at behandlingen er lovlig, rettferdig og åpen, at det foreligger et lovlig behandlingsgrunnlag og at forordningens
                    øvrige plikter overholdes. Det bemerkes at det er Kunden som bestemmer formålet med behandlingen og hvilke personopplysninger som legges inn i Tjenesten.
                </p>
                <h5>Sletting av personopplysninger</h5>
                <p>
                    Selskapet sletter eller anonymiserer dine personopplysninger når det ikke lenger er nødvendig å behandle eller oppbevare dem til det formål de ble samlet inn
                    for. Anonymiserte personopplysninger vil ikke kunne kobles tilbake til en identifiserbar fysisk person
                </p>
                <p>Opplysninger om Kunde lagres så lenge det eksisterer en aktiv avtale. En aktiv avtale defineres som at Kunden står oppført i Property Management som Kunde.</p>
                <p>Din brukerkonto, med personalia, vil enten slettes ved at du selv aktivt sletter din konto eller ved at du tar kontakt med Estatelab PM AS.</p>
                <h5>Oppbevaring og beskyttelse av dine personopplysninger</h5>
                <p>Dine personopplysninger behandles og oppbevares i EU/EØS</p>
                <p>
                    For å begrense risikoen for tap, misbruk, uautoriserte adgang, videresendelse og endring, beskytter Estatelab PM AS dine personopplysninger med tekniske,
                    fysiske og administrative sikkerhetstiltak. For eksempel benyttes passordbeskyttelse for brukerkonto, brannmurer og datakryptering, samt fysisk adgangskontroll
                    til Estatelab PM AS sine IT-systemer.
                </p>
                <h5>Deling av personopplysninger i forbindelse med betaling og overføring</h5>
                <p>Overføring av dine økonomiske transaksjoner til bruk av tjenesten skjer utelukkende via tredjeparts betalingsbehandler.</p>
                <h5>Dine rettigheter</h5>
                <p>
                    Med grunnlag i personopplysningsloven av 2018 og EUs generelle personvernforordning («GDPR»), har du en rekke rettigheter som gjelder ditt personvern og vår
                    bruk av dine personopplysninger. Du har rett til å få innsikt i hvilke opplysninger Estatelab PM AS behandler om deg. Du har videre rett til å komme med
                    innsigelser mot innsamlingen og mot den videre behandling av dine personopplysninger. Du har utover det også rett til at få dine personopplysninger rettet, hvis
                    de er uriktige, og du har rett til å kreve dine personopplysninger blir slettet dersom de ikke lenger er nødvendig for det formål de ble samlet inn for og det
                    ikke foreligger annet grunnlag for å oppbevare de lenger. Dine rettigheter fremkommer av GDPR kapittel III.
                </p>
                <p>
                    Endre av personalia kan selv endres i Tjenesten til Estatelab PM AS.
                    <br />
                    Skulle du ha spørsmål om vår behandling av dine personopplysninger, dine rettigheter eller ønsker å utøve dine rettigheter iht. regelverket, kan du henvende deg
                    til Estatelab PM AS ved å sende en mail til<a href="mailto:account@estatelab.no">account@estatelab.no</a>
                    <br />I den grad samtykke utgjør vårt behandlingsgrunnlag for dine personopplysninger, kan du når som helst trekke tilbake ditt samtykke. Ved å trekke tilbake
                    ditt samtykke påvirker det ikke lovligheten av behandling som bygger på samtykket før det ble trukket tilbake. Å trekke tilbake samtykke, gjøres ved å ta
                    kontakt på e-post til Estatelab PM AS. Å trekke tilbake ditt samtykke medfører at du dessverre ikke lenger kan benytte deg av tjenesten samtykket gjelder.
                </p>
                <h5>Tilsyn og klagemuligheter</h5>
                <p>
                    Tjenesten er underlagt og følger personopplysningsloven av 2018, og EUs generelle personvernforordning (GDPR). Datatilsynet er tilsynsmyndighet for behandling
                    av personopplysninger. Dersom du opplever at vår behandling av dine personopplysninger ikke oppfyller lovkravene, kan du klage til Datatilsynet.
                </p>
                <p>Datatilsynets kontaktopplysninger:</p>
                <p>Datatilsynet,</p>
                <p>Postboks 458 Sentrum,</p>
                <p>0105 Oslo</p>
                <p>Telefon: 22 39 69 00</p>
            </div>
        </LoginSignUpLayout>
    );
};

export default PrivacyPolicy;
