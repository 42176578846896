import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { primaryDateFormat } from '../../components/dateFormatter/dateFormats';
import { predefinedColors } from '../../services/chartService';
import get from 'lodash.get';
import moment from 'moment';
import routingService from '../../services/routingService';
import NumberFormat from '../../components/number-format';
import helper from '../../helpers/helper';
import { propertyStatuses, propertyStatusesOptions } from '../../common/property-options';

const PropertiesTableComponent = (props) => {
    const maxRowsNumberInCollapsedState = 5;
    const { t } = useTranslation();
    const { portfolioId } = useParams();
    const [isTableExpanded, setTableExpanded] = useState(props.tableData.length <= maxRowsNumberInCollapsedState);

    const handleExpandClick = (e) => {
        e.preventDefault();
        setTableExpanded(true);
    };

    const handleCollapseClick = (e) => {
        e.preventDefault();
        setTableExpanded(false);
    };

    const getLegendMarkerColor = (index) => {
        return predefinedColors[index % predefinedColors.length];
    };

    return (
        <div className="d-flex flex-column">
            <Row className="table-header visible-md">
                <Col xs={12} md={3}>
                    <div className="th">{t('portfolio.propertiesTable.property')}</div>
                </Col>
                <Col xs={12} md={3}>
                    <div className="th">{t('property.name.propertyStatus')}</div>
                </Col>
                <Col xs={12} md={2}>
                    <div className="th">{t('property.name.activeNotification.takeoverDate')}</div>
                </Col>
                <Col xs={12} md={2}>
                    <div className="th">{t('portfolio.propertiesTable.yearlyRentalIncome')}</div>
                </Col>
                <Col xs={12} md={2}>
                    <div className="th">{t('portfolio.propertiesTable.contracts')}</div>
                </Col>
            </Row>
            <div className="table-body">
                {(isTableExpanded ? props.tableData : props.tableData.slice(0, maxRowsNumberInCollapsedState)).map((property, index) => (
                    <div className={`table-row ${index % 2 === 1 ? 'alternate' : ''}`}>
                        <Row align={'middle'} key={property.id}>
                            <Col xs={12} md={3}>
                                <div className="td d-flex flex-row align-items-center">
                                    <span className="legend-marker" style={{ backgroundColor: getLegendMarkerColor(index) }}></span>
                                    <Link to={routingService.propertyUrl(portfolioId, property.id)}>{property.propertyName}</Link>
                                </div>
                            </Col>
                            <Col xs={12} md={3}>
                                <div className="td">
                                    <span className="hidden-md">{t('property.name.propertyStatus')}:</span>
                                    {property.propertyStatusId ? helper.getEnumValue(propertyStatusesOptions, property.propertyStatusId) : 'N/A'}
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="td">
                                    <span className="hidden-md">{t('property.name.activeNotification.takeoverDate')}:</span>
                                    {property.takeoverDate ? moment(property.takeoverDate).format(primaryDateFormat) : 'N/A'}
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="td">
                                    <span className="hidden-md">{t('portfolio.propertiesTable.yearlyRentalIncome')}:</span>
                                    <NumberFormat value={property.yearlyIncome} />
                                </div>
                            </Col>
                            <Col xs={12} md={2}>
                                <div className="td">
                                    <span className="hidden-md">{t('portfolio.propertiesTable.contracts')}:</span>
                                    {property.contractsCount}
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
            <div className="table-footer">
                {props.tableData.length > maxRowsNumberInCollapsedState ? (
                    isTableExpanded ? (
                        <Link onClick={handleCollapseClick}>{t('common.buttons.collapse')}</Link>
                    ) : (
                        <Link onClick={handleExpandClick}>{t('common.buttons.expand')}</Link>
                    )
                ) : null}
            </div>
        </div>
    );
};

const mapState = ({ portfolio }) => {
    return {
        tableData: get(portfolio, 'kpis.properties').filter((item) => !item.isArchived),
    };
};

const PropertiesTable = connect(mapState)(PropertiesTableComponent);
export default PropertiesTable;
