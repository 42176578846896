import { Checkbox, DatePicker, Form, Radio, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';
import { deadlineTypes } from '../../../../common/constants';
import { leaseDurationOptionReminderTypeOptions, leaseDurationOptionTypeOptions, reminderPrioritiesOptions } from '../../../../common/contract-options';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import PositiveNumericInput from '../../../../components/numeric-input';
import {
    addLeaseDurationOption,
    addLeaseDurationOptionAndContractDuration,
    setPropertyToRentalObject,
    setValueContract,
    updateLeaseDurationOption,
} from '../../../../reducers/contractReducer';

const LeaseContractDurationComponent = (props) => {
    const { t } = useTranslation();
    const [leaseDurationOptionType, setLeaseDurationOptionType] = useState(
        (props.leaseDurationOptionEditModel && props.leaseDurationOptionEditModel.leaseDurationOptionType) || leaseDurationOptionTypeOptions.extendLeaseEndDateTo
    );

    const notification = props.leaseDurationOptions.find((o) => !!o.notification);

    const extendOption = props.leaseDurationOptions?.find((o) => o.leaseDurationOptionType === leaseDurationOptionTypeOptions.extendLeaseEndDateTo) || undefined;
    const terminateOption = props.leaseDurationOptions?.find((o) => o.leaseDurationOptionType === leaseDurationOptionTypeOptions.terminatePriorEndDateTo) || undefined;

    const [optionToExtend, setOptionToExtend] = useState(!!extendOption);
    const [optionExpiration, setOptionExpiration] = useState(!!terminateOption);
    const [hasReminder, setHasReminder] = useState(!!notification?.hasReminder);
    const [remindByEmail, setRemindByEmail] = useState(!!notification?.remindByEmail);
    const [leaseDurationOptionReminderType, setLeaseDurationOptionReminderType] = useState(
        notification?.reminders?.length > 0 ? leaseDurationOptionReminderTypeOptions.date : leaseDurationOptionReminderTypeOptions.prior
    );

    const [openOptionExpirationDate, setOpenOptionExpirationDate] = useState(false);

    const leaseOptionRef = props.leaseOptionRef;

    const isAddedRentObject = props.rentObject && props.rentObject.uId;

    useEffect(() => {
        if (props.leaseOptionRef.current) {
            const notification = props.leaseDurationOptions?.findLast((o) => !!o.notification)?.notification || undefined;
            const extendOption = props.leaseDurationOptions?.findLast((o) => o.leaseDurationOptionType === leaseDurationOptionTypeOptions.extendLeaseEndDateTo) || undefined;
            const terminateOption = props.leaseDurationOptions?.findLast((o) => o.leaseDurationOptionType === leaseDurationOptionTypeOptions.terminatePriorEndDateTo) || undefined;
            const reminderType = notification?.reminders?.length > 0 ? leaseDurationOptionReminderTypeOptions.prior : leaseDurationOptionReminderTypeOptions.date;
            setOptionToExtend(!!extendOption);
            setOptionExpiration(!!terminateOption);
            setHasReminder(!!notification?.hasReminder);
            setRemindByEmail(!!notification?.remindByEmail);
            setLeaseDurationOptionReminderType(reminderType);

            props.leaseOptionRef.current.setFieldsValue({
                optionToExtend: !!extendOption,
                optionExpiration: !!terminateOption,
                description: props.leaseDurationOptions[0]?.description || null,
                reminderValue:
                    (reminderType === leaseDurationOptionReminderTypeOptions.prior && notification?.reminders?.length && notification.reminders[0].timeBeforeExpirity) || null,
                reminderPriorType:
                    (reminderType === leaseDurationOptionReminderTypeOptions.prior && notification?.reminders?.length && notification.reminders[0].reminderPeriodTypeId) ||
                    deadlineTypes[0].id,
                reminderDate: reminderType === leaseDurationOptionReminderTypeOptions.date && notification?.reminderDate ? moment(notification.reminderDate) : null,
                remindByEmail: !!notification?.remindByEmail,
                hasReminder: !!notification?.hasReminder,
                date: !!extendOption?.date ? moment(extendOption.date) : null,
                optionExpirationDate: !!terminateOption?.date ? moment(terminateOption.date) : null,
            });
        }
    }, [props.leaseDurationOptions]);

    const disabledDates = (current) => {
        if (leaseDurationOptionType === leaseDurationOptionTypeOptions.extendLeaseEndDateTo) {
            if (isAddedRentObject) {
                const addedRentObjectLeaseOptionsList = [...props.rentObject.leaseDurationOptions];
                if (addedRentObjectLeaseOptionsList && addedRentObjectLeaseOptionsList.length > 0) {
                    return current && current < moment(addedRentObjectLeaseOptionsList[addedRentObjectLeaseOptionsList.length - 1].date).endOf('day');
                } else {
                    return current && current < moment(props.leaseEndDate).endOf('day');
                }
            }
            if (props.rentObject) {
                const rentObjectLeaseOptionsList = [...props.rentObjectLeaseDurationOptions];
                if (rentObjectLeaseOptionsList && rentObjectLeaseOptionsList.length > 0) {
                    return current && current < moment(rentObjectLeaseOptionsList[rentObjectLeaseOptionsList.length - 1].date).endOf('day');
                } else {
                    return current && current < moment(props.leaseEndDate).endOf('day');
                }
            } else {
                const contractLeaseOptionsList = [...props.leaseDurationOptions];
                if (contractLeaseOptionsList && contractLeaseOptionsList.length > 0) {
                    return current && current < moment(contractLeaseOptionsList[contractLeaseOptionsList.length - 1].date).endOf('day');
                } else {
                    return current && current < moment(props.leaseEndDate).endOf('day');
                }
            }
        } else {
            if (isAddedRentObject) {
                const addedRentObjectLeaseOptionsList = [...props.rentObject.leaseDurationOptions];
                if (addedRentObjectLeaseOptionsList && addedRentObjectLeaseOptionsList.length > 0) {
                    return current && current > moment(addedRentObjectLeaseOptionsList[addedRentObjectLeaseOptionsList.length - 1].date).endOf('day');
                } else {
                    return current && current > moment(props.leaseEndDate).endOf('day');
                }
            }
            if (props.rentObject) {
                const rentObjectLeaseOptionsList = [...props.rentObjectLeaseDurationOptions];
                if (rentObjectLeaseOptionsList && rentObjectLeaseOptionsList.length > 0) {
                    return current && current > moment(rentObjectLeaseOptionsList[rentObjectLeaseOptionsList.length - 1].date).endOf('day');
                } else {
                    return current && current > moment(props.leaseEndDate).endOf('day');
                }
            } else {
                const contractLeaseOptionsList = [...props.leaseDurationOptions];
                if (contractLeaseOptionsList && contractLeaseOptionsList.length > 0) {
                    return current && current > moment(contractLeaseOptionsList[contractLeaseOptionsList.length - 1].date).endOf('day');
                } else {
                    return current && current > moment(props.leaseEndDate).endOf('day');
                }
            }
        }
    };

    const disabledOptionDate = (current) => {
        const date = leaseOptionRef.current.getFieldValue('date');
        return current && current > moment(date).subtract(1, 'days').endOf('day');
    };

    const disabledReminderDate = (current) => {
        const expirationDate = leaseOptionRef.current.getFieldValue('optionExpirationDate');
        if (expirationDate) {
            return current && current > moment(expirationDate).subtract(1, 'days').endOf('day');
        }
    };

    const changeHasReminder = async (e) => {
        if (!hasReminder) {
            let fields = [];

            if (optionToExtend) {
                fields.push('date');
            }

            if (optionExpiration) {
                fields.push('optionExpirationDate');
            }

            try {
                await leaseOptionRef.current.validateFields(fields);
                setHasReminder(true);
            } catch (err) {
                setHasReminder(false);
                e.stopPropagation();
            }
        } else {
            removeReminder();
        }
    };

    const removeReminder = () => {
        setHasReminder(false);
        leaseOptionRef.current.setFieldsValue({ reminderDate: null, reminderValue: null });
        setLeaseDurationOptionReminderType(leaseDurationOptionReminderTypeOptions.date);
        setRemindByEmail(false);
    };

    const onClickOptionExpirationDate = () => {
        if (leaseOptionRef.current) {
            if (optionToExtend) {
                leaseOptionRef.current
                    .validateFields(['date'])
                    .then(() => {
                        if (!openOptionExpirationDate) {
                            setOpenOptionExpirationDate(true);
                        }
                    })
                    .catch((e) => {
                        setOpenOptionExpirationDate(false);
                    });
            } else {
                if (!openOptionExpirationDate) {
                    setOpenOptionExpirationDate(true);
                }
            }
        }
    };

    const onRemoveExpirationDateIfLeaseDurationOptionTypeIsTerminatePriorEndDateTo = () => {
        if (leaseDurationOptionType === leaseDurationOptionTypeOptions.terminatePriorEndDateTo) {
            const date = leaseOptionRef.current.getFieldValue('date');
            const optionExpirationDate = leaseOptionRef.current.getFieldValue('optionExpirationDate');
            if (moment(date).diff(optionExpirationDate, 'days') > 0) {
                leaseOptionRef.current.setFieldsValue({ optionExpirationDate: null });
            }
        }
    };

    useEffect(() => {
        if (hasReminder && !(optionToExtend || optionExpiration)) {
            removeReminder();
        }
    }, [optionToExtend, optionExpiration]);

    return (
        <div className="result-modal creation-modal lease-duration">
            <div className="main">
                <h5 className="mb-0">{t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.header')}</h5>
                <Form name="basic" autoComplete="off" ref={leaseOptionRef}>
                    <Form.Item initialValue={optionToExtend} valuePropName="checked" name="optionToExtend">
                        <Checkbox
                            checked={optionToExtend}
                            onChange={(e) => {
                                setOptionToExtend(e.target.checked);
                            }}>
                            {t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.optionToExtend')}
                        </Checkbox>
                    </Form.Item>
                    <div className="pl-4">
                        <p className="font-weight-bold">{t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.optionExpirationDate')}</p>
                        <Form.Item
                            name="date"
                            initialValue={!!extendOption?.date ? moment(extendOption.date) : null}
                            rules={[
                                {
                                    required: optionToExtend,
                                    message: t('contract.leaseDurationOptionAndContractOptionModal.date') + t('common.isRequired'),
                                },
                            ]}>
                            <DatePicker
                                dropdownClassName={'new-design-date-picker'}
                                disabled={!optionToExtend}
                                suffixIcon={<Image src={calendarIcon} />}
                                disabledDate={disabledDates}
                                onSelect={(value) => onRemoveExpirationDateIfLeaseDurationOptionTypeIsTerminatePriorEndDateTo(value)}
                                format={primaryDateFormat}
                                allowClear={false}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item valuePropName="checked" name="optionExpiration">
                        <Checkbox
                            checked={optionExpiration}
                            onChange={(e) => {
                                setOptionExpiration(e.target.checked);
                            }}>
                            {t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.optionExpiration')}
                        </Checkbox>
                    </Form.Item>
                    <div className="pl-4">
                        <p className="font-weight-bold">{t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.optionExpirationDate')}</p>
                        <Form.Item
                            name="optionExpirationDate"
                            initialValue={!!terminateOption?.date ? moment(terminateOption.date) : null}
                            rules={[
                                {
                                    required: optionExpiration,
                                    message: t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.optionExpirationDate') + t('common.isRequired'),
                                },
                            ]}>
                            <DatePicker
                                dropdownClassName={'new-design-date-picker'}
                                disabled={!optionExpiration}
                                suffixIcon={<Image src={calendarIcon} />}
                                open={openOptionExpirationDate}
                                onBlur={() => setOpenOptionExpirationDate(false)}
                                onSelect={() => setOpenOptionExpirationDate(false)}
                                onOpenChange={onClickOptionExpirationDate}
                                disabledDate={disabledOptionDate}
                                format={primaryDateFormat}
                                allowClear={false}
                            />
                        </Form.Item>
                    </div>
                    <div className="d-flex flex-column">
                        <Form.Item initialValue={hasReminder} className="mb-3" valuePropName="checked" name="hasReminder">
                            <div onClick={(e) => changeHasReminder(e)}>
                                <Checkbox disabled={!(optionToExtend || optionExpiration)} className="mb-3" checked={hasReminder}>
                                    {t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.setReminder')}
                                </Checkbox>
                            </div>
                        </Form.Item>
                        {hasReminder && (
                            <React.Fragment>
                                <Form.Item initialValue={remindByEmail} className="mb-3" valuePropName="checked" name="remindByEmail">
                                    <Checkbox
                                        className="m-0 pl-4 pt-2"
                                        checked={remindByEmail}
                                        onChange={() => {
                                            setRemindByEmail(!remindByEmail);
                                        }}>
                                        {t('contract.leaseDurationOptionAndContractOptionModal.remindMeByEmail')}
                                    </Checkbox>
                                </Form.Item>
                                <Row className="m-0 mt-2 pl-4">
                                    <Col xs={12} md={2} className="p-0 d-flex flex-column">
                                        <Form.Item name="reminderType">
                                            <Radio.Group
                                                className="radio-container"
                                                defaultValue={leaseDurationOptionReminderType}
                                                onChange={(e) => setLeaseDurationOptionReminderType(e.target.value)}>
                                                <Radio value={leaseDurationOptionReminderTypeOptions.date} className="mb-1">
                                                    {t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.date')}
                                                </Radio>
                                                <Radio value={leaseDurationOptionReminderTypeOptions.prior} className="mt-4">
                                                    {t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.priority')}
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={12} md={5} className="p-0">
                                        <Form.Item
                                            className="mb-3"
                                            name="reminderDate"
                                            rules={[
                                                {
                                                    required: leaseDurationOptionReminderType === leaseDurationOptionReminderTypeOptions.date,
                                                    message: t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.date') + t('common.isRequired'),
                                                },
                                            ]}
                                            initialValue={
                                                leaseDurationOptionReminderType === leaseDurationOptionReminderTypeOptions.date && notification?.reminderDate
                                                    ? moment(notification.reminderDate)
                                                    : null
                                            }>
                                            <DatePicker
                                                style={{ width: '100%' }}
                                                suffixIcon={<Image src={calendarIcon} />}
                                                dropdownClassName={'new-design-date-picker'}
                                                disabled={leaseDurationOptionReminderType === leaseDurationOptionReminderTypeOptions.prior}
                                                disabledDate={disabledReminderDate}
                                                format={primaryDateFormat}
                                                allowClear={false}
                                            />
                                        </Form.Item>

                                        <div className="pre-select-input-wrapper">
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: leaseDurationOptionReminderType === leaseDurationOptionReminderTypeOptions.prior,
                                                        message: t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.priority') + t('common.isRequired'),
                                                    },
                                                ]}
                                                className="pre-input"
                                                name="reminderValue">
                                                <PositiveNumericInput
                                                    disabled={leaseDurationOptionReminderType === leaseDurationOptionReminderTypeOptions.date}
                                                    placeholder={t('contract.leaseDurationOptionAndContractOptionModal.priorDeadline.placeholder')}
                                                />
                                            </Form.Item>
                                            <Form.Item className="pre-select" name="reminderPriorType">
                                                <Select
                                                    disabled={leaseDurationOptionReminderType === leaseDurationOptionReminderTypeOptions.date}
                                                    dropdownClassName="new-design-dropdown">
                                                    {deadlineTypes.map((item, index) => {
                                                        return (
                                                            <Select.Option value={item.id} key={item.id}>
                                                                {item.name}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                    </div>
                    <p className="font-weight-bold">{t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.description.title')}</p>
                    <Form.Item className="mb-0" name="description" initialValue={props.leaseDurationOptions[0]?.description || null}>
                        <TextArea
                            placeholder={t('contract.leaseDurationOptionAndContractOptionModal.leaseDuration.description.placeholder')}
                            rows={5}
                            style={{ minHeight: '48px' }}
                        />
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        rentObjects: get(contract, 'rentObjects'),
        hasContractDuration: get(contract, 'hasContractDuration'),
        leaseDurationOptionEditModel: get(contract, 'leaseDurationOptionEditModel'),
        leaseDurationOptionLoading: get(contract, 'leaseDurationOptionLoading'),
        leaseDurationOptions: get(contract, 'leaseDurationOptions'),
        rentObjectLeaseDurationOptions: get(contract, 'rentObject.leaseDurationOptions'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showContractLeaseDurationModal', false));
            dispatch(setValueContract('showRentObjectLeaseDurationModal', false));
            dispatch(setValueContract('showAddedRentObjectLeaseDurationModal', false));
            dispatch(setValueContract('leaseDurationOptionEditModel', null));
        },
        addLeaseDurationOptionCall(data, contractId, propertyId, portfolioId) {
            return dispatch(addLeaseDurationOption(data, contractId, propertyId, portfolioId));
        },
        updateLeaseDurationOptionCall(data, contractId, propertyId, portfolioId) {
            return dispatch(updateLeaseDurationOption(data, contractId, propertyId, portfolioId));
        },
        addLeaseDurationOptionAndContractDurationCall(dates, leaseDurationData, contractId, portfolioId, propertyId) {
            return dispatch(addLeaseDurationOptionAndContractDuration(dates, leaseDurationData, contractId, portfolioId, propertyId));
        },
        setRentObjectLeaseDurationOptionsCall(data) {
            dispatch(setValueContract('rentObject.leaseDurationOptions', data));
        },
        setPropertyToRentalObjectCall(id, key, value) {
            dispatch(setPropertyToRentalObject(id, key, value));
        },
    };
};

const LeaseContractDuration = connect(mapState, mapDispatch)(LeaseContractDurationComponent);
export default LeaseContractDuration;
