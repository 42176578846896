import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { setValueProperty } from '../../../reducers/propertyReducer';
import { Image } from 'react-bootstrap';
import { CloseOutlined } from '@ant-design/icons';
import ActionButton from '../../../components/action-button';
import done from '../../../assets/images/popup-done.svg';
import routingService from '../../../services/routingService';

const PropertyCreationResultComponent = (props) => {
    const { t } = useTranslation();

    const onClose = () => {
        props.closeModalWindow();
    };

    const goToPropertyCreation = () => {
        props.closeModalWindow();
        routingService.navigateToCreateProperty(props.portfolioId);
    };

    const goToPlotCreation = () => {
        props.closeModalWindow();
        routingService.navigateToCreatePlot(props.portfolioId, props.propertyId);
    };

    return (
        <Modal wrapClassName="result-modal" footer={null} visible={true} maskClosable={false} onCancel={onClose} closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <span className="icon">
                        <Image src={done} />
                    </span>
                    <div className="d-flex flex-column">
                        <h4 className="mb-2">{t('property.result.title')}</h4>
                        <p className="text-secondary">{t('property.result.secondTitle', { name: props.propertyName })}</p>
                    </div>
                </div>
                <div className="main">
                    <h6>{t('property.result.mainTitle')}</h6>
                    <p className="text-secondary">{t('common.resultOrUpdateMessage')}</p>
                </div>
                <div className="btns">
                    <ActionButton onClick={goToPropertyCreation} className="btn-secondary" text={t('property.btnAdd')} />
                    <ActionButton onClick={goToPlotCreation} className="btn-primary" text={t('property.result.btn')} />
                </div>
            </div>
        </Modal>
    );
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueProperty('showCreationResultModal', false));
        },
    };
};

const PropertyCreationResult = connect(null, mapDispatch)(PropertyCreationResultComponent);
export default PropertyCreationResult;
