import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import { reSendInvites, setValuePortfolio } from '../../../reducers/portfolioReducer';
import InviteUserRow from './invite-user-row';
import ActionButton from '../../../components/action-button';
import InviteConfirmModal from './invite-confim-modal';
import LoaderSpinner from '../../../components/spinner';

export const modalActions = {
    delete: 'delete',
    resend: 'resend'
}

const PortfolioInviteComponent = (props) => {
    const { t } = useTranslation();

    const [inviteModal, setInviteModalVisible] = useState({ visible: false, inviteId: null, action: null });
    const [showInviteRow, setShowInviteRow] = useState(false);
    const [isInviteLoading, setIsInviteLoading] = useState(false);

    const inviteContentRef = useRef();

    return (
        <React.Fragment>
            {isInviteLoading ? (
                <div style={{ height: `${inviteContentRef?.current?.clientHeight}px`}}>
                    <LoaderSpinner />
                </div>
            ) :
                (
                    <div className="page-container email" id="invite-step" ref={inviteContentRef}>
                        <div className="d-flex flex-column">
                            <ActionButton
                                style={{ height: '30px', marginBottom: '15px' }}
                                className='btn-primary invite-btn'
                                text={t('portfolio.invites.addUser')}
                                onClick={() => setShowInviteRow(true)}
                            />
                            {showInviteRow && <InviteUserRow isNewInvite={true} isEditPortfolio={props.isEdit} setIsInviteLoading={setIsInviteLoading} />}
                            <div className='invite-drawer' />
                            <Row className='mb-4'>
                                <Col span={6}>
                                    <p className="font-weight-bold d-flex flex-row align-items-center">
                                        {t('portfolio.invites.email.title')}
                                    </p>
                                </Col>
                                <Col span={4}>
                                    <p className="font-weight-bold d-flex flex-row align-items-center">
                                        {t('portfolio.invites.firstName.title')}
                                    </p>
                                </Col>
                                <Col span={4}>
                                    <p className="font-weight-bold d-flex flex-row align-items-center">
                                        {t('portfolio.invites.lastName.title')}
                                    </p>
                                </Col>
                                <Col span={5}>
                                    <p className="font-weight-bold d-flex flex-row align-items-center">
                                        {t('portfolio.invites.role.title')}
                                    </p>
                                </Col>
                            </Row>
                            {props.portfolio?.invites?.map(item => {
                                return (
                                    <InviteUserRow userInvite={item} isEditPortfolio={props.isEdit} setInviteModalVisible={setInviteModalVisible} setIsInviteLoading={setIsInviteLoading} />
                                )
                            })}
                        </div>
                    </div>
                )}
            <InviteConfirmModal
                inviteModalVisible={inviteModal.visible}
                inviteId={inviteModal.inviteId}
                action={inviteModal.action}
                isEditPortfolio={props.isEdit}
                setInviteModalVisible={setInviteModalVisible}
                setPortfolioCall={props.setPortfolioCall}
                portfolio={props.portfolio}
                setIsInviteLoading={setIsInviteLoading}
            />
        </React.Fragment>
    );
};

const mapState = ({ portfolio, common }) => {
    return {
        portfolio: get(portfolio, 'portfolio'),
        userEmail: get(common, 'user.email'),
        invites: get(portfolio, 'portfolio.invites'),
        sendInvitesLoading: get(portfolio, 'sendInvitesLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setInvitesCall(value) {
            dispatch(setValuePortfolio('portfolio.invites', value));
        },
        reSendInvitesCall(portfolioId, invites) {
            return dispatch(reSendInvites(portfolioId, invites));
        },
        setPortfolioCall(value) {
            dispatch(setValuePortfolio('portfolio', value));
        }
    };
};

const PortfolioInvite = connect(mapState, mapDispatch)(PortfolioInviteComponent);
export default PortfolioInvite;
