import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { defaultManager } from '../../../../reducers/portfolioReducer';
import commonService from '../../../../services/commonService';
import PortfolioManagerByCompany from './manager-company';
import PortfolioManagerByPerson from './manager-person';
import { getCountries } from '../../../../reducers/commonReducer';

const PortfolioManagerStepComponent = (props) => {
    const portfolioManager = props.portfolioManager || defaultManager;

    useEffect(() => {
        if (!props.hasCountries) {
            props.getCountriesCall();
        }
    }, []);

    return portfolioManager.ownedBy === commonService.ownerRadioGroupTypes.company ? (
        <PortfolioManagerByCompany managerRef={props.managerRef} />
    ) : (
        <PortfolioManagerByPerson managerRef={props.managerRef} />
    );
};

const mapState = ({ portfolio, common }) => {
    return {
        portfolioManager: get(portfolio, 'portfolio.manager'),
        hasCountries: get(common, 'countries.length') > 0,
    };
};

const mapDispatch = (dispatch) => {
    return {
        getCountriesCall() {
            dispatch(getCountries());
        },
    };
};

const PortfolioManagerStep = connect(mapState, mapDispatch)(PortfolioManagerStepComponent);
export default PortfolioManagerStep;
