import { Button, Dropdown, Menu, Table } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import add from '../../../assets/images/add.svg';
import moreIcon from '../../../assets/images/more.svg';
import { invoiceStatusesOptions } from '../../../common/invoiceOptions';
import ActionButton from '../../../components/action-button';
import DateFormatter from '../../../components/dateFormatter/dateFormatter';
import DeleteMenuItem from '../../../components/menu-items/delete-menu-item';
import EditMenuItem from '../../../components/menu-items/edit-menu-item';
import LoaderSpinner from '../../../components/spinner';
import helper from '../../../helpers/helper';
import { getInvoices, setValueInvoice } from '../../../reducers/invoiceReducer';
import ConfirmDeleteInvoice from './modals/confirm-delete-modal';
import InvoiceModal from './modals/invoice-modal';

const InvoicesComponent = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (props.invoicesProperty) {
            props.getInvoicesCall(props.invoicesProperty.propertyId);
        }
    }, []);

    const goBack = () => {
        props.goBack && props.goBack();
    };

    const editInvoice = (invoiceId) => {
        const invoice = props.invoices.find((x) => x.id === invoiceId);
        if (invoice) {
            props.setInvoice(invoice);
            props.showInvoiceModal();
        }
    };

    const deleteInvoice = (invoiceId) => {
        const invoice = props.invoices.find((x) => x.id === invoiceId);
        if (invoice) {
            props.setInvoice(invoice);
            props.showDeleteInvoiceModal();
        }
    };

    const showStatus = (value) => {
        return helper.getEnumValue(invoiceStatusesOptions, value);
    };

    const dropdownMenu = (id) => {
        return (
            <Menu className="menu edit-menu">
                <EditMenuItem key="0" onClick={() => editInvoice(id)} text={t('backoffice.invoices.editInvoice')} />
                <DeleteMenuItem key="1" onClick={() => deleteInvoice(id)} text={t('backoffice.invoices.deleteInvoice')} />
            </Menu>
        );
    };

    const columns = [
        {
            title: t('backoffice.invoices.invoiceNumber'),
            dataIndex: 'invoiceNumber',
            width: '15%',
            sorter: (a, b) => a.invoiceNumber - b.invoiceNumber,
            key: 'invoiceNumber',
        },
        {
            title: t('backoffice.invoices.orgNumberOrFullName'),
            dataIndex: 'orgNumberOrFullName',
            width: '15%',
            key: 'orgNumber',
        },
        {
            title: t('backoffice.invoices.invoiceEmail'),
            dataIndex: 'invoiceEmail',
            width: '15%',
            sorter: (a, b) => a.invoiceEmail.toUpperCase().localeCompare(b.invoiceEmail.toUpperCase()),
            key: 'invoiceEmail',
        },
        {
            title: t('backoffice.invoices.amount'),
            dataIndex: 'amount',
            width: '15%',
            sorter: (a, b) => a.amount - b.amount,
            key: 'amount',
        },
        {
            title: t('backoffice.invoices.dateOfPayment'),
            dataIndex: 'dateOfPayment',
            key: 'dateOfPayment',
            width: '15%',
            sorter: (a, b) => moment(a.dateOfPayment).unix() - moment(b.dateOfPayment).unix(),
            render: (value) => (value ? <DateFormatter date={value} format="DD.MM.YYYY" /> : 'N/A'),
        },
        {
            title: t('backoffice.invoices.created'),
            dataIndex: 'createdOnUtc',
            key: 'createdOnUtc',
            width: '15%',
            sorter: (a, b) => moment(a.createdOnUtc).unix() - moment(b.createdOnUtc).unix(),
            render: (value) => (value ? <DateFormatter date={value} format="DD.MM.YYYY HH:mm" /> : 'N/A'),
        },
        {
            title: t('backoffice.invoices.status'),
            dataIndex: 'status',
            width: '8%',
            key: 'status',
            render: (t) => <p>{showStatus(t)}</p>,
        },
        {
            dataIndex: 'id',
            key: 'id',
            width: '2%',
            render: (t) => (
                <Dropdown trigger={['click']} placement="bottomRight" overlay={dropdownMenu(t)}>
                    <Button className="btn-link btn-transparent settings-btn">
                        <Image src={moreIcon} />
                    </Button>
                </Dropdown>
            ),
        },
    ];

    return (
        <div className="h-100">
            {props.invoicesLoading ? (
                <LoaderSpinner />
            ) : (
                <div className="list-page">
                    <ActionButton className="btn-link p-0" text={t('backoffice.invoices.goBack')} onClick={goBack} />
                    <div className="d-flex flex-row justify-content-between">
                        <h3 className="mb-4">{t('backoffice.invoices.title')}</h3>
                        <ActionButton className="btn-primary" icon={<Image src={add} />} onClick={props.showInvoiceModal} text={t('backoffice.invoices.addInvoice')} />
                    </div>
                    <Table columns={columns} dataSource={props.invoices} className="components-table-demo-nested" />
                </div>
            )}
            {props.showInvoiceModalWindow && <InvoiceModal />}
            {props.showConfirmDeleteModal && <ConfirmDeleteInvoice />}
        </div>
    );
};

const mapState = ({ invoice }) => {
    return {
        invoices: get(invoice, 'invoices'),
        invoicesLoading: get(invoice, 'invoicesLoading'),
        invoicesProperty: get(invoice, 'invoicesProperty'),
        showInvoiceModalWindow: get(invoice, 'showInvoiceModal'),
        showConfirmDeleteModal: get(invoice, 'showConfirmDeleteModal'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        getInvoicesCall(propertyId) {
            dispatch(getInvoices(propertyId));
        },
        showInvoiceModal() {
            dispatch(setValueInvoice('showInvoiceModal', true));
        },
        showDeleteInvoiceModal() {
            dispatch(setValueInvoice('showConfirmDeleteModal', true));
        },
        setInvoice(data) {
            dispatch(setValueInvoice('invoice', data));
        },
    };
};

const Invoices = connect(mapState, mapDispatch)(InvoicesComponent);
export default Invoices;
