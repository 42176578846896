import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { setValueContract } from '../../../../reducers/contractReducer';
import routingService from '../../../../services/routingService';
import get from 'lodash-es/get';
import RentObjectCard from '../../../../components/rent-object/rent-object-card';
import EmptyList from '../../../../components/empty-list/empty-list';
import RentObjectDetailsModal from './details-modal';

const RentObjectsComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId, propertyId, tenantId, contractId } = useParams();

    const openRentObjectModal = (rentObjectId) => {
        const rentObject = props.rentObjects.find((x) => x.id === rentObjectId);
        if (rentObject) {
            props.setRentObjectWithDetails(rentObject);
            props.showRentObjectDetailsModalCall();
        }
    };

    const openRentObjectCalculations = (rentObjectId) => {
        routingService.navigateToRentObjectCalculations(portfolioId, propertyId, tenantId, contractId, rentObjectId);
    };

    return (
        <div className="list">
            {props.hasRentObjects ? (
                <div className="cards">
                    {props.rentObjects.map((rentObject) => (
                        <RentObjectCard key={rentObject.id} rentObject={rentObject} openRentObject={openRentObjectModal} openRentObjectCalculations={openRentObjectCalculations} />
                    ))}
                </div>
            ) : (
                <EmptyList title={t('contract.contractDetails.rentObjectTab.emptyList.title')} />
            )}
            {props.showRentObjectDetailsModal && <RentObjectDetailsModal />}
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        rentObjects: get(contract, 'rentObjects'),
        hasRentObjects: get(contract, 'rentObjects.length') > 0,
        showRentObjectDetailsModal: get(contract, 'showRentObjectDetailsModal'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        showRentObjectDetailsModalCall() {
            dispatch(setValueContract('showRentObjectDetailsModal', true));
        },
        setRentObjectWithDetails(data) {
            dispatch(setValueContract('rentObjectWithDetails', data));
        },
    };
};

const RentObjects = connect(mapState, mapDispatch)(RentObjectsComponent);
export default RentObjects;
