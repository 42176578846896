import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import userMdIcon from '../../../../assets/images/user-active.svg';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import { setValueContract } from '../../../../reducers/contractReducer';

const ContractNotesModalComponent = (props) => {
    const { t } = useTranslation();

    const onClose = () => {
        props.closeModalWindow();
    };

    const onAddNote = () => {
        props.closeModalWindow();
        props.showCreateNoteModalCall();
    };

    return (
        <Modal
            wrapClassName="result-modal creation-modal"
            footer={null}
            visible={true}
            maskClosable={false}
            onCancel={onClose}
            closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <h4 className="mb-0">{t('contract.notes.seeNotes')}</h4>
                </div>
                <div className="main">
                    {props.contractNotes &&
                        props.contractNotes.map((item) => (
                            <div className="contract-note">
                                <div className="note-header">
                                    <span className="icon">
                                        <Image src={userMdIcon} />
                                    </span>
                                    <div className="d-flex flex-column">
                                        <h4>{item.userName}</h4>
                                        <p className="text-secondary">{moment(item.date).format(primaryDateFormat)}</p>
                                    </div>
                                </div>
                                <div className="note-text">{item.note}</div>
                            </div>
                        ))}
                    <div className="d-flex flex-column">
                        <Button onClick={onAddNote} className="btn-primary ml-auto">
                            {t('contract.notes.addNewNoteBtn')}
                        </Button>
                    </div>
                </div>
                <div className="btns">
                    <Button onClick={onClose} className="btn-secondary">
                        {t('common.buttons.cancel')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const mapState = ({ contract }) => {
    return {
        contractNotes: get(contract, 'contractNotes'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueContract('showContractNotesModal', false));
        },
        showCreateNoteModalCall() {
            dispatch(setValueContract('showCreateNoteModal', true));
        },
    };
};

const ContractNotesModal = connect(mapState, mapDispatch)(ContractNotesModalComponent);
export default ContractNotesModal;
