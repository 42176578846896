import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../action-button';

const ConfirmDeleteModal = (props) => {
    const { t } = useTranslation();

    return (
        <Modal wrapClassName="result-modal confirm-delete-modal" visible={true} maskClosable={false} onCancel={props.onClose} closeIcon={<CloseOutlined className="close-icon" />}>
            <div>
                <div className="header">
                    <div className="d-flex flex-column">
                        <h4 className="mb-0">{props.headerTitle || t('common.areYouSure')}</h4>
                    </div>
                </div>
                <div className="main">
                    <h6 className="mb-0">{props.title}</h6>
                    <p className="text-secondary mb-0">{props.text}</p>
                </div>
                <div className="btns">
                    <ActionButton onClick={props.onClose} className="btn-secondary" text={t('common.buttons.cancel')} />
                    <ActionButton onClick={props.onConfirm} className="btn-error" text={props.deleteBtnText} loading={props.loading} />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmDeleteModal;
