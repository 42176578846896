import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete } from 'antd';
import axios from 'axios';
import get from 'lodash-es/get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { enterBtnCode } from '../../common/constants';
import toaster from '../../common/toaster';
import useDebounce from '../../helpers/useDebounse';
import { setValuePlot } from '../../reducers/plotReducer';
import plotService from '../../services/plotService';

const MainAddressComponent = (props) => {
    const { t } = useTranslation();
    const [searching, setSearching] = useState(false);
    const [cancelToken, setCancelToken] = useState(axios.CancelToken.source());
    const debouncedSearchCompany = useDebounce(props.searchMainAddress, 500);

    const onSelectAddress = (id, data) => {
        if (props.onSelect) {
            props.setValuePlotCall('plot.mainAddress', data.info.address);
            props.onSelect(data.info);
        }
    };

    const onChange = (value) => {
        props.setValuePlotCall('plot.mainAddress', value);
        props.onChange(value);
    };

    const onSearch = (data) => {
        if (data === '') {
            props.setValuePlotCall('addresses', []);
            props.onSearch();
            if (searching) {
                cancelTokenFunc();
            }
        }
    };

    const cancelTokenFunc = () => {
        cancelToken.cancel();
        setSearching(false);
        setCancelToken(axios.CancelToken.source());
    };

    useEffect(() => {
        if (debouncedSearchCompany) {
            if (props.municipality === null) {
                toaster.error(t('newPlot.modal.needForAddress'), null);
            } else {
                setSearching(true);
                let searchString = `${debouncedSearchCompany}, ${props.plot.city}`;
                plotService
                    .searchMunicipalityAddresses(searchString, cancelToken)
                    .then((res) => {
                        setSearching(false);
                        const filteredData = res.data?.cadastralUnits
                            .map((item) => ({
                                ...item,
                                address: item?.address?.split(',')[0],
                            }))
                            .filter((item) => item.municipalityNumber === props.municipality);
                        props.setValuePlotCall('addresses', filteredData);
                    })
                    .catch((error) => {
                        if (axios.isCancel(error)) {
                            console.log('Canceled');
                        }
                        props.setValuePlotCall('addresses', []);
                        setSearching(false);
                    });
            }
        }
    }, [debouncedSearchCompany, cancelToken]);

    return (
        <div className="d-flex flex-column">
            <p className="font-weight-bold">{props.title}</p>
            <div className="select-container">
                <AutoComplete
                    virtual={false}
                    dropdownClassName="main-address-dropdown"
                    allowClear
                    autoComplete="off"
                    value={props.mainAddress}
                    placeholder={props.placeholder}
                    onSearch={onSearch}
                    onChange={onChange}
                    onSelect={onSelectAddress}
                    onKeyDown={(e) => (e.keyCode === enterBtnCode ? e.preventDefault() : '')}>
                    {props.addresses &&
                        props.addresses.map((address, i) => (
                            <AutoComplete.Option
                                key={i}
                                info={{
                                    address: address.address,
                                    municipalityName: address.municipalityName,
                                    municipalityNumber: address.municipalityNumber,
                                    gnr: address.gnr,
                                    bnr: address.bnr,
                                }}>
                                <div className="d-flex flex-column">
                                    <p>{address.municipalityName + ' ' + address.municipalityNumber + '-' + address.gnr + '/' + address.bnr}</p>
                                    <p>{address.address || 'N/A'}</p>
                                </div>
                            </AutoComplete.Option>
                        ))}
                </AutoComplete>
                {searching && <LoadingOutlined className="autocomplete-loading-icon" />}
            </div>
        </div>
    );
};

const mapState = ({ plot }) => {
    return {
        addresses: get(plot, 'addresses'),
        municipality: get(plot, 'plot.municipality'),
        mainAddress: get(plot, 'plot.mainAddress'),
        plot: get(plot, 'plot'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValuePlotCall(key, value) {
            dispatch(setValuePlot(key, value));
        },
    };
};

const MainAddress = connect(mapState, mapDispatch)(MainAddressComponent);
export default MainAddress;
