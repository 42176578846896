import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { defaultOwner, setValueProperty } from '../../../../reducers/propertyReducer';
import { requiredCommonInput, validateEmail } from '../../../../common/validators';
import get from 'lodash-es/get';
import CommonInput from '../../../../components/common-input';
import OwnPhoneInput from '../../../../components/phone-input';
import CountrySelect from '../../../../components/country-selector';

const PropertyOwnerByPersonComponent = (props) => {
    const { t } = useTranslation();
    const propertyOwner = props.propertyOwner || defaultOwner;

    useEffect(() => {
        if (propertyOwner) {
            props.ownerRef.current.setFieldsValue(propertyOwner);
        }
    }, [propertyOwner]);

    return (
        <React.Fragment>
            <div className="page-container pt-0">
                <Row>
                    <Col lg={12} xl={6}>
                        <Form.Item name="ownedBy" hidden={true}></Form.Item>
                        <Form.Item name="firstName" rules={[requiredCommonInput(t('property.owner.details.firstName.title') + t('common.isRequired'))]}>
                            <CommonInput maxLength={150} title={t('property.owner.details.firstName.title')} placeholder={t('property.owner.details.firstName.placeholder')} />
                        </Form.Item>
                        <Form.Item name="lastName" rules={[requiredCommonInput(t('property.owner.details.lastName.title') + t('common.isRequired'))]}>
                            <CommonInput maxLength={150} title={t('property.owner.details.lastName.title')} placeholder={t('property.owner.details.lastName.placeholder')} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <hr />
            <div className="page-container">
                <Row>
                    <Col lg={12} xl={6}>
                        <h5>{t('property.owner.contacts.title')}</h5>
                        <Form.Item name="address">
                            <CommonInput maxLength={150} title={t('property.owner.contacts.address.title')} placeholder={t('property.owner.contacts.address.placeholder')} />
                        </Form.Item>
                        <p className="font-weight-bold">{t('property.owner.contacts.phone.title')}</p>
                        <Form.Item name="phone">
                            <OwnPhoneInput placeholder={t('property.owner.contacts.email.placeholder')} />
                        </Form.Item>
                        <Form.Item name="email" rules={[validateEmail]}>
                            <CommonInput
                                type={'email'}
                                maxLength={150}
                                title={t('property.owner.contacts.email.title')}
                                placeholder={t('property.owner.contacts.email.placeholder')}
                            />
                        </Form.Item>
                        <Form.Item name="post">
                            <CommonInput maxLength={150} title={t('property.owner.contacts.post.title')} placeholder={t('property.owner.contacts.post.placeholder')} />
                        </Form.Item>
                        <p className="font-weight-bold">{t('property.owner.contacts.country.title')}</p>
                        <Form.Item name="country" initialValue={t('defaultCountry')}>
                            <CountrySelect placeholder={t('property.owner.contacts.country.placeholder')} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

const mapState = ({ property }) => {
    return {
        propertyOwner: get(property, 'property.owner'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValuePropertyCall(key, value) {
            dispatch(setValueProperty(key, value));
        },
    };
};

const PropertyOwnerByPerson = connect(mapState, mapDispatch)(PropertyOwnerByPersonComponent);
export default PropertyOwnerByPerson;
