import i18next from 'i18next';

export const propertyStatuses = {
    active: 1,
    consideredPurchased: 2,
    archive: 3
}

export const propertyStatusesOptions = [
    { id: propertyStatuses.active, name: () => i18next.t('property.name.active') },
    { id: propertyStatuses.consideredPurchased, name: () => i18next.t('property.name.considered') },
    { id: propertyStatuses.archive, name: () => i18next.t('property.name.archive') },
]

export const filterOptions = {
    active: 1,
    archived: 2
}