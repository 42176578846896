import React from 'react';
import { Image } from 'react-bootstrap';
import logo from '../../assets/images/logo.svg';

const Logo = () => {
    return (
        <div className="logo-container">
            <Image src={logo} className="logo" />
        </div>
    );
};

export default Logo;
