import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import ActionButton from '../../../components/action-button';
import PropertyRow from '../../../components/property-row';

const PlotReviewComponent = (props) => {
    const { t } = useTranslation();

    const goToStep = (stepKey, objectId) => {
        props.goToStep(stepKey, objectId);
    };

    const renderPlot = (plot) => {
        return (
            <div className="review-part details-info" key={plot.uId}>
                <div className="d-flex flex-row justify-content-between align-items-baseline">
                    <h5>{plot.displayName}</h5>
                    <ActionButton onClick={() => goToStep('details', plot.uId)} className="btn-link" text={t('common.buttons.edit')} />
                </div>
                {plot.name && <PropertyRow title={t('plot.review.name')} value={plot.name} />}
                <PropertyRow title={t('plot.review.municipality')} value={plot.municipality + '/' + plot.city} />
                <PropertyRow title={t('plot.review.address')} value={plot.mainAddress} />
            </div>
        );
    };

    return (
        <div className="page-container review">
            {props.hasPlots && props.plots.map((plot) => renderPlot(plot))}
            {props.plot && props.plot.id && renderPlot(props.plot)}
        </div>
    );
};

const mapState = ({ plot }) => {
    return {
        plots: get(plot, 'plots'),
        plot: get(plot, 'plot'),
        hasPlots: get(plot, 'plots.length') > 0,
    };
};

const PlotReview = connect(mapState, null)(PlotReviewComponent);
export default PlotReview;
