import { Col, Row } from 'antd';
import React, { useRef } from 'react';
import ActionButton from '../action-button';

export const actionButtonTypes = {
    primary: 'primary',
    secondary: 'secondary',
};

const ContentBlock = (props) => {
    const contentRef = useRef();

    return (
        <div className={`content-block ${props.collapsed ? 'collapsed' : ''} ${props.className}`} style={props.style}>
            <div className="title-row">
                <h5 className="mb-0">{props.name}</h5>
                <div className="actions">
                    <div
                        disabled={props.loading}
                        className={`collapse-btn btn-transparent ${props.loading && 'disabled'}`}
                        onClick={() => {
                            if (!props.loading) {
                                props.onCollapse();
                            }
                        }}></div>
                </div>
            </div>
            <div style={{ maxHeight: props.collapsed ? 0 : props.isTurnover ? 'fit-content' : (contentRef.current?.offsetHeight + 300) }} className="rent-object-wrapper">
                <div ref={contentRef} className="content-block-content mt-0">
                    {props.children}
                    <Row justify="end" className="content-block-actions m-0">
                        {props.actions?.map((action) => (
                            <Col className="ml-3">
                                <ActionButton
                                    className={`content-block-action ${action.type === actionButtonTypes.primary ? 'btn-primary' : 'btn-secondary'}`}
                                    loading={props.loading}
                                    disabled={action.disabled || props.loading}
                                    text={action.label}
                                    onClick={action.onClick}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default ContentBlock;
