import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgotPassword } from '../../reducers/newPasswordReducer';
import LoginSignUpLayout from '../../components/layouts/login-layout';
import get from 'lodash.get';
import moment from 'moment';
import routingService from '../../services/routingService';

class ForgotViewComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sentDate: null,
            canResendAgain: false,
            timer: null,
        };
    }

    setSentDate = (value) => {
        this.setState({ sentDate: value });
    };

    setCanResendAgain = (value) => {
        this.setState({ canResendAgain: value });
    };

    resendEmail = (e) => {
        e.preventDefault();
        if (this.props.email) {
            this.props.forgotPasswordCall(this.props.email).then(() => {
                this.setSentDate(moment());
                this.setCanResendAgain(false);

                const timer = setTimeout(() => {
                    this.setCanResendAgain(true);
                    this.setState({ timer: null });
                }, 1000 * 60 * 2);
                this.setState({ timer: timer });
            });
        }
    };

    componentWillUnmount() {
        if (this.state.timer) {
            clearTimeout(this.state.timer);
        }
    }

    render() {
        const t = this.props.t;

        return (
            <LoginSignUpLayout className="forgot-password" header={t('forgotPasswordPage.header')}>
                <div className="forgot-password">
                    <div className="d-flex flex-direction-row mb-3">
                        <p className="text-secondary">{t('forgotPasswordPage.subHeader')}</p>
                    </div>
                    <div className="d-flex flex-direction-row">
                        <p>
                            <span className="font-weight-bold">
                                {t('forgotPasswordPage.instructionsSentTo')} {this.props.email}.
                            </span>
                            <span className="text-secondary"> {t('forgotPasswordPage.emailHasBeenSentWithInstructions')}</span>
                        </p>
                    </div>
                    <div>
                        {this.state.sentDate ? (
                            <p className="mb-0">
                                <span className="text-secondary">{t('forgotPasswordPage.newCodeSentAt', { date: this.state.sentDate.format('HH:mm') })} </span>
                                {this.state.canResendAgain ? (
                                    <Link to="#" onClick={(e) => this.resendEmail(e)}>
                                        {t('forgotPasswordPage.resendEmail')}
                                    </Link>
                                ) : (
                                    <span className="text-secondary">{t('forgotPasswordPage.waitToSend')} </span>
                                )}
                            </p>
                        ) : (
                            <p className="mb-0">
                                <span className="text-secondary">{t('forgotPasswordPage.didntGetTheEmail')} </span>
                                <Link to="#" onClick={(e) => this.resendEmail(e)}>
                                    {t('forgotPasswordPage.resendEmail')}
                                </Link>
                            </p>
                        )}
                    </div>
                    <div>
                        <p>
                            <span className="text-secondary">{t('forgotPasswordPage.wrongEmail')} </span>
                            <Link to={routingService.forgotPasswordUrl()}>{t('forgotPasswordPage.changeYourEmail')}</Link>
                        </p>
                    </div>
                    <div>
                        <p>
                            <Link to={routingService.loginUrl()}>{t('forgotPasswordPage.backToLogin')}</Link>
                        </p>
                    </div>
                </div>
            </LoginSignUpLayout>
        );
    }
}

const mapState = ({ password }) => {
    return {
        loading: get(password, 'loading'),
        email: get(password, 'email'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        forgotPasswordCall(username) {
            return dispatch(forgotPassword(username));
        },
    };
};

const InfoView = connect(mapState, mapDispatch)(withTranslation()(ForgotViewComponent));
export default InfoView;
