import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { setValueProperty } from '../../reducers/propertyReducer';
import { deletePlot, setDeletePlotModel, setValuePlot } from '../../reducers/plotReducer';
import PlotCard from '../../components/plot/plot-card';
import SearchInput from '../../components/search-input';
import ActionButton from '../../components/action-button';
import ConfirmDeletePlot from './modals/confirm-delete-modal';
import EmptyList from '../../components/empty-list/empty-list';
import emptyItem from '../../assets/images/empty-building.svg';
import add from '../../assets/images/plus.svg';
import get from 'lodash-es/get';
import routingService from '../../services/routingService';
import { editPermisionTypes } from '../../common/constants';

const PlotsComponent = (props) => {
    const { t } = useTranslation();
    const [filteredPlots, setFilteredPlots] = useState(props.plots || []);
    const { portfolioId, propertyId } = useParams();

    useEffect(() => {
        setFilteredPlots(props.plots || []);
    }, [props.plots]);

    const openPlot = (plotId) => {
        routingService.navigateToPlotDetails(portfolioId, propertyId, plotId);
    };

    const createPlot = () => {
        if (!props.hasEditPermission) return;
        routingService.navigateToCreatePlot(portfolioId, propertyId);
    };

    const editPlot = (plot) => {
        routingService.navigateToEditPlot(portfolioId, plot.propertyId, plot.id);
    };

    const deletePlot = (plot) => {
        props.setDeletePlotModelCall(plot);
        props.showPlotDeleteModal();
    };

    const handleDeletePlot = async () => {
        const isSuccess = await props.deletePlotCall(props.deletePlotModel.id, props.deletePlotModel.propertyId, portfolioId);
        if (isSuccess) {
            const list = props.plots.filter((x) => x.id !== props.deletePlotModel.id);
            props.setValuePropertyCall('property.plots', list);
            return true;
        }
    };

    const onSearch = (filterText) => {
        if (!props.hasPlots) {
            return;
        }

        filterText = (filterText || '').toUpperCase();
        const plots = props.plots || [];
        const filteredPlots = filterText
            ? plots.filter(
                  (plot) =>
                      (plot.name && plot.name.toUpperCase().includes(filterText)) ||
                      (plot.mainAddress && plot.mainAddress.toUpperCase().includes(filterText)) ||
                      (plot.municipality && plot.municipality.toUpperCase().includes(filterText)) ||
                      (plot.city && plot.city.toUpperCase().includes(filterText))
              )
            : plots;

        setFilteredPlots(filteredPlots);
    };

    const addNewPlotBtn = (className) => (
        <ActionButton
            className={`btn-primary width-fit-content ${className ? className : ''}`}
            icon={<Image src={add} />}
            text={t('plot.btnAddNew')}
            disabled={!props.hasEditPermission || props.isArchived}
            onClick={createPlot}
        />
    );

    return (
        <div className="list">
            <div className="search-header">
                <SearchInput className="mr-3" placeholder={t('plot.searchPlaceholder')} onSearch={onSearch} />
                {addNewPlotBtn()}
            </div>
            {props.hasPlots ? (
                <div className="cards">
                    {filteredPlots.map((plot) => (
                        <PlotCard key={plot.id} plot={plot} openPlot={openPlot} editPlot={editPlot} deletePlot={deletePlot} hasEditPermission={props.hasEditPermission} isArchived={props.isArchived}/>
                    ))}
                </div>
            ) : (
                <EmptyList image={emptyItem} title={t('plot.emptyList.title')} subTitle={t('plot.emptyList.subtitle')}>
                    {addNewPlotBtn('hide-on-mobile')}
                </EmptyList>
            )}
            {props.confirmDeletePlot && <ConfirmDeletePlot handleDeletePlot={handleDeletePlot} />}
        </div>
    );
};

const mapState = ({ property, plot, navigation }) => {
    return {
        plots: get(property, 'property.plots'),
        hasPlots: get(property, 'property.plots.length') > 0,
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        confirmDeletePlot: get(plot, 'showConfirmDeleteModal'),
        deletePlotModel: get(plot, 'deletePlotModel'),
        isArchived: get(property, 'property.isArchived')
    };
};

const mapDispatch = (dispatch) => {
    return {
        showPlotDeleteModal() {
            dispatch(setValuePlot('showConfirmDeleteModal', true));
        },
        setDeletePlotModelCall(value) {
            dispatch(setDeletePlotModel(value));
        },
        setValuePropertyCall(key, value) {
            dispatch(setValueProperty(key, value));
        },
        deletePlotCall(plotId, propertyId, portfolioId) {
            return dispatch(deletePlot(plotId, propertyId, portfolioId));
        },
    };
};

const Plots = connect(mapState, mapDispatch)(PlotsComponent);
export default Plots;
