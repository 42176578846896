import { localStorageKeys } from '../common/constants';

const localStorageService = {
    getUser: (_) => {
        return JSON.parse(localStorage.getItem(localStorageKeys.userKey));
    },

    setUser: (user) => {
        localStorage.setItem(localStorageKeys.userKey, JSON.stringify(user));
    },

    clearUser: (_) => {
        localStorage.removeItem(localStorageKeys.userKey);
    },

    setIdentityData(identity) {
        localStorage.setItem(localStorageKeys.identity, JSON.stringify(identity));
    },

    getIdentityData() {
        return JSON.parse(localStorage.getItem(localStorageKeys.identity));
    },

    getLanguage: (_) => {
        return localStorage.getItem(localStorageKeys.langKey);
    },

    setLanguage: (lang) => {
        localStorage.setItem(localStorageKeys.langKey, lang);
    },

    showCommonCostNotification: (value) => {
        localStorage.setItem('showCommonCostNotification', value);
    },

    getCommonCostNotification: (_) => localStorage.getItem('showCommonCostNotification') !== 'false',
};

export default localStorageService;
