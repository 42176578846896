import { Checkbox, DatePicker, Form } from 'antd';
import get from 'lodash-es/get';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CustomContractDatePicker from '../../../../components/custom-contract-date-picker';
import { primaryDateFormat } from '../../../../components/dateFormatter/dateFormats';
import calendarIcon from '../../../../assets/images/calendar-icon.svg';

const SignatureComponent = (props) => {
    const { t } = useTranslation();
    const [sameDate, setSameDate] = useState(false);

    return (
        <div className="page-container pb-0">
            <h5 className="mb-3">{t('contract.signature.title')}</h5>
            <Row>
                <Col lg={12} xl={6}>
                    <p className="font-weight-bold">{t('contract.signatureLandLordDate.subtitle')}</p>
                    <Form.Item
                        initialValue={props.contract.signatureLandLordDate ? moment(props.contract.signatureLandLordDate).utc(true) : null}
                        name="signatureLandLordDate"
                        rules={[
                            {
                                required: true,
                                message: t('contract.signatureLandLordDate.subtitle') + t('common.isRequired'),
                            },
                        ]}>
                        <DatePicker
                                style={{ width: '50%' }}
                                suffixIcon={<Image src={calendarIcon} />}
                                allowClear={false}
                                dropdownClassName={'new-design-date-picker'}
                                format={primaryDateFormat}
                            />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <p className="font-weight-bold">{t('contract.signatureTenantDate.subtitle')}</p>
                    {sameDate ? (
                        <Form.Item
                            initialValue={props.contract.signatureLandLordDate ? moment(props.contract.signatureLandLordDate).utc(true) : null}
                            name="signatureLandLordDate"
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.signatureLandLordDate.subtitle') + t('common.isRequired'),
                                },
                            ]}>
                            <DatePicker
                                style={{ width: '50%' }}
                                disabled={sameDate}
                                suffixIcon={<Image src={calendarIcon} />}
                                allowClear={false}
                                dropdownClassName={'new-design-date-picker'}
                                format={primaryDateFormat}
                            />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            initialValue={props.contract.signatureTenantDate ? moment(props.contract.signatureTenantDate).utc(true) : null}
                            name="signatureTenantDate"
                            rules={[
                                {
                                    required: true,
                                    message: t('contract.signatureTenantDate.subtitle') + t('common.isRequired'),
                                },
                            ]}>
                            <DatePicker
                                style={{ width: '50%' }}
                                suffixIcon={<Image src={calendarIcon} />}
                                allowClear={false}
                                dropdownClassName={'new-design-date-picker'}
                                format={primaryDateFormat}
                            />
                        </Form.Item>
                    )}
                </Col>
            </Row>
            <Row>
                <Col lg={12} xl={6}>
                    <Form.Item valuePropName="checked" name="sameDateForSignature" initialValue={props.contract.sameDateForSignature ? props.contract.sameDateForSignature : null}>
                        <Checkbox onChange={(e) => setSameDate(e.target.checked)}>{t('contract.signatureTenantDate.sameDateSignature')}</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
    };
};

const Signature = connect(mapState, null)(SignatureComponent);
export default Signature;
