import { PlusOutlined } from '@ant-design/icons';
import get from 'lodash-es/get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ActionButton from '../../../../components/action-button';
import { setValueContract } from '../../../../reducers/contractReducer';
import ConfirmDeleteRentObject from '../duration-and-rental-objects/modals/remove-rent-object-modal';
import { Collapse } from 'antd';
import RentObjectSection from './rent-object-section';

const RentalObjectsCreateComponent = (props) => {
    const { t } = useTranslation();
    const [activeKeys, setActiveKeys] = useState(['0']);

    useEffect(() => {
        if (!props.hasRentObjects) {
            addAnotherRentalObject();
        }
    }, []);

    const addAnotherRentalObject = () => {
        props.setValueContractCall('rentObjects', [...props.rentObjects, { uId: uuidv4(), id: -1, newlyCreated: true }]);
        setActiveKeys([...activeKeys, props.rentObjects.length]);
    };

    return (
        <div>
            <div className={'page-container pb-0'}>
                <h5>{t('contract.durationAndRentalObjects.rentObject.title')}</h5>
                <Collapse
                    className="contract-collapse"
                    expandIcon={({ isActive }) => (isActive ? <div className="collapse-btn btn-transparent" /> : <div className="collapse-btn collapsed btn-transparent" />)}
                    activeKey={activeKeys}
                    onChange={setActiveKeys}>
                    {props.rentObjects?.map((rentObject, id) => {
                        return (
                            <Collapse.Panel
                                className="contract-collapse-item"
                                header={<h5 className="mb-0">{!rentObject?.newlyCreated ? rentObject.displayName : t('contract.durationAndRentalObjects.rentObject.newTitle')}</h5>}
                                key={id}>
                                <RentObjectSection key={rentObject.uId} rentObject={rentObject} />
                            </Collapse.Panel>
                        );
                    })}
                </Collapse>
                <ActionButton
                    className="btn-secondary mb-3"
                    icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                    onClick={addAnotherRentalObject}
                    disabled ={props.rentObjects?.some(rentObject => rentObject.newlyCreated)}
                    text={
                        props.rentObjects?.length === 0
                            ? t('contract.durationAndRentalObjects.rentObject.addNewRentalObject')
                            : t('contract.durationAndRentalObjects.rentObject.addAnotherRentalObject')
                    }
                />
            </div>
            {props.showRemoveRentObjectModal && <ConfirmDeleteRentObject />}
        </div>
    );
};

const mapState = ({ contract }) => {
    return {
        rentObject: get(contract, 'rentObject'),
        rentObjects: get(contract, 'rentObjects'),
        hasRentObjects: get(contract, 'rentObjects.length') > 0,
        showRemoveRentObjectModal: get(contract, 'showRemoveRentObjectModal'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
    };
};

const RentalObjectsCreate = connect(mapState, mapDispatch)(RentalObjectsCreateComponent);
export default RentalObjectsCreate;
