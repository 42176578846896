import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { requiredCommonInput } from '../../../common/validators';
import get from 'lodash-es/get';
import CommonInput from '../../../components/common-input';

const PortfolioNameComponent = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (props.name) {
            props.nameRef.current.setFieldsValue({ portfolioName: props.name });
        }
    }, [props.name]);

    return (
        <div className="page-container">
            <Row>
                <Col lg={12} xl={6}>
                    <h5>{t('portfolio.name.namePortfolio')}</h5>
                    <Form.Item name="portfolioName" rules={[requiredCommonInput(t('portfolio.name.message') + t('common.isRequired'))]}>
                        <CommonInput maxLength={70} title={<p><span style={{ color: '#F56A6A' }}>*</span>{t('portfolio.name.title')}</p>} placeholder={t('portfolio.name.placeholder')} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

const mapState = ({ portfolio }) => {
    return {
        name: get(portfolio, 'portfolio.name'),
    };
};

const PortfolioName = connect(mapState, null)(PortfolioNameComponent);
export default PortfolioName;
