import axios from 'axios';
import loginService from '../services/loginService';
import localStorageService from '../services/localStorageService';
import { StatusCodes } from 'http-status-codes';
import routingService from '../services/routingService';
import authService, { REFRESH_TOKEN_ENDPOINT } from './../services/authService';
import languageService from '../services/languageService';

const configureRefreshTokenInterceptor = () => {
    axios.interceptors.response.use(
        (response) => response,
        async function (error) {
            if (isTokensRelatedError(error)) {
                return await handleTokensRelatedError(error);
            }
            return Promise.reject(error);
        }
    );

    const isTokensRelatedError = (error) => {
        return (
            error.response.config.headers.Authorization &&
            error.response &&
            (error.response.status === StatusCodes.UNAUTHORIZED || error.response.config.url.endsWith(REFRESH_TOKEN_ENDPOINT))
        );
    };

    let refreshInProgress = false;
    let requestsQueue = [];

    const addRequestToQueue = (callback) => {
        requestsQueue.push(callback);
    };

    const onRefreshFinished = (accessToken) => {
        requestsQueue.forEach((requestCallback) => requestCallback(accessToken));
        requestsQueue = [];
    };

    const handleTokensRelatedError = async (error) => {
        if (!error || !error.response) {
            return Promise.reject(error);
        }
        const originalRequest = error.response.config;

        if (error.response.status !== StatusCodes.OK && originalRequest.url.endsWith(REFRESH_TOKEN_ENDPOINT)) {
            authService.logout();
            routingService.navigateToLogin(window.location.pathname);
            return Promise.reject(error);
        }

        const retryOriginalRequest = new Promise((resolve) => {
            addRequestToQueue((refreshToken) => {
                originalRequest.headers.Authorization = 'Bearer ' + refreshToken;
                resolve(axios(originalRequest));
            });
        });

        if (error.response.status === StatusCodes.UNAUTHORIZED && !refreshInProgress) {
            refreshInProgress = true;

            const identity = authService.getIdentity();
            if (!identity || !originalRequest) {
                return Promise.reject(error);
            }

            const response = await loginService.refreshToken({
                token: identity.token.accessToken,
                refreshToken: identity.token.refreshToken,
            });

            authService.updateTokens(response.data);

            originalRequest.headers['Authorization'] = 'Bearer ' + response.data.accessToken;
            refreshInProgress = false;
            onRefreshFinished(response.data.accessToken);
        }

        return retryOriginalRequest;
    };
};

const configureRequestInterceptors = () => {
    axios.interceptors.request.use(
        (request) => {
            const identity = authService.getIdentity();
            if (identity && identity.token && identity.token.accessToken && !request.headers.Authorization) {
                request.headers.Authorization = 'Bearer ' + identity.token.accessToken;
            }
            return request;
        },
        (error) => error
    );

    axios.interceptors.request.use(
        (request) => {
            const language = localStorageService.getLanguage();
            request.headers.Language = language !== 'null' ? language : languageService.getDefaultLanguage();

            return request;
        },
        (error) => error
    );
};

const applyGlobalAxiosInterceptors = function () {
    configureRequestInterceptors();
    configureRefreshTokenInterceptor();
};

export default applyGlobalAxiosInterceptors;
