import { Input, InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';
import { enterBtnCode } from '../../common/constants';
import helper from '../../helpers/helper';
import { useTranslation } from 'react-i18next';

const PositiveNumericInput = ({ defaultValue, min, inputValue, value, onChange, suffix, onWheel, className, disabled, disableFormatter, placeholder, onKeyDownCapture, avoidNull, ...rest }) => {
    const { i18n } = useTranslation();
    const [decimalSeparator, setDecimalSeparator] = useState();

    useEffect(() => {
        setDecimalSeparator(i18n.language == 'en' ? '.' : ',');
    }, [i18n.language]);
    
    const onChangeInput = (value) => {
        if (onChange) {
            if (value) {
                onChange(parseFloat(value));
            } else {
                onChange(null);
            }
        }
    };

    return (
        <div className="input-number-wrapper">
            <InputNumber
                {...rest}
                className={`${className} ant-input numeric-input`}
                value={inputValue || value}
                defaultValue={defaultValue}
                decimalSeparator={decimalSeparator}
                disabled={disabled}
                placeholder={placeholder}
                formatter={(value) => disableFormatter ? value : helper.numberWithTwoDigits(value)}
                suffix={suffix}
                onWheel={onWheel}
                onChange={!avoidNull ? onChange : onChangeInput}
                onKeyDownCapture={onKeyDownCapture}
                min={min}
                onKeyDown={(e) => (e.keyCode === enterBtnCode || e.keyCode === 189 || e.keyCode === 69 || e.keyCode === 109 || e.keyCode === 107 ? e.preventDefault() : '')}
            />
            <div className="suffix">{suffix}</div>
        </div>
    );
};

export default PositiveNumericInput;
