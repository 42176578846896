import React, { useState } from 'react';
import { Button, Divider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { contractStatusesOptions, rentObjectRentalTypeOptions, rentObjectTypeOptions, unitTypeOptions } from '../../../../common/contract-options';
import helper from '../../../../helpers/helper';
import ContentBlock from '../../../../components/content-block';
import moment from 'moment';
import UploadedFile from '../../../../components/uploaded-file';
import { connect } from 'react-redux';
import { downloadDocument, downloadFile as downloadContractFile, openPdfDocument } from '../../../../reducers/contractReducer';
import { useParams } from 'react-router';
import { pdfExtension } from '../../../../common/constants';

const { Text } = Typography;

const ContractSetupComponent = ({ contract, editContract, downloadContractFileCall, openPdfDocumentCall }) => {
    const { t, i18n } = useTranslation();

    const { portfolioId } = useParams();

    const notApplicable = 'N/A';
    const [contractSetupCollapsed, setContractSetupCollapsed] = useState(true);
    const [addedRentalCollapse, setAddedRentalCollapse] = useState(
        contract.rentObjects.map((item) => {
            return {
                id: item.id,
                collapsed: true,
            };
        })
    );
    const [leaseDuraionRentalCollapse, setleaseDuraionRentalCollapse] = useState(
        contract.rentObjects.map((item) => {
            return {
                id: item.id,
                collapsed: true,
            };
        })
    );

    const collapseLeaseDurationRent = (id) => {
        const collapsedItem = leaseDuraionRentalCollapse.find((item) => item.id == id);

        setleaseDuraionRentalCollapse((prev) => {
            return prev.map((item) => {
                if (item.id === collapsedItem.id) {
                    return {
                        id: item.id,
                        collapsed: !item.collapsed,
                    };
                } else {
                    return item;
                }
            });
        });
    };

    const collapseAddedRent = (id) => {
        const collapsedItem = addedRentalCollapse.find((item) => item.id == id);

        setAddedRentalCollapse((prev) => {
            return prev.map((item) => {
                if (item.id === collapsedItem.id) {
                    return {
                        id: item.id,
                        collapsed: !item.collapsed,
                    };
                } else {
                    return item;
                }
            });
        });
    };

    const handleDownloadContractFile = (e, file) => {
        e.preventDefault();

        if (helper.getFileExtension(file.name) == pdfExtension) {
            openPdfDocumentCall(file, file.contractId, portfolioId);
        } else {
            downloadContractFileCall(file, file.contractId, file.name, portfolioId);
        }
    };

    return (
        <div className={`contract-setup ${contractSetupCollapsed ? '' : 'contract-setup-full'}`}>
            <div className="contract-setup-header">
                <h5>{t('contract.preview.contractSetup.header')}</h5>
            </div>
            <h6>{t('contract.preview.contractSetup.basic')}</h6>
            <div className="text-row" style={{ marginTop: '30px' }}>
                <p className="title-text">{t('contract.preview.contractSetup.contractName')}</p>
                <Text ellipsis={true} className="main-text">{contract.name ? contract.name : notApplicable}</Text>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.contractSetup.contractStatus')}</p>
                <p className="main-text">{contract.status ? helper.getEnumValue(contractStatusesOptions, contract.status) : notApplicable}</p>
            </div>
            <div style={{ visibility: contractSetupCollapsed ? 'hidden' : '' }}>
                <h6>{t('contract.preview.contractSetup.uploadedFiles')}</h6>
                {contract.documents &&
                    contract.documents.map((file) => {
                        return <UploadedFile file={file} key={file.name} handleDownload={(e) => handleDownloadContractFile(e, file)} />;
                    })}
            </div>
            <Divider style={{ visibility: contractSetupCollapsed ? 'hidden' : '' }} />
            <h6 style={{ visibility: contractSetupCollapsed ? 'hidden' : '' }}>{t('contract.preview.contractSetup.addedRental')}</h6>
            {contract.rentObjects.length > 0 &&
                contract.rentObjects.map((item) => {
                    return (
                        <ContentBlock
                            collapsed={addedRentalCollapse.find((a) => a.id == item.id).collapsed}
                            onCollapse={() => collapseAddedRent(item.id)}
                            actions={[]}
                            name={`${item.name ? item.name : helper.getEnumValue(rentObjectRentalTypeOptions, item?.rentObjectRentalType)} - ${helper.numberWithSpaces(
                                item.amount
                            )}${helper.getEnumValue(unitTypeOptions, item.rentObjectUnit)}`}>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.contractSetup.rentalObjectName')}</p>
                                <Text ellipsis={true} className="main-text">{item.name ? item.name : notApplicable}</Text>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.costAndPayment.type')}</p>
                                <p className="main-text">{item.rentObjectType ? helper.getEnumValue(rentObjectTypeOptions, item.rentObjectType) : notApplicable}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.contractSetup.assignedTo')}</p>
                                <p className="main-text">{`"${
                                    item.plotId ? (item.plot?.name ? item.plot?.name : item.plot?.address) : item.building?.name ? item.building?.name : item.building?.address
                                }"`}</p>
                            </div>
                            <div className="text-row">
                                <p className="title-text">{t('contract.preview.contractSetup.floors')}</p>
                                <p className="main-text">
                                    {item.buildingId
                                        ? item.building?.floors?.length > 0
                                            ? item.building.floors.filter(floor => item.assignedFloorIds?.includes(floor.id)).map((item) => item.level).join(', ')
                                            : notApplicable
                                        : notApplicable}
                                </p>
                            </div>
                        </ContentBlock>
                    );
                })}
            <h6>{t('contract.preview.contractSetup.generalDuration')}</h6>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.contractSetup.leaseTimeline')}</p>
                <p className="main-text">
                    {contract.leaseStartDate && contract.leaseEndDate
                        ? `${moment(contract.leaseStartDate).format('DD.MM.YYYY')} - ${moment(contract.leaseEndDate).format('DD.MM.YYYY')}`
                        : notApplicable}
                </p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.contractSetup.duration')}</p>
                <p className="main-text">
                    {contract.leaseStartDate && contract.leaseEndDate
                        ? `${Math.round(moment.duration(moment(contract.leaseEndDate).diff(moment(contract.leaseStartDate))).asYears() * 10) / 10} years`
                        : notApplicable}
                </p>
            </div>
            <h6>{t('contract.preview.contractSetup.leaseDurationOption')}</h6>
            {contract.rentObjects.length > 0 &&
                contract.rentObjects.map((item, index) => {
                    return (
                        item.leaseStartDate &&
                        item.leaseEndDate && (
                            <ContentBlock
                                collapsed={leaseDuraionRentalCollapse.find((a) => a.id == item.id).collapsed}
                                onCollapse={() => collapseLeaseDurationRent(item.id)}
                                actions={[]}
                                style={{ marginBottom: index === contract.rentObjects.length - 1 ? '40px' : '15px' }}
                                name={`${item.name ? item.name : helper.getEnumValue(rentObjectRentalTypeOptions, item?.rentObjectRentalType)} - ${helper.numberWithSpaces(
                                    item.amount
                                )}${helper.getEnumValue(unitTypeOptions, item.rentObjectUnit)}`}>
                                <div className="text-row">
                                    <p className="title-text">{t('contract.preview.contractSetup.leaseTimeline')}</p>
                                    <p className="main-text">
                                        {item.leaseStartDate && item.leaseEndDate
                                            ? `${moment(item.leaseStartDate).format('DD.MM.YYYY')} - ${moment(item.leaseEndDate).add('day', 1).format('DD.MM.YYYY')}`
                                            : notApplicable}
                                    </p>
                                </div>
                                <div className="text-row">
                                    <p className="title-text">{t('contract.preview.contractSetup.duration')}</p>
                                    <p className="main-text">
                                        {item.leaseStartDate && item.leaseEndDate
                                            ? `${Math.round(moment.duration(moment(item.leaseEndDate).diff(moment(item.leaseStartDate))).asYears() * 10) / 10} years`
                                            : notApplicable}
                                    </p>
                                </div>
                            </ContentBlock>
                        )
                    );
                })}
            <Button
                type="primary"
                className="contract-expand-btn"
                onClick={() => {
                    setContractSetupCollapsed((prev) => !prev);
                }}>
                {contractSetupCollapsed ? t('contract.preview.contractSetup.expandButton') : t('contract.preview.contractSetup.collapseButton')}
            </Button>
        </div>
    );
};

const mapDispatch = (dispatch) => {
    return {
        downloadContractFileCall(file, contractId, name, portfolioId) {
            return dispatch(downloadDocument(file, contractId, name, portfolioId));
        },
        getContractDownloadLinkCall(file, contractId) {
            return dispatch(downloadContractFile(file, contractId));
        },
        openPdfDocumentCall(document, contractId, portfolioId) {
            return dispatch(openPdfDocument(document, contractId, portfolioId));
        },
    };
};

const ContractSetup = connect(null, mapDispatch)(ContractSetupComponent);
export default ContractSetup;
