import React, { useEffect, useState } from 'react';
import { rentObjectRentalTypeOptions, unitTypeOptions, warrantyTypeOptions } from '../../../../common/contract-options';
import helper from '../../../../helpers/helper';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'antd';
import ContentBlock from '../../../../components/content-block';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';
import { contractStepNames } from '../../create';
import { downloadWarrantyDocument, downloadWarrantyFile, openWarrantyPdfDocument } from '../../../../reducers/contractReducer';
import { connect } from 'react-redux';
import UploadedFile from '../../../../components/uploaded-file';
import { useParams } from 'react-router';
import { pdfExtension } from '../../../../common/constants';

const AdvancedOptionsComponent = ({ contract, editContract, openWarrantyPdfDocumentCall, downloadWarrantyFileCall }) => {
    const { t, i18n } = useTranslation();
    const notApplicable = 'N/A';
    const { portfolioId, propertyId, contractId } = useParams();
    const [warrantyContract, setWarrantyContract] = useState();
    const [advancedCollapsed, setAdvancedCollapsed] = useState(true);
    const [rentalWarrantiesCollapse, setRentalWarrantiesCollapse] = useState(
        contract.contractWarranties
            .filter((item) => item.rentObjectId !== null)
            .map((item) => {
                return {
                    id: item.id,
                    collapsed: true,
                };
            })
    );

    const collapseRentalWarranties = (id) => {
        const collapsedItem = rentalWarrantiesCollapse.find((item) => item.id == id);

        setRentalWarrantiesCollapse((prev) => {
            return prev.map((item) => {
                if (item.id === collapsedItem.id) {
                    return {
                        id: item.id,
                        collapsed: !item.collapsed,
                    };
                } else {
                    return item;
                }
            });
        });
    };

    useEffect(() => {
        setWarrantyContract(contract.contractWarranties.find((item) => item.rentObjectId == null));
    }, []);

    const handleDownloadWarrantyFile = (e, file) => {
        e.preventDefault();

        if (helper.getFileExtension(file.name) == pdfExtension) {
            openWarrantyPdfDocumentCall(file, contractId, portfolioId, propertyId);
        } else {
            downloadWarrantyFileCall(file, contractId, file.name, portfolioId, propertyId);
        }
    };

    return (
        <div className={`advanced-options ${advancedCollapsed ? '' : 'advanced-options-full'}`}>
            <div className="contract-setup-header">
                <h5>{t('contract.preview.advancedOptions.header')}</h5>
            </div>
            <h6>{t('contract.preview.advancedOptions.warranty')}</h6>
            <div className="text-row mt-4">
                <p className="title-text">{t('contract.preview.advancedOptions.hasEventOnHandoverDueDate')}</p>
                <p className="main-text">{warrantyContract?.hasEventOnHandoverDueDate ? t('common.yes') : t('common.no')}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.advancedOptions.hasAnnualTenantReminderAdjustmentCpiRegulation')}</p>
                <p className="main-text">{warrantyContract?.hasAnnualTenantReminderAdjustmentCpiRegulation ? t('common.yes') : t('common.no')}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.advancedOptions.warrantyType')}</p>
                <p className="main-text">{warrantyContract?.warrantyTypeId ? helper.getEnumValue(warrantyTypeOptions, warrantyContract?.warrantyTypeId) : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.advancedOptions.warrantyValue')}</p>
                <p className="main-text">{warrantyContract?.warrantyValue ? `${helper.numberWithSpaces(warrantyContract.warrantyValue)} NOK` : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.advancedOptions.hasCpiRegulateWarrantyValue')}</p>
                <p className="main-text">{warrantyContract?.hasCpiRegulateWarrantyValue ? t('common.yes') : t('common.no')}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.advancedOptions.warrantyHandoverDate')}</p>
                <p className="main-text">{warrantyContract?.warrantyHandoverDate ? moment(warrantyContract?.warrantyHandoverDate).format('DD.MM.YYYY') : notApplicable}</p>
            </div>
            <div className="text-row">
                <p className="title-text">{t('contract.preview.advancedOptions.warrantyExpiryDate')}</p>
                <p className="main-text">{warrantyContract?.warrantyExpiryDate ? moment(warrantyContract?.warrantyExpiryDate).format('DD.MM.YYYY') : notApplicable}</p>
            </div>
            <div style={{ visibility: advancedCollapsed ? 'hidden' : '' }}>
                <h6>{t('contract.preview.contractSetup.uploadedFiles')}</h6>
                {warrantyContract?.documents &&
                    warrantyContract?.documents.map((file) => {
                        return <UploadedFile file={file} key={file.name} handleDownload={(e) => handleDownloadWarrantyFile(e, file)} />;
                    })}
            </div>
            <Divider style={{ visibility: advancedCollapsed ? 'hidden' : '' }} />
            <h6 style={{ visibility: advancedCollapsed ? 'hidden' : '' }}>{t('contract.preview.advancedOptions.warrantyForIndividual')}</h6>
            {contract.contractWarranties.filter((item) => item.rentObjectId !== null).length > 0 &&
                contract.contractWarranties
                    .filter((item) => item.rentObjectId !== null)
                    .map((item) => {
                        const rentObject = contract.rentObjects.find((rent) => rent.id == item.rentObjectId);
                        return (
                            <ContentBlock
                                collapsed={rentalWarrantiesCollapse.find((a) => a.id == item.id).collapsed}
                                onCollapse={() => collapseRentalWarranties(item.id)}
                                actions={[]}
                                name={`${rentObject.name ? rentObject.name : helper.getEnumValue(rentObjectRentalTypeOptions, rentObject?.rentObjectRentalType)
                                    } - ${helper.numberWithSpaces(rentObject.amount)}${helper.getEnumValue(unitTypeOptions, rentObject.rentObjectUnit)}`}>
                                <div className="text-row">
                                    <p className="title-text">{t('contract.preview.advancedOptions.hasEventOnHandoverDueDate')}</p>
                                    <p className="main-text">{item?.hasEventOnHandoverDueDate ? t('common.yes') : t('common.no')}</p>
                                </div>
                                <div className="text-row">
                                    <p className="title-text">{t('contract.preview.advancedOptions.hasAnnualTenantReminderAdjustmentCpiRegulation')}</p>
                                    <p className="main-text">{item?.hasAnnualTenantReminderAdjustmentCpiRegulation ? t('common.yes') : t('common.no')}</p>
                                </div>
                                <div className="text-row">
                                    <p className="title-text">{t('contract.preview.advancedOptions.warrantyType')}</p>
                                    <p className="main-text">{item?.warrantyTypeId ? helper.getEnumValue(warrantyTypeOptions, item?.warrantyTypeId) : notApplicable}</p>
                                </div>
                                <div className="text-row">
                                    <p className="title-text">{t('contract.preview.advancedOptions.warrantyValue')}</p>
                                    <p className="main-text">{item?.warrantyValue ? `${helper.numberWithSpaces(item.warrantyValue)} NOK` : notApplicable}</p>
                                </div>
                                <div className="text-row">
                                    <p className="title-text">{t('contract.preview.advancedOptions.hasCpiRegulateWarrantyValue')}</p>
                                    <p className="main-text">{item?.hasCpiRegulateWarrantyValue ? t('common.yes') : t('common.no')}</p>
                                </div>
                                <div className="text-row">
                                    <p className="title-text">{t('contract.preview.advancedOptions.warrantyHandoverDate')}</p>
                                    <p className="main-text">{item?.warrantyHandoverDate ? moment(item?.warrantyHandoverDate).format('DD.MM.YYYY') : notApplicable}</p>
                                </div>
                                <div className="text-row">
                                    <p className="title-text">{t('contract.preview.advancedOptions.warrantyExpiryDate')}</p>
                                    <p className="main-text">{item?.warrantyExpiryDate ? moment(item?.warrantyExpiryDate).format('DD.MM.YYYY') : notApplicable}</p>
                                </div>
                                <div>
                                    <h6>{t('contract.preview.contractSetup.uploadedFiles')}</h6>
                                    {item?.documents &&
                                        item?.documents.map((file) => {
                                            return <UploadedFile file={file} key={file.name} handleDownload={(e) => handleDownloadWarrantyFile(e, file)} />;
                                        })}
                                </div>
                            </ContentBlock>
                        );
                    })}
            <Divider />
            <h6>{t('contract.preview.advancedOptions.signature')}</h6>
            <div className="text-row mt-4">
                <p className="title-text">{t('contract.signatureLandLordDate.subtitle')}</p>
                <p className="main-text">{contract?.signatureLandLordDate ? moment(contract?.signatureLandLordDate).format('DD.MM.YYYY') : notApplicable}</p>
            </div>
            <div className="text-row mt-2 mb-5">
                <p className="title-text">{t('contract.signatureTenantDate.subtitle')}</p>
                <p className="main-text">{contract?.signatureTenantDate ? moment(contract?.signatureTenantDate).format('DD.MM.YYYY') : notApplicable}</p>
            </div>
            <Button type="primary" className="contract-expand-btn" onClick={() => setAdvancedCollapsed((prev) => !prev)}>
                {advancedCollapsed ? t('contract.preview.advancedOptions.expandButton') : t('contract.preview.advancedOptions.collapseButton')}
            </Button>
        </div>
    );
};

const mapDispatch = (dispatch) => {
    return {
        downloadWarrantyFileCall(file, contractId, name, portfolioId, propertyId) {
            return dispatch(downloadWarrantyDocument(file, contractId, name, portfolioId, propertyId));
        },
        openWarrantyPdfDocumentCall(document, contractId, portfolioId, propertyId) {
            return dispatch(openWarrantyPdfDocument(document, contractId, portfolioId, propertyId));
        },
    };
};

const AdvancedOptions = connect(null, mapDispatch)(AdvancedOptionsComponent);
export default AdvancedOptions;
