import React from 'react';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import uuidV4 from 'uuid/v4';
import warning from '../../assets/images/warning.svg';

const TooltipBtn = ({ placement = 'top', children, title }) => {
    return (
        <React.Fragment>
            {title ? (
                <OverlayTrigger
                    delay={{ show: 200, hide: 150 }}
                    placement={window.innerWidth > 590 ? placement : 'top'}
                    flip={true}
                    overlay={
                        <Tooltip className="btn-tooltip" id={`tooltip-${uuidV4()}`}>
                            <div className="d-flex flex-row">
                                <Image src={warning} />
                                {title && <p className="tooltip-text">{title}</p>}
                            </div>
                        </Tooltip>
                    }>
                    {children}
                </OverlayTrigger>
            ) : (
                children
            )}
        </React.Fragment>
    );
};
export default TooltipBtn;
