import { Form, Input } from 'antd';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../components/action-button';

const NewTranslationRow = ({ loading, onSave }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const handleAddRowClick = () => {
        form.validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                onSave(values).then((isSuccess) => {
                    isSuccess && form.resetFields();
                });
            })
            .catch((_) => {});
    };

    return (
        <Form form={form} name="basic" autoComplete="off">
            <Row>
                <Col>
                    <Form.Item
                        className="m-0"
                        name="key"
                        rules={[
                            {
                                required: true,
                                message: t('translationManager.key') + t('common.isRequired'),
                            },
                        ]}>
                        <Input maxLength={128} placeholder={t('translationManager.enterKey')} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        className="m-0"
                        name="sourceLanguageValue"
                        rules={[
                            {
                                required: true,
                                message: t('translationManager.value') + t('common.isRequired'),
                            },
                        ]}>
                        <Input placeholder={t('translationManager.enterValue')} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item className="m-0" name="destinationLanguageValue">
                        <Input placeholder={t('translationManager.enterTranslation')} />
                    </Form.Item>
                </Col>
                <Col className="remove-icon-col"></Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <ActionButton className="btn-primary-m mt-3 mb-3" text={t('translationManager.addItem')} loading={loading} onClick={handleAddRowClick} />
                </Col>
                <Col className="remove-icon-col"></Col>
            </Row>
        </Form>
    );
};

export default NewTranslationRow;
