import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { changePassword } from '../../reducers/newPasswordReducer';
import LoginSignUpLayout from '../../components/layouts/login-layout';
import Password from '../../components/password-form';
import queryString from 'query-string';
import clear from '../../common/clear';
import get from 'lodash.get';

const NewPasswordView = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onSubmit = () => {
        form.validateFields()
            .then(() => {
                var parsed = queryString.parse(window.location.search);
                var values = form.getFieldsValue();
                props.changePasswordCall({ password: values.password.trim(), resetToken: parsed.token.replaceAll(' ', '+'), userId: parsed.userId });
            })
            .catch((_) => {});
    };

    return (
        <LoginSignUpLayout header={t('createNewPasswordPage.header')}>
            <div className="forgot-password">
                <Form form={form} name="basic" className="mt-4" autoComplete="off">
                    <Password passwordIsRequired={true} submitBtnText={t('createNewPasswordPage.form.submitBtnText')} form={form} onSubmit={onSubmit} loading={props.loading} />
                </Form>
            </div>
        </LoginSignUpLayout>
    );
};

const mapState = ({ password }) => {
    return {
        loading: get(password, 'loading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        changePasswordCall(values) {
            dispatch(changePassword(values));
        },
    };
};

const ChangePassword = clear(connect(mapState, mapDispatch)(NewPasswordView));
export default ChangePassword;
