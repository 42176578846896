import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import BreakdownComponent from './breakdown';
import CommonCostDetailsRightSideComponents from '../right-side-components';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { getCommonCostCalculations } from '../../../../reducers/commonCostReducer';

const CommonCostAnalysis = () => {
    const { portfolioId, propertyId, commonCostId } = useParams();
    const dispatch = useDispatch();
    const getCommonCostCalculationsCall = (portfolioId, propertyId, commonCostId) => dispatch(getCommonCostCalculations(portfolioId, propertyId, commonCostId));

    useEffect(() => {
        getCommonCostCalculationsCall(portfolioId, propertyId, commonCostId);
    }, []);

    return (
        <Row className="pb-3 analysis-page">
            <Col sm={12} lg={8}>
                <BreakdownComponent />
            </Col>
            <CommonCostDetailsRightSideComponents />
        </Row>
    );
};

export default CommonCostAnalysis;
