import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { getPortfolioKpis } from '../../reducers/portfolioReducer';
import clear from '../../common/clear';
import get from 'lodash-es/get';
import MainLayout from '../../components/layouts/main-layout';
import NotFoundPage from '../../components/error-page';
import Events from '../../components/events';
import AnalysisTab from './analysis-tab';
import LoaderSpinner from '../../components/spinner';
import routingService from '../../services/routingService';
import localStorageService from '../../services/localStorageService';

const { TabPane } = Tabs;

export const portfolioDashboardTabs = {
    analysis: '1',
    events: '2',
    getKeyName: function (value) {
        return Object.keys(this).find((key) => this[key] === value) || 'analysis';
    },
};

const PortfolioDashboardComponent = (props) => {
    const { t } = useTranslation();
    const { portfolioId } = useParams();
    const [currentTabKey, setCurrentTabKey] = useState(portfolioDashboardTabs.analysis);

    useEffect(() => {
        if (portfolioId) {
            props.getKpis(portfolioId);
        } else {
            routingService.navigateToPortfolios();
        }

        const params = new URLSearchParams(decodeURI(props.location.search));
        const selectedTab = portfolioDashboardTabs[params.has('tab') ? params.get('tab') : ''] || portfolioDashboardTabs.analysis;
        if (selectedTab && currentTabKey !== selectedTab) {
            setCurrentTabKey(selectedTab);
        }
    }, [portfolioId]);
    
    const changeTab = (id) => {
        setCurrentTabKey(id);

        const params = new URLSearchParams(props.location.search);
        const selectedTabName = portfolioDashboardTabs.getKeyName(id);
        params.set('tab', selectedTabName);
        routingService.replace('?' + params.toString());
    };

    return (
        <MainLayout {...props}>
            {props.portfolioNotFound ? <NotFoundPage header={t('portfolio.notFound')} /> : null}
            {!props.portfolioNotFound && !props.kpisLoading && props.kpis ? (
                <div className="d-flex flex-column h-100">
                    <div className="page-title">{`${t('portfolio.dashboard.pageTitle')} - ${props.portfolios.find(item => item.id == portfolioId)?.name}`}</div>
                    <Tabs activeKey={currentTabKey} onChange={changeTab}>
                        <TabPane tab={t('portfolio.dashboard.analysis')} key={portfolioDashboardTabs.analysis}>
                            <AnalysisTab />
                        </TabPane>
                        <TabPane tab={t('events.header')} key={portfolioDashboardTabs.events}>
                            <Events />
                        </TabPane>
                    </Tabs>
                </div>
            ) : (
                <LoaderSpinner />
            )}
        </MainLayout>
    );
};

const mapState = ({ portfolio, navigation }) => {
    return {
        kpis: get(portfolio, 'kpis'),
        kpisLoading: get(portfolio, 'kpisLoading'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
        portfolios: get(navigation, 'navigationPortfolios'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        getKpis(portfolioId, propertyId) {
            dispatch(getPortfolioKpis(portfolioId, propertyId));
        },
    };
};

const PortfolioDashboard = clear(connect(mapState, mapDispatch)(PortfolioDashboardComponent));
export default PortfolioDashboard;
