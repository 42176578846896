import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clear from '../../../common/clear';
import MainLayout from '../../../components/layouts/main-layout';
import { getUsers, setValueBackoffice } from '../../../reducers/backofficeReducer';
import { clearInvoicesPropertyId, setInvoicesPropertyId } from '../../../reducers/invoiceReducer';
import Invoices from '../invoices';
import Users from './users';

const BackOfficeUsersComponent = (props) => {
    useEffect(() => {
        props.getUsersCall().then((response) => {
            const users = response.data;
            props.setValueBackofficeCall('users', users || []);
            props.setValueBackofficeCall('totalUsersCount', users.length);
            const totalPropertiesCount = users.map((x) => x.properties.length).reduce((a, b) => a + b);
            props.setValueBackofficeCall('totalPropertiesCount', totalPropertiesCount);
        });
    }, []);

    const pagesKeys = {
        users: 'users',
        invoices: 'invoices',
    };

    const [currentPageKey, setCurrentPageKey] = useState(pagesKeys.users);

    const goBackFromInvoices = () => {
        props.clearInvoicesPropertyIdCall();
        setCurrentPageKey(pagesKeys.users);
    };

    const showInvoices = (propertyId) => {
        props.setInvoicesPropertyIdCall(propertyId);
        setCurrentPageKey(pagesKeys.invoices);
    };

    const pages = [
        {
            key: pagesKeys.users,
            page: <Users showInvoices={showInvoices} />,
        },
        {
            key: pagesKeys.invoices,
            page: <Invoices goBack={goBackFromInvoices} />,
        },
    ];

    const currentPage = pages.find((x) => x.key === currentPageKey);

    return <MainLayout>{currentPage.page}</MainLayout>;
};

const mapDispatch = (dispatch) => {
    return {
        setInvoicesPropertyIdCall(propertyId) {
            dispatch(setInvoicesPropertyId(propertyId));
        },
        clearInvoicesPropertyIdCall() {
            dispatch(clearInvoicesPropertyId());
        },
        getUsersCall() {
            return dispatch(getUsers());
        },
        setValueBackofficeCall(key, value) {
            dispatch(setValueBackoffice(key, value));
        },
    };
};

const BackOfficeUsers = clear(connect(null, mapDispatch)(BackOfficeUsersComponent));
export default BackOfficeUsers;
