import React, { useState } from 'react';
import { minimumRentTypes, rentObjectRentalTypeOptions, turnoverInvoiceTypes, unitTypeOptions } from '../../../../common/contract-options';
import ContentBlock, { actionButtonTypes } from '../../../../components/content-block';
import helper from '../../../../helpers/helper';
import { Form } from 'antd';
import MinimumRentYearly from './minimum-rent-yearly';
import AscendingMinimumRent from './ascending-minimum-rent';
import TurnoverRate from './turnover-rate';
import ThesholdAmount from './threshold-amount';
import TurnoverStatemant from './turnover-statemant';
import Invoicing from './invoicing';
import { connect } from 'react-redux';
import { setValueContract } from '../../../../reducers/contractReducer';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TurnoverRentComponent = (props) => {
    const { rentObject, contract } = props;

    const { t } = useTranslation();

    const [minimumRentYearlyForm] = Form.useForm();
    const [ascendingMinimumRentForm] = Form.useForm();
    const [turnoverRateForm] = Form.useForm();
    const [tesholdAmountForm] = Form.useForm();
    const [invoicingForm] = Form.useForm();
    const [statementForm] = Form.useForm();

    const [minimumRent, setMinimumRent] = useState(
        rentObject?.turnover?.minimunRentYearly?.annuallyPerUnit
            ? rentObject?.turnover?.minimunRentYearly?.annuallyPerUnit
            : rentObject?.turnover?.minimunRentYearly?.totalAnnualAmount
    );
    const [turnoverCollapsed, setTurnoverCollapsed] = useState(rentObject?.turnover);
    const [isMinimumYearlyOn, setIsMinimumYearlyOn] = useState(rentObject?.turnover?.minimunRentYearlyId ? true : false);
    const [isAscendingMinimumOn, setIsAscendingMinimumOn] = useState(rentObject?.turnover?.ascendingMinimumRents?.length ? true : false);
    const [turnoverRateLabels, setTurnoverRateLabels] = useState([]);
    const [isThesholdAmountOn, setIsThesholdAmountOn] = useState(rentObject?.turnover?.maximunRentYearlyId ? true : false);
    const [isStatemant, setIsStatemant] = useState(rentObject?.turnover?.auditStatemantId ? true : false);
    const [turnoverRateTabActiveKey, setTurnoverRateTabActiveKey] = useState('0');

    const validateTurnover = async (id) => {
        const turnover = { rentObjectId: id };

        if (isMinimumYearlyOn) {
            const minimumYear = await minimumRentYearlyForm.validateFields();
            turnover.minimunRentYearly = {
                [minimumYear.minimumRentType == minimumRentTypes.annuallyPerUnit ? 'annuallyPerUnit' : 'totalAnnualAmount']: minimumYear.minimumRentValue,
            };
        }

        if (isAscendingMinimumOn) {
            const ascendingMinimum = await ascendingMinimumRentForm.validateFields();
            turnover.ascendingMinimumRents = ascendingMinimum.ascendingMinimumRents.map((item) => {
                return {
                    annuallyPerUnit: item.annuallyPerUnit,
                    intervalFrom: item.intervalDates[0].utc(true),
                    intervalTo: item.intervalDates[1].utc(true),
                };
            });
        }
        let turnoverRate; 
        try {
            turnoverRate = await turnoverRateForm.validateFields();
        } catch (err) {
            const validatedFieldTabKey = err?.errorFields[0]?.name[1];
            if (validatedFieldTabKey) {
                setTurnoverRateTabActiveKey(validatedFieldTabKey.toString());   
            }
            throw err;
        }
        turnover.turnoverRates = turnoverRate.turnoverRates;

        if (isThesholdAmountOn) {
            const thesholdAmount = await tesholdAmountForm.validateFields();
            turnover.maximunRentYearly = {
                [thesholdAmount.thesholdAmountType == minimumRentTypes.annuallyPerUnit ? 'annuallyPerUnit' : 'totalAnnualAmount']: thesholdAmount.thesholdAmountValue,
            };
        }

        const invoicing = await invoicingForm.validateFields();

        if (invoicing.turnoverInvoiceTypeId != turnoverInvoiceTypes.minimumRent) {
            invoicing.arrearsBased.turnoverRecurrenceTypeId = 1;
        }

        turnover.turnoverInvoice = invoicing;

        if (isStatemant) {
            const statemant = await statementForm.validateFields();

            statemant.turnoverRecurrenceTypeId = 1;
            turnover.auditStatemant = statemant;
        }

        props.setValueContractCall('turnovers', [...props.turnovers.filter((item) => item.rentObjectId != turnover.rentObjectId), turnover]);
    };

    return (
        <ContentBlock
            collapsed={turnoverCollapsed}
            isTurnover={true}
            style={{ maxHeight: 'fit-content' }}
            onCollapse={() => setTurnoverCollapsed((prev) => !prev)}
            actions={[
                {
                    type: actionButtonTypes.primary,
                    label: t('common.buttons.done'),
                    onClick: async () => {
                        await validateTurnover(props.rentObject.id);
                        setTurnoverCollapsed(true);
                    },
                },
            ]}
            className="trunover-content-block"
            name={`${helper.getEnumValue(rentObjectRentalTypeOptions, rentObject?.rentObjectRentalType)} - ${rentObject.amount}${helper.getEnumValue(
                unitTypeOptions,
                rentObject.rentObjectUnit
            )}`}>
            <>
                <Form form={minimumRentYearlyForm}>
                    <MinimumRentYearly
                        minimumRentValue={minimumRent}
                        amount={rentObject.amount}
                        minimumRentYearlyForm={minimumRentYearlyForm}
                        isMinimumYearlyOn={isMinimumYearlyOn}
                        setMinimumRent={setMinimumRent}
                        setIsMinimumYearlyOn={setIsMinimumYearlyOn}
                        setIsAscendingMinimumOn={setIsAscendingMinimumOn}
                        minimumRent={rentObject?.turnover?.minimunRentYearly}
                    />
                </Form>
                <Form
                    form={ascendingMinimumRentForm}
                    initialValues={{
                        ascendingMinimumRents: rentObject?.turnover?.ascendingMinimumRents?.map((item) => {
                            return {
                                annuallyPerUnit: item.annuallyPerUnit,
                                intervalDates: [moment(item.intervalFrom).utc(true), moment(item.intervalTo).utc(true)],
                            };
                        }),
                    }}>
                    <AscendingMinimumRent
                        minimumRent={minimumRent}
                        ascendingMinimumRentForm={ascendingMinimumRentForm}
                        minimumRentYearlyForm={minimumRentYearlyForm}
                        isAscendingMinimumOn={isAscendingMinimumOn}
                        setIsAscendingMinimumOn={setIsAscendingMinimumOn}
                        leaseStartDate={props.rentObject?.hasCustomDuration ? rentObject?.leaseStartDate :  contract?.leaseStartDate}
                        leaseEndDate={props.rentObject?.hasCustomDuration ? rentObject?.leaseEndDate : contract?.leaseEndDate}
                        isMinimumYearlyOn={isMinimumYearlyOn}
                    />
                </Form>
                <Form form={turnoverRateForm} initialValues={{ turnoverRates: rentObject?.turnover?.turnoverRates }}>
                    <TurnoverRate
                        rentObject={props.rentObject}
                        turnoverRateTabActiveKey={turnoverRateTabActiveKey}
                        setTurnoverRateTabActiveKey={setTurnoverRateTabActiveKey}
                        turnoverRateLabels={turnoverRateLabels}
                        setTurnoverRateLabels={setTurnoverRateLabels}
                        turnoverRateForm={turnoverRateForm}
                        turnoverRates={rentObject?.turnover?.turnoverRates}
                    />
                </Form>
                <Form form={tesholdAmountForm}>
                    <ThesholdAmount isThesholdAmountOn={isThesholdAmountOn} setIsThesholdAmountOn={setIsThesholdAmountOn} maximumRent={rentObject?.turnover?.maximunRentYearly} />
                </Form>
                <Form form={invoicingForm} initialValues={rentObject?.turnover?.turnoverInvoice}>
                    <Invoicing
                        minimumRentValue={minimumRent}
                        amount={rentObject.amount}
                        turnover={rentObject?.turnover}
                        invoiceType={rentObject?.turnover?.turnoverInvoice?.turnoverInvoiceTypeId}
                        periodType={rentObject?.turnover?.turnoverInvoice?.arrearsBased?.turnoverRecurrencePeriodTypeId}
                    />
                </Form>
                <Form form={statementForm} initialValues={rentObject?.turnover?.auditStatemant}>
                    <TurnoverStatemant
                        isStatemant={isStatemant}
                        setIsStatemant={setIsStatemant}
                        turnover={rentObject?.turnover}
                        periodType={rentObject?.turnover?.auditStatemant?.turnoverRecurrencePeriodTypeId}
                    />
                </Form>
            </>
        </ContentBlock>
    );
};

const mapState = ({ contract }) => {
    return {
        contract: get(contract, 'contract'),
        rentObjects: get(contract, 'rentObjects'),
        turnovers: get(contract, 'turnovers'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueContractCall(key, value) {
            dispatch(setValueContract(key, value));
        },
    };
};

const TurnoverRent = connect(mapState, mapDispatch)(TurnoverRentComponent);
export default TurnoverRent;
