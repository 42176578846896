import { Col, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import helper from '../../../helpers/helper';
import { emptyMark } from './rent-roll-table';

const { Text } = Typography;

const RentRollFooter = (props) => {
    const { t } = useTranslation();

    const { rentObjectsTotal } = props;

    const getRowCell = (value, mark, suffix = '', cellClassName = '') => {
        if (!value) {
            return <Text className="rent-object-cell">{mark}</Text>;
        }

        return (
            <Text className={`rent-object-cell ${cellClassName}`}>
                {helper.numberWithSpaces(value?.toFixed(1))}
                {suffix}
            </Text>
        );
    };

    return (
        <Row className="rent-roll-total-section" gutter={[8, 8]}>
            <Col span={11} className="rent-roll-total-left-part">
                <Row>
                    <Col offset={1}>
                        <Text className="rent-object-cell">
                            {t('rentRoll.totalSection.running')} <span className="secondary-text">{t('rentRoll.totalSection.activeOnly')}</span>{' '}
                            {moment().format(primaryDateFormat)}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col offset={10} span={3}>
                        {t('rentRoll.totalSection.m2')}
                    </Col>
                    <Col span={3}>{t('rentRoll.totalSection.units')}</Col>
                    <Col span={4}>{t('rentRoll.totalSection.rentM2')}</Col>
                    <Col span={4}>{t('rentRoll.totalSection.rentUnits')}</Col>
                </Row>
                <Row>
                    <Col span={9} offset={1}>
                        {t('rentRoll.totalSection.currentTotal')}
                    </Col>
                    <Col span={3}>{getRowCell(rentObjectsTotal?.currentTotalUnits?.squareMeters, emptyMark.hyphen)}</Col>
                    <Col span={3}>{getRowCell(rentObjectsTotal?.currentTotalUnits?.units, emptyMark.hyphen)}</Col>
                    <Col span={4}>{getRowCell(rentObjectsTotal?.currentTotalRented?.squareMeters, emptyMark.hyphen)}</Col>
                    <Col span={4}>{getRowCell(rentObjectsTotal?.currentTotalRented?.units, emptyMark.hyphen)}</Col>
                </Row>
                <Row>
                    <Col span={9} offset={1}>
                        {t('rentRoll.totalSection.vacantBta')}
                    </Col>
                    <Col span={3}>{getRowCell(rentObjectsTotal?.vacancy?.vacancy, emptyMark.hyphen)}</Col>
                    <Col span={3}>{getRowCell(null, emptyMark.hyphen)}</Col>
                    <Col span={4}>{getRowCell(null, emptyMark.hyphen)}</Col>
                    <Col span={4}>{getRowCell(null, emptyMark.hyphen)}</Col>
                </Row>
                <Row>
                    <Col span={9} offset={1}>
                        {t('rentRoll.totalSection.TotalBta')}
                    </Col>
                    <Col span={3}>{getRowCell(rentObjectsTotal?.currentTotalUnits?.squareMeters + rentObjectsTotal?.vacancy?.vacancy, emptyMark.hyphen)}</Col>
                    <Col span={3}>{getRowCell(null, emptyMark.hyphen)}</Col>
                    <Col span={4}>{getRowCell(null, emptyMark.hyphen)}</Col>
                    <Col span={4}>{getRowCell(null, emptyMark.hyphen)}</Col>
                </Row>
            </Col>
            <Col span={13} className="rent-roll-total-right-part" style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <Row className="row-with-divider">
                    <Col offset={4} span={4}>
                        {t('rentRoll.totalSection.rent2023')}
                    </Col>
                    <Col span={4}>{t('rentRoll.totalSection.contractedYerlyRent')}</Col>
                    <Col span={4} offset={1}>
                        {t('rentRoll.totalSection.wault')}
                    </Col>
                </Row>
                <Row>
                    <Col span={3} offset={1}>
                        {t('rentRoll.totalSection.m2')}
                    </Col>
                    <Col span={4}>{getRowCell(rentObjectsTotal?.rentByYears?.squareMeters, emptyMark.hyphen)}</Col>
                    <Col span={4}>{getRowCell(rentObjectsTotal?.contractedRentByYears?.squareMeters, emptyMark.hyphen)}</Col>
                    <Col span={4} offset={1}>
                        {getRowCell(rentObjectsTotal?.wault, emptyMark.hyphen, ' years')}
                    </Col>
                </Row>
                <Row className="row-with-divider">
                    <Col span={3} offset={1}>
                        {t('rentRoll.totalSection.units')}
                    </Col>
                    <Col span={4}>{getRowCell(rentObjectsTotal?.rentByYears?.units, emptyMark.hyphen)}</Col>
                    <Col span={4}>{getRowCell(rentObjectsTotal?.contractedRentByYears?.units, emptyMark.hyphen)}</Col>
                </Row>
                <Row>
                    <Col span={4} offset={4}>
                        {getRowCell(rentObjectsTotal?.rentByYears?.squareMeters + rentObjectsTotal?.rentByYears?.units, emptyMark.hyphen, '', 'rent-total-value')}
                    </Col>
                    <Col span={4}>
                        {getRowCell(
                            rentObjectsTotal?.contractedRentByYears?.squareMeters + rentObjectsTotal?.contractedRentByYears?.units,
                            emptyMark.hyphen,
                            '',
                            'contracted-rent-total-value'
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default RentRollFooter;
