import React from 'react';
import { Row, Col } from 'react-bootstrap';
import BreakdownComponent from './breakdown';
import CommonCostDetailsRightSideComponents from '../right-side-components';

const CommonCostAnalysis = () => {
    return (
        <Row className="pb-3 analysis-page mx-0">
            <Col sm={12} lg={8}>
                <BreakdownComponent />
            </Col>
            <CommonCostDetailsRightSideComponents />
        </Row>
    );
};

export default CommonCostAnalysis;
