import i18next from 'i18next';

export const repeatOption = {
    doesNotRepeat: 1,
    daily: 2,
    weekly: 3,
    monthly: 4,
    annually: 5,
    everyWeekday: 6,
    custom: 7,
};

export const repeatReminderOptions = [
    { id: repeatOption.doesNotRepeat, name: () => i18next.t('events.modal.repeatOption.options.doesNotRepeat') },
    { id: repeatOption.daily, name: () => i18next.t('events.modal.repeatOption.options.daily') },
    { id: repeatOption.weekly, name: () => i18next.t('events.modal.repeatOption.options.weekly') },
    { id: repeatOption.monthly, name: () => i18next.t('events.modal.repeatOption.options.monthly') },
    { id: repeatOption.annually, name: () => i18next.t('events.modal.repeatOption.options.annually') },
    { id: repeatOption.everyWeekday, name: () => i18next.t('events.modal.repeatOption.options.everyWeekday') },
    { id: repeatOption.custom, name: () => i18next.t('events.modal.repeatOption.options.custom') },
];

export const repeatReminderOnReminderModalOptions = [
    { id: repeatOption.daily, name: () => i18next.t('events.customRecurrence.repeatOptions.day') },
    { id: repeatOption.weekly, name: () => i18next.t('events.customRecurrence.repeatOptions.week') },
    { id: repeatOption.monthly, name: () => i18next.t('events.customRecurrence.repeatOptions.month') },
    { id: repeatOption.annually, name: () => i18next.t('events.customRecurrence.repeatOptions.year') },
];

export const eventStatuses = {
    active: 1,
    completed: 2,
    deleted: 3,
};

export const eventStatusesOptions = [
    { id: eventStatuses.active, name: () => i18next.t('events.filterModal.activeEvents') },
    { id: eventStatuses.completed, name: () => i18next.t('events.filterModal.completedEvents') },
    { id: eventStatuses.deleted, name: () => i18next.t('events.filterModal.deletedEvents') },
];

export const eventPeriodes = {
    current: 1,
    past: 2,
    future: 3,
};

export const endsOptions = {
    date: 1,
    count: 2,
};

export const eventReminderRepeatOnOption = {
    exactMonthlyDay: 1,
    exactMonthlyWeekDay: 2,
};

export const digits = [
    { id: 1, name: () => i18next.t('common.digits.first') },
    { id: 2, name: () => i18next.t('common.digits.second') },
    { id: 3, name: () => i18next.t('common.digits.third') },
    { id: 4, name: () => i18next.t('common.digits.fourth') },
    { id: 5, name: () => i18next.t('common.digits.fifth') },
];

export const weekDays = [
    { id: 1, name: () => i18next.t('common.daysOfWeek.monday') },
    { id: 2, name: () => i18next.t('common.daysOfWeek.tuesday') },
    { id: 3, name: () => i18next.t('common.daysOfWeek.wednesday') },
    { id: 4, name: () => i18next.t('common.daysOfWeek.thursday') },
    { id: 5, name: () => i18next.t('common.daysOfWeek.friday') },
    { id: 5, name: () => i18next.t('common.daysOfWeek.saturday') },
    { id: 5, name: () => i18next.t('common.daysOfWeek.sunday') },
];
