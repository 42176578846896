import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { setValueTenant, getTenantSummary } from '../../../reducers/tenantReducer';
import { rentObjectRentalTypeOptions } from '../../../common/contract-options';
import RentalPercentageChart from './rental-percentage-chart';
import LoaderSpinner from '../../../components/spinner';
import get from 'lodash-es/get';
import helper from '../../../helpers/helper';
import NumberFormat from '../../../components/number-format';

const { Column } = Table;

const SummaryBar = ({ tenantSummary }) => {
    const { t } = useTranslation();
    const { rentSummary, commonCostSummary } = tenantSummary;
    const total = rentSummary + commonCostSummary;
    const rentSummaryPercent = Math.ceil((rentSummary * 100) / total);

    return (
        <div className="tenant-summary-chart">
            <div className="chart-legend">
                <div className="legend-item">
                    <span className="marker" style={{ backgroundColor: '#A105D8' }}></span>
                    <span className="text-secondary">{t('tenant.details.summary.rent')} </span>
                    <div className="legend-value">
                        <NumberFormat value={rentSummary} />
                    </div>
                </div>
                <div className="legend-item">
                    <span className="marker" style={{ backgroundColor: '#7000FF' }}></span>
                    <span className="text-secondary">{t('tenant.details.summary.commonCost')} </span>
                    <div className="legend-value">
                        <NumberFormat value={commonCostSummary} />
                    </div>
                </div>
            </div>
            <div className="chart-bar">
                <div style={{ width: `${rentSummaryPercent}%`, backgroundColor: '#A105D8' }}></div>
                <div style={{ width: `${100 - rentSummaryPercent}%`, backgroundColor: '#7000FF' }}></div>
            </div>
        </div>
    );
};

const TenantSummaryInfoComponent = (props) => {
    const { t } = useTranslation();
    const { propertyId, tenantId, portfolioId } = useParams();

    useEffect(() => {
        if (!props.tenantSummary) {
            props.getTenantSummaryCall(tenantId, propertyId, portfolioId);
        }
    }, []);

    return (
        <>
            {props.tenantSummaryLoading && <LoaderSpinner />}
            {props.tenantSummary && (
                <div className="summary-info">
                    <Row>
                        <Col md={12} lg={4} className="chart-column">
                            <div className="rental-percentage-chart">
                                <RentalPercentageChart />
                            </div>
                        </Col>
                        <Col md={12} lg={8} className="table-column">
                            <Table
                                dataSource={props.tenantSummary.rentByTypeTableData}
                                pagination={false}
                                summary={() => (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell colSpan={3} className="no-border">
                                                &nbsp;
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>{t('tenant.details.summary.total')}</Table.Summary.Cell>
                                            <Table.Summary.Cell colSpan={2} className="text-right">
                                                <NumberFormat value={props.tenantSummary.rentByTypeTotal} />
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                )}>
                                <Column
                                    title={t('tenant.details.summary.type')}
                                    key="rentalType"
                                    width="30%"
                                    dataIndex="rentalType"
                                    render={(value) => helper.getEnumValue(rentObjectRentalTypeOptions, value)}
                                />
                                <Column
                                    title={t('tenant.details.summary.amount')}
                                    width="25%"
                                    align="right"
                                    key="amount"
                                    dataIndex="amount"
                                    render={(value) => <NumberFormat value={value} />}
                                />
                                <Column
                                    title={t('tenant.details.summary.annualRent')}
                                    width="45%"
                                    align="right"
                                    key="annualRent"
                                    dataIndex="annualRent"
                                    render={(value) => <NumberFormat value={value} />}
                                />
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="mb-3 mt-3">{t('tenant.details.summary.tenantSummary')}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ offset: 1 }}>
                            <div>
                                <SummaryBar tenantSummary={props.tenantSummary} />
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

const mapState = ({ tenant }) => {
    return {
        tenantSummary: get(tenant, 'tenantSummary'),
        tenantSummaryLoading: get(tenant, 'tenantSummaryLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setValueTenantCall(key, value) {
            dispatch(setValueTenant(key, value));
        },
        getTenantSummaryCall(id, propertyId, portfolioId) {
            return dispatch(getTenantSummary(id, propertyId, portfolioId));
        },
    };
};

const TenantSummaryInfo = connect(mapState, mapDispatch)(TenantSummaryInfoComponent);
export default TenantSummaryInfo;
