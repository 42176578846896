import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Form } from 'antd';
import { createPortfolio, editPortfolio, getOwnerByProtfolioId, getPortfolioById, setValuePortfolio } from '../../../reducers/portfolioReducer';
import { clearNavigationData } from '../../../reducers/navigationReducer';
import objectHelper from '../../../helpers/trim-object';
import clear from '../../../common/clear';
import get from 'lodash-es/get';
import inviteUsers from '../../../assets/images/new-design-invite-users-icon.svg';
import review from '../../../assets/images/review.svg';
import portfolioName from '../../../assets/images/new-design-portfolio-icon.svg';
import portfolioManager from '../../../assets/images/new-design-portfolio-owner-icon.svg';
import ObjectLayout from '../../../components/layouts/object-layout';
import LeaveModal from '../../../components/leave-modal';
import PortfolioReview from './review-step';
import PortfolioInvite from './invite-step';
import PortfolioName from './name-step';
import NotFoundPage from '../../../components/error-page';
import routingService from '../../../services/routingService';
import PortfolioManagerStep from './portfolio-manager';
import localStorageService from '../../../services/localStorageService';
import { userPermisionsTypes } from '../../../common/constants';

const CreateEditPortfolio = (props) => {
    const { t } = useTranslation();
    const { portfolioId } = useParams();
    const isEdit = !!portfolioId;

    const stepNames = {
        name: 'name',
        details: 'details',
        invites: 'invites',
        review: 'review',
    };

    const nameRef = useRef();
    const managerRef = useRef();
    const inviteRef = useRef();

    const [currentStepKey, setCurrentStepKey] = useState(stepNames.name);
    const [timelineSteps, setTimelineSteps] = useState([]);
    const timelineStepsRef = useRef([]);

    useEffect(() => {
        isEdit && props.getPortfolioByIdCall(portfolioId);
        isEdit && props.getOwnerByProtfolioId(portfolioId);
        mapTimelineSteps(steps, isStepCompleted, setTimelineSteps);
    }, []);

    useEffect(() => {
        mapTimelineSteps(steps, isStepCompleted, setTimelineSteps);
    }, [props.portfolio]);

    const mapTimelineSteps = () => {
        const timelineSteps = steps.map((x) => ({
            key: x.key,
            stepTitle: x.stepTitle,
            isCompleted: isStepCompleted(x.key),
            canSkip: x.canSkip,
        }));

        setTimelineSteps(timelineSteps);
        timelineStepsRef.current = timelineSteps;
    };

    const isStepCompleted = (stepKey) => {
        if (!props.portfolio) {
            return false;
        }

        switch (stepKey) {
            case stepNames.name:
                return props.portfolio && props.portfolio.name;
            case stepNames.details:
                return !!props.portfolio.manager;
            case stepNames.invites:
                return props.portfolio.invites && props.portfolio.invites.length && props.portfolio.invites.every((x) => x.email);
        }

        return false;
    };

    const getDataFromNameForm = () => {
        const values = nameRef.current.getFieldsValue();
        const name = objectHelper.trimObjectProperties(values);
        return name;
    };

    const getDataFromManagerForm = () => {
        const values = managerRef.current.getFieldsValue();
        const manager = objectHelper.trimObjectProperties(values);
        return manager;
    };

    const getDataFromInvitesForm = () => {
        const values = inviteRef.current.getFieldsValue();
        if (!values || !values.invites) {
            return [];
        }
        const invites = values.invites.map((x) => objectHelper.trimObjectProperties(x));
        return invites;
    };

    const validateStep = (formRef) => {
        return formRef.current.validateFields();
    };

    const onStepChanging = () => {
        switch (currentStepKey) {
            case stepNames.name:
                return validateStep(nameRef)
                    .then((_) => {
                        const portfolio = getDataFromNameForm();
                        props.setValuePortfolioCall('portfolio.name', portfolio.portfolioName);
                        return Promise.resolve(true);
                    })
                    .catch((_) => {});
            case stepNames.details:
                return validateStep(managerRef)
                    .then((_) => {
                        const manager = getDataFromManagerForm();
                        if (manager.phone && manager.phone[0] !== '+') {
                            manager.phone = `+${manager.phone}`;
                        }
                        manager.id = props.portfolio.manager.id;
                        props.setValuePortfolioCall('portfolio.manager', manager);
                        return Promise.resolve(true);
                    })
                    .catch((_) => {});
            case stepNames.invites:
                return validateStep(inviteRef)
                    .then((_) => {
                        const invites = mapInvites();
                        props.setValuePortfolioCall('portfolio.invites', invites);
                        return Promise.resolve(true);
                    })
                    .catch((_) => {});
            default:
                return Promise.resolve(true);
        }
    };

    const created = (portfolioId) => {
        if (portfolioId > 0) {
            goOutOfPage(portfolioId);
            props.clearNavigationPortfoliosCall();
            props.setCurrentPortfolioCall({ id: portfolioId });
            routingService.navigateToPortfolioDashboard(portfolioId);
        }
    };

    const updated = (portfolioId) => {
        if (portfolioId > 0) {
            goOutOfPage(portfolioId);
            props.clearNavigationPortfoliosCall();
            props.setCurrentPortfolioCall({ id: portfolioId });
            routingService.navigateToPortfolioDashboard(portfolioId);
        }
    };

    const mapInvites = () => {
        return props.portfolio.invites;
    };

    const onSaveAndExitOnEdit = () => {
        const storePortfolio = props.portfolio;
        storePortfolio.invites = mapInvites();

        if (currentStepKey === stepNames.name) {
            validateStep(nameRef)
                .then(() => {
                    const portfolio = getDataFromNameForm();
                    storePortfolio.name = portfolio.portfolioName;
                    props.editPortfolioCall(portfolioId, storePortfolio).then((portfolioId) => updated(portfolioId));
                })
                .catch((_) => {});
        } else if (currentStepKey === stepNames.details) {
            validateStep(managerRef)
                .then(() => {
                    const manager = getDataFromManagerForm();
                    manager.id = props.portfolio.manager.id;
                    storePortfolio.manager = manager;
                    props.editPortfolioCall(portfolioId, storePortfolio).then((portfolioId) => updated(portfolioId));
                })
                .catch((_) => {});
        } else if (currentStepKey === stepNames.invites) {
            validateStep(inviteRef)
                .then((_) => {
                    const invites = mapInvites();
                    storePortfolio.invites = invites;
                    props.editPortfolioCall(portfolioId, storePortfolio).then((portfolioId) => updated(portfolioId));
                })
                .catch((_) => {});
        } else {
            props.editPortfolioCall(portfolioId, props.portfolio).then((portfolioId) => updated(portfolioId));
        }
    };

    const updateUserAndIdentityInfo = (portfolioId) => {
        const currentUser = localStorageService.getUser();
        const identityInfo = localStorageService.getIdentityData();

        localStorageService.setIdentityData({
            ...identityInfo,
            user: {
                ...identityInfo.user,
                userPortfolios: [
                    ...identityInfo.user.userPortfolios,
                    {
                        userId: identityInfo.user.id,
                        email: identityInfo.user.email,
                        portfolioId: portfolioId,
                        permissionId: userPermisionsTypes.portefolioAdmin,
                        permission: userPermisionsTypes.portefolioAdmin,
                        propertyIds: [],
                    },
                ],
            },
        });

        localStorageService.setUser({
            ...currentUser,
            userPortfolios: [
                ...currentUser.userPortfolios,
                {
                    userId: currentUser.id,
                    email: currentUser.email,
                    portfolioId: portfolioId,
                    permissionId: userPermisionsTypes.portefolioAdmin,
                    permission: userPermisionsTypes.portefolioAdmin,
                    propertyIds: [],
                },
            ],
        });
    };

    const onSaveAndExitOnCreate = () => {
        const storePortfolio = props.portfolio;
        storePortfolio.invites = mapInvites();

        props
            .createPortfolioCall(storePortfolio)
            .then((portfolioId) => {
                updateUserAndIdentityInfo(portfolioId);

                return portfolioId;
            })
            .then((portfolioId) => created(portfolioId));
    };

    const onSaveAndExit = () => {
        if (portfolioId) {
            onSaveAndExitOnEdit();
        } else {
            onSaveAndExitOnCreate();
        }
    };

    const onNext = () => {
        const currentStepIndex = timelineSteps.findIndex((x) => x.key === currentStepKey);
        const nextStep = timelineSteps[currentStepIndex + 1];

        if (!nextStep) {
            return;
        }

        onStepChanging().then((isSuccess) => isSuccess && onChangeStep(nextStep));
    };

    const onPrevious = (previousStep) => {
        if (currentStepKey === stepNames.details) {
            const values = managerRef.current.getFieldsValue();
            const portfolioManager = objectHelper.trimObjectProperties(values);
            if (props.portfolio.manager) {
                portfolioManager.id = props.portfolio.manager.id;
            }
            props.setValuePortfolioCall('portfolio.manager', portfolioManager);
        }

        if (previousStep) {
            setCurrentStepKey(previousStep.key);
        } else {
            const currentStepIndex = steps.findIndex((x) => x.key === currentStepKey);
            const previousStep = steps[currentStepIndex - 1];
            previousStep && setCurrentStepKey(previousStep.key);
        }
    };

    const onChangeStep = (newStep) => {
        const timelineStepsByRef = timelineStepsRef.current;
        const newIndex = timelineStepsByRef.findIndex((x) => x.key === newStep.key);
        const previousSteps = timelineStepsByRef.slice(0, newIndex);
        const isPreviousStepsAllCompleted = previousSteps.every((x) => x.isCompleted || x.canSkip);

        if (isPreviousStepsAllCompleted) {
            setCurrentStepKey(newStep.key);
        } else {
            const firstInCompletedStep = timelineStepsByRef.find((x) => !x.isCompleted || x.canSkip);
            firstInCompletedStep && setCurrentStepKey(firstInCompletedStep.key);
        }
    };

    const onSwitchStep = (newStep) => {
        const currentIndex = steps.findIndex((x) => x.key === currentStepKey);
        const newIndex = steps.findIndex((x) => x.key === newStep.key);

        if (newIndex === currentIndex) {
            return;
        }

        if (newIndex < currentIndex) {
            onPrevious(newStep);
        } else {
            onStepChanging().then((isSuccess) => isSuccess && onChangeStep(newStep));
        }
    };

    const goOutOfPage = (portfolioId) => {
        routingService.navigateToPortfolios({ portfolioId });
    };

    const onOpenCloseDraftModal = () => {
        props.setValuePortfolioCall('showDraftModal', !props.showDraftModal);
    };

    const steps = [
        {
            key: stepNames.name,
            header: t('portfolio.name.header.newPortfolio'),
            stepTitle: t('portfolio.name.stepTitle'),
            layoutMessage: <p style={{ color: '#F56A6A' }}>{`*${t('portfolio.name.required')}`}</p>,
            logo: portfolioName,
            content: (
                <Form ref={nameRef} name="basic" autoComplete="off">
                    <PortfolioName nameRef={nameRef} />
                </Form>
            ),
        },
        {
            key: stepNames.details,
            header: t('portfolio.manager.header'),
            stepTitle: t('portfolio.manager.stepTitle'),
            logo: portfolioManager,
            content: (
                <Form name="owner" autoComplete="off" ref={managerRef}>
                    <PortfolioManagerStep managerRef={managerRef} />
                </Form>
            ),
        },
        {
            key: stepNames.invites,
            header: t('portfolio.invites.header'),
            stepTitle: t('portfolio.invites.stepTitle'),
            logo: inviteUsers,
            canSkip: true,
            onSkip: () => {
                setCurrentStepKey(stepNames.review);
            },
            content: (
                <Form ref={inviteRef} name="invites" autoComplete="off">
                    <PortfolioInvite inviteFormRef={inviteRef} isEdit={isEdit} />
                </Form>
            ),
        },
        {
            key: stepNames.review,
            header: t('portfolio.review.header'),
            stepTitle: t('portfolio.review.stepTitle'),
            logo: review,
            content: <PortfolioReview goToStep={setCurrentStepKey} />,
        },
    ];

    return (
        <React.Fragment>
            {props.portfolioNotFound ? (
                <NotFoundPage header={t('portfolio.notFound')} />
            ) : (
                <ObjectLayout
                    steps={steps}
                    timelineSteps={timelineSteps}
                    isEdit={isEdit}
                    exitUrl={routingService.portfoliosUrl()}
                    currentStepKey={currentStepKey}
                    loading={props.portfolioLoading}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onSwitchStep={onSwitchStep}
                    onSaveAndExit={onSaveAndExit}
                    onCancel={onOpenCloseDraftModal}
                />
            )}
            {props.showDraftModal && <LeaveModal onCancel={onOpenCloseDraftModal} onLeave={goOutOfPage} onSave={onSaveAndExit} loading={props.portfolioLoading} />}
        </React.Fragment>
    );
};

const mapState = ({ portfolio, common }) => {
    return {
        user: get(common, 'user'),
        portfolio: get(portfolio, 'portfolio'),
        showDraftModal: get(portfolio, 'showDraftModal'),
        portfolioLoading: get(portfolio, 'portfolioLoading'),
        portfolioNotFound: get(portfolio, 'portfolioNotFound'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        createPortfolioCall(values) {
            return dispatch(createPortfolio(values));
        },
        setCurrentPortfolioCall(portfolio) {
            dispatch(setValuePortfolio('portfolio', portfolio));
        },
        editPortfolioCall(id, values) {
            return dispatch(editPortfolio(id, values));
        },
        showPortfolioCreationResult() {
            dispatch(setValuePortfolio('showCreationResultModal', true));
        },
        showPortfolioUpdatedResult() {
            dispatch(setValuePortfolio('showItemUpdatedResultModal', true));
        },
        setValuePortfolioCall(key, value) {
            dispatch(setValuePortfolio(key, value));
        },
        getPortfolioByIdCall(id) {
            dispatch(getPortfolioById(id));
        },
        getOwnerByProtfolioId(id) {
            dispatch(getOwnerByProtfolioId(id));
        },
        clearNavigationPortfoliosCall() {
            dispatch(clearNavigationData());
        },
    };
};

const Portfolio = clear(connect(mapState, mapDispatch)(CreateEditPortfolio));
export default Portfolio;
