import { Tabs } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { commonCostDetailsSelector, commonCostSelector } from '../../pages/common-cost/selectors';
import CurrencyFormat from '../currency-format';

const { TabPane } = Tabs;

const feesTabs = {
    budget: '1',
    actuals: '2',
};

const FeesDetailsComponent = () => {
    const { t } = useTranslation();
    const [currentTabKey, setCurrentTabKey] = useState(feesTabs.budget);
    const commonCostDetails = useSelector(commonCostDetailsSelector);
    const commonCost = useSelector(commonCostSelector);

    return (
        <>
            <p className="font-weight-bold">{t('commonCost.feesDetails.title')}</p>
            <div className="common-cost-analysis-details common-cost-fees">
                <Tabs activeKey={currentTabKey} onChange={setCurrentTabKey}>
                    <TabPane tab={t('commonCost.analysisDetails.budget')} key={feesTabs.budget}>
                        <div className="details-row">
                            <p>{t('commonCost.costs.administrationFees')}</p>
                            <span className="text">
                                <CurrencyFormat value={commonCostDetails.budgetAdministrationFee} />
                            </span>
                        </div>
                    </TabPane>
                    <TabPane disabled={!commonCost.hasAnyActuals} tab={t('commonCost.analysisDetails.actual')} key={feesTabs.actuals}>
                        <div className="details-row">
                            <p>{t('commonCost.costs.administrationFees')}</p>
                            <span className="text">
                                <CurrencyFormat value={commonCostDetails.actualAdministrationFee} />
                            </span>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
};

export default FeesDetailsComponent;
