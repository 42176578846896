import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CommonCostDetailsComponent from '../../../components/common-cost/details-component';
import FeesDetails from '../../../components/common-cost/fees-container';
import Notification from '../../../components/notification';
import NotificationWarning from '../../../components/notification-warning';
import NumberFormat from '../../../components/number-format';
import localStorageService from '../../../services/localStorageService';
import { updateLettableArea } from '../../../reducers/commonCostReducer';
import { connect } from 'react-redux';
import ActionButton from '../../../components/action-button';
import { useParams } from 'react-router';

const CommonCostDetailsRightSideComponents = (props) => {
    const { t } = useTranslation();
    const [showCommonCostNotification, setShowCommonCostNotification] = useState(localStorageService.getCommonCostNotification());
    const commonCostDetails = useSelector(({ commonCost }) => commonCost.commonCostDetails);
    const buildingDetails = commonCostDetails.buildingDetails;
    const propertyId = useSelector(({ property }) => property.property.id);
    const commonCostId = useSelector(({ commonCost }) => commonCost.commonCost.id);
    
    const { portfolioId } = useParams();

    const onCloseCommonCostNotification = (_) => {
        localStorageService.showCommonCostNotification(false);
        setShowCommonCostNotification(false);
    };

    const applyLettableAreaNotificationRequest = (_) => {
        props.updateLettableArea(
            {
                commonCostId: commonCostId,
                lettable: buildingDetails.lettable,
            },
            propertyId,
            commonCostId,
            portfolioId
        );
    };

    return (
        <Col sm={12} lg={4}>
            <div className="details-column">
                <div>
                    <CommonCostDetailsComponent />
                    <FeesDetails />
                </div>
                {!!showCommonCostNotification && (
                    <Notification
                        showImage={false}
                        onClose={onCloseCommonCostNotification}
                        textElements={
                            <React.Fragment>
                                <p>
                                    <span className="title">{t('commonCost.notification.vacancy.title')}</span>
                                    <span>{t('commonCost.notification.vacancy.text')}</span>
                                </p>
                                <p>
                                    <span className="title">{t('commonCost.notification.vacancy.secondTitle')}</span>
                                    <span>{t('commonCost.notification.vacancy.secondText')}</span>
                                </p>
                            </React.Fragment>
                        }
                    />
                )}
                {!!buildingDetails?.hasDifferentArea && (
                    <NotificationWarning
                        textElements={
                            <React.Fragment>
                                <p>
                                    <span className="title">{t('commonCost.notification.lettableAreaChanged.title')}</span>
                                    <br />
                                    <span>
                                        {t('commonCost.notification.lettableAreaChanged.textBody')}
                                        <NumberFormat value={buildingDetails.lettable} />
                                        <span className="ml-1">{t('contract.unitTypes.m2')}</span>.
                                    </span>
                                    {!!buildingDetails.lettable && buildingDetails.lettable > commonCostDetails.lettable - commonCostDetails.vacancyArea && (
                                        <>
                                            <br />
                                            <span>
                                                {t('commonCost.notification.grossAreaChanged.textConfirm')}
                                                <button className="apply-gross-area-notification-request-button" onClick={applyLettableAreaNotificationRequest}>
                                                    {t('commonCost.notification.grossAreaChanged.applyButtonText')}
                                                </button>
                                            </span>
                                        </>
                                    )}
                                </p>
                            </React.Fragment>
                        }
                    />
                )}
                <ActionButton className="btn-primary" disabled={true} text={t('commonCost.buttons.publishToTenantsPortal')}></ActionButton>
            </div>
        </Col>
    );
};

const mapDispatch = (dispatch) => {
    return {
        updateLettableArea(data, propertyId, commonCostId, portfolioId) {
            return dispatch(updateLettableArea(data, propertyId, commonCostId, portfolioId));
        },
    };
};

export default connect(null, mapDispatch)(CommonCostDetailsRightSideComponents);
