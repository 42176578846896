import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clearDeleteBuildingModel, setValueBuilding } from '../../../reducers/buildingReducer';
import get from 'lodash-es/get';
import toaster from '../../../common/toaster';
import ConfirmDeleteModal from '../../../components/confirm-delete-modal';

const ConfirmDeleteBuildingComponent = (props) => {
    const { t } = useTranslation();

    const handleCloseClick = (_) => {
        props.clearDeleteBuildingModelCall();
        props.closeModalWindow();
    };

    const handleDeleteClick = async (_) => {
        if (props.handleDeleteBuilding) {
            const isSuccess = await props.handleDeleteBuilding();
            isSuccess && toaster.success(t('common.itemDeletedSuccessfully', { name: props.building.name || props.building.address }));
            props.closeModalWindow();
            props.clearDeleteBuildingModelCall();
        }
    };

    return (
        <ConfirmDeleteModal
            onClose={handleCloseClick}
            onConfirm={handleDeleteClick}
            title={t('building.delete.title', { name: props.building.name || props.building.address })}
            text={t('building.delete.sentence')}
            loading={props.deleteLoading}
            deleteBtnText={t('building.delete.deleteBtnText')}
        />
    );
};

const mapState = ({ building }) => {
    return {
        building: get(building, 'deleteBuildingModel'),
        deleteLoading: get(building, 'deleteLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueBuilding('showConfirmDeleteModal', false));
        },
        clearDeleteBuildingModelCall() {
            dispatch(clearDeleteBuildingModel());
        },
    };
};

const ConfirmDeleteBuilding = connect(mapState, mapDispatch)(ConfirmDeleteBuildingComponent);
export default ConfirmDeleteBuilding;
