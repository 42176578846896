import { createSelector } from 'reselect';
import commonCostService from '../../services/commonCostService';

export const ownerCostSelector = ({ ownerCost }) => ownerCost.ownerCost;
export const ownerCostBudgetItemsSelector = ({ ownerCost }) => ownerCost.ownerCost?.ownerCostBudgets;
export const commonCostActualItemsSelector = ({ ownerCost }) => ownerCost.ownerCost?.ownerCostActuals;
export const commonCostDetailsSelector = ({ commonCost }) => commonCost.commonCostDetails;
export const showActualsSelector = ({ commonCost }) => commonCost.showActuals;

export const selectMergedBudgetAndActualItems = createSelector([ownerCostBudgetItemsSelector, commonCostActualItemsSelector], (budgetItems, actualItems) =>
    commonCostService.mergeBudgetAndActualItems(budgetItems, actualItems)
);

export const selectFilteredBudgetCosts = createSelector(ownerCostBudgetItemsSelector, (budgetItems) => commonCostService.filterCommonCostItemsByValue(budgetItems));

export const selectFilteredActualCosts = createSelector(commonCostActualItemsSelector, (actualItems) => commonCostService.filterCommonCostItemsByValue(actualItems));

export const selectHasAnyBudgetCosts = createSelector(ownerCostBudgetItemsSelector, (budgetItems) => budgetItems?.length > 0);

export const selectHasAnyActualCosts = createSelector(commonCostActualItemsSelector, (actualItems) => actualItems?.length > 0);
