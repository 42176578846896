import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Image, Row } from 'react-bootstrap';
import { getPortfoliosWithDetails, setValuePortfolio } from '../../reducers/portfolioReducer';
import routingService from '../../services/routingService';
import clear from '../../common/clear';
import get from 'lodash-es/get';
import emptyPortfolio from '../../assets/images/new-portfolio-icon.svg';
import add from '../../assets/images/plus.svg';
import PortfolioUpdatedResult from './modals/item-updated-result-modal';
import PortfolioCard from '../../components/portfolio/portfolio-card';
import EmptyList from '../../components/empty-list/empty-list';
import MainLayout from '../../components/layouts/main-layout';
import PortfolioCreationResult from './modals/result-modal';
import NumberFormat from '../../components/number-format';
import ActionButton from '../../components/action-button';
import LoaderSpinner from '../../components/spinner';
import userService from '../../services/userService';
import { Checkbox, Switch } from 'antd';
import { editPermisionTypes, userPermisionsTypes } from '../../common/constants';
import authService from '../../services/authService';
import localStorageService from '../../services/localStorageService';

const PortfoliosComponent = (props) => {
    const { t } = useTranslation();

    const [showMyPortfolios, setShowMyPortfolios] = useState(true);
    const portfolioinvites = props.portfolios.filter((item) => item.isAccepted == null);

    useEffect(() => {
        props.getPortfoliosWithDetailsCall(showMyPortfolios);
    }, [showMyPortfolios]);

    const createPortfolio = () => {
        routingService.navigateToCreatePortfolio();
    };

    const openPortfolio = (portfolio) => {
        if (portfolio.permissionId == userPermisionsTypes.rentRolleUser) {
            props.setCurrentPortfolioCall({ id: portfolio.id, name: portfolio.name });
            routingService.navigateToProperties(portfolio.id);
        } else {
            props.setCurrentPortfolioCall({ id: portfolio.id, name: portfolio.name });
            routingService.navigateToPortfolioDashboard(portfolio.id);
        }
    };

    const editPortfolio = (portfolioId) => {
        routingService.navigateToEditPortfolio(portfolioId);
    };

    const deletePortfolio = (portfolioId) => {
        // To DO: implement delete logic
    };

    const acceptInvite = (portfolio) => {
        const currentUser = localStorageService.getUser();
        const identityInfo = localStorageService.getIdentityData();

        localStorageService.setIdentityData({
            ...identityInfo,
            user: {
                ...identityInfo.user,
                userPortfolios: [
                    ...identityInfo.user.userPortfolios,
                    {
                        userId: identityInfo.user.id,
                        email: identityInfo.user.email,
                        portfolioId: portfolio.id,
                        permissionId: portfolio.permissionId,
                        propertyIds: portfolio.propertyIds,
                    },
                ],
            },
        });

        localStorageService.setUser({
            ...currentUser,
            userPortfolios: [
                ...currentUser.userPortfolios,
                {
                    userId: currentUser.id,
                    email: currentUser.email,
                    portfolioId: portfolio.id,
                    permissionId: portfolio.permissionId,
                    propertyIds: portfolio.propertyIds,
                },
            ],
        });
    };

    const declineInvite = (portfolio) => {};

    const addNewPortfolioBtn = (
        <ActionButton onClick={createPortfolio} className="btn-primary add-btn mb-0" text={t('portfolio.page.addNewPortfolio')} icon={<Image src={add} />} />
    );

    return (
        <MainLayout>
            {props.portfoliosWithDetailsLoading ? (
                <LoaderSpinner />
            ) : (
                <div className="list">
                    <div className="d-flex flex-row justify-content-between">
                        <h3 className="mb-0">{t('portfolio.page.portfolios')}</h3>
                        {props.hasPortfolios && addNewPortfolioBtn}
                    </div>
                    {props.hasPortfolios ? (
                        <Row className="total-info mt-4">
                            <Col xs={12} md={6} lg={3}>
                                <div className="d-flex flex-column">
                                    <p>{t('portfolio.page.portfolios')}</p>
                                    <h4>{props.totalData.totalPortfoliosCount}</h4>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={3}>
                                <div className="d-flex flex-column">
                                    <p>{t('portfolio.page.totalInfo.properties')}</p>
                                    <h4>{props.totalData.totalPropertiesCount}</h4>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={3}>
                                <div className="d-flex flex-column">
                                    <p>{t('portfolio.page.totalInfo.contracts')}</p>
                                    <h4>{props.totalData.totalActiveContractsCount}</h4>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={3}>
                                <div className="d-flex flex-column">
                                    <p>{t('portfolio.page.totalInfo.annualRentIncome')}</p>
                                    <h4>
                                        <NumberFormat value={props.totalData.totalAnnualRentIncome} />
                                    </h4>
                                </div>
                            </Col>
                        </Row>
                    ) : null}
                    {props.hasPortfolios && authService.isAdmin() ? (
                        <div className="show-my-portfolio-switch">
                            <Switch checked={showMyPortfolios} onChange={() => setShowMyPortfolios((prev) => !prev)} />
                            <p>{t('portfolio.page.showOnlyMyPortfolios')}</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {props.hasPortfolios ? (
                        <>
                            {portfolioinvites.length > 0 && <h4 className="mt-4">{t('portfolio.page.pendingPortfolioInvites')}</h4>}
                            <div className="cards portfolio-invites">
                                {portfolioinvites.map((portfolio) => (
                                    <PortfolioCard
                                        isInvite={true}
                                        key={portfolio.id}
                                        portfolio={portfolio}
                                        openPortfolio={openPortfolio}
                                        editPortfolio={editPortfolio}
                                        deletePortfolio={deletePortfolio}
                                        acceptInvite={acceptInvite}
                                        declineInvite={declineInvite}
                                    />
                                ))}
                            </div>
                            <div className="cards">
                                {props.portfolios
                                    .filter((item) => item.isAccepted)
                                    .map((portfolio) => (
                                        <PortfolioCard
                                            isInvite={false}
                                            key={portfolio.id}
                                            portfolio={portfolio}
                                            openPortfolio={openPortfolio}
                                            editPortfolio={editPortfolio}
                                            deletePortfolio={deletePortfolio}
                                            hasEditPermission={[userPermisionsTypes.portefolioAdmin, userPermisionsTypes.portefolioOwner].includes(portfolio.permissionId)}
                                        />
                                    ))}
                            </div>
                        </>
                    ) : (
                        <EmptyList image={emptyPortfolio} title={t('portfolio.emptyList.title')}>
                            {addNewPortfolioBtn}
                        </EmptyList>
                    )}
                </div>
            )}
            {props.showCreationResultModal && <PortfolioCreationResult portfolioId={props.location.state.portfolioId} />}
            {props.showItemUpdatedResultModal && <PortfolioUpdatedResult />}
        </MainLayout>
    );
};

const mapState = ({ portfolio, common, navigation }) => {
    return {
        totalData: get(portfolio, 'totalData'),
        portfolios: get(portfolio, 'portfoliosWithDetails'),
        hasPortfolios: get(portfolio, 'portfoliosWithDetails.length') > 0,
        showCreationResultModal: get(portfolio, 'showCreationResultModal'),
        showItemUpdatedResultModal: get(portfolio, 'showItemUpdatedResultModal'),
        portfoliosWithDetailsLoading: get(portfolio, 'portfoliosWithDetailsLoading'),
        user: get(common, 'user'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setCurrentPortfolioCall(portfolio) {
            dispatch(setValuePortfolio('portfolio', portfolio));
        },
        getPortfoliosWithDetailsCall(showMyPortfolios) {
            dispatch(getPortfoliosWithDetails(showMyPortfolios));
        },
    };
};

const Portfolios = clear(connect(mapState, mapDispatch)(PortfoliosComponent));
export default Portfolios;
