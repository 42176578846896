import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { deleteBuilding, setDeleteBuildingModel, setValueBuilding } from '../../reducers/buildingReducer';
import { setValueProperty } from '../../reducers/propertyReducer';
import ConfirmDeleteBuilding from './modals/confirm-delete-modal';
import BuildingCard from '../../components/building/building-card';
import SearchInput from '../../components/search-input';
import ActionButton from '../../components/action-button';
import EmptyList from '../../components/empty-list/empty-list';
import emptyItem from '../../assets/images/empty-building.svg';
import add from '../../assets/images/plus.svg';
import get from 'lodash-es/get';
import routingService from '../../services/routingService';
import TooltipBtn from '../../components/tooltip-btn';
import { editPermisionTypes } from '../../common/constants';
import { getListOfOwnerCosts } from '../../reducers/ownerCostReducer';
import { getListOfCommonCosts } from '../../reducers/commonCostReducer';
import toaster from '../../common/toaster';

const BuildingsComponent = (props) => {
    const { t } = useTranslation();
    const [filteredBuildings, setFilteredBuildings] = useState(props.buildings || []);
    const { portfolioId, propertyId } = useParams();

    useEffect(() => {
        setFilteredBuildings(props.buildings || []);
        props.getListOfCommonCostsCall(portfolioId, propertyId);
        props.getListOfOwnerCostsCall(portfolioId, propertyId);
    }, [props.buildings]);

    const openBuilding = (buildingId) => {
        routingService.navigateToBuildingDetails(portfolioId, propertyId, buildingId);
    };

    const createBuilding = () => {
        if (disableAddNewBuildingBtn || !props.hasEditPermission) return;
        routingService.navigateToCreateBuilding(portfolioId, propertyId);
    };

    const editBuilding = (building) => {
        routingService.navigateToEditBuilding(portfolioId, building.propertyId, building.id);
    };

    const deleteBuilding = (building) => {
        if (props.commonCosts.some(item => item.buildingId == building.id) || props.ownerCosts.some(item => item.buildingId == building.id)) {
            toaster.error(t('building.deleteErrorNotification'), null);
        } else {
            props.setDeleteBuildingModelCall(building);
            props.showBuildingDeleteModal();
        }
    };

    const onSearch = (filterText) => {
        if (!props.hasBuildings) {
            return;
        }

        filterText = (filterText || '').toUpperCase();
        const buildings = props.buildings || [];
        const filteredBuildings = filterText
            ? buildings.filter(
                (building) =>
                    (building.name && building.name.toUpperCase().includes(filterText)) ||
                    (building.address && building.address.toUpperCase().includes(filterText)) ||
                    (building.assignedPlots &&
                        building.assignedPlots.length &&
                        building.assignedPlots.some(
                            (plot) =>
                                (plot.name && plot.name.toUpperCase().includes(filterText)) ||
                                (plot.mainAddress && plot.mainAddress.toUpperCase().includes(filterText)) ||
                                (plot.municipality && plot.municipality.toUpperCase().includes(filterText)) ||
                                (plot.city && plot.city.toUpperCase().includes(filterText))
                        ))
            )
            : buildings;
        setFilteredBuildings(filteredBuildings);
    };

    const handleDeleteBuilding = async () => {
        const isSuccess = await props.deleteBuildingCall(props.deleteBuildingModel.id, props.deleteBuildingModel.propertyId, portfolioId);
        if (isSuccess) {
            const list = props.buildings.filter((x) => x.id !== props.deleteBuildingModel.id);
            props.setValuePropertyCall('property.buildings', list);
            return isSuccess;
        }
    };

    const disableAddNewBuildingBtn = !props.hasEditPermission || !props.hasPlots || props.isArchived;

    const showAddNewBuildingBtn = (className) => {
        const addNewBuildingBtn = (
            <ActionButton
                className={`btn-primary width-fit-content ${disableAddNewBuildingBtn ? 'ant-btn-disabled add-new-building-btn-disabled' : ''} ${className ? className : ''}`}
                icon={<Image src={add} />}
                text={t('building.btnAddNew')}
                onClick={createBuilding}
            />
        );

        if (disableAddNewBuildingBtn) {
            return (
                <TooltipBtn title={t(!props.hasEditPermission ? 'common.noPermission' : 'building.noPlotsMessage')} placement="right">
                    <span className="mobile-btn-container">{addNewBuildingBtn}</span>
                </TooltipBtn>
            );
        }
        return addNewBuildingBtn;
    };

    return (
        <div className="list">
            <div className="search-header">
                <SearchInput className="mr-3" placeholder={t('building.searchPlaceholder')} onSearch={onSearch} />
                {showAddNewBuildingBtn()}
            </div>
            {props.hasBuildings ? (
                <div className="cards">
                    {filteredBuildings.map((building) => (
                        <BuildingCard
                            key={building.id}
                            building={building}
                            openBuilding={openBuilding}
                            editBuilding={editBuilding}
                            deleteBuilding={deleteBuilding}
                            hasEditPermission={props.hasEditPermission}
                            isArchived={props.isArchived}
                        />
                    ))}
                </div>
            ) : (
                <EmptyList image={emptyItem} title={t('building.emptyList.title')} subTitle={t('building.emptyList.subtitle')}>
                    {showAddNewBuildingBtn('hide-on-mobile')}
                </EmptyList>
            )}
            {props.confirmDeleteBuilding && <ConfirmDeleteBuilding handleDeleteBuilding={handleDeleteBuilding} />}
        </div>
    );
};

const mapState = ({ property, navigation, building, ownerCost, commonCost }) => {
    return {
        buildings: get(property, 'property.buildings'),
        hasBuildings: get(property, 'property.buildings.length') > 0,
        hasPlots: get(property, 'property.plots.length') > 0,
        confirmDeleteBuilding: get(building, 'showConfirmDeleteModal'),
        deleteBuildingModel: get(building, 'deleteBuildingModel'),
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
        isArchived: get(property, 'property.isArchived'),
        ownerCosts: get(ownerCost, 'ownerCosts'),
        commonCosts: get(commonCost, 'commonCosts'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        showBuildingDeleteModal() {
            dispatch(setValueBuilding('showConfirmDeleteModal', true));
        },
        setDeleteBuildingModelCall(value) {
            dispatch(setDeleteBuildingModel(value));
        },
        setValuePropertyCall(key, value) {
            dispatch(setValueProperty(key, value));
        },
        deleteBuildingCall(buildingId, propertyId, portfolioId) {
            return dispatch(deleteBuilding(buildingId, propertyId, portfolioId));
        },
        getListOfOwnerCostsCall(portfolioId, propertyId, year) {
            return dispatch(getListOfOwnerCosts(portfolioId, propertyId, year));
        },
        getListOfCommonCostsCall(portfolioId, propertyId, year) {
            return dispatch(getListOfCommonCosts(portfolioId, propertyId, year));
        },
    };
};

const Buildings = connect(mapState, mapDispatch)(BuildingsComponent);
export default Buildings;
