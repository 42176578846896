import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clearDeleteTenantModel, setValueTenant } from '../../../reducers/tenantReducer';
import get from 'lodash-es/get';
import ConfirmDeleteModal from '../../../components/confirm-delete-modal';

const ConfirmDeleteTenantComponent = (props) => {
    const { t } = useTranslation();

    const onClose = (_) => {
        props.closeModalWindow();
        props.clearDeleteTenantModelCall();
    };

    const handleDeleteClick = (_) => {
        props.handleDeleteTenant && props.handleDeleteTenant();
    };

    return (
        <ConfirmDeleteModal
            onClose={onClose}
            onConfirm={handleDeleteClick}
            title={t('tenant.delete.title', { name: props.tenant.displayName })}
            text={t('tenant.delete.sentence')}
            loading={props.deleteLoading}
            deleteBtnText={t('tenant.delete.deleteBtnText')}
        />
    );
};

const mapState = ({ tenant }) => {
    return {
        tenant: get(tenant, 'deleteTenantModel'),
        deleteLoading: get(tenant, 'deleteLoading'),
    };
};

const mapDispatch = (dispatch) => {
    return {
        closeModalWindow() {
            dispatch(setValueTenant('showConfirmDeleteModal', false));
        },
        clearDeleteTenantModelCall() {
            dispatch(clearDeleteTenantModel());
        },
    };
};

const ConfirmDeleteTenant = connect(mapState, mapDispatch)(ConfirmDeleteTenantComponent);
export default ConfirmDeleteTenant;
