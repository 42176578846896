import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import ContractSetup from './contract-setup';
import Overview from './overview';
import CostAndPayment from './cost-and-payment';
import AdvancedOptions from './advanced';
import { useParams } from 'react-router';
import routingService from '../../../../services/routingService';
import { setValueContract } from '../../../../reducers/contractReducer';
import { contractStepNames } from '../../create';
import { editPermisionTypes } from '../../../../common/constants';
import { get } from 'lodash-es';

const NewContractPreviewLayoutComponent = (props) => {
    const { t } = useTranslation();
    const contract = useSelector(({ contract }) => contract.contract);
    const tenant = useSelector(({ tenant }) => tenant.tenant);

    const { portfolioId } = useParams();

    const editContract = (stepName) => {
        props.setMoveStepName(stepName);
        routingService.navigateToEditContract(portfolioId, contract.propertyId, contract.tenantId, contract.id, stepName);
    };

    return (
        <div className="new-contract-preview-layout">
            <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-row align-center">
                    <h4>{contract.name}</h4>
                    {contract.isDraft && <p className="draft-icon">{t('contract.preview.draft')}</p>}
                </div>
                <Button
                    type="primary"
                    className="contract-edit-btn"
                    disabled={contract.isArchived || !props.hasEditPermission}
                    icon={<EditOutlined />}
                    onClick={() => editContract(contractStepNames.setUpYourContract)}>
                    {t('common.buttons.edit')}
                </Button>
            </div>
            <Overview contract={contract} tenant={tenant} />
            <ContractSetup contract={contract} editContract={editContract} />
            <CostAndPayment contract={contract} editContract={editContract} />
            <AdvancedOptions contract={contract} editContract={editContract} />
        </div>
    );
};

const mapState = ({ navigation }) => {
    return {
        hasEditPermission: editPermisionTypes.includes(get(navigation, 'selectedPortfolio.permissionId')),
    };
};

const mapDispatch = (dispatch) => {
    return {
        setMoveStepName(name) {
            dispatch(setValueContract('moveToStepName', name));
        },
    };
};

const NewContractPreviewLayout = connect(mapState, mapDispatch)(NewContractPreviewLayoutComponent);
export default NewContractPreviewLayout;
