import { Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const { Option } = Select;

const defaultYearsQuantity = 100;

const CustomContractDatePicker = ({
    feedbackFormat = 'MMMM D, YYYY',
    value = moment().utc(),
    onValidate,
    onChange,
    onChangeDate,
    showSearch,
    disabled,
    startDate,
    endDate,
    delayYears = 10,
    format = { day: 'DD', month: 'MM', year: 'YYYY' },
}) => {
    const getDateDataFromMoment = (moment) => {
        const data = moment.toObject();
        return { day: data.date, month: data.months, year: data.years };
    };

    const defaultStartYear = new Date().getFullYear() - delayYears;

    const MapDateDataFromProps = () => {
        return value ? getDateDataFromMoment(value) : getDateDataFromMoment(moment().utc());
    };

    const [dateData, setDateData] = useState(MapDateDataFromProps());

    const getYears = () => {
        let startYear = defaultStartYear;
        let yearsQty = defaultYearsQuantity;

        if (startDate) {
            startYear = getDateDataFromMoment(startDate.clone().add(1, 'day')).year;

            if (endDate) {
                yearsQty = getDateDataFromMoment(endDate.clone().add(-1, 'day')).year - startYear + 1;
            }
        } else if (endDate) {
            startYear = getDateDataFromMoment(endDate.clone().add(-1, 'day')).year - yearsQty + 1;
        }

        return Array.from({ length: yearsQty }, (v, i) => startYear + i);
    };

    const getMonths = (year) => {
        let months = moment.months().map((value) => moment().month(value).format('MM'));

        if (startDate) {
            const startDateData = getDateDataFromMoment(startDate.clone().add(1, 'day'));

            if (year === startDateData.year) {
                months = months.filter((month) => month - 1 >= startDateData.month);
            }
        }

        if (endDate) {
            const endDateData = getDateDataFromMoment(endDate.clone().add(-1, 'day'));

            if (year === endDateData.year) {
                months = months.filter((month) => month - 1 <= endDateData.month);
            }
        }

        return months.map((value) =>
            moment()
                .month(value - 1)
                .format(format.month)
        );
    };

    const getDays = (year, month) => {
        const daysInMonth = moment({ year, month }).daysInMonth();
        let days = Array.from({ length: daysInMonth }, (v, i) => i + 1);
        if (startDate) {
            const startDateData = getDateDataFromMoment(startDate.clone().add(1, 'day'));

            if (year === startDateData.year && month === startDateData.month) {
                days = days.filter((day) => day >= startDateData.day);
            }
        }

        if (endDate) {
            const endDateData = getDateDataFromMoment(endDate.clone().add(-1, 'day'));

            if (year === endDateData.year && month === endDateData.month) {
                days = days.filter((day) => day <= endDateData.day);
            }
        }

        return days.map((d) => moment({ day: d, month: month, year: year }).format(format.day));
    };

    const handleMonthChange = (value) => {
        const monthNumber = /\d/.test(value) ? +value - 1 : +moment().month(value).format("M") - 1;

        if (!format?.day) {
            setDateData({ ...dateData, month: monthNumber, day: 10 });
            return;
        }

        const daysList = getDays(dateData.year, monthNumber);

        if (dateData.day > daysList[daysList.length - 1]) {
            setDateData({ ...dateData, month: monthNumber, day: daysList[daysList.length - 1] });
            return;
        }

        setDateData({ ...dateData, month: monthNumber });
    };

    const handleYearChange = (value) => {
        const monthsList = getMonths(value);

        let month = dateData.month;

        if (startDate && monthsList[0] - 1 > month) {
            month = monthsList[0] - 1;
        }

        if (endDate && monthsList[monthsList.length - 1] - 1 < month) {
            month = monthsList[monthsList.length - 1] - 1;
        }

        if (!format?.day) {
            setDateData({ month, year: value, day: 10 });
            return;
        }

        const daysList = getDays(value, month);
        const day = dateData.day > daysList[daysList.length - 1] ? daysList[daysList.length - 1] : dateData.day < daysList[0] ? daysList[0] : dateData.day;

        setDateData({ year: value, day, month });
    };

    const handleDayChange = (value) => {
        setDateData({ ...dateData, day: value });
    };

    const validateDateData = () => {
        const isValid = !!dateData.day && (dateData.month === 0 || !!dateData.month) && !!dateData.year;

        if (onValidate) {
            onValidate(isValid);
        }

        return isValid;
    };

    useEffect(() => {
        if (!validateDateData()) {
            return;
        }

        const newDate = moment({ ...dateData });

        if (onChange) {
            onChange(newDate);
        }

        if (onChangeDate) {
            onChangeDate(newDate);
        }
    }, [dateData]);

    const isDateEquals = (first, second) => {
        return first?.day === second?.day && first?.month === second?.month && first?.year === second?.year;
    };

    useEffect(() => {
        const data = MapDateDataFromProps();

        if (!isDateEquals(data, dateData)) {
            setDateData(data);
        }
    }, [value]);

    return (
        <div className="contract-date-picker">
            <div className="select-wrapper">
                {!!format?.day && (
                    <Select
                        showSearch={showSearch}
                        value={dateData.month === 0 || dateData.month ? moment({ day: dateData.day }).format(format.day) : undefined}
                        onChange={handleDayChange}
                        disabled={disabled || (!dateData.month && dateData.month !== 0)}
                        dropdownClassName="new-design-dropdown"
                        placeholder={format.day}>
                        {getDays(dateData.year, dateData.month).map((value, index) => (
                            <Option className="date-option" key={index} value={value}>
                                {value}
                            </Option>
                        ))}
                    </Select>
                )}
                {!!format?.month && (
                    <Select
                        showSearch={showSearch}
                        value={dateData.month === 0 || dateData.month ? moment({ month: dateData.month }).format(format.month) : undefined}
                        onChange={handleMonthChange}
                        dropdownClassName="new-design-dropdown"
                        disabled={disabled}
                        placeholder={format.month}>
                        {getMonths(dateData.year).map((month, index) => {
                            return (
                                <Option className="date-option" key={index} value={month}>
                                    {month}
                                </Option>
                            );
                        })}
                    </Select>
                )}
                {!!format?.year && (
                    <Select
                        showSearch={showSearch}
                        dropdownClassName="new-design-dropdown"
                        value={dateData.year}
                        onChange={handleYearChange}
                        placeholder={format.year}
                        disabled={disabled}>
                        {getYears().map((year, index) => (
                            <Option className="date-option" key={index} value={year}>
                                {year}
                            </Option>
                        ))}
                    </Select>
                )}
            </div>
            {feedbackFormat && validateDateData() && <p className="mb-0 date-feedback">{moment(dateData).format(feedbackFormat)}</p>}
        </div>
    );
};

export default CustomContractDatePicker;
