import { QuestionCircleFilled } from '@ant-design/icons';
import { Col, Radio, Row, Space, Input, Form, Select, InputNumber } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { turnoverInvoiceTypes, turnoverInvoiceTypesOptions, turnoverRecurrencePeriodTypes, turnoverRecurrencePeriodTypesOptions } from '../../../../common/contract-options';
import TurnoverNotification from './turnover-notification';
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { validateEmail } from '../../../../common/validators';

const InvoicingComponent = (props) => {
    const { t } = useTranslation();

    const { amount, minimumRentValue, turnover } = props;

    const [invoiceType, setInvoiceType] = useState(props.invoiceType);
    const [notificationVisible, setNotificationVisible] = useState(Object.values(turnoverInvoiceTypes).map((item) => ({ id: item, value: false })));
    const [periodType, setPeriodType] = useState(props.periodType);
    const [arrearsBasedPeriodType, setArrearsBasedPeriodType] = useState();
    const [assignTurnoverNotificationVisible, setAssignTurnoverNotificationVisible] = useState(false);
    const [month, setMonth] = useState();

    const handleNotification = (value, id) => {
        setNotificationVisible((prev) => {
            return prev.map((item) => {
                return item.id == id ? { ...item, value: value } : item;
            });
        });
    };

    const onChangeInvoicingType = (value) => {
        setInvoiceType(value);
        setNotificationVisible(Object.values(turnoverInvoiceTypes).map((item) => ({ id: item, value: false })));
    };

    const getOptionStatement = (type) => {
        switch (type) {
            case turnoverRecurrencePeriodTypes.annually:
                return (
                    <>
                        <Col span={4}>
                            <p className="mt-2">{t('contract.turnover.statemant.on')}</p>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name={['arrearsBased', 'onDay']}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value == undefined) {
                                                return Promise.resolve();
                                            }

                                            if (month !== undefined && month !== null) {
                                                if (value > moment().set('month', month).daysInMonth()) {
                                                    return Promise.reject(new Error(`Max ${moment().set('month', month).daysInMonth()}`));
                                                }
                                            }

                                            if (value < 1) {
                                                return Promise.reject(new Error('Min 1'));
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                    {
                                        required: true,
                                        message: t('contract.turnover.days') + t('common.isRequired'),
                                    },
                                ]}>
                                <InputNumber className="ant-input hidden-controls" precision={0}/>
                            </Form.Item>
                        </Col>
                        <Col span={8} offset={2}>
                            <Form.Item name={['arrearsBased', 'month']}>
                                <Select
                                    onChange={(value) => setMonth(value)}
                                    options={moment.months().map((item, index) => {
                                        return {
                                            value: index,
                                            label: item,
                                        };
                                    })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5} offset={1}>
                            <p className="mt-2" >{t('contract.turnover.statemant.ofEachYear')}</p>
                        </Col>
                    </>
                );

            case turnoverRecurrencePeriodTypes.monthly:
                return (
                    <>
                        <Col span={4}>
                            <p className="mt-2">{t('contract.turnover.statemant.onDay')}</p>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name={['arrearsBased', 'onDay']}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value == undefined) {
                                                return Promise.resolve();
                                            }

                                            if (value < 1) {
                                                return Promise.reject(new Error('Min 1'));
                                            }

                                            if (value > 31) {
                                                return Promise.reject(new Error('Max 31'));
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={5} offset={1}>
                            <p className="mt-2">{t('contract.turnover.statemant.ofEactMonth')}</p>
                        </Col>
                    </>
                );

            case turnoverRecurrencePeriodTypes.quarterly:
                return (
                    <>
                        <Col span={4}>
                            <p className="mt-2">{t('contract.turnover.statemant.onDay')}</p>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name={['arrearsBased', 'onDay']}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value == undefined) {
                                                return Promise.resolve();
                                            }

                                            if (value < 1) {
                                                return Promise.reject(new Error('Min 1'));
                                            }

                                            if (value > 92) {
                                                return Promise.reject(new Error('Max 92'));
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={5} offset={1}>
                            <p className="mt-2">{t('contract.turnover.statemant.ofEachQuarter')}</p>
                        </Col>
                    </>
                );

            default:
                break;
        }
    };

    return (
        <div className="invoicing">
            <h6>{t('contract.turnover.invoicing.title')}</h6>
            <Row>
                <Col span={14}>
                    <Form.Item
                        name="turnoverInvoiceTypeId"
                        rules={[
                            {
                                required: true,
                                message: t('contract.turnover.minimumYearly.chooseOption'),
                            },
                        ]}>
                        <Radio.Group className="mt-3" onChange={(e) => onChangeInvoicingType(e.target.value)}>
                            <Space direction="vertical">
                                <div className="d-flex align-items-center">
                                    <Radio value={turnoverInvoiceTypes.minimumRent}>
                                        {turnoverInvoiceTypesOptions.find((item) => item.id == turnoverInvoiceTypes.minimumRent).name()}
                                    </Radio>
                                    <QuestionCircleFilled onClick={() => handleNotification(true, turnoverInvoiceTypes.minimumRent)} />
                                </div>
                                {notificationVisible.find((item) => item.id == turnoverInvoiceTypes.minimumRent).value && (
                                    <TurnoverNotification
                                        text={t('contract.turnover.invoicing.minimumRentNotification')}
                                        close={() => handleNotification(false, turnoverInvoiceTypes.minimumRent)}
                                    />
                                )}
                                {invoiceType == turnoverInvoiceTypes.minimumRent && (
                                    <div className="minimum-rent-wrapper">
                                        <Row className="mt-4">
                                            <Col span={24}>
                                                <h6>{`${t('contract.turnover.statemant.tenantTurnover')}`}</h6>
                                                <Form.Item name="tenantEmail" rules={[validateEmail]}>
                                                    <Input placeholder={t('contract.turnover.statemant.enterEmail')} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-4">
                                            <Col span={24}>
                                                <h6>{`${t('contract.turnover.statemant.auditTurnover')}`}</h6>
                                                <Form.Item name="auditEmail" rules={[validateEmail]}>
                                                    <Input placeholder={t('contract.turnover.statemant.enterEmail')} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {minimumRentValue && (
                                            <p className="ml-3">
                                                {`${t('contract.turnover.minimumYearly.title')}: ${minimumRentValue} per unit`}
                                                <br />
                                                {`${t('contract.turnover.minimumYearly.helperTextPart1')} ${amount * minimumRentValue}`}
                                                <br />
                                                {`(${t('contract.turnover.minimumYearly.helperTextPart2')})`}
                                            </p>
                                        )}
                                    </div>
                                )}
                                <div className="d-flex align-items-center">
                                    <Radio value={turnoverInvoiceTypes.budget}>{turnoverInvoiceTypesOptions.find((item) => item.id == turnoverInvoiceTypes.budget).name()}</Radio>
                                    <QuestionCircleFilled onClick={() => handleNotification(true, turnoverInvoiceTypes.budget)} />
                                </div>
                                {notificationVisible.find((item) => item.id == turnoverInvoiceTypes.budget).value && (
                                    <TurnoverNotification
                                        text={t('contract.turnover.invoicing.budgetNotification')}
                                        close={() => handleNotification(false, turnoverInvoiceTypes.budget)}
                                    />
                                )}
                                {invoiceType == turnoverInvoiceTypes.budget && (
                                    <div className="budget-wrapper">
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name={['arrearsBased', 'turnoverRecurrencePeriodTypeId']}>
                                                    <Radio.Group onChange={(e) => setPeriodType(e.target.value)}>
                                                        <Radio value={turnoverRecurrencePeriodTypes.annually}>
                                                            {turnoverRecurrencePeriodTypesOptions.find((item) => item.id == turnoverRecurrencePeriodTypes.annually).name()}
                                                        </Radio>
                                                        <Radio value={turnoverRecurrencePeriodTypes.monthly}>
                                                            {turnoverRecurrencePeriodTypesOptions.find((item) => item.id == turnoverRecurrencePeriodTypes.monthly).name()}
                                                        </Radio>
                                                        <Radio value={turnoverRecurrencePeriodTypes.quarterly}>
                                                            {turnoverRecurrencePeriodTypesOptions.find((item) => item.id == turnoverRecurrencePeriodTypes.quarterly).name()}
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col span={24}>
                                                <Row className="mt-4">
                                                    {getOptionStatement(periodType)}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col span={24}>
                                                <h6>{`${t('contract.turnover.statemant.tenantTurnover')}`}</h6>
                                                <Form.Item name={['arrearsBased', 'tenantEmail']} rules={[validateEmail]}>
                                                    <Input placeholder={t('contract.turnover.statemant.enterEmail')} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-4">
                                            <Col span={24}>
                                                <h6>{`${t('contract.turnover.statemant.auditTurnover')}`}</h6>
                                                <Form.Item name={['arrearsBased', 'auditEmail']} rules={[validateEmail]}>
                                                    <Input placeholder={t('contract.turnover.statemant.enterEmail')} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                <div className="d-flex align-items-center">
                                    <Radio value={turnoverInvoiceTypes.arrearsBased}>
                                        {turnoverInvoiceTypesOptions.find((item) => item.id == turnoverInvoiceTypes.arrearsBased).name()}
                                    </Radio>
                                    <QuestionCircleFilled onClick={() => handleNotification(true, turnoverInvoiceTypes.arrearsBased)} />
                                </div>
                                {notificationVisible.find((item) => item.id == turnoverInvoiceTypes.arrearsBased).value && (
                                    <TurnoverNotification
                                        text={t('contract.turnover.invoicing.arrearsBasedNotification')}
                                        close={() => handleNotification(false, turnoverInvoiceTypes.arrearsBased)}
                                    />
                                )}
                                {invoiceType == turnoverInvoiceTypes.arrearsBased && (
                                    <div className="budget-wrapper">
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item name={['arrearsBased', 'turnoverRecurrencePeriodTypeId']}>
                                                    <Radio.Group onChange={(e) => setArrearsBasedPeriodType(e.target.value)}>
                                                        <Radio value={turnoverRecurrencePeriodTypes.annually}>
                                                            {turnoverRecurrencePeriodTypesOptions.find((item) => item.id == turnoverRecurrencePeriodTypes.annually).name()}
                                                        </Radio>
                                                        <Radio value={turnoverRecurrencePeriodTypes.monthly}>
                                                            {turnoverRecurrencePeriodTypesOptions.find((item) => item.id == turnoverRecurrencePeriodTypes.monthly).name()}
                                                        </Radio>
                                                        <Radio value={turnoverRecurrencePeriodTypes.quarterly}>
                                                            {turnoverRecurrencePeriodTypesOptions.find((item) => item.id == turnoverRecurrencePeriodTypes.quarterly).name()}
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col span={24}>
                                                <Row className="mt-4">
                                                    {getOptionStatement(arrearsBasedPeriodType)}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row align="middle">
                                            <Col>
                                                <h6 className="mt-3">{t('contract.turnover.statemant.assignTurnover')}</h6>
                                            </Col>
                                            <Col className="ml-1">
                                                <QuestionCircleFilled onClick={() => setAssignTurnoverNotificationVisible(true)} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={15}>
                                                {assignTurnoverNotificationVisible && (
                                                    <TurnoverNotification
                                                        text={t('contract.turnover.statemant.eventReminder')}
                                                        close={() => setAssignTurnoverNotificationVisible(false)}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col span={15}>
                                                <Form.Item
                                                    name={['arrearsBased', 'assignedUserIds']}
                                                    initialValue={turnover?.turnoverInvoice?.arrearsBased?.assignedUsers.map((item) => item.userId)}>
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Please select"
                                                        options={props.users.map((item) => {
                                                            return {
                                                                label: item.userName,
                                                                value: item.userId,
                                                            };
                                                        })}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={15}>
                                                {assignTurnoverNotificationVisible && (
                                                    <TurnoverNotification
                                                        text={t('contract.turnover.statemant.statementNeedsAproved')}
                                                        close={() => setAssignTurnoverNotificationVisible(false)}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col span={24}>
                                                <h6>{`${t('contract.turnover.statemant.tenantTurnover')}`}</h6>
                                                <Form.Item name={['arrearsBased', 'tenantEmail']} rules={[validateEmail]}>
                                                    <Input placeholder={t('contract.turnover.statemant.enterEmail')} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-4">
                                            <Col span={24}>
                                                <h6>{`${t('contract.turnover.statemant.auditTurnover')}`}</h6>
                                                <Form.Item name={['arrearsBased', 'auditEmail']} rules={[validateEmail]}>
                                                    <Input placeholder={t('contract.turnover.statemant.enterEmail')} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

const mapState = ({ portfolio }) => {
    return {
        users: get(portfolio, 'portfolioUsers'),
    };
};

const Invoicing = connect(mapState, null)(InvoicingComponent);
export default Invoicing;
