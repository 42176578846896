import { Row, Col, Typography, Tooltip } from 'antd';
import React from 'react';
import RentRollHeader from './rent-roll-header';
import helper from '../../../helpers/helper';
import { contractStatus, rentObjectRentalTypeOptions, warrantyTypeOptions } from '../../../common/contract-options';
import { primaryDateFormat } from '../../../components/dateFormatter/dateFormats';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import RentRollTotalRow from './rent-roll-total-row';

const { Text } = Typography;

export const emptyMark = {
    hyphen: '-',
    notApplicable: 'N/A',
    empty: '',
};

export const cellColors = {
    purple: '#BE7AFF',
    red: '#F13F3F',
    default: '#B8BDDD',
    green: '#00AE31',
    orange: '#F2994A',
    yellow: 'ffff00',
};

export const invalidDate = moment('01.01.0001', 'DD.MM.YYYY');

const RentRollTable = (props) => {
    const { contract, isContractExpiring, isContractStarting, currentYear } = props;

    const { t } = useTranslation();

    const getRowCell = (value, mark, isNumber = true, isTooltipNeeded, cellColor, decimalPoints = 1, textAlign = 'center', rentCpiAdjusted) => {
        if (!value && !rentCpiAdjusted) {
            return (
                <Text ellipsis={{ tooltip: isTooltipNeeded }} className="rent-object-cell" style={{ textAlign: textAlign }}>
                    {mark}
                </Text>
            );
        }

        const roundedValue = isNumber ? value?.toFixed(decimalPoints) : value;

        const cpiPeriodsTooltip = (
            <div>
                <p>{t('rentRoll.cpiAdjustedPeriods')}</p>
                {rentCpiAdjusted?.rentCpiAdjustmentPeriods?.map((item) => {
                    return (
                        <p>{`${moment(item?.periodStartDate).format(primaryDateFormat)} - ${moment(item?.periodEndDate).format(primaryDateFormat)}: ${
                            item?.currentCpiIndexValue
                        }`}</p>
                    );
                })}
            </div>
        );

        const isRentAdjusted = Number.isFinite(rentCpiAdjusted?.currentRentCpiAdjusted);

        return isRentAdjusted ? (
            <Tooltip placement="topRight" title={cpiPeriodsTooltip}>
                <Text ellipsis={{ tooltip: isTooltipNeeded }} style={{ color: cellColor, textAlign: textAlign }} className="rent-object-cell">
                    {helper.numberWithSpaces(roundedValue)}
                </Text>
            </Tooltip>
        ) : (
            <Text ellipsis={{ tooltip: isTooltipNeeded }} style={{ color: isRentAdjusted ? cellColors.yellow : cellColor, textAlign: textAlign }} className="rent-object-cell">
                {isNumber ? helper.numberWithSpaces(roundedValue) : value}
            </Text>
        );
    };

    const getRentByYearsAndUnitCellsColor = (rent) => {
        const isRentObjectStarting = moment(rent?.leaseStartDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'), null, '()');
        const isRentObjectEpiring = moment(rent?.leaseEndDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'), null, '()');

        if (rent?.turnoverId && !!rent?.turnover?.minimunRentYearlyId) {
            return cellColors.purple;
        }

        if (isContractExpiring || isRentObjectEpiring) {
            return cellColors.red;
        }

        if (isContractStarting || isRentObjectStarting) {
            return cellColors.green;
        }

        return cellColors.default;
    };

    return (
        <>
            <RentRollHeader isTotal={false} />
            {contract?.rentObjects?.map((rentObject) => {
                const isRentObjectExpired = rentObject?.leaseEndDate && moment(rentObject?.leaseEndDate).isBefore(moment().year(currentYear));

                return (
                    <Row className="rent-object-row">
                        <Col сlassName="ml-1" span={2}>
                            {getRowCell(helper.getEnumValue(rentObjectRentalTypeOptions, rentObject?.rentObjectRentalTypeId), emptyMark.hyphen, false, true, getRentByYearsAndUnitCellsColor(rentObject), null, 'start')}
                        </Col>
                        <Col span={1}>
                            {getRowCell(rentObject?.buildingId ? rentObject?.building?.address : rentObject?.plot?.address, emptyMark.hyphen, false, true, null, null, 'end')}
                        </Col>
                        <Col span={1}>
                            {getRowCell(
                                rentObject?.buildingId &&
                                    rentObject?.building?.floors
                                        ?.filter((floor) => rentObject?.assignedFloorIds?.includes(floor.id))
                                        ?.map((item) => item?.level)
                                        .join(', '),
                                emptyMark.hyphen,
                                false,
                                false,
                                null,
                                null,
                                'end'
                            )}
                        </Col>
                        <Col span={1}>{getRowCell(rentObject?.squareMeters, emptyMark.hyphen, true, false, getRentByYearsAndUnitCellsColor(rentObject), null, 'end')}</Col>
                        <Col span={1}>{getRowCell(rentObject?.units, emptyMark.hyphen, true, false, getRentByYearsAndUnitCellsColor(rentObject), null, 'end')}</Col>
                        <Col span={1}>
                            {getRowCell(
                                rentObject?.rentSquareMetersCpiAdjusted?.currentRentCpiAdjusted ?? rentObject.rentSquareMeters,
                                emptyMark.hyphen,
                                true,
                                false,
                                null,
                                null,
                                'end',
                                rentObject?.rentSquareMetersCpiAdjusted
                            )}
                        </Col>
                        <Col span={2}>
                            {getRowCell(
                                rentObject?.rentUnitsCpiAdjusted?.currentRentCpiAdjusted ?? rentObject?.rentUnits,
                                emptyMark.hyphen,
                                true,
                                false,
                                null,
                                null,
                                'end',
                                rentObject?.rentUnitsCpiAdjusted
                            )}
                        </Col>
                        <Col span={1}>
                            {getRowCell(
                                rentObject?.turnoverId && rentObject?.turnover?.turnoverRates?.map((item) => `${item?.rate}%`)?.join(' | '),
                                emptyMark.notApplicable,
                                false,
                                false,
                                rentObject?.turnoverId && !!rentObject?.turnover?.minimunRentYearlyId ? cellColors.purple : cellColors.default,
                                null,
                                'end'
                            )}
                        </Col>
                        <Col span={2}>
                            {getRowCell(
                                rentObject?.rentByYears?.yearlyRentPaymentPlanItem?.fullRentAmount - rentObject?.rentByYears?.yearlyRentPaymentPlanItem?.exemptionAmount,
                                emptyMark.hyphen,
                                true,
                                false,
                                getRentByYearsAndUnitCellsColor(rentObject),
                                2,
                                'end'
                            )}
                        </Col>
                        <Col span={2}>
                            {getRowCell(
                                rentObject?.contractedRentByYears?.yearlyRentPaymentPlanItem?.fullRentAmount,
                                emptyMark.hyphen,
                                true,
                                false,
                                getRentByYearsAndUnitCellsColor(rentObject),
                                2,
                                'end'
                            )}
                        </Col>
                        <Col span={2}>
                            {getRowCell(
                                rentObject?.leaseStartDate && !moment(rentObject?.leaseStartDate).isSame(invalidDate)
                                    ? moment(rentObject?.leaseStartDate).format(primaryDateFormat)
                                    : moment(contract?.leaseStartDate).format(primaryDateFormat),
                                emptyMark.hyphen,
                                false,
                                false,
                                isContractStarting || moment(rentObject?.leaseStartDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'))
                                    ? cellColors.green
                                    : cellColors.default,
                                null,
                                'end'
                            )}
                        </Col>
                        <Col span={2}>
                            {getRowCell(
                                rentObject?.leaseEndDate && !moment(rentObject?.leaseEndDate).isSame(invalidDate)
                                    ? moment(rentObject?.leaseEndDate).format(primaryDateFormat)
                                    : moment(contract?.leaseEndDate).format(primaryDateFormat),
                                emptyMark.hyphen,
                                false,
                                false,
                                isContractExpiring || moment(rentObject?.leaseEndDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'))
                                    ? cellColors.red
                                    : cellColors.default,
                                null,
                                'start'
                            )}
                        </Col>
                        <Col span={2}>
                            {contract?.status == contractStatus.terminated
                                ? getRowCell(`Term. ${moment(contract?.terminatedDate).format(primaryDateFormat)}`, emptyMark.hyphen, false, false, cellColors.red)
                                : getRowCell(
                                      rentObject?.remaining ? rentObject?.remaining : t('rentRoll.expired'),
                                      emptyMark.hyphen,
                                      !!rentObject?.remaining && !isRentObjectExpired,
                                      false,
                                      rentObject?.remaining ? cellColors.default : cellColors.red,
                                      null,
                                      'center'
                                  )}
                        </Col>
                        <Col span={1}>
                            {getRowCell(
                                rentObject?.cpiRate ? `${rentObject?.cpiRate}%` : contract?.hasRentAdjustmentOptions && `${contract?.cpiRate}%`,
                                emptyMark.hyphen,
                                false,
                                false,
                                null,
                                null,
                                'end'
                            )}
                        </Col>
                        <Col span={1}>{getRowCell(emptyMark.hyphen, emptyMark.hyphen, false)}</Col>
                        <Col span={1}>{getRowCell(rentObject?.hasVatCalculation ? t('common.yes') : t('common.no'), emptyMark.hyphen, false, false, null, null, 'end')}</Col>
                        <Col span={1}>
                            {getRowCell(
                                helper.getEnumValue(warrantyTypeOptions, rentObject?.contractWarranties[0]?.warrantyTypeId),
                                emptyMark.hyphen,
                                false,
                                true,
                                null,
                                null,
                                'end'
                            )}
                        </Col>
                    </Row>
                );
            })}
            <RentRollTotalRow contract={contract} isHeader={false} isContractExpiring={isContractExpiring} isContractStarting={isContractStarting} />
            <Row className="rent-roll-table-notes">
                {contract?.rentObjects?.some((rentObject) => rentObject?.turnoverId && !!rentObject?.turnover?.minimunRentYearlyId) && (
                    <Col className="note-wrapper">
                        <div className="dot" style={{ background: cellColors.purple }} />
                        <Text style={{ color: cellColors.purple }} className="rent-object-cell">
                            {t('rentRoll.minimumRentOnly')}
                        </Text>
                    </Col>
                )}
                <Col className="ml-4">
                    {isContractExpiring && (
                        <div className="note-wrapper">
                            <div className="dot" style={{ background: cellColors.red }} />
                            <Text style={{ color: cellColors.red }} className="rent-object-cell">
                                {`${t('rentRoll.durationEnd')} ${moment(contract?.leaseEndDate).format(primaryDateFormat)}`}
                            </Text>
                        </div>
                    )}
                </Col>
                <Col className="ml-4">
                    {contract?.rentObjects?.map((rentObject) => {
                        if (moment(rentObject?.leaseStartDate).isBetween(moment().year(currentYear).startOf('year'), moment().year(currentYear).endOf('year'))) {
                            return (
                                <div className="note-wrapper">
                                    <div className="dot" style={{ background: cellColors.green }} />
                                    <Text style={{ color: cellColors.green }} className="rent-object-cell d-flex">
                                        {`${t('rentRoll.durationStart')} ${moment(rentObject?.leaseStartDate).format(primaryDateFormat)}`}
                                    </Text>
                                </div>
                            );
                        }
                    })}
                    {isContractStarting && (
                        <div className="note-wrapper">
                            <div className="dot" style={{ background: cellColors.green }} />
                            <Text style={{ color: cellColors.green }} className="rent-object-cell d-flex">
                                {`${t('rentRoll.durationStart')} ${moment(contract?.leaseStartDate).format(primaryDateFormat)}`}
                            </Text>
                        </div>
                    )}
                </Col>
                <Col className="ml-4">
                    {contract?.rentObjects?.map((rentObject) => {
                        return rentObject?.rentByYears?.rentPaymentPlanExemptionItems?.map((item) => {
                            return (
                                <div className="note-wrapper">
                                    <div className="dot" style={{ background: cellColors.orange }} />
                                    <Text style={{ color: cellColors.orange }} className="rent-object-cell d-flex">
                                        {`${t('rentRoll.exemptionAmount')} ${moment(item?.startDate).format(primaryDateFormat)} - ${moment(item?.endDate).format(
                                            primaryDateFormat
                                        )} - ${helper.numberWithSpaces(item?.exemptionAmount?.toFixed(1))} (${helper.getEnumValue(
                                            rentObjectRentalTypeOptions,
                                            rentObject?.rentObjectRentalTypeId
                                        )})`}
                                    </Text>
                                </div>
                            );
                        });
                    })}
                </Col>
            </Row>
        </>
    );
};

export default RentRollTable;
